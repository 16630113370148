import { useApolloClient } from "@apollo/client"
import {
  SoftArchiveRegistrationMutationFn,
  SoftArchiveRegistrationMutationHookResult,
  UnifiedHomeRegistrationFragment,
  UnifiedHomeRegistrationsDocument,
  UnifiedHomeRegistrationsQueryResult,
  UnifiedHomeRegistrationsQueryVariables,
  useSoftArchiveRegistrationMutation
} from "../api/generated"
import useUnifiedHomeRegistrationVars from "./useUnifiedHomeRegistrationQueryVars"

// Toggles the soft-archived state of a registration based on whether the registration
// is currently soft-archived or not.
function useSoftArchiveRegistration(
  registration: UnifiedHomeRegistrationFragment
): SoftArchiveRegistrationMutationHookResult {
  const shouldArchive = !registration.softArchivedTime
  const unifiedHomeRegistrationVars = useUnifiedHomeRegistrationVars()
  const client = useApolloClient()

  // Update the appropriate registration queries to either add or remove the registration
  // based on whether it is being soft archived or not.
  function updateCache(shouldArchive: boolean) {
    const query = UnifiedHomeRegistrationsDocument
    const variables = unifiedHomeRegistrationVars
    const data = client.readQuery<
      UnifiedHomeRegistrationsQueryResult["data"],
      UnifiedHomeRegistrationsQueryVariables
    >({
      query,
      variables
    })
    const nextData: UnifiedHomeRegistrationsQueryResult["data"] = {
      me: {
        ...data?.me,
        registrations: {
          ...data?.me?.registrations,
          // If we should archive, then we should remove the registration from the
          // user's home screen, otherwise, add back.
          edges: shouldArchive
            ? data?.me?.registrations?.edges?.filter(
                (edge) => edge?.node?.id !== registration.id
              )
            : [
                ...(data?.me?.registrations?.edges || []),
                {
                  node: registration
                }
              ]
        }
      }
    }

    client.writeQuery({
      query,
      variables,
      data: nextData
    })

    // TODO: we should also update the query the lists the user's soft-archived registrations,
    // performing the opposite action.
  }

  const [mutate, state] = useSoftArchiveRegistrationMutation({
    variables: shouldArchive
      ? {
          id: registration.id,
          softArchivedTime: new Date(),
          // Archiving a registration should turn off the user's notifications for that registration.
          sendDiscussionNotifications: false,
          sendDueNotifications: false
        }
      : {
          id: registration.id,
          softArchivedTime: null,
          // Archiving a registration should turn on the user's notifications for that registration.
          sendDiscussionNotifications: true,
          sendDueNotifications: true
        },
    optimisticResponse: {
      updateRegistration: shouldArchive
        ? {
            ...registration,
            softArchivedTime: new Date(),
            send_discussion_notifications: false,
            send_due_notifications: false,
            __typename: "Registration"
          }
        : {
            ...registration,
            softArchivedTime: null,
            send_discussion_notifications: true,
            send_due_notifications: true,
            __typename: "Registration"
          }
    },
    onError() {
      // Restore cache when mutation fails.
      updateCache(!shouldArchive)
    }
  })

  const handleMutate: SoftArchiveRegistrationMutationFn = (options) => {
    updateCache(shouldArchive)
    return mutate(options)
  }

  return [handleMutate, state]
}

export default useSoftArchiveRegistration
