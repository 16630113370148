import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import View from "@pathwright/ui/src/components/ui/View"
import classnames from "classnames"
import PropTypes from "prop-types"
import { useState } from "react"
import styled from "styled-components"
import copyToClipboard from "../copy/copyToClipboard"
import Fullscreen from "../fullscreen/Fullscreen"

const StyledWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  pointer-events: none !important;

  > * {
    pointer-events: auto;
  }

  h4 + p {
    text-align: left;
    word-break: break-word;
  }
`

const StyledErrorMessage = styled(View.Secondary)`
  position: relative;
  font-family: monospace;
  text-align: left;
  margin-top: 10px !important;
  margin-bottom: 25px !important;
  color: black;
  max-width: 100%;
  overflow: auto;

  .SubmitSizer {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
  }

  /* only display the copy button when hovering over the error message */
  @media (hover: hover) {
    .ErrorCopy {
      display: none;
    }

    &:hover .ErrorCopy {
      display: block;
    }
  }
`

const Error = ({
  className,
  icon,
  errorTitle,
  errorMessage,
  errorDetails,
  email,
  primaryAction,
  secondaryAction,
  ...blankSlateProps
}) => {
  const [errorMessageNode, setErrorMessageNode] = useState(null)

  let mailtoLink = null
  if (errorMessageNode && email) {
    mailtoLink = `mailto:${email}?${new URLSearchParams({
      subject: errorMessage || "Error report",
      body: errorMessageNode.outerText
    }).toString()}`
  }

  return (
    <StyledWrapper className={classnames("Error", className)}>
      <View.Primary rounded style={{ position: "relative", maxWidth: "100%" }}>
        <BlankSlate
          {...blankSlateProps}
          icons={null}
          icon={icon}
          stack
          heading={errorTitle}
          body={errorMessage}
          primaryAction={
            primaryAction
              ? {
                  ...primaryAction,
                  styleType: "primary",
                  href: mailtoLink,
                  target: "_blank",
                  size: "large",
                  brand: true
                }
              : null
          }
          secondaryAction={
            secondaryAction
              ? {
                  ...secondaryAction,
                  styleType: "text",
                  brand: true
                }
              : null
          }
          preActionContent={
            !!errorDetails && (
              <StyledErrorMessage padding>
                <SubmitButton
                  className="ErrorCopy"
                  styleType="primary"
                  inverted
                  brand={false}
                  size="medium"
                  onClick={() =>
                    Promise.resolve(copyToClipboard(errorMessageNode.outerText))
                  }
                >
                  Copy text
                </SubmitButton>
                <div ref={setErrorMessageNode}>{errorDetails}</div>
              </StyledErrorMessage>
            )
          }
        />
      </View.Primary>
    </StyledWrapper>
  )
}

Error.displayName = "Error"

Error.propTypes = {
  ...BlankSlate.propTypes,
  email: PropTypes.string,
  errorTitle: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  primaryAction: PropTypes.object.isRequired,
  secondaryAction: PropTypes.object
}

Error.defaultProps = {
  icon: "computer-frown"
}

export const FullscreenError = (props) => (
  <Fullscreen>
    <Error {...props} />
  </Fullscreen>
)

export default Error
