import { Button, VStack } from "@chakra-ui/react"
import type { IconName } from "@pathwright/pathicons"
import Pathicon from "../../pathicon/Pathicon"
import { motion } from "framer-motion"
import { BORDER_RADIUS } from "../constants"

const containerAnimation = {
  initial: {
    opacity: 0,
    x: 50
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.3,
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
}

const itemAnimation = {
  initial: {
    y: 20,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1
  }
}

const ToolbarItem = ({ icon, label }: { icon: IconName; label: string }) => {
  return (
    <Button
      as={motion.div}
      variant={"unstyled"}
      variants={itemAnimation}
      initial={"initial"}
      animate={"animate"}
      textDecoration={"none"}
      aria-label={label}
      py={1}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      _hover={{ bgColor: "blackAlpha.200", cursor: "pointer" }}
    >
      <Pathicon icon={icon} />
    </Button>
  )
}

type FloatingToolbarProps = {
  position?: "static" | "relative" | "absolute" | "fixed" | "sticky"
  top?: number | string
  right?: number | string
  left?: number | string
}

const FloatingToolbar = ({
  position = "fixed",
  top = "25%",
  left,
  right = "28%"
}: FloatingToolbarProps) => {
  const SPACING = 2
  return (
    <VStack
      as={motion.div}
      w={14}
      // h={32}
      spacing={SPACING}
      py={SPACING}
      position={position}
      top={top}
      // py={4}
      right={right}
      left={left}
      borderRadius={BORDER_RADIUS}
      alignItems="center"
      variants={containerAnimation}
      initial={"initial"}
      animate={"animate"}
      whileHover={"hover"}
      boxShadow={"base"}
      bgColor={"blackAlpha.200"}
      _hover={{ shadow: "xl", bgColor: "whiteAlpha.300" }}
      zIndex={10}
    >
      <ToolbarItem icon="cards" label="Edit " />
      <ToolbarItem icon="gear" label="Edit" />
      <ToolbarItem icon="skip-back-2" label="Edit" />
    </VStack>
  )
}

export default FloatingToolbar
