import { Box, BoxProps } from "@chakra-ui/react"
import { useEffect, useRef } from "react"
import { usePreviousDistinct } from "react-use"
import { useUnifiedHomeRegistrationsLazyQuery } from "../../api/generated"
import PathCreatorFlow, {
  usePathCreatorFlow
} from "../../path/create/PathCreatorFlow"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import useUnifiedHomeRegistrationVars from "../useUnifiedHomeRegistrationQueryVars"

const AddPathButton = () => {
  const { me } = usePathwrightContext()
  const ref = useRef<HTMLDivElement>(null)

  // Just get it working stop-gap for reloading user registrations
  // after successfully completing PathCreatorFlow.
  // NOTE: we could possibly(?) use the onComplete state prop to update the cache instead.
  const [load] = useUnifiedHomeRegistrationsLazyQuery({
    variables: useUnifiedHomeRegistrationVars(),
    fetchPolicy: "network-only"
  })

  const flowProps = usePathCreatorFlow({
    onComplete: async () => {
      // Once the flow is complete, let's reload the user's registrations.
      await load()
    }
  })

  const { pathCreatorState } = flowProps

  // When PathCreatorFlow is in "initial" state, we position
  // it sticky to the bottom.
  const stickyProps: BoxProps =
    pathCreatorState.status === "initial"
      ? {
          position: "sticky",
          bottom: "40px",
          backdropFilter: "blur(20px)",
          zIndex: 10
        }
      : {}

  // When PathCreatorFlow goes from "initial" status to some other status,
  // we know the user has initially interacted with the element, so let's
  // scroll it into view. This works because the item is no longer sticky.
  // Otherwise, if it were sticky, the item would only scroll the container partially.
  const prevPathCreatorStatus = usePreviousDistinct(pathCreatorState.status)
  useEffect(() => {
    if (prevPathCreatorStatus === "initial") {
      ref.current?.scrollIntoView({ behavior: "smooth" })
    }
  }, [pathCreatorState.status])

  return (
    <Box
      ref={ref}
      w="100%"
      // TODO: ensure we include enough height for the select menus
      // (calculate in some way)?
      mb="400px"
      {...stickyProps}
    >
      <PathCreatorFlow targetUserId={me.id!} {...flowProps} />
    </Box>
  )
}

export default AddPathButton
