import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { motion } from "framer-motion"
import { useState, useEffect } from "react"

import { BORDER_RADIUS } from "../../constants"

const MotionBox = motion(Box)

type ProgressBarProps = {
  progressValue: number
  children?: React.ReactNode
}

const ProgressBar = ({ progressValue, children }: ProgressBarProps) => {
  const [progress, setProgress] = useState(0)
  const [opacity, setOpacity] = useState(0)

  const value = progressValue * 100

  useEffect(() => {
    setProgress(value)
  }, [value])

  return (
    <Box w="full" h="full" borderRadius={BORDER_RADIUS}>
      <MotionBox
        position={"relative"}
        zIndex={10}
        backgroundColor={progress === 100 ? "green.400" : "blue.500"}
        borderRadius={BORDER_RADIUS}
        initial={{ width: 0, height: 16 }}
        animate={{ width: `${progress}%`, transition: { duration: 1.5 } }}
        whileHover={{
          height: 32,
          transition: {
            duration: 0.2,
            type: "spring",
            stiffness: 800,
            damping: 50,
            mass: 0.5
          }
        }}
        onMouseEnter={() => setOpacity(1)}
        onMouseLeave={() => setOpacity(0)}
      >
        <Text
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{
            opacity: opacity,
            transition: {
              duration: 0.1
            }
          }}
          py={2}
          px={3}
          fontSize={"xs"}
          fontWeight={"bold"}
          color={"whiteAlpha.700"}
          textAlign={"right"}
        >
          {Math.floor(progress)}%
        </Text>
      </MotionBox>
    </Box>
  )
}

export default ProgressBar
