import { usePathCreatorRandomResourceImageLazyQuery } from "../../api/generated"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

// Get random resource image based on the school theme.
function useLazyRandomThemeResourceImage(
  options?: Parameters<typeof usePathCreatorRandomResourceImageLazyQuery>[0]
) {
  const { school } = usePathwrightContext()

  return usePathCreatorRandomResourceImageLazyQuery({
    variables: {
      themeType: school.theme?.theme_type
    },
    fetchPolicy: "network-only",
    ...options
  })
}

export default useLazyRandomThemeResourceImage
