import gql from "graphql-tag"
import { ResourceFragmentDoc } from "../../api/generated"
import AUTHOR_FRAGMENT from "../../author/graphql/author-fragment"
import PUBLISHER_FRAGMENT from "../../publisher/graphql/publisher-fragment"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"

export default gql`
  query StoreResourcesQuery(
    $cursor: String
    $first: Int!
    $role: RoleFilter
    $search: String
    $author_ids: IDFilter
    $category_ids: IDFilter
    $publisher_ids: IDFilter
    $resource_types: ResourceTypeFilter
    $visibility: ResourceVisibility
    $school_id: Int!
    $licensee_school_id: Int!
    $include_school_license: Boolean!
    $user_registered: IDFilter
  ) {
    context(school_id: $school_id) {
      school {
        ...School
        resources(
          first: $first
          after: $cursor
          role: $role
          search: $search
          visibility: $visibility
          author_ids: $author_ids
          category_ids: $category_ids
          publisher_ids: $publisher_ids
          resource_types: $resource_types
          user_registered: $user_registered
        ) {
          total
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              ...Resource
              curriculum: group(type: curriculum) {
                id
              }
              publisher {
                ...Publisher
              }
              authors(first: 2) {
                total
                edges {
                  node {
                    ...Author
                  }
                }
              }
              school_licenses(
                first: 1
                licensee_school_id: $licensee_school_id
              ) @include(if: $include_school_license) {
                edges {
                  node {
                    id
                  }
                }
              }
              # NOTE: Only using for Belltower. If total is 0 we can display "Coming Soon"
              license_offerings(first: 1, licensing_mode: school_licensing) {
                total
              }
            }
          }
        }
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${PUBLISHER_FRAGMENT}
  ${ResourceFragmentDoc}
  ${AUTHOR_FRAGMENT}
`
