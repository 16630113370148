import isObject from "lodash/isObject"
import path from "path-browserify"

export const isAbsoluteUrl = (to) => {
  let pathname = ""

  if (to) {
    if (typeof to === "string") pathname = to
    if (isObject(to)) pathname = to.pathname
  }

  if (pathname) return pathname.indexOf("://") > -1
  return false
}

export const isLeftClickEvent = (event) => event.button === 0

export const isModifiedEvent = (event) => {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

export const isEmptyObject = (obj) => {
  return obj ? Object.keys(obj).length === 0 : false
}

export const getUrlObjFromRoute = (route) => {
  if (!route) return

  let urlStr = ""

  if (typeof route === "object") {
    // Convert query obj to search string.
    let searchStr = new URLSearchParams(
      route.query || route.search || ""
    ).toString()
    // Only prepend question mark when the search string doesn't start with one already.
    if (searchStr && !searchStr.startsWith("?")) searchStr = `?${searchStr}`
    urlStr = route.pathname + searchStr
  } else {
    urlStr = route
  }

  // Transform route into a URL object in order to extract the url parts.
  const url = new URL(path.join(window.location.origin, urlStr))

  return url
}

export const getUrlFromRoute = (route) => {
  if (!route) return

  // Get url parts to form route.
  const { pathname, search, hash } = getUrlObjFromRoute(route)
  return pathname + search + hash
}

export const getStateFromRoute = (route) => {
  if (!route) return null
  if (typeof route !== "object") return null
  return route.state
}
