import Alert from "@pathwright/ui/src/components/alert/Alert"
import View from "@pathwright/ui/src/components/ui/View"
import AdaptedAuthContainer from "@pathwright/web/src/modules/auth/AdaptedAuthContainer"
import SignUpOrIn from "@pathwright/web/src/modules/auth/SignUpOrIn"
import { SIGN_UP } from "@pathwright/web/src/modules/auth/constants"
import useSpaceHasFeature from "@pathwright/web/src/modules/lib/useSpaceHasFeature"
import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import joinStore from "join/store/join-store"
import get from "lodash/get"
import { observer } from "mobx-react"
import { getStoreUrl } from "store/utils"
import { resetClientStore } from "../../ClientRoutes"
import "./Join.css"
import RegisterHeader from "./RegisterHeader"

const SignUpOrInContainer = ({
  inviteToken,
  providedEmail,
  providedFirstName,
  providedLastName
}) => {
  const { me } = usePathwrightContext()
  const hasAuthPw3 = useSpaceHasFeature("auth__platform")

  // The user creates their school membership by clicking "Continue"
  // thereby completing the flow. At this point we need to refetch queries
  // since they now have new perms.
  const onComplete = () => {
    window.App.navigate(getStoreUrl())
    // Refetch Apollo queries.
    resetClientStore()
    // Refetch bootstrapped data.
    // This will check the session and trigger bootstrapping the school store. Bit messy, but it works.
    window.App.getStore("auth").action.checkSession()
  }

  return hasAuthPw3 ? (
    <AdaptedAuthContainer
      inviteToken={inviteToken}
      userPropertyDefaults={{
        email: providedEmail,
        firstName: providedFirstName,
        lastName: providedLastName
      }}
      onComplete={onComplete}
      promptUserToJoinSpace
    />
  ) : (
    <SignUpOrIn
      initialView={SIGN_UP}
      inviteToken={inviteToken}
      providedEmail={providedEmail}
      providedFirstName={providedFirstName}
      providedLastName={providedLastName}
      onContinue={
        me && me.membership ? () => window.App.navigate(getStoreUrl()) : null
      }
      // we don't currently have a mutation for creating user membership using token apart froming signing in or signing up
      // renderSubmit={
      //   me && !me.membership ? (
      //     <SubmitButton
      //       onSubmit={async () => {
      //         await joinStore.action.loadInvitation
      //         window.App.navigate(getStoreUrl())
      //       }}
      //       styleType="primary"
      //       size="large"
      //       hideSuccess
      //     >
      //       {t(`Join as {{ membershipRoleLabel }}`, {
      //         membershipRoleLabel: (() => {
      //           switch (invitation.role) {
      //             case MEMBERSHIP_TYPE_STUDENT:
      //               return t("student")
      //             case MEMBERSHIP_TYPE_EDITOR:
      //               return t("library editor")
      //             case MEMBERSHIP_TYPE_ADMIN:
      //               return t("admin")
      //           }
      //         })()
      //       })}
      //     </SubmitButton>
      //   ) : null
      // }
    />
  )
}

const Join = () => {
  const { invite_token, invitation, invitation_error } = joinStore
  const { to_email, first_name, last_name } = get(invitation, "extra", {})

  return (
    <View.Primary className="Join">
      <RegisterHeader
        closeTo={getStoreUrl()}
        invitation={invitation && invitation.extra ? invitation : null}
      />
      <div className="Join__error-wrapper">
        <Alert
          error={invitation_error}
          onClear={joinStore.action.clearErrors}
        />
      </div>
      <SignUpOrInContainer
        inviteToken={invite_token}
        providedEmail={to_email}
        providedFirstName={first_name}
        providedLastName={last_name}
      />
    </View.Primary>
  )
}

Join.displayName = "Join"

export default observer(Join)
