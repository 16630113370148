import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import SignupDefault from "@pathwright/web/src/modules/signup/SignupDefault"
import SignupWithStore from "@pathwright/web/src/modules/signup/SignupWithStore"
import { PATHWRIGHT_SCHOOL_ID } from "lib/core/constants"

const SignupContainer = ({ location, match }) => {
  const { school } = usePathwrightContext()
  const isBelltower = parseInt(match.params.licensorId) === 13279 // licensor is belltower
  const utmParameters = {
    utm_source: location.query.utm_source || null,
    utm_medium: location.query.utm_medium || null,
    utm_campaign: location.query.utm_campaign || null
  }
  const belltowerLicensorId = isBelltower
    ? parseInt(match.params.licensorId)
    : null
  // List of resources to copy to the created school.
  const copy = location.query.copy ? location.query.copy.split(",") : []

  // assume that if this is Pathwright Grow and we're not licensing through Belltower
  // that the user should signup for a Pathwright Grow account
  if (school.id === PATHWRIGHT_SCHOOL_ID && !isBelltower) {
    return (
      <SignupDefault
        initialSchoolType={location.query.ref}
        utmParameters={utmParameters}
        copy={copy}
        stripeApiKey={
          window.App.getStore("pathwrightAccount").getState().payment_key
        }
      />
    )
  }

  // otherwise signup through SchoolCurriculumSubscriptionPlan (if licensorId)
  // or Store of current school (if !licensorId)
  return (
    <SignupWithStore
      licensorId={belltowerLicensorId}
      initialSchoolType={belltowerLicensorId ? "ministry" : location.query.ref}
      utmParameters={utmParameters}
      copy={copy}
    />
  )
}

export default SignupContainer
