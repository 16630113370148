import useIntersection from "@pathwright/ui/src/components/hooks/useIntersection"
import { useScrollContext } from "@pathwright/ui/src/components/scroll/ScrollView"
import get from "lodash/get"
import PropTypes from "prop-types"
import React, { useContext, useRef, useState } from "react"
import { useDiscussionsQuery } from "../../api/generated"
import useControlledTagsQueryParam from "../../tag/useControlledTagsQueryParam"
import { flattenEdges, usePaginator } from "../../utils/apollo"
import { discussionContextPropType } from "../propTypes"
import {
  DISCUSSION_CONTROL_FILTER_ALL,
  DISCUSSION_CONTROL_SORT_RECENTLY_ACTIVE,
  POSSIBLE_FILTERS,
  POSSIBLE_SORTS,
  getDiscussionsQueryVariables
} from "./utils"

export const DiscussionListContext = React.createContext()

export const useDiscussionListContext = () => useContext(DiscussionListContext)

const DiscussionListContainer = ({
  children,
  context,
  filter: filterProp,
  sort: sortProp,
  onStartDiscussion,
  onSelectDiscussion,
  filterBySelectedDiscussionTag,
  pageSize,
  initialLoadingCount,
  showReplyPrompt
}) => {
  const scrollContext = useScrollContext()
  // we'll check if the List node has entered the containing ScrollView/document before initiating the query
  const [setIntersectionNode, intersection, hasIntersected] = useIntersection({
    root: scrollContext && scrollContext.scrollNode
  })

  const [filter, setFilter] = useState(filterProp)
  const [sort, setSort] = useState(sortProp)
  // can be used for resetting the discussion filter and sort to the initial
  // state, say, after adding a new discussion
  const initialFilter = useRef(filter).current
  const initialSort = useRef(sort).current

  const { tags, setTags, tagIdFilter } = useControlledTagsQueryParam({
    context,
    queryParamKey: "topics"
  })

  const discussionsQuery = useDiscussionsQuery({
    variables: getDiscussionsQueryVariables({
      context,
      tagIdFilter,
      filter,
      sort,
      first: pageSize
    }),
    skip: !hasIntersected,
    fetchPolicy: "cache-and-network"
  })

  const { data, loading, refetch } = discussionsQuery

  const { loadMore, hasMore, loadingMore } = usePaginator({
    data: discussionsQuery, // hacky
    path: "discussions"
  })

  const discussions = flattenEdges(get(data, "discussions"))

  // number of items loading will be at most the page size
  const loadingCount =
    typeof initialLoadingCount === "number" ? initialLoadingCount : pageSize

  // if paginating, then the number of items loading will be at most the page size, and at least the remaining items yet to be loaded
  const loadingMoreCount = discussions
    ? Math.min(discussions.total - discussions.length, pageSize)
    : 0

  const currentLoadingCount = loadingMore
    ? loadingMoreCount
    : loading
    ? loadingCount
    : 0

  return (
    <DiscussionListContext.Provider
      value={{
        context,
        tags,
        tagIdFilter,
        filter,
        sort,
        initialFilter,
        initialSort,
        onFilterTags: setTags,
        onFilter: setFilter,
        onSort: setSort,
        onStartDiscussion,
        onSelectDiscussion,
        discussionsQuery,
        discussions,
        refetch,
        loading,
        loadMore,
        hasMore,
        loadingMore,
        loadingCount: currentLoadingCount,
        setListNode: setIntersectionNode,
        showReplyPrompt,
        filterBySelectedDiscussionTag
      }}
    >
      {children}
    </DiscussionListContext.Provider>
  )
}

DiscussionListContainer.displayName = "DiscussionListContainer"

DiscussionListContainer.propTypes = {
  context: discussionContextPropType.isRequired,
  filter: PropTypes.oneOf(POSSIBLE_FILTERS),
  sort: PropTypes.oneOf(POSSIBLE_SORTS),
  onStartDiscussion: PropTypes.func,
  onSelectDiscussion: PropTypes.func,
  pageSize: PropTypes.number,
  initialLoadingCount: PropTypes.number,
  showReplyPrompt: PropTypes.bool,
  filterBySelectedDiscussionTag: PropTypes.bool
}

DiscussionListContainer.defaultProps = {
  initialLoadingCount: null,
  pageSize: 15,
  filter: DISCUSSION_CONTROL_FILTER_ALL,
  sort: DISCUSSION_CONTROL_SORT_RECENTLY_ACTIVE,
  showReplyPrompt: false,
  // Enables clicking a tag on a discussion to filter the whole list
  // by that discussion tag.
  filterBySelectedDiscussionTag: false
}

export default DiscussionListContainer
