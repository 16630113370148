import { Avatar, AvatarGroup, HStack, Text } from "@chakra-ui/react"
import { motion } from "framer-motion"
import {
  GroupListItemFragment,
  useUnifiedHomeCohortMembersQuery
} from "../../api/generated"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { tiltAnimation } from "../constants"

type GroupAvatarsProps = {
  group: GroupListItemFragment
  showGroupName?: boolean
  fontColor?: "whiteAlpha.900" | "blackAlpha.900"
  noOfLines?: number
}

// Dumb component that accepts the group.
export const GroupAvatars = ({
  group,
  showGroupName,
  fontColor = "whiteAlpha.900",
  noOfLines
}: GroupAvatarsProps) => {
  const { me } = usePathwrightContext()
  const myID = me.id

  return (
    <HStack
      w="full"
      // py={0.5}
      gap={1}
      pos="relative"
      zIndex={10}
    >
      <AvatarGroup size="xs" max={3} spacing={"-0.5rem"}>
        <Avatar
          as={motion.div}
          // whileHover={{
          //   ...tiltAnimation
          // }}
          name={me.display_name as string}
          src={me.profile?.image as string}
          borderWidth={0}
          zIndex={10}
          borderColor={fontColor}
        />
        {group.registrations?.edges?.map(
          (member: any) =>
            member.node?.user?.id !== myID && (
              <Avatar
                as={motion.div}
                // whileHover={{
                //   ...tiltAnimation
                // }}
                key={member.node?.id}
                name={member.node?.user?.display_name}
                src={member.node?.user?.profile?.image}
                borderWidth={0}
                zIndex={10}
                borderColor={fontColor}
              />
            )
        )}
      </AvatarGroup>
      {showGroupName && (
        <Text
          size="xs"
          fontWeight={"medium"}
          m={0}
          pl={2}
          color={fontColor}
          noOfLines={noOfLines}
        >
          {group.name}
        </Text>
      )}
    </HStack>
  )
}

type GroupAvatarsContainerProps = {
  groupId: number
} & Omit<GroupAvatarsProps, "group">

// Container component that queries the group by id.
const GroupAvatarsContainer = ({
  groupId,
  ...rest
}: GroupAvatarsContainerProps) => {
  const { data } = useUnifiedHomeCohortMembersQuery({
    variables: {
      groupId: groupId
    }
  })

  return data?.group ? <GroupAvatars group={data.group} {...rest} /> : null
}

export default GroupAvatarsContainer
