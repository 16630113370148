import gql from "graphql-tag"
import { SchoolSubscriptionFragmentDoc } from "../../api/generated"
import USER_FRAGMENT from "../../user/graphql/user-fragment"

export default gql`
  fragment Membership on Membership {
    id
    created
    last_seen
    last_active
    has_access
    access_revoked_time
    archived_time
    role
    consent_dtime
    consent_snapshot
    user {
      ...User
      school_subscription {
        ...SchoolSubscription
      }
    }
  }
  ${USER_FRAGMENT}
  ${SchoolSubscriptionFragmentDoc}
`
