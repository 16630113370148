import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react"
import {
  CheckIcon,
  ChevronDownIcon,
  SearchIcon,
  XIcon
} from "@pathwright/pathicons"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { Select, chakraComponents, createFilter } from "chakra-react-select"
import { validate as validateEmail } from "email-validator"
import omit from "lodash/omit"
import { useMemo } from "react"
import FocusLock from "react-focus-lock"
import PeopleItem from "./PeopleItem"
import { useSelectPeopleContext } from "./SelectPeopleContext"
import SelectQueuedPeople from "./SelectQueuedPeople"
import {
  getIsValidItem,
  getUserItemAlreadyAdded,
  getUserItemAlreadyAddedLabel
} from "./utils"

const tPrefix = "share_card.add_tab"

const SelectQueuedPeopleMenu = () => (
  <Box
    bg="white"
    borderRadius="md"
    border="1px solid"
    borderColor="gray.100"
    position="absolute"
    top="calc(100% + 8px)"
    width="100%"
  >
    <SelectQueuedPeople />
  </Box>
)

const getShowSelectButton = ({ selectProps }) =>
  Boolean(selectProps.menuIsOpen && selectProps.inputValue) ||
  // NOTE: basically checking isSearchable instead of !!queued.length.
  !selectProps.isSearchable

const SelectButton = (props) => {
  const {
    peopleSelection: { selected, queued, pending },
    addToPeopleSelection,
    itemsOfQueued
  } = useSelectPeopleContext()

  // Allow user to bulk select items that can be directly added to selection.
  // NOTE: the props.options are simply those options passed to the Select component.
  // They are not the filtered options, nor do we have access to the filtered options (why?).
  // So, we must filter them ourselves based on the current inputValue.
  const multiItems = props.options
    .filter((option) => ["email", "user"].includes(option.type))
    .filter((option) => !getUserItemAlreadyAdded(option))
    .filter(
      (option) =>
        // Emails can be added in comma-separated strings, which will not stand up to the filterOption.
        option.type === "email" ||
        props.selectProps.filterOption(
          // Passing expected option shape.
          {
            label: props.selectProps.getOptionLabel(option),
            value: props.selectProps.getOptionValue(option),
            data: option
          },
          props.selectProps.inputValue
        )
    )

  const items = queued.length ? pending : multiItems
  // The `sourceItems` are those items to compare against when determining
  // if the "Select {{ count }}" button can be enabled.
  const sourceItems = queued.length ? itemsOfQueued : props.options
  const showButton = getShowSelectButton(props)

  const canSelect = useMemo(() => {
    return (
      // Any positive number of items can be selected.
      items.length > 0 ||
      // If no items, check if the lack of items would result in a change in selection
      selected.filter((item) =>
        sourceItems.find((sourceItem) => sourceItem.meta.key === item.meta.key)
      ).length > 0
    )
  }, [selected, items]) // NOTE: items currently changes on every render...

  if (showButton) {
    const onClick = (e) => {
      addToPeopleSelection(items)
      props.selectProps.onMenuClose()
    }

    return (
      <Button
        cursor="pointer"
        gap=".4em"
        ml=".4em"
        size="sm"
        onClick={onClick}
        isDisabled={!canSelect}
        // Prevent closing the select menu before click event is fired!
        onMouseDown={(e) => e.preventDefault()}
      >
        <CheckIcon size="1em" /> Select {items.length}
      </Button>
    )
  }

  return null
}

const LoadingSpinner = () => (
  <Spinner
    thickness="2px"
    speed="0.65s"
    emptyColor="gray.200"
    color="inhert"
    size="sm"
  />
)

const customComponents = {
  DropdownIndicator: (props) => {
    const showDropdownIndicator = !getShowSelectButton(props)

    return showDropdownIndicator ? (
      <chakraComponents.DropdownIndicator {...props}>
        <ChevronDownIcon size="1em" color="inhert" />
      </chakraComponents.DropdownIndicator>
    ) : null
  },
  ClearIndicator: (props) => {
    const {
      itemsOfQueuedState: { loading: itemsOfQueuedLoading }
    } = useSelectPeopleContext()

    // Why would an interactive button be hidden?
    const componentProps = omit(props, ["innerProps.aria-hidden"])

    return (
      <chakraComponents.ClearIndicator {...componentProps}>
        {itemsOfQueuedLoading ? (
          <LoadingSpinner />
        ) : (
          <XIcon size="1em" color="inhert" />
        )}
      </chakraComponents.ClearIndicator>
    )
  },
  Option: ({ children, ...props }) => {
    const { t } = useTranslate()

    return (
      <chakraComponents.Option
        {...props}
        isDisabled={!getIsValidItem(props.data)}
      >
        <PeopleItem item={props.data} />
        {getUserItemAlreadyAdded(props.data) ? (
          <Text color="gray.500">
            {getUserItemAlreadyAddedLabel(props.data, t)}
          </Text>
        ) : null}
      </chakraComponents.Option>
    )
  },
  Control: ({ children, ...props }) => {
    const {
      itemsState: { loading: itemsLoading },
      peopleSelection: { queued }
    } = useSelectPeopleContext()

    const showSelectButton = getShowSelectButton(props)
    const componentProps = omit(props, ["innerProps.onTouchEnd"])

    // NOTE: only showing loading indicator when menu is open.
    const loading = itemsLoading && props.selectProps.menuIsOpen

    const searchIndicator = (
      <Flex alignItems="center">
        <Box display="flex" mx={2}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <SearchIcon size="1em" color="inhert" mx={2} />
          )}
        </Box>
      </Flex>
    )

    if (showSelectButton) {
      if (queued.length) {
        // In this case, ordering the children in a reverse order from
        // how we want them visually displayed. In the control styles, we
        // conditionally reverse the direction of the elements. This is so that
        // the ClearIndicator is positioned on the left side.
        return (
          <chakraComponents.Control {...componentProps}>
            <Flex alignItems="center">
              <SelectButton {...props} />
            </Flex>
            {children}
            {/* ClearIndicator rendered here */}
          </chakraComponents.Control>
        )
      }

      return (
        <chakraComponents.Control {...componentProps}>
          {searchIndicator}
          {children}
          <Flex alignItems="center">
            <SelectButton {...props} />
          </Flex>
        </chakraComponents.Control>
      )
    }

    return (
      <chakraComponents.Control {...componentProps}>
        {searchIndicator}
        {children}
      </chakraComponents.Control>
    )
  },
  // Hiding default LoadingIndicator as we handle that manually.
  LoadingIndicator: () => null
}

const chakraStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "gray.50" : "transparent",
    color: "gray.900"
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    border: "solid 1px",
    borderColor: "gray.100"
  }),
  placeholder: (provided) => ({
    ...provided,
    paddingLeft: "2px",
    fontSize: { base: "sm", md: null },
    noOfLines: 1
  }),
  control: (provided, state) => ({
    ...provided,
    padding: "0 .4em",
    border: "1px solid",
    borderColor: "gray.100",
    color: "gray.900",
    // HACK: reversing the direction of the control children let's us avoid a different,
    // less desirable hack for reordering the "x" icon to the left side.
    flexDirection:
      getShowSelectButton(state) && !state.selectProps.isSearchable
        ? "row-reverse"
        : "row"
  }),
  clearIndicator: (provided) => ({
    ...provided,
    m: 0,
    mr: 1
  })
}

export const useEmailOptions = (emailsStr) => {
  const {
    peopleSelection: { selected }
  } = useSelectPeopleContext()

  const emailOptions = useMemo(() => {
    const emails = [
      ...new Set(
        emailsStr
          .split(",")
          .map((email) => email.trim())
          .filter(Boolean)
      )
    ]

    if (emails.some((email) => validateEmail(email))) {
      return (
        emails
          // Filter out all emails that are invalid.
          .filter((email) => validateEmail(email))
          // Filter out emails that have already been selected.
          .filter(
            (email) =>
              !selected.find(
                (item) =>
                  item.type === "email" &&
                  item.meta.key.split("email:")[1] === email
              )
          )
          .map((email) => ({
            type: "email",
            people_count: 1,
            meta: {
              key: `email:${email}`,
              type: "email",
              title: email,
              _search: email
            }
          }))
      )
    } else {
      return []
    }
  }, [selected, emailsStr])

  return emailOptions
}

const useOptions = () => {
  const {
    items,
    search,
    peopleSelection: { selected, queued }
  } = useSelectPeopleContext()

  const itemOptions = useMemo(
    () =>
      items.filter(
        (item) =>
          ![...selected, ...queued].find(
            (selected) => selected.meta.key === item.meta.key
          )
      ),
    [items, selected, queued]
  )

  const emailOptions = useEmailOptions(search)
    // Filter out emails that match "user" items.
    .filter(
      (emailOption) =>
        !itemOptions.find(
          (item) =>
            item.type === "user" &&
            item.meta._search
              .toLowerCase()
              .includes(emailOption.meta._search.toLowerCase())
        )
    )

  const options = useMemo(
    () => [...itemOptions, ...emailOptions],
    [itemOptions, emailOptions]
  )

  return options
}

const filterOption = createFilter({
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: "any",
  stringify: (option) => option.data.meta._search,
  trim: true
})

const filterOptionByParsedSearch = (option, rawInput) => {
  // Only filter if receiving rawInput. Check if any of the comma-separated
  // values match the option.
  if (rawInput) {
    const searchTerms = rawInput
      ? rawInput
          .split(",")
          .map((str) => str.trim())
          .filter(Boolean)
      : []
    return searchTerms.some((searchTerm) => filterOption(option, searchTerm))
  }

  return true
}

const SelectPeople = () => {
  const {
    itemsState: { loadMore },
    search,
    setSearch,
    peopleSelection: { queued },
    addToPeopleSelection,
    removeFromPeopleSelection
  } = useSelectPeopleContext()

  const { tc } = useTranslate()
  const options = useOptions()

  const handleChange = (value) => {
    value = [].concat(value).filter(Boolean)

    if (value.length > queued.length) {
      const toAdd = value.slice(queued.length)
      addToPeopleSelection(toAdd)
    } else {
      removeFromPeopleSelection(queued[0])
    }
  }

  const handleInputChange = (value, { action }) => {
    // Explicitly controlling intputValue as react-select clears
    // the input value onBlur.
    if (action !== "input-blur" && action !== "menu-close") {
      setSearch(value)
    }
  }

  return (
    <FocusLock returnFocus persistentFocus={false} disabled={!queued.length}>
      <Box position="relative" zIndex={1}>
        <Select
          name={"add-people"}
          placeholder={`${tc(`${tPrefix}.Members, groups, or emails`)}...`}
          value={queued[0] || null}
          inputValue={search}
          options={options}
          filterOption={filterOptionByParsedSearch}
          getOptionLabel={(option) => option.meta.title}
          getOptionValue={(option) => option.meta.key}
          onChange={handleChange}
          onInputChange={handleInputChange}
          onMenuScrollToBottom={loadMore}
          components={customComponents}
          chakraStyles={chakraStyles}
          variant="unstyled"
          isClearable
          escapeClearsValue
          // TODO: isLoading seems to prevent the ClearIndicator from rendering.
          // isLoading={loading} // enables "Loading..." message when loading and there are no options yet.
          isSearchable={!queued.length}
          openMenuOnClick={!queued.length}
          menuIsOpen={queued.length ? false : undefined}
        />
        {!!queued.length && <SelectQueuedPeopleMenu />}
      </Box>
    </FocusLock>
  )
}

export default SelectPeople
