import { useQuery } from "@apollo/client"
import { Box } from "@chakra-ui/react"
import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import Error from "@pathwright/ui/src/components/error/Error"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import classnames from "classnames"
import styled from "styled-components"
import { FEATURE_LOCK_QUERY } from "../../lib/FeatureLock"
import CertificateContext from "../context/CertificateContext"
import CertificateFeatureLockGate from "../gating/CertificateFeatureLockGate"
import DisabledResourceCertificateGate from "../gating/DisabledResourceCertificateGate"
import useCertificatePreviewParam from "../hooks/useCertificatePreviewParam"
import CertificateContainer from "./CertificateContainer"
import CertificateCreator from "./CertificateCreator"
import CertificateNavigationBar from "./CertificateNavigationBar"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-top: 50px;
  overflow: scroll;
  min-width: 0;
  z-index: 1000000000;

  .CertificateManager {
    position: relative;
    overflow: visible;
    min-height: calc(100vh - 50px);
    background-color: transparent;
  }

  &.preview {
    padding-top: 0;
  }
`

const GateBox = ({ children }) => (
  <Box
    bg="whitesmoke"
    width={"min(100vw, 792px)"}
    minHeight={"min(100vh, 612px)"}
    position="relative"
    display="flex"
    alignItems="center"
    justifyContent="center"
    borderRadius={"20px"}
    m={2}
  >
    <Box maxW="400px">{children}</Box>
  </Box>
)

const ActiveCertificate = ({ className }) => {
  const [preview] = useCertificatePreviewParam()
  // Hacky, but prepping the feature lock query now, so we don't run into
  // a waterfall scenarios since the certificate context must load first.
  const featureLockQuery = useQuery(FEATURE_LOCK_QUERY)

  return (
    <CertificateContext>
      {({ certificate, resource, loading, error }) => (
        <Container
          className={classnames(
            "ActiveCertificate",
            "chakra-ui-reset-css",
            { preview: !!preview },
            className
          )}
        >
          <ThemeBackground />
          <CertificateNavigationBar />
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            backdropFilter="blur(10px)"
            backgroundColor="rgba(0,0,0,.5)"
          />
          {loading || featureLockQuery.loading ? (
            <LoadingCircle center inverted />
          ) : error ? (
            <Error errorMessage={error.message} />
          ) : (
            <CertificateFeatureLockGate>
              {({ gate }) =>
                gate ? (
                  <GateBox>{gate}</GateBox>
                ) : resource && !resource.has_certificate ? (
                  <GateBox>
                    <DisabledResourceCertificateGate />
                  </GateBox>
                ) : certificate ? (
                  <CertificateContainer />
                ) : (
                  <CertificateCreator />
                )
              }
            </CertificateFeatureLockGate>
          )}
        </Container>
      )}
    </CertificateContext>
  )
}

ActiveCertificate.displayName = "ActiveCertificate"

export default ActiveCertificate
