import { HStack, StackProps, Text } from "@chakra-ui/react"
import { PathiconProps } from "modules/pathicon/ExpensivePathicon"
import Pathicon from "../../../pathicon/Pathicon"

type SimpleButtonProps = {
  label: string
  icon?: PathiconProps["icon"]
  iconSize?: PathiconProps["size"]
  iconPosition?: "left" | "right"
  onClick?: () => void
  children?: React.ReactNode
  colorScheme?: "blackAlpha" | "whiteAlpha"
} & StackProps

const SimpleButton = ({
  label,
  icon,
  iconSize,
  iconPosition,
  onClick,
  colorScheme = "blackAlpha",
  ...props
}: SimpleButtonProps) => {
  return (
    <HStack
      {...props}
      py={1}
      px={4}
      textDecoration="none"
      color={colorScheme === "whiteAlpha" ? "whiteAlpha.900" : "blackAlpha.900"}
      onClick={onClick}
      flexDirection={iconPosition === "right" ? "row-reverse" : "row"}
    >
      <Text
        pl={iconPosition === "right" ? 0 : 1}
        m={0}
        fontSize="sm"
        fontWeight={"semibold"}
        color={
          colorScheme === "whiteAlpha" ? "whiteAlpha.900" : "blackAlpha.900"
        }
      >
        {label}
      </Text>
      {icon && <Pathicon icon={icon} size={iconSize} />}
    </HStack>
  )
}

export default SimpleButton
