// ** Notes on Layering (z-index) in the Blocks editor **
// Layering is a tricky issue because our blocks follow a WYSIWYG
// but also render block-level tools (ex: move, delete, style).
// This means that contextual menus and tools can easily conflict with each other.

export const EDITOR_LAYERING = {
  // Block controls (move, delete, styles menu, block selector, etc.) should layer above Block content.
  CONTROLS: 101,

  // Elevate the baseline z-index of a block while it is focused.
  FOCUSED_BLOCK: 102,

  // When a block is hovered, its AddBlock component (plus button) should be above tools and content.
  HOVERED_ADD_BLOCK: 103,

  // When the block is hovered, its BlockControls (ex: styles menu) should layer above the AddBlock buttons.
  HOVERED_BLOCK_CONTROLS: 104,

  // This ensures that when the BlockSelector is open, it will layer above the BlockControls.
  OPEN_BLOCK_SELECTOR: 105,

  // This ensures that when the BlockSelector is open, the AddBlocks icon (plus/x) layers above the BlockSelector.
  OPEN_BLOCK_SELECTOR_ADD_BLOCK: 106,

  // The Delete Prompt overlay should appear over everything within a block.
  DELETE_PROMPT: 110,

  // Ensures that the Content Editor controls (ex: Publish button) appears over all blocks.
  CONTENT_EDITOR_CONTROLS: 200
}

export type EDITOR_MODE_TYPE = "edit" | "view"

export const EDITOR_MODE = {
  EDIT: "edit",
  VIEW: "view"
} as const

// In-state actions
export const ERROR = "ERROR"
export const GET_STATE = "GET_STATE"
export const TOGGLE_MODE = "TOGGLE_MODE"
export const START_PUBLISH = "START_PUBLISH"
export const START_DISCARD_DRAFT = "START_DISCARD_DRAFT"
export const ADD_BLOCK = "ADD_BLOCK"
export const SET_COPIED_BLOCK = "SET_COPIED_BLOCK"
export const CLEAR_COPIED_BLOCK = "CLEAR_COPIED_BLOCK"
export const PASTE_BLOCK = "PASTE_BLOCK"
export const MOVE_BLOCK_UP = "MOVE_BLOCK_UP"
export const MOVE_BLOCK_DOWN = "MOVE_BLOCK_DOWN"
export const DELETE_BLOCK = "DELETE_BLOCK"
export const CLEAR_DELETE_BLOCK = "CLEAR_DELETE_BLOCK"
export const UPDATE_BLOCK_DATA = "UPDATE_BLOCK_DATA"
export const UPDATE_BLOCK_LAYOUT = "UPDATE_BLOCK_LAYOUT"
export const UPDATE_BLOCK_STYLE = "UPDATE_BLOCK_STYLE"
export const RESET = "RESET"
export const SET_CHANGES = "SET_CHANGES"

// Syncer actions
export const START_SYNCING = "START_SYNCING"
export const STOP_SYNCING = "STOP_SYNCING"
export const SYNCED_PUBLISH = "SYNCED_PUBLISH"
export const SYNCED_DISCARD_DRAFT = "SYNCED_DISCARD_DRAFT"
export const SYNCED_BLOCKS = "SYNCED_BLOCKS"
export const SYNCED_ADD_BLOCK = "SYNCED_ADD_BLOCK"
export const SYNCED_PASTE_BLOCK = "SYNCED_PASTE_BLOCK"
export const SYNCED_UPDATE_BLOCK = "SYNCED_UPDATE_BLOCK"
