import { withTranslate } from "@pathwright/ui/src/components/"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import getStripePaymentPortalHref from "@pathwright/web/src/modules/stripe/utils/getStripePaymentPortalHref"
import { withUserCommunity } from "@pathwright/web/src/modules/user/UserCommunityContext"
import ConnectStores from "lib/core/store/components/ConnectStores"
import { useEffect } from "react"
import ProfileFormContainer from "./ProfileFormContainer"

const linkData = {
  billing: {
    icon: "credit-card",
    label: "Billing",
    to: "/manage/profile/billing/"
  },
  history: {
    icon: "credit-card",
    label: "View Payment History",
    to: "/manage/profile/history/"
  },
  paymentPortal: {
    icon: "credit-card",
    label: "Manage subscription"
  },
  change_password: {
    icon: "unlock",
    label: "Change Password",
    to: "/manage/profile/password/"
  },
  create_password: {
    icon: "unlock",
    label: "Create Password",
    to: "/manage/profile/password/"
  },
  notifications: {
    icon: "gear",
    label: "Manage Notifications",
    to: "/manage/profile/notifications/"
  }
}

const ProfileCard = (props) => {
  const {
    card,
    school,
    loadUserSubscription,
    showStatus,
    showLeaveStatus,
    statusMessage,
    statusLeaveMessage,
    canManageSubscription,
    canManageNotificationSettings,
    isCommunityGroupOwner,
    communityGroupSubscriptions,
    t
  } = props

  const { me } = usePathwrightContext()
  useEffect(() => {
    loadUserSubscription()
  }, [])

  return (
    <Card
      title={t("profile.label")}
      showStatus={showStatus}
      showLeaveStatus={showLeaveStatus}
      statusMessage={statusMessage}
      statusLeaveMessage={statusLeaveMessage}
      card={card}
    >
      <ProfileFormContainer
        onPristine={() => card.setCardDirty(false)}
        onDirty={() => card.setCardDirty(true)}
        pathwright_login_enabled={props.pathwright_login_enabled}
      />
      <CardBlock />
      {canManageNotificationSettings && (
        <CardLink
          {...linkData.notifications}
          label={t("profile.notifications")}
        />
      )}
      {communityGroupSubscriptions.length ? (
        isCommunityGroupOwner ? (
          <CardLink
            {...linkData.paymentPortal}
            href={getStripePaymentPortalHref(school)}
          />
        ) : (
          <CardLink {...linkData.paymentPortal} to="/subscribe" />
        )
      ) : canManageSubscription ? (
        <CardLink {...linkData.billing} label={t("profile.billing")} />
      ) : (
        <CardLink {...linkData.history} label={t("profile.history")} />
      )}
      {props.pathwright_login_enabled ? (
        me.no_password_set ? (
          <CardLink
            {...linkData.create_password}
            label={t("profile.create_password")}
          />
        ) : (
          <CardLink
            {...linkData.change_password}
            label={t("profile.password")}
          />
        )
      ) : null}
    </Card>
  )
}

ProfileCard.displayName = "ProfileCard"

const mapStateToProps = function (state, ownProps) {
  const { t } = ownProps
  const { user_loading, is_saving_user, user_success } =
    state.profileStore.state
  const { loadUserSubscription } = state.profileStore.action
  const { subscription_plans, integrations, school } = state.schoolStore.state
  const { subscription } = state.subscribeStore.state
  const canManageNotificationSettings = school.has_consent_description

  const canManageSubscription =
    (subscription_plans && subscription_plans.length) ||
    (subscription && subscription.id)
  const { pathwright_login_enabled } = integrations.sso
  const showStatus = user_loading || is_saving_user
  const statusMessage = (() => {
    if (user_loading) {
      return t("profile.loading")
    } else if (is_saving_user) {
      return t("profile.saving")
    }
  })()
  const showLeaveStatus = user_success != null
  const statusLeaveMessage = user_success ? t("profile.saved") : undefined

  const props = _.assign(
    {},
    {
      school,
      loadUserSubscription,
      subscription_plans,
      showStatus,
      statusMessage,
      showLeaveStatus,
      statusLeaveMessage,
      pathwright_login_enabled,
      canManageSubscription,
      canManageNotificationSettings
    },
    ownProps
  )
  return props
}

const stores = [
  {
    store: "profile",
    watch: ["user_loading", "is_saving_user", "user_success"]
  },
  { store: "school", watch: false },
  { store: "subscribe", watch: false }
]

export default withTranslate(
  withUserCommunity(ConnectStores(ProfileCard, stores, {}, mapStateToProps))
)
