import { useMutation } from "@apollo/client"
import { Button, Heading, Text, VStack } from "@chakra-ui/react"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { Link as ReactRouterLink } from "react-router-dom"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { RESOURCE_TYPE_LABELS } from "../../resource/constants"
import UPDATE_RESOURCE_MUTATION from "../../resource/manage/graphql/update-resource-advanced-mutation"
import { canAdministrate } from "../../user/permissions"
import { useCertificateContext } from "../context/CertificateContext"

// Delays accepting the resource until mutation is called.
export const useUpdateResourceCertificateEnabled = ({ enable, ...rest }) => {
  const has_certificate = enable
  const certificate_generator_class = enable ? "CertificateGenerator" : ""

  const [mutation, state] = useMutation(UPDATE_RESOURCE_MUTATION, {
    variables: {
      // Enables the certificate.
      certificate_generator_class: enable ? "CertificateGenerator" : ""
    },
    ...rest
  })

  function mutate(resource) {
    return mutation({
      variables: {
        // Required variables.
        id: resource.id,
        name: resource.name,
        resource_type: RESOURCE_TYPE_LABELS[resource.resource_type]
      },
      optimisticResponse: {
        __typename: "Mutations",
        updateResource: {
          ...resource,
          has_certificate,
          certificate_generator_class,
          __typename: "Resource"
        }
      }
    })
  }

  return [mutate, state]
}

// Can accept the resource in initial hook props.
export const useUpdateResourceCertificateEnabledForResource = ({
  resource,
  enable,
  ...rest
}) => {
  const [mutation, state] = useUpdateResourceCertificateEnabled(
    UPDATE_RESOURCE_MUTATION,
    { enable, ...rest }
  )
  function mutate() {
    return mutation(resource)
  }
  return [mutate, state]
}

const DisabledResourceCertificateGate = () => {
  const pwContext = usePathwrightContext()
  const { school } = pwContext
  const { resource, certificate } = useCertificateContext()
  const [updateResource, { loading }] =
    useUpdateResourceCertificateEnabledForResource({
      resource,
      enable: true
    })

  return certificate ? (
    <VStack spacing={4} padding={4} w="100%" textAlign={"center"}>
      <Pathicon icon="certificate" style={{ fontSize: "24px" }} />
      <Heading as={"h3"} size="md">
        The certificate for {school.name} is not enabled for {resource.name}.
      </Heading>
      <Button
        colorScheme="brand"
        onClick={() => updateResource()}
        isLoading={loading}
        size="lg"
      >
        Enable certificate
      </Button>
    </VStack>
  ) : canAdministrate(pwContext) ? (
    <VStack spacing={4} padding={4} w="100%" textAlign={"center"}>
      <Pathicon icon="certificate" style={{ fontSize: "24px" }} />
      <Heading as={"h3"} size="md">
        No certificate exists for {school.name}.
      </Heading>
      <Button
        colorScheme="brand"
        as={ReactRouterLink}
        variant="solid"
        to="/certificate/"
        size="lg"
      >
        Create certificate
      </Button>
    </VStack>
  ) : (
    <VStack spacing={4} padding={4} w="100%" textAlign={"center"}>
      <Pathicon icon="certificate" style={{ fontSize: "24px" }} />
      <Heading as={"h3"} size="md">
        No certificate exists for {school.name}.
      </Heading>
      <Button colorScheme="brand" variant="solid" size="lg" isDisabled>
        Create certificate
      </Button>
      <Text size="xs" color="gray.500">
        Only Administrators can create a certificate.
      </Text>
    </VStack>
  )
}

export default DisabledResourceCertificateGate
