import gql from "graphql-tag"
import { SchoolSubscriptionFragmentDoc } from "../../api/generated"

export default gql`
  query UserSchoolSubscriptionQuery {
    me {
      id
      school_subscription {
        ...SchoolSubscription
      }
    }
  }
  ${SchoolSubscriptionFragmentDoc}
`
