import gql from "graphql-tag"
import { ResourceFragmentDoc } from "../../api/generated"

export default gql`
  query ResourceQuery($id: Int!) {
    resource(id: $id) {
      ...Resource
    }
  }
  ${ResourceFragmentDoc}
`
