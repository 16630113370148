import { Button, ButtonProps } from "@chakra-ui/react"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { useFormikContext } from "formik"

function FormSubmitButton({ isDisabled, ...props }: ButtonProps) {
  const form = useFormikContext()

  return (
    <Button
      type="submit"
      variant="solid"
      size="lg"
      w="100%"
      cursor={form.isValid ? "pointer" : "disabled"}
      // isDisabled={!form.isValid || form.isSubmitting || props.isLoading}
      // isLoading={form.isSubmitting}
      isDisabled={!form.isValid || props.isLoading || isDisabled}
      isLoading={props.isLoading}
      rightIcon={<Pathicon icon="chevron-right" />}
      {...props}
    >
      Next
    </Button>
  )
}

export default FormSubmitButton
