import { Box } from "@chakra-ui/react"
import { useUIThemeContext } from "@pathwright/ui/src/components/ui/PathwrightUI"

const HomeBackground = () => {
  const theme = useUIThemeContext()

  const overlayColor = theme.backgroundOverlay
    ? `rgba(${theme.backgroundOverlay})`
    : null

  const image = theme.backgroundImage

  return (
    <Box
      zIndex={-10}
      position={"absolute"}
      top={0}
      left={0}
      right={0}
      bottom={0}
      w="100%"
      minH={"100vh"}
      h="100%"
      bgImage={
        image.startsWith("http") || image.startsWith("file:///")
          ? `url(${image})`
          : image
      }
      backgroundSize="cover"
      backgroundPosition="top center"
      backgroundRepeat="no-repeat"
      backgroundAttachment="fixed"
    >
      {/* Overlay */}
      {overlayColor && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg={overlayColor}
          opacity={0.33}
        />
      )}
    </Box>
  )
}

export default HomeBackground
