import { captureException, captureMessage, withScope } from "@sentry/browser"
import { jwtDecode } from "jwt-decode"
import { useFirebaseContext, useFirebaseValue } from "../firebase/utils"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

const useClipboard = () => {
  const { me } = usePathwrightContext()

  // Show a warning in local dev just so dev is aware useClipboard might
  // be in use when no user is authenticated, which can be OK.
  if (!me && process.env.NODE_ENV === "development") {
    console.warn(
      "Attempting to use the clipboard without being logged in. This is OK as long as you aren't expecting there to always be an authenticated user."
    )
  }

  const firebasePath = me ? `/user/${me.id}/clipboard/` : null
  const { firebase } = useFirebaseContext()
  const [_clips, clipboardRef] = useFirebaseValue(firebasePath)
  // Default clips to an empty array.
  const clips = _clips || []

  // Util for filtering existing clips by clip key/value pairs
  const _filterClips = ({ object_type, object_id, object_label }) => {
    const filteredClips = clips.filter(
      (clip) =>
        (!object_type || clip.object_type === object_type) &&
        (!object_id || clip.object_id === object_id) &&
        (!object_label || clip.object_label === object_label)
    )
    return filteredClips
  }

  const _removeClipsOfType = (object_type) =>
    clips.filter((clip) => clip.object_type !== object_type)

  const _save = async (nextClips = null) => {
    // There are very rare cases where the user is authenticated in Pathwright
    // but the user has no clipboardRef (it is null).
    // The following try/catch + Sentry loggin is an attempt to debug this issue.
    if (!clipboardRef) {
      // Attempt to log user's fb token data to Sentry.
      try {
        const { currentUser } = firebase.auth()
        const tokenData = currentUser ? jwtDecode(currentUser.xa) : null

        const issue = {
          message: "Failed attempt to copy item to clipboard.",
          extra: ["clipbard:user_fb_token_data", tokenData]
        }

        withScope((scope) => {
          scope.setExtra(...issue.extra)
          captureMessage(issue.message)
          console.warn(issue.message, ...issue.extra)
        })

        // Attempt to refresh the user's token in Sentry.
        if (currentUser) {
          const token = await firebase.auth().currentUser.getIdToken()
          const tokenData = token ? jwtDecode(token) : null
          const issue = {
            message: "Attempting to refresh user's firebase auth token.",
            extra: ["clipbard:user_refreshed_fb_token_data", tokenData]
          }

          withScope((scope) => {
            scope.setExtra(...issue.extra)
            captureMessage(issue.message)
            console.warn(issue.message, ...issue.extra)
          })
        }
      } catch (error) {
        captureException(error)
      }
      // Silently fail, ignoring user's attempt to copy to clipboard.
      return
    }
    await clipboardRef.set(nextClips || clips)
  }

  // Remove clips of this type (w/ legacy support for multiple clips of one type)
  const clear = ({ object_type }) => {
    const remainingClips = _removeClipsOfType(object_type)
    return _save(remainingClips)
  }

  const getClip = ({ object_type, object_id }) => {
    const filteredClips = _filterClips({ object_type, object_id })
    return filteredClips[filteredClips.length - 1]
  }

  const copy = ({ object_type, object_id, object_label }, data = {}) => {
    const clip = { object_type, object_id, object_label, data }
    const nextClips = _removeClipsOfType(object_type).concat([clip])
    _save(nextClips)
  }

  return {
    clear,
    copy,
    getClip
  }
}

export default useClipboard
