import { useMutation } from "@apollo/client"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Modal from "@pathwright/ui/src/components/modal/Modal"
import View from "@pathwright/ui/src/components/ui/View"
import gql from "graphql-tag"
import styled from "styled-components"
import {
  GROUP_STATUS_COMPLETED,
  GROUP_STATUS_IN_PROGRESS
} from "../../group/constants"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const COHORTS_COMPLETION_MUTATION = gql`
  mutation ($id: ID!, $status: GroupStatus!) {
    updateGroupStatus(id: $id, status: $status) {
      id
      status
    }
  }
`

const useUpdateCohortCompletion = (cohort) => {
  const { me } = usePathwrightContext()

  const [updateCompletion, { data, loading, error }] = useMutation(
    COHORTS_COMPLETION_MUTATION,
    {
      variables: {
        id: cohort.id
      },
      optimisticResponse: {
        __typename: "Mutations",
        updateGroupStatus: {
          id: cohort.id,
          __typename: "Group",
          status:
            cohort.status === GROUP_STATUS_IN_PROGRESS
              ? GROUP_STATUS_COMPLETED
              : GROUP_STATUS_IN_PROGRESS
        }
      },
      onCompleted: () => {
        // reload codex query for People
        const codexSpace =
          window.codexClient && window.codexClient.store.get("space")
        if (codexSpace && codexSpace.id === `people:${me.id}`)
          codexSpace.refetch()
      },
      // refetching context mainly in order to update the user's group_role_stats
      // which controls what Home UIs are available to the user
      refetchQueries: ["Context", "CohortShareContextQuery"]
      // refetchQueries: ["Cohorts"]
    }
  )

  return [updateCompletion, { data, loading, error }]
}

const CompleteCohortModal = ({ cohort, onClose }) => {
  const isCompleted = cohort.status === GROUP_STATUS_COMPLETED

  const { t } = useTranslate()
  const [updateCompletion] = useUpdateCohortCompletion(cohort)

  const handleCompleteToggle = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    const variables = {
      status: isCompleted ? GROUP_STATUS_IN_PROGRESS : GROUP_STATUS_COMPLETED
    }
    const result = await updateCompletion({
      variables
    })
    onClose()
  }

  if (isCompleted) {
    return (
      <Modal onClose={onClose}>
        <CompleteCohortModalContainer rounded>
          <BlankSlate
            icon="check-circle"
            heading={t("Resume this Cohort")}
            body={t(
              "Resuming this Cohort grants learners full access to the course."
            )}
            stack
            primaryAction={{
              label: t("Confirm and resume"),
              onClick: handleCompleteToggle
            }}
            secondaryAction={{
              label: t("Nevermind, go back"),
              onClick: (e) => {
                e.preventDefault()
                onClose()
              }
            }}
          />
        </CompleteCohortModalContainer>
      </Modal>
    )
  }

  return (
    <Modal onClose={onClose}>
      <CompleteCohortModalContainer rounded>
        <BlankSlate
          icon="check-circle"
          heading={t("Complete this Cohort")}
          body={
            <>
              {t(
                `Completing this Cohort will make the path view only. Learners will be able to view content, progress, and their submissions and notes but won't be able to complete or post anything new. This action cannot currently be undone.`
              )}{" "}
              <Button
                styleType="text"
                href="http://help.pathwright.com/en/articles/5163383-mark-a-cohort-complete"
              >
                {t("Learn more")}
              </Button>
              .
            </>
          }
          stack
          primaryAction={{
            label: t("Confirm and complete"),
            onClick: handleCompleteToggle
          }}
          secondaryAction={{
            label: t("Nevermind, go back"),
            onClick: (e) => {
              e.preventDefault()
              onClose()
            }
          }}
        />
      </CompleteCohortModalContainer>
    </Modal>
  )
}

const CompleteCohortModalContainer = styled(View.Primary)`
  max-width: 500px;
  margin: 0 auto;
`

export default CompleteCohortModal
