// TODO: This file was created by bulk-decaffeinate.

import { gql } from "@apollo/client"
import { getApolloClient } from "@pathwright/web/src/modules/pathwright/PathwrightClient"

// Sanity-check the conversion and remove this comment.
const Offering = require("library/models/offering").default

// TODO: update all "group" language to "offering"

// Sync the cohort name change to all child cohorts.
function syncCollectionCohortNameChange({ id, name }) {
  // We may not be scoped to any store with the respective resource
  // but let's attempt to get the resource name for this cohort.
  const resourceName =
    App.getStore("resource").getState().resource?.name ||
    App.getStore("resourceAdmin").getState().resource?.name
  if (resourceName) {
    // Derive the new child cohort name following the pattern: "<Collection Resource Name>: <Collection Cohort Name>".
    const newChildCohortName = `${resourceName}: ${name}`
    const client = getApolloClient()
    // We must extract the cache data in order to find cached child cohorts.
    const cacheData = client.cache.extract()
    // Find child cohort of this cohort.
    const matchingEntries = Object.entries(cacheData).filter(([key, data]) => {
      return data.__typename === "Group" && data.parent_section_id === id
    })
    // For each child cohort, update the name in the cache.
    matchingEntries.forEach(([key, data]) => {
      client.writeFragment({
        // Scope a Group fragment to the keys we need.
        fragment: gql`
          fragment GroupName on Group {
            id
            name
          }
        `,
        fragmentName: "GroupName",
        id: key,
        data: {
          ...data,
          name: newChildCohortName
        }
      })
    })
  }
}

class GroupAdminStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    this.prototype.defaults = {
      group: null,

      is_saving_group: false,
      is_deleting_group: false,
      is_loading_group: false,
      is_creating_group: false,

      group_success: null,
      group_error: null
    }

    this.prototype.storeTriggers = {
      "offering:updated"() {
        return this.get("group")
      },
      "offering:deleted"() {
        return this.get("group")
      },
      "offering:created"() {
        return this.get("group")
      }
    }

    this.prototype.resources = {
      group: {
        model: Offering
      }
    }

    this.prototype.actions = {
      saveGroup(groupData) {
        const group = this.get("group")
        const isNew = group.isNew()
        if (!group) {
          throw new Error(
            "You must select a group to save it. See selectGroup or selectNewGroup."
          )
        }
        group.set(groupData)
        const changed = { ...group.changed }
        this._mutate({ is_saving_group: true, group_error: null })
        return new Promise(
          function (resolve, reject) {
            return group
              .save()
              .then((result) => {
                this.action.setSuccessTimeout("group_success", "Saved")
                this._mutate({ group })
                // triggerEvent = isNew and "group:created" or "group:updated"
                this.storeTrigger("offering:updated")
                if (this.get("is_creating_group")) {
                  this.action.onGroupCreated()
                }
                // refetch group for Apollo Client cache
                window.apolloClientStoreBridge.refetchGroup({
                  id: group.id
                })

                // If we updated the cohort name, let's attempt to update the names of
                // all child cohorts (we don't know if this is a collection cohort, just by
                // checking the 'group' data).
                if ("name" in changed) {
                  // Update all child cohorts with name change.
                  syncCollectionCohortNameChange({
                    id: group.get("id"),
                    name: group.get("name")
                  })
                }

                return resolve(
                  this.resource.group.load(
                    {
                      resource: group.get("resource_id"),
                      id: group.get("id")
                    },
                    { forceReload: true }
                  )
                )
              })
              .fail((err) => {
                this._mutate({ group_error: err })
                return reject(err)
              })
              .always(() => {
                return this._mutate({ is_saving_group: false })
              })
          }.bind(this)
        )
      },

      onGroupCreated() {
        this.storeTrigger("offering:created")
        const group = this.get("group")
        this._mutate({ is_creating_group: false })
        const navStore = this.getStore("navigation")
        if (navStore.get("is_showing_modal")) {
          const url = `/manage/resource/${group.get("resource_id")}/group/${
            group.id
          }/`
          navStore.action.navigate(url)
          // If we created a new group from members, we need to switch to it on  close
          if (
            __guard__(navStore.get("modal_launched_from_url"), (x) =>
              x.indexOf("/members")
            ) > -1
          ) {
            return navStore.action.setDefaultModalLaunchedFromURL(
              group.get("url") + "members/",
              true
            )
          }
        }
      },

      deleteGroup(id) {
        let group = this.get("group")
        if (!group || (id != null && group.id !== id)) {
          group = new Offering({ id })
        }
        this._mutate({ is_deleting_group: true, group_error: null })
        return new Promise(
          function (resolve, reject) {
            return group
              .destroy()
              .then(() => {
                this.storeTrigger("offering:deleted")
                this._mutate({ group: null })
                return resolve()
              })
              .fail((err) => {
                this._mutate({ group_error: err })
                return reject(err)
              })
              .always(() => {
                return this._mutate({ is_deleting_group: false })
              })
          }.bind(this)
        )
      },

      selectGroup(resource_id, group_id) {
        let group = this.get("group")
        return new Promise((resolve, reject) => {
          if (group_id === "new") {
            this._mutate({ is_creating_group: true })
            this.action.selectNewGroup(resource_id)
            return resolve(this.get("group"))
          }

          resolve(
            this.resource.group.load({
              resource: resource_id,
              id: group_id
            })
          )
        })
      },

      selectNewGroup(resource_id) {
        const defaults = _.defaults({}, { resource_id })
        const group = new Offering(defaults)
        this._mutate({ group })
        return group
      },

      createNewGroup(resource_id) {
        return new Promise(
          function (resolve, reject) {
            this.action.selectNewGroup(resource_id)
            const group = this.get("group")
            return group
              .save()
              .then((results) => {
                this._mutate({ group })
                this.storeTrigger("offering:created")
                return resolve(group)
              })
              .fail((err) => {
                console.log(err)
                return reject(err)
              })
          }.bind(this)
        )
      },

      setSuccessTimeout(key, message) {
        this._mutate({ [key]: message })
        return setTimeout(() => {
          return this._mutate({ [key]: null })
        }, 1000)
      }
    }
  }
}
GroupAdminStore.initClass()

export default window.App.stores.registerStore("groupAdmin", GroupAdminStore)

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null
    ? transform(value)
    : undefined
}
