import CertificateCard from "@pathwright/web/src/modules/certificate/cards/CertificateCard"
import { CreateCohortCard } from "@pathwright/web/src/modules/cohort/create/CreateCohort"
import Dashboard from "@pathwright/web/src/modules/home/Dashboard"
import ResourceEditorsCard from "@pathwright/web/src/modules/resource/manage/ResourceEditorsCard"
import { cardRoutes as authorRoutes } from "author/routes"
import { cardRoutes as categoryRoutes } from "category/routes"
import { groupCardRoutes } from "group/components/manage/routes"
import {
  ENROLLMENT_ROLE_EDITOR,
  INVITE_METHOD_EMAIL
} from "invitation/constants"
import ResourceGroupListCard from "./grouplist/ResourceGroupListCard"
// Editor and Invite Editor Cards
import Invite from "invitation/invite/components/Invite"
import inviteStore from "invitation/invite/store/invite-store"
import Invitations from "invitation/list/components/Invitations"
import invitationStore from "invitation/list/store/invitation-store"
import { createCardRoutes } from "lib/core/routing/card-routing"
// License Cards
import ManageLicenseCard from "license/manage/ManageLicenseCard"
import ManageLicensePricingCard from "license/manage/pricing/ManageLicensePricingCard"
import ManageLicenseSubscriptionCard from "license/manage/subscription/ManageLicenseSubscriptionCard"
import { getDefaultLaunchedFromHome } from "../../../lib/core/routing/utils"
import ManageResourceCohortsCard from "./ManageResourceCohortsCard"
import ManageResourceDetailsContainer from "./ManageResourceDetailsContainer"
import ManageResourceMenuContainer from "./ManageResourceMenuContainer"
import ResourceArchiveCard from "./archive/ResourceArchiveCard"
import ResourceThemeCard from "./theme/ResourceThemeCard"
const resourceAdminStore = window.App.getStore("resourceAdmin")
window.invitationStore = invitationStore

const selectResource = (route) => {
  const { match } = route
  const resourceID = match.params.resourceId
  return resourceAdminStore.action
    .selectResource(resourceID)
    .loadOfferings(resourceID)
    .promise.then(() => {
      window.App.getStore("authorAdmin").action.loadAuthors()
      window.App.getStore("categoryAdmin").action.loadCategories()
    })
}

const selectGroup = ({ match }) => {
  return window.App.getStore("groupAdmin").action.selectGroup(
    match.params.resourceId,
    match.params.cohortId
  )
}

// loadResourceLicenseOffering = (route)=>
//   {resource} = resourceAdminStore.getState()
//   selectResource(route).then =>
//     {resource} = resourceAdminStore.getState()
//     window.App.getStore("resourceLicenseAdmin").action
//       .selectLicenseOffering(resource)

const loadResourceLicenseOffering = ({ match }) => {
  return window.App.getStore("resourceLicenseAdmin").action.loadLicenseOffering(
    match.params.resourceId,
    match.params.resourceLicenseOfferingId
  )
}

const enterEditorsInvite = (route) => {
  selectResource(route).then(() => {
    const { resource } = resourceAdminStore.getState()
    const offering = resourceAdminStore.request.getCurriculumOffering()
    inviteStore.action.initialize()
    inviteStore.action.setContext({
      resource,
      offering,
      method: INVITE_METHOD_EMAIL,
      role: ENROLLMENT_ROLE_EDITOR
    })
  })
}

const enterEditorsInvitations = (route) => {
  invitationStore.action.initialize()
  const { status } = route.location.query
  selectResource(route).then(() => {
    const { resource } = resourceAdminStore.getState()
    const offering = resourceAdminStore.request.getCurriculumOffering()
    invitationStore.action.setContext({ resource, offering, status })
    invitationStore.action.loadInvitations()
  })
}

const ManageResourceEditorWrapped = ({ card, location, match }) => {
  return (
    <ResourceEditorsCard
      card={card}
      id={parseInt(match.params.resourceId)}
      pathname={location.pathname}
    />
  )
}

const licenseCards = {
  path: "license/",
  component: ManageLicenseCard,
  middleware: loadResourceLicenseOffering,
  childCards: {
    pricing: {
      component: ManageLicensePricingCard
    },
    subscription: {
      component: ManageLicenseSubscriptionCard
    }
  }
}

export const cardRoutes = {
  resource: {
    component: ManageResourceMenuContainer,
    path: ":resourceId(\\d+)/",
    middleware: selectResource,
    getDefaultLaunchedFrom: getDefaultLaunchedFromHome,
    childCards: {
      theme: {
        component: ResourceThemeCard
      },
      details: {
        component: ManageResourceDetailsContainer,
        childCards: {
          ...authorRoutes,
          ...categoryRoutes,
          theme: {
            component: ResourceThemeCard
          }
        }
      },
      cohorts: {
        component: (props) => (
          <ManageResourceCohortsCard
            card={props.card}
            resourceId={parseInt(props.match.params.resourceId)}
          />
        ),
        childCards: {
          manage: {
            component: ResourceGroupListCard,
            childCards: {
              new: {
                component: ({ card, match }) => (
                  <CreateCohortCard
                    card={card}
                    resourceId={parseInt(match.params.resourceId)}
                  />
                )
              },
              [":cohortId(\\d+)/"]: {
                ...groupCardRoutes,
                path: undefined
              }
            }
          },
          groupDetails: _.assign({}, groupCardRoutes, {
            path: ":cohortId(\\d+)/"
          }),
          license: licenseCards,
          // Linking to specific RLO by ID.
          licenseSelected: {
            ...licenseCards,
            path: "license/:resourceLicenseOfferingId(\\d+)/",
            // When leaving this route, we must skip the /license route
            // to avoid awkward routing UX.
            reversePath: "../../"
          }
        }
      },
      certificate: {
        component: (props) => (
          <CertificateCard
            card={props.card}
            resourceId={parseInt(props.match.params.resourceId)}
          />
        )
      },
      editors: {
        component: ManageResourceEditorWrapped,
        childCards: {
          invite: {
            component: Invite,
            middleware: enterEditorsInvite,
            childCards: {
              invitation: {
                component: Invitations,
                middleware: enterEditorsInvitations,
                leaveMiddleware: invitationStore.action.reset
              }
            }
          }
        }
      },
      archive: {
        component: ResourceArchiveCard
      }
    }
  }
}

const manageResourceCardRoutes = createCardRoutes(
  "/manage/resource/",
  cardRoutes,
  Dashboard
)

export default manageResourceCardRoutes
