import { Flex } from "@chakra-ui/react"
import { BlocksConfigPropsType } from "@pathwright/blocks-engine"
// @ts-expect-error missing types
import BlockContentContainer from "@pathwright/blocks/src/main/BlockContentContainer"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { Suspense } from "react"
import { getGraphQLEndpoint } from "../pathwright/PathwrightClient"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

type BlockContentProps = {
  showLoading?: boolean
} & BlocksConfigPropsType

const BlockContent = ({ showLoading = true, ...props }: BlockContentProps) => {
  const context = usePathwrightContext()
  const graphQLEndpoint = `${getGraphQLEndpoint()}blocks/graphql?school_id=${
    context.school.id
  }`
  const authToken = localStorage.auth_token

  return (
    <Suspense fallback={showLoading ? <LoadingCircle /> : null}>
      <BlockContentContainer
        graphQLEndpoint={graphQLEndpoint}
        authToken={authToken}
        renderLoading={() => (
          <Flex minH="300px">
            <LoadingCircle />
          </Flex>
        )}
        {...props}
      />
    </Suspense>
  )
}

export default BlockContent
