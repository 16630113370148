import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  HStack,
  IconButton,
  IconButtonProps,
  UseDisclosureReturn,
  VStack,
  useBreakpointValue
} from "@chakra-ui/react"
import React, { useMemo } from "react"
import { usePreviousDistinct } from "react-use"
import Pathicon from "../../pathicon/Pathicon"
import { useMergedDisclosure } from "./utils"

type UsePanelSizeProps = {
  allowOverlay?: boolean
}

// Use these drawer sizes based on media queries.
export const usePanelSize = (options?: UsePanelSizeProps) =>
  useBreakpointValue({
    // When allowing the panel to overlay, we set the size to full on
    // <= sm sized screens.
    base: options?.allowOverlay ? "full" : "xs",
    md: "xs",
    "2xl": "sm"
  })

type useMotionPropsProps = {
  isOpen: boolean
  preventAnimation: boolean
}

// Initially the drawer should not animate in, but always
// animate in/out afterwards, unless drawer is offset.
const useMotionProps = ({ isOpen, preventAnimation }: useMotionPropsProps) => {
  const prevIsOpen = usePreviousDistinct(isOpen)
  return useMemo(() => {
    if (preventAnimation || typeof prevIsOpen !== "boolean") {
      return {
        initial: false
      }
    }
    return {}
  }, [prevIsOpen])
}

type ToolbarOption = {
  key: string
  icon: string
  selected: boolean
  toggleSelected: () => void
} & Omit<IconButtonProps, "icon">

type Toolbar = {
  // Hide the toolbar when an option is selected?
  hideWhenSelected?: boolean
  options: ToolbarOption[]
}

export type AugmentationComponentProps = {} & UseDisclosureReturn

export type AugmentationFnComponent<T = {}> = (
  props: AugmentationComponentProps & T
) => JSX.Element

export type AugmentationComponent =
  | JSX.Element
  | AugmentationFnComponent
  | false
  | null

export type AugmentationComponents = {
  header?: AugmentationComponent
  body: AugmentationComponent
  footer?: AugmentationComponent
}

export type AugmentationWrapperComponent = AugmentationFnComponent<{
  Panel: (props: Partial<AugmentationComponents>) => JSX.Element
}>

export type AugmentationPanelOptions = {
  toolbar?: Toolbar
  offset?: {
    top?: number
    right?: number
  }
  allowOverlay?: boolean
  allowClose?: boolean
  disclosure?: UseDisclosureReturn
}

export type AugmentationPanelProps = AugmentationPanelOptions &
  AugmentationComponents

const AugmentationPanel = React.forwardRef<HTMLElement, AugmentationPanelProps>(
  (props, ref) => {
    const { offset, allowClose, allowOverlay, header, body, footer, toolbar } =
      props
    const size = usePanelSize({ allowOverlay })

    const showToolbar =
      !!toolbar?.options.length &&
      (!toolbar.hideWhenSelected ||
        toolbar.options.every((option) => !option.selected))
    const showPanel =
      !showToolbar || toolbar.options.some((option) => option.selected)

    const disclosure = useMergedDisclosure(props.disclosure, {
      isOpen: props.disclosure?.isOpen,
      // We probably need to open panel by default when has body?
      defaultIsOpen: showPanel
    })

    const { isOpen, onClose } = disclosure
    const augmentationComponentProps = disclosure

    const motionProps = useMotionProps({
      isOpen,
      preventAnimation: !!offset
    })

    // Offset from all sides of screen.
    const gutter = 10

    // Standard border radius for all corners of panel.
    const borderRadius = "20px"

    // useToggleIntercomVisibility(isOpen)

    // Styling the drawer content conditionally based on drawer size.
    const contentSizeProps =
      // Use overlay styles for when drawer is "full" size.
      size === "full"
        ? {
            inset: "10px !important",
            maxW: `calc(100% - ${gutter * 2}px)`,
            maxH: `calc(100% - ${gutter * 2}px)`
          }
        : {}

    // We should only apply border radius to content/body when
    // header or footer are not provided.
    const contentBorderRadius = {
      ...(header ? {} : { borderTopRadius: borderRadius }),
      ...(footer ? {} : { borderBottomRadius: borderRadius })
    }

    // Conditionally close the panel depending on if we are
    function handleClose() {
      if (showPanel) {
        // Toggle each selected option off (there really should only be one),
        // which will effectively hide the panel, yet leave the toolbar visible.
        toolbar?.options.forEach((option) => {
          if (option.selected) {
            option.toggleSelected()
          }
        })
      } else {
        onClose()
      }
    }

    return (
      <>
        <Drawer
          isOpen={isOpen}
          onClose={handleClose}
          variant="aside"
          size={size}
          trapFocus={false}
          blockScrollOnMount={false}
          closeOnOverlayClick={false}
        >
          <DrawerContent
            ref={ref}
            className="AugmentationPanel"
            motionProps={motionProps}
            bg="transparent"
            bottom={`${gutter}px !important`}
            top={`${(offset?.top || 0) + gutter}px !important`}
            marginRight={offset?.right ? `${offset.right}px` : undefined}
            // This fixes width calculations when only toolbar shows.
            w="unset !important"
            boxShadow="none"
            {...contentSizeProps}
          >
            <HStack h="100%" alignItems="flex-start">
              {showToolbar && (
                <VStack
                  className="CertificateDrawerLauncher"
                  spacing={1}
                  position="relative"
                  bg="rgba(255, 255, 255, 0.95)"
                  px=".25em"
                  py=".5em"
                  borderRadius="20px"
                >
                  {toolbar.options.map(
                    ({ toggleSelected, selected, icon, ...rest }) => (
                      <IconButton
                        size="sm"
                        s
                        colorScheme="gray"
                        variant={selected ? "outline" : "ghost"}
                        // @ts-expect-error: icon as string not acceptable
                        icon={<Pathicon icon={icon} size="1.2em" />}
                        onClick={toggleSelected}
                        {...rest}
                      />
                    )
                  )}
                </VStack>
              )}
              {showPanel && (
                <VStack
                  h="100%"
                  flexGrow={1}
                  spacing={0}
                  backdropFilter="blur(10px)"
                  {...contentBorderRadius}
                >
                  {allowClose && <DrawerCloseButton zIndex={1000} />}
                  {!!header && (
                    <DrawerHeader
                      bg="rgba(255, 255, 255, 0.95)"
                      py=".5em"
                      borderTopRadius="20px"
                      w="100%"
                    >
                      {typeof header === "function"
                        ? header(augmentationComponentProps)
                        : header}
                    </DrawerHeader>
                  )}
                  <DrawerBody
                    bg="rgba(255, 255, 255, 0.9)"
                    w="100%"
                    {...contentBorderRadius}
                  >
                    {typeof body === "function"
                      ? body(augmentationComponentProps)
                      : body}
                  </DrawerBody>
                  {!!footer && (
                    <DrawerFooter
                      bg="rgba(255, 255, 255, 0.95)"
                      marginTop="auto"
                      display="flex"
                      justifyContent="center"
                      borderBottomRadius="20px"
                      w="100%"
                    >
                      {typeof footer === "function"
                        ? footer(augmentationComponentProps)
                        : footer}
                    </DrawerFooter>
                  )}
                </VStack>
              )}
            </HStack>
          </DrawerContent>
        </Drawer>
      </>
    )
  }
)

AugmentationPanel.displayName = "AugmentationPanel"

export default AugmentationPanel
