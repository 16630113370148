import { default as PathwrightUII18nextProvider } from "@pathwright/ui/src/components/lng/I18nextProvider"
import { BackendModule } from "i18next"
// import { preProcessTranslation } from "@pathwright/ui/src/components/lng/utils"

// import { useEffect, useState } from "react"
// import { useTranslationLazyQuery } from "../api/generated"
import memoize from "lodash/memoize"
import { useUpdateLocalTranslationMutation } from "../api/generated"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

// NOTE: ideally we could catch when a key is missing in the school's
// translation (depending on `saveMissing` option) and then fetch the
// default english translation on demand, adding it as a new translation
// resource, at which point react i18n would rerender the offending component.
// This is not working, but I may be going about it in a naive way.
// So, for now, loading the default english translation with the school's
// translation in order to set it up as a fallback when initializing i18next.

// function MissingTranslationHandler() {
//   const { translation } = usePathwrightContext()

//   const [defaultEnglishTranslation, setDefaultEnglishTranslation] = useState(
//     () =>
//       translation.language === "en" && translation.is_default
//         ? translation
//         : null
//   )
//   const [loadDefaultEnglishTranslation, defaultEnglishTranslationState] =
//     useTranslationLazyQuery()

//   // Set the default translation.
//   useEffect(() => {
//     if (defaultEnglishTranslationState.data?.translation) {
//       setDefaultEnglishTranslation(
//         defaultEnglishTranslationState.data?.translation.master_translation
//       )
//     }
//   }, [defaultEnglishTranslationState.data?.translation])

//   useEffect(() => {
//     i18n.addResources(
//       translation.language,
//       "defaultEnglish",
//       preProcessTranslation(defaultEnglishTranslation),
//       true,
//       true
//     )
//   }, [defaultEnglishTranslation])

//   useEffect(() => {
//     i18n.on(
//       "missingKey",
//       function (lngs: any, namespace: any, key: any, res: any) {
//         if (!defaultEnglishTranslation) {
//           loadDefaultEnglishTranslation()
//         }
//       }
//     )
//   }, [])

//   return null
// }

// Simply providing the translation from PathwrightContext to the I18nextProvider from legacy/ui
const I18nextProvider = (props: any) => {
  const { translation, school } = usePathwrightContext()
  const [mutate] = useUpdateLocalTranslationMutation()

  // Only using this backend in local dev, so don't compile it.
  // Mainly don't want to compile the RegExp lookbehind assertions
  // that aren't supported in older versions of Safari that a number of our
  // users are still using (though we don't actively support older browsers.)
  const localI18nBackendPlugin: BackendModule | null =
    process.env.NODE_ENV === "development"
      ? {
          type: "backend",
          init() {},
          read() {},
          // Handles missing keys, saving them to our local translation file.
          // Memoize the create fn so as to only call it once per unique set of args.
          create: memoize<
            // @ts-expect-error: memoize mistakenly(?) doesn't permit a function that returns void...
            BackendModule["create"]
          >(
            function (languages, namespace, key, fallbackValue) {
              if (key.startsWith("$t(")) {
                // Do not treat nested key as missing.
                return key
              }

              const keySeparatorRe = /(?<=\w)\.(?=\w)/
              const keys = key.split(keySeparatorRe)
              // TODO: refactor this approach to created a nested translation as it
              // relies on hacky side-effect manipulation in reduce fn.
              let translation = {} as Record<string, string | {}>

              // Convert the key into a nested one, if using key separators.
              // For example, convert "a.b.This text" to { a: { b: "This text" } }.
              keys.reduce((acc, key, i) => {
                if (i === keys.length - 1) {
                  // If we've actually created a nested translation, use the key
                  // otherwise use the fallbackValue.
                  // acc[key] = i > 0 ? key : fallbackValue
                  // Hmm, actually try using the fallbackValue if it exists.
                  let value = fallbackValue || key

                  // Prompt dev to provide a translation for singular and plural as i18next
                  // does not currently extract it from the translation call.
                  if (value.endsWith("_one") || value.endsWith("_other")) {
                    value =
                      prompt(`Please provide a translation for "${value}".`) ||
                      value
                  }

                  acc[key] = value
                } else {
                  return (acc[key] = {})
                }
                return acc
              }, translation)

              // Log to console so dev can be aware of the update.
              console.log("i18next backend: updated local translation:", {
                // languages,
                // namespace,
                key,
                fallbackValue,
                translation
              })

              // Save the missing translation to our local JSON file.
              return mutate({
                variables: {
                  translation
                }
              })
            },
            (languages, namespace, key, fallbackValue) =>
              `${languages}:${namespace}:${key}:${fallbackValue}`
          )
        }
      : null

  return (
    <PathwrightUII18nextProvider
      translation={translation}
      locale={school.ietf_language_tag}
      localI18nBackendPlugin={localI18nBackendPlugin}
      {...props}
    >
      {/* <MissingTranslationHandler /> */}
      {props.children}
    </PathwrightUII18nextProvider>
  )
}

export default I18nextProvider
