import { useMutation } from "@apollo/client"
import RadioButton from "@pathwright/ui/src/components/form/form-radio/RadioButton"
import RadioGroup from "@pathwright/ui/src/components/form/form-radio/RadioGroup"
import SubmitHandler from "@pathwright/ui/src/components/submit/SubmitHandler"
import SubmitStatus from "@pathwright/ui/src/components/submit/SubmitStatus"
import gql from "graphql-tag"
import { useState } from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

const UPDATE_NOTIFICATION_DIGEST_SETTINGS_MUTATION = gql`
  mutation UpdateNotificationDigestInterval(
    $interval: NotificationDigestInterval
  ) {
    updateNotificationDigestInterval(interval: $interval)
  }
`

const NotificationsDigestSettingsContainer = styled.div`
  padding: 10px;

  /* fix vertical centering in Safari */
  input[type="radio"] {
    font-size: 0 !important;
  }
`

const RadioButtonContainer = styled.div`
  position: relative;
  margin-bottom: 0.5em;

  .SubmitStatus {
    left: unset;
    right: 0.5em;
  }
`

const digestOptions = [
  { value: "never", label: "Never" },
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" }
]

const NotificationsDigestSettings = () => {
  const {
    me: { profile }
  } = usePathwrightContext()

  const [selectedInterval, setSelectedInterval] = useState(
    profile.notification_digest_interval
  )

  const [mutate, { loading }] = useMutation(
    UPDATE_NOTIFICATION_DIGEST_SETTINGS_MUTATION,
    {
      // kinda hardcore to reload the whole context for this... is there a way to just update the profile cache?
      refetchQueries: ["Context"]
    }
  )

  const handleChange = (e) => {
    const { value } = e.target
    setSelectedInterval(value)
    return mutate({ variables: { interval: value } })
  }

  return (
    <NotificationsDigestSettingsContainer>
      <RadioGroup topLabel label="Send notifications digest email:" hideStatus>
        {digestOptions.map((option) => (
          <SubmitHandler key={option.value} onSubmit={handleChange}>
            {({ status, handleSubmit }) => (
              <RadioButtonContainer>
                <RadioButton
                  key={option.value}
                  name="digest_interval"
                  type="radio"
                  value={option.value}
                  checked={option.value == selectedInterval}
                  required
                  onChange={handleSubmit}
                >
                  {option.label}
                </RadioButton>
                <SubmitStatus status={status} size={14} />
              </RadioButtonContainer>
            )}
          </SubmitHandler>
        ))}
      </RadioGroup>
    </NotificationsDigestSettingsContainer>
  )
}

export default NotificationsDigestSettings
