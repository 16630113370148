import { getApolloClient } from "@pathwright/web/src/modules/pathwright/PathwrightClient"
import gql from "graphql-tag"
import produce from "immer"

class BaseModel extends require("lib/static-shim").default(Backbone.Model) {
  static initClass() {
    this.prototype.errors = []
    this.prototype.calculated = {}
    // Use gql fields to update the GQL cache after model saves
    this.prototype.gqlSync = {
      typename: null,
      // Map any backbone model keys to gql fields that don't map 1-1.
      // Provide backbone model keys mapping to:
      // { field: string, resolver?: (v) => v }
      keyMap: {}
    }
  }

  constructor(attrs, options) {
    // DECAFFEINATE FIX
    super(...arguments)
    this.options = options
    this._snaps = []

    this._loaded = false
    this.listenToOnce(this, "sync", function () {
      this.trigger("loaded", this)
      return (this._loaded = true)
    })

    // Store the model's changed attributes before syncing as otherwise they
    // are reset after save and unavailable in "sync" event callback.
    this._changedAttributes = {}
    this.on("request", (model) => {
      const changedAttributes = model.changedAttributes(
        this.previousAttributes()
      )
      this._changedAttributes = Object.keys(changedAttributes).length
        ? changedAttributes
        : null
    })

    // After model save, sync the updates to cached GraphQL fragments.
    this.on("sync", (model) => {
      // Only proceed if we have gql sync config and changed attributes.
      if (this.gqlSync && this._changedAttributes) {
        const { gqlSync, _changedAttributes: changedAttributes } = this

        // The updates being saved are stored in the models changed key.
        // We can map these changes to Apollo values.
        const gqlFields = Object.keys(changedAttributes).map(
          (key) => gqlSync.keyMap[key]?.field || key
        )
        const client = getApolloClient()
        const fragmentName = `Backbone${gqlSync.typename}`
        // Generate a fragment for the mapped GQL type.
        const fragmentDoc = gql`fragment ${fragmentName} on ${
          gqlSync.typename
        } {
        ${gqlFields.join("\n")}
      }`
        const fragment = {
          fragment: fragmentDoc,
          fragmentName: fragmentName,
          id: `${gqlSync.typename}:${model.id}`
        }
        const fragmentData = client.readFragment(fragment)
        const nextFragmentData = produce(fragmentData, (draft) => {
          // For each changed attribute, map the model key and value to the GQL
          // field and value.
          Object.entries(changedAttributes).forEach(
            ([modelKey, modelValue]) => {
              if (draft) {
                // Get the GQL field name, falling back to the model key.
                const fieldName = gqlSync.keyMap[modelKey]?.field || modelKey
                // Get the GQL field value, falling back to the model value.
                const fieldValue =
                  gqlSync.keyMap[modelKey]?.resolver?.(modelValue) || modelValue
                // Dev log.
                if (process.env.NODE_ENV === "development") {
                  console.log(
                    `Updating cached GraphQL fragment "${gqlSync.typename}" field "${fieldName}" to value: ${fieldValue}`
                  )
                }
                draft[fieldName] = fieldValue
              }
            }
          )
        })
        if (nextFragmentData) {
          client.writeFragment({
            ...fragment,
            data: nextFragmentData
          })
        } else {
          // Warn about unexpected failure to update the cache.
          console.warn(
            `Failed to update cached GraphQL fragment "${gqlSync.typename}" for Backbone.Model`,
            { model }
          )
        }
      }
    })
  }

  isLoaded() {
    return this._loaded
  }

  snapshot() {
    const snap = _.clone(this.attributes)
    this._snaps.push(snap)
    return snap
  }

  rollback(silent) {
    if (silent == null) {
      silent = false
    }
    if (this._snaps.length) {
      const snap = this._snaps.pop()
      return this.set(snap, { silent })
    }
  }

  urlRoot(url) {
    return Pathwright.getAPIUrl(url)
  }

  getViewContext(withGlobal) {
    // returns the data needed to render templates
    if (withGlobal == null) {
      withGlobal = false
    }
    let data = this.toJSON()
    data.cid = this.cid
    if (withGlobal) {
      data = $.extend(Pathwright.getGlobalViewContext(), data)
    }
    return data
  }

  validate(attrs, options) {}

  isValid() {
    // Validate should not return anything if
    // the model is valid
    return this.validate(this.attributes, this.options) == null
  }

  merge(data) {
    const mergeData = _.pick(
      this.attributes,
      (() => {
        const result = []
        for (let k in data) {
          const v = data[k]
          result.push(k)
        }
        return result
      })()
    )
    return this.set(_.defaults(mergeData, data))
  }

  index() {
    if (this.collection != null) {
      return this.collection.indexOf(this)
    }
  }

  next() {
    if (this.collection != null) {
      const i = this.index() + 1
      if (i <= this.collection.length) {
        return this.collection.at(i)
      }
    }
    return null
  }

  prev() {
    if (this.collection != null) {
      const i = this.index() - 1
      if (i >= 0) {
        return this.collection.at(i)
      }
    }
    return null
  }

  fetch() {
    const whenFetched = super.fetch()
    if (this.whenFetched == null) {
      this.whenFetched = whenFetched
    }
    return whenFetched
  }

  serialize() {
    // Returns a plain object version of this models data (including any sub models/collections)
    var _deepSerialize = function (obj) {
      for (let key in obj) {
        const val = obj[key]
        if (_.isFunction(val != null ? val.serialize : undefined)) {
          obj[key] = _deepSerialize(val.serialize())
        } else if (_.isFunction(val != null ? val.toJSON : undefined)) {
          obj[key] = _deepSerialize(val.toJSON())
        }
      }
      return obj
    }
    return _deepSerialize(this.toJSON())
  }
}
BaseModel.initClass()

// Override url function to force closing '/'
BaseModel.oldURL = BaseModel.prototype.url
BaseModel.prototype.url = function () {
  let base = BaseModel.oldURL.call(this)
  if (base.length) {
    if (base.slice(-1) !== "/") {
      base = `${base}/`
    }
  }
  return base
}

export default BaseModel
