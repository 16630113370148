import {
  Box,
  BoxProps,
  Link as ChakraLink,
  HStack,
  IconButton,
  LinkProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack
} from "@chakra-ui/react"
import type { IconName } from "@pathwright/pathicons"
import Link from "@pathwright/ui/src/components/link/Link"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { motion } from "framer-motion"

export type OptionMenuItemProps = {
  icon?: string
  label: string
  onClick?: () => void
  to?: any
} & LinkProps

export const OptionMenuItem = ({
  icon,
  label,
  onClick,
  to,
  ...props
}: OptionMenuItemProps) => {
  return (
    <ChakraLink
      as={to && Link}
      href={props.href || to}
      to={to}
      onClick={onClick}
      // textDecoration="none"
      w="100%"
      p={2}
      aria-label={label}
      _hover={{ textDecoration: "none", bg: "blackAlpha.200" }}
      {...props}
    >
      <HStack align="center">
        <Pathicon icon={icon as IconName} />
        <Text fontSize="xs" my={0} fontWeight="normal">
          {label}
        </Text>
      </HStack>
    </ChakraLink>
  )
}

type OptionMenuButtonProps = {
  children?: React.ReactNode
  optionItems?: any[]
  variant?: "ghost" | "solid" | "outline" | "link" | "unstyled" | undefined
  colorScheme?: "blackAlpha" | "gray" | "whiteAlpha"
} & BoxProps

const OptionMenuButton = ({
  children,
  optionItems,
  variant,
  colorScheme,
  ...props
}: OptionMenuButtonProps) => {
  return (
    <Box
      {...props} //! shuould always be first
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { ease: "easeInOut", duration: 0.3, delay: 0.1 }
      }}
      pos="absolute"
      zIndex={1000}
    >
      <Popover
        placement="right-start"
        // gutter={16}
        offset={[-12, 16]}
      >
        <PopoverTrigger>
          <IconButton
            icon={<Pathicon icon="ellipses-horizontal" />}
            aria-label="Options Menu"
            size="xs"
            variant={variant}
            colorScheme={colorScheme}
          />
        </PopoverTrigger>
        <PopoverContent w={"fit-content"} overflow={"hidden"} zIndex={1000}>
          <PopoverBody p={0} zIndex={1000}>
            <VStack align="flex-start" gap={0}>
              {children && children}
              {optionItems &&
                optionItems.map((item, index) => (
                  <OptionMenuItem
                    key={index}
                    icon={item.icon}
                    label={item.label}
                    onClick={item.onClick}
                  />
                ))}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

export default OptionMenuButton
