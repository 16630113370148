import { ServerBlockTypeType } from "@pathwright/blocks-core"
import PropTypes from "prop-types"
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from "react"
import { getBlockType, getBlockTypeLabel, getStyles } from "../block/utils"
import { useBlocksConfig } from "../config/BlocksConfigProvider"
import {
  SyncedReducerReturnType,
  useSyncedReducer
} from "../syncer/useSyncedReducer"
import * as types from "../types"
import { scopeContentData } from "../utils/scope"
import { createID, generateBlockClip } from "../utils/utils"
import * as constants from "./constants"
import {
  EditorReducerActionType,
  EditorStateType,
  getInitialState,
  reducer
} from "./editorState"

declare global {
  interface Window {
    logContentState: () => void
  }
}

// ESP = EditorStateProvider
export namespace ESPTypes {
  export type Props = {
    children: ReactNode | ((props: MergedEditorContextValueType) => ReactNode)
    syncer: (
      action: EditorReducerActionType,
      state: EditorStateType,
      dispatch: (action: EditorReducerActionType) => void
    ) => void
    content: types.BlocksContentType
    blockTypes: ServerBlockTypeType[]
    blocksContext: types.BlocksContextType
    contentLoading?: boolean
  }

  export type BlocksContext = {
    blocksContext: types.BlocksContextType | {}
    blockTypes: ServerBlockTypeType[]
  }

  export type Mode = {
    mode?: constants.EDITOR_MODE_TYPE
  }

  export type ContentState = {
    changes?: types.BlocksChanges
    isPublished?: boolean
    publishing?: boolean
    discarding?: boolean
    error?: Error
    syncing?: boolean
    lastPublished?: number
    lastModified?: number
    createdBy?: string
  }

  export type BlocksState = {
    blocks: types.BlockType[]
    blocksAreEmpty?: boolean
  }

  export type BlockState = {
    deleteBlockID?: string
    copiedBlockClip?: types.CopiedBlockClipType
    pastingBlockIndex?: number
  }

  export type Handlers = {
    handleBlockChange: (
      change: Partial<types.BlockDataType>,
      blockId: string
    ) => void
    handleToggleMode: () => void
    handlePublishContent: () => void
    handleDiscardDraftContent: () => void
    handleAddBlock: (
      type: string,
      order: number,
      data: types.BlockDataType,
      layout: string,
      style: types.BlockStyleType
    ) => void
    handleCopyBlock: (block?: types.BlockType) => void
    handlePasteBlock: (index: number) => Promise<void>
    handleMoveUp: (oldIndex: number, id: string) => void
    handleMoveDown: (oldIndex: number, id: string) => void
    handleBlockDelete: (blockId: string) => Promise<void>
    handleCancelBlockDelete: () => void
    handleLayoutChange: (blockId: string, layout: string) => void
    handleUpdateBlockStyles: (
      block: types.BlockType,
      style: types.BlockStyleType
    ) => void
  }
}

export type MergedEditorContextValueType = ESPTypes.BlocksContext &
  ESPTypes.Mode &
  ESPTypes.ContentState &
  ESPTypes.BlocksState &
  ESPTypes.BlockState &
  ESPTypes.Handlers

const EditorBlocksContextContext = createContext<ESPTypes.BlocksContext>({
  blocksContext: {},
  blockTypes: []
})
export const useEditorBlocksContext = (): ESPTypes.BlocksContext =>
  useContext(EditorBlocksContextContext)

const EditorModeContext = createContext<ESPTypes.Mode>({})
export const useEditorMode = () => useContext(EditorModeContext)

const EditorContentContext = createContext<ESPTypes.ContentState>({})
export const useEditorContent = () => useContext(EditorContentContext)

const EditorBlocksContext = createContext<ESPTypes.BlocksState>({ blocks: [] })
export const useEditorBlocks = () => useContext(EditorBlocksContext)

const EditorBlockContext = createContext<ESPTypes.BlockState>({})
export const useEditorBlock = () => useContext(EditorBlockContext)

const EditorDispatchContext = createContext<Partial<ESPTypes.Handlers>>({})
export const useEditorDispatch = () => useContext(EditorDispatchContext)

export const EditorStateProvider = ({ children, ...props }: ESPTypes.Props) => {
  const { blockTypes, content, blocksContext, syncer, contentLoading } = props

  const {
    copyBlock,
    copiedBlock: copiedBlockFromContext,
    contextKey
  } = useBlocksConfig()

  const [state, dispatch]: SyncedReducerReturnType<
    EditorStateType,
    EditorReducerActionType
  > = useSyncedReducer<
    EditorStateType,
    EditorReducerActionType,
    {
      content: types.BlocksContentType
      blockTypes: ServerBlockTypeType[]
    }
  >(reducer, syncer, { content, blockTypes }, getInitialState)

  const {
    error,
    syncing,
    mode,
    lastModified,
    lastPublished,
    createdBy,
    copiedBlockClip,
    pastingBlockIndex,
    publishing,
    discarding,
    deleteBlockID,
    blocks,
    changes
  } = state

  // Debug the Editor state
  window.logContentState = useCallback(() => {
    const nextState = reducer(state, { type: constants.GET_STATE })
    const { blockTypes, ...logState } = nextState
    console.log("Editor state: ", JSON.stringify(logState, null, 2))
  }, [state])

  // Sync props copiedBlock to local state
  useEffect(() => {
    if (copiedBlockFromContext) {
      dispatch({
        type: constants.SET_COPIED_BLOCK,
        payload: {
          copiedBlockClip: copiedBlockFromContext
        }
      })
    }
  }, [copiedBlockFromContext])

  // Update parent after being finished with copiedBlockClip
  useEffect(() => {
    if (copyBlock && copiedBlockFromContext && !copiedBlockClip) {
      copyBlock(null)
    }
  }, [copiedBlockClip])

  // Respond to Apollo cache publish updates
  useEffect(() => {
    window.addEventListener("blocksPublished", (event: any) => {
      const { contextKey: eventKey } = event.detail

      if (eventKey !== contextKey) return

      dispatch({
        type: constants.SYNCED_PUBLISH,
        payload: content
      })
    })
  }, [])

  // Update local state when we get new content.changes from the server
  // The Step dropdown's View history section can become stale when making a Blocks change
  useEffect(() => {
    dispatch({
      type: constants.SET_CHANGES,
      payload: {
        changes: content?.changes
      }
    })
  }, [content?.changes])

  const handleToggleMode = useCallback((): void => {
    dispatch({ type: constants.TOGGLE_MODE })
  }, [])

  const handlePublishContent = useCallback(async (): Promise<void> => {
    dispatch({ type: constants.START_PUBLISH })
  }, [])

  const handleDiscardDraftContent = useCallback(async (): Promise<void> => {
    dispatch({ type: constants.START_DISCARD_DRAFT })
  }, [])

  // Pass style and data in case we're pasting a block
  const handleAddBlock = useCallback(
    (
      type: string,
      order: number,
      data: types.BlockDataType,
      layout: string,
      style?: types.BlockStyleType | undefined
    ): void => {
      // Block IDs are created client-side
      // We create the new block ID here so we can pass it to the syncer
      const id: string = createID()

      const newBlock: types.BlockType = {
        type,
        data,
        layout,
        style,
        order,
        id
      }

      const action = {
        type: constants.ADD_BLOCK,
        payload: {
          ...newBlock
        }
      } as const

      dispatch(action)
    },
    []
  )

  const handleCopyBlock = useCallback((block?: types.BlockType): void => {
    // Clear copied block
    if (!block) {
      if (copyBlock) copyBlock(null)
      dispatch({ type: constants.CLEAR_COPIED_BLOCK })
      return
    }

    const { layout, type } = block

    // Generate a copied block clip
    const blockLabel: string = getBlockTypeLabel({ type, layout, blockTypes })
    const copiedBlockClip: types.CopiedBlockClipType = generateBlockClip(
      block,
      content.id,
      blockLabel
    )

    // Set the clip in state
    dispatch({
      type: constants.SET_COPIED_BLOCK,
      payload: {
        copiedBlockClip
      }
    })

    // Use clipboard copy function prop if available
    if (copyBlock) {
      return copyBlock(copiedBlockClip)
    }

    // If not clipboard is available, the fallback is to simply duplicate the block immediately below
    handleAddBlock(
      block.type,
      block.order + 1,
      scopeContentData(block.data, getBlockType(block.type, blockTypes)),
      block.layout,
      getStyles(block)
    )
  }, [])

  const handlePasteBlock = useCallback(
    async (index: number = 0): Promise<void> => {
      const action = {
        type: constants.PASTE_BLOCK,
        payload: {
          index
        }
      } as const

      dispatch(action)

      // // Clear the parent
      if (copyBlock) {
        copyBlock(null)
      }
    },
    []
  )

  const handleMoveUp = useCallback((oldIndex: number, id: string): void => {
    dispatch({
      type: constants.MOVE_BLOCK_UP,
      payload: {
        oldIndex,
        id
      }
    })
  }, [])

  const handleMoveDown = useCallback((oldIndex: number, id: string): void => {
    dispatch({
      type: constants.MOVE_BLOCK_DOWN,
      payload: {
        oldIndex,
        id
      }
    })
  }, [])

  const handleBlockDelete = useCallback(async (id: string): Promise<void> => {
    dispatch({ type: constants.DELETE_BLOCK, payload: { id } })
  }, [])

  const handleCancelBlockDelete = useCallback(() => {
    dispatch({ type: constants.CLEAR_DELETE_BLOCK })
  }, [])

  const handleDataChange = useCallback(
    (data: types.BlockDataType, id: string) => {
      const action = {
        type: constants.UPDATE_BLOCK_DATA,
        payload: {
          id,
          block: {
            data
          }
        }
      } as const

      dispatch(action)
    },
    []
  )

  const handleLayoutChange = useCallback(
    (id: string, layout: string) => {
      const action = {
        type: constants.UPDATE_BLOCK_LAYOUT,
        payload: {
          id,
          layout,
          blockTypes
        }
      } as const

      dispatch(action)
    },
    [blockTypes]
  )

  const handleUpdateBlockStyles = useCallback(
    (block: types.BlockType, style: types.BlockStyleType) => {
      const action = {
        type: constants.UPDATE_BLOCK_STYLE,
        payload: {
          id: block.id,
          style: style
        }
      } as const

      dispatch(action)
    },
    []
  )

  const isPublished: boolean = useMemo(
    () => lastPublished! >= lastModified!,
    [lastPublished, lastModified]
  )
  const blocksAreEmpty: boolean = useMemo(
    () => !blocks.length,
    [blocks?.length]
  )

  // Note: these handlers should have no dependencies and should access state via _getState()
  const handlers: ESPTypes.Handlers = useMemo(
    () => ({
      handleToggleMode,
      handlePublishContent,
      handleDiscardDraftContent,
      handleAddBlock,
      handleCopyBlock,
      handlePasteBlock,
      handleMoveUp,
      handleMoveDown,
      handleBlockDelete,
      handleCancelBlockDelete,
      handleBlockChange: handleDataChange,
      handleLayoutChange,
      handleUpdateBlockStyles
    }),
    []
  )

  const blocksContextValue: ESPTypes.BlocksContext = useMemo(
    () => ({ blocksContext, blockTypes }),
    [blocksContext, blockTypes]
  )
  const modeValue: ESPTypes.Mode = useMemo(() => ({ mode }), [mode])
  const editorContentValue: ESPTypes.ContentState = useMemo(
    () => ({
      isPublished,
      publishing,
      discarding,
      error,
      syncing,
      lastModified,
      lastPublished,
      createdBy,
      changes,
      contentLoading
    }),
    [
      isPublished,
      error,
      syncing,
      publishing,
      discarding,
      lastModified,
      lastPublished,
      createdBy,
      changes,
      contentLoading
    ]
  )
  const blockValue: ESPTypes.BlockState = useMemo(
    () => ({
      deleteBlockID,
      copiedBlockClip,
      pastingBlockIndex
    }),
    [deleteBlockID, copiedBlockClip, pastingBlockIndex]
  )
  const blocksValue: ESPTypes.BlocksState = useMemo(
    () => ({ blocks, blocksAreEmpty }),
    [blocks]
  )

  const renderChildren =
    typeof children === "function"
      ? (val: MergedEditorContextValueType) => children(val)
      : (val: any) => children

  // Using separate Context providers to avoid re-renders in consumers
  // https://github.com/facebook/react/issues/15156#issuecomment-474590693
  return (
    <EditorBlocksContextContext.Provider value={blocksContextValue}>
      <EditorContentContext.Provider value={editorContentValue}>
        <EditorModeContext.Provider value={modeValue}>
          <EditorBlocksContext.Provider value={blocksValue}>
            <EditorBlockContext.Provider value={blockValue}>
              <EditorDispatchContext.Provider value={handlers}>
                {renderChildren({
                  ...blocksContextValue,
                  ...editorContentValue,
                  ...modeValue,
                  ...blocksValue,
                  ...blockValue,
                  ...handlers
                })}
              </EditorDispatchContext.Provider>
            </EditorBlockContext.Provider>
          </EditorBlocksContext.Provider>
        </EditorModeContext.Provider>
      </EditorContentContext.Provider>
    </EditorBlocksContextContext.Provider>
  )
}

EditorStateProvider.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lastModifiedDateTime: PropTypes.number,
    lastPublishedDateTime: PropTypes.number,
    blocks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired,
        data: PropTypes.object.isRequired
      })
    )
  }),
  blockTypes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      menu: PropTypes.shape({
        category: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired
      }).isRequired,
      layouts: PropTypes.objectOf(
        PropTypes.shape({
          data: PropTypes.object.isRequired,
          label: PropTypes.string.isRequired,
          key: PropTypes.string.isRequired
        })
      ),
      helpLink: PropTypes.string,
      defaults: PropTypes.object,
      __typename: PropTypes.string,
      fields: PropTypes.objectOf(
        PropTypes.shape({
          isInterfaceField: PropTypes.bool,
          isList: PropTypes.bool,
          name: PropTypes.string,
          type: PropTypes.string.isRequired,
          scope: PropTypes.string.isRequired,
          required: PropTypes.bool
        })
      )
    })
  ),
  blocksContext: PropTypes.shape({
    accountID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    accessToken: PropTypes.string,
    mediaStoragePath: PropTypes.string.isRequired
  }),
  syncer: PropTypes.func.isRequired
}

export default EditorStateProvider
