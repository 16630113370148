import {
  Box,
  Checkbox,
  HStack,
  LightMode,
  Link,
  Radio,
  Text
} from "@chakra-ui/react"
import {
  FormatOptionLabelMeta,
  GroupBase,
  MultiValue,
  OptionBase,
  Props,
  Select,
  SingleValue,
  useChakraSelectProps
} from "chakra-react-select"
import { useEffect, useMemo, useRef, useState } from "react"
import { usePreviousDistinct } from "react-use"
import { RegistrationRoleEnum } from "../../api/generated"
import Pathicon from "../../pathicon/Pathicon"
import { GroupAvatars } from "../../unified-home/components/GroupAvatars"
import RoleBadge, {
  RoleBadgeItem,
  RoleOption,
  getRoleOptions
} from "./RoleBadge"
import { getSelectCustomComponents } from "./components/select"
import { Cohort, CohortRole, ResourceRole, SelectedCohort } from "./state"

interface SelectRoleOption extends RoleOption, OptionBase {}

// Note: having ts issues when extending to include the typed label.
interface SelectRoleGroupOption extends GroupBase<SelectRoleOption> {} // {label: "Path" | "Cohort"}

const selectComponents = getSelectCustomComponents<
  SelectRoleOption,
  boolean,
  SelectCustomProps
>()

const MultiValueRemove = (
  props: Parameters<typeof selectComponents.MultiValueRemove>[0]
) =>
  // Only allowing option to be cleared if not found in initially selected options.
  isOptionClearable({
    option: props.data,
    initiallySelectedOptions: props.selectProps._initiallySelectedOptions
  }) ? (
    <selectComponents.MultiValueRemove {...props} />
  ) : null

// Add learn more link to menu.
const Menu = (props: Parameters<typeof selectComponents.Menu>[0]) => (
  <LightMode>
    <selectComponents.Menu {...props}>
      {props.children}
      <Link
        href="https://help.pathwright.com/en/articles/2083448-pathwright-member-roles"
        target="_blank"
        textDecor="underline"
        cursor="pointer"
      >
        <HStack p={2}>
          <Pathicon icon="lightbulb" />
          <Text>Learn more about roles...</Text>
        </HStack>
      </Link>
    </selectComponents.Menu>
  </LightMode>
)

// Pass any custom props to components
type SelectCustomProps = { _initiallySelectedOptions?: SelectRoleOption[] }

// Note: Typescript is not able to infer types for chakra-react-select some reason.
// Have to explicitly type the generics: https://react-select.com/typescript
type SelectProps = Props<
  SelectRoleOption,
  boolean,
  GroupBase<SelectRoleOption>
> &
  SelectCustomProps

const pathRoles: ResourceRole[] = [RegistrationRoleEnum.Editor, undefined]
const cohortRoles: CohortRole[] = [
  RegistrationRoleEnum.Student,
  RegistrationRoleEnum.Moderator,
  RegistrationRoleEnum.Teacher,
  undefined,
  null
]

function useCustomChakraSelectProps(): SelectProps {
  return useChakraSelectProps({
    chakraStyles: {
      container: (provided, state) => ({
        ...provided,
        w: "100%"
      }),
      option: (provided, state) => ({
        ...provided,
        color: "gray.900",
        w: "initial",
        bg:
          state.isFocused && state.isSelected
            ? "gray.300"
            : state.isFocused
            ? "gray.200"
            : state.isSelected
            ? "gray.100"
            : "transparent"
      }),
      group: (provided, state) =>
        // Style cohort group differently.
        state.data.label === "Cohort"
          ? {
              ...provided,
              bg: "gray.50"
            }
          : provided,
      groupHeading: (provided, state) =>
        // Style cohort group differently.
        state.data.label === "Cohort"
          ? {
              ...provided,
              bg: "gray.50"
            }
          : provided,
      menu: (provided, state) => ({
        ...provided,
        bg: "white",
        borderRadius: "xl",
        // Hide overflowing bg of focused/selected items at start/end of menu.
        overflow: "hidden"
      }),
      menuList: (provided, state) => ({
        ...provided,
        padding: 0,
        border: "none",
        bg: "transparent"
      }),
      placeholder: (provided) => ({
        ...provided,
        paddingLeft: "2px",
        fontSize: { base: "sm", md: undefined },
        noOfLines: 1
      }),
      control: (provided, state) => ({
        ...provided,
        padding: "0 .4em",
        border: "1px solid",
        borderColor: "gray.100",
        color: "gray.900",
        borderRadius: "xl"
      }),
      clearIndicator: (provided) => ({
        ...provided,
        m: 0,
        mr: 1
      }),
      valueContainer: (provided) => ({
        ...provided,
        pl: 0
      }),
      multiValueRemove: (provided) => ({
        ...provided,
        ml: 0
      })
    }
  })
}

// Filter role options by a set of roles.
// This can be used to group role options by role.
function groupRoleOptionsByRole<T extends SelectRoleOption>(
  roleOptions: T[],
  filterRoles: T["value"][]
): SelectRoleOption[] {
  return roleOptions.filter((roleOption) => {
    return filterRoles.includes(roleOption.value)
  })
}

function sortRoleOptionsByRole(roleOptions: SelectRoleOption[]) {
  // Based on the order of these roles:
  const roles = [...pathRoles, ...cohortRoles]
  // Sort higest roles to front for consistent ordering.
  return roleOptions.sort((optionA, optionB) => {
    function getIndex(option: SelectRoleOption) {
      return roles.findIndex((role) => option.value === role)
    }
    // Sort null values to the end.
    const valueA = getIndex(optionA)
    const valueB = getIndex(optionB)
    return valueA - valueB
  })
}

function isOptionClearable({
  option,
  initiallySelectedOptions
}: {
  option: SelectRoleOption
  initiallySelectedOptions?: SelectRoleOption[]
}) {
  // User can clear option as long as it isn't found in the initial values.
  const isClearable = initiallySelectedOptions?.every(
    (selectedOption) => option.value !== selectedOption.value
  )

  return Boolean(isClearable)
}

type Roles = {
  resourceRole: ResourceRole
  cohortRole: CohortRole
}

export type RoleSelectorOnChange = (value: Roles) => void

type RoleSelectorProps = {
  value: Roles
  cohort: SelectedCohort | Cohort
  onChange: RoleSelectorOnChange
}

function RoleSelector({ value, cohort, onChange }: RoleSelectorProps) {
  const [selectedOptions, setSelectedOptions] = useState<SelectRoleOption[]>([])
  const chakraSelectProps = useCustomChakraSelectProps()
  const options = getRoleOptions({
    // TODO: get these from permissions.
    roles: [
      RegistrationRoleEnum.Editor,
      RegistrationRoleEnum.Teacher,
      RegistrationRoleEnum.Moderator,
      RegistrationRoleEnum.Student,
      RegistrationRoleEnum.Observer
    ],
    selecedRoles: selectedOptions
  })

  // Group options by Path and Cohort.
  const groupedOptions = [
    {
      label: "Path",
      options: sortRoleOptionsByRole(groupRoleOptionsByRole(options, pathRoles))
    },
    {
      label: "Cohort",
      options: sortRoleOptionsByRole(
        groupRoleOptionsByRole(options, cohortRoles)
      )
    }
  ]

  function formatGroupLabel(group: SelectRoleGroupOption) {
    return group.label === "Cohort" && cohort ? (
      "id" in cohort ? (
        <GroupAvatars
          group={cohort}
          showGroupName
          fontColor="blackAlpha.900"
          noOfLines={1}
        />
      ) : (
        <HStack>
          <Pathicon icon="group" />
          <Text as="span">
            <strong>{cohort.name}</strong> {group.label}:
          </Text>
        </HStack>
      )
    ) : null
  }

  function formatOptionLabel(
    option: SelectRoleOption,
    meta: FormatOptionLabelMeta<SelectRoleOption>
  ) {
    const isSelected = meta.selectValue.some(
      (selected) => selected.value === option.value
    )

    const InputComponent =
      option.value === RegistrationRoleEnum.Editor ? Checkbox : Radio

    // Let's disable the input component when option isn't clearable.
    const isClearable = isOptionClearable({
      option,
      initiallySelectedOptions: initiallySelectedOptionsRef.current
    })

    // Contextualize the option label, more verbose version used in menu.
    return meta.context === "menu" ? (
      <HStack w="100%" justifyContent="flex-start">
        <InputComponent
          // Don't allow tabing to these "dummy" checkboxes.
          tabIndex={-1}
          isChecked={isSelected}
          borderColor="gray.300"
          isDisabled={!isClearable}
        />
        <Box>
          <RoleBadge badges={option.badges} />
          <Text m={0} ml="2" display="inline">
            {option.description}
          </Text>
        </Box>
      </HStack>
    ) : (
      // <RoleBadge badges={option.badges} />
      // Trying out showing the badges without the + in between.
      <HStack>
        {option.badges.map((badge) => (
          <RoleBadgeItem key={badge.label} badge={badge} />
        ))}
      </HStack>
    )
  }

  // We allow multi-select, but this means the user can select all roles,
  // but we really want a hybrid where the user can only select one role per group.
  // Here we manually set the selected options, removing any option that is already
  // selected in the same group.
  function handleChange(
    selected: SingleValue<SelectRoleOption> | MultiValue<SelectRoleOption>
  ) {
    // Set selected options, forcing array.
    // Removing any previously selected items in the same group as each selected item.
    const groupedSelected = ([] as SelectRoleOption[])
      .concat(selected as SelectRoleOption[])
      // Merge in the initially selected options so that they aren't removed.
      .concat(initiallySelectedOptionsRef.current)
      .reduce<Record<string, SelectRoleOption>>((acc, option) => {
        const group = groupedOptions.find((g) =>
          g.options.some((o) => o.value === option.value)
        )
        const groupLabel = group?.label

        // Based on the group label, we set the selected option, which will overwrite
        // any previously set option for that group label. React select must order the
        // selected options by pushing the most recently selected option to the end of the list.
        if (groupLabel) {
          acc[groupLabel] = option
        }
        return acc
      }, {})

    // Finally, set the selected options.
    setSelectedOptions(sortRoleOptionsByRole(Object.values(groupedSelected)))
  }

  const preivousSelected = usePreviousDistinct(selectedOptions)

  // Whenever the selected options change, fire an onChange callback with the roles.
  useEffect(() => {
    // Only call onChange when local state has changed from initial.
    if (preivousSelected) {
      // Map the grouped options to grouped roles.
      const groupedRoles = groupedOptions.map((grouped) =>
        grouped.options.map((option) => option.value)
      )

      // Reduce the grouped roles to the (possibly) selected group role.
      // If not selected, we'll simply set the role to null.
      const [resourceRole, cohortRole] = groupedRoles.reduce((acc, roles) => {
        const role = roles.find((role) =>
          selectedOptions.some((selected) => selected.value === role)
        )
        const roleValue = typeof role !== "undefined" ? role : role
        acc.push(roleValue)
        return acc
      }, []) as [ResourceRole, CohortRole] // Must constrain to ResourceRole and CohortRole for dispatching.

      onChange({
        resourceRole,
        cohortRole
      })
    }
  }, [selectedOptions])

  // Get the selected options
  function getSelectedOptions(roles: Roles) {
    const { resourceRole, cohortRole } = roles

    const resourceRoleOption = groupedOptions[0].options.find(
      (option) => option.value === resourceRole
    )
    const cohortRoleOption = groupedOptions[1].options.find(
      (option) => option.value === cohortRole
    )

    const selectedOptions = []
    if (resourceRoleOption) selectedOptions.push(resourceRoleOption)
    if (cohortRoleOption) selectedOptions.push(cohortRoleOption)

    return sortRoleOptionsByRole(selectedOptions)
  }

  // Sync local state with parent.
  useEffect(() => {
    setSelectedOptions(getSelectedOptions(value))
  }, [value.resourceRole, value.cohortRole])

  // Store the initially selected options so that we can ensure
  // they are not removable. This means that a user can not unselect
  // a role they already have in a path/cohort.
  const initiallySelectedOptionsRef = useRef(
    useMemo(() => getSelectedOptions(value), [])
  )

  // Determine if user can clear the select input based on if there are any
  // currently selected options that are clearable.
  const isClearable = useMemo(() => {
    return selectedOptions.some((option) =>
      isOptionClearable({
        option,
        initiallySelectedOptions: initiallySelectedOptionsRef.current
      })
    )
  }, [value])

  // User can only select 2 options. We know that a cohort role is required,
  // but we can't pass a fn to Select to assess if the immediately selected
  // item should permit closing the menu.
  // We can assume the user will select a second item when there is only 1
  // selected item, and replace a selected item when there are 2 selected items.
  const closeMenuOnSelect = selectedOptions.length >= 1

  return (
    <Select
      options={groupedOptions}
      value={selectedOptions}
      onChange={handleChange}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
      placeholder="Your roles..."
      variant="unstyled"
      isClearable={isClearable}
      escapeClearsValue
      hideSelectedOptions={false}
      isMulti={true}
      isSearchable={false}
      autoFocus
      openMenuOnFocus
      closeMenuOnSelect={closeMenuOnSelect}
      _initiallySelectedOptions={initiallySelectedOptionsRef.current}
      {...{
        ...chakraSelectProps,
        components: {
          ...chakraSelectProps,
          ...selectComponents,
          // Locally custom components.
          MultiValueRemove,
          Menu
        }
      }}
    />
  )
}

export default RoleSelector
