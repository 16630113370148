import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { getSchoolUrl } from "../utils/urls"
import { UnifiedHomeRegistrationFragment } from "../api/generated"

type getCertificateDownloadUrlProps = {
  pwContext: ReturnType<typeof usePathwrightContext>
  registration: any
}

//adapted from src/modules/home/learn/utils.js
export const getCertificateDownloadUrl = ({
  pwContext,
  registration
}: getCertificateDownloadUrlProps) => {
  // ensure resource has certificate and path is complete
  if (
    registration.path.progress < 1 ||
    !registration.path.completion_date ||
    !pwContext.school.has_certificate ||
    !registration.resource?.has_certificate
  )
    return null
  // other users shouldn't have access to certificate
  if (!pwContext.me || pwContext.me.id !== registration.user.id) return null

  return getSchoolUrl(
    `/certgen/certificate/${registration.resource.slug}/${
      registration.path.id
    }?sso=${localStorage.getItem("auth_token")}`
  )
}

export const getResourceLink = (registration: any) => {
  const resource = registration?.resource!
  const groupId = registration?.group
    ? registration?.group?.id
    : registration?.id
  return {
    pathURL: `/library/${resource?.slug}/${groupId}/path/`
  }
}
//! This function is not being used in the codebase
//Maps a number between a threshold range to a value between 0 and 1
export const mapToRange = (
  value: number,
  minThreshold: number,
  maxThreshold: number
) => {
  // Ensure value is within the bounds of minThreshold and maxThreshold
  if (value < minThreshold || value > maxThreshold) {
    // throw new Error("Value out of bounds")
    console.debug("Value out of bounds:", value)
  }
  // if

  if (value >= maxThreshold) {
    return 1
  }
  // Normalize the value to a range between 0 and 1
  return (value - minThreshold) / (maxThreshold - minThreshold)
}
