import IconButton from "@pathwright/ui/src/components/button/IconButton"
import ReactSelectInput from "@pathwright/ui/src/components/form/form-select/ReactSelectInput"
import useDebouncedValue from "@pathwright/ui/src/components/hooks/useDebouncedValue"
import usePreviousEffect from "@pathwright/ui/src/components/hooks/usePreviousEffect"
import Resource from "@pathwright/ui/src/components/library/Resource"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import get from "lodash/get"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { useAssignResourceQuery } from "../api/generated"
import { flattenEdges, usePaginator } from "../utils/apollo"
import { getAssignConfig } from "./utils"

const AssignResourceCohort = ({
  resourceId,
  cohortId,
  cohort,
  role,
  onSelectCohort,
  onUnselectCohort,
  onUnselectResource
}) => {
  const { t } = useTranslate()

  const [search, debouncedSearch, debouncingSearch, handleDebouncedSearch] =
    useDebouncedValue(300)

  const assignResourceQuery = useAssignResourceQuery({
    variables: {
      id: resourceId,
      assignConfig: getAssignConfig(role),
      search: debouncedSearch || null
    }
  })

  const { loading } = assignResourceQuery

  const { loadMore, hasMore, loadingMore } = usePaginator({
    data: assignResourceQuery, // hacky
    path: "resource.cohorts"
  })

  const resource = flattenEdges(get(assignResourceQuery, "data.resource"))

  // Get filtered cohorts for role
  const cohorts = resource ? resource.cohorts : []
  const searching = !!search || !!debouncedSearch

  useEffect(() => {
    if (resource) {
      let selectedCohort = cohort
      if (!selectedCohort && cohortId) {
        selectedCohort = cohorts.find((cohort) => cohort.id === cohortId)
      }
      onSelectCohort(resource, selectedCohort)
    }
  }, [resource && resource.id, cohortId])

  // whenever the assignResourceQuery changes (due to change in query variables), update the selected cohort
  usePreviousEffect(
    ([prevAssignResourceQuery]) => {
      // did query changed due to change in search value?
      const searching =
        get(prevAssignResourceQuery, "variables.search") !==
        get(assignResourceQuery, "variables.search")

      // only proceed if the query change is NOT a result of searching, otherwise we might unselect a cohort after the cohorts are not longer filtered by previous search value
      if (!searching) {
        if (cohort && !cohorts.find((c) => c.id === cohort.id)) {
          // remove the cohort if it no longer exists in the available cohorts
          onUnselectCohort(cohort)
        } else {
          onSelectCohort(resource, cohort)
        }
      }
    },
    [assignResourceQuery]
  )

  const getMeta = () => {
    if (cohort) {
      if (cohort.is_master) {
        return t("Source")
      } else {
        if (cohorts.length > 1) {
          return (
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                whiteSpace: "pre"
              }}
              onClick={() => onUnselectCohort(cohort)}
            >
              <Pathicon icon="flip-horizontal" />
              <span> </span>
              {cohort.name}
            </div>
          )
        }
        return cohort.name
      }
    } else if (cohorts.length === 0 && !loading && !searching) {
      // For now, warn that no group exists
      return (
        <>
          <Pathicon icon="caution-triangle" /> {t("No cohort available")}
        </>
      )
    }
  }

  const controls = (
    <IconButton icon="x" onClick={() => onUnselectResource(resource)} />
  )

  return (
    <Resource
      layout="list"
      resource={resource}
      loading={!resource}
      meta={getMeta()}
      controls={controls}
    >
      {!cohort && (cohorts.length > 1 || searching) && (
        <ReactSelectInput
          autoFocus
          options={cohorts}
          isLoading={loading || loadingMore || debouncingSearch}
          onMenuScrollToBottom={hasMore && loadMore}
          placeholder={`${t("Select cohort")}...`}
          noOptionsMessage={() => t("No cohort found")}
          value={cohortId}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
          isSearchable
          onInputChange={(val) => {
            handleDebouncedSearch(val)
            // return val for react-select to handle
            return val
          }}
          onChange={(cohort) => onSelectCohort(resource, cohort)}
        />
      )}
    </Resource>
  )
}

AssignResourceCohort.displayName = "AssignResourceCohort"

AssignResourceCohort.propTypes = {
  resourceId: PropTypes.number,
  cohortId: PropTypes.number,
  resource: PropTypes.object,
  onSelectCohort: PropTypes.func,
  onUnselectResource: PropTypes.func
}

AssignResourceCohort.defaultProps = {
  resourceId: null,
  cohortId: null,
  resource: null,
  onSelectCohort: () => {},
  onUnselectResource: () => {}
}

export default AssignResourceCohort
