import {
  usePathQuery,
  useSyncPathMutation
} from "@pathwright/web/src/modules/api/generated"
import get from "lodash/get"
import { usePathwrightClient } from "../../pathwright/PathwrightClient"

const usePath = ({
  pathId,
  cohortId,
  forceReload,
  ensureConsistentRead = false,
  skip = false
}) => {
  const client = usePathwrightClient()
  const pathQuery = usePathQuery({
    variables: {
      id: pathId, // optional
      cohort_id: cohortId
    },
    skip: (!cohortId && !pathId) || skip,
    fetchPolicy: forceReload ? "network-only" : "cache-first",
    // Ensure we reload from the main db and not from the read replica
    // if ensureConsistentRead is true.
    client: ensureConsistentRead ? client._consistentReadClient : client
  })
  const { data, error, loading, refetch } = pathQuery
  const path = get(data, "path")

  const [
    syncPath,
    {
      // loading: syncing // handling syncing state
      error: syncError,
      loading: syncingPath
    }
  ] = useSyncPathMutation({
    variables: {
      id: path?.id
    }
  })

  const handleRefetch = async () => {
    if (!loading && cohortId) refetch()
  }

  const handleSyncPath = async () => {
    if (!syncingPath) {
      await syncPath()
    }
  }

  const loaded = !loading && !error && !syncError && !!path

  return {
    path,
    error: error || syncError,
    loaded,
    loading,
    syncing: syncingPath,
    syncPath: handleSyncPath,
    refetch: handleRefetch
  }
}

export default usePath
