import gql from "graphql-tag"
import { DiscussionPostFragmentDoc } from "../../api/generated"

export default gql`
  mutation DeleteDiscussionLike(
    $context: DiscussionContextInput!
    $discussion_id: Int!
  ) {
    deleteDiscussionLike(context: $context, discussion_id: $discussion_id) {
      ...DiscussionPost
    }
  }
  ${DiscussionPostFragmentDoc}
`
