import { default as AdminDashboard } from "@pathwright/web/src/modules/dashboard/Dashboard"
import Dashboard, {
  DASHBOARD_SECTIONS
} from "@pathwright/web/src/modules/home/Dashboard"
import AcceptCommunityGroupInvitationCard from "@pathwright/web/src/modules/invitation/AcceptCommunityGroupInvitationCard"
import MembershipCard from "@pathwright/web/src/modules/membership/MembershipCard"
import AddMentorGroupCard from "@pathwright/web/src/modules/mentor-group/groups/add/AddMentorGroupCard"
import ManageMentorGroupCard from "@pathwright/web/src/modules/mentor-group/manage/ManageMentorGroupCard"
import AddMentorGroupMembersCard from "@pathwright/web/src/modules/mentor-group/members/add/AddMentorGroupMembersCard"
import MentorGroupMembersCard from "@pathwright/web/src/modules/mentor-group/members/list/MentorGroupMembersCard"
import MentorGroupMembershipSettingsCard from "@pathwright/web/src/modules/mentor-group/members/list/MentorGroupMembershipSettingsCard"
import { inviteCardRoute } from "invitation/invite/routes"
import { createCardRoutes } from "lib/core/routing/card-routing"
import get from "lodash/get"
import path from "path-browserify"
import { assignCardRoutes, profileCardRoute } from "profile/routes"
import StoreOrLibraryBrowse from "store/StoreOrLibraryBrowse"
import { authRedirect } from "../lib/core/routing/middleware"

// MEMBERS ROUTES (linked from /dashboard)

// community/members/ -> MembershipsCard
// community/members/invite/ -> InviteCard
// community/members/invite/invitation -> InvitationsCard
// community/members/123/ -> ProfileCard

// GROUPS ROUTES (linked from /dashboard)

// community/groups/ -> MentorGroupsCard
// community/groups/add-group/ -> AddMentorGroupCard
// community/groups/456/ -> MentorGroupMembersCard
// community/groups/456/add-group/ -> AddMentorGroupMembersCard
// community/groups/456/manage/ -> ManageMentorGroup
// community/groups/456/123/ -> ProfileCard

// DIRECT ROUTES (linked from /home/teach)

// community/456/ -> MentorGroupMembersCard
// community/456/add-group/ -> AddMentorGroupMembersCard
// community/456/manage/ -> ManageMentorGroup
// community/456/profile/ -> ProfileCard

function CommunityPrimaryFallback(props) {
  const { user, is_authenticated } = window.App.getStore("auth").getState()
  if (is_authenticated) {
    if (user.membership && user.membership.can_manage_school) {
      return <AdminDashboard />
    }
    return <Dashboard section={DASHBOARD_SECTIONS.TEACH} />
  }
  return <StoreOrLibraryBrowse {...props} />
}

CommunityPrimaryFallback.displayName = "CommunityPrimaryFallback"

const getMentorGroupRoutes = (depth = 5) => {
  const groupIdVar = `cohortId${depth}`

  return {
    group: {
      middleware: authRedirect,
      path: `:${groupIdVar}(\\d+)/`,
      component: (props) => (
        <MentorGroupMembersCard
          {...props}
          groupId={parseInt(props.params[groupIdVar])}
        />
      ),
      childCards: {
        "add-group": {
          component: ({ card, params }) => (
            <AddMentorGroupCard
              card={card}
              parentGroupId={parseInt(params[groupIdVar])}
            />
          )
        },
        "add-member": {
          component: (props) => (
            <AddMentorGroupMembersCard
              {...props}
              id={parseInt(props.params[groupIdVar])}
              onSubmitSuccess={() =>
                window.App.navigate(path.join(window.location.pathname, "../"))
              }
            />
          )
        },
        manage: {
          component: (props) => (
            <ManageMentorGroupCard
              {...props}
              id={parseInt(props.params[groupIdVar])}
            />
          )
        },
        member: {
          ...profileCardRoute.profile,
          // profileCardRoute expects the userId param.
          path: `member/:userId(\\d+)?/`,
          reversePath: "../../"
        },
        ":manageMember(manage-member-\\d+)/": {
          component: (props) => (
            <MentorGroupMembershipSettingsCard
              {...props}
              groupId={parseInt(props.params[groupIdVar])}
              membershipId={parseInt(props.params.manageMember.match(/\d+/)[0])}
              onSubmitSuccess={() =>
                window.App.navigate(path.join(window.location.pathname, "../"))
              }
            />
          )
        },
        // NOTE: group route must be last
        ...(depth > 0 ? getMentorGroupRoutes(depth - 1) : {})
      }
    }
  }
}

const mentorGroupRoutes = getMentorGroupRoutes()

/**
 * Modifies cards by adding a basePath which will be used
 * in place of parent card route paths. Basically allowing
 * for the :communityTab(members|groups)/ route to support
 * child routes that require one or the other value for communityTab.
 */

const provideCardBasePath = (cards, basePath) =>
  Object.entries(cards).reduce(
    (newCards, [cardPath, card]) => ({
      ...newCards,
      [cardPath]: {
        basePath,
        ...card
      }
    }),
    {}
  )

const communityMembersCards = provideCardBasePath(
  {
    // NOTE: order matters! profileCardRoute path matches anything.
    ...inviteCardRoute,
    ...assignCardRoutes,
    ...profileCardRoute
  },
  "/community/:communityTab(members)/"
)

const communityGroupsCards = provideCardBasePath(
  {
    // NOTE: add-group route must be before members
    ["add-group/"]: {
      component: AddMentorGroupCard
    },
    // NOTE: order matters! mentorGroupRoutes path matches anything.
    // ...assignCardRoutes,
    ...mentorGroupRoutes
  },
  "/community/:communityTab(groups)/"
)

export default createCardRoutes(
  "/community/",
  {
    // MEMBERS ROUTES
    [":communityTab(members|groups)/"]: {
      middleware: authRedirect,
      getDefaultLaunchedFrom: () => "/dashboard/",
      component: (props) => (
        <MembershipCard {...props} tab={props.match.params.communityTab} />
      ),
      childCards: {
        ...communityMembersCards,
        ...communityGroupsCards
      }
    },
    // JOIN ROUTE
    join: {
      getDefaultLaunchedFrom: () => "/library/",
      component: ({ card, location }) => {
        return (
          <AcceptCommunityGroupInvitationCard
            card={card}
            inviteToken={get(location, "query.ic")}
          />
        )
      }
    },
    // DIRECT ROUTES
    ...{
      ...mentorGroupRoutes,
      getDefaultLaunchedFrom: () => "/home/teach/"
    }
  },
  CommunityPrimaryFallback
)
