import Home from "home/components/HomeOrLibraryBrowse"
import { createCardRoutes } from "lib/core/routing/card-routing"
import { loadResource } from "path/middleware"
import Invitations from "./components/Invitations"
import invitationStore from "./store/invitation-store"

export const enterInvitations = (route) => {
  invitationStore.action.initialize()
  const { status } = route.location.query
  if (route.match.params.cohortId) {
    loadResource(route).then(() => {
      const { resource, offering } = window.App.getStore("resource").getState()
      invitationStore.action.setContext({ resource, offering, status })
      invitationStore.action.loadInvitations()
    })
  } else if (route.match.params.resource) {
    loadResource(route).then(() => {
      const { resource } = window.App.getStore("resourceAdmin").getState()
      const offering =
        window.App.getStore("resourceAdmin").request.getCurriculumOffering()
      invitationStore.action.setContext({ resource, offering, status })
      invitationStore.action.loadInvitations()
    })
  } else {
    invitationStore.action.setContext({ status })
    invitationStore.action.loadInvitations()
  }
}

const leaveInvitations = () => {
  invitationStore.action.reset()
}

export const invitationCardRoute = {
  invitation: {
    component: Invitations,
    middleware: enterInvitations,
    leaveMiddleware: leaveInvitations
  }
}

export const schoolRoute = createCardRoutes(
  "/manage/",
  invitationCardRoute,
  Home
)
