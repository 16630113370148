import DiscussionTagManagerCard from "@pathwright/web/src/modules/discussion/topics/DiscussionTagManagerCard"
import ManageCohortFeaturePermissions from "@pathwright/web/src/modules/feature/manage/ManageCohortFeaturePermissions"
import ManageCohortFeatures from "@pathwright/web/src/modules/feature/manage/ManageCohortFeatures"
import { featureParamToKey } from "@pathwright/web/src/modules/feature/utils"
import { getResourceSlugId } from "@pathwright/web/src/modules/utils/urls"

const getContextPropsFromParams = (params) => {
  const resourceId =
    parseInt(params.resourceId) || getResourceSlugId(params.resourceSlug)
  const cohortId = parseInt(params.cohortId)

  if (resourceId && cohortId) {
    return {
      context: {
        resource_id: resourceId,
        cohort_id: cohortId
      }
    }
  }

  // receiving component should be able to handle when only the cohort_id
  // is supplied (which will sometimes be the case)
  return { cohortId }
}

const withContext = (Component) => (props) =>
  <Component {...props} {...getContextPropsFromParams(props.match.params)} />

const ManageCohortFeaturesContainer = withContext(
  ({ card, context, cohortId }) => {
    return (
      <ManageCohortFeatures
        card={card}
        context={context}
        cohortId={cohortId}
        onChangeCommunityEnabled={(isEnabled) => {
          // updating cohort data in appropriate stores with the new discussion_enabled value
          const updates = {
            ["groupAdmin"]: "group",
            ["resource"]: "offering"
          }

          Object.entries(updates).forEach(([storeKey, attr]) => {
            try {
              App.getStore(storeKey)._mutate({
                [attr]: {
                  ...App.getStore(storeKey).getState()[attr],
                  discussion_enabled: isEnabled
                }
              })
            } catch (error) {
              console.warn(
                `Failed to update attr "${attr}" in store "${storeKey}": ${error}`
              )
            }
          })
        }}
      />
    )
  }
)

const ManageCohortFeaturePermissionsContainer = withContext(
  ({ card, match, context, cohortId }) => {
    return (
      <ManageCohortFeaturePermissions
        card={card}
        featureKey={featureParamToKey(match.params.featureKey)}
        context={context}
        cohortId={cohortId}
      />
    )
  }
)

export const cohortFeaturesCardRoutes = {
  community: {
    component: ManageCohortFeaturesContainer,
    childCards: {
      // Discussion topics are not a feature governed by CFPs.
      ["discussion-topics"]: {
        component: withContext(DiscussionTagManagerCard)
      },
      ":featureKey": {
        component: ManageCohortFeaturePermissionsContainer
      }
    }
  }
}
