import { Button, HStack, Heading, Link, Text, VStack } from "@chakra-ui/react"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { Link as ReactRouterLink } from "react-router-dom"
import FeatureLock from "../../lib/FeatureLock"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { canAdministrate } from "../../user/permissions"
import { useCertificateContext } from "../context/CertificateContext"

const CertificateFeatureLockGate = ({ children }) => {
  const pwContext = usePathwrightContext()
  const { certificate, hasLegacyCertificate } = useCertificateContext()

  return (
    <FeatureLock has="course-certificates">
      {({
        hasFeatures,
        lockedFeatures,
        activationLocked,
        getPlansNameList
      }) => {
        const upgradePrompt = (
          <HStack>
            <Pathicon icon="rocket" />
            {lockedFeatures?.["course-certificates"] ? (
              <span>{`Upgrade to the ${lockedFeatures["course-certificates"][0].name} Plan`}</span>
            ) : (
              <span>Upgrade to the Complete Plan</span>
            )}
          </HStack>
        )

        // Allow any school with a certificate or legacy certificate to bypass feature lock for now.
        const gate = !hasFeatures && !certificate && !hasLegacyCertificate && (
          <VStack spacing={4} padding={4} w="100%" textAlign={"center"}>
            <Pathicon icon="rocket" style={{ fontSize: "24px" }} />
            <Heading as={"h3"} size="md">
              Offer Completion Certificates
            </Heading>
            <Text textAlign="center">
              Reward and document learner achievement by providing a
              custom-designed certificate of completion they can download or
              print.{" "}
              <Link
                textDecoration="underline"
                textUnderlineOffset={2}
                href="http://help.pathwright.com/en/articles/9211185-provide-a-certificate-of-completion"
                isExternal
              >
                Learn more about certificates.
              </Link>
            </Text>
            <br />
            {canAdministrate(pwContext) ? (
              <>
                <Button
                  colorScheme="brand"
                  as={ReactRouterLink}
                  variant="solid"
                  to="/pricing/plan/"
                  size="lg"
                >
                  {upgradePrompt}
                </Button>
                <Text size="xs" color="gray.500">
                  To unlock completion certificates
                </Text>
              </>
            ) : (
              <>
                <Button
                  colorScheme="brand"
                  variant="solid"
                  size="lg"
                  isDisabled
                >
                  {upgradePrompt}
                </Button>
                <Text size="xs" color="gray.500">
                  Only Administrators can make plan updates.
                </Text>
              </>
            )}
          </VStack>
        )

        // This way you can optionally provide a function as children to
        // more flexibly handle the gate.
        return typeof children === "function"
          ? children({ gate })
          : gate
          ? gate
          : children
      }}
    </FeatureLock>
  )
}

export default CertificateFeatureLockGate
