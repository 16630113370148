import { Avatar, AvatarProps, Box, HStack, Text } from "@chakra-ui/react"
import Pathicon from "../../pathicon/Pathicon"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

type UserAvatarProps = {
  name?: string
  image?: string
  showName?: boolean
  isProfile?: boolean
} & AvatarProps

const UserAvatar = ({
  name,
  image,
  showName,
  isProfile,
  ...props
}: UserAvatarProps) => {
  const { me } = usePathwrightContext()

  return (
    <HStack
      position="relative"
      // h="max-content" w="fit-content"
    >
      <Avatar
        {...props}
        name={name ? name : (me.display_name as string)}
        src={image ? image : (me.profile?.image as string)}
      />
      {showName && (
        <Text size="sm" fontWeight={"light"} color="whiteAlpha.900" m={0}>
          {me.display_name as string}
        </Text>
      )}
      {!image && isProfile && (
        <Pathicon
          icon="cloud-up-fill"
          style={{
            width: 20,
            // height: 16,
            margin: 0,
            padding: 0,
            position: "absolute",
            right: 4,
            bottom: 0
          }}
        />
      )}
    </HStack>
  )
}

export default UserAvatar
