import gql from "graphql-tag"
import { DiscussionPostFragmentDoc } from "../../api/generated"
import DISCUSSION_RESPONSE_FRAGMENT from "./discussion-response-fragment"

export default gql`
  query DiscussionResponseQuery(
    $id: Int
    $context: DiscussionContextInput!
    $response_id: Int!
  ) {
    discussion(id: $id, context: $context) {
      ...DiscussionPost
      response(response_id: $response_id) {
        ...DiscussionResponse
      }
    }
  }
  ${DiscussionPostFragmentDoc}
  ${DISCUSSION_RESPONSE_FRAGMENT}
`
