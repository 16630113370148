import {
  ContextFragment,
  DiscussionPostFragment,
  GroupContextFragment,
  useGroupQuery
} from "../api/generated"
import {
  hasEditorLevelAccess,
  hasLearnerLevelAccess,
  hasModeratorLevelAccess,
  hasObserverLevelAccess
} from "../group/permissions"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { hasAccess } from "../user/permissions"

export function useDiscussionPerms(discussion: DiscussionPostFragment) {
  const pwContext = usePathwrightContext()
  const cohortQuery = useGroupQuery({
    variables: { id: discussion.cohort_id! }
  })
  const cohort = cohortQuery.data?.group

  return {
    canEdit: canEditDiscussion(pwContext, discussion, cohort),
    canDelete: canDeleteDiscussion(pwContext, discussion, cohort),
    canView: canViewDiscussion(pwContext, discussion, cohort),
    canLike: canLikeDiscussion(pwContext, discussion, cohort),
    canAskToAnswer: canAskToAnswerDiscussion(pwContext, discussion, cohort),
    canRespondTo: canRespondToDiscussion(pwContext, discussion, cohort),
    canSubscribeTo: canSubscribeToDiscussion(pwContext, discussion, cohort)
  }
}

export const canEditDiscussion = (
  pwContext: ContextFragment,
  discussion: DiscussionPostFragment,
  cohort?: GroupContextFragment | null
) => {
  // anonymous user cannot edit discussion
  if (!hasAccess(pwContext)) return false
  if (hasEditorLevelAccess(pwContext, cohort)) return true

  // moderators can edit non-discussion-type questions
  if (
    !discussion.is_discussion_question &&
    hasModeratorLevelAccess(pwContext, cohort)
  ) {
    return true
  }

  if (discussion.user?.id === pwContext.me?.id) return true

  return false
}

// same as canEditDiscussion
export const canDeleteDiscussion = (
  pwContext: ContextFragment,
  discussion: DiscussionPostFragment,
  cohort?: GroupContextFragment | null
) => {
  if (canEditDiscussion(pwContext, discussion, cohort)) return true

  return false
}

// discussion may be start-locked, but not handling that in discussion permissions
export const canViewDiscussion = (
  pwContext: ContextFragment,
  discussion: DiscussionPostFragment,
  cohort?: GroupContextFragment | null
) => {
  // anonymous user cannot view discussion
  if (!hasAccess(pwContext)) return false
  if (hasEditorLevelAccess(pwContext, cohort)) return true
  if (hasObserverLevelAccess(pwContext, cohort)) return true

  if (discussion.user?.id === pwContext.me?.id) return true

  return false
}

export const canAskToAnswerDiscussion = (
  pwContext: ContextFragment,
  discussion: DiscussionPostFragment,
  cohort?: GroupContextFragment | null
) => {
  if (
    !discussion.is_discussion_question &&
    hasModeratorLevelAccess(pwContext, cohort)
  ) {
    return true
  }

  return false
}

// General perms for if user can engage with a discussion.
export const canEngageWithDiscussion = (
  pwContext: ContextFragment,
  discussion: DiscussionPostFragment,
  cohort?: GroupContextFragment | null
) => {
  // anonymous user cannot engage with discussion
  if (!hasAccess(pwContext)) return false
  if (!discussion.allow_replies) return false
  if (hasEditorLevelAccess(pwContext, cohort)) return true
  // learners or greateer can engage with discussion
  if (hasLearnerLevelAccess(pwContext, cohort)) return true

  return false
}

export const canRespondToDiscussion = (
  pwContext: ContextFragment,
  discussion: DiscussionPostFragment,
  cohort?: GroupContextFragment | null
) => {
  return canEngageWithDiscussion(pwContext, discussion, cohort)
}

export const canLikeDiscussion = (
  pwContext: ContextFragment,
  discussion: DiscussionPostFragment,
  cohort?: GroupContextFragment | null
) => {
  return canEngageWithDiscussion(pwContext, discussion, cohort)
}

export const canSubscribeToDiscussion = (
  pwContext: ContextFragment,
  discussion: DiscussionPostFragment,
  cohort?: GroupContextFragment | null
) => {
  return canEngageWithDiscussion(pwContext, discussion, cohort)
}
