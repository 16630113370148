import get from "lodash/get"
import { useCohortSwitcherCohortsQuery } from "../../api/generated"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { canEditLibrary } from "../../user/permissions"
import { usePaginator } from "../../utils/apollo"

// simple hook for querying the cohorts, reuseable by the Picker
const useCohortsQuery = ({ resourceId, search, skip }) => {
  const pwContext = usePathwrightContext()

  const variables = {
    resourceId
  }

  // if the user cannot edit the library then let's filter out any cohorts the user
  // is not registered for
  if (!canEditLibrary(pwContext)) {
    variables.role = {
      neq: null
    }
  }

  // ensure we're not running a query for null or empty string search variable
  if (search) {
    variables.search = search
  }

  const cohortsQuery = useCohortSwitcherCohortsQuery({
    variables,
    skip: !resourceId || skip
  })

  const { loading, error } = cohortsQuery

  const cohorts = get(cohortsQuery, "data.cohorts.edges", []).map((e) => e.node)

  const { loadingMore, loadMore, hasMore } = usePaginator({
    data: cohortsQuery, // hacky
    path: "cohorts"
  })

  return {
    query: cohortsQuery,
    cohorts: cohorts,
    error,
    loading,
    loadingMore,
    loadMore,
    hasMore
  }
}

export default useCohortsQuery
