import { graphql } from "@apollo/client/react/hoc"
import Button from "@pathwright/ui/src/components/button/Button"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import FullscreenConfirm from "@pathwright/ui/src/components/confirm/FullscreenConfirm"
import RadioButton from "@pathwright/ui/src/components/form/form-radio/RadioButton"
import RadioGroup from "@pathwright/ui/src/components/form/form-radio/RadioGroup"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import TextArea from "@pathwright/ui/src/components/form/form-textarea/TextArea"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import Text from "@pathwright/ui/src/components/ui/Text"
import produce from "immer"
import compose from "lodash/flowRight"
import get from "lodash/get"
import { useMemo } from "react"
import { Field, Form, Submit, validators } from "react-connect-form-forked"
import SelectInput from "../../lib/SelectInput/SelectInput"
import { PathwrightContext } from "../../pathwright/PathwrightContext"
import { flattenEdges, usePaginator } from "../../utils/apollo"
import { withContextAsProp, withDebouncedProp } from "../../utils/component"
import {
  MENTOR_GROUP_DESCRIPTIONS,
  MENTOR_GROUP_TYPES,
  MENTOR_GROUP_TYPE_INPUTS,
  MENTOR_GROUP_TYPE_LABELS
} from "../constants"
import DELETE_MENTOR_GROUP_MUTATION from "../graphql/delete-mentor-group-mutation"
import MENTOR_GROUP_QUERY from "../graphql/mentor-group-query"
import MENTOR_GROUPS_QUERY from "../graphql/mentor-groups-query"
import UPDATE_MENTOR_GROUP_MUTATION from "../graphql/update-mentor-group-mutation"

const ManageMentorGroup = ({
  card,
  parentableGroups,
  parentableGroupsQuery,
  mentorGroup,
  permissions,
  updateGroup,
  deleteGroup,
  handleDebouncedSearch,
  t
}) => {
  const tPrefix = "mentor_group.form.fields"

  const { loadMore, hasMore, loadingMore } = usePaginator({
    data: parentableGroupsQuery,
    path: "school.mentor_groups"
  })

  const parentableGroupOptions = useMemo(() => {
    let parentableGroupOptions = []
    const defaultOption = { id: null, name: t(`${tPrefix}.none`) }

    // Include the default option.
    if (parentableGroups) {
      parentableGroupOptions.push(defaultOption, ...parentableGroups)
    }

    // Include the parent group if not found in the list of parentable groups.
    // This ensures the SelectInput has the parent group selected by default.
    if (
      mentorGroup?.parent &&
      !parentableGroupOptions.find((g) => g.id === mentorGroup.parent.id)
    ) {
      parentableGroupOptions.push(mentorGroup.parent)
    }

    return parentableGroupOptions
  }, [parentableGroups, mentorGroup])

  return (
    <Card card={card} title={mentorGroup && mentorGroup.name}>
      {mentorGroup ? (
        <Form
          initialValue={{
            name: mentorGroup.name,
            parent: mentorGroup.parent_id,
            description: mentorGroup.description,
            type: mentorGroup.type
          }}
          onPristine={() => card.setCardDirty(false)}
          onDirty={() => card.setCardDirty(true)}
          onSubmit={(form) =>
            updateGroup({
              name: form.name,
              parent: form.parent,
              description: form.description,
              type: MENTOR_GROUP_TYPE_INPUTS[form.type]
            })
          }
        >
          <Submit component={SubmitButton} styleType="primary">
            {t(`${tPrefix}.save`)}
          </Submit>
          <CardBlock>
            <Field
              id="name"
              name="name"
              component={TextInput}
              label={t("Group name")}
              placeholder={t(`${tPrefix}.name.placeholder`)}
              validations={[validators.isMinLength(3)]}
              topLabel
              required
            />
            <Field
              id="description"
              name="description"
              component={TextArea}
              label={t("Group description")}
              topLabel
            />
            {permissions.can_change_parent &&
              !!parentableGroupOptions.length && (
                <Field
                  name="parent"
                  type="select"
                  label={t(`${tPrefix}.parent.label`)}
                  instructions={t(`${tPrefix}.parent.instructions`)}
                  topLabel
                  hideStatus
                  simpleValue
                  searchable
                  placeholder={t(`${tPrefix}.select`)}
                  component={SelectInput}
                  options={parentableGroupOptions}
                  labelKey="name"
                  valueKey="id"
                  onMenuScrollToBottom={() => hasMore && loadMore()}
                  onInputChange={(val) => {
                    handleDebouncedSearch && handleDebouncedSearch(val)
                    // return val for react-select to handle
                    return val
                  }}
                />
              )}
            {false && (
              <RadioGroup label={t(`${tPrefix}.community_group_type`)} topLabel>
                {MENTOR_GROUP_TYPES.map((type) => (
                  <Field
                    key={type}
                    name="type"
                    type="radio"
                    component={RadioButton}
                    selected={mentorGroup.type == type}
                    value={type}
                  >
                    <div>
                      <Text.H5>{MENTOR_GROUP_TYPE_LABELS[type]}</Text.H5>
                      <Text.Body>{MENTOR_GROUP_DESCRIPTIONS[type]}</Text.Body>
                    </div>
                  </Field>
                ))}
              </RadioGroup>
            )}
          </CardBlock>
          {permissions.can_delete_group && (
            <CardBlock>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "space-between"
                }}
              >
                <Text.Body>
                  {t(`${tPrefix}.delete`)} {mentorGroup.name}
                </Text.Body>
                <FullscreenConfirm
                  body={t(`${tPrefix}.delete_confirmation_message`, {
                    mentor_group_name: mentorGroup.name
                  })}
                  confirmedHeading={`${mentorGroup.name} was deleted.`}
                  onConfirm={() => deleteGroup()}
                  onConfirmed={() => {
                    window.App &&
                      window.App.getStore("navigation").action.navigateRelative(
                        "../../"
                      )
                  }}
                >
                  {({ confirm }) => (
                    <Button color="red" styleType="secondary" onClick={confirm}>
                      {t(`${tPrefix}.delete`)}
                    </Button>
                  )}
                </FullscreenConfirm>
              </div>
            </CardBlock>
          )}
        </Form>
      ) : null}
    </Card>
  )
}

ManageMentorGroup.displayName = "ManageMentorGroup"

export default compose(
  withTranslate,

  graphql(MENTOR_GROUP_QUERY, {
    options: ({ id }) => ({
      variables: { id }
    }),
    props: ({ data }) => ({
      mentorGroup: get(data, "school.mentor_group"),
      permissions: get(data, "school.mentor_group.permissions") || {}
    })
  }),
  withDebouncedProp("search"),
  graphql(MENTOR_GROUPS_QUERY, {
    options: ({ id, debouncedSearch }) => ({
      variables: {
        // excluding this mentor group
        id: { neq: id },
        // excluding all the descendant child groups of this mentor group
        descendentOfParentId: { neq: id },
        search: debouncedSearch
      }
    }),
    props: ({ data, ownProps }) => ({
      parentableGroups:
        data.school && data.school.mentor_groups
          ? flattenEdges(data.school.mentor_groups).filter(
              (group) => group.id !== ownProps.id
            )
          : null,
      parentableGroupsQuery: data
    })
  }),
  graphql(UPDATE_MENTOR_GROUP_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      updateGroup: (form) =>
        mutate({
          variables: { id: ownProps.id, ...form },
          refetchQueries: [
            {
              query: MENTOR_GROUPS_QUERY,
              variables: {
                parentId: { eq: get(ownProps.mentorGroup, "parent_id") }
              }
            },
            {
              query: MENTOR_GROUPS_QUERY,
              variables: {
                parentId: { eq: parseInt(form.parent) || null }
              }
            }
          ]
        })
    })
  }),
  withContextAsProp(PathwrightContext, "userId", "me.id"),
  graphql(DELETE_MENTOR_GROUP_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      deleteGroup: () =>
        mutate({
          variables: {
            id: ownProps.id
          },
          update: (cache, result) => {
            // reload codex query for People
            const codexSpace =
              window.codexClient && window.codexClient.store.get("space")
            if (codexSpace && codexSpace.id === `people:${ownProps.userId}`)
              codexSpace.refetch()

            const query = MENTOR_GROUPS_QUERY
            const writeQueryToCach = (variables) => {
              let data = null
              try {
                data = cache.readQuery({ query, variables })
              } catch (e) {
                // failed to read the cache, most likely because the query doesn't exist in the cache yet
                return
              }

              // data could be null, even if no error occurs when reading query.
              if (!data) return

              const nextData = produce(data, (draft) => {
                const { edges } = draft.school.mentor_groups
                const deletedEdgeIndex = edges.findIndex(
                  (e) => e.node.id === ownProps.id
                )
                // if edge exists, remove it
                if (deletedEdgeIndex > -1) {
                  edges.splice(deletedEdgeIndex, 1)
                }
              })
              cache.writeQuery({ query, variables, data: nextData })
            }

            // remove group from top level groups query
            writeQueryToCach({
              parentId: { eq: null },
              seatLimitFilter: { neq: 1 }
            })
            // remove group from query using the group's parent_id
            writeQueryToCach({
              parentId: { eq: ownProps.mentorGroup.parent_id }
            })
          },
          // refetching context mainly in order to update the user's group_role_stats
          // which controls what Home UIs are available to the user
          refetchQueries: ["Context"]
        })
    })
  })
)(ManageMentorGroup)
