import { Badge, HStack } from "@chakra-ui/react"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import useIntersection from "@pathwright/ui/src/components/hooks/useIntersection"
import Course from "@pathwright/ui/src/components/library/Course"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import ProgressBar from "@pathwright/ui/src/components/progress/ProgressBar"
import { useScrollContext } from "@pathwright/ui/src/components/scroll/ScrollView"
import UserReportCard from "@pathwright/web/src/modules/path/report/UserReportCard"
import get from "lodash/get"
import path from "path-browserify"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import {
  OrderByDir,
  UserProfileRegistrationsDocument,
  useUserProfileRegistrationsQuery
} from "../api/generated"
import { getAssignTo } from "../assign/AssignDropdownItem"
import AssignGate from "../assign/AssignGate"
import { INVITATION_ROLE_TYPE_MAP } from "../share/invitation/constants"
import { getRoleLabel } from "../share/select/utils"
import { flattenEdges, usePaginator } from "../utils/apollo"
import ProfileRegistrationControls from "./ProfileRegistrationControls"

const StyledList = styled.ul`
  flex-shrink: 0;
  margin: 0;
  padding: 5px;
  list-style-type: none;
`

const StyledItem = styled.li`
  padding: 5px;
  height: 80px;
`

const coverWidth = 70 / 0.75
const StyledProgress = styled(ProgressBar)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: ${coverWidth + 10}px;
  width: calc(100% - ${coverWidth}px - 20px) !important;
`

const AddToPathContainer = styled.div`
  margin: 10px;
  display: flex;
  .Button {
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0.1);
  }
  /* padding: 6px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 10px;
  border-radius: 12px; */
`

const RoleBadge = ({ registration }) => {
  const { tca } = useTranslate()

  const roleKey = INVITATION_ROLE_TYPE_MAP[1][registration.role]
  const role = getRoleLabel("noun", roleKey, tca)

  return <Badge fontSize=".8em">{role}</Badge>
}

const ProfileRegistrations = ({ userId }) => {
  const variables = {
    id: userId,
    // Filter out any non-"top-level" registrations, so we only are left
    // with those that don't have any parent cohort registration above.
    hasParentRegistration: false,
    orderBy: { created: OrderByDir.Desc }
  }
  const userRegistrationsQuery = useUserProfileRegistrationsQuery({
    variables
  })

  const onRemoveRegistration = (registration, cache) => {
    const query = UserProfileRegistrationsDocument
    const data = cache.readQuery({ query, variables })
    const nextData = {
      user: {
        ...data.user,
        registrations: {
          ...data.user.registrations,
          edges: data.user.registrations.edges.filter(
            (edge) => edge.node.id !== registration.id
          )
        }
      }
    }
    cache.writeQuery({ query, variables, data: nextData })
  }

  const userRegistrationsPaginator = usePaginator({
    data: userRegistrationsQuery, // hacky
    path: "user.registrations"
  })

  const { scrollNode } = useScrollContext()
  // we'll check if the LoadingBlock placeholder element has entered the containing ScrollView
  const [setIntersectionNode, intersection, hasIntersected] = useIntersection({
    root: scrollNode
  })

  // paginate when last node
  React.useEffect(() => {
    if (
      hasIntersected &&
      !userRegistrationsQuery.loading &&
      !userRegistrationsPaginator.loadingMore &&
      userRegistrationsPaginator.hasMore
    )
      userRegistrationsPaginator.loadMore()
  }, [hasIntersected])

  const user = get(userRegistrationsQuery, "data.user")

  const registrations =
    flattenEdges(get(userRegistrationsQuery, "data.user.registrations")) || []

  if (userRegistrationsQuery.loading) return null

  return (
    <StyledList>
      {registrations.length > 0 && (
        <AssignGate blankSlate={null}>
          <AddToPathContainer>
            <Button
              styleType="secondary"
              to={getAssignTo({ userId })}
              label="Add to path"
              icon="plus-circle"
            />
          </AddToPathContainer>
        </AssignGate>
      )}

      {registrations.length > 0 ? (
        registrations.map((registration, i) => {
          const isLearnerRegistration = registration.role <= 10

          return (
            <StyledItem
              key={registration.id}
              ref={i === registrations.length - 1 ? setIntersectionNode : null}
            >
              {/* display every resource as a course for now */}
              <Course
                course={registration.resource}
                meta={
                  <HStack>
                    {registration.group.name ? (
                      <span>{registration.group.name}</span>
                    ) : null}
                    <RoleBadge registration={registration} />
                  </HStack>
                }
                to={
                  isLearnerRegistration
                    ? {
                        pathname: path.join(
                          window.location.pathname,
                          `${registration.path.id}/`
                        ),
                        card: {
                          component: UserReportCard,
                          props: {
                            userId: userId,
                            pathId: registration.path.id
                          }
                        }
                      }
                    : null
                }
                layout="list"
                controls={
                  <ProfileRegistrationControls
                    registration={registration}
                    onRemoveRegistration={onRemoveRegistration}
                  />
                }
              >
                {isLearnerRegistration && (
                  <StyledProgress progress={registration.path.progress} />
                )}
              </Course>
            </StyledItem>
          )
        })
      ) : (
        <AssignGate
          blankSlate={
            <BlankSlate
              icon="list-two-column"
              body={`${user.first_name} hasn't started any courses yet.`}
            />
          }
        >
          <BlankSlate
            icon="list-two-column"
            body={`${user.first_name} hasn't started any courses yet.`}
            primaryAction={{
              children: `Add to Path`,
              to: getAssignTo({ userId })
            }}
          />
        </AssignGate>
      )}
    </StyledList>
  )
}

ProfileRegistrations.displayName = "ProfileRegistrations"

ProfileRegistrations.propTypes = {
  userId: PropTypes.number.isRequired
}

export default ProfileRegistrations
