import { useFormikContext } from "formik"
import { useEffect } from "react"

// Submits formik form when Meta+Enter keyboard keys are pressed.
// NOTE: if Formik is not provided the onSubmit prop, using this
// component will result in an unhandled error when user uses
// keyboard shortcut.
function KeyboardFormSubmit() {
  const context = useFormikContext()

  useEffect(() => {
    function handler(e: KeyboardEvent) {
      if (e.key === "Enter" && e.getModifierState("Meta")) {
        try {
          // Only submit form if form is valid.
          if (context?.isValid) {
            context.submitForm?.()
          }
        } catch (error) {
          console.warn(
            "Failed attempt to submit form via keyboard shortcut due to error:",
            error
          )
        }
      }
    }

    document.addEventListener("keydown", handler)

    return () => {
      document.removeEventListener("keydown", handler)
    }
  }, [])

  return null
}

export default KeyboardFormSubmit
