import useHomeActiveEntries from "@pathwright/web/src/modules/home/useHomeActiveEntries"
import UnifiedHome from "@pathwright/web/src/modules/unified-home/UnifiedHome"

const Homescreen = () => {
  // Here we explicitly select the "paths.unified" entry.
  useHomeActiveEntries("paths.unified")

  return (
    // <div>
    //   <UnifiedHome />
    // </div>
    <UnifiedHome />
  )
}

Homescreen.displayName = "Homescreen"

export default Homescreen
