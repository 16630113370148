import { UnifiedHomeRegistrationFragment } from "modules/api/generated"
import { hasTeacherLevelAccess } from "../../../group/permissions"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import { usePositioningContext } from "../../../positioning/PositioningContext"
import { canEditLibrary } from "../../../user/permissions"
import useSoftArchiveRegistration from "../../useSoftArchiveRegistration"
import GroupNotificationsFormOption from "../GroupNotificationsFormOption"
import OptionMenuButton, { OptionMenuItem } from "../ui/OptionMenuButton"
type PathCardRegistrationControlsProps = {
  registration: UnifiedHomeRegistrationFragment
}

const PathCardRegistrationControls = ({
  registration
}: PathCardRegistrationControlsProps) => {
  const pwContext = usePathwrightContext()
  const { updatePositionedObject } = usePositioningContext()
  const { resource, group } = registration

  function handleMoveToTop() {
    updatePositionedObject({
      item: registration,
      // Note: currently a bug in positional-indexing results in every
      // item being set to "!" (bug) which results in an ISO (as should be expected).
      index: 0
    })
  }

  const [softArchive] = useSoftArchiveRegistration(registration)

  return (
    <OptionMenuButton
      position="relative"
      zIndex={100}
      right={2.5}
      top={2}
      variant="solid"
      colorScheme="whiteAlpha"
    >
      <OptionMenuItem
        icon="arrow-up-circle-fill"
        label="Move to top"
        onClick={handleMoveToTop}
      />
      <GroupNotificationsFormOption registration={registration} />

      {/* //!Add action */}
      <OptionMenuItem
        icon="inbox-fill"
        label="Inbox"
        // href={`/manage/resource/${resource?.id}/`}
      />

      {/* //!Add action */}
      <OptionMenuItem
        icon="skip-back2"
        label="Archive"
        onClick={softArchive}
        // href={`/manage/resource/${resource?.id}/`}
      />

      {/* {canEditLibrary(pwContext) && (
        //! Check action
        <OptionMenuItem
          icon="x-circle"
          label="Leave cohort"
          onClick={() => {
            console.log("Edit")
          }}
        />
      )} */}

      {hasTeacherLevelAccess(pwContext, group) && false && (
        <OptionMenuItem
          icon="gear"
          label="Edit cohort settings"
          href={`/manage/group/${resource?.id}/${group?.id}/`}
        />
      )}

      {canEditLibrary(pwContext) && false && (
        <OptionMenuItem
          icon="gear"
          label="Edit cohort settings"
          href={`/manage/resource/${resource?.id}/`}
        />
      )}
    </OptionMenuButton>
  )
}

export default PathCardRegistrationControls
