import gql from "graphql-tag"
import { DiscussionPostFragmentDoc } from "../../api/generated"

export default gql`
  mutation CreateDiscussion(
    $context: DiscussionContextInput!
    $post: DiscussionPostInput!
    $initialResponse: DiscussionResponseInput
    $notify: Boolean = false
  ) {
    createDiscussion(
      context: $context
      post: $post
      initialResponse: $initialResponse
      notify: $notify
    ) {
      ...DiscussionPost
    }
  }
  ${DiscussionPostFragmentDoc}
`
