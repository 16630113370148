import gql from "graphql-tag"
import { GroupFragmentDoc, ResourceFragmentDoc } from "../../../api/generated"
import LICENSE_OFFERING_FRAGMENT from "./license-offering-fragment"

export default gql`
  fragment ResourceLicense on ResourceLicense {
    id
    seat_count
    seats_filled
    seats_available
    expiration_dtime
    school {
      id
      name
    }
    license_offering {
      ...LicenseOffering
    }
    group {
      ...Group
      resource {
        ...Resource
      }
    }
  }
  ${LICENSE_OFFERING_FRAGMENT}
  ${GroupFragmentDoc}
  ${ResourceFragmentDoc}
`
