import { usePathCreatorRandomResourceImageQuery } from "../../api/generated"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

// Get random resource image based on the school theme.
function useRandomThemeResourceImage(
  options?: Parameters<typeof usePathCreatorRandomResourceImageQuery>[0]
) {
  const { school } = usePathwrightContext()

  const randomResourceImageQuery = usePathCreatorRandomResourceImageQuery({
    variables: {
      themeType: school.theme?.theme_type
    },
    fetchPolicy: "network-only",
    ...options
  })

  return randomResourceImageQuery.data?.resourceImage || []
}

export default useRandomThemeResourceImage
