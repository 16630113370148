import {
  IconButton,
  IconButtonProps,
  StackProps,
  VStack
} from "@chakra-ui/react"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { canGoBack } from "./history"
import { PathCreatorHistoryProps } from "./state"

// Provides backward and forward nav buttons to navigate the state history stack.
function PathCreatorNav({
  historyState,
  historyDispatch,
  ...stackProps
}: PathCreatorHistoryProps & StackProps) {
  const iconButtonProps: Omit<IconButtonProps, "aria-label"> = {
    variant: "solid",
    colorScheme: "whiteAlpha",
    cursor: "pointer",
    isRound: true,
    h: 8,
    minW: 8
  }

  return historyState.index > 0 ? (
    <VStack {...stackProps}>
      <IconButton
        aria-label="reset"
        icon={<Pathicon icon="x" size="1em" />}
        onClick={() =>
          historyDispatch({
            type: "reset"
          })
        }
        isDisabled={historyState.items.length <= 1}
        {...iconButtonProps}
      />
      <IconButton
        aria-label="go back"
        icon={<Pathicon icon="skip-back-2" size="1em" />}
        onClick={() =>
          historyDispatch({
            type: "goBack"
          })
        }
        isDisabled={!canGoBack(historyState)}
        {...iconButtonProps}
      />
      {/* <IconButton
          aria-label="go forward"
          icon={<Pathicon icon="chevron-right" />}
          onClick={() =>
            historyDispatch({
              type: "goForward"
            })
          }
          isDisabled={!canGoForward(historyState)}
        /> */}
    </VStack>
  ) : null
}

export default PathCreatorNav
