import { graphql } from "@apollo/client/react/hoc"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import DatePicker from "@pathwright/ui/src/components/form/form-date-picker/DatePicker"
import Fieldset from "@pathwright/ui/src/components/form/form-utils/Fieldset"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import compose from "lodash/flowRight"
import get from "lodash/get"
import moment from "moment"
import PropTypes from "prop-types"
import { Field, Form, Submit } from "react-connect-form-forked"
// import { SimpleRegistrationDocument } from "../api/generated"
import { RegistrationDocument } from "../api/generated"
import SelectRole from "../invitation/SelectRole"
import { ROLE_TYPE_OFFERING, ROLE_VALUES } from "../invitation/constants"
import { filterAvailableRoles } from "../invitation/utils"
import UPDATE_USER_REGISTRATION_MUTATION from "../registration/graphql/update-user-registration-mutation"

const ManageRegistration = ({
  card,
  group,
  registration,
  loading,
  error,
  onSubmit,
  onSubmitSuccess
}) => (
  <Card card={card} title="Registration Settings">
    {error && <Alert error={error.message} />}
    {loading ? (
      <LoadingCircle />
    ) : !registration && !error ? (
      <BlankSlate
        icon="x-circle"
        body="This registration may have been deleted."
      />
    ) : registration ? (
      <Form
        initialValue={registration}
        onSubmit={onSubmit}
        onSubmitSuccess={(response) =>
          onSubmitSuccess(get(response, "data.updateRegistration"))
        }
      >
        <CardBlock>
          <Fieldset
            title={`Change ${registration.user.first_name}'s role in ${group.name}`}
          >
            <Field
              name="role"
              type="custom"
              roleType={ROLE_TYPE_OFFERING}
              group={group}
              component={SelectRole}
              roleFilter={(type, role) =>
                filterAvailableRoles(type, role, group) ||
                role === registration.role
              }
            />
          </Fieldset>
        </CardBlock>
        <CardBlock>
          <Fieldset
            title="Access expiration"
            header={`Adjust the expiration date for ${registration.user.first_name}'s access to this cohort`}
            footer="Leave blank if access should never expire."
          >
            <Field
              name="expiration_dtime"
              type="custom"
              component={DatePicker}
            />
          </Fieldset>
        </CardBlock>
        <Submit styleType="primary" component={SubmitButton}>
          Save
        </Submit>
      </Form>
    ) : null}
  </Card>
)

ManageRegistration.displayName = "ManageRegistration"
ManageRegistration.propTypes = {
  registration: PropTypes.shape({
    role: PropTypes.number,
    expiration_dtime: PropTypes.string
  })
}
ManageRegistration.defaultProps = {
  onSubmitSuccess: () => {} // hack hook for member store to respond to member update
}

export default compose(
  graphql(RegistrationDocument, {
    options: ({ groupId, registrationId }) => ({
      variables: {
        group_id: groupId,
        registration_id: registrationId
      }
    }),
    props: ({ data }) => ({
      error: data.error,
      loading: data.loading,
      group: get(data, "group"),
      registration: get(data, "group.registration")
    })
  }),
  graphql(UPDATE_USER_REGISTRATION_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      onSubmit: (changes) =>
        mutate({
          variables: {
            expiration_dtime: changes.expiration_dtime
              ? moment(changes.expiration_dtime).format()
              : null,
            role: ROLE_VALUES[ROLE_TYPE_OFFERING][changes.role.value],
            id: ownProps.registrationId
          }
        })
    })
  })
)(ManageRegistration)
