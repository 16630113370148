const { composeMiddleware } = require("lib/core/routing/middleware")

export const createCardRoutes = function (root, cards, primaryFallback) {
  if (primaryFallback == null) {
    primaryFallback = null
  }
  const createStackCard = (cardStack, card, key, path) => ({
    key,
    path,
    reversePath: card.reversePath || "../",
    middleware: card.middleware,
    leaveMiddleware: card.leaveMiddleware,
    // Ensure each card is assigned a getDefaultLaunchedFrom fn, passing it down from
    // the top card (though, the top card might not have one defined...).
    getDefaultLaunchedFrom:
      card.getDefaultLaunchedFrom ||
      cardStack.slice(-1)[0]?.getDefaultLaunchedFrom ||
      null,
    component: card.component
  })

  const getCardStackMiddleware = function (cards) {
    const middleware = []
    for (let card of Array.from(cards)) {
      if (_.isFunction(card.middleware)) {
        middleware.push(card.middleware)
      }
    }
    return middleware
  }

  const getCardStackLeaveMiddleware = function (cards) {
    const middleware = []
    for (let card of Array.from(cards)) {
      if (_.isFunction(card.leaveMiddleware)) {
        middleware.push(card.leaveMiddleware)
      }
    }
    return middleware
  }

  const getCardPath = function (basePath, key, card) {
    // allow card to reset basePath
    basePath = card.basePath || basePath
    let path = null
    if (card.path) {
      path = `${basePath}${card.path}`
    } else {
      path = `${basePath}${key}`
    }

    // Ensure a final "/" but only if the path does not end with a ")"
    return path.replace(/([^)]$)/, "$1/").replace(/\/+$/, "/")
  }

  var createCardRoute = function (routes, path, key, card, initialCardStack) {
    if (initialCardStack == null) {
      initialCardStack = []
    }
    const previousPath = path
    path = getCardPath(path, key, card)
    const stackCard = createStackCard(initialCardStack, card, key, path)
    const cardStack = _.clone(initialCardStack).concat([stackCard])

    // Card/route middleware
    let onEnter = null
    const cardMiddleware = getCardStackMiddleware(cardStack)
    if (cardMiddleware.length) {
      onEnter = composeMiddleware(cardMiddleware)
    }
    let onLeave = null
    const cardLeaveMiddleware = getCardStackLeaveMiddleware(cardStack)
    if (cardLeaveMiddleware.length) {
      onLeave = composeMiddleware(cardLeaveMiddleware)
    }

    const reactRoute = {
      path,
      onEnter,
      onLeave,
      cards: cardStack,
      getDefaultLaunchedFrom: stackCard.getDefaultLaunchedFrom,
      components: {
        // dummy component
        cards(props) {
          return React.createElement("div")
        },
        primary: primaryFallback
      }
    }
    routes.push(reactRoute)

    if (card.childCards) {
      for (key in card.childCards) {
        const childCard = card.childCards[key]
        routes = createCardRoute(routes, path, key, childCard, cardStack)
      }
    }
    return routes
  }

  let cardRoutes = []
  for (let key in cards) {
    const card = cards[key]
    cardRoutes = createCardRoute(cardRoutes, root, key, card)
  }

  return cardRoutes
}
