import { useResetPathItemForUserMutation } from "../../api/generated"

const useResetPathItemForUser = (pathItem) => {
  const [resetPathItemForUser, { data, loading, error }] =
    useResetPathItemForUserMutation({
      variables: {
        id: pathItem.id,
        path_id: pathItem.path_id
      },
      refetchQueries: ["Path", "PathPerformanceQuery"]
    })

  return [resetPathItemForUser, { data, loading, error }]
}

export default useResetPathItemForUser
