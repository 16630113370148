import { Box, Flex, UseDisclosureProps, useDisclosure } from "@chakra-ui/react"
import { forwardRef } from "react"
import { useMeasure, useWindowSize } from "react-use"
import { AugmentationPanelOptions, usePanelSize } from "./AugmentationPanel"

export type AugmentationLayoutPanel = ReturnType<
  typeof forwardRef<
    HTMLElement,
    AugmentationPanelOptions & {
      innerGutter?: number
      outerGutter?: number
    }
  >
>

type AugmentationLayoutProps = {
  primary: JSX.Element
  Augmentation: AugmentationLayoutPanel
  disclosureOptions?: UseDisclosureProps
  // Augmentation: typeof AugmentationPanel
  // Allow the augmentation panel to completely overlay the primary view at smaller screen sizes.
  allowOverlay?: boolean
  // When there is space to do so, center the primary component, positioning the augmentation panel
  // relative to it.
  centerPrimary?: boolean
  innerGutter?: number
  outerGutter?: number
}

// Handle min width for primary view, default to 0, but if positive than don't allow
// primary view to shrink below that width. Also, restrict augmentation to a max width in that case,
// that is, don't allow to overlay whole screen.

const AugmentationLayout = ({
  primary,
  Augmentation,
  disclosureOptions,
  allowOverlay,
  centerPrimary,
  innerGutter,
  outerGutter
}: AugmentationLayoutProps) => {
  const [layoutRef, layoutSize] = useMeasure<HTMLDivElement>()
  const [primaryRef, primarySize] = useMeasure<HTMLDivElement>()
  const [panelRef, panelSize] = useMeasure<HTMLDivElement>()
  const windowSize = useWindowSize()
  const panelDisclosure = useDisclosure(disclosureOptions)

  // Default the innter and outer gutter sizes when not provided.
  innerGutter ||= 10
  outerGutter ||= 10

  const panelTokenSize = usePanelSize({ allowOverlay })

  // Calculate the sum of the primary view and augmentation panel widhts plus
  // the inner gutter.
  const primaryPlusPanelWidth =
    primarySize.width + innerGutter + panelSize.width // + outerGutter * 2

  // Calculate the width of space available for positining the augmentation panel relative
  // to the primary view, allowing the primary view to remain central rather than shifting
  // it over. This is ideal for situations where you don't want layout shift if possible.
  // That could be jarring to the user.
  const inlineRelativeWidth = centerPrimary
    ? Math.max(
        0,
        layoutSize.width / 2 -
          primarySize.width / 2 -
          panelSize.width -
          outerGutter
      )
    : 0

  // Get distance of layout from right of window.
  const layoutRightOffset = Math.max(
    0,
    windowSize.width - (layoutSize.left + layoutSize.right)
  )

  // Since the panel is positioned absolutely in a portal, we will reposition it
  // and the primary view so that the panel "feels" inline.
  // Offset the primary view to the left to make room for the inner gutter and the panel.
  // Ignoring offset when panel is full size.
  let primaryOffset =
    panelTokenSize === "full"
      ? 0
      : Math.min(
          panelSize.width + innerGutter,
          layoutSize.width - primarySize.width
        )

  // Calculate the width of space to oofset the panel when inlining the panel relative
  // to the centered primary view.
  const inlineRelativeOffset = inlineRelativeWidth > 0 ? primaryOffset / 2 : 0

  // Offset the panel to the left (but from right of window) to put it visually
  // next to the primary view, with an inner gutter gap.
  let panelOffset =
    (layoutRightOffset + (layoutSize.width - primaryPlusPanelWidth)) / 2 -
    inlineRelativeOffset

  // Seems to be a bug in useMeasure that retains the size of the element even after it
  // has unmounted. So, we need to manually track whether the panel is open, and, if not,
  // reset offsets to 0.
  if (!panelDisclosure.isOpen) {
    primaryOffset = panelOffset = 0
  }

  // TODO: If we cannot inline the panel, than we handle the floating panel layout which depends on
  // a icon panel to launch the panel, which needs to be positioned absolutely adjacent to the primary view.

  return (
    <Box ref={layoutRef} className="AugmentationLayout">
      <Flex
        position="relative"
        justifyContent="center"
        alignItems="flex-start"
        // minW={`${containerWidth}px`}
        maxW={`calc(100% - ${outerGutter * 2}px)`}
        mx={`${outerGutter}px`}
      >
        <Box
          ref={primaryRef}
          marginRight={
            !inlineRelativeWidth && primaryOffset
              ? `${primaryOffset}px`
              : undefined
          }
        >
          {primary}
        </Box>
        {/* We can't just wrap the panel with a ref and pass the JSX.Element since
        the panel is portaled elseware, meaning the wrapping element will be empty
        and thus have no size. This is why an Augmentation component is expected. */}
        <Augmentation
          ref={panelRef}
          offset={{ right: panelOffset }}
          allowOverlay={allowOverlay}
          disclosure={panelDisclosure}
          innerGutter={innerGutter}
          outerGutter={outerGutter}
        />
      </Flex>
    </Box>
  )
}

export default AugmentationLayout
