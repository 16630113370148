import PopupAlert from "@pathwright/ui/src/components/alert/PopupAlert"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import Dropdown from "@pathwright/ui/src/components/dropdown"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import SubmitHandler, {
  SUBMIT_STATUS
} from "@pathwright/ui/src/components/submit/SubmitHandler"
import get from "lodash/get"
import styled from "styled-components"
import { useRegistrationsQuery } from "../../../api/generated"
import { flattenEdges } from "../../../utils/apollo"
import { useDiscussionPerms } from "../../permissions"
import { getDiscussionContext } from "./utils"

const StyledAskToRespondItem = styled.div`
  display: flex;
  align-items: center;

  .Avatar {
    margin-right: 0.5em;
  }
`

const AskToRespond = ({ discussion }) => {
  const { tc } = useTranslate()
  const discussionContext = getDiscussionContext(discussion)

  const DISCUSSION_ASK_TO_ANSWER = 1
  const RESPONSE_ASK_TO_REPLY = 5

  const registrationsQuery = useRegistrationsQuery({
    variables: {
      groupId: discussionContext.cohort_id,
      roleFilter: {
        gte: "moderator",
        lte: "teacher"
      }
    }
  })

  const registrations = flattenEdges(
    get(registrationsQuery, "data.group.registrations"),
    []
  )

  if (registrationsQuery.loading || !registrations.length) return null

  const askToRespond = (user) => {
    const action = DISCUSSION_ASK_TO_ANSWER
    const target = `discussion_${discussion.id}`

    const contextObj = {
      step: get(discussion, "path_item.source_id"),
      course: get(discussion, "resource.id"),
      class: get(discussion, "cohort.id")
    }

    const contextKey = Object.entries(contextObj)
      .reduce(
        (contextKeys, [k, v]) =>
          v ? [...contextKeys, `${k}_${v}`] : contextKeys,
        []
      )
      .join(",")

    const contextId = discussion.context_id

    return (
      window.App &&
      window.App.getStore("notification").action.sendActionNotification(
        action,
        target,
        [user.id],
        contextKey,
        contextId
      )
    )
  }

  return (
    <>
      <Dropdown.Divider>{tc("ask to respond")}</Dropdown.Divider>
      {registrations.map((registration) => (
        <SubmitHandler onSubmit={() => askToRespond(registration.user)}>
          {({ handleSubmit, status, error, clearError }) => (
            <>
              <Dropdown.Item key={registration.id} onClick={handleSubmit}>
                <StyledAskToRespondItem>
                  <Avatar user={registration.user} size="25px" />{" "}
                  {registration.user.display_name}
                </StyledAskToRespondItem>
              </Dropdown.Item>
              {(status === SUBMIT_STATUS.LOADING ||
                status === SUBMIT_STATUS.SUCCESS) && (
                <PopupAlert
                  success={tc("asked {{ user }} to respond.", {
                    user: registration.user.display_name
                  })}
                />
              )}
              {!!error && (
                <PopupAlert
                  error={error}
                  onClear={clearError}
                  clearAfter={null}
                />
              )}
            </>
          )}
        </SubmitHandler>
      ))}
    </>
  )
}

const DiscussionListItemControls = ({
  discussion,
  handleEdit,
  handleDelete
}) => {
  const discussionPerms = useDiscussionPerms(discussion)
  const { tc } = useTranslate()

  if (
    !discussionPerms.canEdit &&
    !discussionPerms.canDelete &&
    !discussionPerms.canAskToAnswer
  ) {
    return null
  }

  return (
    <Dropdown.Trigger
      className="DiscussionListItemControls"
      onClick={(e) => e.stopPropagation()}
      renderDropdown={() => (
        <Dropdown.Menu>
          {discussionPerms.canEdit && (
            <Dropdown.Item
              label={tc("edit")}
              onClick={() => handleEdit(discussion)}
              closeOnClick
            />
          )}
          {discussionPerms.canDelete && (
            <Dropdown.Item
              label={tc("delete")}
              onClick={() => handleDelete(discussion)}
              closeOnClick
            />
          )}
          {discussionPerms.canAskToAnswer && (
            <AskToRespond discussion={discussion} />
          )}
        </Dropdown.Menu>
      )}
    >
      <IconButton styleType="secondary" icon="ellipses-horizontal" />
    </Dropdown.Trigger>
  )
}

DiscussionListItemControls.displayName = "DiscussionListItemControls"

export default DiscussionListItemControls
