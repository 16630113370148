import { StackProps } from "@chakra-ui/react"
import ChooseOne from "./components/ChooseOne"
import { ItemKey, PathCreatorProps, StatusKey } from "./state"
import useLazyRandomThemeResourceImage from "./useLazyRandomThemeResourceImage"

type CreateOrSelectResourceProps = {
  itemKey: ItemKey
} & Pick<PathCreatorProps, "pathCreatorDispatch"> &
  StackProps

function CreateOrSelectResource({
  itemKey,
  pathCreatorDispatch,
  ...stackProps
}: CreateOrSelectResourceProps) {
  const [loadImage] = useLazyRandomThemeResourceImage()

  // Handles choosing to create or select resource.
  async function handleOnChooseCreateOrSelectResource(status: StatusKey) {
    if (status === "create_resource") {
      // When creating a new resource, we first load a random image to store in the cache.
      // Only dispatching the next state once loaded.
      await loadImage()
    }

    pathCreatorDispatch({
      type: "set_status",
      status: status
    })
  }

  return (
    <ChooseOne
      itemKey="resource"
      onChoose={handleOnChooseCreateOrSelectResource}
      {...stackProps}
    />
  )
}

export default CreateOrSelectResource
