import useEventListener from "@pathwright/ui/src/components/hooks/useEventListener"
import React, { useContext, useEffect, useState } from "react"
import ToggleElementVisibility from "../lib/ToggleElementVisibility"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import PathwrightIntercomLauncher from "./PathwrightIntercomLauncher"
import {
  bootIntercom,
  dispatchIntercomEvent,
  isUsingPathwrightIntercom,
  loadIntercom
} from "./utils"

const FULL_INTERCOM_UI_ELEMENT_IDS = [
  "pathwright-intercom-launcher",
  "intercom-container",
  "intercom-launcher"
]

const INTERCOM_LAUNCHER_ELEMENT_CLASSNAMES = ["intercom-launcher"]

const IntercomContext = React.createContext()

export const useIntercomContext = () => useContext(IntercomContext)

// Hides Intercom UI after component mounts, then shows Intercom UI
// after component unmounts.
export const useToggleIntercomVisibility = (shouldHide) => {
  const { hideIntercomUI, showIntercomUI } = useIntercomContext()

  // Simply hide Intercom until component unmounts.
  useEffect(() => {
    if (typeof shouldHide === "undefined") {
      hideIntercomUI()
    }
  }, [])

  // Controlled option with shouldHide boolean.
  useEffect(() => {
    if (typeof shouldHide === "boolean") {
      shouldHide ? hideIntercomUI() : showIntercomUI()
    }
  }, [shouldHide])

  // Always show Intercom when unmounting.
  useEffect(() => showIntercomUI, [])
}

export const withToggleIntercomVisibility = (Component) => (props) => {
  useToggleIntercomVisibility()
  return <Component {...props} />
}

export const ToggleIntercomVisibility = withToggleIntercomVisibility(
  ({ children }) => children || null
)

const IntercomProvider = ({
  hideIntercomUI,
  showIntercomUI,
  hideIntercomLauncher,
  showIntercomLauncher,
  children
}) => {
  const [usingPathwrightIntercom, setUsePathwrightIntercomLauncher] =
    useState(false)
  const pwContext = usePathwrightContext()

  const boot = () => {
    if (window.Intercom) {
      window.Intercom("shutdown")
      dispatchIntercomEvent("shutdown")
      bootIntercom(pwContext)
    }
  }

  // After intercom boots or shuts down, let's determine if we should use the Pathwright Intercom launcher.
  useEventListener(
    "intercom",
    (e) => {
      switch (e.detail) {
        case "boot":
          setUsePathwrightIntercomLauncher(isUsingPathwrightIntercom(pwContext))
          break
        case "shutdown":
          setUsePathwrightIntercomLauncher(false)
          break
      }
    },
    [pwContext]
  )

  useEffect(() => {
    loadIntercom(pwContext).then(boot)
  }, [pwContext.me])

  return (
    <IntercomContext.Provider
      value={{
        // hide/show full Intercom UI including launcher and message window
        hideIntercomUI: hideIntercomUI,
        showIntercomUI: showIntercomUI,
        // hide/show launcher (only Intercom launcher, not PathwrightIntercomLauncher)
        hideIntercomLauncher: hideIntercomLauncher,
        showIntercomLauncher: showIntercomLauncher,
        usingPathwrightIntercom: usingPathwrightIntercom
      }}
    >
      {children}
    </IntercomContext.Provider>
  )
}

IntercomProvider.displayName = "IntercomProvider"

export default ({ children }) => (
  <ToggleElementVisibility elementIds={FULL_INTERCOM_UI_ELEMENT_IDS}>
    {({ hideElements: hideIntercomUI, showElements: showIntercomUI }) => (
      <ToggleElementVisibility
        elementClassNames={INTERCOM_LAUNCHER_ELEMENT_CLASSNAMES}
      >
        {({
          hideElements: hideIntercomLauncher,
          showElements: showIntercomLauncher
        }) => (
          <IntercomProvider
            hideIntercomUI={hideIntercomUI}
            showIntercomUI={showIntercomUI}
            hideIntercomLauncher={hideIntercomLauncher}
            showIntercomLauncher={showIntercomLauncher}
          >
            {children}
            <PathwrightIntercomLauncher />
          </IntercomProvider>
        )}
      </ToggleElementVisibility>
    )}
  </ToggleElementVisibility>
)
