import CustomEvent from "custom-event"
import { useEffect } from "react"
import isEqual from "react-fast-compare"
import { useHistory } from "react-router-dom"

// Dispatch a history event.
export const dispatchHistoryEvent = (action, route, state) => {
  const event = new CustomEvent("history", {
    detail: {
      action,
      route,
      state
    }
  })
  document.dispatchEvent(event)
}

// Execute a history event.
const handleHistoryEvent = (history, event) => {
  const { action, route, state } = event.detail
  // const stringifiedHistory = JSON.stringify({ route, state })

  // if (action === "push") {
  //   // Check if we're attempting to push the previous route into
  //   // history, and if so, simply go back to previous route.
  //   const previousEntry = historyEntries[historyEntries.length - 2]
  //   console.log({ previousEntry, stringifiedHistory })
  //   if (previousEntry && previousEntry === stringifiedHistory) {
  //     console.log("going back")
  //     return history.goBack()
  //   } else {
  //     // Record new entry.
  //     historyEntries.push(stringifiedHistory)
  //   }
  // }

  // Attempt to convert unrecognized query obj to search string.
  if (route && route.query && !route.search) {
    try {
      route.search = new URLSearchParams(route.query).toString()
    } catch {
      // noop
    }
  }

  return history[action](route, state)
}

// Hook up history event listener that will execute history events.
export const useHistoryEvent = () => {
  const history = useHistory()

  useEffect(() => {
    const handler = (e) => handleHistoryEvent(history, e)
    document.addEventListener("history", handler)
    return () => document.removeEventListener("history", handler)
  }, [])
}

// These history events provide a workaround for this issue:
// https://github.com/remix-run/history/issues/822#issuecomment-951953365

// Possibly use historyEntries to track history entries and handle
// going back instead of pushing previous entry, but to fully implement
// would need to listen to history itslef and update historyEntries.
export let historyEntries = []

// Note: using the history under react-router-dom context.
export const HistoryListener = () => {
  const history = useHistory()
  function onChangeHistory(location, action) {
    // Sync historyEntries with the history action.
    switch (action) {
      case "PUSH":
        historyEntries.push({ location, action })
        break
      case "POP":
        historyEntries.pop()
        break
      case "REPLACE":
        historyEntries[historyEntries.length - 1] = { location, action }
        break
    }

    // Remove the unique location.key field for comparing entries.
    function getComparableEntry(entry) {
      if (entry) {
        const clonedEntry = structuredClone(entry)
        delete clonedEntry.location.key
        // Defaulting the state to an empy obj for comparison.
        clonedEntry.state ||= {}
        return clonedEntry
      }
    }

    // If we happen to have pushed a duplicate entry, pop the last one.
    // This could occur when the same route has been replaced multiple times.
    if (
      isEqual(
        getComparableEntry(historyEntries[historyEntries.length - 1]),
        getComparableEntry(historyEntries[historyEntries.length - 2])
      )
    ) {
      historyEntries.pop()
    }
  }

  useEffect(() => {
    // Initial location is not listened to, so manually doing so.
    onChangeHistory(history.location, "PUSH")
    // Sync history with nav store.
    const unlisten = history.listen(onChangeHistory)
    // Unlisten when HistoryListener unmounts.
    return unlisten
  }, [])

  return null
}
