import { useMutation } from "@apollo/client"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import Button from "@pathwright/ui/src/components/button/Button"
import InlineConfirm from "@pathwright/ui/src/components/confirm/InlineConfirm"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import HtmlText from "@pathwright/ui/src/components/text/HtmlText"
import { stripHTML } from "@pathwright/ui/src/components/text/utils"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import { TERTIARY_GRAY } from "@pathwright/ui/src/components/utils/colors"
import { media } from "@pathwright/ui/src/components/utils/styles"
import CustomEvent from "custom-event"
import PropTypes from "prop-types"
import { useEffect } from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import OrderedTagList from "../../../tag/OrderedTagList"
import DELETE_DISCUSSION_MUTATION from "../../graphql/delete-discussion-mutation"
import UPDATE_USER_DISCUSSION_HISTORY from "../../graphql/update-user-discussion-history-mutation"
import DiscussionAvatarStack from "./DiscussionAvatarStack"
import DiscussionListItemControls from "./DiscussionListItemControls"
import DiscussionSubscription from "./DiscussionSubscribe"
import DiscussionUnreadIndicator from "./DiscussionUnreadIndicator"
import DiscussionVote from "./DiscussionVote"
import { getDiscussionContext } from "./utils"

const Container = styled(View)`
  position: relative;
  cursor: pointer;
  border-radius: 20px;

  /* HACKish styles to align content with DiscussionPanel content */
  .DiscussionPanel & .DiscussionListItem__content,
  .DiscussionPanel & .DiscussionListItem__actions {
    padding-left: 5px;
  }

  .DiscussionListItem__meta {
    color: ${TERTIARY_GRAY};
    font-size: 0.75em;
    margin-bottom: 0.8em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .DiscussionListItem__meta-poster {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin-right: 0.4em;
    }

    /* Remove left margin on first Tag to center the dividing bullet. */
    .OrderedTagList .Tag:first-child {
      margin-left: 0 !important;
    }
    /* Also reduce top and bottom margin. */
    .OrderedTagList .Tag {
      margin-top: 1px !important;
      margin-bottom: 1px !important;
    }
  }

  h5 {
    font-size: ${(p) => (p.compact ? "1.12em" : "1.3em")};
    margin-bottom: 0.1em;
  }

  h5 + p {
    font-size: 0.935em;
  }

  .BackgroundImage {
    z-index: 100; /* layer over .Avatar */
  }

  .DiscussionListItem__content {
    padding-bottom: 0.5em;
  }
`

const StyledItem = styled(View)`
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content;
  align-items: center;
  padding-left: 1.2em;
  position: relative;
`

const StyledMeta = styled(Text.Meta)`
  display: flex;
  align-items: center;
  padding: 0.5em;
  padding-left: 1.2em;

  .Button {
    position: relative;
    z-index: 101; /* layer over .BackgroundImage */
    ${media.max.phone`font-size: 1.5em !important;`}
  }

  > * + * {
    margin-left: 0.6em;
  }
`

const ControlsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const PromptButton = styled(Button)`
  padding: 15px calc(20px + 1.2em) !important;
  border-radius: 0 0 20px 20px !important;

  .Button__content-wrapper {
    justify-content: flex-start;

    .Avatar {
      margin-right: 0.4em;
    }

    i {
      margin-left: 0.4em;
    }
  }
`

const StyledDiscussionUnreadIndicator = styled(DiscussionUnreadIndicator)`
  left: -15px;
`

// Simple helper Component for showing the DiscussionUnreadIndicator only once,
// though it could appear in one of two places.
const WithDiscussionUnreadIndicator = ({
  children,
  discussion,
  showIndicator
}) => (
  <div style={{ position: "relative" }}>
    {showIndicator && (
      <StyledDiscussionUnreadIndicator
        discussion={discussion}
        unreadType="discussion"
      />
    )}
    {children}
  </div>
)

const DiscussionListItem = ({
  discussion,
  compact,
  showReplyPrompt,
  onSelectDiscussion,
  onEditDiscussion,
  onDeleteDiscussion,
  onSelectTag
}) => {
  const { t } = useTranslate()
  const discussionContext = getDiscussionContext(discussion)
  const pwContext = usePathwrightContext()

  const [updateUserDiscussionHistory] = useMutation(
    UPDATE_USER_DISCUSSION_HISTORY,
    {
      variables: {
        context: discussionContext,
        discussion_id: discussion.id
      }
    }
  )

  const [deleteDiscussion] = useMutation(DELETE_DISCUSSION_MUTATION, {
    variables: {
      context: discussionContext,
      id: discussion.id
    },
    update: (proxy, result) => {
      if (typeof onDeleteDiscussion === "function") {
        onDeleteDiscussion(discussion)
      }
      // Notify listeners of discussion being deleted.
      const event = new CustomEvent("discussion:deleted", {
        detail: {
          discussion
        }
      })
      document.dispatchEvent(event)
    }
  })

  // Consider a discussion viewed by user once mounted in non-compact mode.
  useEffect(() => {
    if (!compact) updateUserDiscussionHistory()
  }, [])

  const handleViewDiscussion = (e, viaReplyPrompt = false) => {
    e.stopPropagation()
    if (typeof onSelectDiscussion === "function") {
      onSelectDiscussion(discussion, viaReplyPrompt)
    }
  }

  const handleEditDiscussion = () => {
    if (typeof onEditDiscussion === "function") {
      onEditDiscussion(discussion)
    }
  }

  const showHeading = Boolean(
    !discussion.is_discussion_question || discussion.tagLinks.length
  )

  return (
    <Container
      className="DiscussionListItem"
      emphasis="primary"
      shadow={compact}
      compact={compact}
      onClick={handleViewDiscussion}
    >
      <InlineConfirm
        overlay
        onConfirm={() =>
          deleteDiscussion().then(() => {
            // HACK: Apollo bug? This does refetch the sync plan queries but DOES NOT cause the SyncButton to update
            // unclear as to why, but the query data in the SyncButton is stale
            // Maybe related: https://github.com/apollographql/apollo-client/issues/3909
            // appears that below works because the useSyncPlanContext is higher up in the tree, above the SyncButton
            // refetchSyncPlanQueries()
            window.App &&
              window.App.getStore("pathAdmin").storeTrigger("path:item:updated")
          })
        }
      >
        {({ confirm: confirmDelete }) => (
          <>
            <View
              className="DiscussionListItem__content"
              paddingTop
              paddingLeft
              paddingRight
              size="small"
            >
              <StyledItem>
                <div>
                  <div>
                    {showHeading && (
                      <WithDiscussionUnreadIndicator
                        discussion={discussion}
                        showIndicator
                      >
                        <Text.Body
                          as="span"
                          className="DiscussionListItem__meta"
                        >
                          {!discussion.is_discussion_question && (
                            <span className="DiscussionListItem__meta-poster">
                              {!compact && (
                                <Avatar
                                  user={discussion.user}
                                  size="1.2em"
                                  style={{ marginRight: ".4em" }}
                                />
                              )}
                              {t("{{ user }} {{ date }}", {
                                user: discussion.user.display_name,
                                date: moment(discussion.created_time).fromNow()
                              })}
                            </span>
                          )}
                          {!!discussion.tagLinks.length && (
                            <OrderedTagList
                              tagLinks={discussion.tagLinks}
                              context={discussionContext}
                              onClickTag={onSelectTag}
                            />
                          )}
                        </Text.Body>
                      </WithDiscussionUnreadIndicator>
                    )}
                    <WithDiscussionUnreadIndicator
                      discussion={discussion}
                      showIndicator={!showHeading}
                    >
                      <Text.H5 lines={compact ? 2 : null}>
                        {discussion.title}
                      </Text.H5>
                    </WithDiscussionUnreadIndicator>
                    {compact ? (
                      <Text.Body lines={1}>
                        {stripHTML(discussion.body)}
                      </Text.Body>
                    ) : (
                      <HtmlText html={discussion.body} quill />
                    )}
                  </div>
                </div>
                <ControlsContainer>
                  <DiscussionListItemControls
                    discussion={discussion}
                    handleDelete={() => confirmDelete()}
                    handleEdit={handleEditDiscussion}
                  />
                </ControlsContainer>
              </StyledItem>
            </View>
            <View
              className="DiscussionListItem__actions"
              paddingLeft
              roundedBottom
              size="small"
            >
              <StyledMeta>
                <DiscussionVote discussion={discussion} />
                {!compact && <DiscussionSubscription discussion={discussion} />}
                <DiscussionAvatarStack discussion={discussion} />
              </StyledMeta>
            </View>
            {showReplyPrompt && (
              <PromptButton
                className="DiscussionListItem__prompt"
                styleType="secondary"
                stretch
                onClick={(e) =>
                  handleViewDiscussion(e, true /* viaReplyPrompt */)
                }
              >
                <Avatar user={pwContext.me} size="1.5em" />
                {t("Reply")}
                <Pathicon icon="chevron-right" />
              </PromptButton>
            )}
          </>
        )}
      </InlineConfirm>
    </Container>
  )
}

DiscussionListItem.displayName = "DiscussionListItem"

DiscussionListItem.propTypes = {
  onSelectDiscussion: PropTypes.func,
  onEditDiscussion: PropTypes.func,
  onDeleteDiscussion: PropTypes.func,
  onSelectTag: PropTypes.func,
  compact: PropTypes.bool,
  showReplyPrompt: PropTypes.bool
}

DiscussionListItem.defaultProps = {
  compact: true,
  showReplyPrompt: false
}

export default DiscussionListItem
