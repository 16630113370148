export const loadAuthors = function ({ match }) {
  window.App.getStore("authorAdmin").action.loadAuthors()
  // aka onLeave childCard route
  if (!match.params.authorId) {
    return window.App.getStore("authorAdmin").action.unselectAuthor()
  }
}

export const selectAuthor = function ({ match }) {
  let { authorId } = match.params
  authorId = authorId !== "new" ? authorId : null
  return window.App.getStore("authorAdmin").action.selectAuthor(authorId)
}

export const unselectAuthor = () =>
  window.App.getStore("authorAdmin").action.unselectAuthor()
