import Checkbox from "@pathwright/ui/src/components/form/form-checkbox/Checkbox"
import CheckboxGroup from "@pathwright/ui/src/components/form/form-checkbox/CheckboxGroup"
import LoadingBlock from "@pathwright/ui/src/components/loading/LoadingBlock"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import get from "lodash/get"
import PropTypes from "prop-types"
import { Field, Form } from "react-connect-form-forked"
import styled from "styled-components"
import { useSchoolSubscriptionPlansQuery } from "../../api/generated"
import { useCurrencyFormatter } from "../../currency/CurrencyFormatterProvider"
import { flattenEdges } from "../../utils/apollo"

const StyledCheckboxGroup = styled(CheckboxGroup)`
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;

  & > div {
    align-items: flex-start;
  }
`

const StyledCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
`

// To silence PropTypes error from "eact-connect-form-forked"
const CheckboxContainer = (props) => <StyledCheckbox {...props} />

const StyledPlanDetails = styled.span`
  position: relative;
  display: grid;
  ${(p) =>
    p.locked
      ? `grid-template-columns: max-content max-content 1fr max-content;`
      : `grid-template-columns: max-content 1fr max-content;`}
  align-items: center;

  img {
    height: 1em;
    padding-right: 5px;
    padding-left: 5px;
  }

  .Pathicon {
    margin-left: 5px;
  }
`

export const useSchoolSubscriptionPlans = ({
  interval,
  intervalFilter
} = {}) => {
  const { data, error, loading, refetch } = useSchoolSubscriptionPlansQuery({
    variables: {
      ignore_visibility: true,
      interval,
      intervalFilter
    }
  })

  return {
    schoolSubscriptionPlans:
      flattenEdges(get(data, "school.subscription_plans")) || [],
    error,
    loading,
    refetch
  }
}

const SchoolSubscriptionPlansCheckboxes = ({
  onChange,
  value,
  interval,
  intervalFilter,
  ...rest
}) => {
  const { currencyFormatter } = useCurrencyFormatter()
  const { loading, schoolSubscriptionPlans } = useSchoolSubscriptionPlans({
    interval,
    intervalFilter
  })

  if (loading) {
    return <LoadingBlock />
  }

  if (!schoolSubscriptionPlans.length) {
    return null
  }

  const handleChange = (e, list) => {
    const value = parseInt(e.target.value)
    const index = list.indexOf(value)
    if (index > -1) {
      onChange && onChange(list.slice(0, index).concat(list.slice(index + 1)))
    } else {
      onChange && onChange(list.concat(value))
    }
    return e
  }

  // Sort by interval, then by amount, then by name, then by visibility
  const sortedPlans = schoolSubscriptionPlans
    .sort((a, b) => a.subscription_plan.interval - b.subscription_plan.interval)
    .sort((a, b) => a.subscription_plan.amount - b.subscription_plan.amount)
    .sort((a, b) => {
      const nameA = a.subscription_plan.name.toUpperCase()
      const nameB = b.subscription_plan.name.toUpperCase()
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
    })
    .sort((a, b) =>
      a.is_visible !== b.is_visible ? (a.is_visible ? -1 : 1) : 0
    )

  return (
    <Form
      initialValue={{ school_subscription_plans: value }}
      render={(formState) => (
        <StyledCheckboxGroup {...rest}>
          {sortedPlans.map((plan) => (
            <Field
              id={`school_subscription_plan-${plan.id}`}
              name="school_subscription_plans"
              type="checkbox"
              key={plan.id}
              value={plan.id}
              checked={value.includes(plan.id)}
              onChange={(e) =>
                handleChange(e, formState.value.school_subscription_plans)
              }
              component={CheckboxContainer}
            >
              <StyledPlanDetails locked={!plan.is_visible}>
                {!plan.is_visible && <Pathicon icon="lock" />}
                <img src={plan.image} />
                <strong>{plan.subscription_plan.name}</strong>
                &#8212;
                {currencyFormatter.customFormat(plan.subscription_plan.amount)}/
                {plan.subscription_plan.plan_type}
              </StyledPlanDetails>
            </Field>
          ))}
        </StyledCheckboxGroup>
      )}
    />
  )
}

SchoolSubscriptionPlansCheckboxes.displayName =
  "SchoolSubscriptionPlansCheckboxes"

SchoolSubscriptionPlansCheckboxes.propTypes = {
  value: PropTypes.array, // list of selected school subscription plan ids - this is a controlled component
  onChange: PropTypes.func
}

SchoolSubscriptionPlansCheckboxes.defaultProps = {
  value: []
}

export default SchoolSubscriptionPlansCheckboxes
