import classnames from "classnames"
import PropTypes from "prop-types"
import styled from "styled-components"
import BackgroundImage from "../background/BackgroundImage"
import ThemeBackground from "../background/ThemeBackground"
import Portal from "../portal/Portal"
import ScrollView from "../scroll/ScrollView"
import FullscreenContainer from "./FullscreenContainer"

export const FULLSCREEN_LAYER_BASE = 999999999
export const FULLSCREEN_LAYER_APP = 0
export const FULLSCREEN_LAYER_PANEL = 2000
export const FULLSCREEN_LAYER_OVERLAY = 2020

const StyledFullscreenContainer = styled(FullscreenContainer)`
  pointer-events: none;

  .ScrollView > * {
    pointer-events: auto;
  }
`

const pointerEventsAuto = {
  pointerEvents: "auto"
}

const pointerEventsNone = {
  pointerEvents: "none"
}

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
`

const Fullscreen = ({
  portalElementID,
  themePortalElementID,
  className,
  children,
  offset,
  layer,
  background,
  onClickBackground,
  allowScroll,
  style
}) => {
  const handleClick = onClickBackground
    ? (e) => {
        e.preventDefault()
        e.stopPropagation()
        onClickBackground(e)
        return false
      }
    : null

  const hideBackground = background === null

  const bg = background ? (
    <BackgroundImage
      image={background.image}
      overlay={background.overlay}
      onClick={handleClick}
      style={pointerEventsAuto}
    />
  ) : !hideBackground ? (
    <ThemeBackground onClick={handleClick} style={pointerEventsAuto} />
  ) : null

  return (
    <StyledFullscreenContainer
      className={classnames("Fullscreen", className)}
      portalElementID={portalElementID}
      layer={layer}
      offset={offset}
      style={style}
    >
      {themePortalElementID ? (
        <Portal portalElementID={themePortalElementID}>{bg}</Portal>
      ) : (
        bg
      )}

      {/* transparent overlay to prevent pointer events - fixes scrolling overlayed content on ios */}
      {!hideBackground && !allowScroll && <Overlay style={pointerEventsNone} />}

      <ScrollView
        fill
        lockBodyScroll={allowScroll}
        style={allowScroll ? pointerEventsAuto : pointerEventsNone}
        onClick={handleClick}
      >
        {/* ensure click is handled inside scroll view */}
        {!hideBackground && <Overlay onClick={handleClick} />}
        {children}
      </ScrollView>
    </StyledFullscreenContainer>
  )
}

Fullscreen.displayName = "Fullscreen"

Fullscreen.propTypes = {
  ...FullscreenContainer.propTypes,
  allowScroll: PropTypes.bool
}

Fullscreen.defaultProps = {
  allowScroll: true
}

export default Fullscreen
