import { BoxProps } from "@chakra-ui/react"

const base: BoxProps = {
  w: "100%",
  p: 4,
  borderRadius: "xl"
}

export const transient: BoxProps = {
  ...base,
  bg: "whiteAlpha.500"
}

export const solid: BoxProps = {
  ...base,
  bg: "blackAlpha.500"
}
