import AvatarStack from "@pathwright/ui/src/components/avatar/AvatarStack"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import classnames from "classnames"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import { useMemo } from "react"
import styled from "styled-components"
import USER_FRAGMENT from "../../user/graphql/user-fragment"

const INBOX_REVIEWERS_QUERY = gql`
  query InboxReviewers($pathId: Int!) {
    inboxReviewers(first: 50, path_id: $pathId) {
      edges {
        node {
          id
          group_role
          cohort_role
          can_review
          user {
            ...User
          }
        }
      }
    }
  }
  ${USER_FRAGMENT}
`

export const useInboxReviewers = (pathId, options) => {
  const query = useQuery(INBOX_REVIEWERS_QUERY, {
    variables: { pathId: pathId },
    ...options
  })

  const reviewers = useMemo(() => {
    if (query.data) {
      return query.data.inboxReviewers.edges.map((edge) => edge.node)
    }
    return []
  }, [query.data])

  return { query, reviewers }
}

export function getReviewersLabel(t, { count, limit }) {
  const remainder = count - limit

  if (remainder > 0) {
    return t("+{{ count }} reviewer", {
      defaultValue_plural: "{{ count }} reviewers",
      count: remainder
    })
  }

  return t("{{ count }} reviewer", {
    defaultValue_plural: "{{ count }} reviewers",
    count
  })
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span:last-child {
    margin-left: 0.4em;
  }
`

const Reviewers = ({ className, item, limit, getLabel, ...rest }) => {
  const { query, reviewers } = useInboxReviewers(item.data.path_id)
  const { t } = useTranslate()
  const label = getLabel(t, { count: reviewers.length, limit })

  if (query.loading || !reviewers.length) return null

  return (
    <Container className={classnames("Reviewers", className)}>
      <AvatarStack
        users={reviewers.map((reviewer) => reviewer.user)}
        size="1.4em"
        itemOffset="-0.2em"
        limit={limit}
        reverse
        getTooltip={(item) => item.display_name}
        {...rest}
      />
      {!!label && <span>{label}</span>}
    </Container>
  )
}

Reviewers.displayName = "Reviewers"

Reviewers.propTypes = {
  item: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
  limit: PropTypes.number,
  getLabel: PropTypes.func.isRequired
}

Reviewers.defaultProps = {
  item: null,
  limit: 3,
  getLabel: getReviewersLabel
}

export default Reviewers
