import { captureException } from "@sentry/browser"
import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import Error from "./Error"
import LoadingChunkErrorBoundary from "./LoadingChunkErrorBoundary"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    // log the error to our error reporting service
    if (this.props.logToSentry) {
      captureException(error)
    }
  }

  render() {
    if (this.state.error) {
      if (this.props.renderError) {
        return this.props.renderError(this.state.error, () => {
          this.setState({ error: null })
        })
      }

      // You can render any custom fallback UI
      return window.school ? (
        <Error
          className={classnames("ErrorBoundary", this.props.className)}
          email={window.school.email}
          errorTitle="An unexpected error occurred"
          errorDetails={this.state.error.toString()}
          // errorDetails={this.state.error.stack}
          primaryAction={{
            label: `Email ${window.school.name}`
          }}
          secondaryAction={{
            label: "or refresh",
            onClick: () => (window.location = window.location)
          }}
        />
      ) : (
        <Error
          className={classnames("ErrorBoundary", this.props.className)}
          errorTitle="An unexpected error occurred"
          errorDetails={this.state.error.toString()}
          // errorDetails={this.state.error.stack}
          primaryAction={{
            label: "Try refreshing",
            onClick: () => (window.location = window.location)
          }}
        />
      )
    }

    if (this.props.catchLoadingChunkFailures) {
      return (
        <LoadingChunkErrorBoundary>
          {this.props.children}
        </LoadingChunkErrorBoundary>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.defaultProps = {
  logToSentry: true,
  catchLoadingChunkFailures: true
}

ErrorBoundary.propTypes = {
  logToSentry: PropTypes.bool,
  catchLoadingChunkFailures: PropTypes.bool,
  renderError: PropTypes.func
}

ErrorBoundary.displayName = "ErrorBoundary"

export default ErrorBoundary
