import { mergeDeep, omitDeep } from "@apollo/client/utilities"
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  LightMode,
  VStack
} from "@chakra-ui/react"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { Field, Form, Formik, FormikProps } from "formik"
import { MouseEvent, useMemo, useRef, useState } from "react"
import { z } from "zod"
import { toFormikValidate } from "zod-formik-adapter"
import { RegistrationRoleEnum } from "../../api/generated"
import BGMode from "../../components/BGMode"
import KeyboardFormSubmit from "../../components/formik/KeyboardFormSubmit"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import usePathCreatorFlowSubmit from "././usePathCreatorFlowSubmit"
import CohortSelector from "./CohortSelector"
import ResourceSelector from "./ResourceSelector"
import RoleSelector, { RoleSelectorOnChange } from "./RoleSelector"
import FormSubmitButton from "./components/FormSubmitButton"
import {
  Cohort,
  PathCreatorHistoryProps,
  PathCreatorProps,
  PathCreatorState,
  Resource,
  SelectedCohort,
  SelectedResource,
  StatusKey,
  pathCreatorStateSchema,
  statusKeySchema
} from "./state"
import { solid } from "./style"
import useRandomThemeResourceImage from "./useRandomThemeResourceImage"

const validator = toFormikValidate(pathCreatorStateSchema)

const valuesSchema = z.union(pathCreatorStateSchema.options)

type PathCreatorValues = Omit<z.infer<typeof valuesSchema>, "status">

// Get the "most valid" state based on the current status and the
// provided form values.
function getMostValidState(
  currentStatus: StatusKey,
  values: PathCreatorValues
): PathCreatorState {
  // We slice this list of statuses starting from the status in the list
  // comming immediately after the current status.
  const currentStatusIndex = statusKeySchema.options.indexOf(currentStatus)

  // // Ensure we don't exceed the length of the list.
  const nextStatusIndex = Math.min(
    currentStatusIndex + 1,
    // Note: we're not validating the final "complete" status, since the form
    // is not concerned with that state.
    statusKeySchema.options.length - 2
  )

  // We reverse the list, since we want to stop at the first valid status.
  // const statuses = statusKeySchema.options.slice(nextStatusIndex).reverse()
  const statuses = statusKeySchema.options
    .slice(
      // Some issue arose due to only looking forward for possible acceptable status.
      // This broke validation. It may be that we should rather try going forward, and
      // if not possible, going backward?
      // nextStatusIndex,
      0,
      // Note: we're not validating the final "complete" status, since the form
      // is not concerned with that state.
      statusKeySchema.options.length - 1
    )
    .reverse()

  // Ensure we have a status, falling back to the current status.
  if (!statuses.length) statuses.push(currentStatus)

  // Looping through each status, return the data for the first valid status found.
  for (let i = 0; i < statuses.length; i++) {
    const status = statuses[i]
    const parsed = pathCreatorStateSchema.safeParse({
      status,
      ...structuredClone(values)
    })

    if (parsed.success) {
      return parsed.data
    }
  }

  // When no valid status is found, return the next status + current values.
  // Force this to be considered PathCreatorState, even though the status + values
  // combo may not actually be valid (we'll validate that).
  return {
    status: statusKeySchema.options[nextStatusIndex],
    ...structuredClone(values)
  } as PathCreatorState
}

// Transforms the form values into expected pathCreatorState shape.
function transformValues(
  pathCreatorState: PathCreatorState,
  values: PathCreatorFormValues
) {
  // Here we merge in the current path creator state, omiting the status.
  // This is because there is some data (like selected resource's source cohort)
  // that is not configurable, and therefore should not be handled by form state.
  const mergedValues = mergeDeep(
    omitDeep(pathCreatorState, "status"),
    structuredClone(values)
  )

  // Return the merged values.
  return mergedValues
}

// Transforms the form values into (potentially) valid state.
function transformValuesToState(
  pathCreatorState: PathCreatorState,
  values: PathCreatorFormValues
) {
  const mergedValues = transformValues(pathCreatorState, values)
  // Return the most valid state for these values.
  return getMostValidState(pathCreatorState.status, mergedValues)
}

// Override user's role if we should force the editor role.
function useShouldForceDesignRole() {
  const { me } = usePathwrightContext()
  const shouldForceDesignRole = !!me.permissions?.can_edit_library
  return shouldForceDesignRole
}

export type OnFlowComplete = (
  state: PathCreatorState & { status: "complete" }
) => Promise<void> | void

type PathCreatorFormValues = {
  resource?: SelectedResource | Resource | null
  cohort?: SelectedCohort | Cohort | null
}

type PathCreatorFormProps = {
  targetUserId: number
  onFlowComplete?: OnFlowComplete
} & PathCreatorProps &
  PathCreatorHistoryProps

function PathCreatorForm({
  targetUserId,
  onFlowComplete,
  pathCreatorState,
  pathCreatorDispatch
}: PathCreatorFormProps) {
  const [error, setError] = useState<string>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  // Ugh, some functions that encapsulate pathCreatorState run with stale value,
  // so storing a ref to it in order to use most up-to-date value within said
  // functions. Can't resolve issue by using useCallback as the resulting function
  // still doesn't get updated before being called with stale value.
  const pathCreatorStateRef = useRef(pathCreatorState)
  pathCreatorStateRef.current = pathCreatorState
  // Selecting resource when that is the status or we
  // have a selected resource.
  // TODO: this is ugly, consider how to simplify.
  const selectingResource = Boolean(
    pathCreatorState.status === "select_resource" ||
      (pathCreatorState.resource && "id" in pathCreatorState.resource)
  )
  const [defaultImage, image, burredImage] = useRandomThemeResourceImage({
    skip: selectingResource,
    fetchPolicy: "cache-only"
  })
  const resourceImage = pathCreatorState.resource?.image || image
  // Get the bg image to use when possible for form bg.
  const resourceBgImage =
    pathCreatorState.resource && "background_image" in pathCreatorState.resource
      ? pathCreatorState.resource?.background_image
      : burredImage

  // When user presses "Enter" within input that is valid, but the form itself
  // is not valid (presumably because they have returned to a previously completed
  // input), then manually focus the next tabbable element).
  // TODO: generalize and apply to react select components (for advancing after selecting resource/cohort).
  function tabOnEnter(formik: FormikProps<PathCreatorFormValues>) {
    return function (e: KeyboardEvent) {
      if (e.key === "Enter") {
        // Loop through all form elements, finding the element that follows the current one.
        const currentElement = e.target as HTMLElement
        const form = currentElement.closest("form")!
        const formElements = Array.from(form.elements).filter(
          (element) => element.tagName === "INPUT"
        ) as HTMLElement[]
        const currentIndex = [...formElements].findIndex((el) =>
          currentElement.isEqualNode(el)
        )
        const targetIndex = (currentIndex + 1) % formElements.length

        // TODO: possibly explore re-enabling validation check to prevent user from progressing to
        // next input when current is invalid, but it's likely not that importatnt of a UX.
        // Better to allow the user to advance always rather than sometimes preventing them when
        // they should be able to advance (we have to jump through some hoops to ensure state is valid
        // when subsequent inputs are in an invalid state).

        // Get input name, declaring it is a key of formik.errors (which it should be).
        // const inputName = currentElement
        //   .getAttribute("name")
        //   ?.split(".")[0] as keyof typeof formik.errors

        // Only focus when target index differs from current and input is valid.
        // if (targetIndex !== currentIndex && !formik.errors[inputName]) {
        //   formElements[targetIndex]?.focus()
        // }

        // Only focus when target index differs from current.
        if (targetIndex > currentIndex) {
          formElements[targetIndex]?.focus()
        }
      }
    }
  }

  const onFlowSubmit = usePathCreatorFlowSubmit()

  function handleFlowSubmit(formik: FormikProps<PathCreatorFormValues>) {
    return async function (e: MouseEvent) {
      // Don't cause <form /> onSubmit handler to be called.
      e.preventDefault()
      e.stopPropagation()

      const currentState = transformValuesToState(
        pathCreatorStateRef.current,
        formik.values
      )

      if (currentState.status === "submit") {
        try {
          setIsSubmitting(true)
          const state = await onFlowSubmit({
            targetUserId,
            ...currentState
          })

          // Call the onFlowComplete callback.
          await onFlowComplete?.(state)

          pathCreatorDispatch({
            type: "set",
            value: state
          })
        } catch (error) {
          setError(error as unknown as string)
        } finally {
          setIsSubmitting(false)
        }
      }
    }
  }

  function onSubmit(values: PathCreatorFormValues) {
    // Dispatch a "set" action based on current state.
    // At this point, we should only be submitting a valid form.
    pathCreatorDispatch({
      type: "set",
      value: transformValuesToState(pathCreatorStateRef.current, values)
    })
  }

  // Validate form based on expected action
  async function validate(values: PathCreatorFormValues) {
    return validator(
      transformValuesToState(pathCreatorStateRef.current, values)
    )
  }

  const shouldForceDesignRole = useShouldForceDesignRole()

  // NOTE: previously we supported navigating forward through the history stack.
  // This required the alternate use of the appropriate history item for deriving
  // the initial values. This is somewhat buggy, and we're not supporting forward
  // nav, so commenting out for now.

  // Grab the succeeding history item for the default value when
  // no current path creator state value exists.
  // const currentHistoryItem = historyState.items[historyState.index + 1]

  // Use current history item as the initial state when we've gone back in history.
  // const initialState =
  //   historyState.index < historyState.items.length - 1
  //     ? currentHistoryItem
  //     : pathCreatorState

  // const initialState = currentHistoryItem
  //   || pathCreatorState

  const initialState = pathCreatorState

  const initialValues = useMemo(() => {
    const defaultResource = {
      image: resourceImage,
      name: "",
      role: undefined
    }

    const defaultCohort = {
      name: "",
      role: undefined
    }

    // The initial values, prioritizing path creator state, then
    // the immediately succeeding history item state, then "".
    const initialValues: PathCreatorFormValues = {
      resource: initialState.resource || defaultResource
    }

    // We only want to include/validate the cohort values when we have a reesource.
    if (pathCreatorState.resource) {
      initialValues.cohort = initialState.cohort || defaultCohort
    }

    return initialValues
  }, [initialState])

  return (
    <Formik
      initialValues={initialValues}
      // Necessary for keeping form up to date with pathCreatorState.
      enableReinitialize
      validateOnMount
      // Prevent the default validation behavior on blur which can lead to validating
      // with stale data due to auto-focusing inputs on mount which must trigger a
      // validation call with stale data.
      validateOnBlur={false}
      validate={validate}
      onSubmit={onSubmit}
    >
      {(form) => (
        <BGMode
          exclusions={[`[data-theme="dark"][id$="-listbox"]`]}
          input={{
            color: "whiteAlpha.800",
            // Attempt to override browser autofill styles, but not working.
            bg: "light-dark(transparent, transparent) !important",
            borderColor: "whiteAlpha.600",
            _placeholder: { color: "whiteAlpha.600" }
          }}
        >
          <Box as={Form} w="100%">
            <KeyboardFormSubmit />
            {!!(resourceBgImage || resourceImage) && (
              <Image
                src={resourceBgImage || resourceImage}
                w="100%"
                h="100%"
                position="absolute"
                inset={0}
                borderRadius="xl"
                zIndex={-1}
                fallback={
                  <Box
                    w="100%"
                    h="100%"
                    position="absolute"
                    inset={0}
                    bg="gray.200"
                    borderRadius="xl"
                  />
                }
              />
            )}
            <VStack spacing={2} {...solid}>
              <HStack spacing={4} w="100%">
                <VStack spacing={2} flexGrow={1} w="100%">
                  <FormControl>
                    {/* Hidden but functional(?) form label */}
                    <FormLabel pos="absolute" top="-99999999999px">
                      Path name
                    </FormLabel>
                    {selectingResource ? (
                      <Field
                        as={ResourceSelector}
                        name="resource"
                        onChange={async (item: SelectedResource) => {
                          // Only update when resource differs, prevents losing selected roles and/or cohort.
                          if (
                            !form.values.resource ||
                            !("id" in form.values.resource) ||
                            item?.id !== form.values.resource.id
                          ) {
                            const values = {
                              resource: item,
                              // Clear out the cohort when clearing selected resource,
                              // or cohort isn't a selected one.
                              cohort:
                                item &&
                                form.values.cohort &&
                                !("id" in form.values.cohort)
                                  ? form.values.cohort
                                  : null
                            }
                            // Setting values without validating.
                            await form.setValues(values, false)
                            // Now validate, and only submit when form is valid.
                            if (!(await validate(values))) onSubmit(values)
                          }
                        }}
                      />
                    ) : (
                      <InputGroup>
                        <InputLeftElement>
                          <Image
                            src={resourceImage}
                            maxH="24px"
                            borderRadius="md"
                            fallback={
                              <Box
                                maxH="24px"
                                bg="gray.200"
                                borderRadius="md"
                              />
                            }
                          />
                        </InputLeftElement>
                        <Field
                          name="resource.name"
                          type="text"
                          as={Input}
                          placeholder="Path name..."
                          autoFocus
                          onKeyUp={tabOnEnter(form)}
                          // Seems this value isn't getting set on the input, likely due to
                          // the Field wrapper.
                          paddingInlineStart="var(--input-height)"
                          borderRadius="xl"
                        />
                        {/* Improve UX by displaying a submit button prompt to indicate the field must be submitted.
                            User may also click the button to submit the field. */}
                        {(!pathCreatorState.resource ||
                          // NOTE: can't depend on form.errors because "resource" key can become invalid
                          // when editing other fields.
                          !form.values.resource?.name) && (
                          <InputRightElement>
                            {
                              <IconButton
                                type="submit"
                                aria-label="next"
                                icon={<Pathicon icon="chevron-right" />}
                                h="100%"
                                borderRadius="0px"
                                isDisabled={"resource" in form.errors}
                              />
                            }
                          </InputRightElement>
                        )}
                      </InputGroup>
                    )}
                  </FormControl>

                  {!!pathCreatorState.resource && (
                    // When a resource is present, we must determine if user should be
                    // presented with option to select/create or select or create.
                    // create cohort
                    <FormControl>
                      <FormLabel pos="absolute" top="-99999999999px">
                        Cohort name
                      </FormLabel>
                      {
                        // Gross... ensure we only show select input when able to select
                        // a cohort or have already selected an existing cohort.
                        Boolean(
                          pathCreatorState.resource &&
                            "id" in pathCreatorState.resource &&
                            ((pathCreatorState.cohort &&
                              "id" in pathCreatorState.cohort) ||
                              (!pathCreatorState.cohort &&
                                pathCreatorState.status !== "create_cohort"))
                        ) ? (
                          <Field
                            as={CohortSelector}
                            name="cohort"
                            targetUserId={targetUserId}
                            pathCreatorState={pathCreatorState}
                            pathCreatorDispatch={pathCreatorDispatch}
                            onChange={async (item: SelectedCohort) => {
                              // Only update when cohort differs, prevents losing selected roles.
                              if (
                                !form.values.cohort ||
                                !("id" in form.values.cohort) ||
                                item?.id !== form.values.cohort.id
                              ) {
                                const values = {
                                  resource: form.values.resource,
                                  cohort: item
                                    ? {
                                        ...item,
                                        role: undefined
                                      }
                                    : item
                                }
                                // Setting values without validating.
                                await form.setValues(values, false)
                                // Now validate, and only submit when form is valid.
                                if (!(await validate(values))) onSubmit(values)
                              }
                            }}
                          />
                        ) : (
                          <InputGroup>
                            <InputLeftElement>
                              <Pathicon icon="group" />
                            </InputLeftElement>
                            <Field
                              as={Input}
                              type="text"
                              name="cohort.name"
                              // HACK: preventing error when input would otherwise go from uncontrolled to controlled
                              // due to not providing an initial cohort value when form mounts.
                              value={form.values.cohort?.name || ""}
                              placeholder="Cohort name..."
                              autoFocus
                              // Seems this value isn't getting set on the input, likely due to
                              // the Field wrapper.
                              paddingInlineStart="var(--input-height)"
                              onKeyUp={tabOnEnter(form)}
                              borderRadius="xl"
                            />
                            {/* Improve UX by displaying a submit button prompt to indicate the field must be submitted.
                            User may also click the button to submit the field. */}
                            {(!pathCreatorState.cohort ||
                              // NOTE: can't depend on form.errors because "cohort" key can become invalid
                              // when editing other fields.
                              !form.values.cohort?.name) && (
                              <InputRightElement>
                                {
                                  <IconButton
                                    type="submit"
                                    aria-label="next"
                                    icon={<Pathicon icon="chevron-right" />}
                                    h="100%"
                                    borderRadius="0px"
                                    isDisabled={"cohort" in form.errors}
                                  />
                                }
                              </InputRightElement>
                            )}
                          </InputGroup>
                        )
                      }
                    </FormControl>
                  )}
                </VStack>
              </HStack>
              {!!pathCreatorState.cohort && (
                <FormControl>
                  {/* Hidden but functional(?) form label */}
                  <FormLabel pos="absolute" top="-99999999999px">
                    Your Roles
                  </FormLabel>
                  <Field
                    as={RoleSelector}
                    value={{
                      resourceRole: shouldForceDesignRole
                        ? RegistrationRoleEnum.Editor
                        : form.values.resource?.role,
                      cohortRole: form.values.cohort?.role
                    }}
                    cohort={form.values.cohort}
                    onChange={async (
                      roles: Parameters<RoleSelectorOnChange>[0]
                    ) => {
                      const values = {
                        resource: {
                          ...form.values.resource!,
                          role: roles.resourceRole
                        },
                        cohort: {
                          ...form.values.cohort!,
                          role: roles.cohortRole
                        }
                      }
                      // Setting values without validating.
                      await form.setValues(values, false)
                      // Now validate, and only submit when form is valid.
                      if (!(await validate(values))) onSubmit(values)
                    }}
                  />
                </FormControl>
              )}

              {!!error && (
                <Alert status="error">
                  <HStack>
                    <Pathicon icon="caution-triangle" />
                    <Box>
                      <AlertTitle>Error</AlertTitle>
                      <AlertDescription>{error.toString()}</AlertDescription>
                    </Box>
                  </HStack>
                </Alert>
              )}

              <LightMode>
                {/* Only revealing submit button once all data is ready (or have reached "select_role" for better UX). */}
                {pathCreatorState.status === "select_role" ||
                pathCreatorState.status === "submit" ? (
                  <FormSubmitButton
                    onClick={handleFlowSubmit(form)}
                    isLoading={isSubmitting}
                    // Ugh, gotta disable when selecting role...
                    // This is because the "select_role" state can be valid without selecting any role.
                    // If we could solve this in the state discriminated union, that would be preferable.
                    isDisabled={pathCreatorState.status === "select_role"}
                  />
                ) : (
                  // Hackery used to keep submit button in form but hidden in order for
                  // "enter" keypress to submit the form due to presence submit button.
                  <FormSubmitButton
                    // In testing, can't query by role for hidden element due to: https://github.com/testing-library/dom-testing-library/issues/846
                    data-testid="hidden-form-button"
                    hidden
                    pos="absolute"
                    top="-99999999999px"
                  />
                )}
              </LightMode>
            </VStack>
          </Box>
        </BGMode>
      )}
    </Formik>
  )
}

export default PathCreatorForm
