import { Box, Heading, Stack } from "@chakra-ui/react"
import {
  animate,
  motion,
  useMotionTemplate,
  useMotionValue,
  useMotionValueEvent,
  useScroll,
  useTransform
} from "framer-motion"
import { useRef } from "react"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import UserAvatar from "../components/UserAvatar"
// import GroupsContainer from "./GroupsContainer"
// import FloatingToolbar from "../components/FloatingToolbar"
import HomeBackground from "../components/ui/HomeBackground"

const MotionHeader = motion(Heading)
// const COLORS = [
//   "rgba(255, 255, 255, 0.8)",
//   "rgba(238, 33, 47,0.8)",
//   "rgba(255, 255, 255, 0.8)",
//   "rgba(0, 151, 220,0.8)",
//   "rgba(255, 255, 255, 0.8)",
//   "rgba(19, 179, 89,0.8)"
// ]

const COLORS = ["rgba(255, 255, 255, 0.8)", "rgba(255, 255, 255, 0.6)"]

const TopContainer = () => {
  const { me } = usePathwrightContext()

  const containerRef = useRef<HTMLDivElement>(null)

  const { scrollY } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"]
  })

  const scrollYInput = [100, 120]
  const color = useMotionValue(COLORS[0])

  // Controls opacity of GroupsContainer
  const opacity = useTransform(scrollY, [0, 120], [1, 0])
  const iconSize = useTransform(scrollY, [0, 120], [64, 40])
  const headingSize = useTransform(scrollY, [0, 120], ["2.25rem", "1.61rem"])

  // Controls gradient opacity and bgColors
  const gradientOpacity = useTransform(scrollY, scrollYInput, [0, 1])
  const backgroundImage = useMotionTemplate`radial-gradient(25% 100% at 50% 0%, ${color} 30% ,rgba(255, 255, 255, 0) 100%)`

  //cool way to trigger animations with framer-motion: https://www.framer.com/motion/use-animation-controls/
  const bgAnimation = animate(color, COLORS, {
    ease: "easeInOut",
    duration: 20,
    repeat: Infinity,
    repeatType: "mirror"
  })

  // useMotionValueEvent(scrollY, "change", (latest) => {
  //   console.log("scrollY", latest)
  // })
  useMotionValueEvent(scrollY, "change", (latest) => {
    if (latest > 120) {
      bgAnimation.play()
    } else {
      bgAnimation.pause()
    }
  })

  const resetScrollPosition = () => {
    if (containerRef.current) {
      // containerRef.current.scrollTo({ top: 0, behavior: "smooth" })
      window.scrollTo({ top: 0, behavior: "smooth" })
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }

  return (
    <Stack
      ref={containerRef}
      direction={"column"}
      // minH={"190px"}
      h="full"
      position="sticky"
      spacing={4}
      top={0}
      left={0}
      w="100%"
      align={"center"}
      // overflow={"hidden"}
      zIndex={1000}
      overflow={"hidden"}

      // Since we're z-indexing this element and positioning it sticky,
      // it will overlay elements that the user may wish to interact with.
      // Add pointerEvents "all" to any child element the user may wish to interact with.

      // pointerEvents="none"
    >
      {/* <FloatingToolbar position="absolute" right="25%" top="105%" /> */}
      <HomeBackground />
      {me ? (
        <Stack
          as={motion.div}
          direction={"row"}
          align="center"
          pos="relative"
          w={"fit-content"}
          zIndex={10}
          onClick={resetScrollPosition}
          cursor="pointer" //
        >
          <motion.div style={{ width: iconSize, height: iconSize }}>
            <UserAvatar
              boxSize="full"
              // size={"64px"}
              name={me.full_name as string}
              image={me.profile?.image as string}
              isProfile
            />
          </motion.div>
          <MotionHeader
            style={{ fontSize: headingSize }}
            color="whiteAlpha.900"
          >
            {me.full_name}
          </MotionHeader>
        </Stack>
      ) : null}
      {/* <motion.div style={{ opacity, pointerEvents: "auto" }}>
        <GroupsContainer />
      </motion.div> */}

      {/* // This Box is the gradient overlay that will animate behind user avatar */}
      <Box
        as={motion.div}
        position="absolute"
        bottom={-2}
        // backdropFilter="auto"
        // backdropBlur="2px"
        w="100%"
        h="16px"
        style={{ opacity: gradientOpacity, backgroundImage }}
        pointerEvents="none"
        zIndex={-1}
      />
    </Stack>
  )
}

export default TopContainer
