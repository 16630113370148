import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import React, { useState } from "react"
import AugmentationPanel from "../../components/augmentation/AugmentationPanel"
// import { useToggleIntercomVisibility } from "../../intercom/IntercomProvider"
import { useCertificateContext } from "../context/CertificateContext"
import { useCertificateState } from "../context/CertificateState"
import CertificateSubmitButton from "./CertificateSubmitButton"
// import IconLauncher from "./IconLauncher"
import { AugmentationLayoutPanel } from "../../components/augmentation/AugmentationLayout"
import { useMergedDisclosure } from "../../components/augmentation/utils"
import { useToggleIntercomVisibility } from "../../intercom/IntercomProvider"
import panels from "./panels"

const CertificateAugmentationPanel: AugmentationLayoutPanel = React.forwardRef(
  function CertificateAugmentationPanel(props, ref) {
    const disclosure = useMergedDisclosure(props.disclosure)
    const { isOpen, onOpen } = disclosure

    const { certificate } = useCertificateContext()
    const {
      certificateStoredState: { discardCount }
    } = useCertificateState()

    // Controlling the tab index so a tab can be directly opened to
    // from opening the icon launcher.
    const [tabIndex, setTabIndex] = useState<number | undefined>(0)

    const handleOpenAtTab = (tabIndex: number) => {
      setTabIndex(tabIndex)
      onOpen()
    }

    useToggleIntercomVisibility(isOpen)

    const showSubmit = Boolean(certificate || discardCount)

    return (
      <>
        <Tabs
          variant="line"
          colorScheme={"gray"}
          index={tabIndex}
          onChange={(i) => setTabIndex(i)}
          isLazy
          lazyBehavior="keepMounted"
        >
          <AugmentationPanel
            {...props}
            ref={ref}
            // Allow closing the panel when offset matches the outer gutter.
            // Probably need a clearer way of handling this case.
            allowClose={
              !!props.offset?.right &&
              !!props.outerGutter &&
              props.offset.right <= props.outerGutter
            }
            disclosure={disclosure}
            header={
              <TabList
                paddingLeft={2}
                paddingRight={2}
                justifyContent="center"
                borderBottom="none"
              >
                {panels.map((panel) => (
                  <Tab
                    key={panel.key}
                    gap={1}
                    fontWeight="bold"
                    borderBottom="none"
                    opacity=".45"
                    _selected={{ color: "black", opacity: "1" }}
                  >
                    <panel.Icon size="1em" /> {panel.label}
                  </Tab>
                ))}
              </TabList>
            }
            body={
              <TabPanels>
                {panels.map((panel) => (
                  <TabPanel key={panel.key} padding={0}>
                    <panel.Panel />
                  </TabPanel>
                ))}
              </TabPanels>
            }
            footer={showSubmit && <CertificateSubmitButton />}
            toolbar={{
              hideWhenSelected: true,
              options: panels.map((panel, i) => ({
                key: panel.key,
                icon: panel.icon,
                selected: i === tabIndex,
                toggleSelected: () =>
                  i === tabIndex ? setTabIndex(undefined) : setTabIndex(i),
                ["aria-label"]: `Certificate ${panel.label.toLowerCase()} settings`
              }))
            }}
            offset={{
              ...props.offset,
              top: 50
            }}
          />
        </Tabs>
      </>
    )
  }
)

export default CertificateAugmentationPanel
