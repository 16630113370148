import { HStack } from "@chakra-ui/react"
import Button from "@pathwright/ui/src/components/button/Button"
import Dropdown from "@pathwright/ui/src/components/dropdown"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import {
  RESOURCE_TYPE_COLLECTION,
  RESOURCE_TYPE_COURSE
} from "../../resource/constants"
import { getResourceTypeLabel } from "../../resource/utils"

const ResourceTypeSwitcher = ({
  resourceType: resourceTypeProp,
  onSwitchResourceType,
  prompt: promptProp
}) => {
  const [resourceType, setResourceType] = useState(RESOURCE_TYPE_COURSE)
  const { t, tc } = useTranslate()
  const { school } = usePathwrightContext()

  // enforce platform version restriction, only allowing course resource type for versions less than 2.6
  if (school.platform_version < 2.6) resourceTypeProp = RESOURCE_TYPE_COURSE

  useEffect(() => {
    onSwitchResourceType(resourceType)
  }, [resourceType])

  useEffect(() => {
    if (resourceTypeProp) setResourceType(resourceTypeProp)
  }, [resourceTypeProp])

  const prompt = promptProp || t("Create a")

  const courseTooltip = (
    <Tooltip
      title={
        <>
          A Path is a series of steps for completing any kind of learning
          experience: a class, online course, event, skill-based outcome, and
          more.
          <br />
          <br />
          <Button
            styleType="text"
            href="http://www.pathwright.com/"
            target="_blank"
            inverted
          >
            {t("Learn more about kinds of Paths you can make.")}{" "}
            <Pathicon icon="external-link" />
          </Button>
        </>
      }
    >
      <Pathicon icon="info-circle" />
    </Tooltip>
  )

  const collectionTooltip = (
    <Tooltip
      title={
        <>
          A Collection is a "path of paths" through multiple courses.
          Collections are perfect for longer programs or programs where learners
          can choose the order they want to learn in.
          <br />
          <br />
          <Button
            styleType="text"
            href="https://help.pathwright.com/en/articles/2312339-collections-chart-progress-through-an-entire-program"
            target="_blank"
            inverted
          >
            {t("Learn more")} <Pathicon icon="external-link" />
          </Button>
        </>
      }
    >
      <Pathicon icon="info-circle" />
    </Tooltip>
  )

  const tooltip = {
    [RESOURCE_TYPE_COURSE]: courseTooltip,
    [RESOURCE_TYPE_COLLECTION]: collectionTooltip
  }[resourceType]

  const className = "ResourceTypeSwitcher"

  return resourceTypeProp ? (
    // if resourceTypeProp then don't allow selecting resource type, resource has been preselected
    <Container className={className} style={{ marginBottom: "0.25em" }}>
      <HStack>
        <span>{getResourceTypeLabel(resourceType, tc)}</span>
        <span>{tooltip}</span>
      </HStack>
    </Container>
  ) : (
    <Container className={className}>
      {prompt}
      <Dropdown.Trigger
        alignment="left"
        onClick={(e) => e.stopPropagation()}
        renderDropdown={() => (
          <Dropdown.Menu style={{ minWidth: "200px" }}>
            <Dropdown.Item
              label={getResourceTypeLabel(RESOURCE_TYPE_COURSE, tc)}
              onClick={() => setResourceType(RESOURCE_TYPE_COURSE)}
              meta={t(
                "A series of learning steps grouped in one or more lessons  (a single course, class, training, event, etc.)."
              )}
              closeOnClick
            />
            <Dropdown.Item
              label={getResourceTypeLabel(RESOURCE_TYPE_COLLECTION, tc)}
              onClick={() => setResourceType(RESOURCE_TYPE_COLLECTION)}
              meta={t(
                "A series of Paths that make up a larger program, conference, degree, etc."
              )}
              closeOnClick
            />
          </Dropdown.Menu>
        )}
      >
        <Button styleType="secondary">
          {getResourceTypeLabel(resourceType, tc)}
          <Pathicon icon="chevron-down" />
        </Button>
      </Dropdown.Trigger>
      {tooltip}
    </Container>
  )
}

ResourceTypeSwitcher.displayName = "ResourceTypeSwitcher"

ResourceTypeSwitcher.propTypes = {
  resourceType: PropTypes.oneOf([
    RESOURCE_TYPE_COLLECTION,
    RESOURCE_TYPE_COURSE
  ]),
  onSwitchResourceType: PropTypes.func.isRequired,
  prompt: PropTypes.string
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  ${(p) => (p.inverted ? "color: white;" : "")}

  > .Pathicon:first-child,
  > .Button .Button__content-wrapper .Pathicon:first-child {
    margin-right: 0.4em;
  }
  > .Dropdown__Trigger > .Button {
    margin-left: 0.4em;
    margin-right: 0.4em;
    // min-width: 200px;
  }

  .Tooltip__trigger {
    display: inline-flex !important;
    padding-top: 6px;
    padding-bottom: 6px;
  }
`

export default ResourceTypeSwitcher
