import { useMutation } from "@apollo/client"
import Button from "@pathwright/ui/src/components/button/Button"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import { useMemo } from "react"
import styled from "styled-components"
import CREATE_DISCUSSION_LIKE_MUTATION from "../../graphql/create-discussion-like-mutation"
import DELETE_DISCUSSION_LIKE_MUTATION from "../../graphql/delete-discussion-like-mutation"
import { useDiscussionPerms } from "../../permissions"
import { getDiscussionContext } from "./utils"

const StyledButton = styled(Button)`
  .Pathicon {
    margin-right: 1px !important;
  }
`

const DiscussionVote = ({ discussion }) => {
  const { t } = useTranslate()
  const discussionContext = getDiscussionContext(discussion)
  const discussionPerms = useDiscussionPerms(discussion)

  const [createDiscussionLike, createDiscussionLikeState] = useMutation(
    CREATE_DISCUSSION_LIKE_MUTATION,
    {
      variables: {
        context: discussionContext,
        discussion_id: discussion.id
      },
      optimisticResponse: {
        __typename: "Mutations",
        createDiscussionLike: {
          __typename: "Discussion",
          ...discussion,
          likes: discussion.likes + 1,
          user_liked: true
        }
      }
    }
  )

  const [deleteDiscussionLike, deleteDiscussionLikeState] = useMutation(
    DELETE_DISCUSSION_LIKE_MUTATION,
    {
      variables: {
        context: discussionContext,
        discussion_id: discussion.id
      },
      optimisticResponse: {
        __typename: "Mutations",
        deleteDiscussionLike: {
          __typename: "Discussion",
          ...discussion,
          likes: discussion.likes - 1,
          user_liked: false
        }
      }
    }
  )

  const isExecutingMutation =
    createDiscussionLikeState.loading || deleteDiscussionLikeState.loading

  const handleToggleVote = () => {
    // Prevent triggering subsequent mutation while awaiting result of executed mutation.
    if (!isExecutingMutation) {
      if (discussion.user_liked) {
        deleteDiscussionLike()
      } else if (discussionPerms.canLike) {
        createDiscussionLike()
      }
    }
  }

  const tooltip = useMemo(
    () =>
      discussion.user_liked
        ? discussion.likes > 1
          ? t("You and {{ count }} other liked", {
              defaultValue_plural: "You and {{ count }} others liked",
              count: discussion.user_liked
                ? discussion.likes - 1
                : discussion.likes
            })
          : t("You liked")
        : discussion.likes > 0
        ? t("{{ count }} like", {
            defaultValue_plural: "{{ count }} likes",
            count: discussion.likes
          })
        : t("Like"),
    [discussion.likes, discussion.user_liked]
  )

  return (
    <Tooltip title={tooltip}>
      <StyledButton
        styleType="tertiary"
        onClick={(e) => {
          e.stopPropagation()
          handleToggleVote()
        }}
        brand={discussion.user_liked}
        interactive={discussionPerms.canLike}
        style={{ padding: ".5em", borderRadius: "8px" }}
        icon={discussion.user_liked ? "heart-fill" : "heart"}
        label={discussion.likes || null}
        size="large"
      />
    </Tooltip>
  )
}

DiscussionVote.displayName = "DiscussionVote"

export default DiscussionVote
