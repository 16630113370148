import { EditIcon, TestTubeIcon } from "@pathwright/pathicons"
import DesignPanel from "./DesignPanel"
import VariablesPanel from "./VariablesPanel"

const panels = [
  {
    key: "design",
    label: "Design",
    icon: "edit",
    Icon: EditIcon,
    Panel: DesignPanel
  },
  {
    key: "variables",
    label: "Variables",
    icon: "test-tube",
    Icon: TestTubeIcon,
    Panel: VariablesPanel
  }
]

export default panels
