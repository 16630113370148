// @ts-ignore
import { useObserveSizeContext } from "@pathwright/ui/src/components/observers/ObserveSizeContext"
// @ts-ignore
import Portable from "@pathwright/ui/src/components/portable/Portable"
// @ts-ignore
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
// @ts-ignore
import { useScreensizeContext } from "@pathwright/ui/src/components/ui/Screensize"

import { AUGMENTATION_PANEL_QUERY_PARAM } from "@pathwright/web/src/modules/utils/urls"
import { History, Location } from "history"
import { useEffect, useMemo, useRef } from "react"
import styled from "styled-components"
import RouteCardStack from "../components/cards/RouteCardStack"
import Modal from "../components/modal/Modal"

const StyledScrollView = styled(ScrollView)`
  min-width: 480px;
  max-width: 480px;
  min-height: 100vh;
  padding: 10px;
  margin-left: auto;

  .CardStack {
    min-height: calc(100vh - 20px);
    /* Don't allow cards to be less width than container */
    max-width: unset !important;
  }

  /* Contain the card to the height of the screen minus padding set on the containing ScrollView. */
  .Card {
    max-height: calc(100vh - 20px);
    padding-bottom: 0;
  }

  /* Contain the card to the height of the screen minus padding set on the containing ScrollView. */
  .CardBody {
    height: calc(100vh - 20px) !important;
    overflow: auto;
  }

  /* Make nav sticky */
  .Card__Nav {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
  }

  /* Hack... */
  /* Since we're positioning the submit button using 'fixed', we need to hide
  the button while we wait for the card to animate in, since the button will 
  otherwise initially render in the pre-animated position and jump to the end position. */
  .Card.Card--animating:not(.Card--noaction)
    > .CardContainer
    .Button.Card__Action,
  .Card.Card--animating:not(.Card--noaction)
    > .CardContainer
    > button[type="submit"],
  .Card.Card--animating:not(.Card--noaction)
    > .CardContainer
    form
    .SubmitStatusButton,
  .Card.Card--animating:not(.Card--noaction)
    > .CardContainer
    form
    .FormSubmitStatus,
  .Card.Card--animating:not(.Card--noaction)
    > .CardContainer
    form
    .FormStateSubmitButton {
    display: none !important;
  }

  /* Hack...  */
  /* Since we are already hacking the position of the submit button within a Card,
  in order to position the button "sticky", we must use "fixed" positioning.
  Since the card should always be opening in the top right, we can afix the button relative
  to that part of the screen. */
  .Card:not(.Card--noaction) > .CardContainer .Button.Card__Action,
  .Card:not(.Card--noaction) > .CardContainer > button[type="submit"],
  .Card:not(.Card--noaction) > .CardContainer form .SubmitStatusButton,
  .Card:not(.Card--noaction) > .CardContainer form .FormSubmitStatus,
  .Card:not(.Card--noaction) > .CardContainer form .FormStateSubmitButton {
    position: fixed;
    right: 15px;
    top: 13px;
    z-index: 10;
  }
`

// TODO: implement animation similarly to Changelog?

type PortableCardStackProps = {
  // TODO: type this at the source.
  routeContext: {
    ModalComponent?: JSX.Element
    PrimaryComponent?: JSX.Element
    SecondaryComponent?: JSX.Element
    routeState?: {
      cards?: any[]
      path: string
      history: History
      location: Location & {
        query: Record<string, string>
      }
      modalLaunchedFrom: Location & {
        query: Record<string, string>
      }
    }
  }
}

type SetPorting = (porting: boolean) => {}

// Change whether we're portalling the cardstack in a modal based on screensize.
const PortableCardStack = ({ routeContext }: PortableCardStackProps) => {
  const setCardStackSizeRef = useObserveSizeContext().useSizeRef("cardstack")

  const currentCardStack = routeContext.routeState?.cards?.length ? (
    <RouteCardStack {...routeContext.routeState} />
  ) : null

  const currentCardStackRef = useRef<JSX.Element | null>(null)

  // The cardStack can become null during a route change.
  // Not quite sure what the root cause is but it can lead to a
  // flash where the card stack is momentarily unmounted, then remounted.
  // Keeping a cached card stack that is only updated when the existance of the
  // ?AUGMENTATION_PANEL_QUERY_PARAM query param changes allows us to fall back and avoid this flash.
  const cachedCardStack = useMemo(() => {
    // If we have a currentCardStack, then update our ref.
    if (currentCardStack) {
      currentCardStackRef.current = currentCardStack
      return currentCardStack
      // If we no longer have a card query param, clear out the ref.
    } else if (
      !routeContext.routeState?.location.query[AUGMENTATION_PANEL_QUERY_PARAM]
    ) {
      currentCardStackRef.current = null
    }

    // Always returning the currently stored value of currentCardStack.
    return currentCardStackRef.current
  }, [
    currentCardStack,
    routeContext.routeState?.location.query[AUGMENTATION_PANEL_QUERY_PARAM]
  ])

  // Fallback to cached card stack if available.
  const cardStack = currentCardStack || cachedCardStack

  // For now, forcing modal for all card routes, save on /home/unified/
  const forceModal =
    !routeContext.routeState?.location?.pathname?.startsWith("/home/unified")

  const portableCardStack = cardStack ? (
    <Portable renderPortal={cardStack}>
      {({
        porting,
        setPorting,
        outPortal
      }: {
        porting: boolean
        setPorting: SetPorting
        outPortal: JSX.Element
      }) => {
        const screensize = useScreensizeContext()

        // Only portaling to the side at larger screen sizes.
        useEffect(() => {
          setPorting(screensize === "xl")
        }, [screensize])

        return cardStack ? (
          porting && !forceModal ? (
            <StyledScrollView
              className="Panel--cardstack"
              ref={setCardStackSizeRef}
            >
              {outPortal}
            </StyledScrollView>
          ) : (
            <Modal
              className="Modal--cardstack"
              key={routeContext.routeState?.cards?.[0].key}
            >
              {outPortal}
            </Modal>
          )
        ) : null
      }}
    </Portable>
  ) : null

  return portableCardStack
}

export default PortableCardStack
