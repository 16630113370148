import {
  OrderByDir,
  PositioningContextTypeInput,
  UnifiedHomeRegistrationsQueryVariables
} from "../api/generated"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

// This is really just a stop-gap to share vars with other queries
const useUnifiedHomeRegistrationVars =
  (): UnifiedHomeRegistrationsQueryVariables => {
    const { me } = usePathwrightContext()

    return {
      first: 1042,
      positioningContext: {
        contextType: PositioningContextTypeInput.HomeScreen,
        contextKey: `user:${me.id}`
      },
      orderBy: [{ position: OrderByDir.Asc }, { created: OrderByDir.Asc }],
      softArchived: { eq: null }
    }
  }

export default useUnifiedHomeRegistrationVars
