import gql from "graphql-tag"
import { SubscriptionFragmentDoc } from "../../api/generated"

export default gql`
  query SubscriptionQuery($id: Int!) {
    subscription(id: $id) {
      ...Subscription
      payment_intent_client_secret
      stripe_publishable_api_key
    }
  }
  ${SubscriptionFragmentDoc}
`
