import gql from "graphql-tag"
import { DiscussionPostFragmentDoc } from "../../api/generated"

export default gql`
  mutation UpdateDiscussion(
    $id: Int!
    $context: DiscussionContextInput!
    $post: DiscussionPostInput!
  ) {
    updateDiscussion(id: $id, context: $context, post: $post) {
      ...DiscussionPost
    }
  }
  ${DiscussionPostFragmentDoc}
`
