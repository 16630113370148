import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import {
  FormatOptionLabelMeta,
  GroupBase,
  MultiValue,
  Props,
  Select,
  SingleValue,
  useChakraSelectProps
} from "chakra-react-select"
import { GroupAvatars } from "../../unified-home/components/GroupAvatars"
import { PathCreatorStateNavProps } from "./PathCreatorStateNav"
import RoleBadge, { getRoleOptions } from "./RoleBadge"
import { getSelectCustomComponents } from "./components/select"
import { SelectedCohort, SelectedResource } from "./state"

// react-select doesn't export this type.
interface FilterOptionOption<Option> {
  readonly label: string
  readonly value: string
  readonly data: Option
}

type Option = SelectedCohort | SelectedResource

type ItemsProps<T extends Option> = {
  options?: T[]
  value?: T | null
  onChange: (option?: T | null) => void
}

type ItemSelectProps<T extends Option> = Omit<
  Partial<SelectProps<T>>,
  "onChange"
>

export type PathCreatorNavItemSelectorProps = Omit<
  PathCreatorStateNavProps,
  "stateKey"
>

export type ItemSelectorProps<T extends Option> = {
  isLoading: boolean
} & ItemsProps<T> &
  ItemSelectProps<T>

// Note: Typescript is not able to infer types for chakra-react-select some reason.
// Have to explicitly type the generics: https://react-select.com/typescript
type SelectProps<T extends Option> = Props<T, boolean, GroupBase<T>>

function useCustomChakraSelectProps<T extends Option>(): SelectProps<T> {
  return useChakraSelectProps({
    chakraStyles: {
      container: (provided, state) => ({
        ...provided,
        w: "100%"
      }),
      option: (provided, state) => ({
        ...provided,
        color: "gray.900",
        w: "initial",
        bg:
          state.isFocused && state.isSelected
            ? "gray.300"
            : state.isFocused
            ? "gray.200"
            : state.isSelected
            ? "gray.100"
            : "transparent"
      }),
      menu: (provided, state) => ({
        ...provided,
        bg: "white",
        borderRadius: "xl",
        // Hide overflowing bg of focused/selected items at start/end of menu.
        overflow: "hidden"
      }),
      menuList: (provided, state) => ({
        ...provided,
        padding: 0,
        border: "none",
        bg: "transparent"
      }),
      placeholder: (provided) => ({
        ...provided,
        paddingLeft: "2px",
        fontSize: { base: "sm", md: undefined },
        noOfLines: 1
      }),
      control: (provided, state) => ({
        ...provided,
        padding: "0 .4em",
        border: "1px solid",
        borderColor: "gray.100",
        color: "gray.900",
        borderRadius: "xl"
      }),
      clearIndicator: (provided) => ({
        ...provided,
        m: 0,
        mr: 1
      }),
      valueContainer: (provided) => ({
        ...provided,
        pl: 0
      })
    }
  })
}

function ItemSelector<T extends SelectedResource>(
  props: ItemSelectorProps<T>
): JSX.Element
function ItemSelector<T extends SelectedCohort>(
  props: ItemSelectorProps<T>
): JSX.Element
function ItemSelector<T extends Option>({
  options,
  value,
  onChange,
  isLoading,
  ...selectProps
}: ItemSelectorProps<T>) {
  function handleChange(selected: SingleValue<T> | MultiValue<T>) {
    // We know we're only dealing with single value selection (not enabling multi-select).
    onChange((selected || null) as SingleValue<T> | null)
  }

  function formatOptionLabel(option: T, meta: FormatOptionLabelMeta<T>) {
    return meta.context === "menu" ? (
      // Create new option.
      option.id === -1 ? (
        <HStack>
          <Pathicon icon="plus" />
          <Text as="span">{option.name}</Text>
        </HStack>
      ) : (
        <VStack spacing={0} w="100%">
          <HStack w="100%" justifyContent="flex-start">
            {"image" in option ? (
              <>
                <Image
                  src={option.image}
                  maxW="50px"
                  borderRadius="md"
                  alignSelf="flex-start"
                  fallback={
                    <Box w="50px" h="37.5px" bg="gray.200" borderRadius="md" />
                  }
                />
                <VStack alignItems="flex-start">
                  <Text as="span">{option.name}</Text>
                  {!!option.role && (
                    <RoleBadge
                      badges={
                        getRoleOptions({ roles: [option.role] })[0].badges
                      }
                    />
                  )}
                </VStack>
              </>
            ) : (
              <HStack w="100%">
                <GroupAvatars
                  group={option}
                  showGroupName
                  fontColor="blackAlpha.900"
                  noOfLines={1}
                />
                {!!option.role && (
                  <HStack alignSelf="flex-end">
                    <RoleBadge
                      badges={
                        getRoleOptions({ roles: [option.role] })[0].badges
                      }
                    />
                  </HStack>
                )}
              </HStack>
            )}
          </HStack>
        </VStack>
      )
    ) : "image" in option ? (
      <HStack>
        <Image
          src={option.image}
          maxH="24px"
          borderRadius="md"
          fallback={<Box maxH="24px" bg="gray.200" borderRadius="md" />}
        />
        <Text as="span">{option.name}</Text>
      </HStack>
    ) : (
      // Wrapping in LightMode to reverse Avatar border color.
      <GroupAvatars group={option} showGroupName />
    )
  }

  // Perform filtering by search value.
  // TODO: custom handle search? Maybe try "match-sorter" for searching in state.
  // Though, "match-sorter" may be overkill if all we're searching is the item's name.
  function filterOption(option: FilterOptionOption<T>, inputValue: string) {
    return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  }

  const chakraSelectProps = useCustomChakraSelectProps<T>()
  const selectComponents = getSelectCustomComponents<T, boolean>()

  return (
    <Select
      options={options}
      value={value}
      // Required for telling react-select how to determine which option is selected.
      getOptionValue={(option) => option.id + ""}
      onChange={handleChange}
      formatOptionLabel={formatOptionLabel}
      filterOption={filterOption}
      // TODO: probably need item-specific placeholder?
      placeholder="Select..."
      variant="unstyled"
      isClearable
      escapeClearsValue
      hideSelectedOptions={false}
      isSearchable={true}
      autoFocus={!value}
      openMenuOnFocus={!value}
      closeMenuOnSelect={true}
      backspaceRemovesValue={true}
      isLoading={isLoading}
      loadingMessage={() => null}
      {...{
        ...chakraSelectProps,
        components: {
          ...chakraSelectProps,
          ...selectComponents
        }
      }}
      {...selectProps}
    />
  )
}

export default ItemSelector
