// @ts-ignore
import { Redirect, Route, matchPath } from "react-router-dom"

import {
  AUGMENTATION_PANEL_QUERY_PARAM,
  getCardPath,
  getRoute
} from "@pathwright/web/src/modules/utils/urls"
import pathToRegexp, { Key } from "path-to-regexp"
import { historyEntries } from "./router"

// Takes a route path and a pathname and generates match tokens from
// the route path that can be used to identify the name of matched portions
// of the pathname. This is not great, but seems to be the best approach
// when using path-to-regexp v1, which is the same version used by react-router-dom
// under the hood, and more recent versions don't support the same route
// path patterns (for example, the "?" optional flag).
export function parseMatchParamsFromRoutePath(
  routePath: string,
  pathname: string
) {
  const re = pathToRegexp(routePath)
  const pathRe = new RegExp(re.source, re.flags + "g")
  // Filter out the all string tokens, leaving only the Keys which
  // are those variable portions of the path.
  const tokens = pathToRegexp
    .parse(routePath)
    .filter((token) => typeof token === "object") as Key[]

  let match: RegExpMatchArray | null

  // Store up all the params that are found.
  let params: Record<string, string> = {}

  // When tokens exist, execute the path regexp until there are no more matches
  // and append each token name + value to the params.
  if (tokens.length) {
    while ((match = pathRe.exec(pathname)) !== null) {
      const values = match.slice(1)
      // Reuce the values as token names on the params.
      params = values.reduce((params, value) => {
        const key = tokens.shift()!.name
        params[key] = value
        return params
      }, params)
    }
  }

  return params
}

// Handles matching *all* routes and checking if the matched route is a card route.
// When that is the case, and no ?AUGMENTATION_PANEL_QUERY_PARAM query param exists in the current location,
// then we redirect to the previous route and append the ?AUGMENTATION_PANEL_QUERY_PARAM query param.
// This allows us to maintain two route states, 1) the pathname mapping to the primary
// component and 2) the ?AUGMENTATION_PANEL_QUERY_PARAM query param mapping to the card stack.
export function CardMatchRedirect({ routes }: any) {
  return (
    <Route
      path="*"
      render={(props: any) => {
        // Get the card path from the ?AUGMENTATION_PANEL_QUERY_PARAM query param, if it exists.
        const cardPath = getCardPath(props.location)
        // Attempt to find a card route that matches the card path.
        const cardRouteMatch = cardPath
          ? routes.find((route: any) => matchPath(cardPath, route.props))
          : null
        // Attempt to find a card route that matches the location pathname.
        // We can use the existance of a card route at the current location.pathname
        // to determine if we should redirect to the ?AUGMENTATION_PANEL_QUERY_PARAM.
        const routeMatch = routes.find((route: any) =>
          matchPath(props.location.pathname, route.props)
        )

        // Assemble the redirect route for redirecting to the ?AUGMENTATION_PANEL_QUERY_PARAM route.
        const prevLocation = historyEntries.slice(-2)?.[0]?.location
        // In the event that we do not have a prev location, we'll want to fallback
        // to teh default launched from route, if possible. This is in the event that
        // we hit a card route directly when first loading the app.
        const defaultLaunchedFrom =
          routeMatch?.props.getDefaultLaunchedFrom?.(props)

        // Attempt to get the root pathname to redirect to.
        const rootPathname =
          prevLocation && prevLocation.pathname !== props.location.pathname
            ? prevLocation.pathname
            : defaultLaunchedFrom

        // We can only redirect when the currently match route is a card route.
        let redirectTo: string | object | null =
          routeMatch?.props.isCardRoute && rootPathname
            ? `${rootPathname}?${AUGMENTATION_PANEL_QUERY_PARAM}=${props.location.pathname}`
            : null

        // Let's preserve any current search params when redirecting.
        if (redirectTo) {
          redirectTo = getRoute(redirectTo, "", props.location.search)
        }

        // Attempt to capture the match props from the route render method
        // to then pass down into the cardRouteMatch render method?
        return cardRouteMatch ? (
          {
            ...cardRouteMatch,
            props: {
              ...cardRouteMatch.props,
              // We could potentially match props.location.pathname but
              // matching "all" might be more resiliant.
              // At this point we know that the route matches this card
              // (at least per the ?AUGMENTATION_PANEL_QUERY_PARAM query param), and we do this
              // since we can't match routes against query params.
              path: "*"
            }
          }
        ) : redirectTo ? (
          <Redirect to={redirectTo} />
        ) : null
      }}
    />
  )
}
