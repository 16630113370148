import { CreateCohortCard } from "@pathwright/web/src/modules/cohort/create/CreateCohort"
import TeachGate from "@pathwright/web/src/modules/home/teach/TeachGate"
import { getHomeActivePath } from "@pathwright/web/src/modules/home/useHomeActiveEntries"
import { belltowerHomeRedirect } from "lib/core/routing/middleware"
import { getDefaultLaunchedFromHome } from "../lib/core/routing/utils"
import Homescreen from "../unified-home/Homescreen"
import HomeOrLibraryBrowse from "./components/HomeOrLibraryBrowse"

const { createCardRoutes } = require("lib/core/routing/card-routing")

export const createCohortCardRoute = createCardRoutes(
  "/home/teach/",
  {
    create: {
      component: CreateCohortCard,
      getDefaultLaunchedFrom: getDefaultLaunchedFromHome
    }
  },
  require("./components/HomeOrLibraryBrowse").default
)

export default {
  //Testing unified home route
  unifiedRoute: {
    path: "/home/unified/",
    component: Homescreen
  },
  homeRoute: {
    path: "/home/:section?/:subSection?/",
    onEnter: (nextState, replace, ...rest) => {
      // If no section is selected yet, select the user's active section.
      if (!nextState.params.section) {
        const homeActivePath = getHomeActivePath(nextState.pwContext)
        // Avoid infinite loop by first checking if we're already at the active home path.
        if (nextState.location.pathname.indexOf(homeActivePath) === -1)
          return replace(homeActivePath)
      }

      return belltowerHomeRedirect(nextState, replace, ...rest)
    },
    component: (props) => (
      <HomeOrLibraryBrowse
        {...props}
        section={props.match.params.section}
        subSection={props.match.params.subSection}
        nodeKey={props.location.query.id}
      />
    )
  },
  activateRoute: {
    path: "/home/teach/activate/",
    getDefaultLaunchedFrom: () => "/home/teach/",
    components: {
      modal: TeachGate,
      primary: require("./components/HomeOrLibraryBrowse").default
    }
  }
}
