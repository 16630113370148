// TODO: generalize for use outside of the HistoryFlow

import { useEffect, useReducer } from "react"
import isEqual from "react-fast-compare"
import { exhaustiveGuard } from "../../lib/typing"

export type ItemEquivalence<T> = (itemA: T, itemB: T) => boolean
export type SourceSync<T> = (item: T) => void
export type HistorySync<T> = (updatedItem: T, items: T[]) => T[]

export type UseHistoryReducerProps<T extends any> = {
  item: T
  sourceSync: SourceSync<T>
  succeedingHistorySync: HistorySync<T>
  itemEquivalence?: ItemEquivalence<T>
}

export type HistoryState<T = any> = {
  // History stack of all the items.
  items: T[]
  // The index of the current item.
  index: number
  // Unfortunate dependency necessary for determining if two
  // items are equivalent, which may not require exact equality.
  itemEquivalence: ItemEquivalence<T>
}

type HistoryAction<T = any> =
  | {
      type: "push"
      item: T
    }
  | {
      type: "pop"
    }
  | {
      type: "replace"
      item: T
    }
  | {
      type: "go"
      index: number
    }
  | {
      type: "goBack"
    }
  | {
      type: "goForward"
    }
  | {
      type: "revise"
      item: T
      reviser: HistorySync<T>
    }
  | {
      type: "reviseForward"
      index: number
      item: T
      reviser: HistorySync<T>
    }
  | {
      type: "reset"
    }

export type HistoryDispatch<T> = ReturnType<typeof useHistoryReducer<T>>[1]

export type HistoryProps<T = any> = {
  historyState: HistoryState<T>
  historyDispatch: HistoryDispatch<T>
}

// Check if history item exists before the current index.
export function canGoBack(historyState: HistoryState) {
  return historyState.index > 0
}

// Check if history item exists after the current index.
export function canGoForward(historyState: HistoryState) {
  return historyState.index < historyState.items.length - 1
}

// The default ite equivalence comparator, exact equality.
const isEquivalent: ItemEquivalence<any> = (itemA, itemB) => {
  return isEqual(itemA, itemB)
}

// Preferably choose history item over another item when they are equal
// preventing issues of referential inequality.
function chooseItem<T = any>(historyItem: T, item: T) {
  return isEqual(historyItem, item) ? historyItem : item
}

function reducer<T>(
  state: HistoryState<T>,
  action: HistoryAction<T>
): HistoryState<T> {
  const { type } = action

  switch (type) {
    case "push":
      // We must determine what action to take based on itemEquivalence of the pushed item
      // with the item at the current index AND at the next index.

      // If the pushed item is equivalent to the item at the current index then we merely
      // replace the item at the current index with the pushed item, leaving the rest of
      // the history items in tact, as well as the current index.

      // Else, if the pushed item is equivalent to the item at the next index, we merely
      // replace that item at that index with the pushed item, leaving the rest of the
      // history items in tact, but updating the current index +1.

      // Checking the first case, pushed item itemEquivalence with item at current index.
      if (
        state.items[state.index] &&
        state.itemEquivalence(state.items[state.index], action.item)
        // isEquivalent(action.item, currentItem)
      ) {
        // Should we return current state or bump the current index?
        return reducer(
          {
            ...state,
            index: state.index
          },
          {
            type: "replace",
            item: action.item
          }
        )
      } else if (
        state.items[state.index + 1] &&
        state.itemEquivalence(state.items[state.index + 1], action.item)
      ) {
        // Checking the second case, pushed item itemEquivalence with item at current index.
        return reducer(
          {
            ...state,
            index: state.index + 1
          },
          {
            type: "replace",
            item: action.item
          }
        )
      } else {
        // Pushing item to front of history, replacing any succeeding items.
        return {
          ...state,
          items: [...state.items.slice(0, state.index + 1), action.item],
          index: state.items.length ? state.index + 1 : 0
        }
      }
    case "pop":
      // Popping off the the item at the current index and all items that follow.
      return {
        ...state,
        items: state.items.slice(0, state.index),
        index: Math.max(state.index - 1, 0)
      }
    case "replace":
      // Replacing the item at the current index.
      // If we find that the item at the current index is not equivalent
      // to the new item, then we also remove all the items that follow.
      if (
        state.items[state.index] &&
        state.itemEquivalence(state.items[state.index], action.item)
        // isEquivalent(action.item, currentItem)
      ) {
        return {
          ...state,
          items: [
            ...state.items.slice(0, state.index),
            action.item,
            ...state.items.slice(state.index + 1)
          ]
        }
      } else {
        return {
          ...state,
          items: [...state.items.slice(0, state.index), action.item]
        }
      }
    case "go":
      if (action.index !== state.index) {
        // Merely changes the current index.
        return {
          ...state,
          index: Math.min(Math.max(action.index, 0), state.items.length - 1)
        }
      } else {
        return state
      }
    case "goBack":
      // Reduce the index by 1.
      return reducer(state, {
        type: "go",
        index: state.index - 1
      })
    case "goForward":
      // Increase the index by 1.
      return reducer(state, {
        type: "go",
        index: state.index + 1
      })
    case "revise": {
      // Allow the source to revise any succeeding items in history
      // based on the current item.
      const nextItems = [
        // Retain all items up to current index.
        ...state.items.slice(0, state.index),
        // Revise all succeeding items using the provided reviser fn.
        ...action
          .reviser(action.item, state.items.slice(state.index))
          .map((item, i) => {
            // So as not to run into an infinite loop where an equivalent item is
            // treated as new due to referential inequality, let's compare exact
            // equality, preferring the history item when equal.
            const stateItem = state.items[state.index + i]
            return isEqual(stateItem, item) ? stateItem : item
          })
      ]

      return {
        ...state,
        items: nextItems
      }
    }
    case "reviseForward": {
      // Like "revise", but revising starting at an index in history
      // likely different from the current index. Once revised, going
      // to most recent history item.
      const nextState = reducer(
        {
          ...state,
          // Revise starting from this index.
          index: action.index
        },
        {
          type: "revise",
          item: action.item,
          reviser: action.reviser
        }
      )

      // Go to most recent history item.
      return reducer(nextState, {
        type: "go",
        index: Infinity
      })
    }
    case "reset":
      // Reset to initial state.
      return {
        ...state,
        items: [state.items[0]],
        index: 0
      }
    default:
      exhaustiveGuard(type)
  }
}

// Tracks the state history based on an item prop.
// When the item changes, the item is synced to history.
// When the history changes, the current history item is synced to the source.
// This allows for overriding the source item by navigating history state.
// An itemEquivalence prop is used to compare items to determine if the are
// equivalent, defaulting to using react-fast-compare for exact equality.
export function useHistoryReducer<T>({
  item,
  sourceSync,
  succeedingHistorySync,
  itemEquivalence
}: UseHistoryReducerProps<T>) {
  const value = useReducer(reducer<T>, {
    items: [],
    index: 0,
    itemEquivalence: itemEquivalence || isEquivalent
  })

  // Destructuring separately to accurately preserve return value typing.
  const [historyState, historyDispatch] = value
  // Current history item.
  const historyItem = historyState.items[historyState.index]

  // As the item changes, we push the item.
  useEffect(() => {
    if (item !== historyItem) {
      historyDispatch({
        type: "push",
        item
      })
    }
  }, [item])

  // As the history item changes, we sync with source.
  useEffect(() => {
    if (typeof historyItem !== "undefined") {
      if (!isEqual(historyItem, item)) {
        sourceSync(historyItem)
      }

      // NOTE: always revising history after a historyItem change may be naive.
      // This can result in removing succeeding history items when the user is simiply
      // navigating backwards in history (depending on )

      // Sync the update with any succeeding history items.
      // This ensures that if an equivalent item replaced history state,
      // then any succeeding items will be updated if necessary.
      historyDispatch({
        type: "revise",
        item: historyItem,
        reviser: succeedingHistorySync
      })
    }
  }, [historyItem])

  return value
}
