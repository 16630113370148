import gql from "graphql-tag"
import { SubscriptionFragmentDoc, UserFragmentDoc } from "../../api/generated"
import MENTOR_GROUP_FRAGMENT from "../../mentor-group/graphql/mentor-group-fragment"
import STRIPE_PRICE_FRAGMENT from "../../stripe/graphql/stripe-price-fragment"
import STRIPE_PRODUCT_FRAGMENT from "../../stripe/graphql/stripe-product-fragment"

export default gql`
  fragment SchoolGroupSubscription on SchoolGroupSubscription {
    id
    price {
      ...StripePrice
      product {
        ...StripeProduct
      }
    }
    user {
      ...User
    }
    subscription {
      ...Subscription
    }
    community_group {
      ...MentorGroup
    }
  }
  ${UserFragmentDoc}
  ${SubscriptionFragmentDoc}
  ${STRIPE_PRICE_FRAGMENT}
  ${STRIPE_PRODUCT_FRAGMENT}
  ${MENTOR_GROUP_FRAGMENT}
`
