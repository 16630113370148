import gql from "graphql-tag"
import { SubscriptionFragmentDoc } from "../../api/generated"

export default gql`
  query SubscriptionsQuery($paymentIntentNeedingActionIdFilter: IDFilter) {
    subscriptions(
      first: 10
      payment_intent_needing_action_id: $paymentIntentNeedingActionIdFilter
    ) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...Subscription
        }
      }
    }
  }
  ${SubscriptionFragmentDoc}
`
