// import { useObserveSize } from "@pathwright/ui/src/components/observers/ObserveSizeContext"
import { Box } from "@chakra-ui/react"
import { useWindowSize } from "react-use"
import AugmentationLayout from "../../components/augmentation/AugmentationLayout"
import { useCertificateContext } from "../context/CertificateContext"
import CertificateState, {
  useCertificateState
} from "../context/CertificateState"
import CertificateAugmentationPanel from "../panel/CertificateAugmentation"
import CertificateDrawer, {
  drawerWidths,
  useDrawerSize
} from "../panel/CertificateDrawer"
import MultiSelect from "../text/MultiSelect"
import CertificateEditor from "./CertificateEditor"

const innerGutter = 10
const outerGutter = 10

// Display the drawer as if "inline" adjacent to editor.
const CertificateInlineLayout = ({ editorOffset, drawerOffset }) => (
  <>
    <CertificateEditor offset={editorOffset} />
    <CertificateDrawer offset={drawerOffset} />
  </>
)

// Display the drawer as a typical drawer, docked to edge of screen.
const CertificateDockedLayout = ({ editorWidth }) => {
  const containerWidth =
    editorWidth + outerGutter * 2 + 48 /* button width */ * 2

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      maxW={`calc(100vw - ${outerGutter * 2}px)`}
      w={`${containerWidth}px`}
      mx={`${outerGutter}px`}
    >
      <CertificateEditor />
      <Box position="absolute" top="0" right="0">
        <CertificateDrawer />
      </Box>
    </Box>
  )
}

const CertificateLayout = () => {
  const { templateDimensions } = useCertificateState()
  const drawerSize = useDrawerSize()

  const { width: screenWidth } = useWindowSize()
  const editorWidth = templateDimensions?.width
  const drawerWidth = drawerWidths[drawerSize]

  // Calculate the width of the sapce available for "inserting" the drawer inline.
  // We can use the "inline" layout when inline width is not negative.
  const inlineWidth =
    screenWidth - editorWidth - innerGutter - drawerWidth - outerGutter * 2

  // Since the drawer is positioned absolutely in a portal, we will reposition it
  // and the editor so that the drawer "feels" inline.
  // Offset the editor to the left to make room for the inner gutter and the drawer.
  const editorOffset = drawerWidth + innerGutter
  // Offset the drawer to the left to put it visually next to the editor, with an inner gutter gap.
  const drawerOffset =
    (screenWidth - (editorWidth + innerGutter + drawerWidth)) / 2

  // If we cannot inline the drawer, than we handle the floating drawer layout which depends on
  // a icon panel to launch the drawer, which needs to be positioned absolutely adjacent to the editor.
  return inlineWidth >= 0 ? (
    <CertificateInlineLayout
      editorOffset={editorOffset}
      drawerOffset={drawerOffset}
    />
  ) : (
    <CertificateDockedLayout editorWidth={editorWidth} />
  )
}

const CertificateManager = () => {
  const { certificateScope } = useCertificateContext()

  return (
    <CertificateState>
      {!certificateScope && <MultiSelect />}
      {/* <CertificateLayout /> */}
      <AugmentationLayout
        primary={<CertificateEditor />}
        Augmentation={CertificateAugmentationPanel}
        allowOverlay={false}
        centerPrimary={false}
        disclosureOptions={{ defaultIsOpen: true }}
      />
    </CertificateState>
  )
}

CertificateManager.displayName = "CertificateManager"

export default CertificateManager
