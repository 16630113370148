import gql from "graphql-tag"
import {
  TagAttachmentFragmentDoc,
  TagFragmentDoc,
  TagLinkFragmentDoc
} from "../api/generated"

export const TAG_FRAGMENT = TagFragmentDoc

export const TAG_ATTACHMENT_FRAGMENT = TagAttachmentFragmentDoc

export const TAG_LINK_FRAGMENT = TagLinkFragmentDoc

export const TAGS_QUERY = gql`
  query Tags(
    $context: TagAttachmentContextInput
    $withContextualTagAttachment: Boolean!
  ) {
    tags(first: 1042) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...Tag
          tagAttachments(first: 1, context: $context) {
            total
            # For position data. NOTE: assumes only 1 attachment exists
            # for the provided context, which currently is true, but may change
            # in the future if more FKs are added to TagAttachments.
            edges @include(if: $withContextualTagAttachment) {
              node {
                ...TagAttachment
              }
            }
          }
        }
      }
    }
  }
  ${TAG_FRAGMENT}
  ${TAG_ATTACHMENT_FRAGMENT}
`

export const TAG_ATTACHMENTS_QUERY = gql`
  query TagAttachments($context: TagAttachmentContextInput) {
    tagAttachments(first: 1042, context: $context) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...TagAttachment
          tag {
            ...Tag
          }
        }
      }
    }
  }
  ${TAG_ATTACHMENT_FRAGMENT}
`

export const CREATE_TAG_MUTATION = gql`
  mutation CreateTag($tag: TagCreateInput!) {
    createTag(tag: $tag) {
      id
      name
      description
      color
    }
  }
`

export const UPDATE_TAG_MUTATION = gql`
  mutation UpdateTag($tag: TagUpdateInput!) {
    updateTag(tag: $tag) {
      id
      name
      description
      color
    }
  }
`

export const DELETE_TAG_MUTATION = gql`
  mutation DeleteTag($tag: TagDeleteInput!) {
    deleteTag(tag: $tag)
  }
`

export const CREATE_TAG_ATTACHMENT_MUTATION = gql`
  mutation CreateTagAttachment(
    $tagId: Int!
    $context: TagAttachmentContextInput!
    $position: String
  ) {
    createTagAttachment(
      tag_id: $tagId
      context: $context
      position: $position
    ) {
      ...TagAttachment
    }
  }
  ${TAG_ATTACHMENT_FRAGMENT}
`

export const UPDATE_TAG_ATTACHMENT_MUTATION = gql`
  mutation UpdateTagAttachment(
    $tagId: Int!
    $context: TagAttachmentContextInput!
    $position: String
  ) {
    updateTagAttachment(
      tag_id: $tagId
      context: $context
      position: $position
    ) {
      ...TagAttachment
    }
  }
  ${TAG_ATTACHMENT_FRAGMENT}
`

export const DELETE_TAG_ATTACHMENT_MUTATION = gql`
  mutation DeleteTagAttachment(
    $tagId: Int!
    $context: TagAttachmentContextInput!
  ) {
    deleteTagAttachment(tag_id: $tagId, context: $context)
  }
`

export const CREATE_TAG_LINK_MUTATION = gql`
  mutation CreateTagLink($tagId: Int!, $context: TagLinkContextInput!) {
    createTagLink(tag_id: $tagId, context: $context) {
      ...TagLink
      tag {
        ...Tag
      }
    }
  }
  ${TAG_LINK_FRAGMENT}
  ${TAG_FRAGMENT}
`

export const DELETE_TAG_LINK_MUTATION = gql`
  mutation DeleteTagLink($tagId: Int!, $context: TagLinkContextInput!) {
    deleteTagLink(tag_id: $tagId, context: $context)
  }
`
