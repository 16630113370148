import AvatarStack from "@pathwright/ui/src/components/avatar/AvatarStack"
import useIntersection from "@pathwright/ui/src/components/hooks/useIntersection"
import { useScrollContext } from "@pathwright/ui/src/components/scroll/ScrollView"
import { PRIMARY_GRAY } from "@pathwright/ui/src/components/utils/colors"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import { useMemo } from "react"
import styled from "styled-components"
import { OrderByDir } from "../api/generated"
import { flattenEdges } from "../utils/apollo"

const CohortMembersContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;

  .AvatarStack + .AvatarStack {
    margin-left: 10px;
  }

  .Avatar {
    backface-visibility: visible;
    /* border: 1px solid rgba(0, 0, 0, 0.33) !important; */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  }
`

const Count = styled.div`
  height: 26px;
  display: flex;
  align-items: center;
  color: ${(p) => (p.inverted ? "white" : PRIMARY_GRAY)};
  font-size: 10px;
  margin-left: 4px;
`

const COHORT_MEMBERS_QUERY = gql`
  query CohortMembers($id: Int!, $orderBy: [RegistrationOrderByInput]) {
    group(id: $id) {
      id
      registrations(
        first: 5
        orderBy: $orderBy
        role_filter: { in: [teacher, moderator, student] }
      ) {
        total
        edges {
          node {
            id
            role
            user {
              id
              first_name
              last_name
              profile {
                id
                image
              }
            }
          }
        }
      }
    }
  }
`

export const useCohortMembers = ({ cohortId, orderBy, ...rest }) => {
  const { data, loading } = useQuery(COHORT_MEMBERS_QUERY, {
    variables: {
      id: cohortId,
      orderBy: orderBy || [
        { role: OrderByDir.Desc },
        { created: OrderByDir.Asc }
      ]
    },
    ...rest
  })

  const { count, userData } = useMemo(() => {
    let count = 0
    let userData = { students: [], staff: [] }

    if (!loading && data && data.group) {
      const registrations = data.group.registrations
        ? flattenEdges(data.group.registrations)
        : []
      count = data.group.registrations.total - 5
      userData = registrations.reduce((grouped, reg) => {
        if (reg.role > 10) {
          grouped.staff.push(reg.user)
        } else {
          grouped.students.push(reg.user)
        }

        return grouped
      }, userData)
    }

    return {
      count,
      userData
    }
  }, [loading, data])

  return {
    count,
    userData
  }
}

const CohortMembers = ({ cohortId, style, orderBy, inverted, ...props }) => {
  const scrollContext = useScrollContext()
  // check if CohortMembersContainer node has entered the containing ScrollView/document before initiating the query
  const [setIntersectionNode, _, hasIntersected] = useIntersection(
    {
      root: scrollContext ? scrollContext.scrollNode : null
    },
    // NOTE: the delay only applies if the element does not immediately intersect the root node
    300 /* delay */
  )

  const { count, userData } = useCohortMembers({
    cohortId,
    orderBy,
    skip: !hasIntersected
  })

  return (
    <CohortMembersContainer
      className="CohortMembers"
      ref={setIntersectionNode}
      style={style}
    >
      {/* {loading ? <LoadingCircle /> : null} */}
      {!!userData.staff.length && (
        <AvatarStack users={userData.staff} reverse {...props} />
      )}
      {!!userData.students.length && (
        <>
          <AvatarStack users={userData.students} reverse {...props} />
          {count > 0 ? <Count inverted={inverted}>+{count}</Count> : null}
        </>
      )}
    </CohortMembersContainer>
  )
}

CohortMembers.defaultProps = {
  size: 30,
  itemOffset: 4
}

export default CohortMembers
