import { useFragment } from "@apollo/client"
import classnames from "classnames"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import {
  DiscussionPostFragmentDoc,
  useDiscussionPostQuery
} from "../../../api/generated"
import { flattenEdges } from "../../../utils/apollo"
import DiscussionForm from "../../form/DiscussionForm"
import { discussionContextPropType } from "../../propTypes"
import DiscussionListItem from "./DiscussionListItem"
import DiscussionListItemLoading from "./DiscussionListItemLoading"

// Get the cached discussion post when possible rather than querying.
function useCachedDiscussionPost(discussionId) {
  try {
    const discussionFragment = useFragment({
      fragment: DiscussionPostFragmentDoc,
      fragmentName: "DiscussionPost",
      from: {
        __typename: "Discussion",
        id: discussionId
      }
    })

    // Note: we could use the partial fragment but we'd have to be careful.
    // This is where TS would be helpful.
    return discussionFragment?.complete ? discussionFragment?.data : null
  } catch {
    return null
  }
}

const controllableModes = ["edit", "view"]

const DiscussionItemContainer = ({
  className: classNameProp,
  discussionId,
  isDiscussionQuestion,
  context,
  compact,
  showReplyPrompt,
  mode: modeProp,
  onChangeMode,
  onCreateDiscussion,
  onDeleteDiscussion
}) => {
  const cachedDiscussion = useCachedDiscussionPost(discussionId)

  const discussionQuery = useDiscussionPostQuery({
    variables: {
      id: discussionId,
      context
    },
    // Skipping when cached discussion is available.
    skip: !discussionId || !!cachedDiscussion
  })

  // Preferring cached discussion if possible.
  const discussion = flattenEdges(
    discussionQuery?.data?.discussion || cachedDiscussion
  )

  const [mode, setMode] = useState()

  useEffect(() => {
    // Alert upstream that mode has changed.
    if (mode && mode !== modeProp && onChangeMode) {
      onChangeMode(mode)
    }
  }, [mode])

  useEffect(() => {
    // Alert upstream that mode has changed.
    if (mode && mode !== modeProp && controllableModes.includes(modeProp)) {
      setMode(modeProp)
    }
  }, [modeProp])

  useEffect(() => {
    if (!discussionId) {
      // Creating new discussion.
      setMode("create")
    } else if (!discussion) {
      // Loading existing discussion.
      setMode("load")
    } else {
      // Either viewing or editing loaded discussion.
      // If current mode is not a conrollable mode and
      if (controllableModes.includes(modeProp)) {
        // Parent can control whether component enters "view" or "edit" mode at this point.
        setMode(modeProp)
      } else {
        // By default enter "view" mode where "edit" mode must be explicitly set.
        setMode("view")
      }
    }
  }, [discussionId, !!discussion])

  const className = classnames("DiscussionItemContainer", classNameProp, {
    [`DiscussionItemContainer__${mode}`]: !!mode
  })

  switch (mode) {
    case "load":
      return (
        <div className={className}>
          <DiscussionListItemLoading />
        </div>
      )
    case "create":
      return (
        <div className={className}>
          <DiscussionForm
            context={context}
            isDiscussionQuestion={isDiscussionQuestion}
            onSubmit={onCreateDiscussion}
            inverted={false}
            expanded
          />
        </div>
      )
    case "edit":
      return (
        <div className={className}>
          <DiscussionForm
            context={context}
            discussion={discussion}
            onCancel={() => setMode("view")}
            onSubmit={() => setMode("view")}
            inverted={false}
            expanded
          />
        </div>
      )
    case "view":
      return (
        <div className={className}>
          <DiscussionListItem
            discussion={discussion}
            onEditDiscussion={() => setMode("edit")}
            onDeleteDiscussion={onDeleteDiscussion}
            compact={compact}
            showReplyPrompt={showReplyPrompt}
          />
        </div>
      )
    default:
      return null
  }
}

DiscussionItemContainer.displayName = "DiscussionItemContainer"

DiscussionItemContainer.propTypes = {
  discussionId: PropTypes.number,
  context: discussionContextPropType.isRequired,
  onCreateDiscussion: PropTypes.func,
  onDeleteDiscussion: PropTypes.func,
  compact: PropTypes.bool,
  showReplyPrompt: PropTypes.bool,
  mode: PropTypes.oneOf(controllableModes)
}

DiscussionItemContainer.defaultProps = {
  compact: true,
  showReplyPrompt: false
}

export default DiscussionItemContainer
