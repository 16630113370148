import { Spinner, Text, VStack } from "@chakra-ui/react"
import { motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import {
  UnifiedHomeRegistrationFragmentDoc,
  useUnifiedHomeRegistrationsQuery
} from "../../api/generated"
import {
  PositioningContextProvider,
  sortPositionedObjects
} from "../../positioning/PositioningContext"
import FloatingTip from "../components/FloatingTip"
import PathCard from "../components/pathCard/PathCard"
import useUnifiedHomeRegistrationVars from "../useUnifiedHomeRegistrationQueryVars"

const RegistrationsContainer = () => {
  const [delayedLoading, setDelayedLoading] = useState(true)

  const variables = useUnifiedHomeRegistrationVars()
  const { data, loading } = useUnifiedHomeRegistrationsQuery({
    variables
  })

  const registrations = data?.me?.registrations
    ? sortPositionedObjects(
        // @ts-expect-error: expected fields are possibly undefined.
        data?.me?.registrations?.edges?.map((edge) => edge?.node!) || []
      )
    : []

  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!loading) {
      setDelayedLoading(false)
    } else {
      setDelayedLoading(true)
    }
  }, [loading])

  if (loading || delayedLoading) {
    return (
      <VStack align={"center"} spacing={1} w="full" p={2}>
        <Spinner
          thickness="3px"
          speed="0.65s"
          emptyColor="whiteAlpha.200"
          color="whiteAlpha.900"
          size="lg"
        />
        <Text fontWeight={"light"} color="whiteAlpha.900" fontSize={"sm"} m={0}>
          Loading registrations
        </Text>
      </VStack>
    )
  }

  const containerVariants = {
    initial: { opacity: 0.8 },
    animate: {
      opacity: 1,
      transition: {
        delayChildren: 0.05,
        staggerChildren: 0.08
      }
    }
  }

  const staggerVariants = {
    initial: { opacity: 0, y: 160 },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 800,
        damping: 60,
        mass: 1
      }
    }
  }

  return (
    <VStack w="100%" h="100%" pos={"relative"}>
      <FloatingTip />
      <PositioningContextProvider
        items={registrations}
        itemKey="registration"
        itemFragmentDocument={UnifiedHomeRegistrationFragmentDoc}
        context={variables.positioningContext}
      >
        <VStack
          as={motion.div}
          ref={ref}
          variants={containerVariants}
          initial="initial"
          animate="animate"
          spacing={3}
          position={"relative"}
          w="full"
          h="full"
          borderRadius={25}
          align={"flex-start"}
          justify={"flex-start"}
          // minH="800px"
        >
          {registrations?.map((registration) => (
            <motion.div
              key={registration.id}
              style={{ width: "100%", overflow: "visible" }}
              variants={staggerVariants}
            >
              <PathCard
                // @ts-expect-error: TODO: fix typing (currently registrations are typed as PositionedObject[])
                registration={registration}
              />
            </motion.div>
          ))}
        </VStack>
      </PositioningContextProvider>
    </VStack>
  )
}

export default RegistrationsContainer
