export const loadCategories = function ({ match }) {
  window.App.getStore("categoryAdmin").action.loadCategories()
  // aka onLeave childCard route
  if (!match.params.categoryId) {
    return window.App.getStore("categoryAdmin").action.unselectCategory()
  }
}

export const selectCategory = function ({ match }) {
  let { categoryId } = match.params
  categoryId = categoryId !== "new" ? categoryId : null
  return window.App.getStore("categoryAdmin").action.selectCategory(categoryId)
}
