import {
  PathCreatorResourceFragment,
  usePathCreatorResourcesQuery
} from "../../api/generated"
import ItemSelector from "./ItemSelector"
import { registrationRolesMap } from "./RoleBadge"
import { SelectedResource } from "./state"

// Resources licensed from other schools don't have a source cohort,
// at least, not one that is linked to the licensee school.
// The PathCreatorFlow currently doesn't have a method for handling
// these resources yet.
export type SelectedResourceWithSourceCohort = PathCreatorResourceFragment & {
  source_cohort: NonNullable<PathCreatorResourceFragment["source_cohort"]>
}

// Maps the PathCreatorResourceFragment to the SelectedResource type.
export function massageResource(
  resource: SelectedResourceWithSourceCohort
): SelectedResource {
  return {
    id: resource.id,
    name: resource.name,
    image: resource.image,
    background_image: resource.background_image,
    sourceCohort: {
      id: resource.source_cohort.id,
      role: (registrationRolesMap.get(
        resource.source_cohort?.registration?.role!
      ) || null) as SelectedResource["role"]
    }
  }
}

type ResourceSelectorProps = {
  value: SelectedResource | null
  onChange: (item?: SelectedResource | null) => void
}

function ResourceSelector({ value, onChange }: ResourceSelectorProps) {
  const query = usePathCreatorResourcesQuery()
  const resources = query.data?.resources?.edges
    ?.map((edge) => edge?.node!)
    .reduce((acc, node) => {
      // Narrow the list down to only those resources that have a soure cohort.
      if (node.source_cohort) {
        acc.push(massageResource(node as SelectedResourceWithSourceCohort))
      }
      return acc
    }, [] as SelectedResource[])

  return (
    <ItemSelector
      options={resources}
      value={value?.id ? value : null}
      onChange={onChange}
      isLoading={query.loading}
    />
  )
}

export default ResourceSelector
