import classnames from "classnames"
import PropTypes from "prop-types"
import React, { useCallback, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { VelocityTransitionGroup } from "velocity-react"
import usePreviousEffect from "../hooks/usePreviousEffect"
import { media } from "../utils/styles"
import CardStackCard from "./CardStackCard"
import "./card-transitions"
import { cardSizeStyle } from "./ui"

const StyledContainer = styled.div`
  &.CardStack {
    position: relative;
    margin: 0 auto;
    background-color: transparent !important;
    transition: max-width 0.1s ease;
    ${cardSizeStyle}
  }

  &.CardStack .Card {
    /* Ensure CardStack is controlling the Card max-widths. */
    max-width: unset;
  }

  &.CardStack .CardStack__cards > span,
  &.CardStack .CardStack__cards > div {
    display: flex;
  }

  ${media.max.phone`
    &.CardStack,
    &.CardStack .CardStack__cards,
    &.CardStack .CardStack__cards .Card,
    .CardStack__cards > div {
      min-height: 100vh;
    }
  `}
`

export const CardStackContext = React.createContext()

export const useCardStackContext = () => useContext(CardStackContext)

const CardStack = ({
  card,
  cards,
  onCloseCard,
  onCloseCardStack,
  className,
  ...passProps
}) => {
  const [cardStack, setCardStack] = useState(card ? [card] : cards ? cards : [])
  // Handles sizing the entire cardstack to a custom width.
  const [cardStackSize, setCardStackSize] = useState("medium")
  // is top card transitioning in?
  const [animating, setAnimating] = useState(false)
  // cards with unsaved changes (stored by card index)
  const [dirtyCards, setDirtyCards] = useState([])

  const handleAnimating = (animating) => {
    setAnimating(animating)
  }

  const pushCard = useCallback(
    (card) => {
      if (animating) return
      if (card.component) {
        setCardStack([...cardStack, card])
      } else {
        // allows for swapping out props for same card component if card does not have component key
        setCardStack([
          ...cardStack.slice(0, -1),
          {
            ...cardStack[cardStack.length - 1],
            ...card
          }
        ])
      }
    },
    [cardStack, animating]
  )

  const popCard = useCallback(() => {
    if (animating) return
    setCardStack([...cardStack.slice(0, cardStack.length - 1)])
  }, [cardStack, animating])

  // update cardStack if new cards are passed
  // if card is passed, expect it never to update
  useEffect(() => {
    if (cards) setCardStack(cards)
  }, [cards])

  usePreviousEffect(
    ([prevCardStack]) => {
      if (cardStack.length > prevCardStack.length) handleAnimating(true)
      // if last card has been popped, call onCloseCardStack
      if (cardStack.length < prevCardStack.length && !cardStack.length) {
        onCloseCardStack && onCloseCardStack()
      }
    },
    [cardStack]
  )

  const handleCardDirty = useCallback(
    (isDirty, index) => {
      if (isDirty) {
        return setDirtyCards(dirtyCards.concat(index))
      } else if (dirtyCards.indexOf(index) > -1) {
        return setDirtyCards(dirtyCards.filter((i) => i !== index))
      }
    },
    [dirtyCards]
  )

  const handleCloseCard = useCallback(
    (card, index) => {
      // potentially prevent popping the card if onCloseCard returns false
      if (onCloseCard && onCloseCard(card, index) === false) return
      popCard()
      handleCardDirty(false, index)
    },
    [cardStack, popCard, onCloseCard]
  )

  const onCardTransitionComplete = useCallback((nodes) => {
    handleAnimating(false)
    Array.from(nodes).forEach((node) => {
      if (node != null) {
        node.style.transform = ""
        node.style.WebkitTransform = ""
        node.style.transformOrigin = ""
      }
    })
  }, [])

  return (
    <CardStackContext.Provider
      value={{
        pushCard: card ? pushCard : null,
        popCard: card ? popCard : null,
        forcePushCard: pushCard,
        forcePopCard: popCard,
        setCardStackSize
      }}
    >
      {!!cardStack.length && (
        <StyledContainer
          className={classnames("CardStack", className)}
          size={cardStackSize}
        >
          <div className="CardStack__cards">
            <VelocityTransitionGroup
              enter={{
                animation: "transition.cardIn",
                complete: onCardTransitionComplete
              }}
              leave={{ animation: "transition.cardOut" }}
              runOnMount
            >
              {cardStack.map((card, index) => (
                <CardStackCard
                  {...passProps}
                  key={index}
                  index={index}
                  card={card}
                  isActive={index === cardStack.length - 1}
                  isAnimating={animating}
                  // pass pushCard as a way of allowing a card to push another
                  // card on top of it without requiring context (allows for mixing route and inline cards)
                  pushCard={pushCard}
                  onClose={handleCloseCard}
                  isDirty={dirtyCards.indexOf(index) > -1}
                  prevIsDirty={dirtyCards.filter((i) => i < index).length > 0}
                  onCardDirty={handleCardDirty}
                />
              ))}
            </VelocityTransitionGroup>
          </div>
        </StyledContainer>
      )}
    </CardStackContext.Provider>
  )
}

CardStack.displayName = "CardStack"

const cardType = PropTypes.shape({
  component: PropTypes.func
})

CardStack.propTypes = {
  card: cardType,
  cards: PropTypes.arrayOf(cardType)
}

export default CardStack
