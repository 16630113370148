import Button from "@pathwright/ui/src/components/button/Button"
import Link from "@pathwright/ui/src/components/link/Link"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import { media } from "@pathwright/ui/src/components/utils/styles"
import { getStepUrl } from "@pathwright/web/src/modules/utils/urls"
import get from "lodash/get"
import { Component } from "react"
import styled from "styled-components"
import { useGroupQuery } from "../../api/generated"
import { withCompletionsListContainer } from "../list/CompletionsListContainer"

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

const HeaderRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${media.max.phone`
    width: 200px;
  `};
`

const StyledButton = styled(Button)`
  ${(props) => `visibility: ${props.visible ? "visible" : "hidden"};`}
`

const StyledArrowPathicon = styled(Pathicon)`
  font-weight: 700 !important;
`

const StyledH4 = styled(Text.H4)`
  font-weight: 500;
  font-size: 1.1em;
  width: 300px;
  line-height: 1.4em;
`

const StyledStepLink = styled(Link)`
  display: inline-block;
  text-overflow: ellipsis;
  overflow-x: hidden;
  padding: 1px 0;
  text-decoration: underline;
  white-space: nowrap;
`

export const StepURL = ({ pathItem, children }) => {
  const groupQuery = useGroupQuery({
    variables: {
      id: pathItem.context.cohort_id
    }
  })

  const group = get(groupQuery, "data.group")

  if (!group) return null

  const stepURL = getStepUrl(group.resource.slug, group.id, pathItem.source_id)

  return children({
    pathItem,
    stepURL
  })
}

class CompletionFeedbackPanelHeader extends Component {
  get currentItemIndex() {
    if (this.props.pathItems) {
      for (let i = 0; i < this.props.pathItems.length; i++) {
        const item = this.props.pathItems[i]
        if (item.id === this.props.selectedId) {
          return {
            currentItem: item,
            currentIndex: i
          }
        }
      }
    }

    return {}
  }

  get prevItem() {
    return this.getItemRelativeToCurrentItem(-1)
  }

  get nextItem() {
    return this.getItemRelativeToCurrentItem(+1)
  }

  get nextAction() {
    return !this.nextItem && this.props.hasMore
      ? () => this.props.loadMore()
      : null
  }

  getItemRelativeToCurrentItem(relativeIndex) {
    const { currentItem, currentIndex } = this.currentItemIndex
    return currentItem
      ? this.props.pathItems[currentIndex + relativeIndex]
      : null
  }

  render() {
    const { currentItem, currentIndex } = this.currentItemIndex

    if (!currentItem) return null

    return (
      <StyledHeader>
        <HeaderRow>
          <StyledButton
            visible={!!this.prevItem}
            styleType="blank"
            onClick={() => this.props.onSelect(this.prevItem)}
            style={{ padding: "0.4em 0.5em" }}
          >
            <StyledArrowPathicon icon="chevron-left" />
            {/* Prev */}
          </StyledButton>
          <HeaderInner>
            <StyledH4>
              <StepURL pathItem={currentItem}>
                {({ stepURL }) => (
                  <StyledStepLink target="_blank" to={stepURL}>
                    {currentItem.name}
                  </StyledStepLink>
                )}
              </StepURL>
            </StyledH4>
            {this.props.total > 1 && (
              <Text.H5
                style={{
                  fontWeight: "400",
                  color: "rgba(0,0,0,0.4)",
                  fontSize: "0.9em",
                  lineHeight: "1em"
                }}
              >
                {this.props.t("Submission {{ index }}/{{ total }}", {
                  index: currentIndex + 1,
                  total: this.props.total
                })}
              </Text.H5>
            )}
          </HeaderInner>
          <StyledButton
            visible={!!(this.nextItem || this.nextAction)}
            styleType="blank"
            style={{ padding: "0.4em 0.5em" }}
            onClick={() => {
              // Either load next page and / or select next item.
              this.nextAction
                ? this.nextAction().then(() =>
                    this.props.onSelect(this.nextItem)
                  )
                : this.props.onSelect(this.nextItem)
            }}
          >
            {/* Next */}
            <StyledArrowPathicon icon="chevron-right" />
          </StyledButton>
        </HeaderRow>
      </StyledHeader>
    )
  }
}

CompletionFeedbackPanelHeader.displayName = "CompletionFeedbackPanelHeader"

export default withCompletionsListContainer(
  withTranslate(CompletionFeedbackPanelHeader)
)
