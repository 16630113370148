import OptionsButton from "@pathwright/ui/src/components/button/OptionsButton"
import Dropdown from "@pathwright/ui/src/components/dropdown"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import Link from "@pathwright/ui/src/components/link/Link"
import gql from "graphql-tag"
import get from "lodash/get"
import path from "path-browserify"
import React from "react"
import styled from "styled-components"
import { ResourceFragmentDoc } from "../../api/generated"
import { setStoredPathMode } from "../../path/utils"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { RESOURCE_TYPE_COLLECTION } from "../../resource/constants"
import ResourceCopyControl from "../../resource/manage/ResourceCopyControl"
import {
  getGroupUrl,
  getManageResourceUrl,
  getStoreResourceUrl
} from "../../utils/urls"

export const USER_RESOURCES_QUERY = gql`
  query UserResources($cursor: String) {
    resources(first: 1042, after: $cursor) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...Resource
          source_cohort: curriculum_group {
            id
          }
        }
      }
    }
  }
  ${ResourceFragmentDoc}
`

USER_RESOURCES_QUERY._pathToPageInfo = "resources"

export const USER_EDITOR_RESOURCES_QUERY = gql`
  query UserResources($cursor: String) {
    me {
      id
      registrations(
        first: 1042
        after: $cursor
        role_filter: { in: [editor] }
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            resource {
              id
            }
          }
        }
      }
    }
  }
`

USER_EDITOR_RESOURCES_QUERY._pathToPageInfo = "me.registrations"

const FullWidthDropdownItem = styled(DropdownItem)`
  .Button__content-wrapper {
    width: 100%;
    display: block;
  }
`

const ResourceThumbControls = ({ resource, canCopyResource }) => {
  const shouldRenderCopyControl =
    canCopyResource &&
    resource.source_cohort &&
    // For now, we don't support copying collections
    resource.resource_type !== RESOURCE_TYPE_COLLECTION

  return (
    <Dropdown.Trigger
      className="ResourceThumbControls"
      style={{ position: "absolute", top: 10, right: 10, zIndex: 10000 }}
      onClick={(e) => e.stopPropagation()}
      renderDropdown={() => (
        <DropdownMenu>
          {shouldRenderCopyControl ? (
            <FullWidthDropdownItem>
              <ResourceCopyControl resource={resource} />
            </FullWidthDropdownItem>
          ) : null}
          <DropdownItem
            icon="gear"
            to={getManageResourceUrl(resource.id)}
            label="Edit Path settings"
            closeOnClick
          />
          <DropdownItem
            icon="group"
            to={path.join(getManageResourceUrl(resource.id), "/cohorts/")}
            label="Manage cohorts"
            closeOnClick
          />
        </DropdownMenu>
      )}
    >
      <OptionsButton inverted />
    </Dropdown.Trigger>
  )
}

const THUMB_WIDTH = 180
const THUMB_BORDER_RADIUS = 16

const ResourceThumbContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  color: #000;
  text-decoration: none;
  overflow: hidden;
  border-radius: ${THUMB_BORDER_RADIUS}px;

  img {
    min-height: 60px;
    max-width: 100%; //${THUMB_WIDTH}px;
  }

  img + div {
    padding: 10px;
    background-color: #fff;
    padding: 10px;
    color: #000;
    flex-grow: 1;
  }
`

const Title = styled.h1`
  line-height: 1.2em;
  font-size: 18px !important;
  color: #000;
  max-width: calc(100% - 10px);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin: 0px;
`

const ResourceThumbWrapper = styled.div`
  min-width: 220px;
  max-width: 400px;
  position: relative;
  border-radius: ${THUMB_BORDER_RADIUS}px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  transition: all 0.3s;
  transform: scale(1);
  will-change: transform;

  &:hover {
    transform: scale(1.02);

    .ResourceThumbControls {
      opacity: 1;
    }
  }
`

// TODO: something better for this
const DEFAULT_THUMB_IMAGE =
  "https://pathwright.imgix.net/https%3A%2F%2Fpathwright-static-media.s3.amazonaws.com%2Fdefault-image-set-v1%2Fcover-image-47.png?w=500&h=375&fit=crop&width=500&height=375&ixlib=js-1.4.1&s=74ebbe206c101ff49c5ddfe4ae0506a3"

export const ResourceThumb = React.forwardRef(({ item: resource }, ref) => {
  const url = resource.source_cohort
    ? getGroupUrl(resource.slug, resource.source_cohort.id)
    : // Not ideal, but we have no better URL to link to
      getStoreResourceUrl(resource.slug)
  const image = resource.image ? resource.image : DEFAULT_THUMB_IMAGE
  const { me } = usePathwrightContext()

  const canCopyResource =
    get(me, "permissions.can_administrate") ||
    get(me, "permissions.can_edit_library")

  return (
    <ResourceThumbWrapper ref={ref}>
      <ResourceThumbContainer
        href={url}
        to={url}
        onClick={() => setStoredPathMode(15)}
      >
        <img src={image} alt={`${resource.name} thumbnail`} />
        <div>
          <Title
            title={resource.name}
            className="theme-heading theme-heading-bold"
          >
            {resource.name}
          </Title>
        </div>
      </ResourceThumbContainer>
      {/* <div className="thumb-overlay"></div> */}
      <ResourceThumbControls
        resource={resource}
        canCopyResource={canCopyResource}
      />
    </ResourceThumbWrapper>
  )
})

const ResourceGridContainer = styled.div`
  width: 100%;
  padding: 0px 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 1rem;
`

export const ResourceGrid = (props) => {
  return <ResourceGridContainer {...props} />
}
