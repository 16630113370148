//! Mutations based on modules/home/teach/item/GroupNotificationsForm.js
import { useMutation } from "@apollo/client"
import {
  Box,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Switch,
  Text,
  VStack
} from "@chakra-ui/react"
import { Field, Form, Formik } from "formik"
import { UnifiedHomeRegistrationFragment } from "modules/api/generated"
import React, { useEffect, useState } from "react"
import { useBoolean } from "react-use"
import Pathicon from "../../pathicon/Pathicon"
import UPDATE_REGISTRATION_MUTATION from "../../registration/graphql/update-registration-mutation"

type ItemWrapperProps = {
  children: React.ReactNode
  onClick?: () => void
}

const FormItemWrapper = ({ children, onClick }: ItemWrapperProps) => {
  return (
    <FormControl
      as={HStack}
      align="center"
      justify={"space-between"}
      w={"100%"}
      p={2}
      pl={5}
      _hover={{ textDecoration: "none", bg: "blackAlpha.200" }}
      cursor={"pointer"}
      onClick={onClick}
    >
      {children}
    </FormControl>
  )
}

const GroupNotificationsFormOption = ({
  registration
}: {
  registration: UnifiedHomeRegistrationFragment
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false) // Expand/collapse switch options
  const [isShown, setIsShown] = useBoolean(false) // Shows/hides manin switch and expand/collapse button
  const [isEnabled, setIsEnabled] = useBoolean(false) // Enables/disables the main switch

  let discussion = registration.send_discussion_notifications
  let dates = registration.send_due_notifications

  const [mutation] = useMutation(UPDATE_REGISTRATION_MUTATION)

  // Updates "notifications" based on "discussion" and "dates" status
  useEffect(() => {
    if (!discussion && dates) {
      setIsExpanded(true)
      setIsShown(false)
    }
    if (discussion && !dates) {
      setIsExpanded(true)
      setIsShown(false)
    }
    if (!discussion && !dates) {
      setIsShown(true)
      setIsEnabled(false)
      // setIsExpanded(false)
    }
    if (discussion && dates) {
      // setIsExpanded(true)
      setIsShown(true)
      setIsEnabled(true)
    }
  }, [discussion, dates])

  return (
    <>
      <HStack
        align="center"
        p={2}
        justify={"space-between"}
        w="100%"
        pointerEvents={"auto"}
        minW={200}
        onBlur={() => setIsExpanded(false)}
      >
        <Flex align={"center"} gap={2}>
          <Pathicon icon="bell-fill" />
          <Text fontSize="xs" my={0} fontWeight="normal">
            Get Notifications
          </Text>
        </Flex>
        {isShown && (
          <Flex align={"center"} h={18}>
            <Switch
              opacity={!isExpanded ? 1 : 0}
              id="notifications"
              isChecked={isEnabled}
              isReadOnly
            />
            <Pathicon
              icon={isExpanded ? "chevron-down" : "chevron-right"}
              onClick={() => setIsExpanded((prev) => !prev)}
              cursor={"pointer"}
            />
          </Flex>
        )}
      </HStack>
      <Box w="100%">
        <Collapse in={isExpanded} animateOpacity>
          <Formik
            initialValues={{
              discussion: registration.send_discussion_notifications,
              dates: registration.send_due_notifications
            }}
            enableReinitialize
            onSubmit={(values) => {
              const changes = {
                id: registration.id,
                send_discussion_notifications: !!values.discussion,
                send_due_notifications: !!values.dates
              }

              return mutation({
                variables: changes,
                optimisticResponse: {
                  updateRegistration: {
                    ...changes,
                    __typename: "Registration"
                  }
                }
              })
            }}
          >
            {({ values, setFieldValue, submitForm }) => (
              <Form>
                <VStack align="start" gap={0} w={"100%"} bg="blackAlpha.100">
                  <Field name="discussion">
                    {({ field }: { field: any }) => (
                      <FormItemWrapper>
                        <FormLabel htmlFor="discussion">
                          <HStack align="center">
                            <Pathicon icon="chat-bubbles-fill" />
                            <Text fontSize="xs" my={0} fontWeight="normal">
                              Discussions
                            </Text>
                          </HStack>
                        </FormLabel>
                        <Switch
                          id="discussion"
                          isChecked={field.value as boolean}
                          onChange={async (e) => {
                            await setFieldValue("discussion", e.target.checked)
                            await submitForm()
                          }}
                        />
                      </FormItemWrapper>
                    )}
                  </Field>
                  <Field name="dates">
                    {({ field }: { field: any }) => (
                      <FormItemWrapper>
                        <FormLabel htmlFor="dates">
                          <HStack align="center">
                            <Pathicon icon="calendar" />
                            <Text fontSize="xs" my={0} fontWeight="normal">
                              Date reminders
                            </Text>
                          </HStack>
                        </FormLabel>
                        <Switch
                          id="dates"
                          isChecked={field.value as boolean}
                          onChange={async (e) => {
                            await setFieldValue("dates", e.target.checked)
                            await submitForm()
                          }}
                        />
                      </FormItemWrapper>
                    )}
                  </Field>
                </VStack>
              </Form>
            )}
          </Formik>
        </Collapse>
      </Box>
    </>
  )
}

export default GroupNotificationsFormOption
