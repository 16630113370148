// Note: this file is required by the Node.js GraphQL server so any type
// referenced here must be buildable by the server enviroment!
import { BlockLayoutType, ServerBlockTypeType } from "../types"

import AnnotatedAssessment from "./annotatedassessment/block-type"
import Attachments from "./attachments/block-type"
import Audio from "./audio/block-type"
import Cards from "./cards/block-type"
import Divider from "./divider/block-type"
import Embed from "./embed/block-type"
import Flashcards from "./flashcards/block-type"
import Gallery from "./gallery/block-type"
import Image from "./image/block-type"
import Markdown from "./markdown/block-type"
import Meeting from "./meeting/block-type"
import Question from "./question/block-type"
import Quote from "./quote/block-type"
import Submit from "./submit/block-type"
import Tasklist from "./tasklist/block-type"
import Text from "./text/block-type"
import Title from "./title/block-type"
import Video from "./video/block-type"

export const blocks = [
  Cards,
  Embed,
  Gallery,
  Image,
  Markdown,
  Quote,
  Text,
  Title,
  Question,
  Divider,
  Flashcards,
  Video,
  Audio,
  Attachments,
  Submit,
  AnnotatedAssessment,
  Tasklist,
  Meeting
]

export type LocalBlockTypeType = {
  type: string
  helpLink?: string
  menu?: {
    category: string
    order: number
  }
  layouts: Record<string, BlockLayoutType>
  schema: string
  icon?: string
  resolveUserCompletion?: (
    context: any,
    data: Record<string, any>,
    layout: string
  ) => Promise<{ progress: number | null; score: number | null }>
}

export const getBlockType = (type: string): LocalBlockTypeType | undefined => {
  return blocks.find((bt) => bt.type.toLowerCase() === type?.toLowerCase())
}

export const getBlockTypeKey = (type: string): string | undefined => {
  // return the proper key for a block
  return getBlockType(type)?.type
}

export const getServerBlockType = (
  type: string,
  serverBlockTypes: ServerBlockTypeType[]
): ServerBlockTypeType | undefined => {
  return serverBlockTypes.find(
    (bt) => bt.type.toLowerCase() === type.toLowerCase()
  )
}
