import UnlocksOverlay from "@pathwright/ui/src/components/overlay/UnlocksOverlay"
import useCohortPermissions from "@pathwright/web/src/modules/cohort/useCohortPermissions"
import get from "lodash/get"
import { usePathItemQuery } from "../api/generated"
import { getPathItemNonPassiveFutureStartsDate } from "../path/utils"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

/**
 *
 * @param { cohortId } Int used for querying user's cohort permissions
 * @param { pathItemSourceId } Int used for querying user's path item
 * @param { parentPathItemSourceId } Int used for querying parent path item of user's path item
 * @returns UnlocksOverlay if discussion is locked for user, otherwise null
 *
 * A cohort teacher+ has full access to discussion.
 *
 * A cohort moderator has full access to discussion provided
 * the path item is not password locked.
 *
 * A cohort learner- has full access to discussion provided
 * the path item is not password locked and neither the path item nor
 * the parent path item has a future non-passive starts date.
 */

const DiscussionUnlocksOverlay = ({
  cohortId,
  pathItemSourceId,
  parentPathItemSourceId: parentPathItemSourceIdProp
}: {
  cohortId: number
  pathItemSourceId: number
  parentPathItemSourceId?: number
}) => {
  const { me } = usePathwrightContext()
  const cohortPerms = useCohortPermissions({ cohortId })
  const pathItemQuery = usePathItemQuery({
    variables: {
      source_id: pathItemSourceId,
      user_id: me?.id
    },
    skip: !me || !pathItemSourceId || !!cohortPerms.hasTeacherLevelAccess
  })

  const pathItem = pathItemQuery?.data?.pathItem
  // get the parentPathItemSourceId either from the pathItem or props
  const parentPathItemSourceId =
    pathItem?.parent_source_id || parentPathItemSourceIdProp

  const parentPathItemQuery = usePathItemQuery({
    variables: {
      source_id: parentPathItemSourceId,
      user_id: me?.id
    },
    // only quering if there is a parentPathItemSourceId
    skip: !me || !parentPathItemSourceId || !!cohortPerms.hasTeacherLevelAccess
  })

  const parentPathItem = parentPathItemQuery?.data?.pathItem

  if (
    !pathItem ||
    pathItem?.permissions?.can_discuss ||
    cohortPerms.hasTeacherLevelAccess
  )
    return null

  // NOTE: beyond this point parentPathItem may be undefined
  const starts = getPathItemNonPassiveFutureStartsDate(pathItem, parentPathItem)
  const startLocked = !!starts && !cohortPerms.hasModeratorLevelAccess
  const passwordLocked = pathItem.is_locked || get(parentPathItem, "is_locked")

  if (!passwordLocked && !startLocked) return null

  return (
    <UnlocksOverlay starts={starts} passwordLocked={passwordLocked} overlay />
  )
}

DiscussionUnlocksOverlay.displayName = "DiscussionUnlocksOverlay"

export default DiscussionUnlocksOverlay
