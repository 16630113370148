import { useMutation } from "@apollo/client"
import { Box, VStack } from "@chakra-ui/react"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Success from "@pathwright/ui/src/components/success/Success"
import PathwrightUI from "@pathwright/ui/src/components/ui/PathwrightUI"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import get from "lodash/get"
import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { CohortSwitcherCohortsDocument } from "../../api/generated"
import CREATE_GROUP_MUTATION from "../../group/graphql/create-group-mutation"
import CREATE_RESOURCE_MUTATION from "../../home/teach/graphql/create-resource-mutation"
import { ROLES, ROLE_TYPE_OFFERING } from "../../invitation/constants"
import { useCohortSyncPlanQuery } from "../../path/sync/SyncPlanContext"
import {
  RESOURCE_TYPE_COURSE,
  RESOURCE_TYPE_LABELS
} from "../../resource/constants"
import { getResourceTypeLabel } from "../../resource/utils"
import { getGroupUrl } from "../../utils/urls"
import CohortNameInput from "./CohortNameInput"
import ResourcePicker from "./ResourcePicker"
import ResourceTypeSwitcher from "./ResourceTypeSwitcher"
import UpgradeCohortsGate from "./UpgradeCohortsGate"

const RESOURCE_QUERY = gql`
  query ResourceQuery($id: Int!) {
    resource(id: $id) {
      id
      name
      resource_type
      image(width: 100, height: 75, fit: crop)
      background_overlay
      background_image
      source_cohort: curriculum_group {
        id
        path(type: source) {
          id
          last_synced_dtime
        }
      }
      cohorts: groups(first: 1) {
        total
        edges {
          node {
            id
            path(type: source) {
              id
              last_synced_dtime
            }
          }
        }
      }
    }
  }
`

export const CreateCohortCard = ({
  card,
  resourceId,
  registerCreatorAsRole,
  onCohortCreated
}) => {
  const [createdCohortId, setCreatedCohortId] = useState(null)

  const { query: cohortSyncPlanQuery } = useCohortSyncPlanQuery({
    cohortId: createdCohortId
  })

  const handleCohortCreated = async (data) => {
    setCreatedCohortId(data?.createGroup?.id)

    if (cohortSyncPlanQuery) {
      await cohortSyncPlanQuery.refetch({
        cohortId: data?.createGroup?.id
      })
      setCreatedCohortId(null)
      if (onCohortCreated) {
        onCohortCreated(data)
      }
    }
  }

  return (
    <StyledCard card={card} noaction>
      <Box p={4} pt={0}>
        <CreateCohort
          resourceId={resourceId}
          registerCreatorAsRole={registerCreatorAsRole}
          onClose={card.onClose}
          onCohortCreated={handleCohortCreated}
        />
      </Box>
    </StyledCard>
  )
}

CreateCohortCard.displayName = "CreateCohortCard"

const CreateCohort = ({
  resourceId: preSelectedResourceId,
  registerCreatorAsRole,
  onClose,
  onCohortCreated
}) => {
  const cohortNameInputRef = useRef()
  const [selectedResourceId, setSelectedResourceId] = useState(
    preSelectedResourceId
  )
  const [resourceType, setResourceType] = useState(RESOURCE_TYPE_COURSE)
  const [resourceName, setResourceName] = useState("")
  const [cohortName, setCohortName] = useState("")
  const [resourceCoverImage, setResourceCoverImage] = useState(null)

  const { t, tc } = useTranslate()

  // for an unknown reason, have to handle handle the change in the preSelectedResourceId
  // subsequent mounts of the CreateCohort seem to not set the provided resourceId prop in state
  useEffect(() => {
    preSelectedResourceId && setSelectedResourceId(preSelectedResourceId)
  }, [preSelectedResourceId])

  // optionally query a preselected resource
  const preSelectedResourceQuery = useQuery(RESOURCE_QUERY, {
    variables: { id: preSelectedResourceId },
    skip: !preSelectedResourceId
  })

  const resourceQuery = useQuery(RESOURCE_QUERY, {
    variables: { id: selectedResourceId },
    skip: !selectedResourceId
  })

  const preSelectedResource = get(preSelectedResourceQuery, "data.resource")
  const selectedResource = get(resourceQuery, "data.resource")

  function handleAutoFocusingCohortNameInput() {
    // Try focusing immediately, for best UX.
    cohortNameInputRef.current?.focus()
    // Add a slight delay to ensure focus action occurs after any other.
    setTimeout(() => {
      cohortNameInputRef.current?.focus()
    }, 1)
  }

  // Auto focus cohort name input when user selects an existing resource.
  useEffect(() => {
    if (selectedResource) handleAutoFocusingCohortNameInput()
  }, [selectedResource])

  function onCompleted(...args) {
    // Reset to initial state (necessry if form is not unmounted before user completes again).
    if (!preSelectedResource) setResourceName("")
    setCohortName("")
    return onCohortCreated?.(...args)
  }

  const [createResourceCohort, createResourceCohortMutation] = useMutation(
    CREATE_RESOURCE_MUTATION,
    {
      variables: {
        name: resourceName,
        cover_image: resourceCoverImage,
        resource_type: RESOURCE_TYPE_LABELS[resourceType],
        group_name: cohortName,
        group_role: registerCreatorAsRole
      },
      onCompleted,
      refetchQueries: ["LibraryResourcesQuery", "Cohorts", "UserResources"]
    }
  )

  const [createCohort, createCohortMutation] = useMutation(
    CREATE_GROUP_MUTATION,
    {
      variables: {
        base_resource_id: selectedResourceId,
        visibility: 5, // Private, required variable
        name: cohortName,
        role: registerCreatorAsRole
      },
      onCompleted,
      refetchQueries: [
        "Cohorts",
        {
          query: CohortSwitcherCohortsDocument,
          variables: { resourceId: selectedResourceId }
        }
      ]
    }
  )

  // // TODO: make sure checking path.last_synced_dtime is accurate
  // // if the resource's source cohort has not yet been synced, prompt user to sync to the source
  // if (
  //   preSelectedResource &&
  //   !preSelectedResource.source_cohort.path.last_synced_dtime &&
  //   // cohort exists and has been synced
  //   // NOTE: ignoring the fact that multiple cohorts could exist in which 1 or more have been synced yet the source path has not
  //   // we'll just not handle that case as this is more of a helper prompt
  //   preSelectedResource.cohorts.total === 1 &&
  //   preSelectedResource.cohorts.edges[0].node.path.last_synced_dtime
  // ) {
  //   // NOTE: not handling this prompt for now until we can test a check that works!
  //   // return (
  //   //   <SyncSourcePromptCard
  //   //     card={card}
  //   //     resource={preSelectedResource}
  //   //     cohortId={preSelectedResource.cohorts.edges[0].node.id}
  //   //   />
  //   // )
  // }

  let onSubmit = null
  if ((selectedResource || resourceName) && cohortName) {
    onSubmit = (e) => {
      e.preventDefault()
      return selectedResource ? createCohort() : createResourceCohort()
    }
  }

  // TODO: check for cohorts included in plan
  // How do we handle curriculum subscribers?
  // How do we handle non-activated schools?
  // How do we handle licensed resources?

  if (get(createResourceCohortMutation, "data.createResource")) {
    const {
      createResource: resource,
      createResource: { cohort }
    } = createResourceCohortMutation.data

    return (
      <PathwrightUI.Provider
        theme={{
          backgroundImage: resource.blurred_background,
          backgroundOverlay: resource.background_overlay
        }}
      >
        <Success
          icon="check"
          heading={t("New Path & Cohort Created.")}
          body={t("The next step is to design the learning path.")}
          onBackgroundClick={onClose}
          primaryAction={{
            label: (
              <>
                <Pathicon icon="group" />
                <span> </span>
                {t("Design Path")}
              </>
            ),
            // Linking to the source cohort since editing the source path
            // makes the most sense after creating a new resource + cohort.
            // to: getGroupUrl(resource.slug, cohort.source_cohort_id, "path"),
            // Current opinion is that most users will not be concerned with
            // the source path, so direct them to the created cohort path.
            to: getGroupUrl(resource.slug, cohort.id, "path"),
            onClick: onClose
          }}
          secondaryAction={{
            label: "Create another",
            onClick: () => createResourceCohortMutation.reset()
          }}
        />
      </PathwrightUI.Provider>
    )
  }

  if (get(createCohortMutation, "data.createGroup")) {
    const {
      createGroup: cohort,
      createGroup: { resource }
    } = createCohortMutation.data

    return (
      <PathwrightUI.Provider
        theme={{
          backgroundImage: resource.blurred_background,
          backgroundOverlay: resource.background_overlay
        }}
      >
        <Success
          icon="check"
          heading={t("Cohort Created")}
          body={t(`Your new Cohort in {{ resourceName }} is ready.`, {
            resourceName: resource.name
          })}
          onBackgroundClick={onClose}
          primaryAction={{
            label: (
              <>
                <Pathicon icon="group" />
                <span> </span>
                {t("View Cohort")}
              </>
            ),
            to: `${getGroupUrl(resource.slug, cohort.id, "path")}?path_mode=15`,
            onClick: onClose
          }}
          secondaryAction={{
            label: "Create another",
            onClick: () => createCohortMutation.reset()
          }}
        />
      </PathwrightUI.Provider>
    )
  }

  return (
    <CreateCohortContainer className="CreateCohort">
      <UpgradeCohortsGate resourceId={selectedResourceId}>
        {preSelectedResourceQuery.loading ? (
          <LoadingCircle />
        ) : (
          <form onSubmit={onSubmit}>
            <VStack spacing={3}>
              <ResourceTypeSwitcher
                resourceType={
                  preSelectedResource ? preSelectedResource.resource_type : null
                }
                onSwitchResourceType={setResourceType}
                prompt={t("Create or select a")}
              />
              <ResourcePicker
                resourceType={resourceType}
                onChangeResourceName={setResourceName}
                preSelectedResource={preSelectedResource}
                selectedResource={selectedResource}
                onSelectResource={(resource) =>
                  setSelectedResourceId(resource ? resource.id : null)
                }
                onSelectResourceCoverImage={setResourceCoverImage}
                onOpenStore={onClose}
                onFocusCohortNameInput={handleAutoFocusingCohortNameInput}
              />
              <CohortNameInput
                ref={cohortNameInputRef}
                resourceType={resourceType}
                onChangeCohortName={setCohortName}
                selectedResource={selectedResource}
                resourceCoverImage={resourceCoverImage}
              />
              <SubmitButton
                styleType="primary"
                size="large"
                onClick={onSubmit}
                disabled={!onSubmit}
              >
                {selectedResource
                  ? t("Create Cohort")
                  : t("Create {{ resourceType }} + Cohort", {
                      resourceType: getResourceTypeLabel(resourceType, tc)
                    })}
              </SubmitButton>
            </VStack>
          </form>
        )}
      </UpgradeCohortsGate>
    </CreateCohortContainer>
  )
}

CreateCohort.displayName = "CreateCohort"

CreateCohort.propTypes = {
  resourceId: PropTypes.number,
  registerCreatorAsRole: PropTypes.oneOf(ROLES[ROLE_TYPE_OFFERING]),
  onClose: PropTypes.func.isRequired
}

const StyledCard = styled(Card)`
  .CreateCohort {
    padding: 0;
  }
`

const CreateCohortContainer = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  max-width: 680px;
  margin: 0px auto;

  .Tooltip {
    min-width: 200px;
    white-space: normal;
    text-align: left;
  }
  .Tooltip__trigger {
    display: flex !important;
  }
`

export default CreateCohort
