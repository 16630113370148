import {
  PathCreatorCohortsQuery,
  usePathCreatorCohortsQuery
} from "../../api/generated"
import ItemSelector from "./ItemSelector"
import { registrationRolesMap } from "./RoleBadge"
import { PathCreatorProps, SelectedCohort, SelectedResource } from "./state"

// Unfortunately we can't pass params to fragments, so typing the user_registration
// onto the PathCreatorCohortFragment is a tricky problem, hence using the cohort node.
type SelectedCohortNode = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<PathCreatorCohortsQuery["cohorts"]>["edges"]
    >[number]
  >["node"]
>

// Maps the PathCreatorCohortFragment to the SelectedCohort type.
export function massageCohort(
  // TODO: fix this manual typing. Unfortunately we can't pass params to
  // fragments, so typing the user_registration onto the PathCreatorCohortFragment
  // is a tricky problem.
  cohort: SelectedCohortNode
): SelectedCohort {
  return {
    id: cohort.id,
    name: cohort.name,
    // Map cohort path role to key (we filter out cohorts the user is registered for, so expecting null).
    role: registrationRolesMap.get(
      cohort.user_registration?.role!
    ) as SelectedCohort["role"],
    registrations: cohort.registrations!
  }
}

type CohortSelectorProps = {
  targetUserId: number
  onChange: (item?: SelectedCohort | null) => void
} & PathCreatorProps

function CohortSelector({
  targetUserId,
  pathCreatorState,
  pathCreatorDispatch,
  onChange
}: CohortSelectorProps) {
  const query = usePathCreatorCohortsQuery({
    variables: {
      // There should always be a selected resource at this point, but TS doesn't now that
      resourceId: (pathCreatorState.resource as SelectedResource).id,
      targetUserId
    }
  })
  const cohorts =
    query.data?.cohorts?.edges?.map((edge) => edge?.node!).map(massageCohort) ||
    []

  // Using a -1 id to indicate a "create" option.
  cohorts.unshift({
    id: -1,
    name: "Create a new Cohort"
  })

  function handleChange(item?: SelectedCohort | null) {
    // Negtive id indicates user selected the "create" option.
    if (item?.id === -1) {
      pathCreatorDispatch({
        type: "set",
        value: {
          status: "create_cohort",
          resource: pathCreatorState.resource as SelectedResource,
          cohort: null
        }
      })
    } else {
      onChange(item)
    }
  }

  // Disable selecting cohorts where teh user is already registered.
  function isOptionDisabled(item: SelectedCohort) {
    return !!item.role
  }

  return (
    <ItemSelector
      options={cohorts}
      value={(pathCreatorState.cohort || null) as SelectedCohort | null}
      onChange={handleChange}
      isLoading={query.loading}
      isOptionDisabled={isOptionDisabled}
    />
  )
}

export default CohortSelector
