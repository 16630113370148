import ManagePage from "@pathwright/web/src/modules/account/manage/ManagePage"
import ManagePageAdvanced from "@pathwright/web/src/modules/account/manage/ManagePageAdvanced"
import ManagePageContentGate from "@pathwright/web/src/modules/account/manage/ManagePageContentGate"
import { PAGE_TYPE_TERMS } from "../constants"
import ManagePageBuilder from "./components/ManagePageBuilder"

const ManagePageContainer =
  (Component) =>
  ({ card, match }) => {
    const passProps = { card, PageBlocks: ManagePageBuilder }
    if (match.params.pageId) {
      passProps.id = parseInt(match.params.pageId)
    } else {
      passProps.pageType = PAGE_TYPE_TERMS
    }
    return <Component {...passProps} />
  }

export default {
  component: ManagePageContainer(ManagePage),
  childCards: {
    content: {
      component: ManagePageContainer(ManagePageContentGate)
    },
    // link: {
    //   component: ManagePageContainer(ManagePageLink),
    // },
    // builder: {
    //   component: ManagePageContainer(ManagePageBuilder),
    // },
    // html: {
    //   component: ManagePageContainer(ManagePageHTML),
    // },
    advanced: {
      component: ManagePageContainer(ManagePageAdvanced)
    }
  }
}
