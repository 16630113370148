import gql from "graphql-tag"
import { DiscussionPostFragmentDoc } from "../../api/generated"

export default gql`
  mutation UpdateUserDiscussionHistory(
    $context: DiscussionContextInput!
    $discussion_id: Int!
  ) {
    updateUserDiscussionHistory(
      context: $context
      discussion_id: $discussion_id
    ) {
      ...DiscussionPost
    }
  }
  ${DiscussionPostFragmentDoc}
`
