import gql from "graphql-tag"
import { SchoolSubscriptionFragmentDoc } from "../../api/generated"

export default gql`
  mutation ($school_subscription_plan_id: ID!, $code: String!) {
    redeemSchoolGiftSubscription(
      school_subscription_plan_id: $school_subscription_plan_id
      code: $code
    ) {
      ...SchoolSubscription
    }
  }
  ${SchoolSubscriptionFragmentDoc}
`
