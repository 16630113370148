import {
  Box,
  Center,
  CloseButton,
  Collapse,
  Divider,
  HStack,
  Link,
  List,
  ListItem,
  Spinner,
  Text
} from "@chakra-ui/react"
import { motion } from "framer-motion"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import {
  UnifiedHomeRegistrationFragment,
  useUnifiedHomeResourceGroupsLazyQuery
} from "../../../api/generated"
import Pathicon from "../../../pathicon/Pathicon"
import { BORDER_RADIUS } from "../../constants"
import { getResourceLink } from "../../utils"
import GroupAvatars from "../GroupAvatars"
import ProgressBar from "../ui/ProgressBar"
import SimpleButton from "../ui/SimpleButton"

const StartButton = () => {
  return <SimpleButton label="Start" icon="chevron-right" />
}
const CompleteButton = () => {
  return <SimpleButton label="Complete" icon="check" iconPosition="right" />
}

const ObserverButton = () => {
  return (
    <SimpleButton label="Guided Tour" icon="group-2" iconPosition="right" />
  )
}

const LearnerButton = ({ progress }: { progress: number }) => {
  const complete: number = 1

  return (
    <Box pos="relative" maxH="32px" borderRadius={BORDER_RADIUS}>
      {progress && progress > 0 && progress < complete ? (
        <ProgressBar progressValue={progress} />
      ) : progress === complete ? (
        <CompleteButton />
      ) : (
        <StartButton />
      )}
    </Box>
  )
}

const ModeratorButton = ({ progress }: { progress: number }) => {
  const complete: number = 1
  const notStarted: number = 0
  return (
    <Box pos="relative" maxH="32px" borderRadius={BORDER_RADIUS}>
      {progress === notStarted && (
        <Text
          m={0}
          fontSize="sm"
          fontWeight={"bold"}
          py={1}
          px={4}
          textDecoration="none"
          color={"blackAlpha.900"}
        >
          Not Started
        </Text>
      )}
      {progress && progress > 0 ? (
        <ProgressBar progressValue={progress} />
      ) : (
        progress === complete && <CompleteButton />
      )}
    </Box>
  )
}

const DesignSourceButton = () => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  return (
    <SimpleButton
      label="Source Path"
      icon="recur"
      iconPosition="right"
      colorScheme={isHovered ? "blackAlpha" : "whiteAlpha"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    />
  )
}
type DesignWithCohortsButtonProps = {
  registration: any
}

const DesignWithCohortsButton = ({
  registration
}: DesignWithCohortsButtonProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [loadedGroups, setLoadedGroups] = useState<any[]>([])
  // const [visibleGroups, setVisibleGroups] = useState<number>(5)

  const resource = registration.resource!
  const sourceURL = useMemo(() => getResourceLink(registration), [])

  const [getGroups, { data, loading }] = useUnifiedHomeResourceGroupsLazyQuery({
    variables: {
      resourceId: resource.id
    }
  })

  const resources = data?.resource!

  useEffect(() => {
    if (isOpen && resources) {
      setLoadedGroups(
        resources.groups?.edges
          ?.map((resource) => ({
            id: resource?.group?.id,
            name: resource?.group?.name,
            //!revisit this logic
            isExpired:
              resource?.group?.resource_license &&
              resource?.group?.resource_license.expiration_dtime &&
              moment().isAfter(
                resource?.group.resource_license.expiration_dtime
              ) &&
              resource?.group.resource_license
          }))
          .filter((group) => group.id && group.name) || [] // Filters out any items where id or name is undefined/nul just in case
      )
    }
  }, [isOpen, resources])

  const handleLoadData = () => {
    setIsOpen((prev) => !prev)
    getGroups()
  }
  // const handleLoadMore = () => {
  //   setVisibleGroups((prev) => prev + 5)
  // }

  if (resource?.groups?.total < 2) {
    return null
  }

  return (
    <Box
      h="100%"
      w="100%"
      position={"relative"}
      overflow={"hidden"}
      borderRadius={16}
    >
      {isOpen && !loading && (
        <CloseButton
          onClick={() => setIsOpen((prev) => !prev)}
          as={motion.div}
          borderRadius={"full"}
          position="absolute"
          initial={{ right: 12 }}
          animate={{
            right: 1,
            transition: {
              type: "spring"
            }
          }}
          top={0.5}
          zIndex={100}
          size="sm"
          cursor={"pointer"}
        />
      )}
      <HStack
        py={1}
        px={5}
        w="100%"
        color={"blackAlpha.900"}
        onClick={handleLoadData}
      >
        <Pathicon icon="cohort" />
        <Text fontSize="sm" fontWeight={"bold"} m={0} color={"blackAlpha.900"}>
          {resource.groups?.total} Cohorts
        </Text>
        {!isOpen && <Pathicon icon="chevron-down" />}
      </HStack>

      {isOpen && loading && (
        <Center w="100%">
          <Spinner
            thickness="2px"
            speed="0.65s"
            emptyColor="blackAlpha.200"
            color="blackAlpha.900"
            size="md"
          />
        </Center>
      )}
      <Collapse in={isOpen} animateOpacity>
        {loadedGroups && (
          <List
            my={0}
            pl={0}
            // ml={4}
            maxH={"172px"}
            w="100%"
            py={1.5}
            overflowY={"auto"}
          >
            {loadedGroups.map((group) => {
              const pathURL = `/library/${resource?.slug}/${group.id}/path/`
              return (
                <ListItem
                  key={group.id}
                  _hover={{
                    backgroundColor: "whiteAlpha.900",
                    textDecoration: "none"
                  }}
                  h="100%"
                  w="100%"
                  pr={3}
                  pl={4}
                  py={1}
                  cursor={"pointer"}
                >
                  <Link
                    href={pathURL}
                    _hover={{ textDecoration: "none" }}
                    w="100%"
                  >
                    <GroupAvatars
                      groupId={group.id as number}
                      showGroupName
                      fontColor="blackAlpha.900"
                    />
                  </Link>
                </ListItem>
              )
            })}
          </List>
        )}

        <Divider />
        <Link
          href={sourceURL.pathURL}
          _hover={{
            textDecoration: "none"
          }}
          w="100%"
        >
          <DesignSourceButton />
        </Link>
      </Collapse>
    </Box>
  )
}

interface PathCardButtonProps {
  progress?: number
  registration?: UnifiedHomeRegistrationFragment
  href?: string
  onClick?: () => void
  role:
    | "observer"
    | "copy"
    | "learner"
    | "moderator"
    | "teacher"
    | "designer_teacher"
    | "design_source"
}

const PathCardButton: React.FC<PathCardButtonProps> = ({
  progress = 0,
  registration,
  role,
  href,
  onClick
}) => {
  const isSource: boolean = role === "design_source"
  const isDesignerTeacher: boolean = role === "designer_teacher"
  // Determine if the width should be "full" based on progress
  const isWidthFull: boolean = progress > 0 && progress < 1

  const container = {
    animate: {
      width: isWidthFull ? "100%" : isDesignerTeacher ? "auto" : "max-content",
      // backdropFilter: "blur(20px)",
      backgroundColor: isSource
        ? "rgba(255, 255, 255, 0)"
        : "rgba(255, 255, 255, 0.5)"
    },
    hover: {
      backgroundColor: "rgba(255, 255, 255, 0.85)",
      // role === "designer_teacher"
      //   ? "rgba(255, 255, 255, 0.60)"
      //   : "rgba(255, 255, 255, 0.85)",
      transition: {
        duration: 0.2
      }
    }
  }

  return (
    <Box
      as={motion.div}
      variants={container}
      animate="animate"
      whileHover="hover"
      borderRadius={BORDER_RADIUS}
      cursor={"pointer"}
      onClick={onClick}
      zIndex={10}
      backdropFilter={"auto"}
      backdropBlur={"8px"}
      _hover={{ shadow: "sm" }}
    >
      <Link href={href} _hover={{ textDecoration: "none" }}>
        {/* Conditional rendering based on the role */}
        {role === "design_source" && <DesignSourceButton />}
        {role === "observer" && <ObserverButton />}
        {role === "learner" && <LearnerButton progress={progress} />}
        {role === "moderator" && <ModeratorButton progress={progress} />}
        {role === "designer_teacher" && (
          <DesignWithCohortsButton registration={registration} />
        )}
      </Link>
    </Box>
  )
}

export default PathCardButton
