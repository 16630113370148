import {
  ResourceType,
  usePathCreatorAssignRoleMutation,
  usePathCreatorCreateCohortMutation,
  usePathCreatorCreatePathMutation
} from "../../api/generated"
import { massageCohort } from "./CohortSelector"
import {
  SelectedResourceWithSourceCohort,
  massageResource
} from "./ResourceSelector"
import { PathCreatorState, SelectedCohort, SelectedResource } from "./state"

function usePathCreatorFlowSubmit() {
  const [createPath] = usePathCreatorCreatePathMutation()
  const [createCohort] = usePathCreatorCreateCohortMutation()
  const [assignRole] = usePathCreatorAssignRoleMutation()

  return async function ({
    targetUserId,
    resource,
    cohort
  }: PathCreatorState & { status: "submit" } & {
    targetUserId: number
  }): Promise<PathCreatorState & { status: "complete" }> {
    let existingResource: SelectedResource | null =
      "id" in resource ? resource : null
    let existingCohort: SelectedCohort | null = "id" in cohort ? cohort : null

    if (!existingResource) {
      // Creating a new resource + cohort.
      const result = await createPath({
        variables: {
          name: resource.name,
          image: resource.image,
          resourceType: ResourceType.Course,
          cohortName: cohort.name
        }
      })

      // Set the resource id.
      if (result.errors) {
        throw Error(result.errors[0].toString())
      } else {
        existingResource = massageResource(
          result.data!.createResource! as SelectedResourceWithSourceCohort
        )
        existingCohort = massageCohort(result.data!.createResource!.cohort!)
      }
    } else if (!existingCohort) {
      // Creating a new cohort.
      const result = await createCohort({
        variables: {
          name: cohort.name,
          resourceId: existingResource.id
        }
      })

      if (result.errors) {
        throw Error(result.errors[0].toString())
      } else {
        existingCohort = massageCohort(result.data!.createCohort!)
      }
    }

    let assignRolePromises: Promise<any>[] = []

    // Assign the source cohort role.
    // Only assign source cohort role when user does not have one.
    if (resource.role && !existingResource.sourceCohort.role) {
      assignRolePromises.push(
        assignRole({
          variables: {
            cohorts: [existingResource.sourceCohort.id],
            user: targetUserId,
            role: resource.role
          }
        })
      )
    }

    // Assign the cohort role.
    // Always assign the cohort role as no cohort should be selected for which the user
    // is already registered (we filter those out from the picker).
    if (cohort.role) {
      assignRolePromises.push(
        assignRole({
          variables: {
            cohorts: [existingCohort.id],
            user: targetUserId,
            role: cohort.role
          }
        })
      )
    }

    // Wait for all role assignments to complete.
    await Promise.all(assignRolePromises)

    // Ensure we've set the roles on the existing items.
    existingResource.role = resource.role
    existingResource.sourceCohort.role = resource.role
    existingCohort.role = cohort.role

    // Ugly, but our "complete" cohort requires that role not be undefined
    // so we must retype the existing cohort with a null default for role.
    const retypedExistingCohort: (PathCreatorState & {
      status: "complete"
    })["cohort"] = {
      ...existingCohort,
      // Forcefully constrain role to null (not allowing undefined).
      role: existingCohort.role || null
    }

    return {
      resource: existingResource,
      cohort: retypedExistingCohort,
      status: "complete"
    }
  }
}

export default usePathCreatorFlowSubmit
