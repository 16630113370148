import { Box, SimpleGrid, VStack } from "@chakra-ui/react"
import AddPathButton from "./components/AddPathButton"
import FloatingToolbar from "./components/FloatingToolbar"
import HomeBackground from "./components/ui/HomeBackground"
import RegistrationsContainer from "./containers/RegistrationsContainer"
import TopContainer from "./containers/TopContainer"

const UnifiedHome = () => {
  return (
    <VStack
      w="full"
      minH="100vh"
      h="full"
      py={24}
      sx={{
        [".App__primary:has(&)"]: {
          overflow: "visible"
        }
      }}
    >
      <HomeBackground />
      <TopContainer />
      <FloatingToolbar /> //! todo: make the position dynamic
      <SimpleGrid columns={3} justifyItems={"center"} w="100%" pos={"relative"}>
        <Box maxW={300} w="100%" zIndex={-1} />
        <VStack pos="relative" minW="min(500px, 100%)" maxW="500px" spacing={4}>
          <RegistrationsContainer />
          <AddPathButton />
        </VStack>
        <Box maxW={300} w="100%" zIndex={-1} />
      </SimpleGrid>
    </VStack>
  )
}

export default UnifiedHome
