import i18next from "i18next"
import { transformNamespace } from "i18next-v4-format-converter"
import defaultsDeep from "lodash/defaultsDeep"
import moment from "moment"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { I18nextProvider } from "react-i18next"
import { getEnv } from "../utils/env"
import { I18_DEFAULT_ENGLISH_SPACE, I18_DEFAULT_NAME_SPACE } from "./constants"
import {
  addTranslationFormatters,
  getTranslation,
  preProcessTranslation
} from "./utils"

export const i18nVisibleKey = "display_i18n_keys"
export const i18nTogglerActiveKey = "i18n-keys-active"

let translationEditMode = false
const getIsTranlsationEditMode = () => {
  try {
    translationEditMode =
      eval(JSON.parse(window.localStorage.getItem(i18nVisibleKey))) || false
  } catch (error) {
    console.log("error loading i18nVisibleKey ", error)
  }
  return translationEditMode
}

// Currently only handles the translation output for when
// in translationEditMode.
const postProcessor = {
  type: "postProcessor",
  name: "postProcessor",
  process: function (value, key, options, translator) {
    if (getIsTranlsationEditMode()) {
      const translationKey = key || value
      return `<${translationKey}>`
    }
    return value
  }
}

// This literally seems to export the i18next instance.
// Importing i18next directly in other components will run into
// issues if you need i18next instance features (like `addResources`).
// Haven't had any luck getting the i18next instance from react-i18next
// context either.
export const i18n = i18next.use(postProcessor)

const Provider = ({
  children,
  localI18nBackendPlugin,
  translation,
  lng,
  locale,
  useLocalTranslation,
  debug
}) => {
  // Note that i18next has an isInitialized key that gets set to true after
  // i18next.init completes, but it does not trigger a re-render, hence using state.
  const [initialized, setInitialized] = useState(false)
  const [currentTranslation, setCurrentTranslation] = useState()

  // See notes regarding change in plural keys: https://www.i18next.com/translation-function/plurals
  function getTransformedTranslation(translation) {
    // Transform legacy "_plural" keys to "_other".
    const transformedTranslation = transformNamespace(
      translation.language,
      translation.translation
    )

    // Now merge back in our non-transformed translation in order to
    // retain the "_plural" keys which are used in nested translations
    // (i.e. "New t('path.label_plural')").
    const mergedTranslation = defaultsDeep(
      transformedTranslation,
      translation.translation
    )

    // Overwrite the translation's translation keys with the transformed translation.
    return {
      ...translation,
      translation: mergedTranslation
    }
  }

  function handleSetCurrentTranslation(translation) {
    // Set transformed translation as the current translation.
    return setCurrentTranslation(getTransformedTranslation(translation))
  }

  // This triggers an "added" event on the i18next.store which will
  // in turn cause translated components to re-render.
  const updateTranslation = (translation) => {
    i18next.addResourceBundle(
      translation.language,
      I18_DEFAULT_NAME_SPACE,
      preProcessTranslation(translation.translation)
    )
  }

  const initTranslation = (translation) => {
    const i18nextOptions = {
      ns: [I18_DEFAULT_NAME_SPACE, I18_DEFAULT_ENGLISH_SPACE],
      defaultNS: I18_DEFAULT_NAME_SPACE,
      fallbackNS: I18_DEFAULT_ENGLISH_SPACE,
      interpolation: {
        // React already does escaping
        escapeValue: false
      },
      lng: translation.language,
      debug: debug && !getEnv("production"),
      resources: {
        [translation.language]: {
          [I18_DEFAULT_NAME_SPACE]: preProcessTranslation(
            translation.translation
          ),
          // NOTE: the translation.master_translation is null when the translation.translation
          // *is* the master translation (default english).
          [I18_DEFAULT_ENGLISH_SPACE]: translation.master_translation
            ? preProcessTranslation(
                getTransformedTranslation({
                  id: 1,
                  language: "en",
                  translation: translation.master_translation
                }).translation
              )
            : {}
        }
      },
      nsSeparator: false, // default ":" // false to allow ":" in translation key
      postProcess: "postProcessor",
      react:
        // Only binding on "added" for non-local envs to prevent re-render loop
        // when translations are auto-added via local backend.
        process.env.NODE_ENV !== "development"
          ? {
              // Necessary for translated components to re-render after updating
              // the resource bundle.
              bindI18nStore: "added"
            }
          : {},
      // Only save missing keys in local dev.
      saveMissing: process.env.NODE_ENV === "development"
    }

    // Set moment locale based on current translation.
    moment.locale(translation.language || "en")

    // Use the local i18next backend plugin for local dev.
    if (localI18nBackendPlugin && process.env.NODE_ENV === "development") {
      i18next.use(localI18nBackendPlugin)
    }

    i18n.init(i18nextOptions, () => {
      addTranslationFormatters(i18n)
      setInitialized(true)
    })
  }

  useEffect(() => {
    const handleTranslationEditMode = (storageEvent) => {
      translationEditMode = storageEvent.detail.on
      updateTranslation(currentTranslation)
    }

    window.addEventListener(i18nVisibleKey, handleTranslationEditMode, false)
    return () => {
      window.removeEventListener(
        i18nVisibleKey,
        handleTranslationEditMode,
        false
      )
    }
  }, [currentTranslation])

  // Store translation prop in state.
  useEffect(() => {
    if (useLocalTranslation && process.env.NODE_ENV === "development") {
      // Only use json translation files when not on production
      // These json files are only imported locally and not included in a production build
      getTranslation(lng, I18_DEFAULT_NAME_SPACE).then(
        handleSetCurrentTranslation
      )
    } else if (translation) {
      handleSetCurrentTranslation(translation)
    }
  }, [translation])

  useEffect(() => {
    if (currentTranslation) {
      if (!initialized) {
        initTranslation(currentTranslation)
      } else {
        // Update the translation keys and trigger re-render of components using translations.
        updateTranslation(currentTranslation)
      }
    }
  }, [currentTranslation])

  return (
    <I18nextProvider i18n={i18next}>
      {initialized ? children : null}
    </I18nextProvider>
  )
}

Provider.displayName = "I18nextProvider.Provider"

Provider.propTypes = {
  lng: PropTypes.oneOf(["en", "es"]), // for local testing
  locale: PropTypes.string,
  debug: PropTypes.bool, // for local debugging,
  useLocalTranslation: PropTypes.bool
}

Provider.defaultProps = {
  debug: false,
  lng: "en",
  locale: "en-US",
  useLocalTranslation: false
}

export default Provider
