import {
  UseDisclosureProps,
  UseDisclosureReturn,
  useDisclosure
} from "@chakra-ui/react"

// Merges disclosure props for local disclosure controlled by parent.
export function useMergedDisclosure(
  disclosurRet?: UseDisclosureReturn,
  props?: UseDisclosureProps
) {
  const innerDisclosureProps = useDisclosure(props)

  // Merge local and provided disclosure props.
  const disclosureProps = {
    ...innerDisclosureProps,
    ...disclosurRet,
    getButtonProps() {
      return {
        ...innerDisclosureProps.getButtonProps(),
        ...disclosurRet?.getButtonProps?.()
      }
    },
    getDisclosureProps() {
      return {
        ...innerDisclosureProps.getDisclosureProps(),
        ...disclosurRet?.getDisclosureProps?.()
      }
    }
  }

  return disclosureProps
}
