import gql from "graphql-tag"
import { SchoolSubscriptionPlanFragmentDoc } from "../../api/generated"

export default gql`
  fragment SchoolGiftSubscription on SchoolGiftSubscription {
    id
    redeemed
    recipient_email
    recipient_email_sent_time
    recipient_first_name
    recipient_last_name
    gifted_subscription_cycles
    gift_message
    gift_code
    redemption_link
    sender {
      ...User
    }
    school_subscription_plan {
      ...SchoolSubscriptionPlan
    }
  }
  ${SchoolSubscriptionPlanFragmentDoc}
`
