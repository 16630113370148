import { Box, HStack, useRadio, useRadioGroup } from "@chakra-ui/react"
import RoleBadge from "../shared/RoleBadge"

function RadioPill({ label, color, onClick, ...props }) {
  const { getInputProps } = useRadio(props)

  const input = getInputProps()

  return (
    <Box as="label">
      <input
        aria-label={label}
        aria-describedby={`role-badge-label-${label}`}
        onClick={(e) => onClick?.(e, input.value)}
        {...input}
      />
      <RoleBadge label={label} color={color} highlight={input.checked} />
    </Box>
  )
}

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
const RadioPills = ({ options, value, onChange, onClick, name }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    defaultValue: options[0]?.key,
    value,
    onChange
  })

  const group = getRootProps()

  return (
    <HStack {...group} align="center" justify="center" flexWrap="wrap">
      {options.map((value) => {
        const radio = getRadioProps({ value: value.key })
        return (
          <RadioPill
            key={value.key}
            label={value.label}
            color={value.color}
            onClick={onClick}
            {...radio}
          />
        )
      })}
    </HStack>
  )
}

export default RadioPills
