import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Success from "@pathwright/ui/src/components/success/Success"
import PathwrightUI from "@pathwright/ui/src/components/ui/PathwrightUI"

import { Box, Button, HStack, Image, Input, VStack } from "@chakra-ui/react"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { Field, Form, Formik } from "formik"
import { useState } from "react"
import styled from "styled-components"
import { z } from "zod"
import { toFormikValidate } from "zod-formik-adapter"
import {
  CreatedResourceFragment,
  useCreateResourceMutation
} from "../../api/generated"
import ResourceTypeSwitcher from "../../cohort/create/ResourceTypeSwitcher"
import { useModal } from "../../modal"
import {
  RESOURCE_TYPE_COURSE,
  RESOURCE_TYPE_LABELS
} from "../../resource/constants"
import useRandomThemeResourceImage from "../../resource/query/useRandomThemeResourceImage"
import { getResourceTypeLabel } from "../../resource/utils"
import { getGroupUrl } from "../../utils/urls"

const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  min-width: 400px;
  margin: 0px auto;

  .Tooltip {
    min-width: 200px;
    white-space: normal;
    text-align: left;
  }
`

type CreateResourceSuccessProps = {
  resource: CreatedResourceFragment
}

const CreateResourceSuccess = ({ resource }: CreateResourceSuccessProps) => {
  const { t, tca } = useTranslate()
  const { closeModal } = useModal()
  const url = `${getGroupUrl(
    resource.slug,
    resource.curriculum!.id
  )}?path_mode=15` // set default mode to DESIGN mode (15)
  const typeLabel = getResourceTypeLabel(resource.resource_type, t)

  return (
    <PathwrightUI.Provider
      theme={{
        backgroundImage: resource.blurred_background,
        backgroundOverlay: resource.background_overlay
      }}
    >
      {/* @ts-ignore */}
      <Success
        icon="check"
        heading={tca(`${typeLabel} Created`)}
        body={t(`Your new ${typeLabel} in {{ resourceName }} is ready.`, {
          resourceName: resource.name
        })}
        onBackgroundClick={closeModal}
        primaryAction={{
          label: (
            <HStack>
              <Pathicon icon="group" />
              <span>{tca(`View ${typeLabel}`)}</span>
            </HStack>
          ),
          to: url,
          onClick: closeModal
        }}
      />
    </PathwrightUI.Provider>
  )
}

const schema = z.object({
  name: z.string().min(1)
})
const validate = toFormikValidate(schema)
type Values = z.infer<typeof schema>

type CreateResourceProps = {
  onSave?: (resource: CreatedResourceFragment) => any
}

export const CreateResource = ({ onSave }: CreateResourceProps) => {
  const { tc, tca } = useTranslate()
  const [resourceType, setResourceType] = useState(RESOURCE_TYPE_COURSE)
  const randomResourceCover = useRandomThemeResourceImage()

  const [createResource, { data, loading, error }] = useCreateResourceMutation({
    refetchQueries: ["LibraryResourcesQuery", "UserResources"]
  })

  const placeholder = `Enter a ${getResourceTypeLabel(
    resourceType,
    tc
  )} name...`

  const handleCreateResource = async (values: Values) => {
    const result = await createResource({
      variables: {
        name: values.name,
        cover_image: randomResourceCover,
        resource_type: RESOURCE_TYPE_LABELS[resourceType]
      }
    })
    if (result.data) onSave?.(result.data.createResource!)
  }

  return (
    <Container>
      <ResourceTypeSwitcher onSwitchResourceType={setResourceType} />
      <Formik
        initialValues={{
          name: ""
        }}
        validateOnMount
        validate={validate}
        onSubmit={handleCreateResource}
      >
        {(form) => (
          <Form>
            <VStack w="100%" mt={3} spacing={3}>
              <HStack w="100%">
                <Image
                  src={randomResourceCover}
                  maxW="50px"
                  borderRadius="10px"
                />
                <Box flexGrow={1}>
                  <Field
                    as={Input}
                    name="name"
                    type="text"
                    autoFocus
                    placeholder={placeholder}
                  />
                </Box>
              </HStack>
              <Button
                type="submit"
                colorScheme="brand"
                size="lg"
                cursor={form.isValid ? "pointer" : "disabled"}
                isDisabled={!form.isValid || loading}
                isLoading={loading}
              >
                {tca("Create {{ resourceType }}", {
                  resourceType: getResourceTypeLabel(resourceType, tc)
                })}
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export const CreateResourceModal = () => {
  const [resource, setResource] = useState<CreatedResourceFragment>()

  return !resource ? (
    <CreateResource onSave={setResource} />
  ) : (
    <CreateResourceSuccess resource={resource} />
  )
}
