import ActiveCertificate from "@pathwright/web/src/modules/certificate/editor/ActiveCertificate"
import {
  canAdministrate,
  canEditLibrary
} from "@pathwright/web/src/modules/user/permissions"

export default {
  path: "/certificate/",
  onEnter: ({ location, pwContext }, replace) => {
    const homeLocation =
      window.App.getStore("navigation").request.getHomeLocation()
    // Only permt admins to reach certificate editor when not for resource.
    if (!location.query.resource_id && !canAdministrate(pwContext)) {
      return replace(homeLocation)
    }

    // Only permit library editors+ to reach certificate editor for resource.
    if (location.query.resource_id && !canEditLibrary(pwContext)) {
      return replace(homeLocation)
    }
  },

  component: ActiveCertificate
}
