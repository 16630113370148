import { useSkipPathItemForUserMutation } from "../../api/generated"

const useSkipPathItemForUser = (pathItem) => {
  const [skipPathItemForUser, { data, loading, error }] =
    useSkipPathItemForUserMutation({
      variables: {
        id: pathItem.id,
        path_id: pathItem.path_id
      },
      refetchQueries: ["Path", "PathPerformanceQuery"]
    })

  return [skipPathItemForUser, { data, loading, error }]
}

export default useSkipPathItemForUser
