import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import styled from "styled-components"
import MembershipBlank from "./MembershipBlank"
import MembershipListItem from "./MembershipListItem"
import MembershipSearch from "./MembershipSearch"
import { MEMBERSHIP_FILTER_ALL } from "./constants"

const StyledContainer = styled.div`
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`

const StyledScrollView = styled(ScrollView)`
  min-height: auto;

  .Portable:not(:has(.Panel--cardstack)) & {
    height: 400px;
  }
`

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`

const StyledFooter = styled.footer`
  position: relative;
  padding: 10px;
`

const MembershipList = (props) => {
  const {
    role,
    error,
    // loading,
    loadingMore,
    search,
    debouncedSearch,
    members,
    membershipFilter,
    loadMore,
    setMemberRole,
    setMemberAccess,
    setMemberArchived,
    resetPassword,
    location
  } = props

  if (error) {
    return (
      <BlankSlate
        icons={null}
        heading="An unexpected error occurred"
        body={(error && error.message) || error}
      />
    )
  }

  // Unsure why this works, but this "hack" is to prevent scrolling to top
  // of list when loading more, which will happen if we use the `loading`
  // prop directly. Appears to be related to the loading && loadingMore props
  // being out of sync by 1 render. You can resolve this in the paginator utils,
  // but that can lead to other, slightly off renders (like when searching).
  const loading = loadingMore || (!members && props.loading)

  const hasResults = members && members.edges.length > 0
  const showResults = hasResults || membershipFilter !== MEMBERSHIP_FILTER_ALL
  const searching = Boolean(loading && search && search !== debouncedSearch)

  return (
    <StyledContainer>
      {(search || showResults) && (
        <MembershipSearch {...props} searching={searching} />
      )}
      <StyledScrollView onScrollBottom={!loadingMore && loadMore}>
        <StyledList>
          {
            // We're only querying with search with a minimum of 2 characters
            debouncedSearch && debouncedSearch.length < 2 ? (
              <BlankSlate
                icons={null}
                body="Enter more than one character to search through your school's community."
              />
            ) : loading && !searching && !loadingMore ? (
              <LoadingCircle />
            ) : hasResults ? (
              members.edges.map((edge) => (
                <li key={edge.node.id}>
                  <MembershipListItem
                    {...edge.node}
                    membership={edge.node}
                    setMemberAccess={setMemberAccess}
                    setMemberArchived={setMemberArchived}
                    setMemberRole={setMemberRole}
                    resetPassword={resetPassword}
                    location={location}
                  />
                </li>
              ))
            ) : (
              <MembershipBlank />
            )
          }
        </StyledList>
        <StyledFooter>{loadingMore && <LoadingCircle />}</StyledFooter>
      </StyledScrollView>
    </StyledContainer>
  )
}

MembershipList.displayName = "MembershipList"

export default MembershipList
