import { Button, StackProps, VStack } from "@chakra-ui/react"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { useState } from "react"
import { ItemKey, StatusKey } from "../state"
import { transient } from "../style"

type ChooseOneProps = {
  itemKey: ItemKey
  onChoose: (
    action: `${"create" | "select"}_${ItemKey}`
  ) => Promise<void> | void
} & StackProps

function ChooseOne({ itemKey, onChoose, ...stackProps }: ChooseOneProps) {
  const [loadingKey, setLoadingKey] = useState<StatusKey | null>(null)

  // Handle any asynchronous loading state for teh action key.
  function handleIsLoading(onChoose: ChooseOneProps["onChoose"]) {
    return async function (...args: Parameters<ChooseOneProps["onChoose"]>) {
      setLoadingKey(args[0])
      await onChoose(...args)
      setLoadingKey(null)
    }
  }

  return (
    <VStack w="100%" {...transient} {...stackProps}>
      <Button
        colorScheme="blackAlpha"
        w="100%"
        cursor="pointer"
        onClick={() => handleIsLoading(onChoose)(`create_${itemKey}`)}
        leftIcon={<Pathicon icon="plus" />}
        autoFocus
        isLoading={loadingKey === `create_${itemKey}`}
      >
        Create new
      </Button>
      <Button
        colorScheme="blackAlpha"
        w="100%"
        cursor="pointer"
        onClick={() => handleIsLoading(onChoose)(`select_${itemKey}`)}
        leftIcon={<Pathicon icon="search-list" />}
        isLoading={loadingKey === `select_${itemKey}`}
      >
        Select existing...
      </Button>
    </VStack>
  )
}

export default ChooseOne
