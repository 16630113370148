import { Badge, HStack, Text } from "@chakra-ui/react"
// import { PathiconProps } from "@pathwright/app-web/src/components/pathicon/ExpensivePathicon"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import React from "react"
import { RegistrationRoleEnum } from "../../api/generated"
import { exhaustiveGuard } from "../../lib/typing"
import { CohortRole, ResourceRole } from "./state"

export const registrationRolesMap = new Map([
  [-1, RegistrationRoleEnum.Observer],
  [5, RegistrationRoleEnum.Student],
  [10, RegistrationRoleEnum.Moderator],
  [15, RegistrationRoleEnum.Teacher],
  [20, RegistrationRoleEnum.Editor]
])

type Badge = {
  // icon?: PathiconProps["icon"]
  icon?: string
  label: string
  color: string
}

export interface RoleOption {
  badges: Badge[]
  description: string
  // TODO: would be nice to decouple from path create state.
  value: ResourceRole | CohortRole
}

type GetRoleOptionsProps = {
  roles: RegistrationRoleEnum[]
  selecedRoles?: RoleOption[]
}

export function getRoleOptions({
  roles,
  selecedRoles
}: GetRoleOptionsProps): RoleOption[] {
  // Defining with fn in case we need to translate the lagels (which we will).
  const badgeMap: Record<"design" | "teach" | "learn" | "none", Badge> = {
    design: {
      icon: "edit",
      label: "Design",
      color: "yellow"
    },
    teach: {
      icon: "group",
      label: "Teach",
      color: "orange"
    },
    learn: { icon: "eye", label: "Learn", color: "blue" },
    none: {
      label: "None",
      color: "gray"
    }
  }

  const sortedRoles = [...roles.sort(), null]

  const roleOptions = sortedRoles.reduce<RoleOption[]>((acc, role) => {
    switch (role) {
      case RegistrationRoleEnum.Editor:
        acc.push({
          badges: [badgeMap.design],
          description:
            "Design lessons, steps, and content for all Cohorts taking this Path.",
          value: role
        })
        break
      case RegistrationRoleEnum.Teacher:
        if (
          selecedRoles?.some(
            (selecedRole) => selecedRole.value === RegistrationRoleEnum.Editor
          )
        ) {
          acc.push({
            badges: [badgeMap.teach],
            description: "Invite, schedule, & give feedback",
            value: role
          })
        } else {
          acc.push({
            badges: [badgeMap.design, badgeMap.teach],
            description:
              "Invite, schedule, & give feedback + update design of this Cohort.",
            value: role
          })
        }
        break
      case RegistrationRoleEnum.Moderator:
        acc.push({
          badges: [badgeMap.teach, badgeMap.learn],
          description: "", // "Track your progress + Invite, schedule, & give feedback.",
          value: role
        })

        break
      case RegistrationRoleEnum.Student:
        acc.push({
          badges: [badgeMap.learn],
          description: "Track your progress",
          value: role
        })
        break

      // Currently not showing observer role.
      case RegistrationRoleEnum.Observer:
        break

      case null:
        // Add the no role case.
        acc.push({
          badges: [badgeMap.none],
          description: "Don’t play a role in Cohort.",
          value: null
        })
        break

      default:
        exhaustiveGuard(role)
    }

    return acc
  }, [])

  return roleOptions
}

export function RoleBadgeItem({ badge }: { badge: Badge }) {
  return (
    <HStack
      as={Text}
      size="xs"
      fontWeight="bold"
      m={0}
      borderRadius="10px"
      my={1}
      px={2}
      py={1}
      color="white"
      // Matching bg color on Path mode controls.
      bg={"rgb(42, 46, 51)"}
      shadow="md"
    >
      {/* @ts-expect-error */}
      {!!badge.icon && <Pathicon icon={badge.icon} size=".8em" />}
      <span>{badge.label}</span>
    </HStack>
  )
}

function RoleBadge({ badges }: { badges: Badge[] }) {
  return (
    <HStack display="inline-flex">
      {badges.map((badge, i) => (
        <React.Fragment key={badge.label}>
          {i > 0 && i < badges.length && <Pathicon icon="plus" size=".8em" />}
          <RoleBadgeItem badge={badge} />
        </React.Fragment>
      ))}
    </HStack>
  )
}

export default RoleBadge
