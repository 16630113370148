import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import SwitchButton from "@pathwright/ui/src/components/button/SwitchButton"
import CardStack from "@pathwright/ui/src/components/card/CardStack"
import Error from "@pathwright/ui/src/components/error/Error"
import ErrorBoundary from "@pathwright/ui/src/components/error/ErrorBoundary"
import useControlledState from "@pathwright/ui/src/components/hooks/useControlledState"
import useWindowSize from "@pathwright/ui/src/components/hooks/useWindowSize"
import CountIndicator from "@pathwright/ui/src/components/indicator/CountIndicator"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Modal from "@pathwright/ui/src/components/modal/Modal"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { useScrollContext } from "@pathwright/ui/src/components/scroll/ScrollView"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import moment from "moment"
import pluralize from "pluralize"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import styled, { keyframes } from "styled-components"
import { useCohortQuery } from "../../api/generated"
import { CodexContextProvider } from "../../codex/react/useCodex"
import useCodexNode from "../../codex/react/useCodexNode"
import { CohortCard as CohortCardComponent } from "../../cohort/Cohorts"
import MentorGroupMembersCard from "../../mentor-group/members/list/MentorGroupMembersCard"
import { getGraphQLEndpoint } from "../../pathwright/PathwrightClient"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import useFuse from "../../utils/useFuse"
import { DASHBOARD_SUB_SECTIONS } from "../Dashboard"
import { DASHBOARD_MOBILE_WIDTH } from "../constants"
import SearchInput from "../ui/SearchInput"
import ManageNavItem from "./ManageNavItem"
import PersonaCard from "./PersonaCard"
// import Map from "./Map"

const shrinkIn = keyframes`
	from {
		transform: scale(.93);
		opacity: 0.5;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
`

// import Map from "./Map"
const weeksThreshold = 3

const calendarConfig = {
  sameDay: "[Today]",
  nextDay: "[Tomorrow]",
  nextWeek: "dddd",
  lastDay: "[Yesterday]",
  lastWeek: "[Last] dddd",
  sameElse: function () {
    let weeks = moment().diff(this, "week")
    if (weeks === 0) weeks = 1
    const label = pluralize("week", weeks, true)
    return weeks <= weeksThreshold
      ? `[${label} ago]`
      : `[More than ${weeksThreshold} weeks ago]`
  }
}

export const getLastSeenLabel = (date) => {
  return moment(date).calendar(null, calendarConfig)
}

const sortByDate = (field) => (a, b) =>
  new Date(a.data[field]) > new Date(b.data[field]) ? -1 : 1

const membersFilter = (nodes) =>
  nodes.filter((nodes) => nodes.type === "member")

const fuseOptions = {
  keys: ["meta.name", "meta.keywords"]
}

// usePeople?!
const usePeople = ({ search }) => {
  const { user } = usePathwrightContext()
  const node = useCodexNode(`people:${user.id}`, "space")
  const nodes = node ? node.nodes : []

  const memberNodes = useMemo(
    () =>
      nodes
        .filter((n) => n.type === "member")
        .map((n) => {
          const persona = n.from()
          const group = n.to()

          // add group + parents
          let keywords = [`${group.data.name}`]
          let g = group
          while ((g = g.parent())) {
            keywords.push(g.data.name)
          }

          // add user id
          keywords.push(`#${persona.id}`)

          // add feedback signals
          const reviewCount = persona.data.review_count
            ? parseInt(persona.data.review_count)
            : 0
          if (reviewCount > 0) {
            keywords.push("feedback")
            keywords.push("waiting")
            keywords.push("review")
          }

          return {
            ...n,
            meta: {
              name: persona.data.displayName,
              keywords
            }
          }
        }),
    [nodes.length]
  )

  const members = useFuse(memberNodes, search, fuseOptions)

  return {
    loading: !node,
    nodes,
    members,
    refetch: node && node.refetch
  }
}

const unique = (nodes, field = "id") => {
  return Object.values(
    nodes.reduce((data, n) => {
      if (!data[n[field]]) data[n[field]] = n
      return data
    }, {})
  )
}

const groupPeopleByGroup = (members) => {
  const sortByCreated = (a, b) => {
    return new Date(a.created) > new Date(b.created) ? -1 : 1
  }

  const sortByType = (a, b) => {
    return a.data.type > b.data.type ? -1 : 1
  }

  const groups = Object.values(
    members.reduce((groups, mem) => {
      const person = mem.from()
      const group = mem.to()

      if (!groups[group.id]) {
        groups[group.id] = {
          ...group,
          label: group.data.name,
          lastLearnerActivity: null,
          lastStaffActivity: null,
          nodes: [],
          parent: group.parent()
        }
      }
      // hmm this is a bit of a hack
      groups[group.id].nodes.push(mem)
      return groups
    }, {})
  )
    .map((group) => {
      return {
        ...group,
        nodes: unique(group.nodes).sort(sortByDate("last_seen_dtime"))
      }
    })
    .sort(sortByCreated)
    .sort(sortByType) // mentor groups first

  return groups
}

const groupPeopleByActivity = (members) => {
  // filter out non-leaner members
  members = members.filter((mem) => mem.data.role <= 5)

  return Object.values(
    members.reduce((days, member) => {
      const peep = member.from()
      const lastSeen = moment(peep.data.last_seen_dtime)
      const label = getLastSeenLabel(lastSeen)
      if (!days[label])
        days[label] = {
          id: lastSeen.toISOString(),
          type: "date",
          label,
          date: lastSeen,
          nodes: []
        }
      days[label].nodes.push(member)
      return days
    }, {})
  )
    .map((group) => {
      return {
        ...group,
        nodes: unique(group.nodes, "fromId").sort(sortByDate("last_seen_dtime"))
      }
    })
    .sort((a, b) => (a.date.isAfter(b.date) ? -1 : 1))
}

const PersonaContainer = styled.div`
  transition: background-color 0.2s ease-out;
  cursor: pointer;
  border-radius: 18px;
  width: 80px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  background-color: ${(p) =>
    p.selected ? "rgba(255,255,255,.25)" : "transparent"};

  transform-origin: top center;

  .OverlayTrigger {
    cursor: pointer;
  }

  button {
    // remove all styles from button
    border: none;
    background: none;
    padding: 3px;
    margin: 0;
    outline: none;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
  }

  h3 {
    margin: 0px;
    margin-top: 4px;
    font-size: 0.8em;
    text-align: center;
  }
`

const PeopleContainer = styled.div`
  padding: 20px;
  width: 100%;

  h3 {
    color: #fff;
  }

  > nav {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    @media (max-width: ${545}px) {
      /* flex-wrap: wrap; */
      .NavControls {
        /* margin-top: 10px; */
        justify-content: center;
        .SearchInput {
          width: 100%;
          position: absolute;
          left: 0px;
          right: 0px;
          top: 0px;
          z-index: 1;
          margin-left: 0px;
          padding-left: 0px;
          padding-right: 0px;
          input {
            background-color: #fff;
          }
        }
        .Button.primary {
          /* order: -1;
          margin-left: auto; */
        }
      }
    }

    .SwitchButton,
    .IconButton {
      flex-shrink: 0;
    }

    .IconButton {
      margin-left: 10px;
    }

    .SearchInput {
      margin-left: 10px;
    }

    .NavControls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
    }

    /* .Button {
      margin-left: auto;
    } */
  }

  > main {
    /* min-height: 610px;  */
    padding: 20px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
  }
`

const PersonaAvatar = ({ node, size = 64, onClick, ...props }) => {
  const { avatar, first_name, last_name } = node.data
  const initials = `${first_name[0]}${last_name[0]}`

  return (
    <div>
      <Avatar
        className="PersonaAvatar"
        userId={parseInt(node.userId)}
        image={avatar}
        initials={initials}
        size={size}
        {...props}
      />
    </div>
  )
}

const Persona = ({ node, selected, onSelect, size, ...props }) => {
  const { first_name, last_name } = node.data

  const tip = `${node.data.displayName} • active ${moment(
    node.data.last_seen_dtime
  ).fromNow()}`

  const reviewCount = node.data.review_count
    ? parseInt(node.data.review_count)
    : 0

  const displayName = `${first_name} ${last_name[0]}`

  const handleSelectReview = (e) => {
    e.preventDefault()
    e.stopPropagation()
    node.selectedTab = "review"
    onSelect(node)
  }

  return (
    <PersonaContainer {...props} selected={selected}>
      <Tooltip title={tip} placement="top">
        <PersonaAvatar size={size} node={node} />
      </Tooltip>

      {reviewCount > 0 ? (
        <button onClick={handleSelectReview}>
          <CountIndicator
            className="ReviewCountIndicator"
            count={reviewCount}
          />
        </button>
      ) : null}
      <h3>{displayName}</h3>
    </PersonaContainer>
  )
}

const PeopleRowContainer = styled.div`
  padding: 10px;
  position: relative;
  left: -10px;
  right: -10px;

  transition: background-color 0.2s ease-out;

  header {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    justify-content: space-between;
    // the group heading(s)
    > div:first-child {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }

    .staff {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: auto;
      padding: 6px 0px;

      > div {
        display: flex;
      }

      > div + div .PersonaAvatar {
        margin-left: 6px;
      }
    }

    h3 {
      display: flex;
      align-items: center;
      font-size: 1.2em;
      margin: 0px;
      padding-bottom: 5px;

      max-width: 80%;
      flex-wrap: wrap;

      a {
        color: #fff;
        display: block;
      }

      > span {
        display: block;
        margin: 0px 2px;
        font-weight: normal;
      }

      &:hover {
        /* text-decoration: underline; */
      }

      .Pathicon {
        top: 1px;
        position: relative;
      }
    }

    h4 {
      text-transform: uppercase;
      font-weight: normal;
      font-size: 0.9em;
      color: rgba(255, 255, 255, 0.6);
      margin: 0px;
    }
  }

  > main {
    // background-color: rgba(255, 255, 255, 0.1);
    border-radius: 18px;
    cursor: pointer;

    .PeopleRow__people {
      padding: 10px 0px;
      display: flex;
      flex-wrap: wrap;

      .PersonaAvatar {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.333);
      }
    }
  }

  margin-bottom: 10px;

  &.selected {
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.25);
  }
`

const PeopleRow = ({ group, selected, onSelect }) => {
  const [page, setPage] = useState(1)
  const isGroupSelected = selected && selected.key === group.key

  const limit = 36
  const { t } = useTranslate()

  let members = group.nodes
    .filter((m) => (group.type === "group" ? m.data.role === 5 : true))
    .slice(0, limit * page)

  const staff = group.nodes.filter((n) => n.data.role !== 5)
  const remaining = group.nodes.length - staff.length - members.length

  const renderGroupType = () => {
    if (group.type !== "group") return null
    return (
      <h4>
        {group.data.type === "cohort"
          ? t("cohort.label")
          : t("mentor_group.label")}
      </h4>
    )
  }

  const renderGroupLabel = () => {
    if (group.parent) {
      const groupLabel =
        group.data.name === group.parent.name
          ? `Cohort #${group.id}`
          : group.data.name

      return (
        <>
          <>
            <a href="#">{group.parent.data.name}</a>
            <span>/</span>
          </>
          <a href="#">{groupLabel}</a>
        </>
      )
    }

    return group.label
  }

  const isSelectable = group.type === "group"

  const handleSelect = () => {
    if (isSelectable) onSelect(group)
  }

  return (
    <PeopleRowContainer className={isGroupSelected ? `selected` : ``}>
      <main style={{ cursor: group.type === "group" ? `pointer` : `default` }}>
        <header>
          <div>
            {renderGroupType()}
            <h3 onClick={handleSelect}>
              {/* {renderGroupIcon()} */}
              {renderGroupLabel()}
              {isSelectable ? <Pathicon inverted icon="chevron-right" /> : null}
            </h3>
          </div>
          {group.type === "group" && staff.length ? (
            <div className="staff">
              {staff.map((node, i) => (
                <PersonaAvatar
                  node={node.from()}
                  size={28}
                  onClick={(e) => {
                    // Disable selecting mentors for now
                    e.preventDefault()
                    //onSelect(node.from())
                  }}
                  key={`${node.id}-${i}`}
                />
              ))}
            </div>
          ) : null}
        </header>
        <div className="PeopleRow__people">
          {members.length === 0 ? (
            <BlankSlate
              inverted
              icons={null}
              // heading="No active members."
              body={
                <>
                  {t(`No one has been recently active in {{group}}.`, {
                    group: group.label
                  })}
                  {/* <Button inverted styleType="text" onClick={handleSelect}>
                    {t(`View Group`)}
                  </Button> */}
                </>
              }
            />
          ) : null}

          {members.map((member, i) => {
            const person = member.from()
            return (
              <Persona
                selected={person === selected}
                onSelect={onSelect}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onSelect(person)
                }}
                key={`${member.id}-${i}`}
                node={person}
              />
            )
          })}

          {remaining > 0 ? (
            <IconButton
              inverted
              icon="ellipses-horizontal"
              label={`+${remaining}`}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setPage((p) => p + 1)
              }}
            />
          ) : null}
        </div>
      </main>
    </PeopleRowContainer>
  )
}

const SelectedContainer = styled.div`
  width: 100%;
  margin-left: 10px;
  position: sticky;
  top: 0px;
  align-self: flex-start;
  width: 50%;
  flex-shrink: 0;
  display: flex;
  /* <-- this is the fix */
  animation: ${shrinkIn} 0.2s ease-out;

  @media (max-width: ${DASHBOARD_MOBILE_WIDTH}px) {
    width: 100%;
    margin-left: 0px;
  }

  > div {
    width: 100%;
  }

  .Selected__close {
    position: absolute;
    right: 40px;
    top: 10px;
    z-index: 100000;
    /* background-color: rgba(0, 0, 0, 0.5); */
    border-radius: 50%;
  }

  .CardStack,
  .CardStack__cards,
  .CardContainer,
  .CardBody {
    /* max-height: 600px; */
  }

  /* NOTE: manually excluding certain cards due to undesirable clipping.
   in the case of .MentorGroupMembershipSettingsCard, we don't want to clip the
   SelctMenu for the role selector (ideally the SelectMenu would be in a popover of some sorts) */
  .Card:not(.MentorGroupMembershipSettingsCard) .CardBody {
    overflow: hidden;
  }

  ${(props) =>
    props.$isSticky
      ? `> div:not(:has(.MentorGroupMembershipSettingsCard)) {
        max-height: 100vh;
        overflow: auto;
      }`
      : ``}
`

const PeopleListContainer = styled.div`
  height: 100%;
  max-width: 100%;
  min-width: 0;
  flex-grow: 1;
`

const filters = {
  show: {
    key: "show",
    label: "Show",
    options: {
      all: {
        label: "All",
        key: "all",
        filter: (m) => true
      },
      feedback: {
        label: "Need feedback",
        key: "feedback",
        visible: (n) => n.from().data.reviewCount > 0,
        filter: (n) => n.from().data.reviewCount > 0
      }
    }
  },
  groupBy: {
    key: "by",
    label: "By",
    options: {
      active: {
        label: "Last active",
        key: "active",
        filter: groupPeopleByActivity
      },
      groups: { label: "Group", key: "groups", filter: groupPeopleByGroup }
    }
  }
}

const applyFilters = (selected, nodes) => {
  const filter = filters.show.options[selected["show"]].filter
  const groupBy = filters.groupBy.options[selected["by"]].filter
  return groupBy(nodes.filter(filter))
}

const defaultFilterState = { show: "all", by: "groups" }

const areas = [
  { key: "groups", label: "Groups", to: "/groups" },
  { key: "active", label: "Active", to: "/active" }
]

const GroupCard = ({ node, onSelect }) => {
  return (
    <CardStack
      card={{
        component: MentorGroupMembersCard,
        props: {
          key: node.id,
          groupId: parseInt(node.id)
        }
      }}
      onCloseCardStack={() => onSelect(null)}
    />
  )
}

const CohortCardContainer = styled.div`
  position: relative;
  .CohortCard__wrapper {
    max-height: auto;
    max-width: 100%;
    /* border-radius: 0px; */
    .CohortCard {
      /* border-radius: 0px; */
      .CohortCard__background {
        /* border-radius: 0px; */
      }
      min-height: 180px;
      padding: 10px;
      h1 {
        font-size: 2em;
        margin-bottom: 10px;
      }
    }
  }

  .IconButton.Close {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 100;
  }
`

const CohortCard = ({ node, onSelect }) => {
  const { data, loading, error } = useCohortQuery({
    variables: { id: parseInt(node.id) }
  })

  if (loading) return <LoadingCircle />
  if (error) return <Error error={error} />

  const { cohort } = data

  return (
    <CohortCardContainer>
      <IconButton
        inverted
        className="Close"
        icon="x"
        onClick={() => onSelect(null)}
      />
      <CohortCardComponent item={cohort} />
      {/* <CommunityContainer
        resourceId={cohort.resource.id}
        cohortId={cohort.id}
      /> */}

      {/* <PreviewPathList cohortId={cohort.id} id={cohort.path.id} /> */}
    </CohortCardContainer>
  )
}

const nodeCards = {
  persona: ({ node, onSelect }) => {
    return (
      <PersonaCard
        node={node}
        selectedTab={node.selectedTab}
        onClose={() => onSelect(null)}
      />
    )
  },
  date: ({ node }) => {
    return (
      <div>
        <h1>{node.label}</h1>
        <h2>{moment(node.date).format()}</h2>
      </div>
    )
  },
  group: ({ node, onSelect }) => {
    switch (node.data.type) {
      case "group":
        return <GroupCard node={node} onSelect={onSelect} />
      case "cohort":
        return <CohortCard node={node} onSelect={onSelect} />
      default:
        return null
    }
  }
}

const NodeBlank = ({ node }) => {
  return (
    <div>
      <pre>{JSON.stringify(node, null, 2)}</pre>
    </div>
  )
}

// Assessing if an element is sticky.
function useIstSticky() {
  const ref = useRef()
  const [isSticky, setIsSticky] = useState(false)
  const { useScroll } = useScrollContext()
  useScroll({
    onScroll: () => {
      setIsSticky(ref.current?.getBoundingClientRect?.()?.top === 0)
    }
  })

  return [ref, isSticky]
}

const NodeCard = ({ node, onSelect }) => {
  const [ref, isSticky] = useIstSticky()

  const renderNode = useCallback(() => {
    if (!node) return null
    const Component = nodeCards[node.type] || NodeBlank
    return <Component node={node} onSelect={onSelect} />
  }, [node])

  return (
    <SelectedContainer ref={ref} $isSticky={isSticky}>
      {renderNode()}
    </SelectedContainer>
  )
}

const CardModalContainer = styled.div`
  max-width: 600px;
  margin: 0px auto;
`

export const getPathname = ({ areaIndex, nodeKey }) => {
  // get people section based on areaIndex
  const section =
    areaIndex === 0
      ? DASHBOARD_SUB_SECTIONS.GROUPS
      : DASHBOARD_SUB_SECTIONS.ACTIVE

  // get queryParamsObj for keys where values are not nullish
  const queryParamsObj = Object.entries({
    id: nodeKey
  }).reduce((obj, [key, value]) => (value ? { ...obj, [key]: value } : obj), {})

  // get queryParams based on nodeKey
  const queryParams = new URLSearchParams(queryParamsObj).toString()

  // base pathname with section
  let pathname = `/home/people/${section}/`
  // optionally add queryParams
  if (queryParams) pathname += `?${queryParams.toString()}`

  return pathname
}

const useSelectedAreaIndex = ({ section, nodeKey }) => {
  // default section is "active"
  const areaIndex =
    !section || section === DASHBOARD_SUB_SECTIONS.GROUPS ? 0 : 1

  // when areaIndex changes, update the route
  const handleAreaIndexChange = (areaIndex) =>
    // TODO: use list of sections indexed appropriately
    window.App && window.App.navigate(getPathname({ areaIndex, nodeKey }))

  return useControlledState(areaIndex, handleAreaIndexChange)
}

const useSelectedNode = ({ areaIndex, nodeKey, nodes }) => {
  const selectedNode = nodeKey
    ? nodes.find((node) => node.key === nodeKey)
    : null

  // when selected node changes, update the route
  const handleSelectedChange = (node) =>
    window.App &&
    window.App.navigate(
      getPathname({
        areaIndex,
        nodeKey: node ? node.key : null
      })
    )

  const [selected, setSelected] = useControlledState(
    selectedNode,
    handleSelectedChange
  )

  // if after members list updates we cannot find a member node corresponding
  // to the selected node, clear out selected (this could be due to a node being
  // removed from codex store due to an archival, for instance)
  useEffect(() => {
    if (selected) {
      // assumption here is that the toId is always used for selected node
      const matchingNode = nodes.find((node) => node.key === selected.key)
      if (!matchingNode) setSelected(null)
    }
  }, [nodes.length])

  return [selected, setSelected]
}

const People = ({ section, nodeKey, search }) => {
  const { width } = useWindowSize()
  const { tca } = useTranslate()
  const [filter] = useState(defaultFilterState)
  const [groups, setGroups] = useState([])
  const { loading, members, nodes } = usePeople({ search })
  const [selectedAreaIndex] = useSelectedAreaIndex({
    section,
    nodeKey
  })

  const [selected, setSelected] = useSelectedNode({
    areaIndex: selectedAreaIndex,
    nodeKey,
    nodes
  })

  const isMobile = width <= DASHBOARD_MOBILE_WIDTH

  const renderSelectedNode = () => {
    if (!selected) return null
    if (isMobile) {
      return (
        <Modal onClose={() => setSelected(null)}>
          <CardModalContainer>
            <NodeCard
              key={selected.key}
              onSelect={handleSelect}
              node={selected}
            />
          </CardModalContainer>
        </Modal>
      )
    } else {
      return (
        <NodeCard key={selected.key} onSelect={handleSelect} node={selected} />
      )
    }
  }

  useEffect(() => {
    if (members.length) {
      const groups = applyFilters(
        {
          show: "all",
          by:
            selectedAreaIndex === 0
              ? DASHBOARD_SUB_SECTIONS.GROUPS
              : DASHBOARD_SUB_SECTIONS.ACTIVE
        },
        members
      )
      setGroups(groups)
    }
  }, [filter, members.length, selectedAreaIndex])

  const handleSelect = (node) => {
    setSelected(node)
  }

  if (loading) return <LoadingCircle inverted />

  return (
    <main>
      {/* <Map nodes={nodes} /> */}
      {members.length === 0 ? (
        <BlankSlate
          inverted
          heading={tca(`No active members.`)}
          body={tca(`Nobody's been active recently.`)}
        />
      ) : (
        <PeopleListContainer
          className="PeopleListContainer"
          collapsed={!!selected}
        >
          {groups.map((group) => {
            return (
              <PeopleRow
                key={group.id}
                onSelect={handleSelect}
                selected={selected}
                group={group}
              />
            )
          })}
        </PeopleListContainer>
      )}
      {renderSelectedNode()}
    </main>
  )
}

function PeopleNav({ section, nodeKey, onSearch }) {
  const { tca, tc } = useTranslate()
  const [showSearch, setShowSearch] = useState(false)
  const [selectedAreaIndex, setSelectedAreaIndex] = useSelectedAreaIndex({
    section,
    nodeKey
  })

  const renderNavItems = () => <ManageNavItem />

  const closeSearch = () => {
    onSearch("")
    setShowSearch(false)
  }

  return (
    <nav>
      <SwitchButton
        options={areas.map((area) => ({ ...area, label: tc(area.label) }))}
        onChange={setSelectedAreaIndex}
        activeIndex={selectedAreaIndex}
      />
      <div className="NavControls">
        {showSearch && (
          <SearchInput
            placeholder={`${tca("Search people")}...`}
            onClose={closeSearch}
            onChange={(e) => onSearch(e.target.value)}
            inverted
          />
        )}
        {renderNavItems()}
        <IconButton
          styleType={!showSearch ? "primary" : "primary-alt"}
          icon="search"
          onClick={() => (showSearch ? closeSearch() : setShowSearch(true))}
        />
      </div>
    </nav>
  )
}

const ConnectedPeople = (props) => {
  const [search, setSearch] = useState("")
  const { school } = usePathwrightContext()
  const firebase = window.firebase

  const url = `${getGraphQLEndpoint()}graphql?school_id=${school.id}`
  const config = {
    url,
    // headers: { school_id: 14513, Authorization: `Token ${USER_TOKEN}` },
    headers: {
      school_id: school.id,
      Authorization: `Token ${localStorage.auth_token}`
    },
    firebase: firebase.app()
  }

  return (
    <ErrorBoundary>
      <PeopleContainer>
        <PeopleNav {...props} onSearch={setSearch} />
        <CodexContextProvider config={config} fallback={<LoadingCircle />}>
          <People {...props} search={search} />
        </CodexContextProvider>
      </PeopleContainer>
    </ErrorBoundary>
  )
}

export default ConnectedPeople
