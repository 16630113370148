import {
  Box,
  HStack,
  Heading,
  Link,
  LinkBox,
  LinkOverlay,
  Text,
  VStack
} from "@chakra-ui/react"
import { MotionValue, motion, useScroll, useTransform } from "framer-motion"
import { useEffect, useMemo, useRef, useState } from "react"
// import { Link as ReactRouterLink } from "react-router-dom"

import { UnifiedHomeRegistrationFragment } from "../../../api/generated"
import Pathicon from "../../../pathicon/Pathicon"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import { BORDER_RADIUS } from "../../constants"
import { getCertificateDownloadUrl, getResourceLink } from "../../utils"
import GroupAvatars from "../GroupAvatars"
import UserAvatar from "../UserAvatar"
import PathCardButton from "./PathCardButton"
import PathCardRegistrationControls from "./PathCardRegistrationControls"

const CARD_BORDER_RADIUS = 25

type PathCardBackgroundProps = {
  resource: string
  scale?: MotionValue<number>
  overlayColor?: MotionValue<string>
}

const MotionLinkBox = motion(LinkBox)

const PathCardBackground = ({
  resource,
  scale,
  overlayColor
}: PathCardBackgroundProps) => {
  return (
    <Box
      pos="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      borderRadius={CARD_BORDER_RADIUS}
      overflow={"hidden"}
    >
      <Box
        as={motion.div}
        style={{ scale }}
        pos="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        backgroundImage={resource}
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        zIndex={-1}
      />
      <Box
        as={motion.div}
        style={{ backgroundColor: overlayColor }}
        pos="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg={"blackAlpha.600"}
        zIndex={-1}
      />
    </Box>
  )
}

const PathCard = ({
  registration
}: {
  registration: UnifiedHomeRegistrationFragment
}) => {
  const cardRef = useRef<HTMLDivElement | null>(null)
  const [cardHeight, setCardHeight] = useState(0)

  const resource = registration.resource!
  // Ensure progress is a number or undefined to avoid type errros
  const progress = registration.path?.progress ?? undefined

  const pwContext = usePathwrightContext()

  const certificateDownloadUrl = useMemo(
    () => getCertificateDownloadUrl({ pwContext, registration }),
    []
  )

  const linkProp = useMemo(() => getResourceLink(registration), [])

  //!intersection observer
  // Measure the card height on component mount
  useEffect(() => {
    if (cardRef.current) {
      setCardHeight(cardRef.current.offsetHeight)
    }
  }, [])

  const scrollOfffset = cardHeight + 180

  //https://www.framer.com/motion/use-scroll/
  const { scrollYProgress } = useScroll({
    target: cardRef,
    // offset: ["start end", "end start"]
    offset: [`start ${scrollOfffset}px`, "end start"]
  })

  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.5])

  const bgOverlay = useTransform(
    scrollYProgress,
    [0, 0.5],
    ["rgba(0, 0, 0, 0.6)", "rgba(0, 0, 0, 0.8)"]
  )

  // Animation variants
  const onHoverContainerVariant = {
    initial: {
      // opacity: 0,
      y: 2
    },
    hover: {
      y: -4,
      transition: {
        type: "spring",
        stiffness: 800,
        damping: 60,
        mass: 1,
        staggerChildren: 0.1
      }
    },
    animate: {
      // opacity: 1,
      y: 0
    }
  }

  const childVariant = {
    animate: { y: 0, opacity: 1 },
    hover: { y: -1 }
  }

  return (
    <MotionLinkBox
      ref={cardRef}
      position={"relative"}
      variants={onHoverContainerVariant}
      initial={"initial"}
      animate={"animate"}
      whileHover={"hover"}
      w="100%"
      h="100%"
      borderRadius={CARD_BORDER_RADIUS}
      pos="relative"
      borderWidth={0.5}
      borderColor={"blackAlpha.200"}
      shadow={"base"}
      _hover={{ shadow: "xl" }}
      zIndex={10}
      // onViewportLeave={() => console.log("left")}
    >
      <PathCardBackground
        resource={resource.cover_image}
        scale={scale}
        overlayColor={bgOverlay}
      />

      {/* {isHovered && ( */}
      {/* <PathCardRegistrationControls registration={registration} /> */}
      {/* )} */}

      <PathCardRegistrationControls registration={registration} />

      <VStack
        // py={3}
        // px={4}
        p={"30px"}
        borderRadius={CARD_BORDER_RADIUS}
        rowGap={1}
        align={"flex-start"}
        justify={"center"}
      >
        <LinkOverlay
          // as={ReactRouterLink}
          href={linkProp.pathURL}
          textDecoration={"none"}
        >
          <motion.div variants={childVariant}>
            <Heading
              size={"h5"}
              color="whiteAlpha.900"
              fontWeight={"bold"}
              my={0}
              mb={1.5}
            >
              {resource.name}
            </Heading>
          </motion.div>

          <motion.div variants={childVariant}>
            {
              //!learner
              registration.role === 5 && <UserAvatar size="xs" showName />
            }
            {
              //!observer
              registration.role === -1 && <UserAvatar size="xs" showName />
            }
            {
              //!moderator
              registration.role === 10 && (
                <GroupAvatars
                  groupId={registration?.group?.id as number}
                  showGroupName
                />
              )
            }
            {
              //!teacher
              registration.role === 15 && (
                <GroupAvatars
                  groupId={registration?.group?.id as number}
                  showGroupName
                />
              )
            }
          </motion.div>
        </LinkOverlay>

        <Box
          as={motion.div}
          variants={childVariant}
          w="100%"
          display={"flex"}
          flexDirection={"row"}
          pt={0.5}
          gap={4}
        >
          {resource && registration.role === 5 && (
            <PathCardButton
              role={"learner"}
              progress={progress}
              href={linkProp.pathURL}
            />
          )}
          {/* {resource && item.role === 15 && (
              <PathCardButton role={"teacher"} progress={progress} />
            )} */}
          {resource && registration.role === 10 && (
            <PathCardButton role={"moderator"} href={linkProp.pathURL} />
          )}
          {registration.role === 20 &&
            registration.resource?.groups?.total! <= 1 && (
              <PathCardButton role={"design_source"} href={linkProp.pathURL} />
            )}

          {registration.group?.is_master && registration.role === 20 && (
            <PathCardButton
              role={"designer_teacher"}
              registration={registration}
            />
          )}
          {/* {resource && registration.role === -1 && ( 
              <PathCardButton role={"observer"} />
            )} */}

          {certificateDownloadUrl && (
            <Link
              py={1}
              px={4}
              borderRadius={BORDER_RADIUS}
              _hover={{
                textDecoration: "none",
                bg: "blackAlpha.600",
                shadow: "md"
              }}
              textDecoration={"none"}
              color={"whiteAlpha.900"}
              href={certificateDownloadUrl}
              download
            >
              <HStack>
                <Pathicon icon="certificate-fill" />
                <Text
                  m={0}
                  fontSize="sm"
                  fontWeight={"bold"}
                  color={"whiteAlpha.900"}
                >
                  View Certificate
                </Text>
              </HStack>
            </Link>
          )}
        </Box>
      </VStack>
    </MotionLinkBox>
  )
}

export default PathCard
