import gql from "graphql-tag"
import { GroupFragmentDoc } from "../../api/generated"

export default gql`
  query GroupsQuery(
    $first: Int!
    $cursor: String
    $resource_id: Int!
    $include_curriculum: Boolean = false
    $parentGroupIdFilter: IDFilter
    $search: String
  ) {
    groups(
      first: $first
      after: $cursor
      resource_id: $resource_id
      include_curriculum: $include_curriculum
      search: $search
      parent_group_id: $parentGroupIdFilter
    ) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...Group
        }
      }
    }
  }
  ${GroupFragmentDoc}
`
