import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import Tip from "../../tips/Tip"
import { Tip as TipType } from "../../tips/types"

const FloatingTip = () => {
  const { me } = usePathwrightContext()

  const pathTip: TipType = {
    id: "pathTip",
    title: `Welcome, ${me.first_name}!`,
    content:
      "Your Home screen is where you can design, take, or teach any learning Path. To get started, add a Path at the bottom or select a suggestion below. <br/> (Need help? Just tap the question icon in the bottom right to talk with a friendly guide)"
  }

  return (
    <Tip
      tip={pathTip}
      icon="lightbulb-on"
      style={{
        backgroundColor: "rgba(255,255,255,0.8)"
      }}
      layout="dismissable"
      // @ts-expect-error
      roundedCorners
    />
  )
}

export default FloatingTip
