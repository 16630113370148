const CardStackCard = ({
  card,
  index,
  isActive,
  isDirty,
  isAnimating,
  prevIsDirty,
  pushCard,
  onClose,
  onCardDirty,
  ...passProps // what goodies are these (likely router props)?
}) => {
  const { component: Component, props: cardProps } = card

  // Card specific props
  const cardProp = {
    pushCard,
    onClose() {
      return onClose(card, index)
    },
    setCardDirty(isDirty) {
      return onCardDirty(isDirty, index)
    },
    isDirty,
    prevIsDirty,
    showChildren: isActive,
    isIndex: index === 0,
    index,
    isActive,
    isAnimating,
    // legacy/client RouteCardStack props, ugh
    path: card.path,
    reversePath: card.reversePath
  }

  return <Component {...passProps} {...(cardProps || {})} card={cardProp} />
}

CardStackCard.displayName = "CardStackCard"

export default CardStackCard
