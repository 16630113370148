import { useMutation } from "@apollo/client"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import FullscreenConfirm from "@pathwright/ui/src/components/confirm/FullscreenConfirm"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import React from "react"
import styled from "styled-components"
import { UserRegistrationsDocument } from "../../api/generated"
import { hasTeacherLevelAccess } from "../../group/permissions"
import { getApolloClient } from "../../pathwright/PathwrightClient"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import DELETE_REGISTRATION_MUTATION from "../../registration/graphql/delete-registration-mutation"
import { canEditLibrary } from "../../user/permissions"
import GroupNotificationsForm from "../teach/item/GroupNotificationsForm"
import { getCertificateDownloadUrl } from "./utils"

const StyledContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 10px;
  color: #aaa;
`

export const RegistrationConfirmRemove = ({
  cohort,
  registration,
  children
}) => {
  const { t } = useTranslate()
  const { school } = usePathwrightContext()
  const [deleteRegistration, deleteRegistrationMutation] = useMutation(
    DELETE_REGISTRATION_MUTATION,
    {
      variables: {
        group_id: cohort.id,
        registration_id: registration.id
      }
    }
  )

  const updateCache = () => {
    const cache = getApolloClient(school.id)
    const query = UserRegistrationsDocument
    const data = cache.readQuery({ query })
    const nextData = {
      me: {
        ...data.me,
        registrations: {
          ...data.me.registrations,
          edges: data.me.registrations.edges.filter(
            (edge) => edge.node.id !== registration.id
          )
        }
      }
    }
    cache.writeQuery({ query, data: nextData })
  }

  return (
    <FullscreenConfirm
      icon="caution-triangle"
      heading={t("Before you leave...")}
      body={t(
        "Leaving this cohort will delete your completion progress as well as any work you've posted as a learner."
      )}
      cancelPrompt={t("Nevermind, go back")}
      confirmPrompt={t("Yes, leave this cohort")}
      // confirmingStatus="Automatically turning off access..."
      confirmedHeading={t("You're removed")}
      confirmedBody={t(
        "You're no longer enrolled in this cohort but can restart it anytime."
      )}
      onConfirm={() => deleteRegistration()}
      onConfirmed={() => updateCache()}
      stack
    >
      {children}
    </FullscreenConfirm>
  )
}

const RegistrationControls = function ({ registration }) {
  const { t } = useTranslate()
  const { resource, group } = registration
  const pwContext = usePathwrightContext()

  const certificateDownloadUrl = React.useMemo(
    () => getCertificateDownloadUrl(pwContext, registration),
    []
  )

  return (
    <RegistrationConfirmRemove cohort={group} registration={registration}>
      {({ confirm, confirming }) => (
        <StyledContainer className="RegistrationControls">
          <DropdownTrigger
            preventHide={confirming}
            renderDropdown={() => {
              return (
                <DropdownMenu>
                  {/* for admins to remove themselves form a cohort */}
                  {canEditLibrary(pwContext) && (
                    <DropdownItem
                      icon="x-circle"
                      onClick={confirm}
                      label="Leave cohort"
                      data-testid="leave-cohort"
                    />
                  )}
                  <GroupNotificationsForm registration={registration} />
                  {hasTeacherLevelAccess(pwContext, {
                    // hack arg wrangling
                    ...group,
                    registration
                  }) &&
                    false && (
                      <DropdownItem
                        icon="gear"
                        to={`/manage/group/${resource.id}/${group.id}/`}
                        label="Edit cohort settings"
                        closeOnClick={true}
                      />
                    )}
                  {canEditLibrary(pwContext) && false && (
                    <DropdownItem
                      icon="gear"
                      to={`/manage/resource/${resource.id}/`}
                      label="Edit Path settings"
                      closeOnClick={true}
                    />
                  )}
                  {certificateDownloadUrl && (
                    <DropdownItem
                      icon="arrow-down"
                      href={certificateDownloadUrl}
                      download
                      label={t("Download certificate")}
                      closeOnClick={true}
                    />
                  )}
                </DropdownMenu>
              )
            }}
          >
            <IconButton
              icon="ellipses-horizontal"
              iconProps={{ style: { color: `rgba(1,1,1, .5)` } }}
            />
          </DropdownTrigger>
        </StyledContainer>
      )}
    </RegistrationConfirmRemove>
  )
}

RegistrationControls.displayName = "RegistrationControls"

export default RegistrationControls
