//Styles
export const BORDER_RADIUS = "2xl"

//Animations

export const tiltAnimation = {
  x: 10,
  rotate: [0, 8],
  y: -5,
  zIndex: 1000,
  scale: 1.5,
  transition: {
    duration: 0.2,
    type: "spring",
    stiffness: 1000,
    damping: 50,
    mass: 0.3
  }
}

export const shakeAnimation = {
  x: [0, -5, 5, -5, 5, 0],
  transition: { duration: 0.3, type: "spring", stiffness: 1000, damping: 50 }
}

export const cardContainerAnimation = {
  initial: {
    y: 0
  },
  animate: {
    y: -3,
    transition: {
      type: "spring",
      stiffness: 500,
      damping: 60,
      mass: 1
    }
  }
}
