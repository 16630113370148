import gql from "graphql-tag"
import { SchoolSubscriptionFragmentDoc } from "../../api/generated"

export default gql`
  mutation ($for_user: ID, $cancel_immediately: Boolean) {
    cancelSchoolSubscription(
      for_user: $for_user
      cancel_immediately: $cancel_immediately
    ) {
      ...SchoolSubscription
    }
  }
  ${SchoolSubscriptionFragmentDoc}
`
