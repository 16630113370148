import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never
    }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  AppPeopleSelectionKey: { input: any; output: any }
  DateTime: { input: any; output: any }
  EmailAddress: { input: any; output: any }
  JSON: { input: any; output: any }
  JWT: { input: any; output: any }
  NonEmptyString: { input: any; output: any }
  URL: { input: any; output: any }
  UUID: { input: any; output: any }
}

/** Result of updating a completion via API */
export type ApiCompletionUpdate = {
  __typename?: "APICompletionUpdate"
  completion_id?: Maybe<Scalars["Int"]["output"]>
  /** Reason, if any, for why the update was ignored. */
  ignored_reason?: Maybe<ApiCompletionUpdateIgnoredReason>
  new_path_progress?: Maybe<Scalars["Float"]["output"]>
  new_points_assigned?: Maybe<Scalars["Float"]["output"]>
  new_step_progress?: Maybe<Scalars["Float"]["output"]>
  prev_path_progress?: Maybe<Scalars["Float"]["output"]>
  prev_points_assigned?: Maybe<Scalars["Float"]["output"]>
  prev_step_progress?: Maybe<Scalars["Float"]["output"]>
  success?: Maybe<Scalars["Boolean"]["output"]>
}

export enum ApiCompletionUpdateAction {
  Complete = "complete",
  Reset = "reset",
  Skip = "skip"
}

export enum ApiCompletionUpdateIgnoredReason {
  /** Completion has already been completed and no there was points change. */
  AlreadyCompleted = "already_completed",
  /** Completion has already been reset. */
  AlreadyReset = "already_reset",
  /** Completion has already been skipped. */
  AlreadySkipped = "already_skipped"
}

/** The context of a Pathwright 'session' in a school. */
export type AccountContext = {
  __typename?: "AccountContext"
  membership?: Maybe<Membership>
  permissions: ContextPermissions
  school: School
  theme?: Maybe<Theme>
  user?: Maybe<User>
}

export enum ActionType {
  CanceledPausedSubscription = "canceled_paused_subscription",
  CanceledSubscription = "canceled_subscription",
  CompleteCourse = "complete_course",
  CompleteStep = "complete_step",
  CreateDiscussion = "create_discussion",
  CreateGroup = "create_group",
  CreateMembership = "create_membership",
  CreateRegistration = "create_registration",
  CreateResponse = "create_response",
  CreateSubscription = "create_subscription",
  PausedSubscription = "paused_subscription",
  SkipStep = "skip_step",
  UnpausedSubscription = "unpaused_subscription"
}

export type ActionTypeFilter = {
  eq?: InputMaybe<ActionType>
  gt?: InputMaybe<ActionType>
  gte?: InputMaybe<ActionType>
  in?: InputMaybe<Array<InputMaybe<ActionType>>>
  lt?: InputMaybe<ActionType>
  lte?: InputMaybe<ActionType>
  neq?: InputMaybe<ActionType>
  nin?: InputMaybe<Array<InputMaybe<ActionType>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright Activity */
export type Activity = {
  __typename?: "Activity"
  action?: Maybe<ActionType>
  action_dtime?: Maybe<Scalars["DateTime"]["output"]>
  action_type?: Maybe<Scalars["Int"]["output"]>
  discussion?: Maybe<Discussion>
  discussion_response?: Maybe<Response>
  group?: Maybe<Group>
  id?: Maybe<Scalars["Int"]["output"]>
  interest_level?: Maybe<Scalars["Int"]["output"]>
  offering_id?: Maybe<Scalars["Int"]["output"]>
  registration?: Maybe<Registration>
  resource?: Maybe<Resource>
  step?: Maybe<PathItem>
  triggered_by?: Maybe<User>
  user?: Maybe<User>
}

/** A connection to a list of items. */
export type ActivityConnection = {
  __typename?: "ActivityConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ActivityEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type ActivityEdge = {
  __typename?: "ActivityEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Activity>
}

export type ActivityStats = {
  __typename?: "ActivityStats"
  discussion_responses?: Maybe<Scalars["Int"]["output"]>
  discussions?: Maybe<Scalars["Int"]["output"]>
  registrations?: Maybe<Scalars["Int"]["output"]>
  steps_completed?: Maybe<Scalars["Int"]["output"]>
  /** @deprecated `total` no longer provided. Insteaed, query the `total` on activity. */
  total?: Maybe<Scalars["Int"]["output"]>
}

export type AddPeopleSelection = {
  add_to: Array<InputMaybe<AddPeopleSelectionTo>>
  key: Scalars["AppPeopleSelectionKey"]["input"]
  type: AddPeopleSelectionType
}

export type AddPeopleSelectionTo = {
  key: Scalars["String"]["input"]
  role: AddPeopleSelectionToRole
  type: AddPeopleSelectionToType
}

export enum AddPeopleSelectionToRole {
  CohortCourseEditor = "cohort_course_editor",
  CohortLearner = "cohort_learner",
  CohortModerator = "cohort_moderator",
  CohortObserver = "cohort_observer",
  CohortTeacher = "cohort_teacher",
  GroupAdmin = "group_admin",
  GroupMember = "group_member",
  GroupModerator = "group_moderator",
  SchoolAdmin = "school_admin",
  SchoolMember = "school_member",
  SchoolStaff = "school_staff"
}

export enum AddPeopleSelectionToType {
  Cohort = "cohort",
  Group = "group"
}

export enum AddPeopleSelectionType {
  Cohort = "cohort",
  Email = "email",
  Group = "group",
  User = "user"
}

export type AssignConfig = {
  target_role?: InputMaybe<RegistrationRoleEnum>
}

/** Methods for authenticating a user in a space */
export type AuthMethod = {
  __typename?: "AuthMethod"
  /** Whether this auth method is enabled for the Space */
  enabled: Scalars["Boolean"]["output"]
  id: Scalars["String"]["output"]
  meta: NodeMeta
}

/** A request made by the user to authenticate by some other means than username/password. */
export type AuthRequest = {
  __typename?: "AuthRequest"
  /** How many times this request has attempted confirmation, for preventing brute-force attacks. */
  confirmation_attempts: Scalars["Int"]["output"]
  /** When the authentication request was confirmed and an auth token was issued. */
  confirmed_dtime?: Maybe<Scalars["DateTime"]["output"]>
  /** A place for storing extra information about the request. May include information such as the user's IP address, invite tokens, etc. */
  context?: Maybe<Scalars["JSON"]["output"]>
  /** If set, after this time, the authentication request will be considered invalid. */
  expiration_dtime?: Maybe<Scalars["DateTime"]["output"]>
  /** If this authentication request was successful, this is the auth token that was issued. */
  issued_auth_token?: Maybe<JsonWebToken>
  /** When the authentication request was initially started. */
  request_dtime: Scalars["DateTime"]["output"]
  /** A 'secret key' that must be provided by the client in order to confirm the request. Always null as this key cannot be exposed. */
  request_key?: Maybe<Scalars["String"]["output"]>
  /** The authentication method being used, such as an Email-based OTP (magic link). */
  request_method: AuthRequestMethod
  school?: Maybe<School>
  truncated_email?: Maybe<Scalars["String"]["output"]>
  /** A customer-viewable ID for the authentication request. Must be used for all API calls to prevent sequentially enumerating auth requests. */
  uuid: Scalars["UUID"]["output"]
}

export enum AuthRequestMethod {
  EmailOtp = "email_otp"
}

/** A Pathwright Author */
export type Author = {
  __typename?: "Author"
  bio?: Maybe<Scalars["String"]["output"]>
  display_name?: Maybe<Scalars["String"]["output"]>
  first_name?: Maybe<Scalars["String"]["output"]>
  full_name?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  image?: Maybe<Scalars["String"]["output"]>
  last_name?: Maybe<Scalars["String"]["output"]>
  meta: NodeMeta
  order?: Maybe<Scalars["Int"]["output"]>
  salutation?: Maybe<Scalars["String"]["output"]>
  slug?: Maybe<Scalars["String"]["output"]>
}

/** A Pathwright Author */
export type AuthorImageArgs = {
  balph?: InputMaybe<Scalars["Int"]["input"]>
  blend?: InputMaybe<Scalars["Int"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  bm?: InputMaybe<Scalars["String"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  fm?: InputMaybe<Scalars["String"]["input"]>
  height?: InputMaybe<Scalars["Int"]["input"]>
  high?: InputMaybe<Scalars["Int"]["input"]>
  q?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** A connection to a list of items. */
export type AuthorConnection = {
  __typename?: "AuthorConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AuthorEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type AuthorEdge = {
  __typename?: "AuthorEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Author>
}

/** A background task. */
export type BackgroundTask = {
  __typename?: "BackgroundTask"
  id: Scalars["UUID"]["output"]
  result?: Maybe<Scalars["JSON"]["output"]>
  status: BackgroundTaskStatus
}

export enum BackgroundTaskStatus {
  Failure = "failure",
  Pending = "pending",
  Retry = "retry",
  Revoked = "revoked",
  Started = "started",
  Success = "success"
}

/** A Pathwright Banner */
export type Banner = {
  __typename?: "Banner"
  background_image?: Maybe<Scalars["String"]["output"]>
  background_image_raw?: Maybe<Scalars["String"]["output"]>
  background_overlay?: Maybe<Scalars["String"]["output"]>
  background_type?: Maybe<Scalars["Int"]["output"]>
  button_func?: Maybe<Scalars["String"]["output"]>
  button_text?: Maybe<Scalars["String"]["output"]>
  button_url?: Maybe<Scalars["String"]["output"]>
  font_color?: Maybe<Scalars["String"]["output"]>
  heading?: Maybe<Scalars["String"]["output"]>
  hide_for_products?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>
  hide_for_subscription_plans?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>
  id?: Maybe<Scalars["Int"]["output"]>
  is_visible?: Maybe<Scalars["Boolean"]["output"]>
  order?: Maybe<Scalars["Int"]["output"]>
  readability?: Maybe<Scalars["Boolean"]["output"]>
  show_for_products?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>
  show_for_subscription_plans?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>
  subhead?: Maybe<Scalars["String"]["output"]>
}

/** A Pathwright Banner */
export type BannerBackground_ImageArgs = {
  balph?: InputMaybe<Scalars["Int"]["input"]>
  blend?: InputMaybe<Scalars["Int"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  bm?: InputMaybe<Scalars["String"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  fm?: InputMaybe<Scalars["String"]["input"]>
  height?: InputMaybe<Scalars["Int"]["input"]>
  high?: InputMaybe<Scalars["Int"]["input"]>
  q?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** A connection to a list of items. */
export type BannerConnection = {
  __typename?: "BannerConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BannerEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type BannerEdge = {
  __typename?: "BannerEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Banner>
}

export type BatchMentorGroupMembershipResult = {
  __typename?: "BatchMentorGroupMembershipResult"
  group_memberships_created_count?: Maybe<Scalars["Int"]["output"]>
  success?: Maybe<Scalars["Boolean"]["output"]>
}

export type BlocksContent = {
  __typename?: "BlocksContent"
  content_last_modified?: Maybe<Scalars["DateTime"]["output"]>
  created?: Maybe<Scalars["DateTime"]["output"]>
  created_by_id?: Maybe<Scalars["Int"]["output"]>
  data?: Maybe<Scalars["JSON"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  last_published?: Maybe<Scalars["DateTime"]["output"]>
  method?: Maybe<Scalars["String"]["output"]>
  modified?: Maybe<Scalars["DateTime"]["output"]>
  modified_by_id?: Maybe<Scalars["Int"]["output"]>
  type?: Maybe<Scalars["String"]["output"]>
}

export type Calendar = {
  __typename?: "Calendar"
  events?: Maybe<Array<Maybe<CalendarEvent>>>
  id?: Maybe<Scalars["ID"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  timezone?: Maybe<Scalars["String"]["output"]>
  url?: Maybe<Scalars["String"]["output"]>
}

export type CalendarEvent = {
  __typename?: "CalendarEvent"
  completion_status?: Maybe<Scalars["String"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  end?: Maybe<Scalars["DateTime"]["output"]>
  ical_url?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["ID"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  relative_url?: Maybe<Scalars["String"]["output"]>
  start?: Maybe<Scalars["DateTime"]["output"]>
  summary?: Maybe<Scalars["String"]["output"]>
  type?: Maybe<Scalars["String"]["output"]>
  url?: Maybe<Scalars["String"]["output"]>
}

export type CalendarStats = {
  __typename?: "CalendarStats"
  due?: Maybe<Scalars["Int"]["output"]>
  late?: Maybe<Scalars["Int"]["output"]>
  total?: Maybe<Scalars["Int"]["output"]>
  upcoming?: Maybe<Scalars["Int"]["output"]>
}

/** A Course Category */
export type Category = {
  __typename?: "Category"
  description?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  order?: Maybe<Scalars["Int"]["output"]>
  slug?: Maybe<Scalars["String"]["output"]>
}

/** A connection to a list of items. */
export type CategoryConnection = {
  __typename?: "CategoryConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CategoryEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type CategoryEdge = {
  __typename?: "CategoryEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Category>
}

export type Certificate = {
  __typename?: "Certificate"
  created_time?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  /** Whether the certificate is currently active. */
  is_active?: Maybe<Scalars["Boolean"]["output"]>
  /** Name of certificate for visual identification. */
  name?: Maybe<Scalars["String"]["output"]>
  school?: Maybe<School>
  /** The PDF template URL. */
  template?: Maybe<Scalars["String"]["output"]>
  /** List of text objects to be merged with PDF template. */
  text_objects?: Maybe<Scalars["JSON"]["output"]>
}

/** A connection to a list of items. */
export type CertificateConnection = {
  __typename?: "CertificateConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CertificateEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type CertificateEdge = {
  __typename?: "CertificateEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Certificate>
}

export type CertificateVariables = {
  __typename?: "CertificateVariables"
  id?: Maybe<Scalars["Int"]["output"]>
  /** Key/value pairs of variable paths and the number of times they are used by all courses. */
  stats?: Maybe<Scalars["JSON"]["output"]>
  /** Key/value pairs of variable paths and their values. Only applicable when scoping to a resource. */
  variables?: Maybe<Scalars["JSON"]["output"]>
}

export type CertificateVariablesContextInput = {
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type CertificateVariablesMergeInput = {
  /** Old name of a key that should be transitioned to new_key. */
  current_key?: InputMaybe<Scalars["String"]["input"]>
  /** New name of a key that should be transitioned from current_key. Omitting this arg will result in deleting current_key. */
  new_key?: InputMaybe<Scalars["String"]["input"]>
  /** New value of the new_key. Omitting this arg will result in the current value of current_key to be used by default. */
  new_value?: InputMaybe<Scalars["String"]["input"]>
}

export type Codex = {
  __typename?: "Codex"
  config?: Maybe<CodexConfig>
  space?: Maybe<CodexSpace>
}

export type CodexSpaceArgs = {
  join?: InputMaybe<Scalars["Boolean"]["input"]>
  key: Scalars["String"]["input"]
}

export type CodexAccount = {
  __typename?: "CodexAccount"
  id: Scalars["ID"]["output"]
  name?: Maybe<Scalars["String"]["output"]>
}

export type CodexConfig = {
  __typename?: "CodexConfig"
  account?: Maybe<CodexAccount>
  firebase?: Maybe<FirebaseConfig>
  user?: Maybe<CodexUser>
}

export type CodexNode = {
  __typename?: "CodexNode"
  created?: Maybe<Scalars["DateTime"]["output"]>
  data: Scalars["JSON"]["output"]
  fromId?: Maybe<Scalars["ID"]["output"]>
  graphId?: Maybe<Scalars["ID"]["output"]>
  id: Scalars["ID"]["output"]
  key?: Maybe<Scalars["String"]["output"]>
  parentId?: Maybe<Scalars["ID"]["output"]>
  rootId?: Maybe<Scalars["ID"]["output"]>
  toId?: Maybe<Scalars["ID"]["output"]>
  type: Scalars["String"]["output"]
  updated?: Maybe<Scalars["DateTime"]["output"]>
  userId?: Maybe<Scalars["ID"]["output"]>
}

/** A publish to Paths (codex data structure) */
export type CodexPathPublish = {
  __typename?: "CodexPathPublish"
  node?: Maybe<PathsCodexNode>
}

export type CodexSpace = {
  __typename?: "CodexSpace"
  id: Scalars["ID"]["output"]
  membership?: Maybe<CodexNode>
  nodes?: Maybe<Array<Maybe<CodexNode>>>
}

export type CodexSpaceNodesArgs = {
  last_x_days?: InputMaybe<Scalars["Int"]["input"]>
  type?: InputMaybe<Scalars["String"]["input"]>
}

export type CodexUser = {
  __typename?: "CodexUser"
  authToken: Scalars["String"]["output"]
  displayName: Scalars["String"]["output"]
  id: Scalars["ID"]["output"]
  image?: Maybe<Scalars["String"]["output"]>
}

/** Whether user can use Cohort share UI. */
export type CohortShareAccess = {
  __typename?: "CohortShareAccess"
  /** A user's access level to share UI. */
  access?: Maybe<ShareAccessPermission>
  /** When share UI is gated, this will be the reason for why. */
  gated_reason?: Maybe<CohortShareAccessGatedReasonType>
}

export enum CohortShareAccessGatedReasonType {
  CohortCompleted = "COHORT_COMPLETED",
  CohortEnded = "COHORT_ENDED",
  CohortLicensedSeatsFilled = "COHORT_LICENSED_SEATS_FILLED",
  CohortRegistrationEnded = "COHORT_REGISTRATION_ENDED",
  CohortSeatsFilled = "COHORT_SEATS_FILLED"
}

/** Whether user can use Cohort share > add UI. */
export type CohortShareAddAccess = {
  __typename?: "CohortShareAddAccess"
  /** A user's access level to share > add UI. */
  access?: Maybe<ShareAccessPermission>
  /** When share > add UI is gated, this will be the reason for why. */
  gated_reason?: Maybe<CohortShareAddAccessGatedReasonType>
}

export enum CohortShareAddAccessGatedReasonType {
  SchoolInactive = "SCHOOL_INACTIVE"
}

/** Whether user can use Cohort share > link UI. */
export type CohortShareLinkAccess = {
  __typename?: "CohortShareLinkAccess"
  /** A user's access level to share > link UI. */
  access?: Maybe<ShareAccessPermission>
  /** When share > link UI is gated, this will be the reason for why. */
  gated_reason?: Maybe<CohortShareLinkAccessGatedReasonType>
}

export enum CohortShareLinkAccessGatedReasonType {
  SchoolInactive = "SCHOOL_INACTIVE"
}

/** Whether user can use Cohort share > track UI. */
export type CohortShareTrackAccess = {
  __typename?: "CohortShareTrackAccess"
  /** A user's access level to share > track UI. */
  access?: Maybe<ShareAccessPermission>
  /** When share > track UI is gated, this will be the reason for why. */
  gated_reason?: Maybe<CohortShareTrackAccessGatedReasonType>
}

export enum CohortShareTrackAccessGatedReasonType {
  Placeholder = "PLACEHOLDER"
}

export type CohortsStatusMeta = {
  __typename?: "CohortsStatusMeta"
  completed?: Maybe<Scalars["Int"]["output"]>
  in_progress?: Maybe<Scalars["Int"]["output"]>
}

/** Computed fields on path item */
export type Completion = {
  __typename?: "Completion"
  checked_by?: Maybe<User>
  checked_by_id?: Maybe<Scalars["Int"]["output"]>
  checked_date?: Maybe<Scalars["DateTime"]["output"]>
  completion_attempts?: Maybe<Scalars["Int"]["output"]>
  completion_comment?: Maybe<Scalars["String"]["output"]>
  completion_date?: Maybe<Scalars["DateTime"]["output"]>
  completion_due?: Maybe<Scalars["DateTime"]["output"]>
  feedback_discussion_context?: Maybe<CompletionFeedbackDiscussionContext>
  grading_type?: Maybe<Scalars["String"]["output"]>
  has_failed_passing_attempt?: Maybe<Scalars["Boolean"]["output"]>
  has_points?: Maybe<Scalars["Boolean"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  is_auto_graded?: Maybe<Scalars["Boolean"]["output"]>
  is_complete?: Maybe<Scalars["Boolean"]["output"]>
  is_forced_completion?: Maybe<Scalars["Boolean"]["output"]>
  is_graded?: Maybe<Scalars["Boolean"]["output"]>
  is_late?: Maybe<Scalars["Boolean"]["output"]>
  is_skipped?: Maybe<Scalars["Boolean"]["output"]>
  last_completion_attempt_date?: Maybe<Scalars["DateTime"]["output"]>
  minimum_passing_score?: Maybe<Scalars["Int"]["output"]>
  needs_grading?: Maybe<Scalars["Boolean"]["output"]>
  overridden_by?: Maybe<User>
  overridden_by_api?: Maybe<Scalars["Boolean"]["output"]>
  requires_api_completion?: Maybe<Scalars["Boolean"]["output"]>
  /** Legacy content (only applicable to Path Items using legacy content, pre-dating Blocks). */
  submission?: Maybe<UserSubmission>
  user?: Maybe<User>
  userpoints_earned?: Maybe<Scalars["Int"]["output"]>
  userpoints_explanation?: Maybe<Scalars["String"]["output"]>
  userpoints_value?: Maybe<Scalars["Int"]["output"]>
}

/** Link to the feedback discussion context for a completion */
export type CompletionFeedbackDiscussionContext = {
  __typename?: "CompletionFeedbackDiscussionContext"
  discussion_id?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  num_responses?: Maybe<Scalars["Int"]["output"]>
}

export enum CompletionStateFilter {
  Completed = "completed",
  InProgress = "in_progress",
  Unstarted = "unstarted"
}

/** Computed discount values for an enrollment */
export type ComputedDiscount = {
  __typename?: "ComputedDiscount"
  discount_amount?: Maybe<Scalars["Float"]["output"]>
  discount_percent?: Maybe<Scalars["Float"]["output"]>
  discounted_total?: Maybe<Scalars["Float"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  max_seat_discount?: Maybe<Scalars["Int"]["output"]>
}

export type ContextModelInput = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  path_item_id?: InputMaybe<Scalars["Int"]["input"]>
  path_item_source_id?: InputMaybe<Scalars["Int"]["input"]>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type ContextPermissions = {
  __typename?: "ContextPermissions"
  can_administrate?: Maybe<Scalars["Boolean"]["output"]>
  can_edit_library?: Maybe<Scalars["Boolean"]["output"]>
  has_member_access?: Maybe<Scalars["Boolean"]["output"]>
  has_pathwright_staff_access?: Maybe<Scalars["Boolean"]["output"]>
  is_school_owner?: Maybe<Scalars["Boolean"]["output"]>
}

/** Contextual permissions for a PathwrightFeature */
export type ContextualFeaturePermission = {
  __typename?: "ContextualFeaturePermission"
  cohort_id?: Maybe<Scalars["Int"]["output"]>
  feature_action?: Maybe<FeatureActionType>
  feature_key?: Maybe<FeatureKeyType>
  id?: Maybe<Scalars["Int"]["output"]>
  permission_in_context?: Maybe<FeaturePermissionType>
  resource_id?: Maybe<Scalars["Int"]["output"]>
  school_id?: Maybe<Scalars["Int"]["output"]>
}

/** A Coupon applicable to Paths or */
export type Coupon = {
  __typename?: "Coupon"
  /** If True, this coupon applies to all courses. */
  allCourses: Scalars["Boolean"]["output"]
  /** The unique code used to redeem this coupon. */
  code: Scalars["String"]["output"]
  /** Percent in the range of 0...1. */
  discountPercent: Scalars["Int"]["output"]
  /** Subscriptions plans billing cycles can have varying intervals (weeks, months, years, etc). This determines how many cycles are being discounted. */
  discountedSubscriptionCycles?: Maybe<Scalars["Int"]["output"]>
  /** Coupon can't be used after this date. */
  endDtime?: Maybe<Scalars["DateTime"]["output"]>
  /** The ID of the Coupon. */
  id: Scalars["Int"]["output"]
  /** If True, this can only be redeemed once per user. */
  isOnePerUser: Scalars["Boolean"]["output"]
  /** The max # of times this coupon can be used. */
  maxNumRedemptions?: Maybe<Scalars["Int"]["output"]>
  /** Maximum number of seats that the discount percent can be applied to. */
  maxSeatDiscount: Scalars["Int"]["output"]
  /** Coupon can't be used before this date. */
  startDtime?: Maybe<Scalars["DateTime"]["output"]>
}

/** A coupon discount applicable to a course or school subscription plan */
export type CouponDiscount = {
  __typename?: "CouponDiscount"
  /** The total before discount */
  amount?: Maybe<Scalars["Float"]["output"]>
  code?: Maybe<Scalars["String"]["output"]>
  /** How much the user will save */
  discount_amount?: Maybe<Scalars["Float"]["output"]>
  discount_percent?: Maybe<Scalars["Float"]["output"]>
  discounted_subscription_cycles?: Maybe<Scalars["Int"]["output"]>
  /** How much the user will pay */
  discounted_total?: Maybe<Scalars["Float"]["output"]>
  end_date?: Maybe<Scalars["DateTime"]["output"]>
  has_ended?: Maybe<Scalars["Boolean"]["output"]>
  has_started?: Maybe<Scalars["Boolean"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  is_one_per_user?: Maybe<Scalars["Boolean"]["output"]>
  max_num_redemptions?: Maybe<Scalars["Int"]["output"]>
  max_seat_discount?: Maybe<Scalars["Int"]["output"]>
  num_redemptions?: Maybe<Scalars["Int"]["output"]>
  start_date?: Maybe<Scalars["DateTime"]["output"]>
  user_can_redeem?: Maybe<Scalars["Boolean"]["output"]>
  user_redeemed?: Maybe<Scalars["Boolean"]["output"]>
}

/** The record of a Coupon redemption. */
export type CouponRedemption = {
  __typename?: "CouponRedemption"
  /** The Coupon that was redeemed. */
  coupon: Coupon
  /** The ID of the CouponRedemption. */
  id: Scalars["Int"]["output"]
  /** The time at which the coupon was redeemed. */
  redeemedDtime: Scalars["DateTime"]["output"]
}

export type CreateInvitationsSuccess = {
  __typename?: "CreateInvitationsSuccess"
  invitations_attempted_count?: Maybe<Scalars["Int"]["output"]>
  invitations_sent_count?: Maybe<Scalars["Int"]["output"]>
  /** An array of unsuccessful invitations containing email, role, type, and error. */
  unsuccessful_invitations?: Maybe<Scalars["JSON"]["output"]>
}

/** Curriculum license order data */
export type CurriculumLicenseOrderData = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  license_offering_id: Scalars["Int"]["input"]
  licensee_school_id: Scalars["Int"]["input"]
  licensor_school_id: Scalars["Int"]["input"]
  name?: InputMaybe<Scalars["String"]["input"]>
  role?: InputMaybe<RegistrationRoleEnum>
  seat_count?: InputMaybe<Scalars["Int"]["input"]>
}

/** A school licensing school curriculum subscription */
export type CurriculumSubscription = {
  __typename?: "CurriculumSubscription"
  curriculum_plan?: Maybe<SchoolCurriculumPlan>
  id?: Maybe<Scalars["Int"]["output"]>
  licensee?: Maybe<School>
  licensor?: Maybe<School>
  subscription?: Maybe<Subscription>
}

/** A connection to a list of items. */
export type CurriculumSubscriptionConnection = {
  __typename?: "CurriculumSubscriptionConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CurriculumSubscriptionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type CurriculumSubscriptionEdge = {
  __typename?: "CurriculumSubscriptionEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<CurriculumSubscription>
}

/** Curriculum subscription order data */
export type CurriculumSubscriptionOrderData = {
  curriculum_plan_id: Scalars["Int"]["input"]
  licensee_id: Scalars["Int"]["input"]
  plan_visibility_code?: InputMaybe<Scalars["String"]["input"]>
}

export enum CurriculumType {
  /** The source curriculum for this group (what the teacher edits) */
  Source = "source",
  /** The current logged in user's curriculum */
  User = "user"
}

export type DateFilter = {
  eq?: InputMaybe<Scalars["DateTime"]["input"]>
  gt?: InputMaybe<Scalars["DateTime"]["input"]>
  gte?: InputMaybe<Scalars["DateTime"]["input"]>
  in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>
  lt?: InputMaybe<Scalars["DateTime"]["input"]>
  lte?: InputMaybe<Scalars["DateTime"]["input"]>
  neq?: InputMaybe<Scalars["DateTime"]["input"]>
  nin?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export type DeferOrExecuteResourceCopyConfigResult = {
  __typename?: "DeferOrExecuteResourceCopyConfigResult"
  defer: Scalars["JSON"]["output"]
  execute: Scalars["JSON"]["output"]
}

/** Pathwright discussion */
export type Discussion = {
  __typename?: "Discussion"
  allow_replies?: Maybe<Scalars["Boolean"]["output"]>
  body?: Maybe<Scalars["String"]["output"]>
  cohort?: Maybe<Group>
  cohort_id?: Maybe<Scalars["Int"]["output"]>
  context_id?: Maybe<Scalars["Int"]["output"]>
  created_time?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  is_discussion_question?: Maybe<Scalars["Boolean"]["output"]>
  last_activity_time?: Maybe<Scalars["DateTime"]["output"]>
  likes?: Maybe<Scalars["Int"]["output"]>
  modified_time?: Maybe<Scalars["DateTime"]["output"]>
  order?: Maybe<Scalars["Int"]["output"]>
  path_item?: Maybe<PathItem>
  /** @deprecated No longer used. */
  path_item_name?: Maybe<Scalars["String"]["output"]>
  resource?: Maybe<Resource>
  resource_id?: Maybe<Scalars["Int"]["output"]>
  response?: Maybe<Response>
  responses?: Maybe<ResponseConnection>
  step_id?: Maybe<Scalars["Int"]["output"]>
  tagLinks?: Maybe<TagLinkConnection>
  title?: Maybe<Scalars["String"]["output"]>
  total_responses?: Maybe<Scalars["Int"]["output"]>
  type?: Maybe<Scalars["Int"]["output"]>
  user?: Maybe<User>
  user_has_viewed_recent?: Maybe<Scalars["Boolean"]["output"]>
  user_is_subscribed?: Maybe<Scalars["Boolean"]["output"]>
  user_last_responded_time?: Maybe<Scalars["DateTime"]["output"]>
  user_last_viewed_time?: Maybe<Scalars["DateTime"]["output"]>
  user_liked?: Maybe<Scalars["Boolean"]["output"]>
}

/** Pathwright discussion */
export type DiscussionResponseArgs = {
  response_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright discussion */
export type DiscussionResponsesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
}

/** Pathwright discussion */
export type DiscussionTagLinksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  discussion_context_id?: InputMaybe<Scalars["Int"]["input"]>
  first: Scalars["Int"]["input"]
  tag_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A connection to a list of items. */
export type DiscussionConnection = {
  __typename?: "DiscussionConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DiscussionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

export type DiscussionContextInput = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  completion_id?: InputMaybe<Scalars["Int"]["input"]>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** An edge in a connection. */
export type DiscussionEdge = {
  __typename?: "DiscussionEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Discussion>
}

/** Pathwright user discussion history */
export type DiscussionParticipants = {
  __typename?: "DiscussionParticipants"
  display_name?: Maybe<Scalars["String"]["output"]>
  email?: Maybe<Scalars["String"]["output"]>
  external_account_link?: Maybe<ExternalAccountLink>
  first_name?: Maybe<Scalars["String"]["output"]>
  full_name?: Maybe<Scalars["String"]["output"]>
  gravatar?: Maybe<Gravatar>
  group_role_stats?: Maybe<Array<Maybe<GroupRoleStats>>>
  id?: Maybe<Scalars["Int"]["output"]>
  /** A user account setup is onl considered 'finalized' when the `first_name` and `last_name` fields have been set. */
  is_finalized?: Maybe<Scalars["Boolean"]["output"]>
  is_staff?: Maybe<Scalars["Boolean"]["output"]>
  is_superuser?: Maybe<Scalars["Boolean"]["output"]>
  last_name?: Maybe<Scalars["String"]["output"]>
  membership?: Maybe<Membership>
  memberships?: Maybe<MembershipConnection>
  mentorGroupMemberships?: Maybe<MentorGroupMembershipConnection>
  no_password_set?: Maybe<Scalars["Boolean"]["output"]>
  permissions?: Maybe<ContextPermissions>
  profile?: Maybe<Profile>
  registrations?: Maybe<RegistrationConnection>
  school_group_subscriptions?: Maybe<SchoolGroupSubscriptionConnection>
  school_subscription?: Maybe<SchoolSubscription>
  token?: Maybe<Token>
  username?: Maybe<Scalars["String"]["output"]>
}

/** Pathwright user discussion history */
export type DiscussionParticipantsMembershipsArgs = {
  access_revoked_date_filter?: InputMaybe<Array<InputMaybe<DateFilter>>>
  after?: InputMaybe<Scalars["String"]["input"]>
  archived_date_filter?: InputMaybe<Array<InputMaybe<DateFilter>>>
  daysInactive?: InputMaybe<Scalars["Int"]["input"]>
  first: Scalars["Int"]["input"]
  global?: InputMaybe<Scalars["Boolean"]["input"]>
  hasAccess?: InputMaybe<Scalars["Boolean"]["input"]>
  orderBy?: InputMaybe<Scalars["String"]["input"]>
  role_filter?: InputMaybe<Array<InputMaybe<MembershipRoleFilter>>>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright user discussion history */
export type DiscussionParticipantsMentorGroupMembershipsArgs = {
  _or?: InputMaybe<MentorGroupMembershipOr>
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  group_type?: InputMaybe<MentorGroupType>
  group_types?: InputMaybe<MentorGroupTypeFilter>
  mentor_group_seat_limit?: InputMaybe<Scalars["Int"]["input"]>
  mentor_group_seat_limit_filter?: InputMaybe<IntFilter>
  mentor_group_seats_filled?: InputMaybe<Scalars["Int"]["input"]>
  mentor_group_seats_filled_filter?: InputMaybe<IntFilter>
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  role?: InputMaybe<MentorGroupMembershipRoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright user discussion history */
export type DiscussionParticipantsRegistrationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  completion_state?: InputMaybe<CompletionStateFilter>
  created?: InputMaybe<DateFilter>
  first: Scalars["Int"]["input"]
  has_parent_registration?: InputMaybe<Scalars["Boolean"]["input"]>
  last_active?: InputMaybe<DateFilter>
  orderBy?: InputMaybe<Array<InputMaybe<RegistrationOrderByInput>>>
  parent_cohort_filter?: InputMaybe<IdFilter>
  positioningContext?: InputMaybe<PositioningContextInput>
  progress_filter?: InputMaybe<ProgressFilter>
  role?: InputMaybe<RegistrationRoleEnum>
  role_filter?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  send_discussion_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
  send_due_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
  softArchived?: InputMaybe<DateFilter>
}

/** Pathwright user discussion history */
export type DiscussionParticipantsSchool_Group_SubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A connection to a list of items. */
export type DiscussionParticipantsConnection = {
  __typename?: "DiscussionParticipantsConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DiscussionParticipantsEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type DiscussionParticipantsEdge = {
  __typename?: "DiscussionParticipantsEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<DiscussionParticipants>
}

export type DiscussionPostInput = {
  body?: InputMaybe<Scalars["String"]["input"]>
  is_discussion_question?: InputMaybe<Scalars["Boolean"]["input"]>
  title?: InputMaybe<Scalars["String"]["input"]>
  type?: InputMaybe<Scalars["Int"]["input"]>
}

export type DiscussionResponseContextInput = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  completion_id?: InputMaybe<Scalars["Int"]["input"]>
  discussion_id?: InputMaybe<Scalars["Int"]["input"]>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type DiscussionResponseInput = {
  attachments?: InputMaybe<Scalars["JSON"]["input"]>
  body: Scalars["String"]["input"]
  depth?: InputMaybe<Scalars["Int"]["input"]>
  discussion_id?: InputMaybe<Scalars["Int"]["input"]>
  reply_parent_id?: InputMaybe<Scalars["Int"]["input"]>
}

export enum DiscussionTypeInput {
  Faq = "faq",
  Feedback = "feedback",
  Post = "post",
  Question = "question",
  Review = "review"
}

export enum EmailDeliveryStatus {
  Bounced = "bounced",
  Clicked = "clicked",
  Complained = "complained",
  Deferred = "deferred",
  Opened = "opened",
  Queued = "queued",
  Rejected = "rejected",
  Sent = "sent",
  Unsubscribed = "unsubscribed"
}

/** Related data regarding an email */
export type EmailMessageData = {
  __typename?: "EmailMessageData"
  created_dtime?: Maybe<Scalars["DateTime"]["output"]>
  delivery_status?: Maybe<EmailDeliveryStatus>
  delivery_status_updated?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  message_id?: Maybe<Scalars["Int"]["output"]>
  recipient?: Maybe<Scalars["String"]["output"]>
  reject_reason?: Maybe<Scalars["String"]["output"]>
}

export type EquivalenceIdFilter = {
  eq?: InputMaybe<Scalars["ID"]["input"]>
  neq?: InputMaybe<Scalars["ID"]["input"]>
}

/** An SSO ExternalAccountLink */
export type ExternalAccountLink = {
  __typename?: "ExternalAccountLink"
  external_id?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  last_external_update?: Maybe<Scalars["DateTime"]["output"]>
  link_type?: Maybe<ExternalAccountType>
  linked_oidc_idp_id?: Maybe<Scalars["Int"]["output"]>
  linked_saml2_idp_id?: Maybe<Scalars["Int"]["output"]>
  user?: Maybe<User>
}

export enum ExternalAccountType {
  Generic = "generic",
  Oidc = "oidc",
  Saml2 = "saml2"
}

export enum FeatureActionType {
  AuthLogin = "auth__login",
  UpdateVisibility = "update__visibility",
  View = "view"
}

export type FeatureContextInput = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type FeatureKeyFilter = {
  eq?: InputMaybe<FeatureKeyType>
  gt?: InputMaybe<FeatureKeyType>
  gte?: InputMaybe<FeatureKeyType>
  in?: InputMaybe<Array<InputMaybe<FeatureKeyType>>>
  lt?: InputMaybe<FeatureKeyType>
  lte?: InputMaybe<FeatureKeyType>
  neq?: InputMaybe<FeatureKeyType>
  nin?: InputMaybe<Array<InputMaybe<FeatureKeyType>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export enum FeatureKeyType {
  AuthLoginOptionEmailLink = "auth__login_option__email_link",
  AuthLoginOptionGlobalSsoApple = "auth__login_option__global_sso__apple",
  AuthLoginOptionGlobalSsoGoogle = "auth__login_option__global_sso__google",
  AuthLoginOptionPassword = "auth__login_option__password",
  AuthPlatform = "auth__platform",
  CohortActivityFeed = "cohort__activity_feed",
  CohortMemberDirectory = "cohort__member_directory",
  PathsAppPublish = "paths_app__publish"
}

export enum FeaturePermissionType {
  AdminOnly = "admin_only",
  Disabled = "disabled",
  Everyone = "everyone",
  StaffOnly = "staff_only"
}

/** List of reviewers that have left a discussion, ordered by earliest response. */
export type FeedbackReviewers = {
  __typename?: "FeedbackReviewers"
  id?: Maybe<Scalars["Int"]["output"]>
  reviewers?: Maybe<Array<Maybe<Scalars["JSON"]["output"]>>>
}

/** Filestack keys */
export type FilestackInfo = {
  __typename?: "FilestackInfo"
  api_key?: Maybe<Scalars["String"]["output"]>
  policy?: Maybe<Scalars["String"]["output"]>
  signature?: Maybe<Scalars["String"]["output"]>
}

export type FirebaseConfig = {
  __typename?: "FirebaseConfig"
  apiKey: Scalars["String"]["output"]
  databaseURL: Scalars["String"]["output"]
  projectId: Scalars["String"]["output"]
  userToken?: Maybe<Scalars["String"]["output"]>
}

export type FirebaseIntegration = {
  __typename?: "FirebaseIntegration"
  auth_token?: Maybe<Scalars["String"]["output"]>
  client_api_key?: Maybe<Scalars["String"]["output"]>
  url?: Maybe<Scalars["String"]["output"]>
}

/** Gift subscription order data */
export type GiftSubscriptionOrderData = {
  gift_message?: InputMaybe<Scalars["String"]["input"]>
  num_cycles: Scalars["Int"]["input"]
  plan_id: Scalars["Int"]["input"]
  plan_visibility_code?: InputMaybe<Scalars["String"]["input"]>
  recipient_first_name: Scalars["String"]["input"]
  recipient_last_name: Scalars["String"]["input"]
}

/** A Gravatar profile. See https://en.gravatar.com/site/implement/profiles/ */
export type Gravatar = {
  __typename?: "Gravatar"
  /** The user's Gravatar Profile first name */
  first_name: Scalars["String"]["output"]
  /** The user's Gravatar Profile last name */
  last_name: Scalars["String"]["output"]
  meta: NodeMeta
}

/** A Pathwright Group */
export type Group = {
  __typename?: "Group"
  access_ended?: Maybe<Scalars["Boolean"]["output"]>
  access_ends?: Maybe<Scalars["DateTime"]["output"]>
  access_started?: Maybe<Scalars["Boolean"]["output"]>
  access_starts?: Maybe<Scalars["DateTime"]["output"]>
  analytics_disabled?: Maybe<Scalars["Boolean"]["output"]>
  created?: Maybe<Scalars["DateTime"]["output"]>
  created_by_id?: Maybe<Scalars["Int"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  discussion_enabled?: Maybe<Scalars["Boolean"]["output"]>
  id: Scalars["Int"]["output"]
  include_in_plans?: Maybe<SchoolSubscriptionPlanConnection>
  include_with_products?: Maybe<StripeProductConnection>
  invite_urls?: Maybe<GroupInviteUrls>
  is_collection_linked?: Maybe<Scalars["Boolean"]["output"]>
  is_master?: Maybe<Scalars["Boolean"]["output"]>
  is_source_cohort?: Maybe<Scalars["Boolean"]["output"]>
  is_subscription_only?: Maybe<Scalars["Boolean"]["output"]>
  member_stats?: Maybe<GroupMemberStats>
  meta: NodeMeta
  name: Scalars["String"]["output"]
  parent?: Maybe<Group>
  parent_section_id?: Maybe<Scalars["Int"]["output"]>
  path: Path
  permissions?: Maybe<GroupPermissions>
  price?: Maybe<Scalars["Float"]["output"]>
  registration?: Maybe<Registration>
  registration_ended?: Maybe<Scalars["Boolean"]["output"]>
  registration_ends?: Maybe<Scalars["DateTime"]["output"]>
  registration_started?: Maybe<Scalars["Boolean"]["output"]>
  registration_starts?: Maybe<Scalars["DateTime"]["output"]>
  registrations?: Maybe<RegistrationConnection>
  resource?: Maybe<Resource>
  resource_id?: Maybe<Scalars["Int"]["output"]>
  resource_license?: Maybe<ResourceLicense>
  seats?: Maybe<Scalars["Int"]["output"]>
  seats_available?: Maybe<Scalars["Int"]["output"]>
  seats_filled?: Maybe<Scalars["Int"]["output"]>
  source?: Maybe<Group>
  source_cohort_id?: Maybe<Scalars["Int"]["output"]>
  stats?: Maybe<GroupStats>
  status?: Maybe<GroupStatus>
  /** The current user's (or queried user's) registration in the parent cohort. */
  user_parent_registration?: Maybe<Registration>
  /** The current user (or queried user) registration in the cohort. */
  user_registration?: Maybe<Registration>
  /** The current user's (or queried user's) registration in the source cohort. */
  user_source_registration?: Maybe<Registration>
  visibility?: Maybe<Scalars["Int"]["output"]>
}

/** A Pathwright Group */
export type GroupInclude_In_PlansArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A Pathwright Group */
export type GroupInclude_With_ProductsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A Pathwright Group */
export type GroupPathArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  type?: InputMaybe<CurriculumType>
}

/** A Pathwright Group */
export type GroupRegistrationArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright Group */
export type GroupRegistrationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  completion_state?: InputMaybe<CompletionStateFilter>
  created?: InputMaybe<DateFilter>
  first: Scalars["Int"]["input"]
  has_parent_registration?: InputMaybe<Scalars["Boolean"]["input"]>
  last_active?: InputMaybe<DateFilter>
  orderBy?: InputMaybe<Array<InputMaybe<RegistrationOrderByInput>>>
  parent_cohort_filter?: InputMaybe<IdFilter>
  positioningContext?: InputMaybe<PositioningContextInput>
  progress_filter?: InputMaybe<ProgressFilter>
  role?: InputMaybe<RegistrationRoleEnum>
  role_filter?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  send_discussion_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
  send_due_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
  softArchived?: InputMaybe<DateFilter>
}

/** A Pathwright Group */
export type GroupUser_Parent_RegistrationArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright Group */
export type GroupUser_RegistrationArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright Group */
export type GroupUser_Source_RegistrationArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A connection to a list of items. */
export type GroupConnection = {
  __typename?: "GroupConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GroupEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type GroupEdge = {
  __typename?: "GroupEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Group>
}

export type GroupInviteUrls = {
  __typename?: "GroupInviteUrls"
  editor?: Maybe<Scalars["String"]["output"]>
  moderator?: Maybe<Scalars["String"]["output"]>
  observer?: Maybe<Scalars["String"]["output"]>
  student?: Maybe<Scalars["String"]["output"]>
  teacher?: Maybe<Scalars["String"]["output"]>
  viewer?: Maybe<Scalars["String"]["output"]>
}

/** Group license order data */
export type GroupLicenseOrderData = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  license_offering_id: Scalars["Int"]["input"]
  name: Scalars["String"]["input"]
  role?: InputMaybe<RegistrationRoleEnum>
  seat_count?: InputMaybe<Scalars["Int"]["input"]>
}

export type GroupMemberStats = {
  __typename?: "GroupMemberStats"
  send_discussion_notifications?: Maybe<Scalars["Int"]["output"]>
  staff?: Maybe<Scalars["Int"]["output"]>
  total?: Maybe<Scalars["Int"]["output"]>
}

export type GroupPermissions = {
  __typename?: "GroupPermissions"
  can_invite_editor?: Maybe<Scalars["Boolean"]["output"]>
  can_invite_moderator?: Maybe<Scalars["Boolean"]["output"]>
  can_invite_observer?: Maybe<Scalars["Boolean"]["output"]>
  can_invite_student?: Maybe<Scalars["Boolean"]["output"]>
  can_invite_teacher?: Maybe<Scalars["Boolean"]["output"]>
  can_make_public?: Maybe<Scalars["Boolean"]["output"]>
  can_publish?: Maybe<Scalars["Boolean"]["output"]>
}

export type GroupRoleStats = {
  __typename?: "GroupRoleStats"
  role?: Maybe<Scalars["Int"]["output"]>
  total?: Maybe<Scalars["Int"]["output"]>
  type?: Maybe<Scalars["String"]["output"]>
}

/** Whether user can use Group share UI. */
export type GroupShareAccess = {
  __typename?: "GroupShareAccess"
  /** A user's access level to share UI. */
  access?: Maybe<ShareAccessPermission>
  /** When share UI is gated, this will be the reason for why. */
  gated_reason?: Maybe<GroupShareAccessGatedReasonType>
}

export enum GroupShareAccessGatedReasonType {
  Placeholder = "PLACEHOLDER"
}

/** Whether user can use Group share > add UI. */
export type GroupShareAddAccess = {
  __typename?: "GroupShareAddAccess"
  /** A user's access level to share > add UI. */
  access?: Maybe<ShareAccessPermission>
  /** When share > add UI is gated, this will be the reason for why. */
  gated_reason?: Maybe<GroupShareAddAccessGatedReasonType>
}

export enum GroupShareAddAccessGatedReasonType {
  GroupSeatsFilled = "GROUP_SEATS_FILLED",
  GroupSubscriptionInactive = "GROUP_SUBSCRIPTION_INACTIVE"
}

/** Whether user can use Group share > link UI. */
export type GroupShareLinkAccess = {
  __typename?: "GroupShareLinkAccess"
  /** A user's access level to share > link UI. */
  access?: Maybe<ShareAccessPermission>
  /** When share > link UI is gated, this will be the reason for why. */
  gated_reason?: Maybe<GroupShareLinkAccessGatedReasonType>
}

export enum GroupShareLinkAccessGatedReasonType {
  GroupSeatsFilled = "GROUP_SEATS_FILLED",
  GroupSubscriptionInactive = "GROUP_SUBSCRIPTION_INACTIVE"
}

/** Whether user can use Group share > track UI. */
export type GroupShareTrackAccess = {
  __typename?: "GroupShareTrackAccess"
  /** A user's access level to share > track UI. */
  access?: Maybe<ShareAccessPermission>
  /** When share > track UI is gated, this will be the reason for why. */
  gated_reason?: Maybe<GroupShareTrackAccessGatedReasonType>
}

export enum GroupShareTrackAccessGatedReasonType {
  Placeholder = "PLACEHOLDER"
}

export type GroupStats = {
  __typename?: "GroupStats"
  completion?: Maybe<Scalars["Float"]["output"]>
  points?: Maybe<Scalars["Int"]["output"]>
  total_points?: Maybe<Scalars["Int"]["output"]>
}

export enum GroupStatus {
  /** The cohort has been completed */
  Completed = "completed",
  /** The cohort is in progress */
  InProgress = "in_progress"
}

/** Group subscription order data */
export type GroupSubscriptionOrderData = {
  group_name: Scalars["String"]["input"]
  price_id: Scalars["Int"]["input"]
  quantity: Scalars["Int"]["input"]
  redirect_to?: InputMaybe<Scalars["String"]["input"]>
}

export enum GroupType {
  /** The curriculum group */
  Curriculum = "curriculum"
}

export enum GroupVisibility {
  MembersOnly = "members_only",
  Private = "private",
  Public = "public"
}

export type GroupVisibilityFilter = {
  eq?: InputMaybe<GroupVisibility>
  gt?: InputMaybe<GroupVisibility>
  gte?: InputMaybe<GroupVisibility>
  in?: InputMaybe<Array<InputMaybe<GroupVisibility>>>
  lt?: InputMaybe<GroupVisibility>
  lte?: InputMaybe<GroupVisibility>
  neq?: InputMaybe<GroupVisibility>
  nin?: InputMaybe<Array<InputMaybe<GroupVisibility>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export type IdFilter = {
  eq?: InputMaybe<Scalars["ID"]["input"]>
  gt?: InputMaybe<Scalars["ID"]["input"]>
  gte?: InputMaybe<Scalars["ID"]["input"]>
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>
  lt?: InputMaybe<Scalars["ID"]["input"]>
  lte?: InputMaybe<Scalars["ID"]["input"]>
  neq?: InputMaybe<Scalars["ID"]["input"]>
  nin?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export type ImageInput = {
  balph?: InputMaybe<Scalars["Int"]["input"]>
  blend?: InputMaybe<Scalars["Int"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  bm?: InputMaybe<Scalars["String"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  fm?: InputMaybe<Scalars["String"]["input"]>
  height?: InputMaybe<Scalars["Int"]["input"]>
  high?: InputMaybe<Scalars["Int"]["input"]>
  q?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** Provides context data for the inbox. A group, cohort, or user. */
export type InboxContext = {
  __typename?: "InboxContext"
  /** A cohort of students */
  cohort?: Maybe<Group>
  /** A group of students */
  group?: Maybe<MentorGroup>
  id?: Maybe<Scalars["String"]["output"]>
}

/** A combo query of inbox groups */
export type InboxGroupConnection = {
  __typename?: "InboxGroupConnection"
  cohort?: Maybe<Group>
  mentor_group?: Maybe<MentorGroup>
}

/** A connection to a list of items. */
export type InboxGroupConnectionConnection = {
  __typename?: "InboxGroupConnectionConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InboxGroupConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type InboxGroupConnectionEdge = {
  __typename?: "InboxGroupConnectionEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<InboxGroupConnection>
}

/** A mentor group with reviewable items */
export type InboxMentorGroupConnection = {
  __typename?: "InboxMentorGroupConnection"
  group?: Maybe<MentorGroup>
  id?: Maybe<Scalars["Int"]["output"]>
  pending_review_count?: Maybe<Scalars["Int"]["output"]>
}

/** A path with reviewable items (pending_review_count) */
export type InboxPath = {
  __typename?: "InboxPath"
  group?: Maybe<Group>
  id?: Maybe<Scalars["Int"]["output"]>
  pending_review_count?: Maybe<Scalars["Int"]["output"]>
  registration?: Maybe<Registration>
  resource?: Maybe<Resource>
}

/** A connection to a list of items. */
export type InboxPathConnection = {
  __typename?: "InboxPathConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InboxPathEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type InboxPathEdge = {
  __typename?: "InboxPathEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<InboxPath>
}

/** A pending review count of reviewable items for target (InboxPendingReviewTarget) */
export type InboxPendingReview = {
  __typename?: "InboxPendingReview"
  count?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
}

export enum InboxPendingReviewTarget {
  Group = "group",
  Path = "path"
}

/** A count of completion items for people within a cohort or mentor group */
export type InboxPeopleCompletionCounts = {
  __typename?: "InboxPeopleCompletionCounts"
  count?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  user?: Maybe<User>
}

/** A connection to a list of items. */
export type InboxPeopleCompletionCountsConnection = {
  __typename?: "InboxPeopleCompletionCountsConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InboxPeopleCompletionCountsEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type InboxPeopleCompletionCountsEdge = {
  __typename?: "InboxPeopleCompletionCountsEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<InboxPeopleCompletionCounts>
}

export enum InboxPeopleCompletionCountsTarget {
  Cohort = "cohort",
  Group = "group"
}

/** A reviewer of a reviewable path item */
export type InboxReviwer = {
  __typename?: "InboxReviwer"
  can_review?: Maybe<Scalars["Boolean"]["output"]>
  cohort_role?: Maybe<RegistrationRoleEnum>
  group_role?: Maybe<MentorGroupMembershipRole>
  id?: Maybe<Scalars["Int"]["output"]>
  user?: Maybe<User>
}

/** A connection to a list of items. */
export type InboxReviwerConnection = {
  __typename?: "InboxReviwerConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InboxReviwerEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type InboxReviwerEdge = {
  __typename?: "InboxReviwerEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<InboxReviwer>
}

export type IntFilter = {
  eq?: InputMaybe<Scalars["Int"]["input"]>
  gt?: InputMaybe<Scalars["Int"]["input"]>
  gte?: InputMaybe<Scalars["Int"]["input"]>
  in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  lt?: InputMaybe<Scalars["Int"]["input"]>
  lte?: InputMaybe<Scalars["Int"]["input"]>
  neq?: InputMaybe<Scalars["Int"]["input"]>
  nin?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export type Integrations = {
  __typename?: "Integrations"
  firebase?: Maybe<FirebaseIntegration>
  intercom?: Maybe<IntercomIntegration>
}

export type IntercomIntegration = {
  __typename?: "IntercomIntegration"
  /** The Intercom app ID that will be used by the client. */
  app_id?: Maybe<Scalars["String"]["output"]>
  /** Pathwright's Intercom app ID. */
  pathwright_app_id?: Maybe<Scalars["String"]["output"]>
  /** Intercom access token, used for tracking events, only exposed to school admin. */
  school_access_token?: Maybe<Scalars["String"]["output"]>
  /** The school's Intercom app ID. */
  school_app_id?: Maybe<Scalars["String"]["output"]>
  /** Intercom secret key, used for Identification Verification, only exposed to school admin. */
  school_secret_key?: Maybe<Scalars["String"]["output"]>
  /** If an Intercom secret key is available, a user_hash will be generated for the current user which will be passed to Intercom for Identification Verification. */
  user_hash?: Maybe<Scalars["String"]["output"]>
}

export enum InterestLevel {
  High = "high",
  Low = "low",
  Medium = "medium"
}

/** An invitation for a user to join a school, an offering, or a community group as a given role. */
export type Invitation = {
  __typename?: "Invitation"
  auth_create_user?: Maybe<Scalars["Boolean"]["output"]>
  community_group?: Maybe<MentorGroup>
  community_group_membership?: Maybe<MentorGroupMembership>
  created_dtime?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  invitation_accepted_dtime?: Maybe<Scalars["DateTime"]["output"]>
  invitation_email?: Maybe<EmailMessageData>
  invitation_hash?: Maybe<Scalars["String"]["output"]>
  invitation_message?: Maybe<Scalars["String"]["output"]>
  /** Specific to which type of invitation this is (see invitation_type) */
  invitation_role?: Maybe<Scalars["Int"]["output"]>
  invitation_status?: Maybe<InvitationStatus>
  invitation_subject?: Maybe<Scalars["String"]["output"]>
  /** 1: Invitation to offering 2: Invitation to school 3: Invitation to community group */
  invitation_type?: Maybe<Scalars["Int"]["output"]>
  invitation_url?: Maybe<Scalars["String"]["output"]>
  offering?: Maybe<Group>
  offering_registration?: Maybe<Registration>
  school?: Maybe<School>
  school_membership?: Maybe<Membership>
  to_email?: Maybe<Scalars["String"]["output"]>
  to_first_name?: Maybe<Scalars["String"]["output"]>
  to_last_name?: Maybe<Scalars["String"]["output"]>
  to_user?: Maybe<User>
}

/** A connection to a list of items. */
export type InvitationConnection = {
  __typename?: "InvitationConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvitationEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

export type InvitationContext = {
  /** Scope invitations to cohort */
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  /** Scope invitations to community group */
  community_group_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** An edge in a connection. */
export type InvitationEdge = {
  __typename?: "InvitationEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Invitation>
}

export enum InvitationStatus {
  Accepted = "accepted",
  NotAccepted = "not_accepted"
}

/** The data embedded in an invitation token. */
export type InvitationToken = {
  __typename?: "InvitationToken"
  cohort?: Maybe<Group>
  group?: Maybe<MentorGroup>
  id: Scalars["String"]["output"]
  invite_type: InvitationType
  role: Scalars["Int"]["output"]
  sender?: Maybe<User>
}

export enum InvitationType {
  Cohort = "cohort",
  CommunityGroup = "community_group",
  School = "school"
}

/** Analytics data for a path or path item */
export type ItemAnalytics = {
  __typename?: "ItemAnalytics"
  avg_score?: Maybe<Scalars["Float"]["output"]>
  /** The number of users who have completed the whole path */
  complete?: Maybe<Scalars["Int"]["output"]>
  completion_rate?: Maybe<Scalars["Float"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  /** The number of users who still are working on this path */
  incomplete?: Maybe<Scalars["Int"]["output"]>
  item?: Maybe<PathItem>
  items?: Maybe<Scalars["JSON"]["output"]>
  /** The number of step completions in the path that need grading */
  need_feedback?: Maybe<Scalars["Int"]["output"]>
  next_count?: Maybe<Scalars["Int"]["output"]>
  path_id?: Maybe<Scalars["Int"]["output"]>
  /** This is a list of up to 10 users who have completed this path (used to render avatar stacks).  */
  students_here?: Maybe<Scalars["JSON"]["output"]>
  /** The number of earned points across all steps in this path (Note: this excludes steps that aren't due if due dates are in use) */
  total_points?: Maybe<Scalars["Int"]["output"]>
  /** The number of available points across all completed and past due student steps in this path */
  total_points_available?: Maybe<Scalars["Int"]["output"]>
  total_users?: Maybe<Scalars["Int"]["output"]>
}

/** A JSONWebToken. */
export type JsonWebToken = {
  __typename?: "JSONWebToken"
  token: Scalars["String"]["output"]
}

/** A resource license offering */
export type LicenseOffering = {
  __typename?: "LicenseOffering"
  /** @deprecated Deprecated in favor of `licensing_mode`. */
  allow_private_group_licensing?: Maybe<Scalars["Boolean"]["output"]>
  /** @deprecated Deprecated in favor of `licensing_mode`. */
  allow_school_licensing?: Maybe<Scalars["Boolean"]["output"]>
  cost_per_seat?: Maybe<Scalars["Float"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  include_creator_seat?: Maybe<Scalars["Boolean"]["output"]>
  include_in_curriculum_plans?: Maybe<SchoolCurriculumPlanConnection>
  include_in_school_curriculum_subscriptions?: Maybe<CurriculumSubscriptionConnection>
  include_in_subscription_plans?: Maybe<SchoolSubscriptionPlanConnection>
  included_in_user_subscription?: Maybe<Scalars["Boolean"]["output"]>
  licensing_mode?: Maybe<LicenseOfferingLicensingMode>
  licensor?: Maybe<School>
  minimum_license_duration?: Maybe<Scalars["Int"]["output"]>
  minimum_seat_count?: Maybe<Scalars["Int"]["output"]>
  minimum_seat_count_for_private_group_licensing?: Maybe<
    Scalars["Int"]["output"]
  >
  minimum_seat_count_for_school_licensing?: Maybe<Scalars["Int"]["output"]>
  register_creator_as?: Maybe<RegistrationRoleEnum>
  resource?: Maybe<Resource>
  school_license?: Maybe<ResourceLicense>
}

/** A resource license offering */
export type LicenseOfferingInclude_In_Curriculum_PlansArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A resource license offering */
export type LicenseOfferingInclude_In_School_Curriculum_SubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  licensee_id?: InputMaybe<IdFilter>
  licensor_id?: InputMaybe<IdFilter>
}

/** A resource license offering */
export type LicenseOfferingInclude_In_Subscription_PlansArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A resource license offering */
export type LicenseOfferingSchool_LicenseArgs = {
  licensee_school_id: Scalars["Int"]["input"]
}

/** A connection to a list of items. */
export type LicenseOfferingConnection = {
  __typename?: "LicenseOfferingConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LicenseOfferingEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type LicenseOfferingEdge = {
  __typename?: "LicenseOfferingEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<LicenseOffering>
}

export enum LicenseOfferingFor {
  /** This license is for private groups */
  PrivateGroup = "private_group",
  /** This license is for other schools */
  School = "school"
}

export enum LicenseOfferingLicensingMode {
  /** Resource: Group subscriptions */
  GroupSubscription = "group_subscription",
  /** Resource: Private cohorts */
  PrivateCohorts = "private_cohorts",
  /** Resource: School licensing */
  SchoolLicensing = "school_licensing"
}

export enum LicenseRoleEnum {
  Moderator = "moderator",
  Student = "student",
  Teacher = "teacher"
}

/** A Licensee School */
export type LicenseeSchool = {
  __typename?: "LicenseeSchool"
  about?: Maybe<Scalars["String"]["output"]>
  created?: Maybe<Scalars["DateTime"]["output"]>
  currency_code?: Maybe<Scalars["String"]["output"]>
  email?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  ietf_language_tag?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  subdomain?: Maybe<Scalars["String"]["output"]>
  website?: Maybe<Scalars["String"]["output"]>
}

/** Lifetime school subscription order data */
export type LifetimeSchoolSubscriptionOrderData = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  plan_id: Scalars["Int"]["input"]
  plan_visibility_code?: InputMaybe<Scalars["String"]["input"]>
}

export type ListOrderInput = {
  id: Scalars["Int"]["input"]
  order: Scalars["Int"]["input"]
}

/** Info about a user's login options */
export type LoginInfo = {
  __typename?: "LoginInfo"
  auth_url?: Maybe<Scalars["String"]["output"]>
  is_external?: Maybe<Scalars["Boolean"]["output"]>
  primary_color?: Maybe<Scalars["String"]["output"]>
  prompt?: Maybe<Scalars["String"]["output"]>
}

/** Not-CSS Media Queries */
export type Media = {
  __typename?: "Media"
  filestack?: Maybe<FilestackInfo>
  image?: Maybe<Scalars["String"]["output"]>
}

/** Not-CSS Media Queries */
export type MediaImageArgs = {
  balph?: InputMaybe<Scalars["Int"]["input"]>
  blend?: InputMaybe<Scalars["Int"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  bm?: InputMaybe<Scalars["String"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  fm?: InputMaybe<Scalars["String"]["input"]>
  height?: InputMaybe<Scalars["Int"]["input"]>
  high?: InputMaybe<Scalars["Int"]["input"]>
  q?: InputMaybe<Scalars["Int"]["input"]>
  url: Scalars["String"]["input"]
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** Contextual data needed for the Media Manager */
export type MediaContext = {
  __typename?: "MediaContext"
  accountID?: Maybe<Scalars["String"]["output"]>
  mediaStoragePath?: Maybe<Scalars["String"]["output"]>
  user?: Maybe<User>
}

/** A Pathwright User's Membership to a School */
export type Membership = {
  __typename?: "Membership"
  access_revoked_time?: Maybe<Scalars["DateTime"]["output"]>
  archived_time?: Maybe<Scalars["DateTime"]["output"]>
  consent_dtime?: Maybe<Scalars["DateTime"]["output"]>
  consent_snapshot?: Maybe<Scalars["String"]["output"]>
  created: Scalars["DateTime"]["output"]
  has_access: Scalars["Boolean"]["output"]
  id: Scalars["Int"]["output"]
  last_active?: Maybe<Scalars["DateTime"]["output"]>
  last_seen?: Maybe<Scalars["DateTime"]["output"]>
  role: Scalars["Int"]["output"]
  school: School
  school_id: Scalars["Int"]["output"]
  user: User
}

/** A connection to a list of items. */
export type MembershipConnection = {
  __typename?: "MembershipConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MembershipEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type MembershipEdge = {
  __typename?: "MembershipEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Membership>
}

export type MembershipMeta = {
  __typename?: "MembershipMeta"
  editor?: Maybe<Scalars["Int"]["output"]>
  lead_mentor?: Maybe<Scalars["Int"]["output"]>
  learner?: Maybe<Scalars["Int"]["output"]>
  mentor?: Maybe<Scalars["Int"]["output"]>
  moderator?: Maybe<Scalars["Int"]["output"]>
  observer?: Maybe<Scalars["Int"]["output"]>
  teacher?: Maybe<Scalars["Int"]["output"]>
}

export enum MembershipRole {
  Administrator = "administrator",
  Editor = "editor",
  Facilitator = "facilitator",
  Student = "student"
}

export type MembershipRoleFilter = {
  eq?: InputMaybe<MembershipRole>
  gt?: InputMaybe<MembershipRole>
  gte?: InputMaybe<MembershipRole>
  in?: InputMaybe<Array<InputMaybe<MembershipRole>>>
  lt?: InputMaybe<MembershipRole>
  lte?: InputMaybe<MembershipRole>
  neq?: InputMaybe<MembershipRole>
  nin?: InputMaybe<Array<InputMaybe<MembershipRole>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export type MembershipStats = {
  __typename?: "MembershipStats"
  /** Active within the last 30 days */
  active?: Maybe<Scalars["Int"]["output"]>
  has_access?: Maybe<Scalars["Int"]["output"]>
  /** Inactive for over 60 days */
  inactive?: Maybe<Scalars["Int"]["output"]>
  no_access?: Maybe<Scalars["Int"]["output"]>
  /** Inactive within the last 60 days  */
  slipping?: Maybe<Scalars["Int"]["output"]>
  total?: Maybe<Scalars["Int"]["output"]>
}

export type MentorGroup = {
  __typename?: "MentorGroup"
  child_groups?: Maybe<MentorGroupConnection>
  created?: Maybe<Scalars["DateTime"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  flattened_child_groups?: Maybe<MentorGroupConnection>
  group_subscription?: Maybe<SchoolGroupSubscription>
  group_subscriptions?: Maybe<SchoolGroupSubscriptionConnection>
  has_child_groups?: Maybe<Scalars["Boolean"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  invitations?: Maybe<InvitationConnection>
  memberships?: Maybe<MentorGroupMembershipConnection>
  modified?: Maybe<Scalars["DateTime"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  owner?: Maybe<User>
  parent?: Maybe<MentorGroup>
  parent_id?: Maybe<Scalars["Int"]["output"]>
  permissions?: Maybe<MentorGroupPermissions>
  school?: Maybe<School>
  seat_limit?: Maybe<Scalars["Int"]["output"]>
  seats_available?: Maybe<Scalars["Int"]["output"]>
  seats_filled?: Maybe<Scalars["Int"]["output"]>
  slug?: Maybe<Scalars["String"]["output"]>
  type?: Maybe<Scalars["Int"]["output"]>
  user_membership?: Maybe<MentorGroupMembership>
}

export type MentorGroupChild_GroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  descendant_of_parent_id?: InputMaybe<EquivalenceIdFilter>
  first: Scalars["Int"]["input"]
  id?: InputMaybe<IdFilter>
  parent_id?: InputMaybe<IdFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  seat_limit?: InputMaybe<Scalars["Int"]["input"]>
  seat_limit_filter?: InputMaybe<IntFilter>
  type?: InputMaybe<MentorGroupType>
}

export type MentorGroupFlattened_Child_GroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  descendant_of_parent_id?: InputMaybe<EquivalenceIdFilter>
  first: Scalars["Int"]["input"]
  id?: InputMaybe<IdFilter>
  parent_id?: InputMaybe<IdFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  seat_limit?: InputMaybe<Scalars["Int"]["input"]>
  seat_limit_filter?: InputMaybe<IntFilter>
  type?: InputMaybe<MentorGroupType>
}

export type MentorGroupGroup_SubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

export type MentorGroupInvitationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context?: InputMaybe<InvitationContext>
  delivery_status?: InputMaybe<EmailDeliveryStatus>
  first: Scalars["Int"]["input"]
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  search?: InputMaybe<Scalars["String"]["input"]>
  status?: InputMaybe<InvitationStatus>
  type?: InputMaybe<InvitationType>
}

export type MentorGroupMembershipsArgs = {
  _or?: InputMaybe<MentorGroupMembershipOr>
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  group_type?: InputMaybe<MentorGroupType>
  group_types?: InputMaybe<MentorGroupTypeFilter>
  mentor_group_seat_limit?: InputMaybe<Scalars["Int"]["input"]>
  mentor_group_seat_limit_filter?: InputMaybe<IntFilter>
  mentor_group_seats_filled?: InputMaybe<Scalars["Int"]["input"]>
  mentor_group_seats_filled_filter?: InputMaybe<IntFilter>
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  role?: InputMaybe<MentorGroupMembershipRoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** A connection to a list of items. */
export type MentorGroupConnection = {
  __typename?: "MentorGroupConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MentorGroupEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type MentorGroupEdge = {
  __typename?: "MentorGroupEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<MentorGroup>
}

export type MentorGroupMembership = {
  __typename?: "MentorGroupMembership"
  access_revoked_time?: Maybe<Scalars["DateTime"]["output"]>
  can_review?: Maybe<Scalars["Boolean"]["output"]>
  created?: Maybe<Scalars["DateTime"]["output"]>
  group?: Maybe<MentorGroup>
  group_id?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  role?: Maybe<Scalars["Int"]["output"]>
  school?: Maybe<School>
  send_mentor_digest?: Maybe<Scalars["Boolean"]["output"]>
  user?: Maybe<User>
}

/** A connection to a list of items. */
export type MentorGroupMembershipConnection = {
  __typename?: "MentorGroupMembershipConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MentorGroupMembershipEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type MentorGroupMembershipEdge = {
  __typename?: "MentorGroupMembershipEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<MentorGroupMembership>
}

export type MentorGroupMembershipOr = {
  group_type?: InputMaybe<MentorGroupType>
  group_types?: InputMaybe<MentorGroupTypeFilter>
  mentor_group_seat_limit?: InputMaybe<Scalars["Int"]["input"]>
  mentor_group_seat_limit_filter?: InputMaybe<IntFilter>
  mentor_group_seats_filled?: InputMaybe<Scalars["Int"]["input"]>
  mentor_group_seats_filled_filter?: InputMaybe<IntFilter>
  role?: InputMaybe<MentorGroupMembershipRoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
}

export enum MentorGroupMembershipRole {
  Admin = "admin",
  Member = "member",
  Moderator = "moderator"
}

export type MentorGroupMembershipRoleFilter = {
  eq?: InputMaybe<MentorGroupMembershipRole>
  gt?: InputMaybe<MentorGroupMembershipRole>
  gte?: InputMaybe<MentorGroupMembershipRole>
  in?: InputMaybe<Array<InputMaybe<MentorGroupMembershipRole>>>
  lt?: InputMaybe<MentorGroupMembershipRole>
  lte?: InputMaybe<MentorGroupMembershipRole>
  neq?: InputMaybe<MentorGroupMembershipRole>
  nin?: InputMaybe<Array<InputMaybe<MentorGroupMembershipRole>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export type MentorGroupPermissions = {
  __typename?: "MentorGroupPermissions"
  can_add_admin?: Maybe<Scalars["Boolean"]["output"]>
  can_add_group?: Maybe<Scalars["Boolean"]["output"]>
  can_add_member?: Maybe<Scalars["Boolean"]["output"]>
  can_add_moderator?: Maybe<Scalars["Boolean"]["output"]>
  can_change_parent?: Maybe<Scalars["Boolean"]["output"]>
  can_delete_group?: Maybe<Scalars["Boolean"]["output"]>
  can_edit_group?: Maybe<Scalars["Boolean"]["output"]>
  can_edit_subscription?: Maybe<Scalars["Boolean"]["output"]>
  can_update_admin_review_permission?: Maybe<Scalars["Boolean"]["output"]>
  can_update_member_review_permission?: Maybe<Scalars["Boolean"]["output"]>
  can_update_moderator_review_permission?: Maybe<Scalars["Boolean"]["output"]>
}

export type MentorGroupStats = {
  __typename?: "MentorGroupStats"
  community?: Maybe<Scalars["Int"]["output"]>
  group_subscription?: Maybe<Scalars["Int"]["output"]>
  personal?: Maybe<Scalars["Int"]["output"]>
  total?: Maybe<Scalars["Int"]["output"]>
}

export enum MentorGroupType {
  Community = "community",
  GroupSubscription = "group_subscription",
  Personal = "personal"
}

export type MentorGroupTypeFilter = {
  eq?: InputMaybe<MentorGroupType>
  gt?: InputMaybe<MentorGroupType>
  gte?: InputMaybe<MentorGroupType>
  in?: InputMaybe<Array<InputMaybe<MentorGroupType>>>
  lt?: InputMaybe<MentorGroupType>
  lte?: InputMaybe<MentorGroupType>
  neq?: InputMaybe<MentorGroupType>
  nin?: InputMaybe<Array<InputMaybe<MentorGroupType>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

/** A scheduled message */
export type Message = {
  __typename?: "Message"
  based_on_step_date?: Maybe<MessageStepDateField>
  body?: Maybe<Scalars["String"]["output"]>
  channels?: Maybe<Array<Maybe<MessageChannel>>>
  id?: Maybe<Scalars["Int"]["output"]>
  is_active?: Maybe<Scalars["Boolean"]["output"]>
  path_id?: Maybe<Scalars["Int"]["output"]>
  recipient_filter?: Maybe<MessageRecipientFilter>
  send_date?: Maybe<Scalars["DateTime"]["output"]>
  sent_date?: Maybe<Scalars["DateTime"]["output"]>
  step_source_id?: Maybe<Scalars["Int"]["output"]>
  subject?: Maybe<Scalars["String"]["output"]>
  threshold_minutes?: Maybe<Scalars["Int"]["output"]>
  type?: Maybe<MessageType>
}

export enum MessageChannel {
  Email = "email",
  Notification = "notification"
}

/** A connection to a list of items. */
export type MessageConnection = {
  __typename?: "MessageConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MessageEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

export type MessageContext = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  path_id?: InputMaybe<Scalars["Int"]["input"]>
  source_id: Scalars["Int"]["input"]
}

/** An edge in a connection. */
export type MessageEdge = {
  __typename?: "MessageEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Message>
}

export type MessageInput = {
  based_on_step_date?: InputMaybe<MessageStepDateField>
  body: Scalars["String"]["input"]
  channels: Array<InputMaybe<MessageChannel>>
  recipient_filter?: InputMaybe<MessageRecipientFilter>
  subject: Scalars["String"]["input"]
  threshold_minutes: Scalars["Int"]["input"]
  type: MessageType
}

export enum MessageRecipientFilter {
  /** All learners */
  All = "all",
  /** Learners with completed steps */
  StepComplete = "step_complete",
  /** Learners with incomplete steps */
  StepIncomplete = "step_incomplete"
}

export enum MessageStepDateField {
  Due = "due",
  Starts = "starts"
}

export enum MessageType {
  Reminder = "reminder"
}

export type MessageTypeFilter = {
  eq?: InputMaybe<MessageType>
  gt?: InputMaybe<MessageType>
  gte?: InputMaybe<MessageType>
  in?: InputMaybe<Array<InputMaybe<MessageType>>>
  lt?: InputMaybe<MessageType>
  lte?: InputMaybe<MessageType>
  neq?: InputMaybe<MessageType>
  nin?: InputMaybe<Array<InputMaybe<MessageType>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export type Mutations = {
  __typename?: "Mutations"
  /** Accepts a community group invitation */
  acceptCommunityGroupInvitation?: Maybe<Scalars["Boolean"]["output"]>
  /** Add people to group or cohort */
  addPeopleTo?: Maybe<BackgroundTask>
  apiCompletionUpdate?: Maybe<ApiCompletionUpdate>
  /** Creates a registration for the user */
  assign?: Maybe<Scalars["JSON"]["output"]>
  /** Creates multiple new mentor group memberships */
  batchCreateMentorGroupMembership?: Maybe<BatchMentorGroupMembershipResult>
  /** Cancels a new subscription for the user. */
  cancelSchoolSubscription?: Maybe<SchoolSubscription>
  /** Changes a user's password */
  changePassword?: Maybe<Scalars["Boolean"]["output"]>
  /** Marks a path item as completed */
  completePathItem?: Maybe<PathItem>
  /** Allows a user to attempt to confirm an AuthRequest */
  confirmAuthRequest?: Maybe<AuthRequest>
  /** Copies an existing resource */
  copyResource?: Maybe<Resource>
  /** Creat a banner */
  createBanner?: Maybe<Banner>
  /** Creates Blocks (DB) content */
  createBlocksContent?: Maybe<BlocksContent>
  /** Creates a certificate for a school */
  createCertificate?: Maybe<Certificate>
  /** Creates a contextualFeaturePermission for a given context */
  createContextualFeaturePermission?: Maybe<ContextualFeaturePermission>
  /** Create a curriculum license order */
  createCurriculumLicenseOrder?: Maybe<Order>
  /** Create a curriculum subscription order */
  createCurriculumSubscriptionOrder?: Maybe<Order>
  createDiscussion?: Maybe<Discussion>
  createDiscussionLike?: Maybe<Discussion>
  createDiscussionResponse?: Maybe<Response>
  createDiscussionResponseLike?: Maybe<Response>
  createDiscussionSubscription?: Maybe<Discussion>
  /** Creates an external account link for a user in a school, associating them with an external ID. */
  createExternalAccountLink?: Maybe<ExternalAccountLink>
  /** Create a gift subscription order */
  createGiftSubscriptionOrder?: Maybe<Order>
  /** Creates a new cohort for a resource */
  createGroup?: Maybe<Group>
  /** Create a group license order */
  createGroupLicenseOrder?: Maybe<Order>
  /** Create a group subscription order */
  createGroupSubscriptionOrder?: Maybe<Order>
  /** Creates invitations for the given invite emails */
  createInvitations?: Maybe<CreateInvitationsSuccess>
  /** Create a lifetime school subscription order */
  createLifetimeSchoolSubscriptionOrder?: Maybe<Order>
  /** Creates a school membership for a user who already has an account on Pathwright, but is not yet a member of the school. Allowed only for schools who have API access enabled. */
  createMembershipForExistingUser?: Maybe<Membership>
  /** Creates a new mentor group */
  createMentorGroup?: Maybe<MentorGroup>
  /** Creates a new mentor group membership */
  createMentorGroupMembership?: Maybe<MentorGroupMembership>
  /** Creates a message. */
  createMessage?: Maybe<Message>
  /** Create an order. Only one order may be created at a time. */
  createOrder?: Maybe<Order>
  /** Create a page. */
  createPage?: Maybe<Page>
  /** Creates a user's password, only in cases where a user does not already have a password. */
  createPassword?: Maybe<Scalars["Boolean"]["output"]>
  /** creates a path divider, lesson, step, or resource. */
  createPathItem?: Maybe<PathItem>
  /** Create a platform subscription order */
  createPlatformSubscriptionOrder?: Maybe<Order>
  /** Create a registration order */
  createRegistrationOrder?: Maybe<Order>
  /** Potentially creates a new cohort within a Collection (if a cohort does not already exist for the for the provided resource_id). Optionally registers the user to that cohort. */
  createRegistrationUpsertGroup?: Maybe<Group>
  /** Creates a new resource */
  createResource?: Maybe<Resource>
  /** Create a school with a name and domain. Returns the school if successful. */
  createSchool?: Maybe<School>
  /** Create a school subscription order */
  createSchoolSubscriptionOrder?: Maybe<Order>
  /** Create a school theme. */
  createSchoolTheme?: Maybe<Theme>
  /** Creates a store link for a school. */
  createStoreLink?: Maybe<StoreLink>
  /** Create a storefront */
  createStorefront?: Maybe<Storefront>
  /** Creates a new, unattached tag. */
  createTag?: Maybe<Tag>
  /** Creates a new tagAttachment. */
  createTagAttachment?: Maybe<TagAttachment>
  /** Creates a new tagAttachment. */
  createTagLink?: Maybe<TagLink>
  /** Creates a new translation */
  createTranslation?: Maybe<Translation>
  /** Either defer or execute a copy config. */
  deferOrExecuteResourceCopyConfig?: Maybe<DeferOrExecuteResourceCopyConfigResult>
  /** Creat a banner */
  deleteBanner?: Maybe<Scalars["Boolean"]["output"]>
  /** Archives a certificate */
  deleteCertificate?: Maybe<Scalars["Boolean"]["output"]>
  /** Permanently deletes a contextualFeaturePermission */
  deleteContextualFeaturePermission?: Maybe<Scalars["Boolean"]["output"]>
  deleteDiscussion?: Maybe<Scalars["Boolean"]["output"]>
  deleteDiscussionLike?: Maybe<Discussion>
  deleteDiscussionResponse?: Maybe<Scalars["Boolean"]["output"]>
  deleteDiscussionResponseLike?: Maybe<Scalars["Boolean"]["output"]>
  deleteDiscussionSubscription?: Maybe<Discussion>
  /** Deletes an invitation */
  deleteInvitation?: Maybe<Scalars["Boolean"]["output"]>
  /** Deletes a mentor group */
  deleteMentorGroup?: Maybe<Scalars["Boolean"]["output"]>
  /** Deletes a mentor group membership */
  deleteMentorGroupMembership?: Maybe<Scalars["Boolean"]["output"]>
  /** Deletes a message. */
  deleteMessage?: Maybe<Scalars["Boolean"]["output"]>
  /** deletes a path item */
  deletePathItem?: Maybe<PathItem>
  /** Deletes a positioning context and all related positioning objects. */
  deletePositioningContext?: Maybe<Scalars["Boolean"]["output"]>
  /** (Currently) permanently deletes a user's registration and all child registrations (in the case of Collections). */
  deleteRegistration?: Maybe<Scalars["Boolean"]["output"]>
  /** Deletes an existing tag. */
  deleteTag?: Maybe<Scalars["Boolean"]["output"]>
  /** Deletes an existing tagAttachment, permanently. */
  deleteTagAttachment?: Maybe<Scalars["Boolean"]["output"]>
  /** Deletes an existing tagAttachment, permanently. */
  deleteTagLink?: Maybe<Scalars["Boolean"]["output"]>
  /** Hard delete a WebhookEvent. */
  deleteWebhookEvent?: Maybe<Scalars["Boolean"]["output"]>
  /** Complete a deferred copy config by executing a copy config link. */
  executeDeferredResourceCopyConfig?: Maybe<DeferOrExecuteResourceCopyConfigResult>
  executePathSyncPlan?: Maybe<Scalars["Boolean"]["output"]>
  /** Updates user with require first_name and last_name fields, plus creates school membership and fulfills any pending orders. */
  finalizeAccount?: Maybe<User>
  /** Finalizes an external account link for a user in a school, associating them with an external ID after they complete the account linking process. */
  finalizeExternalAccountLink?: Maybe<ExternalAccountLink>
  /** Get a token with a username/email and password. Returns an auth token if successful. */
  getToken?: Maybe<Token>
  /** Update the last time this user was active */
  heartbeat?: Maybe<Scalars["Boolean"]["output"]>
  /** Handles recording consent for a user-space membership, and creating that membership */
  joinSpace?: Maybe<Membership>
  /** Creates a new license for a resource */
  licenseResource?: Maybe<ResourceLicense>
  /** Pastes a path step or lesson from to the given path and location. */
  pastePathItem?: Maybe<PathItem>
  publishPath?: Maybe<Path>
  /** Allows for updating the Space's Pathwright authentication settings. */
  publishPathToCodex?: Maybe<CodexPathPublish>
  /** Rdeems a subscription for the user. */
  redeemSchoolGiftSubscription?: Maybe<SchoolSubscription>
  /** Issues a new token to the currrent user */
  refreshToken?: Maybe<Scalars["String"]["output"]>
  /** Register a push token for user. */
  registerPushToken?: Maybe<Scalars["Boolean"]["output"]>
  /** Revokes access for all members inactive for the the last x days */
  removeAccessForInactiveMembers?: Maybe<Scalars["Boolean"]["output"]>
  /** Remove a push token for user. */
  removePushToken?: Maybe<Scalars["Boolean"]["output"]>
  /** reorders two or more path items */
  reorderPathItems?: Maybe<Scalars["Boolean"]["output"]>
  /** Requests a password reset for a user (admin only) */
  requestPasswordReset?: Maybe<Scalars["Boolean"]["output"]>
  /** Resend an invitation email */
  resendInvitation?: Maybe<Invitation>
  resetCompletionFeedback?: Maybe<Completion>
  /** Resets a user's password based on a valid reset hash token (sent as part of a password reset email link) */
  resetPassword?: Maybe<Scalars["Boolean"]["output"]>
  /** Resets a previously completed or skipped path item */
  resetPathItem?: Maybe<PathItem>
  /** Resets a path item for a user */
  resetPathItemForUser?: Maybe<PathItem>
  saveCompletionFeedback?: Maybe<Completion>
  /** Schedules path item. */
  scheduleItem?: Maybe<PathItem>
  /** Sends email with gifted subscription details to gift recipient. */
  sendGiftSubscriptionEmail?: Maybe<Scalars["Boolean"]["output"]>
  /** Sets the member's access to the school */
  setMemberAccess?: Maybe<Membership>
  /** Archives/unarchives the user's membership to the school */
  setMemberArchived?: Maybe<Membership>
  /** Sets the member's role within the school */
  setMemberRole?: Maybe<Membership>
  /** Sign out the current user */
  signOut?: Maybe<Scalars["Boolean"]["output"]>
  /** Sign up with an email and password. Returns the user if successful. */
  signUp?: Maybe<Token>
  /** Skips a path item for a user */
  skipPathItemForUser?: Maybe<PathItem>
  /** Allows a user to start a request to authenticate to their account outside normal username/password authentication. */
  startAuthRequest?: Maybe<AuthRequest>
  /** Sends a password reset email for a user  */
  startPasswordRetrieval?: Maybe<Scalars["Boolean"]["output"]>
  syncPath?: Maybe<Path>
  /** Unlockss a path item with a password lock */
  unlockPathItem?: Maybe<PathItem>
  /** Update a banner */
  updateBanner?: Maybe<Banner>
  /** Update a banner's order */
  updateBannerOrder?: Maybe<Scalars["Boolean"]["output"]>
  /** Updates Blocks (DB) content with published metadata */
  updateBlocksContent?: Maybe<BlocksContent>
  /** Updates a certificate */
  updateCertificate?: Maybe<Certificate>
  /** Updates the certificate_json keys of all resources */
  updateCertificateVariables?: Maybe<CertificateVariables>
  /** Creates a new contextualFeaturePermission */
  updateContextualFeaturePermission?: Maybe<ContextualFeaturePermission>
  updateDiscussion?: Maybe<Discussion>
  updateDiscussionResponse?: Maybe<Response>
  /** Creates a new cohort for a resource */
  updateGroup?: Maybe<Group>
  updateGroupDiscussionEnabled?: Maybe<Group>
  updateGroupStatus?: Maybe<Group>
  /** Updates an local translation files, used only in development of the Pathwright platform. */
  updateLocalTranslation?: Maybe<Scalars["JSON"]["output"]>
  /** Sets the member's profile data */
  updateMemberConsent?: Maybe<Membership>
  /** Updates an existing mentor group */
  updateMentorGroup?: Maybe<MentorGroup>
  /** Updates a mentor group membership */
  updateMentorGroupMembership?: Maybe<MentorGroupMembership>
  /** Updates a message. */
  updateMessage?: Maybe<Message>
  /** Update a user's notifciation digest interval. */
  updateNotificationDigestInterval?: Maybe<NotificationDigestInterval>
  /** Update a page. */
  updatePage?: Maybe<Page>
  /** Update page orders. */
  updatePageOrders?: Maybe<Scalars["Boolean"]["output"]>
  /** updates a path item */
  updatePathItem?: Maybe<PathItem>
  /** Allows for updating the Space's Pathwright authentication settings. */
  updatePathwrightAuth?: Maybe<Space>
  /** Creates or updates the existing positioning objects for a given context. */
  updatePositionedObjects?: Maybe<Scalars["JSON"]["output"]>
  /** Updates the referral code for the current user */
  updateReferral?: Maybe<Referral>
  /** Creates a new resources */
  updateRegistration?: Maybe<Registration>
  /** Updates an existing resource */
  updateResource?: Maybe<Resource>
  /** Update fields on the school */
  updateSchool?: Maybe<School>
  /** Processes and updates the custom CSS for a school */
  updateSchoolCustomCSS?: Maybe<Theme>
  /** Create a school with a name and domain. Returns the school if successful. */
  updateSchoolProfile?: Maybe<SchoolProfile>
  /** Create a school theme. */
  updateSchoolTheme?: Maybe<Theme>
  /** Updates an existing tag. */
  updateTag?: Maybe<Tag>
  /** Updates a new tagAttachment. Currently only updates the position of the tagAttachment. */
  updateTagAttachment?: Maybe<TagAttachment>
  /** Updates an existing translation */
  updateTranslation?: Maybe<Translation>
  updateUserDiscussionHistory?: Maybe<Discussion>
}

export type MutationsAcceptCommunityGroupInvitationArgs = {
  token: Scalars["String"]["input"]
}

export type MutationsAddPeopleToArgs = {
  notification_message?: InputMaybe<Scalars["String"]["input"]>
  selection: Array<InputMaybe<AddPeopleSelection>>
  send_from_school?: InputMaybe<Scalars["Boolean"]["input"]>
  send_notification?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsApiCompletionUpdateArgs = {
  action: ApiCompletionUpdateAction
  assign_points?: InputMaybe<Scalars["Float"]["input"]>
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  step_source_id: Scalars["Int"]["input"]
  user_email?: InputMaybe<Scalars["String"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsAssignArgs = {
  assign_to_user: Scalars["Int"]["input"]
  assignment_message?: InputMaybe<Scalars["String"]["input"]>
  offerings: Array<InputMaybe<Scalars["Int"]["input"]>>
  role: RegistrationRoleEnum
  send_from_school?: InputMaybe<Scalars["Boolean"]["input"]>
  send_notification?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsBatchCreateMentorGroupMembershipArgs = {
  group: Scalars["Int"]["input"]
  role: MentorGroupMembershipRole
  user_ids?: InputMaybe<Array<Scalars["Int"]["input"]>>
}

export type MutationsCancelSchoolSubscriptionArgs = {
  cancel_immediately?: InputMaybe<Scalars["Boolean"]["input"]>
  for_user?: InputMaybe<Scalars["ID"]["input"]>
}

export type MutationsChangePasswordArgs = {
  current_password: Scalars["String"]["input"]
  password1: Scalars["String"]["input"]
  password2: Scalars["String"]["input"]
}

export type MutationsCompletePathItemArgs = {
  completion_data?: InputMaybe<Scalars["JSON"]["input"]>
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
  skip?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsConfirmAuthRequestArgs = {
  key: Scalars["String"]["input"]
  uuid: Scalars["UUID"]["input"]
}

export type MutationsCopyResourceArgs = {
  create_offering?: InputMaybe<Scalars["Boolean"]["input"]>
  create_registration_with_role?: InputMaybe<RegistrationRoleEnum>
  id: Scalars["Int"]["input"]
  to_school?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsCreateBannerArgs = {
  background_image?: InputMaybe<Scalars["String"]["input"]>
  background_image_raw?: InputMaybe<Scalars["String"]["input"]>
  background_overlay?: InputMaybe<Scalars["String"]["input"]>
  background_type?: InputMaybe<Scalars["Int"]["input"]>
  button_func?: InputMaybe<Scalars["String"]["input"]>
  button_text?: InputMaybe<Scalars["String"]["input"]>
  button_url?: InputMaybe<Scalars["String"]["input"]>
  font_color?: InputMaybe<Scalars["String"]["input"]>
  heading?: InputMaybe<Scalars["String"]["input"]>
  hide_for_products?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  hide_for_subscription_plans?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >
  is_visible?: InputMaybe<Scalars["Boolean"]["input"]>
  show_for_products?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  show_for_subscription_plans?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >
  subhead?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsCreateBlocksContentArgs = {
  content_last_modified?: InputMaybe<Scalars["DateTime"]["input"]>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsCreateCertificateArgs = {
  is_active?: InputMaybe<Scalars["Boolean"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  template: Scalars["String"]["input"]
  text_objects?: InputMaybe<Array<InputMaybe<TextObject>>>
}

export type MutationsCreateContextualFeaturePermissionArgs = {
  context?: InputMaybe<FeatureContextInput>
  feature_action: FeatureActionType
  feature_key: FeatureKeyType
  permission_in_context: FeaturePermissionType
}

export type MutationsCreateCurriculumLicenseOrderArgs = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  license_offering_id: Scalars["Int"]["input"]
  licensee_school_id: Scalars["Int"]["input"]
  licensor_school_id: Scalars["Int"]["input"]
  name?: InputMaybe<Scalars["String"]["input"]>
  role?: InputMaybe<RegistrationRoleEnum>
  seat_count?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsCreateCurriculumSubscriptionOrderArgs = {
  curriculum_plan_id: Scalars["Int"]["input"]
  licensee_id: Scalars["Int"]["input"]
  plan_visibility_code?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsCreateDiscussionArgs = {
  context: DiscussionContextInput
  initialResponse?: InputMaybe<DiscussionResponseInput>
  notify?: InputMaybe<Scalars["Boolean"]["input"]>
  post: DiscussionPostInput
}

export type MutationsCreateDiscussionLikeArgs = {
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
}

export type MutationsCreateDiscussionResponseArgs = {
  context: DiscussionContextInput
  response: DiscussionResponseInput
}

export type MutationsCreateDiscussionResponseLikeArgs = {
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
  response_id: Scalars["Int"]["input"]
}

export type MutationsCreateDiscussionSubscriptionArgs = {
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
}

export type MutationsCreateExternalAccountLinkArgs = {
  external_id: Scalars["String"]["input"]
  link_type?: InputMaybe<ExternalAccountType>
  linked_oidc_idp_id?: InputMaybe<Scalars["Int"]["input"]>
  linked_saml2_idp_id?: InputMaybe<Scalars["Int"]["input"]>
  user_id: Scalars["Int"]["input"]
}

export type MutationsCreateGiftSubscriptionOrderArgs = {
  gift_message?: InputMaybe<Scalars["String"]["input"]>
  num_cycles: Scalars["Int"]["input"]
  plan_id: Scalars["Int"]["input"]
  plan_visibility_code?: InputMaybe<Scalars["String"]["input"]>
  recipient_first_name: Scalars["String"]["input"]
  recipient_last_name: Scalars["String"]["input"]
}

export type MutationsCreateGroupArgs = {
  base_resource_id: Scalars["Int"]["input"]
  name: Scalars["String"]["input"]
  parent_offering_id?: InputMaybe<Scalars["Int"]["input"]>
  role?: InputMaybe<RegistrationRoleEnum>
  visibility: Scalars["Int"]["input"]
}

export type MutationsCreateGroupLicenseOrderArgs = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  license_offering_id: Scalars["Int"]["input"]
  name: Scalars["String"]["input"]
  role?: InputMaybe<RegistrationRoleEnum>
  seat_count?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsCreateGroupSubscriptionOrderArgs = {
  group_name: Scalars["String"]["input"]
  price_id: Scalars["Int"]["input"]
  quantity: Scalars["Int"]["input"]
  redirect_to?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsCreateInvitationsArgs = {
  context?: InputMaybe<InvitationContext>
  invitation_message?: InputMaybe<Scalars["String"]["input"]>
  invitation_subject?: InputMaybe<Scalars["String"]["input"]>
  invite_emails: Array<InputMaybe<Scalars["String"]["input"]>>
  invite_to_cohort_role?: InputMaybe<RegistrationRoleEnum>
  invite_to_community_group_role?: InputMaybe<MentorGroupMembershipRole>
  invite_to_school_role?: InputMaybe<MembershipRole>
  send_from_school?: InputMaybe<Scalars["Boolean"]["input"]>
  type?: InputMaybe<InvitationType>
}

export type MutationsCreateLifetimeSchoolSubscriptionOrderArgs = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  plan_id: Scalars["Int"]["input"]
  plan_visibility_code?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsCreateMembershipForExistingUserArgs = {
  role?: InputMaybe<MembershipRole>
  user_email: Scalars["String"]["input"]
}

export type MutationsCreateMentorGroupArgs = {
  name: Scalars["String"]["input"]
  parent?: InputMaybe<Scalars["Int"]["input"]>
  type?: InputMaybe<MentorGroupType>
}

export type MutationsCreateMentorGroupMembershipArgs = {
  group: Scalars["Int"]["input"]
  role: MentorGroupMembershipRole
  user: Scalars["Int"]["input"]
}

export type MutationsCreateMessageArgs = {
  message: MessageInput
  message_context: MessageContext
}

export type MutationsCreateOrderArgs = {
  curriculum_license?: InputMaybe<CurriculumLicenseOrderData>
  curriculum_subscription?: InputMaybe<CurriculumSubscriptionOrderData>
  gift_subscription?: InputMaybe<GiftSubscriptionOrderData>
  group_license?: InputMaybe<GroupLicenseOrderData>
  group_subscription?: InputMaybe<GroupSubscriptionOrderData>
  lifetime_school_subscription?: InputMaybe<LifetimeSchoolSubscriptionOrderData>
  platform_subscription?: InputMaybe<PlatformSubscriptionOrderData>
  registration?: InputMaybe<RegistrationOrderData>
  school_subscription?: InputMaybe<SchoolSubscriptionOrderData>
}

export type MutationsCreatePageArgs = {
  mode?: InputMaybe<Scalars["String"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  page_type?: InputMaybe<Scalars["Int"]["input"]>
  show_in_nav?: InputMaybe<Scalars["Boolean"]["input"]>
  title?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsCreatePasswordArgs = {
  password1: Scalars["String"]["input"]
  password2: Scalars["String"]["input"]
}

export type MutationsCreatePathItemArgs = {
  assignment_type?: InputMaybe<PathItemAssignmentType>
  content_resource?: InputMaybe<Scalars["Int"]["input"]>
  content_type?: InputMaybe<Scalars["String"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  name: Scalars["String"]["input"]
  order: Scalars["Int"]["input"]
  parent?: InputMaybe<Scalars["Int"]["input"]>
  path_id: Scalars["Int"]["input"]
  type: PathItemType
}

export type MutationsCreatePlatformSubscriptionOrderArgs = {
  billing_plan_id: Scalars["Int"]["input"]
  interval: Scalars["Int"]["input"]
  school_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsCreateRegistrationOrderArgs = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  invitation_code?: InputMaybe<Scalars["String"]["input"]>
  offering_id: Scalars["Int"]["input"]
  use_group_subscription_id?: InputMaybe<Scalars["Int"]["input"]>
  use_subscription_plan_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsCreateRegistrationUpsertGroupArgs = {
  create_registration?: InputMaybe<Scalars["Boolean"]["input"]>
  parent_offering_id: Scalars["Int"]["input"]
  resource_id: Scalars["Int"]["input"]
  step_source_id: Scalars["Int"]["input"]
}

export type MutationsCreateResourceArgs = {
  cover_image: Scalars["String"]["input"]
  group_name?: InputMaybe<Scalars["String"]["input"]>
  group_role?: InputMaybe<RegistrationRoleEnum>
  name: Scalars["String"]["input"]
  resource_type?: InputMaybe<ResourceType>
}

export type MutationsCreateSchoolArgs = {
  copy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  head_markup?: InputMaybe<Scalars["String"]["input"]>
  licensor_id?: InputMaybe<Scalars["Int"]["input"]>
  name: Scalars["String"]["input"]
  script_markup?: InputMaybe<Scalars["String"]["input"]>
  subdomain: Scalars["String"]["input"]
  theme?: InputMaybe<ThemeInput>
  utm_campaign?: InputMaybe<Scalars["String"]["input"]>
  utm_medium?: InputMaybe<Scalars["String"]["input"]>
  utm_source?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsCreateSchoolSubscriptionOrderArgs = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  plan_id: Scalars["Int"]["input"]
  plan_visibility_code?: InputMaybe<Scalars["String"]["input"]>
  redirect_to?: InputMaybe<Scalars["String"]["input"]>
  start_free_trial?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsCreateSchoolThemeArgs = {
  theme: ThemeInput
}

export type MutationsCreateStoreLinkArgs = {
  copy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  school_id: Scalars["Int"]["input"]
}

export type MutationsCreateStorefrontArgs = {
  autoAddPublicResources?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsCreateTagArgs = {
  tag: TagCreateInput
}

export type MutationsCreateTagAttachmentArgs = {
  context: TagAttachmentContextInput
  position?: InputMaybe<Scalars["String"]["input"]>
  tag_id: Scalars["Int"]["input"]
}

export type MutationsCreateTagLinkArgs = {
  context: TagLinkContextInput
  tag_id: Scalars["Int"]["input"]
}

export type MutationsCreateTranslationArgs = {
  translation: Scalars["JSON"]["input"]
}

export type MutationsDeferOrExecuteResourceCopyConfigArgs = {
  copy_code: Scalars["String"]["input"]
  target_school_id: Scalars["Int"]["input"]
}

export type MutationsDeleteBannerArgs = {
  id: Scalars["Int"]["input"]
}

export type MutationsDeleteCertificateArgs = {
  id: Scalars["Int"]["input"]
}

export type MutationsDeleteContextualFeaturePermissionArgs = {
  context?: InputMaybe<FeatureContextInput>
  feature_action: FeatureActionType
  feature_key: FeatureKeyType
}

export type MutationsDeleteDiscussionArgs = {
  context: DiscussionContextInput
  id: Scalars["Int"]["input"]
}

export type MutationsDeleteDiscussionLikeArgs = {
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
}

export type MutationsDeleteDiscussionResponseArgs = {
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
  response_id: Scalars["Int"]["input"]
}

export type MutationsDeleteDiscussionResponseLikeArgs = {
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
  response_id: Scalars["Int"]["input"]
}

export type MutationsDeleteDiscussionSubscriptionArgs = {
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
}

export type MutationsDeleteInvitationArgs = {
  id: Scalars["Int"]["input"]
}

export type MutationsDeleteMentorGroupArgs = {
  id: Scalars["Int"]["input"]
}

export type MutationsDeleteMentorGroupMembershipArgs = {
  group: Scalars["Int"]["input"]
  id: Scalars["Int"]["input"]
}

export type MutationsDeleteMessageArgs = {
  id: Scalars["Int"]["input"]
  message_context: MessageContext
}

export type MutationsDeletePathItemArgs = {
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
}

export type MutationsDeletePositioningContextArgs = {
  context: PositioningContextInput
}

export type MutationsDeleteRegistrationArgs = {
  group_id: Scalars["Int"]["input"]
  registration_id: Scalars["Int"]["input"]
}

export type MutationsDeleteTagArgs = {
  tag: TagDeleteInput
}

export type MutationsDeleteTagAttachmentArgs = {
  context: TagAttachmentContextInput
  tag_id: Scalars["Int"]["input"]
}

export type MutationsDeleteTagLinkArgs = {
  context: TagLinkContextInput
  tag_id: Scalars["Int"]["input"]
}

export type MutationsDeleteWebhookEventArgs = {
  uuid: Scalars["UUID"]["input"]
}

export type MutationsExecuteDeferredResourceCopyConfigArgs = {
  link_id: Scalars["Int"]["input"]
}

export type MutationsExecutePathSyncPlanArgs = {
  sync_plan?: InputMaybe<PathSyncPlanInput>
}

export type MutationsFinalizeAccountArgs = {
  avatar?: InputMaybe<Scalars["String"]["input"]>
  consent?: InputMaybe<Scalars["Boolean"]["input"]>
  first_name: Scalars["String"]["input"]
  last_name: Scalars["String"]["input"]
  password1?: InputMaybe<Scalars["String"]["input"]>
  password2?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsFinalizeExternalAccountLinkArgs = {
  token: Scalars["String"]["input"]
}

export type MutationsGetTokenArgs = {
  invite_token?: InputMaybe<Scalars["String"]["input"]>
  password: Scalars["String"]["input"]
  username: Scalars["String"]["input"]
}

export type MutationsJoinSpaceArgs = {
  consent: Scalars["Boolean"]["input"]
  invite_token?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsLicenseResourceArgs = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  license_offering_id: Scalars["Int"]["input"]
  licensee_school_id?: InputMaybe<Scalars["Int"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  payment_token?: InputMaybe<Scalars["String"]["input"]>
  resource_id: Scalars["Int"]["input"]
  role?: InputMaybe<LicenseRoleEnum>
  seat_count?: InputMaybe<Scalars["Int"]["input"]>
  use_group_subscription_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsPastePathItemArgs = {
  item_id: Scalars["Int"]["input"]
  order: Scalars["Int"]["input"]
  parent_id?: InputMaybe<Scalars["Int"]["input"]>
  path_id: Scalars["Int"]["input"]
}

export type MutationsPublishPathArgs = {
  id: Scalars["Int"]["input"]
}

export type MutationsPublishPathToCodexArgs = {
  override_id?: InputMaybe<Scalars["String"]["input"]>
  path_id: Scalars["Int"]["input"]
  suffix?: InputMaybe<Scalars["String"]["input"]>
  upsert?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsRedeemSchoolGiftSubscriptionArgs = {
  code?: InputMaybe<Scalars["String"]["input"]>
  school_subscription_plan_id: Scalars["ID"]["input"]
}

export type MutationsRefreshTokenArgs = {
  school_id?: InputMaybe<Scalars["ID"]["input"]>
}

export type MutationsRegisterPushTokenArgs = {
  token: Scalars["String"]["input"]
}

export type MutationsRemoveAccessForInactiveMembersArgs = {
  daysInactive?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsRemovePushTokenArgs = {
  token: Scalars["String"]["input"]
}

export type MutationsReorderPathItemsArgs = {
  items: Array<InputMaybe<PathItemOrder>>
  path_id: Scalars["Int"]["input"]
}

export type MutationsRequestPasswordResetArgs = {
  id: Scalars["Int"]["input"]
}

export type MutationsResendInvitationArgs = {
  id: Scalars["Int"]["input"]
}

export type MutationsResetCompletionFeedbackArgs = {
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
}

export type MutationsResetPasswordArgs = {
  password1: Scalars["String"]["input"]
  password2: Scalars["String"]["input"]
  reset_hash: Scalars["String"]["input"]
}

export type MutationsResetPathItemArgs = {
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
}

export type MutationsResetPathItemForUserArgs = {
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
}

export type MutationsSaveCompletionFeedbackArgs = {
  id: Scalars["Int"]["input"]
  notify?: InputMaybe<Scalars["Boolean"]["input"]>
  path_id: Scalars["Int"]["input"]
  userpoints_earned?: InputMaybe<Scalars["Int"]["input"]>
  userpoints_explanation?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsScheduleItemArgs = {
  due?: InputMaybe<Scalars["DateTime"]["input"]>
  passive_due?: InputMaybe<Scalars["Boolean"]["input"]>
  passive_starts?: InputMaybe<Scalars["Boolean"]["input"]>
  schedule_context: ScheduleItemContext
  starts?: InputMaybe<Scalars["DateTime"]["input"]>
}

export type MutationsSendGiftSubscriptionEmailArgs = {
  gift_id: Scalars["Int"]["input"]
  recipient_email: Scalars["String"]["input"]
}

export type MutationsSetMemberAccessArgs = {
  has_access: Scalars["Boolean"]["input"]
  id: Scalars["Int"]["input"]
}

export type MutationsSetMemberArchivedArgs = {
  id: Scalars["Int"]["input"]
  is_archived: Scalars["Boolean"]["input"]
}

export type MutationsSetMemberRoleArgs = {
  id: Scalars["Int"]["input"]
  role: MembershipRole
}

export type MutationsSignUpArgs = {
  consent?: InputMaybe<Scalars["Boolean"]["input"]>
  create_membership?: InputMaybe<Scalars["Boolean"]["input"]>
  email: Scalars["String"]["input"]
  first_name: Scalars["String"]["input"]
  invite_token?: InputMaybe<Scalars["String"]["input"]>
  last_name: Scalars["String"]["input"]
  password?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsSkipPathItemForUserArgs = {
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
}

export type MutationsStartAuthRequestArgs = {
  email?: InputMaybe<Scalars["EmailAddress"]["input"]>
  method: AuthRequestMethod
  next_url?: InputMaybe<Scalars["String"]["input"]>
  username?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsStartPasswordRetrievalArgs = {
  email: Scalars["String"]["input"]
  next_url?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsSyncPathArgs = {
  id: Scalars["Int"]["input"]
}

export type MutationsUnlockPathItemArgs = {
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
  step_lock_password: Scalars["String"]["input"]
}

export type MutationsUpdateBannerArgs = {
  background_image?: InputMaybe<Scalars["String"]["input"]>
  background_image_raw?: InputMaybe<Scalars["String"]["input"]>
  background_overlay?: InputMaybe<Scalars["String"]["input"]>
  background_type?: InputMaybe<Scalars["Int"]["input"]>
  button_func?: InputMaybe<Scalars["String"]["input"]>
  button_text?: InputMaybe<Scalars["String"]["input"]>
  button_url?: InputMaybe<Scalars["String"]["input"]>
  font_color?: InputMaybe<Scalars["String"]["input"]>
  heading?: InputMaybe<Scalars["String"]["input"]>
  hide_for_products?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  hide_for_subscription_plans?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >
  id: Scalars["Int"]["input"]
  is_visible?: InputMaybe<Scalars["Boolean"]["input"]>
  show_for_products?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  show_for_subscription_plans?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >
  subhead?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsUpdateBannerOrderArgs = {
  banner_orders: Array<InputMaybe<ListOrderInput>>
}

export type MutationsUpdateBlocksContentArgs = {
  content_last_modified?: InputMaybe<Scalars["DateTime"]["input"]>
  id?: InputMaybe<Scalars["Int"]["input"]>
  last_published?: InputMaybe<Scalars["DateTime"]["input"]>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsUpdateCertificateArgs = {
  id: Scalars["Int"]["input"]
  name?: InputMaybe<Scalars["String"]["input"]>
  template?: InputMaybe<Scalars["String"]["input"]>
  text_objects?: InputMaybe<Array<InputMaybe<TextObject>>>
}

export type MutationsUpdateCertificateVariablesArgs = {
  context?: InputMaybe<CertificateVariablesContextInput>
  merge: Array<InputMaybe<CertificateVariablesMergeInput>>
}

export type MutationsUpdateContextualFeaturePermissionArgs = {
  context?: InputMaybe<FeatureContextInput>
  feature_action: FeatureActionType
  feature_key: FeatureKeyType
  permission_in_context: FeaturePermissionType
}

export type MutationsUpdateDiscussionArgs = {
  context: DiscussionContextInput
  id: Scalars["Int"]["input"]
  post: DiscussionPostInput
}

export type MutationsUpdateDiscussionResponseArgs = {
  context: DiscussionContextInput
  response: DiscussionResponseInput
  response_id: Scalars["Int"]["input"]
}

export type MutationsUpdateGroupArgs = {
  discussion_enabled?: InputMaybe<Scalars["Boolean"]["input"]>
  group_id: Scalars["Int"]["input"]
  name?: InputMaybe<Scalars["String"]["input"]>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  visibility?: InputMaybe<GroupVisibility>
}

export type MutationsUpdateGroupDiscussionEnabledArgs = {
  discussion_enabled?: InputMaybe<Scalars["Boolean"]["input"]>
  id: Scalars["ID"]["input"]
}

export type MutationsUpdateGroupStatusArgs = {
  id: Scalars["ID"]["input"]
  status?: InputMaybe<GroupStatus>
}

export type MutationsUpdateLocalTranslationArgs = {
  translation: Scalars["JSON"]["input"]
}

export type MutationsUpdateMemberConsentArgs = {
  consents?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsUpdateMentorGroupArgs = {
  description?: InputMaybe<Scalars["String"]["input"]>
  id: Scalars["Int"]["input"]
  name?: InputMaybe<Scalars["String"]["input"]>
  parent?: InputMaybe<Scalars["Int"]["input"]>
  type?: InputMaybe<MentorGroupType>
}

export type MutationsUpdateMentorGroupMembershipArgs = {
  can_review?: InputMaybe<Scalars["Boolean"]["input"]>
  group: Scalars["Int"]["input"]
  has_access?: InputMaybe<Scalars["Boolean"]["input"]>
  id: Scalars["Int"]["input"]
  role?: InputMaybe<MentorGroupMembershipRole>
  send_mentor_digest?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsUpdateMessageArgs = {
  id: Scalars["Int"]["input"]
  message: MessageInput
  message_context: MessageContext
}

export type MutationsUpdateNotificationDigestIntervalArgs = {
  interval?: InputMaybe<NotificationDigestInterval>
}

export type MutationsUpdatePageArgs = {
  content_type?: InputMaybe<Scalars["Int"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  external_url?: InputMaybe<Scalars["String"]["input"]>
  id?: InputMaybe<Scalars["Int"]["input"]>
  iframe_url?: InputMaybe<Scalars["String"]["input"]>
  is_live?: InputMaybe<Scalars["Boolean"]["input"]>
  mode?: InputMaybe<Scalars["String"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  order?: InputMaybe<Scalars["Int"]["input"]>
  page_type?: InputMaybe<Scalars["Int"]["input"]>
  scripts?: InputMaybe<Scalars["String"]["input"]>
  show_in_nav?: InputMaybe<Scalars["Boolean"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
  template_content?: InputMaybe<Scalars["String"]["input"]>
  title?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsUpdatePageOrdersArgs = {
  page_orders?: InputMaybe<Array<InputMaybe<ListOrderInput>>>
}

export type MutationsUpdatePathItemArgs = {
  allow_resets?: InputMaybe<Scalars["Int"]["input"]>
  assignment_type?: InputMaybe<PathItemAssignmentType>
  completion_comment_required_status?: InputMaybe<Scalars["String"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  grading_type?: InputMaybe<Scalars["String"]["input"]>
  is_previewable?: InputMaybe<Scalars["Boolean"]["input"]>
  is_required?: InputMaybe<Scalars["Boolean"]["input"]>
  lock_password?: InputMaybe<Scalars["String"]["input"]>
  minimum_passing_score?: InputMaybe<Scalars["Int"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  order: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
  points?: InputMaybe<Scalars["Int"]["input"]>
  requires_api_completion?: InputMaybe<Scalars["Boolean"]["input"]>
  show_grading_feedback?: InputMaybe<Scalars["Int"]["input"]>
  source_id: Scalars["Int"]["input"]
  time_to_complete?: InputMaybe<Scalars["Int"]["input"]>
  type: PathItemType
}

export type MutationsUpdatePathwrightAuthArgs = {
  allow_public_signup: Scalars["Boolean"]["input"]
  always_prompt_for_password: Scalars["Boolean"]["input"]
  pathwright_login_enabled: Scalars["Boolean"]["input"]
}

export type MutationsUpdatePositionedObjectsArgs = {
  context: PositioningContextInput
  positions: Array<InputMaybe<PositionedObjectPositionInput>>
}

export type MutationsUpdateReferralArgs = {
  referral: Scalars["String"]["input"]
}

export type MutationsUpdateRegistrationArgs = {
  completion_state?: InputMaybe<CompletionStateFilter>
  expiration_dtime?: InputMaybe<Scalars["DateTime"]["input"]>
  id: Scalars["Int"]["input"]
  role?: InputMaybe<RegistrationRoleEnum>
  send_discussion_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
  send_due_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
  softArchivedTime?: InputMaybe<Scalars["DateTime"]["input"]>
}

export type MutationsUpdateResourceArgs = {
  authors?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  categories?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  certificate_generator_class?: InputMaybe<Scalars["String"]["input"]>
  certificate_json?: InputMaybe<Scalars["JSON"]["input"]>
  cover_image?: InputMaybe<Scalars["String"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  discussion_enabled?: InputMaybe<Scalars["Boolean"]["input"]>
  id: Scalars["Int"]["input"]
  name: Scalars["String"]["input"]
  resource_type: ResourceType
  short_description?: InputMaybe<Scalars["String"]["input"]>
  trailer_embed_code?: InputMaybe<Scalars["String"]["input"]>
  visibility_level_override?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsUpdateSchoolArgs = {
  analytics_account_id?: InputMaybe<Scalars["String"]["input"]>
  consent_description?: InputMaybe<Scalars["String"]["input"]>
  grant_access_upon_authentication?: InputMaybe<Scalars["Boolean"]["input"]>
  has_certificate?: InputMaybe<Scalars["Boolean"]["input"]>
  head_markup?: InputMaybe<Scalars["String"]["input"]>
  image?: InputMaybe<Scalars["String"]["input"]>
  intercom_access_token?: InputMaybe<Scalars["String"]["input"]>
  intercom_app_id?: InputMaybe<Scalars["String"]["input"]>
  intercom_secret_key?: InputMaybe<Scalars["String"]["input"]>
  logo_image?: InputMaybe<Scalars["String"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  pathwright_login_enabled?: InputMaybe<Scalars["Boolean"]["input"]>
  script_markup?: InputMaybe<Scalars["String"]["input"]>
  short_description?: InputMaybe<Scalars["String"]["input"]>
  stripe_access_token?: InputMaybe<Scalars["String"]["input"]>
  subdomain?: InputMaybe<Scalars["String"]["input"]>
  support_email?: InputMaybe<Scalars["String"]["input"]>
  support_phone?: InputMaybe<Scalars["String"]["input"]>
  support_policy?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsUpdateSchoolCustomCssArgs = {
  custom_css: Scalars["String"]["input"]
  id: Scalars["Int"]["input"]
  name: Scalars["String"]["input"]
}

export type MutationsUpdateSchoolProfileArgs = {
  coach_or_tutor?: InputMaybe<Scalars["Boolean"]["input"]>
  id: Scalars["Int"]["input"]
  other?: InputMaybe<Scalars["String"]["input"]>
  plan_to_sell?: InputMaybe<Scalars["Boolean"]["input"]>
  publish_multiple_courses?: InputMaybe<Scalars["Boolean"]["input"]>
  publish_one_course?: InputMaybe<Scalars["Boolean"]["input"]>
  school_type?: InputMaybe<SchoolType>
  teach_formal_classes?: InputMaybe<Scalars["Boolean"]["input"]>
  train_team_customers?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsUpdateSchoolThemeArgs = {
  id: Scalars["Int"]["input"]
  theme: ThemeInput
}

export type MutationsUpdateTagArgs = {
  tag: TagUpdateInput
}

export type MutationsUpdateTagAttachmentArgs = {
  context: TagAttachmentContextInput
  position?: InputMaybe<Scalars["String"]["input"]>
  tag_id: Scalars["Int"]["input"]
}

export type MutationsUpdateTranslationArgs = {
  id: Scalars["Int"]["input"]
  translation: Scalars["JSON"]["input"]
}

export type MutationsUpdateUserDiscussionHistoryArgs = {
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
}

export enum NodeColorMode {
  Dark = "dark",
  Light = "light"
}

export enum NodeColorTone {
  Muted = "muted",
  Vibrant = "vibrant"
}

export type NodeImage = {
  __typename?: "NodeImage"
  color?: Maybe<Scalars["String"]["output"]>
  height?: Maybe<Scalars["Int"]["output"]>
  raw_src?: Maybe<Scalars["String"]["output"]>
  src?: Maybe<Scalars["String"]["output"]>
  width?: Maybe<Scalars["Int"]["output"]>
}

export type NodeImageColorArgs = {
  alpah?: InputMaybe<Scalars["Int"]["input"]>
  mode?: InputMaybe<NodeColorMode>
  tone?: InputMaybe<NodeColorTone>
}

export enum NodeImageFit {
  Clamp = "clamp",
  Clip = "clip",
  Crop = "crop",
  Facearea = "facearea",
  Fill = "fill"
}

export enum NodeImageFormat {
  Blurhash = "blurhash",
  Default = "default",
  Gif = "gif",
  Jpg = "jpg",
  Png = "png",
  Webp = "webp"
}

export enum NodeImageMask {
  BannerCover = "banner_cover"
}

export enum NodeImageSize {
  Lg = "lg",
  Md = "md",
  Sm = "sm",
  Xl = "xl"
}

export type NodeMeta = {
  __typename?: "NodeMeta"
  _search?: Maybe<Scalars["String"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  icon?: Maybe<Scalars["String"]["output"]>
  id: Scalars["String"]["output"]
  image?: Maybe<NodeImage>
  key: Scalars["String"]["output"]
  keywords?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  subtitle?: Maybe<Scalars["String"]["output"]>
  title?: Maybe<Scalars["String"]["output"]>
  type: Scalars["String"]["output"]
  url?: Maybe<Scalars["String"]["output"]>
}

export type NodeMetaImageArgs = {
  ar?: InputMaybe<Scalars["String"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  brightness?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<NodeImageFit>
  format?: InputMaybe<NodeImageFormat>
  height?: InputMaybe<Scalars["Int"]["input"]>
  mask?: InputMaybe<NodeImageMask>
  size?: InputMaybe<NodeImageSize>
  vibrance?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

export type NodeTheme = {
  __typename?: "NodeTheme"
  background?: Maybe<NodeThemeBackground>
  color: Scalars["String"]["output"]
  color_palette?: Maybe<NodeThemeColorPalette>
  fonts?: Maybe<NodeThemeFonts>
}

export type NodeThemeBackground = {
  __typename?: "NodeThemeBackground"
  color?: Maybe<Scalars["String"]["output"]>
  contrast_color?: Maybe<Scalars["String"]["output"]>
  image?: Maybe<NodeImage>
  luminence?: Maybe<Scalars["Float"]["output"]>
}

export type NodeThemeBackgroundImageArgs = {
  ar?: InputMaybe<Scalars["String"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  brightness?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<NodeImageFit>
  format?: InputMaybe<NodeImageFormat>
  height?: InputMaybe<Scalars["Int"]["input"]>
  mask?: InputMaybe<NodeImageMask>
  size?: InputMaybe<NodeImageSize>
  vibrance?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

export type NodeThemeColorPalette = {
  __typename?: "NodeThemeColorPalette"
  _50?: Maybe<Scalars["String"]["output"]>
  _100?: Maybe<Scalars["String"]["output"]>
  _200?: Maybe<Scalars["String"]["output"]>
  _300?: Maybe<Scalars["String"]["output"]>
  _400?: Maybe<Scalars["String"]["output"]>
  _500?: Maybe<Scalars["String"]["output"]>
  _600?: Maybe<Scalars["String"]["output"]>
  _700?: Maybe<Scalars["String"]["output"]>
  _800?: Maybe<Scalars["String"]["output"]>
  _900?: Maybe<Scalars["String"]["output"]>
}

export type NodeThemeFonts = {
  __typename?: "NodeThemeFonts"
  body?: Maybe<Scalars["String"]["output"]>
  subtitle?: Maybe<Scalars["String"]["output"]>
  title?: Maybe<Scalars["String"]["output"]>
}

/** Pathwright note */
export type Note = {
  __typename?: "Note"
  body?: Maybe<Scalars["String"]["output"]>
  cohort_id?: Maybe<Scalars["Int"]["output"]>
  context_id?: Maybe<Scalars["Int"]["output"]>
  created_time?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  modified_time?: Maybe<Scalars["DateTime"]["output"]>
  resource_id?: Maybe<Scalars["Int"]["output"]>
  step_id?: Maybe<Scalars["Int"]["output"]>
  title?: Maybe<Scalars["String"]["output"]>
  user?: Maybe<User>
  visibility?: Maybe<NoteVisibility>
}

/** A connection to a list of items. */
export type NoteConnection = {
  __typename?: "NoteConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NoteEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type NoteEdge = {
  __typename?: "NoteEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Note>
}

export enum NoteVisibility {
  Author = "author",
  Everyone = "everyone",
  Staff = "staff"
}

export enum NotificationDigestInterval {
  Daily = "daily",
  Never = "never",
  Weekly = "weekly"
}

/** A Pathwright Order */
export type Order = {
  __typename?: "Order"
  checkout_session_id?: Maybe<Scalars["String"]["output"]>
  fulfilled_object_id?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  order_data?: Maybe<Scalars["JSON"]["output"]>
  order_status?: Maybe<OrderStatus>
  order_type?: Maybe<OrderType>
  processor_id?: Maybe<Scalars["Int"]["output"]>
  school_id?: Maybe<Scalars["Int"]["output"]>
}

/** Order query results by 'asc' or 'desc'. */
export enum OrderByDir {
  Asc = "ASC",
  Desc = "DESC"
}

/** A connection to a list of items. */
export type OrderConnection = {
  __typename?: "OrderConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type OrderEdge = {
  __typename?: "OrderEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Order>
}

export enum OrderStatus {
  AwaitingPayment = "awaiting_payment",
  Fulfilled = "fulfilled",
  FulfillmentFailed = "fulfillment_failed",
  FulfillmentInProgress = "fulfillment_in_progress",
  FulfillmentPending = "fulfillment_pending",
  PaymentFailed = "payment_failed",
  PaymentPending = "payment_pending",
  Voided = "voided"
}

export enum OrderType {
  CurriculumLicense = "curriculum_license",
  CurriculumSubscription = "curriculum_subscription",
  GiftSubscription = "gift_subscription",
  GroupLicense = "group_license",
  GroupSubscription = "group_subscription",
  LifetimeSchoolSubscription = "lifetime_school_subscription",
  PlatformSubscription = "platform_subscription",
  Registration = "registration",
  SchoolSubscription = "school_subscription"
}

/** A Page */
export type Page = {
  __typename?: "Page"
  content_type?: Maybe<Scalars["Int"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  external_url?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  iframe_url?: Maybe<Scalars["String"]["output"]>
  is_live?: Maybe<Scalars["Boolean"]["output"]>
  mode?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  order?: Maybe<Scalars["Int"]["output"]>
  page_type?: Maybe<Scalars["Int"]["output"]>
  scripts?: Maybe<Scalars["String"]["output"]>
  show_in_nav?: Maybe<Scalars["Boolean"]["output"]>
  slug?: Maybe<Scalars["String"]["output"]>
  template_content?: Maybe<Scalars["String"]["output"]>
  title?: Maybe<Scalars["String"]["output"]>
}

/** A connection to a list of items. */
export type PageConnection = {
  __typename?: "PageConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PageEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type PageEdge = {
  __typename?: "PageEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Page>
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo"
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]["output"]>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"]
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"]
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]["output"]>
}

/** A path */
export type Path = {
  __typename?: "Path"
  analytics?: Maybe<PathAnalytics>
  cohort?: Maybe<Group>
  completion_date?: Maybe<Scalars["DateTime"]["output"]>
  created_date?: Maybe<Scalars["DateTime"]["output"]>
  id: Scalars["Int"]["output"]
  items?: Maybe<PathItemConnection>
  /** @deprecated Deprecated in favor of sync.last_synced_date */
  last_synced_dtime?: Maybe<Scalars["DateTime"]["output"]>
  meta: NodeMeta
  next_step_id?: Maybe<Scalars["Int"]["output"]>
  performance?: Maybe<PathPerformance>
  points?: Maybe<Points>
  previewable_steps?: Maybe<PathItemConnection>
  progress?: Maybe<Scalars["Float"]["output"]>
  publish?: Maybe<PathPublish>
  published_changes?: Maybe<PathChangePublishConnection>
  schedule?: Maybe<PathNodeSchedule>
  sync?: Maybe<PathSync>
  theme: NodeTheme
  user?: Maybe<User>
}

/** A path */
export type PathItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  flatten?: InputMaybe<Scalars["Boolean"]["input"]>
  type?: InputMaybe<PathItemType>
}

/** A path */
export type PathPreviewable_StepsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A path */
export type PathPublished_ChangesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** Analytics data for a path or path item */
export type PathAnalytics = {
  __typename?: "PathAnalytics"
  avg_score?: Maybe<Scalars["Float"]["output"]>
  /** The number of users who have completed the whole path */
  complete?: Maybe<Scalars["Int"]["output"]>
  completion_rate?: Maybe<Scalars["Float"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  /** The number of users who still are working on this path */
  incomplete?: Maybe<Scalars["Int"]["output"]>
  item?: Maybe<PathItem>
  items?: Maybe<Scalars["JSON"]["output"]>
  /** The number of step completions in the path that need grading */
  need_feedback?: Maybe<Scalars["Int"]["output"]>
  next_count?: Maybe<Scalars["Int"]["output"]>
  path_id?: Maybe<Scalars["Int"]["output"]>
  /** This is a list of up to 10 users who have completed this path (used to render avatar stacks).  */
  students_here?: Maybe<Scalars["JSON"]["output"]>
  /** The number of earned points across all steps in this path (Note: this excludes steps that aren't due if due dates are in use) */
  total_points?: Maybe<Scalars["Int"]["output"]>
  /** The number of available points across all completed and past due student steps in this path */
  total_points_available?: Maybe<Scalars["Int"]["output"]>
  total_users?: Maybe<Scalars["Int"]["output"]>
}

/** A published set of changes made to a path */
export type PathChangePublish = {
  __typename?: "PathChangePublish"
  id?: Maybe<Scalars["Int"]["output"]>
  message?: Maybe<Scalars["String"]["output"]>
  path_id?: Maybe<Scalars["Int"]["output"]>
  published_by_id?: Maybe<Scalars["Int"]["output"]>
  published_dtime?: Maybe<Scalars["DateTime"]["output"]>
}

/** A connection to a list of items. */
export type PathChangePublishConnection = {
  __typename?: "PathChangePublishConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PathChangePublishEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type PathChangePublishEdge = {
  __typename?: "PathChangePublishEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<PathChangePublish>
}

/** An item in a path (Lesson, Topic, Step) */
export type PathItem = {
  __typename?: "PathItem"
  allow_resets?: Maybe<Scalars["Int"]["output"]>
  analytics?: Maybe<ItemAnalytics>
  assignment_type?: Maybe<PathItemAssignmentType>
  blocks?: Maybe<BlocksContent>
  /** This dynamically calcuates progress for lessons. Avoid for long result sets as it's an N+1 query! */
  calculated_progress?: Maybe<Scalars["Float"]["output"]>
  cohort?: Maybe<Group>
  cohort_id?: Maybe<Scalars["Int"]["output"]>
  cohort_name?: Maybe<Scalars["String"]["output"]>
  completion?: Maybe<Completion>
  completion_comment_required_status?: Maybe<Scalars["String"]["output"]>
  /** @deprecated It's inefficient to load content for steps in a loop (N+2). Instead, check the content_id and item_content_type_id and load on demand */
  content?: Maybe<StepContent>
  content_id?: Maybe<Scalars["Int"]["output"]>
  content_type?: Maybe<Scalars["String"]["output"]>
  context?: Maybe<StepContext>
  curriculum_sync_opt_out_dtime?: Maybe<Scalars["DateTime"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  due?: Maybe<Scalars["DateTime"]["output"]>
  grading_type?: Maybe<Scalars["String"]["output"]>
  has_started?: Maybe<Scalars["Boolean"]["output"]>
  id: Scalars["Int"]["output"]
  is_locked?: Maybe<Scalars["Boolean"]["output"]>
  is_previewable?: Maybe<Scalars["Boolean"]["output"]>
  is_required?: Maybe<Scalars["Boolean"]["output"]>
  item_content_type_id?: Maybe<Scalars["Int"]["output"]>
  item_needs_grading?: Maybe<Scalars["Boolean"]["output"]>
  items?: Maybe<PathItemConnection>
  last_published_dtime?: Maybe<Scalars["DateTime"]["output"]>
  linked_cohort?: Maybe<Group>
  linked_registration?: Maybe<Registration>
  linked_resource?: Maybe<Resource>
  lock_password?: Maybe<Scalars["String"]["output"]>
  lock_unlocked_time?: Maybe<Scalars["DateTime"]["output"]>
  meta: NodeMeta
  minimum_passing_score?: Maybe<Scalars["Int"]["output"]>
  name: Scalars["String"]["output"]
  order: Scalars["Int"]["output"]
  parent?: Maybe<PathItem>
  parent_id?: Maybe<Scalars["Int"]["output"]>
  parent_name?: Maybe<Scalars["String"]["output"]>
  parent_source_id?: Maybe<Scalars["Int"]["output"]>
  passive_due?: Maybe<Scalars["Boolean"]["output"]>
  passive_starts?: Maybe<Scalars["Boolean"]["output"]>
  path?: Maybe<Path>
  path_id: Scalars["Int"]["output"]
  permissions?: Maybe<PathItemPermissions>
  points?: Maybe<Scalars["Int"]["output"]>
  progress: Scalars["Float"]["output"]
  reminders?: Maybe<MessageConnection>
  resource?: Maybe<Resource>
  resource_name?: Maybe<Scalars["String"]["output"]>
  show_grading_feedback?: Maybe<Scalars["Int"]["output"]>
  source_id: Scalars["Int"]["output"]
  starts?: Maybe<Scalars["DateTime"]["output"]>
  time_to_complete?: Maybe<Scalars["Int"]["output"]>
  type: PathItemType
  url?: Maybe<Scalars["String"]["output"]>
  verb?: Maybe<Scalars["String"]["output"]>
}

/** An item in a path (Lesson, Topic, Step) */
export type PathItemItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** An item in a path (Lesson, Topic, Step) */
export type PathItemRemindersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

export enum PathItemAssignmentType {
  Assessment = "assessment",
  Audio = "audio",
  Completion = "completion",
  Event = "event",
  Reading = "reading",
  Submission = "submission",
  Video = "video"
}

/** A connection to a list of items. */
export type PathItemConnection = {
  __typename?: "PathItemConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PathItemEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type PathItemEdge = {
  __typename?: "PathItemEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<PathItem>
}

export type PathItemOrder = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  order: Scalars["Int"]["input"]
  parent_id?: InputMaybe<Scalars["Int"]["input"]>
  source_id: Scalars["Int"]["input"]
}

export type PathItemPermissions = {
  __typename?: "PathItemPermissions"
  can_complete?: Maybe<Scalars["Boolean"]["output"]>
  can_discuss?: Maybe<Scalars["Boolean"]["output"]>
  can_edit?: Maybe<Scalars["Boolean"]["output"]>
  can_edit_content?: Maybe<Scalars["Boolean"]["output"]>
  can_note?: Maybe<Scalars["Boolean"]["output"]>
  can_review?: Maybe<Scalars["Boolean"]["output"]>
  can_view?: Maybe<Scalars["Boolean"]["output"]>
  can_view_content?: Maybe<Scalars["Boolean"]["output"]>
}

/** Status for path items */
export type PathItemStats = {
  __typename?: "PathItemStats"
  path_items_needing_review?: Maybe<Scalars["Int"]["output"]>
}

export enum PathItemType {
  Divider = "divider",
  Lesson = "lesson",
  Resource = "resource",
  Step = "step"
}

export enum PathItemTypes {
  Divider = "divider",
  Lesson = "lesson",
  Resource = "resource",
  Step = "step"
}

export type PathNodeSchedule = {
  __typename?: "PathNodeSchedule"
  end?: Maybe<ScheduleDate>
  start?: Maybe<ScheduleDate>
}

/** A path's performance data */
export type PathPerformance = {
  __typename?: "PathPerformance"
  id?: Maybe<Scalars["Int"]["output"]>
  points?: Maybe<PathPerformancePoints>
  progress?: Maybe<PathPerformanceProgress>
}

export type PathPerformancePoints = {
  __typename?: "PathPerformancePoints"
  applicable_score?: Maybe<Scalars["JSON"]["output"]>
  applicable_score_percentage?: Maybe<Scalars["Float"]["output"]>
  earned?: Maybe<Scalars["Int"]["output"]>
  percentage?: Maybe<Scalars["Float"]["output"]>
  possible?: Maybe<Scalars["Int"]["output"]>
  total?: Maybe<Scalars["Int"]["output"]>
}

export type PathPerformanceProgress = {
  __typename?: "PathPerformanceProgress"
  percentage?: Maybe<Scalars["Float"]["output"]>
  required_steps_completed?: Maybe<Scalars["Int"]["output"]>
  steps_completed?: Maybe<Scalars["Int"]["output"]>
  total_required_steps?: Maybe<Scalars["Int"]["output"]>
  total_steps?: Maybe<Scalars["Int"]["output"]>
}

/** A Path's publish status. */
export type PathPublish = {
  __typename?: "PathPublish"
  /** The date when the target path was last published. */
  last_published_date?: Maybe<Scalars["DateTime"]["output"]>
  /** The target path. */
  path_id: Scalars["Int"]["output"]
  /** The target path is considered published if it has no unpublished changes (and has been published in the past). */
  published: Scalars["Boolean"]["output"]
}

/** Status of whether a path needs to be synced with its source path */
export type PathSync = {
  __typename?: "PathSync"
  /** The date when the target path was last synced. */
  last_synced_date?: Maybe<Scalars["DateTime"]["output"]>
  /** The target path. */
  path_id: Scalars["Int"]["output"]
  /** Always points to the path from which the target path syncs. Will be null in the case where the target path is a source path. */
  sync_source_path_id?: Maybe<Scalars["Int"]["output"]>
  /** Whether the target path has been synced with the latest changes from the source path. */
  synced: Scalars["Boolean"]["output"]
  /** The target path user (if present). */
  user_id?: Maybe<Scalars["Int"]["output"]>
}

/** Sync plan for a path */
export type PathSyncPlan = {
  __typename?: "PathSyncPlan"
  excluded_changes: Array<Maybe<PathSyncPlanChange>>
  from_path_id?: Maybe<Scalars["Int"]["output"]>
  hash: Scalars["String"]["output"]
  included_changes: Array<Maybe<PathSyncPlanChange>>
  to_path_id?: Maybe<Scalars["Int"]["output"]>
}

/** A single change of a PathSyncPlan */
export type PathSyncPlanChange = {
  __typename?: "PathSyncPlanChange"
  assignment_type?: Maybe<PathItemAssignmentType>
  change_details?: Maybe<Scalars["JSON"]["output"]>
  forced?: Maybe<PathSyncPlanForceType>
  forced_reasons?: Maybe<Array<Maybe<PathSyncPlanForceReasonType>>>
  item_name: Scalars["String"]["output"]
  item_source_id: Scalars["Int"]["output"]
  item_type: PathItemType
  last_changed?: Maybe<Scalars["DateTime"]["output"]>
  parent_name?: Maybe<Scalars["String"]["output"]>
  parent_source_id?: Maybe<Scalars["Int"]["output"]>
}

export enum PathSyncPlanForceReasonType {
  /** Excluded due to lesson not containing any steps with content */
  ExcludeLessonEmpty = "exclude_lesson_empty",
  /** Excluded due to step having no content */
  ExcludeStepNoContent = "exclude_step_no_content",
  /** Excluded upon user request */
  ExcludeUserRequest = "exclude_user_request",
  /** Included upon user request */
  IncludeUserRequest = "include_user_request"
}

export enum PathSyncPlanForceType {
  /** The path item change is force included and force excluded requiring a conflict to be resolved */
  Conflict = "conflict",
  /** The path item change cannot be included in sync */
  Exclude = "exclude",
  /** The path item change cannot be excluded from sync */
  Include = "include"
}

export type PathSyncPlanInput = {
  /** The source IDs of the path items to exclude from the sync plan. */
  exclude_source_ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  /** The ID of the path from which to generate the sync plan. */
  from_path_id: Scalars["Int"]["input"]
  /** When executing a sync plan, the generated sync plan hash is required to ensure no changes have occurred between generating and executing the sync plan. */
  hash?: InputMaybe<Scalars["String"]["input"]>
  /** The source IDs of the path items to include from the sync plan. */
  include_source_ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  /** The ID of the path against which to generate the sync plan. If not provided, the sync plan for the path will be derived from the diff of its current state and unpublished changes. */
  to_path_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A node in the Paths codex data structure */
export type PathsCodexNode = {
  __typename?: "PathsCodexNode"
  key?: Maybe<Scalars["String"]["output"]>
  type?: Maybe<Scalars["String"]["output"]>
  version?: Maybe<Scalars["Int"]["output"]>
}

/** A Pathwright admin scope for cool data */
export type PathwrightAdmin = {
  __typename?: "PathwrightAdmin"
  schools?: Maybe<SchoolConnection>
}

/** A Pathwright admin scope for cool data */
export type PathwrightAdminSchoolsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  licensing_from?: InputMaybe<Scalars["Int"]["input"]>
  school_subscription_plan_id?: InputMaybe<Scalars["Int"]["input"]>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright Payment */
export type Payment = {
  __typename?: "Payment"
  amount?: Maybe<Scalars["Float"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
}

export type PeopleAndGroupConnection = {
  __typename?: "PeopleAndGroupConnection"
  id?: Maybe<Scalars["Int"]["output"]>
  /** The meta data for this 'type'. */
  meta: NodeMeta
  /** The number of people attached to this 'type' */
  people_count?: Maybe<Scalars["Int"]["output"]>
  /** The role of the 'user' type in the queried context. Will be null for all other types. */
  role?: Maybe<Role>
  role_in?: Maybe<Role>
  /** The type of the people source. */
  type?: Maybe<PeopleAndGroupConnectionType>
}

/** A connection to a list of items. */
export type PeopleAndGroupConnectionConnection = {
  __typename?: "PeopleAndGroupConnectionConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PeopleAndGroupConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

export type PeopleAndGroupConnectionContext = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  group_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** An edge in a connection. */
export type PeopleAndGroupConnectionEdge = {
  __typename?: "PeopleAndGroupConnectionEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<PeopleAndGroupConnection>
}

export enum PeopleAndGroupConnectionType {
  Cohort = "cohort",
  Group = "group",
  User = "user"
}

/** Platform Config */
export type PlatformConfig = {
  __typename?: "PlatformConfig"
  id?: Maybe<Scalars["Int"]["output"]>
  payments_maintenance_mode?: Maybe<Scalars["Boolean"]["output"]>
}

/** Platform subscription order data */
export type PlatformSubscriptionOrderData = {
  billing_plan_id: Scalars["Int"]["input"]
  interval: Scalars["Int"]["input"]
  /** Optionally provide the school ID of the school creating the order. */
  school_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type Points = {
  __typename?: "Points"
  /** Sum of the `userpoints_earned` for each "scoreable" path item completion */
  earned?: Maybe<Scalars["Int"]["output"]>
  /** Whether any path item on the path has `userpoints_available` */
  has_points?: Maybe<Scalars["Boolean"]["output"]>
  path_id?: Maybe<Scalars["Int"]["output"]>
  /** Percentage of earned / total */
  percentage?: Maybe<Scalars["Float"]["output"]>
  /** Sum of the `userpoints_available` for each "scoreable" path item completion */
  total?: Maybe<Scalars["Int"]["output"]>
  /** Sum of `userpoints_available` for each path item */
  total_points_possible?: Maybe<Scalars["Int"]["output"]>
}

/** Assign a lexicographical position to a positioned object */
export type PositionedObjectPositionInput = {
  /** A unique key for this object within the context. For example, registration:1234 for a specific registration on a user's home screen. */
  objKey: Scalars["NonEmptyString"]["input"]
  /** The lexicographical position used for fractional indexing. */
  objPosition: Scalars["NonEmptyString"]["input"]
}

/** Assign the context for positioning an object. */
export type PositioningContextInput = {
  /** A unique key for this context within the school. For example, user:1234 for a user's home screen. */
  contextKey: Scalars["NonEmptyString"]["input"]
  /** The type of context to position the object within. For example, 'home_screen' for a user's home screen. */
  contextType: PositioningContextTypeInput
}

/** The positioning context type. */
export enum PositioningContextTypeInput {
  /** Positioning objects on a user's homescreen. */
  HomeScreen = "HOME_SCREEN",
  /** Positioning objects in the Subscribe flow. */
  SubscribeFlow = "SUBSCRIBE_FLOW"
}

/** A Pathwright User Profile */
export type Profile = {
  __typename?: "Profile"
  bio?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  image?: Maybe<Scalars["String"]["output"]>
  last_activity?: Maybe<Scalars["DateTime"]["output"]>
  location?: Maybe<Scalars["String"]["output"]>
  notification_digest_interval?: Maybe<NotificationDigestInterval>
  rewardful_referral_id?: Maybe<Scalars["String"]["output"]>
}

/** A Pathwright User Profile */
export type ProfileImageArgs = {
  balph?: InputMaybe<Scalars["Int"]["input"]>
  blend?: InputMaybe<Scalars["Int"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  bm?: InputMaybe<Scalars["String"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  fm?: InputMaybe<Scalars["String"]["input"]>
  height?: InputMaybe<Scalars["Int"]["input"]>
  high?: InputMaybe<Scalars["Int"]["input"]>
  q?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

export type ProgressFilter = {
  eq?: InputMaybe<Scalars["Float"]["input"]>
  gt?: InputMaybe<Scalars["Float"]["input"]>
  gte?: InputMaybe<Scalars["Float"]["input"]>
  in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>
  lt?: InputMaybe<Scalars["Float"]["input"]>
  lte?: InputMaybe<Scalars["Float"]["input"]>
  neq?: InputMaybe<Scalars["Float"]["input"]>
  nin?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School Publisher */
export type Publisher = {
  __typename?: "Publisher"
  about?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  image?: Maybe<Scalars["String"]["output"]>
  logo?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  theme?: Maybe<Theme>
  website?: Maybe<Scalars["String"]["output"]>
}

/** A Pathwright School Publisher */
export type PublisherImageArgs = {
  balph?: InputMaybe<Scalars["Int"]["input"]>
  blend?: InputMaybe<Scalars["Int"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  bm?: InputMaybe<Scalars["String"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  fm?: InputMaybe<Scalars["String"]["input"]>
  height?: InputMaybe<Scalars["Int"]["input"]>
  high?: InputMaybe<Scalars["Int"]["input"]>
  q?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright School Publisher */
export type PublisherLogoArgs = {
  balph?: InputMaybe<Scalars["Int"]["input"]>
  blend?: InputMaybe<Scalars["Int"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  bm?: InputMaybe<Scalars["String"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  fm?: InputMaybe<Scalars["String"]["input"]>
  height?: InputMaybe<Scalars["Int"]["input"]>
  high?: InputMaybe<Scalars["Int"]["input"]>
  q?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** A connection to a list of items. */
export type PublisherConnection = {
  __typename?: "PublisherConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PublisherEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type PublisherEdge = {
  __typename?: "PublisherEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Publisher>
}

/** Pathwright GraphQL Endpoint */
export type Query = {
  __typename?: "Query"
  account?: Maybe<SchoolAcount>
  activity?: Maybe<ActivityConnection>
  activityStats?: Maybe<ActivityStats>
  admin?: Maybe<PathwrightAdmin>
  assignRoles: Array<Maybe<RegistrationRoleEnum>>
  authRequest?: Maybe<AuthRequest>
  /** The auth token resulting from a confirmed auth request made by the user to authenticate by some other means than username/password. */
  authRequestToken?: Maybe<Scalars["JWT"]["output"]>
  author?: Maybe<Author>
  authors?: Maybe<AuthorConnection>
  backgroundTask?: Maybe<BackgroundTask>
  backgroundTasks?: Maybe<Array<Maybe<BackgroundTask>>>
  calendar?: Maybe<Calendar>
  calendarStats?: Maybe<CalendarStats>
  canReview?: Maybe<Scalars["Boolean"]["output"]>
  categories?: Maybe<CategoryConnection>
  category?: Maybe<Category>
  certificate?: Maybe<Certificate>
  certificatePreview?: Maybe<Scalars["String"]["output"]>
  certificateVariables?: Maybe<CertificateVariables>
  certificates?: Maybe<CertificateConnection>
  codex?: Maybe<Codex>
  computedDiscount?: Maybe<ComputedDiscount>
  context?: Maybe<AccountContext>
  contextualFeaturePermission?: Maybe<ContextualFeaturePermission>
  contextualFeaturePermissions: Array<Maybe<ContextualFeaturePermission>>
  curriculumSubscription?: Maybe<CurriculumSubscription>
  discussion?: Maybe<Discussion>
  discussionParticipants?: Maybe<DiscussionParticipantsConnection>
  discussions?: Maybe<DiscussionConnection>
  feedbackReviewers?: Maybe<FeedbackReviewers>
  gravatar?: Maybe<Gravatar>
  group?: Maybe<Group>
  groups?: Maybe<GroupConnection>
  inboxContext?: Maybe<InboxContext>
  inboxGroupConnections?: Maybe<InboxGroupConnectionConnection>
  inboxGroups?: Maybe<InboxMentorGroupConnection>
  inboxPaths?: Maybe<InboxPathConnection>
  inboxPendingReview?: Maybe<InboxPendingReview>
  inboxPeopleCompletionCounts?: Maybe<InboxPeopleCompletionCountsConnection>
  inboxReviewers?: Maybe<InboxReviwerConnection>
  integrations?: Maybe<Integrations>
  invitationToken?: Maybe<InvitationToken>
  invitations?: Maybe<InvitationConnection>
  license_offering?: Maybe<LicenseOffering>
  loginMethod?: Maybe<LoginInfo>
  loginMethods?: Maybe<Array<LoginInfo>>
  me?: Maybe<User>
  media?: Maybe<Media>
  mediaContext?: Maybe<MediaContext>
  membership?: Maybe<Membership>
  membership_meta?: Maybe<MembershipMeta>
  mentorGroupMembership?: Maybe<MentorGroupMembership>
  message?: Maybe<Message>
  messages?: Maybe<MessageConnection>
  note?: Maybe<Note>
  notes?: Maybe<NoteConnection>
  order?: Maybe<Order>
  orders?: Maybe<OrderConnection>
  path?: Maybe<Path>
  pathItem?: Maybe<PathItem>
  pathItemStats?: Maybe<PathItemStats>
  pathItems?: Maybe<PathItemConnection>
  pathSyncPlan?: Maybe<PathSyncPlan>
  peopleAndGroupConnection?: Maybe<PeopleAndGroupConnection>
  peopleAndGroupConnections?: Maybe<PeopleAndGroupConnectionConnection>
  platformConfig?: Maybe<PlatformConfig>
  report?: Maybe<Scalars["JSON"]["output"]>
  resource?: Maybe<Resource>
  resourceCopyConfig?: Maybe<ResourceCopyConfig>
  resourceCopyConfigConnections?: Maybe<ResourceCopyConfigConnection>
  resourceImage: Array<Maybe<Scalars["URL"]["output"]>>
  resource_license?: Maybe<ResourceLicense>
  resource_licenses?: Maybe<ResourceLicenseConnection>
  resources?: Maybe<ResourceConnection>
  responses?: Maybe<ResponseConnection>
  restAPIGet?: Maybe<Scalars["JSON"]["output"]>
  school?: Maybe<School>
  schoolGroupSubscription?: Maybe<SchoolGroupSubscription>
  schoolGroupSubscriptions?: Maybe<SchoolGroupSubscriptionConnection>
  schoolSubscriptionDiscount?: Maybe<SchoolSubscriptionDiscount>
  sharePermissions?: Maybe<SharePermissions>
  /** Returns the current space (from request header */
  space: Space
  /** A list of spaces the user has access to */
  spaceConnections?: Maybe<SpaceConnectionConnection>
  store?: Maybe<Store>
  storeBanners?: Maybe<Array<Maybe<StoreBanner>>>
  storeCollection?: Maybe<StoreCollection>
  storeCollections?: Maybe<Array<Maybe<StoreCollection>>>
  storeItem?: Maybe<StoreItem>
  storeLink?: Maybe<StoreLink>
  storeLinks?: Maybe<StoreLinkConnection>
  stripeProduct?: Maybe<StripeProduct>
  stripeProducts?: Maybe<StripeProductConnection>
  subdomain?: Maybe<Subdomain>
  subscription?: Maybe<Subscription>
  subscriptions?: Maybe<SubscriptionConnection>
  tag?: Maybe<Tag>
  tagAttachment?: Maybe<TagAttachment>
  tagAttachments?: Maybe<TagAttachmentConnection>
  tags?: Maybe<TagConnection>
  theme?: Maybe<Theme>
  translation?: Maybe<Translation>
  user?: Maybe<User>
  userStatus?: Maybe<UserStatus>
  webhookEventConnections?: Maybe<WebhookEventConnection>
}

/** Pathwright GraphQL Endpoint */
export type QueryActivityArgs = {
  action_type?: InputMaybe<ActionTypeFilter>
  after?: InputMaybe<Scalars["String"]["input"]>
  date?: InputMaybe<DateFilter>
  first: Scalars["Int"]["input"]
  group_id?: InputMaybe<Scalars["Int"]["input"]>
  interest_level?: InputMaybe<InterestLevel>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryAuthRequestArgs = {
  uuid: Scalars["String"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryAuthRequestTokenArgs = {
  uuid: Scalars["String"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryAuthorArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryAuthorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  ids?: InputMaybe<IdFilter>
  manage?: InputMaybe<Scalars["Boolean"]["input"]>
  resource_author_ids?: InputMaybe<IdFilter>
  resource_author_names?: InputMaybe<StringFilter>
  resource_category_ids?: InputMaybe<IdFilter>
  resource_category_names?: InputMaybe<StringFilter>
  resource_search?: InputMaybe<Scalars["String"]["input"]>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryBackgroundTaskArgs = {
  id: Scalars["String"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryBackgroundTasksArgs = {
  ids: Array<InputMaybe<Scalars["String"]["input"]>>
}

/** Pathwright GraphQL Endpoint */
export type QueryCalendarArgs = {
  path_id?: InputMaybe<Scalars["ID"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryCalendarStatsArgs = {
  path_id?: InputMaybe<Scalars["ID"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryCanReviewArgs = {
  for_user_id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  ids?: InputMaybe<IdFilter>
  manage?: InputMaybe<Scalars["Boolean"]["input"]>
  resource_author_ids?: InputMaybe<IdFilter>
  resource_author_names?: InputMaybe<StringFilter>
  resource_category_ids?: InputMaybe<IdFilter>
  resource_category_names?: InputMaybe<StringFilter>
  resource_search?: InputMaybe<Scalars["String"]["input"]>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryCategoryArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryCertificateArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryCertificatePreviewArgs = {
  template: Scalars["String"]["input"]
  text_objects: Array<InputMaybe<TextObject>>
  variables?: InputMaybe<Scalars["JSON"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryCertificateVariablesArgs = {
  context?: InputMaybe<CertificateVariablesContextInput>
}

/** Pathwright GraphQL Endpoint */
export type QueryCertificatesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryComputedDiscountArgs = {
  discount_code: Scalars["String"]["input"]
  license_offering_id?: InputMaybe<Scalars["Int"]["input"]>
  offering_id?: InputMaybe<Scalars["Int"]["input"]>
  resource_id: Scalars["Int"]["input"]
  seat_count?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryContextArgs = {
  auth_token_id?: InputMaybe<Scalars["String"]["input"]>
  school_id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryContextualFeaturePermissionArgs = {
  context?: InputMaybe<FeatureContextInput>
  feature_action: FeatureActionType
  feature_key: FeatureKeyType
}

/** Pathwright GraphQL Endpoint */
export type QueryContextualFeaturePermissionsArgs = {
  context?: InputMaybe<FeatureContextInput>
  feature_action?: InputMaybe<FeatureActionType>
  feature_key?: InputMaybe<FeatureKeyType>
  feature_keys?: InputMaybe<FeatureKeyFilter>
}

/** Pathwright GraphQL Endpoint */
export type QueryCurriculumSubscriptionArgs = {
  id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryDiscussionArgs = {
  context: DiscussionContextInput
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryDiscussionParticipantsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
  first: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryDiscussionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context?: InputMaybe<DiscussionContextInput>
  first: Scalars["Int"]["input"]
  is_discussion_question?: InputMaybe<Scalars["Boolean"]["input"]>
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  tag_id_filter?: InputMaybe<IntFilter>
  total_responses?: InputMaybe<IntFilter>
  type?: InputMaybe<DiscussionTypeInput>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryFeedbackReviewersArgs = {
  completion_id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryGravatarArgs = {
  email: Scalars["String"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryGroupArgs = {
  id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  assign_config?: InputMaybe<AssignConfig>
  first: Scalars["Int"]["input"]
  include_curriculum?: InputMaybe<Scalars["Boolean"]["input"]>
  is_master?: InputMaybe<Scalars["Boolean"]["input"]>
  parent_group_id?: InputMaybe<IdFilter>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  role?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  status?: InputMaybe<GroupStatus>
  visibility?: InputMaybe<GroupVisibility>
  visibility_filter?: InputMaybe<GroupVisibilityFilter>
}

/** Pathwright GraphQL Endpoint */
export type QueryInboxContextArgs = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  group_id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryInboxGroupConnectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryInboxGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  status?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryInboxPathsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryInboxPendingReviewArgs = {
  target_id: Scalars["Int"]["input"]
  target_type: InboxPendingReviewTarget
}

/** Pathwright GraphQL Endpoint */
export type QueryInboxPeopleCompletionCountsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  is_complete?: InputMaybe<Scalars["Boolean"]["input"]>
  needs_review?: InputMaybe<Scalars["Boolean"]["input"]>
  parent_source_id?: InputMaybe<Scalars["Int"]["input"]>
  step_source_id?: InputMaybe<Scalars["Int"]["input"]>
  target_id: Scalars["Int"]["input"]
  target_type: InboxPeopleCompletionCountsTarget
}

/** Pathwright GraphQL Endpoint */
export type QueryInboxReviewersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  path_id?: InputMaybe<Scalars["Int"]["input"]>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryInvitationTokenArgs = {
  token: Scalars["NonEmptyString"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryInvitationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context?: InputMaybe<InvitationContext>
  delivery_status?: InputMaybe<EmailDeliveryStatus>
  first: Scalars["Int"]["input"]
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  search?: InputMaybe<Scalars["String"]["input"]>
  status?: InputMaybe<InvitationStatus>
  type?: InputMaybe<InvitationType>
}

/** Pathwright GraphQL Endpoint */
export type QueryLicense_OfferingArgs = {
  id: Scalars["Int"]["input"]
  licensing_mode?: InputMaybe<LicenseOfferingLicensingMode>
}

/** Pathwright GraphQL Endpoint */
export type QueryLoginMethodArgs = {
  email: Scalars["String"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryLoginMethodsArgs = {
  email: Scalars["String"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryMembershipArgs = {
  access_revoked_date_filter?: InputMaybe<Array<InputMaybe<DateFilter>>>
  archived_date_filter?: InputMaybe<Array<InputMaybe<DateFilter>>>
  daysInactive?: InputMaybe<Scalars["Int"]["input"]>
  global?: InputMaybe<Scalars["Boolean"]["input"]>
  hasAccess?: InputMaybe<Scalars["Boolean"]["input"]>
  id: Scalars["Int"]["input"]
  orderBy?: InputMaybe<Scalars["String"]["input"]>
  role_filter?: InputMaybe<Array<InputMaybe<MembershipRoleFilter>>>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryMembership_MetaArgs = {
  user_id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryMentorGroupMembershipArgs = {
  id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryMessageArgs = {
  id: Scalars["Int"]["input"]
  message_context: MessageContext
}

/** Pathwright GraphQL Endpoint */
export type QueryMessagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  message_context: MessageContext
  type?: InputMaybe<MessageTypeFilter>
}

/** Pathwright GraphQL Endpoint */
export type QueryNoteArgs = {
  context: ContextModelInput
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryNotesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context: ContextModelInput
  first: Scalars["Int"]["input"]
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  visibility?: InputMaybe<NoteVisibility>
}

/** Pathwright GraphQL Endpoint */
export type QueryOrderArgs = {
  id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryPathArgs = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryPathItemArgs = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  id?: InputMaybe<Scalars["Int"]["input"]>
  path_id?: InputMaybe<Scalars["Int"]["input"]>
  source_id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryPathItemStatsArgs = {
  user_id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryPathItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  due?: InputMaybe<DateFilter>
  exclude_default?: InputMaybe<Scalars["Boolean"]["input"]>
  first: Scalars["Int"]["input"]
  group_id?: InputMaybe<Scalars["Int"]["input"]>
  is_complete?: InputMaybe<Scalars["Boolean"]["input"]>
  is_required?: InputMaybe<Scalars["Boolean"]["input"]>
  is_reviewed?: InputMaybe<Scalars["Boolean"]["input"]>
  is_skipped?: InputMaybe<Scalars["Boolean"]["input"]>
  lesson_source_id?: InputMaybe<Scalars["Int"]["input"]>
  needs_attention?: InputMaybe<Scalars["Boolean"]["input"]>
  needs_review?: InputMaybe<Scalars["Boolean"]["input"]>
  next?: InputMaybe<Scalars["Boolean"]["input"]>
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  path_id?: InputMaybe<Scalars["Int"]["input"]>
  registration_role_filter?: InputMaybe<RoleFilter>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  reviewable?: InputMaybe<Scalars["Boolean"]["input"]>
  root_level?: InputMaybe<Scalars["Boolean"]["input"]>
  source_id?: InputMaybe<Scalars["Int"]["input"]>
  starts?: InputMaybe<DateFilter>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
  type?: InputMaybe<PathItemTypes>
  upcoming?: InputMaybe<Scalars["Boolean"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
  with_dates?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryPathSyncPlanArgs = {
  sync_plan?: InputMaybe<PathSyncPlanInput>
}

/** Pathwright GraphQL Endpoint */
export type QueryPeopleAndGroupConnectionArgs = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  group_id?: InputMaybe<Scalars["Int"]["input"]>
  role_in_context?: InputMaybe<RoleInContext>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryPeopleAndGroupConnectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context?: InputMaybe<PeopleAndGroupConnectionContext>
  first: Scalars["Int"]["input"]
  people_count_filter?: InputMaybe<IntFilter>
  role_in_context?: InputMaybe<RoleInContext>
  search_term?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  type?: InputMaybe<PeopleAndGroupConnectionType>
}

/** Pathwright GraphQL Endpoint */
export type QueryReportArgs = {
  community_groups?: InputMaybe<Array<InputMaybe<ReportInput>>>
  community_memberships?: InputMaybe<Array<InputMaybe<ReportInput>>>
  group_performance_completion?: InputMaybe<Array<InputMaybe<ReportInput>>>
  group_performance_points?: InputMaybe<Array<InputMaybe<ReportInput>>>
  group_registrations?: InputMaybe<Array<InputMaybe<ReportInput>>>
  licensing_member_created_cohorts?: InputMaybe<Array<InputMaybe<ReportInput>>>
  licensing_store_connected_accounts?: InputMaybe<
    Array<InputMaybe<ReportInput>>
  >
  licensing_store_member_progress?: InputMaybe<Array<InputMaybe<ReportInput>>>
  licensing_store_resource_usage?: InputMaybe<Array<InputMaybe<ReportInput>>>
  school_cohorts?: InputMaybe<Array<InputMaybe<ReportInput>>>
  school_coupon_redemptions?: InputMaybe<Array<InputMaybe<ReportInput>>>
  school_coupon_summary?: InputMaybe<Array<InputMaybe<ReportInput>>>
  school_external_ids?: InputMaybe<Array<InputMaybe<ReportInput>>>
  school_member_progress?: InputMaybe<Array<InputMaybe<ReportInput>>>
  school_members?: InputMaybe<Array<InputMaybe<ReportInput>>>
  school_registrations?: InputMaybe<Array<InputMaybe<ReportInput>>>
  school_resources?: InputMaybe<Array<InputMaybe<ReportInput>>>
}

/** Pathwright GraphQL Endpoint */
export type QueryResourceArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryResourceCopyConfigArgs = {
  key: Scalars["String"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryResourceCopyConfigConnectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  keys: Array<InputMaybe<Scalars["String"]["input"]>>
}

/** Pathwright GraphQL Endpoint */
export type QueryResourceImageArgs = {
  image?: InputMaybe<Array<InputMaybe<ImageInput>>>
  theme_type?: InputMaybe<ThemeType>
}

/** Pathwright GraphQL Endpoint */
export type QueryResource_LicenseArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  license_offering_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryResource_LicensesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryResourcesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  assign_config?: InputMaybe<AssignConfig>
  author_id?: InputMaybe<Scalars["Int"]["input"]>
  author_ids?: InputMaybe<IdFilter>
  author_names?: InputMaybe<StringFilter>
  author_search?: InputMaybe<Scalars["String"]["input"]>
  category_id?: InputMaybe<Scalars["Int"]["input"]>
  category_ids?: InputMaybe<IdFilter>
  category_names?: InputMaybe<StringFilter>
  category_search?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  has_certificate?: InputMaybe<Scalars["Boolean"]["input"]>
  id?: InputMaybe<IdFilter>
  license_offering_licensing_mode?: InputMaybe<LicenseOfferingLicensingMode>
  licensed_from_school?: InputMaybe<IdFilter>
  orderBy?: InputMaybe<Scalars["String"]["input"]>
  publisher_id?: InputMaybe<Scalars["Int"]["input"]>
  publisher_ids?: InputMaybe<IdFilter>
  resource_types?: InputMaybe<ResourceTypeFilter>
  role?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  user_registered?: InputMaybe<IdFilter>
  visibility?: InputMaybe<ResourceVisibility>
}

/** Pathwright GraphQL Endpoint */
export type QueryResponsesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context: DiscussionResponseContextInput
  first: Scalars["Int"]["input"]
  modified_time?: InputMaybe<DateFilter>
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
}

/** Pathwright GraphQL Endpoint */
export type QueryRestApiGetArgs = {
  endpoint: Scalars["String"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QuerySchoolGroupSubscriptionArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QuerySchoolGroupSubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  license_offering_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QuerySchoolSubscriptionDiscountArgs = {
  discount_code: Scalars["String"]["input"]
  school_plan_id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QuerySharePermissionsArgs = {
  context?: InputMaybe<ShareContext>
}

/** Pathwright GraphQL Endpoint */
export type QuerySpaceConnectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryStoreCollectionArgs = {
  id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryStoreCollectionsArgs = {
  author_id?: InputMaybe<Scalars["Int"]["input"]>
  author_ids?: InputMaybe<IdFilter>
  author_search?: InputMaybe<Scalars["String"]["input"]>
  category_id?: InputMaybe<Scalars["Int"]["input"]>
  category_ids?: InputMaybe<IdFilter>
  category_search?: InputMaybe<Scalars["String"]["input"]>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryStoreItemArgs = {
  id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryStoreLinksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  licensor_id?: InputMaybe<IdFilter>
}

/** Pathwright GraphQL Endpoint */
export type QueryStripeProductArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryStripeProductsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  ids?: InputMaybe<IdFilter>
  include_inactive?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QuerySubdomainArgs = {
  subdomain?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QuerySubscriptionArgs = {
  id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QuerySubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  payment_intent_needing_action_id?: InputMaybe<IdFilter>
}

/** Pathwright GraphQL Endpoint */
export type QueryTagArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryTagAttachmentArgs = {
  context?: InputMaybe<TagAttachmentContextInput>
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryTagAttachmentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context?: InputMaybe<TagAttachmentContextInput>
  first: Scalars["Int"]["input"]
  tag_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryTagsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context?: InputMaybe<TagAttachmentContextInput>
  first: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryTranslationArgs = {
  is_default?: InputMaybe<Scalars["Boolean"]["input"]>
  language?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryUserArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryUserStatusArgs = {
  input?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryWebhookEventConnectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  event?: InputMaybe<WebhookEventType>
  first: Scalars["Int"]["input"]
  last_attempt_dtime_filter?: InputMaybe<DateFilter>
  order_by?: InputMaybe<WebhookEventOrderBy>
  status?: InputMaybe<WebhookEventStatus>
}

export type Referral = {
  __typename?: "Referral"
  referral?: Maybe<Scalars["String"]["output"]>
}

/** A Pathwright Registration */
export type Registration = {
  __typename?: "Registration"
  /**
   * The download URL for the course certificate
   * @deprecated Optimization. `certificate_download_url` field has been moved to its own schema that can be queried separately, `RegistrationCertificate`
   */
  certificate_download_url?: Maybe<Scalars["String"]["output"]>
  completion_state?: Maybe<Scalars["Int"]["output"]>
  created?: Maybe<Scalars["DateTime"]["output"]>
  expiration_dtime?: Maybe<Scalars["DateTime"]["output"]>
  group?: Maybe<Group>
  group_sub_community_membership?: Maybe<MentorGroupMembership>
  group_sub_used?: Maybe<SchoolGroupSubscription>
  id: Scalars["Int"]["output"]
  last_activity_time?: Maybe<Scalars["DateTime"]["output"]>
  meta: NodeMeta
  path?: Maybe<Path>
  path_id?: Maybe<Scalars["Int"]["output"]>
  /** The contextual position for this registration based on the `positioningContext` arg. */
  position?: Maybe<Scalars["String"]["output"]>
  registration_payment?: Maybe<RegistrationPayment>
  resource?: Maybe<Resource>
  role?: Maybe<Scalars["Int"]["output"]>
  school_sub_used?: Maybe<SchoolSubscription>
  send_discussion_notifications?: Maybe<Scalars["Boolean"]["output"]>
  send_due_notifications?: Maybe<Scalars["Boolean"]["output"]>
  /** A soft-archived registration will be hidden or demphasized in some UIs. */
  softArchivedTime?: Maybe<Scalars["DateTime"]["output"]>
  user?: Maybe<User>
  user_id?: Maybe<Scalars["Int"]["output"]>
}

/** A connection to a list of items. */
export type RegistrationConnection = {
  __typename?: "RegistrationConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RegistrationEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type RegistrationEdge = {
  __typename?: "RegistrationEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Registration>
}

export type RegistrationMeta = {
  __typename?: "RegistrationMeta"
  editor_registration_count?: Maybe<Scalars["Int"]["output"]>
  facilitator_registration_count?: Maybe<Scalars["Int"]["output"]>
  student_registration_count?: Maybe<Scalars["Int"]["output"]>
  teacher_registration_count?: Maybe<Scalars["Int"]["output"]>
  total_registration_count?: Maybe<Scalars["Int"]["output"]>
}

/** Input for ordering the list. */
export type RegistrationOrderByInput = {
  /** Order by created. */
  created?: InputMaybe<OrderByDir>
  /** Order by last_activity_time. */
  last_activity_time?: InputMaybe<OrderByDir>
  /** Order by position. */
  position?: InputMaybe<OrderByDir>
  /** Order by role. */
  role?: InputMaybe<OrderByDir>
}

/** Registration order data */
export type RegistrationOrderData = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  invitation_code?: InputMaybe<Scalars["String"]["input"]>
  offering_id: Scalars["Int"]["input"]
  use_group_subscription_id?: InputMaybe<Scalars["Int"]["input"]>
  use_subscription_plan_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright Registration Payment */
export type RegistrationPayment = {
  __typename?: "RegistrationPayment"
  /** The Coupon that was redeemed (if any). */
  couponRedemption?: Maybe<CouponRedemption>
  group_sub_used?: Maybe<SchoolGroupSubscription>
  id?: Maybe<Scalars["Int"]["output"]>
  payment?: Maybe<Payment>
  school_sub_used?: Maybe<SchoolSubscription>
}

export enum RegistrationRoleEnum {
  Editor = "editor",
  Moderator = "moderator",
  Observer = "observer",
  Student = "student",
  Teacher = "teacher"
}

export type RelativeTime = {
  days?: InputMaybe<Scalars["Float"]["input"]>
  hours?: InputMaybe<Scalars["Float"]["input"]>
  minutes?: InputMaybe<Scalars["Float"]["input"]>
}

export type ReportInput = {
  /** Issue a non-default expiry report link. Pass null for non-expiring. By default a report link is set to expire 1 day from date issued. NOTE: only available to Pathwright admin. */
  exp?: InputMaybe<RelativeTime>
  id_type?: InputMaybe<Scalars["Int"]["input"]>
  offering_id?: InputMaybe<Scalars["Int"]["input"]>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  schedule_id?: InputMaybe<Scalars["Int"]["input"]>
  store_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright Resource */
export type Resource = {
  __typename?: "Resource"
  authors?: Maybe<AuthorConnection>
  background_image?: Maybe<Scalars["String"]["output"]>
  background_luminance?: Maybe<Scalars["Float"]["output"]>
  background_overlay?: Maybe<Scalars["String"]["output"]>
  categories?: Maybe<CategoryConnection>
  certificate_generator_class?: Maybe<Scalars["String"]["output"]>
  certificate_json?: Maybe<Scalars["JSON"]["output"]>
  cohorts_status_meta?: Maybe<CohortsStatusMeta>
  created?: Maybe<Scalars["DateTime"]["output"]>
  created_by_id?: Maybe<Scalars["Int"]["output"]>
  curriculum_group?: Maybe<Group>
  description?: Maybe<Scalars["String"]["output"]>
  discussion_enabled?: Maybe<Scalars["Boolean"]["output"]>
  group?: Maybe<Group>
  groups?: Maybe<GroupConnection>
  has_certificate?: Maybe<Scalars["Boolean"]["output"]>
  highest_public_offering_price?: Maybe<Scalars["Float"]["output"]>
  id: Scalars["Int"]["output"]
  image: Scalars["String"]["output"]
  image_raw?: Maybe<Scalars["String"]["output"]>
  last_published_dtime?: Maybe<Scalars["DateTime"]["output"]>
  license_offerings?: Maybe<LicenseOfferingConnection>
  licensed_from_resource_id?: Maybe<Scalars["Int"]["output"]>
  licensed_from_school_id?: Maybe<Scalars["Int"]["output"]>
  licensing_enabled?: Maybe<Scalars["Boolean"]["output"]>
  lowest_public_offering_price?: Maybe<Scalars["Float"]["output"]>
  /** In the future, ResourceMeta will simply be NodeMeta, only consisting of NodeMeta fields. */
  meta?: Maybe<ResourceMeta>
  name: Scalars["String"]["output"]
  publisher?: Maybe<Publisher>
  registration_meta?: Maybe<RegistrationMeta>
  resource_type?: Maybe<Scalars["Int"]["output"]>
  school?: Maybe<School>
  school_licenses?: Maybe<ResourceLicenseConnection>
  short_description?: Maybe<Scalars["String"]["output"]>
  slug: Scalars["String"]["output"]
  trailer_embed_code?: Maybe<Scalars["String"]["output"]>
  visibility?: Maybe<Scalars["Int"]["output"]>
  visibility_level_override?: Maybe<Scalars["Int"]["output"]>
}

/** A Pathwright Resource */
export type ResourceAuthorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A Pathwright Resource */
export type ResourceBackground_ImageArgs = {
  balph?: InputMaybe<Scalars["Int"]["input"]>
  blend?: InputMaybe<Scalars["Int"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  bm?: InputMaybe<Scalars["String"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  fm?: InputMaybe<Scalars["String"]["input"]>
  height?: InputMaybe<Scalars["Int"]["input"]>
  high?: InputMaybe<Scalars["Int"]["input"]>
  q?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright Resource */
export type ResourceCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A Pathwright Resource */
export type ResourceGroupArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  is_source?: InputMaybe<Scalars["Boolean"]["input"]>
  parent_group_id?: InputMaybe<Scalars["Int"]["input"]>
  type?: InputMaybe<GroupType>
}

/** A Pathwright Resource */
export type ResourceGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  assign_config?: InputMaybe<AssignConfig>
  first: Scalars["Int"]["input"]
  include_curriculum?: InputMaybe<Scalars["Boolean"]["input"]>
  is_master?: InputMaybe<Scalars["Boolean"]["input"]>
  parent_group_id?: InputMaybe<IdFilter>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  role?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  status?: InputMaybe<GroupStatus>
  visibility?: InputMaybe<GroupVisibility>
  visibility_filter?: InputMaybe<GroupVisibilityFilter>
}

/** A Pathwright Resource */
export type ResourceImageArgs = {
  balph?: InputMaybe<Scalars["Int"]["input"]>
  blend?: InputMaybe<Scalars["Int"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  bm?: InputMaybe<Scalars["String"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  fm?: InputMaybe<Scalars["String"]["input"]>
  height?: InputMaybe<Scalars["Int"]["input"]>
  high?: InputMaybe<Scalars["Int"]["input"]>
  q?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright Resource */
export type ResourceLicense_OfferingsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  licensing_mode?: InputMaybe<LicenseOfferingLicensingMode>
}

/** A Pathwright Resource */
export type ResourceSchool_LicensesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  licensee_school_id: Scalars["Int"]["input"]
}

/** A connection to a list of items. */
export type ResourceConnection = {
  __typename?: "ResourceConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ResourceEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

export type ResourceCopyConfig = {
  __typename?: "ResourceCopyConfig"
  /** If set, linking an account to this Store will result in this copy config being executed. NOTE: For now, only applies to new school sign-ups to the store. */
  allow_copy_via_link: Scalars["Boolean"]["output"]
  /** If creating a cohort and this is set, assign the copier this role. Can be editor for source path, or other roles for non-source paths. */
  assign_copier_role?: Maybe<RegistrationRoleEnum>
  /** All direct child resource copy configs. */
  children?: Maybe<Array<Maybe<ResourceCopyConfig>>>
  cohort?: Maybe<ResourceCopyConfigCohort>
  /** Should authors be copied to the target school if they don't exist? */
  copy_authors: Scalars["Boolean"]["output"]
  /** Should categories be copied to the target school if they don't exist? */
  copy_categories: Scalars["Boolean"]["output"]
  /** Create a cohort for the copied resource? */
  create_cohort: Scalars["Boolean"]["output"]
  /** If creating a cohort, this is the name to give to the new cohort. */
  create_cohort_name?: Maybe<Scalars["String"]["output"]>
  /** All recursive child resource copy configs. */
  flat_children?: Maybe<Array<Maybe<ResourceCopyConfig>>>
  id: Scalars["Int"]["output"]
  /** The unique code for this resource copy config. */
  lookup_key: Scalars["String"]["output"]
  name?: Maybe<Scalars["String"]["output"]>
  /** The parent resource copy config. If the parent is copied, all child resource copy configs will be copied regardless of how the child resource copy config is configured to run. */
  parent?: Maybe<ResourceCopyConfig>
  resource?: Maybe<ResourceCopyConfigResource>
  school?: Maybe<ResourceCopyConfigSchool>
}

export type ResourceCopyConfigCohort = {
  __typename?: "ResourceCopyConfigCohort"
  id: Scalars["Int"]["output"]
  meta: NodeMeta
}

/** A connection to a list of items. */
export type ResourceCopyConfigConnection = {
  __typename?: "ResourceCopyConfigConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ResourceCopyConfigEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type ResourceCopyConfigEdge = {
  __typename?: "ResourceCopyConfigEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<ResourceCopyConfig>
}

export type ResourceCopyConfigResource = {
  __typename?: "ResourceCopyConfigResource"
  id: Scalars["Int"]["output"]
  meta: NodeMeta
}

export type ResourceCopyConfigSchool = {
  __typename?: "ResourceCopyConfigSchool"
  id: Scalars["Int"]["output"]
  meta: NodeMeta
}

/** An edge in a connection. */
export type ResourceEdge = {
  __typename?: "ResourceEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Resource>
}

/** A resource license */
export type ResourceLicense = {
  __typename?: "ResourceLicense"
  expiration_dtime?: Maybe<Scalars["DateTime"]["output"]>
  group?: Maybe<Group>
  id?: Maybe<Scalars["Int"]["output"]>
  license_offering?: Maybe<LicenseOffering>
  school?: Maybe<LicenseeSchool>
  seat_count?: Maybe<Scalars["Int"]["output"]>
  seats_available?: Maybe<Scalars["Int"]["output"]>
  seats_filled?: Maybe<Scalars["Int"]["output"]>
}

/** A connection to a list of items. */
export type ResourceLicenseConnection = {
  __typename?: "ResourceLicenseConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ResourceLicenseEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type ResourceLicenseEdge = {
  __typename?: "ResourceLicenseEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<ResourceLicense>
}

export type ResourceMeta = {
  __typename?: "ResourceMeta"
  description?: Maybe<Scalars["String"]["output"]>
  /** @deprecated Use Resource.registration_meta instead! */
  editor_registration_count?: Maybe<Scalars["Int"]["output"]>
  /** @deprecated Use Resource.registration_meta instead! */
  facilitator_registration_count?: Maybe<Scalars["Int"]["output"]>
  icon?: Maybe<Scalars["String"]["output"]>
  image?: Maybe<Scalars["String"]["output"]>
  key: Scalars["String"]["output"]
  /** @deprecated Use Resource.registration_meta instead! */
  student_registration_count?: Maybe<Scalars["Int"]["output"]>
  subtitle?: Maybe<Scalars["String"]["output"]>
  /** @deprecated Use Resource.registration_meta instead! */
  teacher_registration_count?: Maybe<Scalars["Int"]["output"]>
  title?: Maybe<Scalars["String"]["output"]>
  /** @deprecated Use Resource.registration_meta instead! */
  total_registration_count?: Maybe<Scalars["Int"]["output"]>
  type: Scalars["String"]["output"]
  url?: Maybe<Scalars["String"]["output"]>
}

export type ResourceStats = {
  __typename?: "ResourceStats"
  active?: Maybe<Scalars["Int"]["output"]>
  never_active?: Maybe<Scalars["Int"]["output"]>
  private?: Maybe<Scalars["Int"]["output"]>
  public?: Maybe<Scalars["Int"]["output"]>
  total?: Maybe<Scalars["Int"]["output"]>
}

export enum ResourceType {
  Collection = "collection",
  Course = "course",
  Lesson = "lesson"
}

export type ResourceTypeFilter = {
  eq?: InputMaybe<ResourceType>
  gt?: InputMaybe<ResourceType>
  gte?: InputMaybe<ResourceType>
  in?: InputMaybe<Array<InputMaybe<ResourceType>>>
  lt?: InputMaybe<ResourceType>
  lte?: InputMaybe<ResourceType>
  neq?: InputMaybe<ResourceType>
  nin?: InputMaybe<Array<InputMaybe<ResourceType>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export type ResourceVisibility = {
  eq?: InputMaybe<ResourceVisibilityEnum>
  gt?: InputMaybe<ResourceVisibilityEnum>
  gte?: InputMaybe<ResourceVisibilityEnum>
  in?: InputMaybe<Array<InputMaybe<ResourceVisibilityEnum>>>
  lt?: InputMaybe<ResourceVisibilityEnum>
  lte?: InputMaybe<ResourceVisibilityEnum>
  neq?: InputMaybe<ResourceVisibilityEnum>
  nin?: InputMaybe<Array<InputMaybe<ResourceVisibilityEnum>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export enum ResourceVisibilityEnum {
  Inactive = "inactive",
  /** Resources visible to members */
  Members = "members",
  Private = "private",
  /** Resources visible to anyone */
  Public = "public"
}

/** Pathwright discussion response */
export type Response = {
  __typename?: "Response"
  attachments?: Maybe<Scalars["JSON"]["output"]>
  body?: Maybe<Scalars["String"]["output"]>
  created_time?: Maybe<Scalars["DateTime"]["output"]>
  depth?: Maybe<Scalars["Int"]["output"]>
  discussion?: Maybe<Discussion>
  id?: Maybe<Scalars["Int"]["output"]>
  likes?: Maybe<Scalars["Int"]["output"]>
  modified_time?: Maybe<Scalars["DateTime"]["output"]>
  parent_id?: Maybe<Scalars["Int"]["output"]>
  parent_response?: Maybe<Response>
  responses?: Maybe<ResponseConnection>
  user?: Maybe<User>
  user_liked?: Maybe<Scalars["Boolean"]["output"]>
}

/** Pathwright discussion response */
export type ResponseResponsesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A connection to a list of items. */
export type ResponseConnection = {
  __typename?: "ResponseConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ResponseEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type ResponseEdge = {
  __typename?: "ResponseEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Response>
}

export enum Role {
  CohortCourseEditor = "cohort_course_editor",
  CohortLearner = "cohort_learner",
  CohortModerator = "cohort_moderator",
  CohortObserver = "cohort_observer",
  CohortTeacher = "cohort_teacher",
  GroupAdmin = "group_admin",
  GroupMember = "group_member",
  GroupModerator = "group_moderator",
  SchoolAdmin = "school_admin",
  SchoolLibraryEditor = "school_library_editor",
  SchoolMember = "school_member",
  SchoolStaff = "school_staff"
}

export type RoleFilter = {
  eq?: InputMaybe<RegistrationRoleEnum>
  gt?: InputMaybe<RegistrationRoleEnum>
  gte?: InputMaybe<RegistrationRoleEnum>
  in?: InputMaybe<Array<InputMaybe<RegistrationRoleEnum>>>
  lt?: InputMaybe<RegistrationRoleEnum>
  lte?: InputMaybe<RegistrationRoleEnum>
  neq?: InputMaybe<RegistrationRoleEnum>
  nin?: InputMaybe<Array<InputMaybe<RegistrationRoleEnum>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export type RoleInContext = {
  /** Whether an item with this type of role should be excluded from results. */
  exclude?: InputMaybe<Scalars["Boolean"]["input"]>
  /** The type of the context in which the user has this role (defaults to school). */
  type: RoleInType
  /** The id of the type. */
  type_id?: InputMaybe<Scalars["Int"]["input"]>
}

export enum RoleInType {
  Cohort = "cohort",
  Group = "group",
  School = "school"
}

export type ScheduleDate = {
  __typename?: "ScheduleDate"
  behavior: ScheduleDateBehavior
  date?: Maybe<Scalars["DateTime"]["output"]>
}

export enum ScheduleDateBehavior {
  Lock = "lock",
  Passthrough = "passthrough"
}

export type ScheduleItemContext = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  path_id?: InputMaybe<Scalars["Int"]["input"]>
  /** The id source ID of the path item to be scheduled. */
  source_id: Scalars["Int"]["input"]
}

/** A Pathwright School */
export type School = {
  __typename?: "School"
  about?: Maybe<Scalars["String"]["output"]>
  /** @deprecated Activity should be queried through root for performance reasons */
  activity?: Maybe<ActivityConnection>
  /** @deprecated Activity stats should be queried through root for performance reasons */
  activity_stats?: Maybe<ActivityStats>
  /** Wheter to prompt users to enter a password when signing up. */
  always_prompt_for_password?: Maybe<Scalars["Boolean"]["output"]>
  author?: Maybe<Author>
  authors?: Maybe<AuthorConnection>
  banner?: Maybe<Banner>
  banners?: Maybe<BannerConnection>
  billing_model?: Maybe<Scalars["Int"]["output"]>
  billing_subscription?: Maybe<SchoolBillingSubscription>
  categories?: Maybe<CategoryConnection>
  category?: Maybe<Category>
  consent_description?: Maybe<Scalars["String"]["output"]>
  couponDiscount?: Maybe<CouponDiscount>
  created?: Maybe<Scalars["DateTime"]["output"]>
  currency_code?: Maybe<Scalars["String"]["output"]>
  curriculum_plans?: Maybe<SchoolCurriculumPlanConnection>
  curriculum_subscription?: Maybe<CurriculumSubscription>
  curriculum_subscriptions?: Maybe<CurriculumSubscriptionConnection>
  email?: Maybe<Scalars["String"]["output"]>
  features: SchoolFeatures
  gift_subscription?: Maybe<SchoolGiftSubscription>
  grant_access_upon_authentication?: Maybe<Scalars["Boolean"]["output"]>
  has_certificate?: Maybe<Scalars["Boolean"]["output"]>
  head_markup?: Maybe<Scalars["String"]["output"]>
  id: Scalars["Int"]["output"]
  ietf_language_tag?: Maybe<Scalars["String"]["output"]>
  image?: Maybe<Scalars["String"]["output"]>
  is_activated?: Maybe<Scalars["Boolean"]["output"]>
  is_curriculum_enabled?: Maybe<Scalars["Boolean"]["output"]>
  is_curriculum_subscriber?: Maybe<Scalars["Boolean"]["output"]>
  license_offering?: Maybe<LicenseOffering>
  logo?: Maybe<Scalars["String"]["output"]>
  members?: Maybe<MembershipConnection>
  membership_stats?: Maybe<MembershipStats>
  mentor_group?: Maybe<MentorGroup>
  mentor_group_stats?: Maybe<MentorGroupStats>
  mentor_groups?: Maybe<MentorGroupConnection>
  name: Scalars["String"]["output"]
  owner?: Maybe<User>
  owner_id?: Maybe<Scalars["Int"]["output"]>
  page?: Maybe<Page>
  pages?: Maybe<PageConnection>
  pathItems?: Maybe<PathItemConnection>
  pathwright_login_enabled?: Maybe<Scalars["Boolean"]["output"]>
  payment_requires_zip_code?: Maybe<Scalars["Boolean"]["output"]>
  platform_version?: Maybe<Scalars["Float"]["output"]>
  primary_domain?: Maybe<Scalars["String"]["output"]>
  profile?: Maybe<SchoolProfile>
  publishers?: Maybe<PublisherConnection>
  registrations?: Maybe<RegistrationConnection>
  resource?: Maybe<Resource>
  resource_license?: Maybe<ResourceLicense>
  resource_licensing_enabled?: Maybe<Scalars["Boolean"]["output"]>
  resource_stats?: Maybe<ResourceStats>
  resources?: Maybe<ResourceConnection>
  school_billing_invoice?: Maybe<SchoolBillingInvoice>
  school_billing_invoices?: Maybe<SchoolBillingInvoiceConnection>
  script_markup?: Maybe<Scalars["String"]["output"]>
  show_stripe_reconnect?: Maybe<Scalars["Boolean"]["output"]>
  sso_link?: Maybe<Scalars["String"]["output"]>
  storeLinks?: Maybe<StoreLinkConnection>
  stripe_access_token?: Maybe<Scalars["String"]["output"]>
  stripe_publishable_api_key?: Maybe<Scalars["String"]["output"]>
  subdomain: Scalars["String"]["output"]
  subscription?: Maybe<Subscription>
  subscription_plans?: Maybe<SchoolSubscriptionPlanConnection>
  subscriptions?: Maybe<SubscriptionConnection>
  theme?: Maybe<Theme>
  /** Fakes a school invoice create. */
  upcoming_school_billing_invoice?: Maybe<SchoolBillingInvoice>
  url?: Maybe<Scalars["String"]["output"]>
  website?: Maybe<Scalars["String"]["output"]>
}

/** A Pathwright School */
export type SchoolActivityArgs = {
  action_type?: InputMaybe<ActionTypeFilter>
  after?: InputMaybe<Scalars["String"]["input"]>
  date?: InputMaybe<DateFilter>
  first: Scalars["Int"]["input"]
  group_id?: InputMaybe<Scalars["Int"]["input"]>
  interest_level?: InputMaybe<InterestLevel>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright School */
export type SchoolAuthorArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolAuthorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  ids?: InputMaybe<IdFilter>
  manage?: InputMaybe<Scalars["Boolean"]["input"]>
  resource_author_ids?: InputMaybe<IdFilter>
  resource_author_names?: InputMaybe<StringFilter>
  resource_category_ids?: InputMaybe<IdFilter>
  resource_category_names?: InputMaybe<StringFilter>
  resource_search?: InputMaybe<Scalars["String"]["input"]>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolBannerArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright School */
export type SchoolBannersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  manage?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** A Pathwright School */
export type SchoolCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  ids?: InputMaybe<IdFilter>
  manage?: InputMaybe<Scalars["Boolean"]["input"]>
  resource_author_ids?: InputMaybe<IdFilter>
  resource_author_names?: InputMaybe<StringFilter>
  resource_category_ids?: InputMaybe<IdFilter>
  resource_category_names?: InputMaybe<StringFilter>
  resource_search?: InputMaybe<Scalars["String"]["input"]>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolCategoryArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolCouponDiscountArgs = {
  code: Scalars["String"]["input"]
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  resource_license_offering_id?: InputMaybe<Scalars["Int"]["input"]>
  school_subscription_plan_id?: InputMaybe<Scalars["Int"]["input"]>
  seat_count?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright School */
export type SchoolCurriculum_PlansArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  visibility_code?: InputMaybe<Scalars["String"]["input"]>
  visibility_code_filter?: InputMaybe<StringFilter>
}

/** A Pathwright School */
export type SchoolCurriculum_SubscriptionArgs = {
  id: Scalars["Int"]["input"]
}

/** A Pathwright School */
export type SchoolCurriculum_SubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  licensee_id?: InputMaybe<IdFilter>
  licensor_id?: InputMaybe<IdFilter>
}

/** A Pathwright School */
export type SchoolGift_SubscriptionArgs = {
  code?: InputMaybe<Scalars["String"]["input"]>
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright School */
export type SchoolImageArgs = {
  balph?: InputMaybe<Scalars["Int"]["input"]>
  blend?: InputMaybe<Scalars["Int"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  bm?: InputMaybe<Scalars["String"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  fm?: InputMaybe<Scalars["String"]["input"]>
  height?: InputMaybe<Scalars["Int"]["input"]>
  high?: InputMaybe<Scalars["Int"]["input"]>
  q?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright School */
export type SchoolLicense_OfferingArgs = {
  id: Scalars["Int"]["input"]
  licensing_mode?: InputMaybe<LicenseOfferingLicensingMode>
}

/** A Pathwright School */
export type SchoolLogoArgs = {
  balph?: InputMaybe<Scalars["Int"]["input"]>
  blend?: InputMaybe<Scalars["Int"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  bm?: InputMaybe<Scalars["String"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  fm?: InputMaybe<Scalars["String"]["input"]>
  height?: InputMaybe<Scalars["Int"]["input"]>
  high?: InputMaybe<Scalars["Int"]["input"]>
  q?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright School */
export type SchoolMembersArgs = {
  access_revoked_date_filter?: InputMaybe<Array<InputMaybe<DateFilter>>>
  after?: InputMaybe<Scalars["String"]["input"]>
  archived_date_filter?: InputMaybe<Array<InputMaybe<DateFilter>>>
  daysInactive?: InputMaybe<Scalars["Int"]["input"]>
  first: Scalars["Int"]["input"]
  global?: InputMaybe<Scalars["Boolean"]["input"]>
  hasAccess?: InputMaybe<Scalars["Boolean"]["input"]>
  orderBy?: InputMaybe<Scalars["String"]["input"]>
  role_filter?: InputMaybe<Array<InputMaybe<MembershipRoleFilter>>>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolMentor_GroupArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolMentor_GroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  descendant_of_parent_id?: InputMaybe<EquivalenceIdFilter>
  first: Scalars["Int"]["input"]
  id?: InputMaybe<IdFilter>
  parent_id?: InputMaybe<IdFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  seat_limit?: InputMaybe<Scalars["Int"]["input"]>
  seat_limit_filter?: InputMaybe<IntFilter>
  type?: InputMaybe<MentorGroupType>
}

/** A Pathwright School */
export type SchoolPageArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  is_live?: InputMaybe<Scalars["Boolean"]["input"]>
  page_type?: InputMaybe<Scalars["Int"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolPagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A Pathwright School */
export type SchoolPathItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  due?: InputMaybe<DateFilter>
  exclude_default?: InputMaybe<Scalars["Boolean"]["input"]>
  first: Scalars["Int"]["input"]
  group_id?: InputMaybe<Scalars["Int"]["input"]>
  is_complete?: InputMaybe<Scalars["Boolean"]["input"]>
  is_required?: InputMaybe<Scalars["Boolean"]["input"]>
  is_reviewed?: InputMaybe<Scalars["Boolean"]["input"]>
  is_skipped?: InputMaybe<Scalars["Boolean"]["input"]>
  lesson_source_id?: InputMaybe<Scalars["Int"]["input"]>
  needs_attention?: InputMaybe<Scalars["Boolean"]["input"]>
  needs_review?: InputMaybe<Scalars["Boolean"]["input"]>
  next?: InputMaybe<Scalars["Boolean"]["input"]>
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  path_id?: InputMaybe<Scalars["Int"]["input"]>
  registration_role_filter?: InputMaybe<RoleFilter>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  reviewable?: InputMaybe<Scalars["Boolean"]["input"]>
  root_level?: InputMaybe<Scalars["Boolean"]["input"]>
  source_id?: InputMaybe<Scalars["Int"]["input"]>
  starts?: InputMaybe<DateFilter>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
  type?: InputMaybe<PathItemTypes>
  upcoming?: InputMaybe<Scalars["Boolean"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
  with_dates?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** A Pathwright School */
export type SchoolPublishersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A Pathwright School */
export type SchoolRegistrationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  completion_state?: InputMaybe<CompletionStateFilter>
  created?: InputMaybe<DateFilter>
  first: Scalars["Int"]["input"]
  has_parent_registration?: InputMaybe<Scalars["Boolean"]["input"]>
  last_active?: InputMaybe<DateFilter>
  orderBy?: InputMaybe<Array<InputMaybe<RegistrationOrderByInput>>>
  parent_cohort_filter?: InputMaybe<IdFilter>
  positioningContext?: InputMaybe<PositioningContextInput>
  progress_filter?: InputMaybe<ProgressFilter>
  role?: InputMaybe<RegistrationRoleEnum>
  role_filter?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  send_discussion_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
  send_due_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
  softArchived?: InputMaybe<DateFilter>
}

/** A Pathwright School */
export type SchoolResourceArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolResource_LicenseArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  license_offering_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright School */
export type SchoolResourcesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  assign_config?: InputMaybe<AssignConfig>
  author_id?: InputMaybe<Scalars["Int"]["input"]>
  author_ids?: InputMaybe<IdFilter>
  author_names?: InputMaybe<StringFilter>
  author_search?: InputMaybe<Scalars["String"]["input"]>
  category_id?: InputMaybe<Scalars["Int"]["input"]>
  category_ids?: InputMaybe<IdFilter>
  category_names?: InputMaybe<StringFilter>
  category_search?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  has_certificate?: InputMaybe<Scalars["Boolean"]["input"]>
  id?: InputMaybe<IdFilter>
  license_offering_licensing_mode?: InputMaybe<LicenseOfferingLicensingMode>
  licensed_from_school?: InputMaybe<IdFilter>
  orderBy?: InputMaybe<Scalars["String"]["input"]>
  publisher_id?: InputMaybe<Scalars["Int"]["input"]>
  publisher_ids?: InputMaybe<IdFilter>
  resource_types?: InputMaybe<ResourceTypeFilter>
  role?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  user_registered?: InputMaybe<IdFilter>
  visibility?: InputMaybe<ResourceVisibility>
}

/** A Pathwright School */
export type SchoolSchool_Billing_InvoiceArgs = {
  id: Scalars["Int"]["input"]
}

/** A Pathwright School */
export type SchoolSchool_Billing_InvoicesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A Pathwright School */
export type SchoolSso_LinkArgs = {
  pathname?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolStoreLinksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  licensor_id?: InputMaybe<IdFilter>
}

/** A Pathwright School */
export type SchoolSubscriptionArgs = {
  id: Scalars["Int"]["input"]
}

/** A Pathwright School */
export type SchoolSubscription_PlansArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  giftable?: InputMaybe<Scalars["Boolean"]["input"]>
  ids?: InputMaybe<IdFilter>
  ignore_visibility?: InputMaybe<Scalars["Boolean"]["input"]>
  interval?: InputMaybe<SubscriptionIntervalType>
  interval_filter?: InputMaybe<SubscriptionIntervalFilter>
  orderBy?: InputMaybe<Array<InputMaybe<SchoolSubscriptionPlanOrderByInput>>>
  positioningContext?: InputMaybe<PositioningContextInput>
  visibility_code?: InputMaybe<Scalars["String"]["input"]>
  visibility_code_filter?: InputMaybe<StringFilter>
}

/** A Pathwright School */
export type SchoolSubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  payment_intent_needing_action_id?: InputMaybe<IdFilter>
}

/** A Pathwright School */
export type SchoolUrlArgs = {
  pathname?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright School Account data */
export type SchoolAcount = {
  __typename?: "SchoolAcount"
  billing_model?: Maybe<Scalars["Int"]["output"]>
  billing_plan_set?: Maybe<SchoolBillingPlanSet>
  billing_subscription?: Maybe<SchoolBillingSubscription>
  curriculum_subscription?: Maybe<CurriculumSubscription>
  id?: Maybe<Scalars["Int"]["output"]>
  is_activated?: Maybe<Scalars["Boolean"]["output"]>
  is_activated_via_override?: Maybe<Scalars["Boolean"]["output"]>
  is_on_legacy_plan?: Maybe<Scalars["Boolean"]["output"]>
  scholarship?: Maybe<SchoolBillingScholarship>
}

/** Pathwright School Account data */
export type SchoolAcountBilling_Plan_SetArgs = {
  default?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** School Billing Block used for invoicing */
export type SchoolBillingBlock = {
  __typename?: "SchoolBillingBlock"
  block_size?: Maybe<Scalars["Int"]["output"]>
  block_type?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  price?: Maybe<Scalars["Float"]["output"]>
}

/** A connection to a list of items. */
export type SchoolBillingBlockConnection = {
  __typename?: "SchoolBillingBlockConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SchoolBillingBlockEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type SchoolBillingBlockEdge = {
  __typename?: "SchoolBillingBlockEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<SchoolBillingBlock>
}

/** Pathwright School Billing Invoice */
export type SchoolBillingInvoice = {
  __typename?: "SchoolBillingInvoice"
  child_subscriptions?: Maybe<Scalars["JSON"]["output"]>
  cycle_end?: Maybe<Scalars["DateTime"]["output"]>
  cycle_start?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  invoice_json?: Maybe<Scalars["JSON"]["output"]>
  invoiced_date?: Maybe<Scalars["DateTime"]["output"]>
  is_initial_prepaid_invoice?: Maybe<Scalars["Boolean"]["output"]>
  is_overdue?: Maybe<Scalars["Boolean"]["output"]>
  parent_subscription?: Maybe<Scalars["JSON"]["output"]>
  payment?: Maybe<Scalars["JSON"]["output"]>
  stripe_invoice_id?: Maybe<Scalars["String"]["output"]>
  stripe_invoice_status?: Maybe<Scalars["String"]["output"]>
  stripe_next_payment_attempt?: Maybe<Scalars["DateTime"]["output"]>
  total_amount_billed?: Maybe<Scalars["Float"]["output"]>
  url?: Maybe<Scalars["String"]["output"]>
}

/** A connection to a list of items. */
export type SchoolBillingInvoiceConnection = {
  __typename?: "SchoolBillingInvoiceConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SchoolBillingInvoiceEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type SchoolBillingInvoiceEdge = {
  __typename?: "SchoolBillingInvoiceEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<SchoolBillingInvoice>
}

/** Pathwright School Subscription Plans Data */
export type SchoolBillingPlan = {
  __typename?: "SchoolBillingPlan"
  amount?: Maybe<Scalars["Float"]["output"]>
  billing_blocks?: Maybe<SchoolBillingBlockConnection>
  cohorts_included?: Maybe<Scalars["Int"]["output"]>
  color?: Maybe<Scalars["String"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  features?: Maybe<Array<Maybe<SchoolBillingPlanFeature>>>
  fee_per_additional_learner?: Maybe<Scalars["Float"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  image?: Maybe<Scalars["String"]["output"]>
  learner_counting_method?: Maybe<Scalars["Int"]["output"]>
  learners_included?: Maybe<Scalars["Int"]["output"]>
  mentor_groups_included?: Maybe<Scalars["Int"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  order?: Maybe<Scalars["Int"]["output"]>
  plan_derived_from?: Maybe<SchoolBillingPlan>
  scholarships_available?: Maybe<Scalars["Boolean"]["output"]>
  should_contact_for_pricing?: Maybe<Scalars["Boolean"]["output"]>
  staff_included?: Maybe<Scalars["Int"]["output"]>
  visibility?: Maybe<Visibility>
}

/** Pathwright School Subscription Plans Data */
export type SchoolBillingPlanBilling_BlocksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  block_type?: InputMaybe<Scalars["Int"]["input"]>
  first: Scalars["Int"]["input"]
}

/** School Billing Plan Feature Data */
export type SchoolBillingPlanFeature = {
  __typename?: "SchoolBillingPlanFeature"
  description?: Maybe<Scalars["String"]["output"]>
  feature_type?: Maybe<Scalars["Int"]["output"]>
  icon?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  key?: Maybe<Scalars["String"]["output"]>
  link?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  order?: Maybe<Scalars["Int"]["output"]>
  platform_version?: Maybe<Scalars["Float"]["output"]>
}

/** School Billing Plan Set Data */
export type SchoolBillingPlanSet = {
  __typename?: "SchoolBillingPlanSet"
  id?: Maybe<Scalars["Int"]["output"]>
  is_default?: Maybe<Scalars["Boolean"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  plans?: Maybe<Array<Maybe<SchoolBillingPlan>>>
}

/** Pathwright School Billing Scholarship */
export type SchoolBillingScholarship = {
  __typename?: "SchoolBillingScholarship"
  coupon?: Maybe<StripeCoupon>
  ends?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  interval?: Maybe<Interval>
  iterations?: Maybe<Scalars["Int"]["output"]>
  order?: Maybe<Scalars["Int"]["output"]>
  plan_id?: Maybe<Scalars["Int"]["output"]>
  starts?: Maybe<Scalars["DateTime"]["output"]>
}

/** Pathwright School Subscription Data */
export type SchoolBillingSubscription = {
  __typename?: "SchoolBillingSubscription"
  charge_type?: Maybe<SubscriptionChargeType>
  child_subscriptions?: Maybe<SchoolBillingSubscriptionConnection>
  extra_cohorts?: Maybe<Scalars["Int"]["output"]>
  extra_mentor_groups?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  interval?: Maybe<SubscriptionIntervalType>
  parent_subscription_id?: Maybe<Scalars["Int"]["output"]>
  plan?: Maybe<SchoolBillingPlan>
  school?: Maybe<School>
  school_id?: Maybe<Scalars["Int"]["output"]>
  subscription?: Maybe<Subscription>
}

/** Pathwright School Subscription Data */
export type SchoolBillingSubscriptionChild_SubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A connection to a list of items. */
export type SchoolBillingSubscriptionConnection = {
  __typename?: "SchoolBillingSubscriptionConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SchoolBillingSubscriptionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type SchoolBillingSubscriptionEdge = {
  __typename?: "SchoolBillingSubscriptionEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<SchoolBillingSubscription>
}

/** A connection to a list of items. */
export type SchoolConnection = {
  __typename?: "SchoolConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SchoolEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** A school licensing school curriculum subscription plan */
export type SchoolCurriculumPlan = {
  __typename?: "SchoolCurriculumPlan"
  amount?: Maybe<Scalars["Float"]["output"]>
  fee_boundary?: Maybe<Scalars["Int"]["output"]>
  fee_per_user?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  is_visible?: Maybe<Scalars["Boolean"]["output"]>
  licensor?: Maybe<School>
  lower_boundary?: Maybe<Scalars["Int"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  plan_id?: Maybe<Scalars["Int"]["output"]>
  transaction_fee_percent?: Maybe<Scalars["Int"]["output"]>
  upper_boundary?: Maybe<Scalars["Int"]["output"]>
  visibility_code?: Maybe<Scalars["String"]["output"]>
}

/** A connection to a list of items. */
export type SchoolCurriculumPlanConnection = {
  __typename?: "SchoolCurriculumPlanConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SchoolCurriculumPlanEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type SchoolCurriculumPlanEdge = {
  __typename?: "SchoolCurriculumPlanEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<SchoolCurriculumPlan>
}

/** An edge in a connection. */
export type SchoolEdge = {
  __typename?: "SchoolEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<School>
}

/** A list of all features that the space may or may not have access to, with a value indicating whether or not the space has access */
export type SchoolFeatures = {
  __typename?: "SchoolFeatures"
  /** Allows users of a Space to use the Auth Request (via email) feature. */
  auth__login_option__email_link: Scalars["Boolean"]["output"]
  /** Allows users of a Space to use the Apple SSO feature. */
  auth__login_option__global_sso__apple: Scalars["Boolean"]["output"]
  /** Allows users of a Space to use the Google SSO feature. */
  auth__login_option__global_sso__google: Scalars["Boolean"]["output"]
  /** Allows users of a Space to use the Password login feature. */
  auth__login_option__password: Scalars["Boolean"]["output"]
  /** Allows a Space to use the Paths Auth feature. */
  auth__platform: Scalars["Boolean"]["output"]
  /** Allows cohort members to view activity of other members. */
  cohort__activity_feed: Scalars["Boolean"]["output"]
  /** Allows cohort members to view other members in the cohort community. */
  cohort__member_directory: Scalars["Boolean"]["output"]
  /** Allows a Space to use the Community Groups feature. */
  community__groups: Scalars["Boolean"]["output"]
  /** Allows a Space to use the Add to Path feature. */
  library__add_to_path: Scalars["Boolean"]["output"]
  /** Allows a Space to use the Collections feature. */
  library__collections: Scalars["Boolean"]["output"]
  /** Allows a Space to use sync a Path to paths.app. */
  paths_app__publish: Scalars["Boolean"]["output"]
  /** Allows a Space to use the Share Card feature. */
  school__share_card: Scalars["Boolean"]["output"]
}

export type SchoolGiftSubscription = {
  __typename?: "SchoolGiftSubscription"
  gift_code?: Maybe<Scalars["String"]["output"]>
  gift_message?: Maybe<Scalars["String"]["output"]>
  gifted_subscription_cycles?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  recipient_email?: Maybe<Scalars["String"]["output"]>
  recipient_email_sent_time?: Maybe<Scalars["DateTime"]["output"]>
  recipient_first_name?: Maybe<Scalars["String"]["output"]>
  recipient_last_name?: Maybe<Scalars["String"]["output"]>
  redeemed?: Maybe<Scalars["Boolean"]["output"]>
  redemption_link?: Maybe<Scalars["String"]["output"]>
  school_subscription_plan?: Maybe<SchoolSubscriptionPlan>
  sender?: Maybe<User>
}

/** A School Group Subscription */
export type SchoolGroupSubscription = {
  __typename?: "SchoolGroupSubscription"
  community_group?: Maybe<MentorGroup>
  id?: Maybe<Scalars["Int"]["output"]>
  price?: Maybe<StripePrice>
  school?: Maybe<School>
  subscription?: Maybe<Subscription>
  user?: Maybe<User>
}

/** A connection to a list of items. */
export type SchoolGroupSubscriptionConnection = {
  __typename?: "SchoolGroupSubscriptionConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SchoolGroupSubscriptionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type SchoolGroupSubscriptionEdge = {
  __typename?: "SchoolGroupSubscriptionEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<SchoolGroupSubscription>
}

export type SchoolProfile = {
  __typename?: "SchoolProfile"
  id?: Maybe<Scalars["Int"]["output"]>
  plan_to_sell?: Maybe<Scalars["Boolean"]["output"]>
  school_type?: Maybe<SchoolType>
}

/** A School Subscription */
export type SchoolSubscription = {
  __typename?: "SchoolSubscription"
  id?: Maybe<Scalars["Int"]["output"]>
  school_subscription_plan?: Maybe<SchoolSubscriptionPlan>
  subscription?: Maybe<Subscription>
  subscription_plan?: Maybe<SubscriptionPlan>
  user?: Maybe<User>
}

export type SchoolSubscriptionDiscount = {
  __typename?: "SchoolSubscriptionDiscount"
  currency_display_discount_amount?: Maybe<Scalars["String"]["output"]>
  currency_display_discount_price?: Maybe<Scalars["String"]["output"]>
  discount_percent?: Maybe<Scalars["Float"]["output"]>
  discounted_price?: Maybe<Scalars["Float"]["output"]>
  discounted_subscription_cycles?: Maybe<Scalars["Int"]["output"]>
}

/** School subscription order data */
export type SchoolSubscriptionOrderData = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  plan_id: Scalars["Int"]["input"]
  plan_visibility_code?: InputMaybe<Scalars["String"]["input"]>
  redirect_to?: InputMaybe<Scalars["String"]["input"]>
  start_free_trial?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** A School Subscription Plan */
export type SchoolSubscriptionPlan = {
  __typename?: "SchoolSubscriptionPlan"
  catalog_header?: Maybe<Scalars["String"]["output"]>
  details_html?: Maybe<Scalars["String"]["output"]>
  giftable_cycles?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>
  id?: Maybe<Scalars["Int"]["output"]>
  image?: Maybe<Scalars["String"]["output"]>
  is_visible?: Maybe<Scalars["Boolean"]["output"]>
  /** The contextual position for this object based on the `positioningContext` arg. */
  position?: Maybe<Scalars["String"]["output"]>
  subscribe_complete_html?: Maybe<Scalars["String"]["output"]>
  subscription_plan?: Maybe<SubscriptionPlan>
}

/** A connection to a list of items. */
export type SchoolSubscriptionPlanConnection = {
  __typename?: "SchoolSubscriptionPlanConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SchoolSubscriptionPlanEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type SchoolSubscriptionPlanEdge = {
  __typename?: "SchoolSubscriptionPlanEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<SchoolSubscriptionPlan>
}

/** Input for ordering the list. */
export type SchoolSubscriptionPlanOrderByInput = {
  /** Order by id. */
  id?: InputMaybe<OrderByDir>
  /** Order by position. */
  position?: InputMaybe<OrderByDir>
}

export enum SchoolType {
  CoachTutor = "coach_tutor",
  CurriculumPublisher = "curriculum_publisher",
  CustomerTraining = "customer_training",
  HigherEd = "higher_ed",
  K12 = "k12",
  Ministry = "ministry",
  NonProfit = "non_profit",
  Other = "other",
  ProfessionalDevelopment = "professional_development",
  Training = "training",
  Unspecified = "unspecified"
}

export enum ShareAccessPermission {
  Full = "FULL",
  Gated = "GATED",
  None = "NONE"
}

/** The current user's share permissions pertaining to provided context. */
export type ShareCohortPermissions = {
  __typename?: "ShareCohortPermissions"
  /** The email of the user to contact for activating school on a Pathwright plan. */
  activate_contact_email?: Maybe<Scalars["EmailAddress"]["output"]>
  /** The maximum number, if any, of users that may be added. */
  add_limit?: Maybe<Scalars["Int"]["output"]>
  /** Whether user can activate school on a Pathwright plan. */
  allow_activate?: Maybe<Scalars["Boolean"]["output"]>
  /** Whether user can manage settings for the [type/type_id] item. */
  allow_manage?: Maybe<Scalars["Boolean"]["output"]>
  invite_to_roles?: Maybe<ShareCohortPermissionsInviteToRoles>
  /** The settings URL for the [type/type_id] item. */
  manage_url?: Maybe<Scalars["String"]["output"]>
  /** Whether user can use share UI. */
  share_access?: Maybe<CohortShareAccess>
  /** Whether user can use share > add UI. */
  share_add_access?: Maybe<CohortShareAddAccess>
  /** Whether user can use share > link UI. */
  share_link_access?: Maybe<CohortShareLinkAccess>
  /** Whether user can use share > track UI. */
  share_track_access?: Maybe<CohortShareTrackAccess>
  type?: Maybe<ShareContextType>
  type_id?: Maybe<Scalars["Int"]["output"]>
}

export type ShareCohortPermissionsInviteToRoles = {
  __typename?: "ShareCohortPermissionsInviteToRoles"
  course_editor?: Maybe<Scalars["String"]["output"]>
  learner?: Maybe<Scalars["String"]["output"]>
  moderator?: Maybe<Scalars["String"]["output"]>
  observer?: Maybe<Scalars["String"]["output"]>
  teacher?: Maybe<Scalars["String"]["output"]>
  viewer?: Maybe<Scalars["String"]["output"]>
}

export type ShareContext = {
  /** The type of the context in which the user has the permission (defaults to school). */
  type: ShareContextType
  /** The id of the type. */
  type_id?: InputMaybe<Scalars["Int"]["input"]>
}

export enum ShareContextType {
  Cohort = "cohort",
  Group = "group",
  School = "school"
}

/** The current user's share permissions pertaining to provided context. */
export type ShareGroupPermissions = {
  __typename?: "ShareGroupPermissions"
  /** The email of the user to contact for activating school on a Pathwright plan. */
  activate_contact_email?: Maybe<Scalars["EmailAddress"]["output"]>
  /** The maximum number, if any, of users that may be added. */
  add_limit?: Maybe<Scalars["Int"]["output"]>
  /** Whether user can activate school on a Pathwright plan. */
  allow_activate?: Maybe<Scalars["Boolean"]["output"]>
  /** Whether user can manage settings for the [type/type_id] item. */
  allow_manage?: Maybe<Scalars["Boolean"]["output"]>
  invite_to_roles?: Maybe<ShareGroupPermissionsInviteToRoles>
  /** The settings URL for the [type/type_id] item. */
  manage_url?: Maybe<Scalars["String"]["output"]>
  /** Whether user can use share UI. */
  share_access?: Maybe<GroupShareAccess>
  /** Whether user can use share > add UI. */
  share_add_access?: Maybe<GroupShareAddAccess>
  /** Whether user can use share > link UI. */
  share_link_access?: Maybe<GroupShareLinkAccess>
  /** Whether user can use share > track UI. */
  share_track_access?: Maybe<GroupShareTrackAccess>
  type?: Maybe<ShareContextType>
  type_id?: Maybe<Scalars["Int"]["output"]>
}

export type ShareGroupPermissionsInviteToRoles = {
  __typename?: "ShareGroupPermissionsInviteToRoles"
  admin?: Maybe<Scalars["String"]["output"]>
  member?: Maybe<Scalars["String"]["output"]>
  moderator?: Maybe<Scalars["String"]["output"]>
}

/** The current user's share permissions pertaining to provided context. */
export type SharePermissions = ShareCohortPermissions | ShareGroupPermissions

export type Space = {
  __typename?: "Space"
  /** Whether new users can sign up without an invitation. */
  allow_public_signup?: Maybe<Scalars["Boolean"]["output"]>
  /** Signifies whether a Space allows a user to join as a member */
  allow_signup: Scalars["Boolean"]["output"]
  /** Whether to prompt users to enter a password when signing up. */
  always_prompt_for_password?: Maybe<Scalars["Boolean"]["output"]>
  authMethods: Array<AuthMethod>
  /** Description of consent from user for space to do xy and z. */
  consent_description?: Maybe<Scalars["String"]["output"]>
  created: Scalars["String"]["output"]
  features: SpaceFeatures
  id: Scalars["Int"]["output"]
  ietf_language_tag?: Maybe<Scalars["String"]["output"]>
  image: Scalars["String"]["output"]
  is_activated: Scalars["Boolean"]["output"]
  is_owner: Scalars["Boolean"]["output"]
  logo_image?: Maybe<Scalars["String"]["output"]>
  media?: Maybe<Array<Maybe<SpaceMedia>>>
  meta: NodeMeta
  name: Scalars["String"]["output"]
  /** Whether Pathwright Sign-in options (currently Password and Magic Link) are available. */
  pathwright_login_enabled?: Maybe<Scalars["Boolean"]["output"]>
  primary_domain?: Maybe<Scalars["String"]["output"]>
  subdomain: Scalars["String"]["output"]
  theme: NodeTheme
}

export type SpaceAllow_SignupArgs = {
  invite_token?: InputMaybe<Scalars["String"]["input"]>
}

export type SpaceImageArgs = {
  balph?: InputMaybe<Scalars["Int"]["input"]>
  blend?: InputMaybe<Scalars["Int"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  bm?: InputMaybe<Scalars["String"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  fm?: InputMaybe<Scalars["String"]["input"]>
  height?: InputMaybe<Scalars["Int"]["input"]>
  high?: InputMaybe<Scalars["Int"]["input"]>
  q?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

export type SpaceConnection = {
  __typename?: "SpaceConnection"
  last_activity_date?: Maybe<Scalars["DateTime"]["output"]>
  role: Scalars["Int"]["output"]
  space: Space
}

/** A connection to a list of items. */
export type SpaceConnectionConnection = {
  __typename?: "SpaceConnectionConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SpaceConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type SpaceConnectionEdge = {
  __typename?: "SpaceConnectionEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<SpaceConnection>
}

/** A list of all features that the space may or may not have access to, with a value indicating whether or not the space has access */
export type SpaceFeatures = {
  __typename?: "SpaceFeatures"
  /** Allows users of a Space to use the Auth Request (via email) feature. */
  auth__login_option__email_link: Scalars["Boolean"]["output"]
  /** Allows users of a Space to use the Apple SSO feature. */
  auth__login_option__global_sso__apple: Scalars["Boolean"]["output"]
  /** Allows users of a Space to use the Google SSO feature. */
  auth__login_option__global_sso__google: Scalars["Boolean"]["output"]
  /** Allows users of a Space to use the Password login feature. */
  auth__login_option__password: Scalars["Boolean"]["output"]
  /** Allows a Space to use the Paths Auth feature. */
  auth__platform: Scalars["Boolean"]["output"]
  /** Allows a Space to use sync a Path to paths.app. */
  paths_app__publish: Scalars["Boolean"]["output"]
}

export type SpaceMedia = {
  __typename?: "SpaceMedia"
  meta: NodeMeta
}

export type StepContent = {
  __typename?: "StepContent"
  data?: Maybe<Scalars["JSON"]["output"]>
  id?: Maybe<Scalars["String"]["output"]>
  method?: Maybe<Scalars["String"]["output"]>
  type?: Maybe<Scalars["String"]["output"]>
}

/** The context this step belongs to (resource and cohort) */
export type StepContext = {
  __typename?: "StepContext"
  cohort_id?: Maybe<Scalars["Int"]["output"]>
  resource_id?: Maybe<Scalars["Int"]["output"]>
  school_id?: Maybe<Scalars["Int"]["output"]>
}

export type Store = {
  __typename?: "Store"
  id?: Maybe<Scalars["Int"]["output"]>
  /** The school providing the curriculum */
  licensor?: Maybe<School>
}

export type StoreBanner = {
  __typename?: "StoreBanner"
  button_text?: Maybe<Scalars["String"]["output"]>
  button_url?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  meta: NodeMeta
  theme: NodeTheme
}

export type StoreCollection = {
  __typename?: "StoreCollection"
  id?: Maybe<Scalars["Int"]["output"]>
  items?: Maybe<StoreItemConnection>
  meta: NodeMeta
  theme: NodeTheme
}

export type StoreCollectionItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  author_id?: InputMaybe<Scalars["Int"]["input"]>
  category_id?: InputMaybe<Scalars["Int"]["input"]>
  first: Scalars["Int"]["input"]
}

export type StoreItem = {
  __typename?: "StoreItem"
  authors?: Maybe<AuthorConnection>
  category_ids: Array<Maybe<Scalars["Int"]["output"]>>
  description?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  meta: NodeMeta
  options: Array<Maybe<StoreItemOption>>
  short_description?: Maybe<Scalars["String"]["output"]>
  theme: NodeTheme
}

export type StoreItemAuthorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A connection to a list of items. */
export type StoreItemConnection = {
  __typename?: "StoreItemConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StoreItemEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type StoreItemEdge = {
  __typename?: "StoreItemEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<StoreItem>
}

export type StoreItemOption = {
  __typename?: "StoreItemOption"
  id?: Maybe<Scalars["Int"]["output"]>
  meta: NodeMeta
  path_id?: Maybe<Scalars["Int"]["output"]>
  price?: Maybe<Scalars["Int"]["output"]>
}

export type StoreLink = {
  __typename?: "StoreLink"
  id?: Maybe<Scalars["Int"]["output"]>
  /** The school licensing the curriculum */
  licensee?: Maybe<School>
  /** The store through which curriculum can be licensed */
  store?: Maybe<Store>
}

/** A connection to a list of items. */
export type StoreLinkConnection = {
  __typename?: "StoreLinkConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StoreLinkEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type StoreLinkEdge = {
  __typename?: "StoreLinkEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<StoreLink>
}

export type Storefront = {
  __typename?: "Storefront"
  id: Scalars["String"]["output"]
  meta: NodeMeta
}

export type StringFilter = {
  eq?: InputMaybe<Scalars["String"]["input"]>
  gt?: InputMaybe<Scalars["String"]["input"]>
  gte?: InputMaybe<Scalars["String"]["input"]>
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  lt?: InputMaybe<Scalars["String"]["input"]>
  lte?: InputMaybe<Scalars["String"]["input"]>
  neq?: InputMaybe<Scalars["String"]["input"]>
  nin?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export enum StripeBillingScheme {
  PerUnit = "per_unit",
  Tiered = "tiered"
}

/** Represents a Coupon for a Subscription on Stripe. Used for Scholarships. */
export type StripeCoupon = {
  __typename?: "StripeCoupon"
  id?: Maybe<Scalars["Int"]["output"]>
  percent_off?: Maybe<Scalars["Float"]["output"]>
}

/** Represents a Price for a Product on Stripe that specifies pricing configuration. */
export type StripePrice = {
  __typename?: "StripePrice"
  active?: Maybe<Scalars["Boolean"]["output"]>
  billing_scheme?: Maybe<StripeBillingScheme>
  currency?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  nickname?: Maybe<Scalars["String"]["output"]>
  product?: Maybe<StripeProduct>
  recurring_interval?: Maybe<StripeRecurringInterval>
  recurring_interval_count?: Maybe<Scalars["Int"]["output"]>
  stripe_price_id?: Maybe<Scalars["String"]["output"]>
  tiers?: Maybe<Array<Maybe<StripeTier>>>
  tiers_mode?: Maybe<StripeTiersMode>
  unit_amount_decimal?: Maybe<Scalars["Float"]["output"]>
}

/** A connection to a list of items. */
export type StripePriceConnection = {
  __typename?: "StripePriceConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StripePriceEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type StripePriceEdge = {
  __typename?: "StripePriceEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<StripePrice>
}

/** Represents a Product on Stripe that can be sold. */
export type StripeProduct = {
  __typename?: "StripeProduct"
  active?: Maybe<Scalars["Boolean"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  images?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  max_quantity?: Maybe<Scalars["Int"]["output"]>
  min_quantity?: Maybe<Scalars["Int"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  processor?: Maybe<School>
  stripe_prices?: Maybe<StripePriceConnection>
  stripe_product_id?: Maybe<Scalars["String"]["output"]>
  unit_label?: Maybe<Scalars["String"]["output"]>
}

/** Represents a Product on Stripe that can be sold. */
export type StripeProductStripe_PricesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A connection to a list of items. */
export type StripeProductConnection = {
  __typename?: "StripeProductConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StripeProductEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type StripeProductEdge = {
  __typename?: "StripeProductEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<StripeProduct>
}

export enum StripeRecurringInterval {
  Day = "day",
  Month = "month",
  Week = "week",
  Year = "year"
}

export type StripeTier = {
  __typename?: "StripeTier"
  flat_amount?: Maybe<Scalars["Int"]["output"]>
  flat_amount_decimal?: Maybe<Scalars["String"]["output"]>
  unit_amount?: Maybe<Scalars["Int"]["output"]>
  unit_amount_decimal?: Maybe<Scalars["String"]["output"]>
  up_to?: Maybe<Scalars["Int"]["output"]>
}

export enum StripeTiersMode {
  Graduated = "graduated",
  Volume = "volume"
}

export type Subdomain = {
  __typename?: "Subdomain"
  id?: Maybe<Scalars["Int"]["output"]>
  subdomain?: Maybe<Scalars["String"]["output"]>
}

/** Subscription Data */
export type Subscription = {
  __typename?: "Subscription"
  canceled_dtime?: Maybe<Scalars["DateTime"]["output"]>
  cancellation_reason?: Maybe<Scalars["Int"]["output"]>
  card_last_4?: Maybe<Scalars["String"]["output"]>
  customer?: Maybe<User>
  cycle_end_dtime?: Maybe<Scalars["DateTime"]["output"]>
  cycle_start_dtime?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  is_canceled_at_cycle_end?: Maybe<Scalars["Boolean"]["output"]>
  is_delinquent?: Maybe<Scalars["Boolean"]["output"]>
  paused_until_dtime?: Maybe<Scalars["DateTime"]["output"]>
  /** The client_secret set on the payment intent associated with this subscription (if subscription.payment_intent_needing_action_id is not null). */
  payment_intent_client_secret?: Maybe<Scalars["String"]["output"]>
  payment_intent_needing_action_id?: Maybe<Scalars["String"]["output"]>
  quantity?: Maybe<Scalars["Int"]["output"]>
  /** Used in conjunction with the payment_intent_client_secret to execute the handleCardAction via Stripe.js. */
  stripe_publishable_api_key?: Maybe<Scalars["String"]["output"]>
  subscription_plan?: Maybe<SubscriptionPlan>
  trial_end_dtime?: Maybe<Scalars["DateTime"]["output"]>
  trial_type?: Maybe<Scalars["Int"]["output"]>
}

export enum SubscriptionChargeType {
  Invoice = "invoice",
  NoCharge = "no_charge",
  PathwrightTeam = "pathwright_team",
  Stripe = "stripe"
}

/** A connection to a list of items. */
export type SubscriptionConnection = {
  __typename?: "SubscriptionConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SubscriptionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type SubscriptionEdge = {
  __typename?: "SubscriptionEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Subscription>
}

export type SubscriptionIntervalFilter = {
  eq?: InputMaybe<SubscriptionIntervalType>
  gt?: InputMaybe<SubscriptionIntervalType>
  gte?: InputMaybe<SubscriptionIntervalType>
  in?: InputMaybe<Array<InputMaybe<SubscriptionIntervalType>>>
  lt?: InputMaybe<SubscriptionIntervalType>
  lte?: InputMaybe<SubscriptionIntervalType>
  neq?: InputMaybe<SubscriptionIntervalType>
  nin?: InputMaybe<Array<InputMaybe<SubscriptionIntervalType>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export enum SubscriptionIntervalType {
  Biannually = "biannually",
  Lifetime = "lifetime",
  Monthly = "monthly",
  Yearly = "yearly"
}

/** Payment Subscription Plan Data */
export type SubscriptionPlan = {
  __typename?: "SubscriptionPlan"
  amount?: Maybe<Scalars["Float"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  interval?: Maybe<Scalars["Int"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  plan_type?: Maybe<Scalars["String"]["output"]>
  trial_period_days?: Maybe<Scalars["Int"]["output"]>
  trial_type?: Maybe<Scalars["Int"]["output"]>
}

/** A generic tag */
export type Tag = {
  __typename?: "Tag"
  color?: Maybe<Scalars["String"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  school?: Maybe<School>
  slug?: Maybe<Scalars["String"]["output"]>
  tagAttachment?: Maybe<TagAttachment>
  tagAttachments?: Maybe<TagAttachmentConnection>
}

/** A generic tag */
export type TagTagAttachmentArgs = {
  context?: InputMaybe<TagAttachmentContextInput>
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A generic tag */
export type TagTagAttachmentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context?: InputMaybe<TagAttachmentContextInput>
  first: Scalars["Int"]["input"]
  tag_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A tag attachment provides a means of attaching a tag to a context. */
export type TagAttachment = {
  __typename?: "TagAttachment"
  id?: Maybe<Scalars["Int"]["output"]>
  position?: Maybe<Scalars["String"]["output"]>
  resource?: Maybe<Resource>
  resource_id?: Maybe<Scalars["Int"]["output"]>
  school?: Maybe<School>
  school_id?: Maybe<Scalars["Int"]["output"]>
  tag?: Maybe<Tag>
  tag_id?: Maybe<Scalars["Int"]["output"]>
}

/** A connection to a list of items. */
export type TagAttachmentConnection = {
  __typename?: "TagAttachmentConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TagAttachmentEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

export type TagAttachmentContextInput = {
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** An edge in a connection. */
export type TagAttachmentEdge = {
  __typename?: "TagAttachmentEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<TagAttachment>
}

/** A connection to a list of items. */
export type TagConnection = {
  __typename?: "TagConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TagEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

export type TagCreateInput = {
  color?: InputMaybe<Scalars["String"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  name: Scalars["String"]["input"]
}

export type TagDeleteInput = {
  id: Scalars["Int"]["input"]
}

/** An edge in a connection. */
export type TagEdge = {
  __typename?: "TagEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Tag>
}

/** A tagLink that links tags to other models within a tagAttachment context, currently only to a discussion context. */
export type TagLink = {
  __typename?: "TagLink"
  discussion_context_id?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  tag?: Maybe<Tag>
  tag_id?: Maybe<Scalars["Int"]["output"]>
}

/** A connection to a list of items. */
export type TagLinkConnection = {
  __typename?: "TagLinkConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TagLinkEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

export type TagLinkContextInput = {
  discussion_context_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** An edge in a connection. */
export type TagLinkEdge = {
  __typename?: "TagLinkEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<TagLink>
}

export type TagUpdateInput = {
  color?: InputMaybe<Scalars["String"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  id: Scalars["Int"]["input"]
  name?: InputMaybe<Scalars["String"]["input"]>
}

export type TextObject = {
  alignment: TextObjectAlignment
  dimensions: TextObjectDimensions
  font_color: Scalars["String"]["input"]
  font_name: Scalars["String"]["input"]
  font_size: Scalars["Float"]["input"]
  gutter?: InputMaybe<Scalars["Float"]["input"]>
  letter_spacing?: InputMaybe<Scalars["Float"]["input"]>
  line_height?: InputMaybe<Scalars["Float"]["input"]>
  position: TextObjectPosition
  text: Scalars["String"]["input"]
}

export enum TextObjectAlignment {
  Center = "center",
  Left = "left",
  Right = "right"
}

export type TextObjectDimensions = {
  height: Scalars["Float"]["input"]
  width: Scalars["Float"]["input"]
}

export type TextObjectPosition = {
  left: Scalars["Float"]["input"]
  top: Scalars["Float"]["input"]
}

/** A School Theme */
export type Theme = {
  __typename?: "Theme"
  background_image?: Maybe<Scalars["String"]["output"]>
  background_overlay?: Maybe<Scalars["String"]["output"]>
  background_type?: Maybe<Scalars["Int"]["output"]>
  color_palette?: Maybe<NodeThemeColorPalette>
  custom_css?: Maybe<Scalars["String"]["output"]>
  heading_font?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  primary_color?: Maybe<Scalars["String"]["output"]>
  processed_custom_css?: Maybe<Scalars["String"]["output"]>
  theme_type?: Maybe<ThemeType>
  typekit_id?: Maybe<Scalars["String"]["output"]>
}

/** A School Theme */
export type ThemeBackground_ImageArgs = {
  balph?: InputMaybe<Scalars["Int"]["input"]>
  blend?: InputMaybe<Scalars["Int"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  bm?: InputMaybe<Scalars["String"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  fm?: InputMaybe<Scalars["String"]["input"]>
  height?: InputMaybe<Scalars["Int"]["input"]>
  high?: InputMaybe<Scalars["Int"]["input"]>
  q?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** Theme attributes */
export type ThemeInput = {
  background_image?: InputMaybe<Scalars["URL"]["input"]>
  background_overlay?: InputMaybe<Scalars["String"]["input"]>
  background_type?: InputMaybe<Scalars["Int"]["input"]>
  custom_css?: InputMaybe<Scalars["String"]["input"]>
  heading_font?: InputMaybe<Scalars["String"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  primary_color?: InputMaybe<Scalars["String"]["input"]>
  theme_type?: InputMaybe<ThemeType>
}

export enum ThemeType {
  Classic = "CLASSIC",
  Creative = "CREATIVE",
  Default = "DEFAULT",
  Legacy = "LEGACY",
  Modern = "MODERN"
}

/** Auth token */
export type Token = {
  __typename?: "Token"
  created?: Maybe<Scalars["DateTime"]["output"]>
  token?: Maybe<Scalars["String"]["output"]>
  user_id?: Maybe<Scalars["Int"]["output"]>
}

/** The context of a Pathwright 'session' in a school. */
export type Translation = {
  __typename?: "Translation"
  id?: Maybe<Scalars["Int"]["output"]>
  is_default?: Maybe<Scalars["Boolean"]["output"]>
  language?: Maybe<Scalars["String"]["output"]>
  /** Returning the default english translation when the school has a custom translation. */
  master_translation?: Maybe<Scalars["JSON"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  school_translation?: Maybe<Scalars["JSON"]["output"]>
  source_translation?: Maybe<Scalars["JSON"]["output"]>
  translation?: Maybe<Scalars["JSON"]["output"]>
}

/** A Pathwright User */
export type User = {
  __typename?: "User"
  display_name?: Maybe<Scalars["String"]["output"]>
  email?: Maybe<Scalars["String"]["output"]>
  external_account_link?: Maybe<ExternalAccountLink>
  first_name?: Maybe<Scalars["String"]["output"]>
  full_name?: Maybe<Scalars["String"]["output"]>
  gravatar?: Maybe<Gravatar>
  group_role_stats?: Maybe<Array<Maybe<GroupRoleStats>>>
  id?: Maybe<Scalars["Int"]["output"]>
  /** A user account setup is onl considered 'finalized' when the `first_name` and `last_name` fields have been set. */
  is_finalized?: Maybe<Scalars["Boolean"]["output"]>
  is_staff?: Maybe<Scalars["Boolean"]["output"]>
  is_superuser?: Maybe<Scalars["Boolean"]["output"]>
  last_name?: Maybe<Scalars["String"]["output"]>
  membership?: Maybe<Membership>
  memberships?: Maybe<MembershipConnection>
  mentorGroupMemberships?: Maybe<MentorGroupMembershipConnection>
  no_password_set?: Maybe<Scalars["Boolean"]["output"]>
  permissions?: Maybe<ContextPermissions>
  profile?: Maybe<Profile>
  registrations?: Maybe<RegistrationConnection>
  school_group_subscriptions?: Maybe<SchoolGroupSubscriptionConnection>
  school_subscription?: Maybe<SchoolSubscription>
  token?: Maybe<Token>
  username?: Maybe<Scalars["String"]["output"]>
}

/** A Pathwright User */
export type UserMembershipsArgs = {
  access_revoked_date_filter?: InputMaybe<Array<InputMaybe<DateFilter>>>
  after?: InputMaybe<Scalars["String"]["input"]>
  archived_date_filter?: InputMaybe<Array<InputMaybe<DateFilter>>>
  daysInactive?: InputMaybe<Scalars["Int"]["input"]>
  first: Scalars["Int"]["input"]
  global?: InputMaybe<Scalars["Boolean"]["input"]>
  hasAccess?: InputMaybe<Scalars["Boolean"]["input"]>
  orderBy?: InputMaybe<Scalars["String"]["input"]>
  role_filter?: InputMaybe<Array<InputMaybe<MembershipRoleFilter>>>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright User */
export type UserMentorGroupMembershipsArgs = {
  _or?: InputMaybe<MentorGroupMembershipOr>
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  group_type?: InputMaybe<MentorGroupType>
  group_types?: InputMaybe<MentorGroupTypeFilter>
  mentor_group_seat_limit?: InputMaybe<Scalars["Int"]["input"]>
  mentor_group_seat_limit_filter?: InputMaybe<IntFilter>
  mentor_group_seats_filled?: InputMaybe<Scalars["Int"]["input"]>
  mentor_group_seats_filled_filter?: InputMaybe<IntFilter>
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  role?: InputMaybe<MentorGroupMembershipRoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright User */
export type UserRegistrationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  completion_state?: InputMaybe<CompletionStateFilter>
  created?: InputMaybe<DateFilter>
  first: Scalars["Int"]["input"]
  has_parent_registration?: InputMaybe<Scalars["Boolean"]["input"]>
  last_active?: InputMaybe<DateFilter>
  orderBy?: InputMaybe<Array<InputMaybe<RegistrationOrderByInput>>>
  parent_cohort_filter?: InputMaybe<IdFilter>
  positioningContext?: InputMaybe<PositioningContextInput>
  progress_filter?: InputMaybe<ProgressFilter>
  role?: InputMaybe<RegistrationRoleEnum>
  role_filter?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  send_discussion_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
  send_due_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
  softArchived?: InputMaybe<DateFilter>
}

/** A Pathwright User */
export type UserSchool_Group_SubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** Check on the existence and verification status of any email/username. */
export type UserStatus = {
  __typename?: "UserStatus"
  /**
   * Whether a user exists with the provided email (or username).
   * @deprecated Use 'user_exists' instead.
   */
  email_exists: Scalars["Boolean"]["output"]
  /** Whether a user's email has been verified. */
  email_verified: Scalars["Boolean"]["output"]
  /** Whether a user has a membership for the Space. */
  has_membership: Scalars["Boolean"]["output"]
  /** Indicates the type of input received. */
  input_type: UserStatusInputType
  /**
   * Whether the email or username is being proxied.
   * @deprecated Use 'input_type' instead.
   */
  is_proxy: Scalars["Boolean"]["output"]
  /** Whether a user has a password set. */
  no_password_set: Scalars["Boolean"]["output"]
  /** Whether the email or username exists. */
  user_exists: Scalars["Boolean"]["output"]
}

export enum UserStatusInputType {
  Email = "email",
  Proxy = "proxy",
  Username = "username"
}

/** Legacy content (only applicable to Path Items using legacy content, pre-dating Blocks). */
export type UserSubmission = {
  __typename?: "UserSubmission"
  file_image?: Maybe<Scalars["String"]["output"]>
  file_name?: Maybe<Scalars["String"]["output"]>
  file_url?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  text?: Maybe<Scalars["String"]["output"]>
}

/** An event from a webhook subscription. Currently these will only be faild webhook events. */
export type WebhookEvent = {
  __typename?: "WebhookEvent"
  /** The event types to being subscribed to. */
  event: WebhookEventType
  event_data: Scalars["JSON"]["output"]
  last_attempt_dtime: Scalars["DateTime"]["output"]
  status: WebhookEventStatus
  uuid: Scalars["UUID"]["output"]
  webhook_subscription: WebhookSubscription
}

/** A connection to a list of items. */
export type WebhookEventConnection = {
  __typename?: "WebhookEventConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WebhookEventEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total: Scalars["Int"]["output"]
}

/** An edge in a connection. */
export type WebhookEventEdge = {
  __typename?: "WebhookEventEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<WebhookEvent>
}

/** Input for ordering the list. */
export type WebhookEventOrderBy = {
  /** Order by last_attempt_dtime. */
  last_attempt_dtime?: InputMaybe<OrderByDir>
}

export enum WebhookEventStatus {
  Failed = "FAILED"
}

export enum WebhookEventType {
  All = "ALL",
  CohortCreated = "COHORT_CREATED",
  NewLearningActivity = "NEW_LEARNING_ACTIVITY",
  RegistrationSucceeded = "REGISTRATION_SUCCEEDED",
  StepCompletionReviewed = "STEP_COMPLETION_REVIEWED",
  StudentCourseCompleted = "STUDENT_COURSE_COMPLETED",
  StudentMembershipCreated = "STUDENT_MEMBERSHIP_CREATED",
  StudentMembershipUpdated = "STUDENT_MEMBERSHIP_UPDATED",
  StudentRegistrationSucceeded = "STUDENT_REGISTRATION_SUCCEEDED",
  StudentSubscriptionCanceled = "STUDENT_SUBSCRIPTION_CANCELED",
  StudentSubscriptionSucceeded = "STUDENT_SUBSCRIPTION_SUCCEEDED",
  UserEmailUpdated = "USER_EMAIL_UPDATED"
}

/** An event from a webhook subscription. Currently these will only be faild webhook events. */
export type WebhookSubscription = {
  __typename?: "WebhookSubscription"
  /** When the credentials were archived. */
  archived_time?: Maybe<Scalars["DateTime"]["output"]>
  /** The time the credentials were created. */
  created_time: Scalars["DateTime"]["output"]
  /** The event types to being subscribed to. */
  event: WebhookEventType
  id: Scalars["Int"]["output"]
  /** The school this subscription belongs to. */
  school: School
  /** The receiver URL. */
  target_url: Scalars["URL"]["output"]
}

export enum Crop {
  Bottom = "bottom",
  Edges = "edges",
  Entropy = "entropy",
  Faces = "faces",
  Focalpoint = "focalpoint",
  Left = "left",
  Right = "right",
  Top = "top"
}

export enum Fit {
  Clamp = "clamp",
  Clip = "clip",
  Crop = "crop",
  Facearea = "facearea",
  Fill = "fill",
  Fillmax = "fillmax",
  Max = "max",
  Min = "min",
  Scale = "scale"
}

export enum Interval {
  Annually = "annually",
  Monthly = "monthly"
}

export enum Visibility {
  Pathwright_1 = "pathwright_1",
  Pathwright_2 = "pathwright_2",
  PrivateCustom = "private_custom"
}

export type CreateSchoolThemeMutationVariables = Exact<{
  theme: ThemeInput
}>

export type CreateSchoolThemeMutation = {
  __typename?: "Mutations"
  createSchoolTheme?: {
    __typename?: "Theme"
    id?: number | null
    name?: string | null
    theme_type?: ThemeType | null
    background_image?: string | null
    background_overlay?: string | null
    background_type?: number | null
    heading_font?: string | null
    primary_color?: string | null
    typekit_id?: string | null
    custom_css?: string | null
    processed_custom_css?: string | null
    color_palette?: {
      __typename?: "NodeThemeColorPalette"
      _50?: string | null
      _100?: string | null
      _200?: string | null
      _300?: string | null
      _400?: string | null
      _500?: string | null
      _600?: string | null
      _700?: string | null
      _800?: string | null
      _900?: string | null
    } | null
  } | null
}

export type UpdateSchoolThemeMutationVariables = Exact<{
  id: Scalars["Int"]["input"]
  theme: ThemeInput
}>

export type UpdateSchoolThemeMutation = {
  __typename?: "Mutations"
  updateSchoolTheme?: {
    __typename?: "Theme"
    id?: number | null
    name?: string | null
    theme_type?: ThemeType | null
    background_image?: string | null
    background_overlay?: string | null
    background_type?: number | null
    heading_font?: string | null
    primary_color?: string | null
    typekit_id?: string | null
    custom_css?: string | null
    processed_custom_css?: string | null
    color_palette?: {
      __typename?: "NodeThemeColorPalette"
      _50?: string | null
      _100?: string | null
      _200?: string | null
      _300?: string | null
      _400?: string | null
      _500?: string | null
      _600?: string | null
      _700?: string | null
      _800?: string | null
      _900?: string | null
    } | null
  } | null
}

export type AssignResourceQueryVariables = Exact<{
  id?: InputMaybe<Scalars["Int"]["input"]>
  assignConfig?: InputMaybe<AssignConfig>
  search?: InputMaybe<Scalars["String"]["input"]>
  cursor?: InputMaybe<Scalars["String"]["input"]>
}>

export type AssignResourceQuery = {
  __typename?: "Query"
  resource?: {
    __typename?: "Resource"
    id: number
    name: string
    slug: string
    created?: any | null
    resource_type?: number | null
    visibility?: number | null
    description?: string | null
    short_description?: string | null
    background_overlay?: string | null
    background_image?: string | null
    image_raw?: string | null
    has_certificate?: boolean | null
    discussion_enabled?: boolean | null
    lowest_public_offering_price?: number | null
    highest_public_offering_price?: number | null
    licensed_from_resource_id?: number | null
    licensed_from_school_id?: number | null
    created_by_id?: number | null
    image: string
    cover_image: string
    cohorts?: {
      __typename?: "GroupConnection"
      total: number
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
      edges?: Array<{
        __typename?: "GroupEdge"
        node?: {
          __typename?: "Group"
          id: number
          name: string
          created?: any | null
          description?: string | null
          price?: number | null
          seats?: number | null
          seats_filled?: number | null
          seats_available?: number | null
          registration_ends?: any | null
          registration_starts?: any | null
          registration_started?: boolean | null
          registration_ended?: boolean | null
          access_ends?: any | null
          access_starts?: any | null
          is_master?: boolean | null
          is_source_cohort?: boolean | null
          source_cohort_id?: number | null
          parent_section_id?: number | null
          visibility?: number | null
          is_collection_linked?: boolean | null
          is_subscription_only?: boolean | null
          status?: GroupStatus | null
          analytics_disabled?: boolean | null
          created_by_id?: number | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type BackgroundTaskQueryVariables = Exact<{
  id: Scalars["String"]["input"]
}>

export type BackgroundTaskQuery = {
  __typename?: "Query"
  backgroundTask?: {
    __typename?: "BackgroundTask"
    id: any
    status: BackgroundTaskStatus
    result?: any | null
  } | null
}

export type BackgroundTaskFragment = {
  __typename?: "BackgroundTask"
  id: any
  status: BackgroundTaskStatus
  result?: any | null
}

export type BackgroundTasksQueryVariables = Exact<{
  ids:
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
}>

export type BackgroundTasksQuery = {
  __typename?: "Query"
  backgroundTasks?: Array<{
    __typename?: "BackgroundTask"
    id: any
    status: BackgroundTaskStatus
    result?: any | null
  } | null> | null
}

export type CohortFragment = {
  __typename?: "Group"
  id: number
  name: string
  created?: any | null
  created_by_id?: number | null
  status?: GroupStatus | null
  is_collection_linked?: boolean | null
  is_master?: boolean | null
  access_starts?: any | null
  parent_section_id?: number | null
  access_ends?: any | null
  registration?: {
    __typename?: "Registration"
    id: number
    role?: number | null
    send_due_notifications?: boolean | null
    send_discussion_notifications?: boolean | null
  } | null
  path: { __typename?: "Path"; id: number }
  resource?: {
    __typename?: "Resource"
    id: number
    name: string
    image: string
    slug: string
    background_image?: string | null
    background_overlay?: string | null
    resource_type?: number | null
  } | null
  resource_license?: {
    __typename?: "ResourceLicense"
    id?: number | null
    expiration_dtime?: any | null
  } | null
}

export type CohortQueryVariables = Exact<{
  id: Scalars["Int"]["input"]
}>

export type CohortQuery = {
  __typename?: "Query"
  cohort?: {
    __typename?: "Group"
    id: number
    name: string
    created?: any | null
    created_by_id?: number | null
    status?: GroupStatus | null
    is_collection_linked?: boolean | null
    is_master?: boolean | null
    access_starts?: any | null
    parent_section_id?: number | null
    access_ends?: any | null
    registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
      send_due_notifications?: boolean | null
      send_discussion_notifications?: boolean | null
    } | null
    path: { __typename?: "Path"; id: number }
    resource?: {
      __typename?: "Resource"
      id: number
      name: string
      image: string
      slug: string
      background_image?: string | null
      background_overlay?: string | null
      resource_type?: number | null
    } | null
    resource_license?: {
      __typename?: "ResourceLicense"
      id?: number | null
      expiration_dtime?: any | null
    } | null
  } | null
}

export type CohortSwitcherCohortsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>
  cursor?: InputMaybe<Scalars["String"]["input"]>
  resourceId: Scalars["Int"]["input"]
  role?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
}>

export type CohortSwitcherCohortsQuery = {
  __typename?: "Query"
  cohorts?: {
    __typename?: "GroupConnection"
    total: number
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges?: Array<{
      __typename?: "GroupEdge"
      node?: {
        __typename?: "Group"
        id: number
        name: string
        created?: any | null
        created_by_id?: number | null
        status?: GroupStatus | null
        is_collection_linked?: boolean | null
        is_master?: boolean | null
        access_starts?: any | null
        parent_section_id?: number | null
        access_ends?: any | null
        registration?: {
          __typename?: "Registration"
          id: number
          role?: number | null
          send_due_notifications?: boolean | null
          send_discussion_notifications?: boolean | null
        } | null
        path: { __typename?: "Path"; id: number }
        resource?: {
          __typename?: "Resource"
          id: number
          name: string
          image: string
          slug: string
          background_image?: string | null
          background_overlay?: string | null
          resource_type?: number | null
        } | null
        resource_license?: {
          __typename?: "ResourceLicense"
          id?: number | null
          expiration_dtime?: any | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type CohortsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars["String"]["input"]>
}>

export type CohortsQuery = {
  __typename?: "Query"
  groups?: {
    __typename?: "GroupConnection"
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges?: Array<{
      __typename?: "GroupEdge"
      node?: {
        __typename?: "Group"
        id: number
        name: string
        created?: any | null
        created_by_id?: number | null
        status?: GroupStatus | null
        is_collection_linked?: boolean | null
        is_master?: boolean | null
        access_starts?: any | null
        parent_section_id?: number | null
        access_ends?: any | null
        registration?: {
          __typename?: "Registration"
          id: number
          role?: number | null
          send_due_notifications?: boolean | null
          send_discussion_notifications?: boolean | null
        } | null
        path: { __typename?: "Path"; id: number }
        resource?: {
          __typename?: "Resource"
          id: number
          name: string
          image: string
          slug: string
          background_image?: string | null
          background_overlay?: string | null
          resource_type?: number | null
        } | null
        resource_license?: {
          __typename?: "ResourceLicense"
          id?: number | null
          expiration_dtime?: any | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type CompletionFragment = {
  __typename?: "Completion"
  id?: number | null
  checked_date?: any | null
  completion_attempts?: number | null
  completion_comment?: string | null
  completion_date?: any | null
  completion_due?: any | null
  last_completion_attempt_date?: any | null
  has_failed_passing_attempt?: boolean | null
  has_points?: boolean | null
  is_late?: boolean | null
  is_graded?: boolean | null
  is_skipped?: boolean | null
  is_complete?: boolean | null
  is_auto_graded?: boolean | null
  is_forced_completion?: boolean | null
  needs_grading?: boolean | null
  userpoints_earned?: number | null
  userpoints_explanation?: string | null
  userpoints_value?: number | null
  minimum_passing_score?: number | null
  grading_type?: string | null
  requires_api_completion?: boolean | null
  overridden_by_api?: boolean | null
  overridden_by?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      rewardful_referral_id?: string | null
    } | null
  } | null
  checked_by?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      rewardful_referral_id?: string | null
    } | null
  } | null
  user?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      rewardful_referral_id?: string | null
    } | null
  } | null
  submission?: {
    __typename?: "UserSubmission"
    id?: number | null
    text?: string | null
    file_url?: string | null
    file_name?: string | null
    file_image?: string | null
  } | null
  feedback_discussion_context?: {
    __typename?: "CompletionFeedbackDiscussionContext"
    id?: number | null
    discussion_id?: number | null
    num_responses?: number | null
  } | null
}

export type CompletionQueryVariables = Exact<{
  id?: InputMaybe<Scalars["Int"]["input"]>
}>

export type CompletionQuery = {
  __typename?: "Query"
  pathItem?: {
    __typename?: "PathItem"
    id: number
    order: number
    verb?: string | null
    name: string
    type: PathItemType
    path_id: number
    source_id: number
    parent_id?: number | null
    parent_source_id?: number | null
    description?: string | null
    curriculum_sync_opt_out_dtime?: any | null
    assignment_type?: PathItemAssignmentType | null
    content_id?: number | null
    content_type?: string | null
    item_content_type_id?: number | null
    is_required?: boolean | null
    is_previewable?: boolean | null
    due?: any | null
    passive_due?: boolean | null
    starts?: any | null
    passive_starts?: boolean | null
    has_started?: boolean | null
    points?: number | null
    item_needs_grading?: boolean | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    time_to_complete?: number | null
    lock_password?: string | null
    is_locked?: boolean | null
    lock_unlocked_time?: any | null
    allow_resets?: number | null
    show_grading_feedback?: number | null
    completion_comment_required_status?: string | null
    last_published_dtime?: any | null
    progress: number
    parent?: { __typename?: "PathItem"; id: number; name: string } | null
    path?: {
      __typename?: "Path"
      id: number
      progress?: number | null
      points?: {
        __typename?: "Points"
        path_id?: number | null
        total?: number | null
        earned?: number | null
        percentage?: number | null
        total_points_possible?: number | null
        has_points?: boolean | null
      } | null
    } | null
    resource?: {
      __typename?: "Resource"
      id: number
      name: string
      image: string
    } | null
    context?: {
      __typename?: "StepContext"
      resource_id?: number | null
      cohort_id?: number | null
    } | null
    blocks?: {
      __typename?: "BlocksContent"
      content_last_modified?: any | null
      last_published?: any | null
    } | null
    permissions?: {
      __typename?: "PathItemPermissions"
      can_complete?: boolean | null
      can_discuss?: boolean | null
      can_edit?: boolean | null
      can_edit_content?: boolean | null
      can_note?: boolean | null
      can_view?: boolean | null
      can_review?: boolean | null
      can_view_content?: boolean | null
    } | null
    completion?: {
      __typename?: "Completion"
      id?: number | null
      checked_date?: any | null
      completion_attempts?: number | null
      completion_comment?: string | null
      completion_date?: any | null
      completion_due?: any | null
      last_completion_attempt_date?: any | null
      has_failed_passing_attempt?: boolean | null
      has_points?: boolean | null
      is_late?: boolean | null
      is_graded?: boolean | null
      is_skipped?: boolean | null
      is_complete?: boolean | null
      is_auto_graded?: boolean | null
      is_forced_completion?: boolean | null
      needs_grading?: boolean | null
      userpoints_earned?: number | null
      userpoints_explanation?: string | null
      userpoints_value?: number | null
      minimum_passing_score?: number | null
      grading_type?: string | null
      requires_api_completion?: boolean | null
      overridden_by_api?: boolean | null
      overridden_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      checked_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      submission?: {
        __typename?: "UserSubmission"
        id?: number | null
        text?: string | null
        file_url?: string | null
        file_name?: string | null
        file_image?: string | null
      } | null
      feedback_discussion_context?: {
        __typename?: "CompletionFeedbackDiscussionContext"
        id?: number | null
        discussion_id?: number | null
        num_responses?: number | null
      } | null
    } | null
    linked_resource?: {
      __typename?: "Resource"
      id: number
      name: string
      slug: string
      created?: any | null
      resource_type?: number | null
      visibility?: number | null
      description?: string | null
      short_description?: string | null
      background_overlay?: string | null
      background_image?: string | null
      image_raw?: string | null
      has_certificate?: boolean | null
      discussion_enabled?: boolean | null
      lowest_public_offering_price?: number | null
      highest_public_offering_price?: number | null
      licensed_from_resource_id?: number | null
      licensed_from_school_id?: number | null
      created_by_id?: number | null
      image: string
      cover_image: string
      curriculum_group?: { __typename?: "Group"; id: number } | null
    } | null
    linked_cohort?: {
      __typename?: "Group"
      id: number
      user_registration?: {
        __typename?: "Registration"
        id: number
        role?: number | null
        completion_state?: number | null
        send_due_notifications?: boolean | null
        send_discussion_notifications?: boolean | null
        last_activity_time?: any | null
        expiration_dtime?: any | null
        created?: any | null
        path?: { __typename?: "Path"; id: number } | null
        user?: {
          __typename?: "User"
          id?: number | null
          email?: string | null
          first_name?: string | null
          last_name?: string | null
          full_name?: string | null
          display_name?: string | null
          profile?: {
            __typename?: "Profile"
            id?: number | null
            bio?: string | null
            image?: string | null
            location?: string | null
            last_activity?: any | null
            rewardful_referral_id?: string | null
          } | null
        } | null
      } | null
    } | null
    linked_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
      completion_state?: number | null
      send_due_notifications?: boolean | null
      send_discussion_notifications?: boolean | null
      last_activity_time?: any | null
      expiration_dtime?: any | null
      created?: any | null
      path?: { __typename?: "Path"; id: number } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type CompletionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  group_id?: InputMaybe<Scalars["Int"]["input"]>
  path_id?: InputMaybe<Scalars["Int"]["input"]>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
  lesson_source_id?: InputMaybe<Scalars["Int"]["input"]>
  source_id?: InputMaybe<Scalars["Int"]["input"]>
  needs_review?: InputMaybe<Scalars["Boolean"]["input"]>
  is_reviewed?: InputMaybe<Scalars["Boolean"]["input"]>
  is_complete?: InputMaybe<Scalars["Boolean"]["input"]>
  order_by?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >
  cursor?: InputMaybe<Scalars["String"]["input"]>
}>

export type CompletionsQuery = {
  __typename?: "Query"
  pathItems?: {
    __typename?: "PathItemConnection"
    total: number
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges?: Array<{
      __typename?: "PathItemEdge"
      node?: {
        __typename?: "PathItem"
        id: number
        order: number
        verb?: string | null
        name: string
        type: PathItemType
        path_id: number
        source_id: number
        parent_id?: number | null
        parent_source_id?: number | null
        description?: string | null
        curriculum_sync_opt_out_dtime?: any | null
        assignment_type?: PathItemAssignmentType | null
        content_id?: number | null
        content_type?: string | null
        item_content_type_id?: number | null
        is_required?: boolean | null
        is_previewable?: boolean | null
        due?: any | null
        passive_due?: boolean | null
        starts?: any | null
        passive_starts?: boolean | null
        has_started?: boolean | null
        points?: number | null
        item_needs_grading?: boolean | null
        minimum_passing_score?: number | null
        grading_type?: string | null
        time_to_complete?: number | null
        lock_password?: string | null
        is_locked?: boolean | null
        lock_unlocked_time?: any | null
        allow_resets?: number | null
        show_grading_feedback?: number | null
        completion_comment_required_status?: string | null
        last_published_dtime?: any | null
        progress: number
        cohort?: { __typename?: "Group"; id: number; name: string } | null
        resource?: {
          __typename?: "Resource"
          id: number
          name: string
          image: string
          background_overlay?: string | null
          background_image?: string | null
          image_raw?: string | null
          blurred_background?: string | null
        } | null
        parent?: {
          __typename?: "PathItem"
          id: number
          source_id: number
          order: number
          name: string
        } | null
        analytics?: {
          __typename?: "ItemAnalytics"
          need_feedback?: number | null
          total_users?: number | null
        } | null
        context?: {
          __typename?: "StepContext"
          resource_id?: number | null
          cohort_id?: number | null
        } | null
        blocks?: {
          __typename?: "BlocksContent"
          content_last_modified?: any | null
          last_published?: any | null
        } | null
        permissions?: {
          __typename?: "PathItemPermissions"
          can_complete?: boolean | null
          can_discuss?: boolean | null
          can_edit?: boolean | null
          can_edit_content?: boolean | null
          can_note?: boolean | null
          can_view?: boolean | null
          can_review?: boolean | null
          can_view_content?: boolean | null
        } | null
        completion?: {
          __typename?: "Completion"
          id?: number | null
          checked_date?: any | null
          completion_attempts?: number | null
          completion_comment?: string | null
          completion_date?: any | null
          completion_due?: any | null
          last_completion_attempt_date?: any | null
          has_failed_passing_attempt?: boolean | null
          has_points?: boolean | null
          is_late?: boolean | null
          is_graded?: boolean | null
          is_skipped?: boolean | null
          is_complete?: boolean | null
          is_auto_graded?: boolean | null
          is_forced_completion?: boolean | null
          needs_grading?: boolean | null
          userpoints_earned?: number | null
          userpoints_explanation?: string | null
          userpoints_value?: number | null
          minimum_passing_score?: number | null
          grading_type?: string | null
          requires_api_completion?: boolean | null
          overridden_by_api?: boolean | null
          overridden_by?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              rewardful_referral_id?: string | null
            } | null
          } | null
          checked_by?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              rewardful_referral_id?: string | null
            } | null
          } | null
          user?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              rewardful_referral_id?: string | null
            } | null
          } | null
          submission?: {
            __typename?: "UserSubmission"
            id?: number | null
            text?: string | null
            file_url?: string | null
            file_name?: string | null
            file_image?: string | null
          } | null
          feedback_discussion_context?: {
            __typename?: "CompletionFeedbackDiscussionContext"
            id?: number | null
            discussion_id?: number | null
            num_responses?: number | null
          } | null
        } | null
        linked_resource?: {
          __typename?: "Resource"
          id: number
          name: string
          slug: string
          created?: any | null
          resource_type?: number | null
          visibility?: number | null
          description?: string | null
          short_description?: string | null
          background_overlay?: string | null
          background_image?: string | null
          image_raw?: string | null
          has_certificate?: boolean | null
          discussion_enabled?: boolean | null
          lowest_public_offering_price?: number | null
          highest_public_offering_price?: number | null
          licensed_from_resource_id?: number | null
          licensed_from_school_id?: number | null
          created_by_id?: number | null
          image: string
          cover_image: string
          curriculum_group?: { __typename?: "Group"; id: number } | null
        } | null
        linked_cohort?: {
          __typename?: "Group"
          id: number
          user_registration?: {
            __typename?: "Registration"
            id: number
            role?: number | null
            completion_state?: number | null
            send_due_notifications?: boolean | null
            send_discussion_notifications?: boolean | null
            last_activity_time?: any | null
            expiration_dtime?: any | null
            created?: any | null
            path?: { __typename?: "Path"; id: number } | null
            user?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
          } | null
        } | null
        linked_registration?: {
          __typename?: "Registration"
          id: number
          role?: number | null
          completion_state?: number | null
          send_due_notifications?: boolean | null
          send_discussion_notifications?: boolean | null
          last_activity_time?: any | null
          expiration_dtime?: any | null
          created?: any | null
          path?: { __typename?: "Path"; id: number } | null
          user?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              rewardful_referral_id?: string | null
            } | null
          } | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type ResetCompletionFeedbackMutationVariables = Exact<{
  id: Scalars["Int"]["input"]
  pathId: Scalars["Int"]["input"]
}>

export type ResetCompletionFeedbackMutation = {
  __typename?: "Mutations"
  resetCompletionFeedback?: {
    __typename?: "Completion"
    id?: number | null
    checked_date?: any | null
    completion_attempts?: number | null
    completion_comment?: string | null
    completion_date?: any | null
    completion_due?: any | null
    last_completion_attempt_date?: any | null
    has_failed_passing_attempt?: boolean | null
    has_points?: boolean | null
    is_late?: boolean | null
    is_graded?: boolean | null
    is_skipped?: boolean | null
    is_complete?: boolean | null
    is_auto_graded?: boolean | null
    is_forced_completion?: boolean | null
    needs_grading?: boolean | null
    userpoints_earned?: number | null
    userpoints_explanation?: string | null
    userpoints_value?: number | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    requires_api_completion?: boolean | null
    overridden_by_api?: boolean | null
    overridden_by?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        rewardful_referral_id?: string | null
      } | null
    } | null
    checked_by?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        rewardful_referral_id?: string | null
      } | null
    } | null
    user?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        rewardful_referral_id?: string | null
      } | null
    } | null
    submission?: {
      __typename?: "UserSubmission"
      id?: number | null
      text?: string | null
      file_url?: string | null
      file_name?: string | null
      file_image?: string | null
    } | null
    feedback_discussion_context?: {
      __typename?: "CompletionFeedbackDiscussionContext"
      id?: number | null
      discussion_id?: number | null
      num_responses?: number | null
    } | null
  } | null
}

export type SaveCompletionFeedbackMutationVariables = Exact<{
  id: Scalars["Int"]["input"]
  pathId: Scalars["Int"]["input"]
  userpointsEarned?: InputMaybe<Scalars["Int"]["input"]>
  userpointsExplanation?: InputMaybe<Scalars["String"]["input"]>
  notify?: InputMaybe<Scalars["Boolean"]["input"]>
}>

export type SaveCompletionFeedbackMutation = {
  __typename?: "Mutations"
  saveCompletionFeedback?: {
    __typename?: "Completion"
    id?: number | null
    checked_date?: any | null
    completion_attempts?: number | null
    completion_comment?: string | null
    completion_date?: any | null
    completion_due?: any | null
    last_completion_attempt_date?: any | null
    has_failed_passing_attempt?: boolean | null
    has_points?: boolean | null
    is_late?: boolean | null
    is_graded?: boolean | null
    is_skipped?: boolean | null
    is_complete?: boolean | null
    is_auto_graded?: boolean | null
    is_forced_completion?: boolean | null
    needs_grading?: boolean | null
    userpoints_earned?: number | null
    userpoints_explanation?: string | null
    userpoints_value?: number | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    requires_api_completion?: boolean | null
    overridden_by_api?: boolean | null
    overridden_by?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        rewardful_referral_id?: string | null
      } | null
    } | null
    checked_by?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        rewardful_referral_id?: string | null
      } | null
    } | null
    user?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        rewardful_referral_id?: string | null
      } | null
    } | null
    submission?: {
      __typename?: "UserSubmission"
      id?: number | null
      text?: string | null
      file_url?: string | null
      file_name?: string | null
      file_image?: string | null
    } | null
    feedback_discussion_context?: {
      __typename?: "CompletionFeedbackDiscussionContext"
      id?: number | null
      discussion_id?: number | null
      num_responses?: number | null
    } | null
  } | null
}

export type DiscussionPostFragment = {
  __typename?: "Discussion"
  id?: number | null
  title?: string | null
  body?: string | null
  type?: number | null
  likes?: number | null
  total_responses?: number | null
  created_time?: any | null
  is_discussion_question?: boolean | null
  allow_replies?: boolean | null
  path_item_name?: string | null
  last_activity_time?: any | null
  user_last_viewed_time?: any | null
  user_last_responded_time?: any | null
  user_has_viewed_recent?: boolean | null
  user_is_subscribed?: boolean | null
  user_liked?: boolean | null
  context_id?: number | null
  resource_id?: number | null
  cohort_id?: number | null
  step_id?: number | null
  user?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      rewardful_referral_id?: string | null
    } | null
  } | null
  cohort?: {
    __typename?: "Group"
    id: number
    name: string
    created?: any | null
    description?: string | null
    price?: number | null
    seats?: number | null
    seats_filled?: number | null
    seats_available?: number | null
    registration_ends?: any | null
    registration_starts?: any | null
    registration_started?: boolean | null
    registration_ended?: boolean | null
    access_ends?: any | null
    access_starts?: any | null
    is_master?: boolean | null
    is_source_cohort?: boolean | null
    source_cohort_id?: number | null
    parent_section_id?: number | null
    visibility?: number | null
    is_collection_linked?: boolean | null
    is_subscription_only?: boolean | null
    status?: GroupStatus | null
    analytics_disabled?: boolean | null
    created_by_id?: number | null
  } | null
  resource?: {
    __typename?: "Resource"
    id: number
    name: string
    slug: string
    created?: any | null
    resource_type?: number | null
    visibility?: number | null
    description?: string | null
    short_description?: string | null
    background_overlay?: string | null
    background_image?: string | null
    image_raw?: string | null
    has_certificate?: boolean | null
    discussion_enabled?: boolean | null
    lowest_public_offering_price?: number | null
    highest_public_offering_price?: number | null
    licensed_from_resource_id?: number | null
    licensed_from_school_id?: number | null
    created_by_id?: number | null
    image: string
    cover_image: string
  } | null
  tagLinks?: {
    __typename?: "TagLinkConnection"
    edges?: Array<{
      __typename?: "TagLinkEdge"
      node?: {
        __typename?: "TagLink"
        id?: number | null
        tag_id?: number | null
        discussion_context_id?: number | null
        tag?: {
          __typename?: "Tag"
          id?: number | null
          name?: string | null
          slug?: string | null
          description?: string | null
          color?: string | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type DiscussionsQueryVariables = Exact<{
  context: DiscussionContextInput
  type?: InputMaybe<DiscussionTypeInput>
  is_discussion_question?: InputMaybe<Scalars["Boolean"]["input"]>
  total_responses?: InputMaybe<IntFilter>
  tagIdFilter?: InputMaybe<IntFilter>
  order_by?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >
  first?: InputMaybe<Scalars["Int"]["input"]>
  cursor?: InputMaybe<Scalars["String"]["input"]>
}>

export type DiscussionsQuery = {
  __typename?: "Query"
  discussions?: {
    __typename?: "DiscussionConnection"
    total: number
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges?: Array<{
      __typename?: "DiscussionEdge"
      node?: {
        __typename?: "Discussion"
        id?: number | null
        title?: string | null
        body?: string | null
        type?: number | null
        likes?: number | null
        total_responses?: number | null
        created_time?: any | null
        is_discussion_question?: boolean | null
        allow_replies?: boolean | null
        path_item_name?: string | null
        last_activity_time?: any | null
        user_last_viewed_time?: any | null
        user_last_responded_time?: any | null
        user_has_viewed_recent?: boolean | null
        user_is_subscribed?: boolean | null
        user_liked?: boolean | null
        context_id?: number | null
        resource_id?: number | null
        cohort_id?: number | null
        step_id?: number | null
        user?: {
          __typename?: "User"
          id?: number | null
          email?: string | null
          first_name?: string | null
          last_name?: string | null
          full_name?: string | null
          display_name?: string | null
          profile?: {
            __typename?: "Profile"
            id?: number | null
            bio?: string | null
            image?: string | null
            location?: string | null
            last_activity?: any | null
            rewardful_referral_id?: string | null
          } | null
        } | null
        cohort?: {
          __typename?: "Group"
          id: number
          name: string
          created?: any | null
          description?: string | null
          price?: number | null
          seats?: number | null
          seats_filled?: number | null
          seats_available?: number | null
          registration_ends?: any | null
          registration_starts?: any | null
          registration_started?: boolean | null
          registration_ended?: boolean | null
          access_ends?: any | null
          access_starts?: any | null
          is_master?: boolean | null
          is_source_cohort?: boolean | null
          source_cohort_id?: number | null
          parent_section_id?: number | null
          visibility?: number | null
          is_collection_linked?: boolean | null
          is_subscription_only?: boolean | null
          status?: GroupStatus | null
          analytics_disabled?: boolean | null
          created_by_id?: number | null
        } | null
        resource?: {
          __typename?: "Resource"
          id: number
          name: string
          slug: string
          created?: any | null
          resource_type?: number | null
          visibility?: number | null
          description?: string | null
          short_description?: string | null
          background_overlay?: string | null
          background_image?: string | null
          image_raw?: string | null
          has_certificate?: boolean | null
          discussion_enabled?: boolean | null
          lowest_public_offering_price?: number | null
          highest_public_offering_price?: number | null
          licensed_from_resource_id?: number | null
          licensed_from_school_id?: number | null
          created_by_id?: number | null
          image: string
          cover_image: string
        } | null
        tagLinks?: {
          __typename?: "TagLinkConnection"
          edges?: Array<{
            __typename?: "TagLinkEdge"
            node?: {
              __typename?: "TagLink"
              id?: number | null
              tag_id?: number | null
              discussion_context_id?: number | null
              tag?: {
                __typename?: "Tag"
                id?: number | null
                name?: string | null
                slug?: string | null
                description?: string | null
                color?: string | null
              } | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type DiscussionPostQueryVariables = Exact<{
  id?: InputMaybe<Scalars["Int"]["input"]>
  context: DiscussionContextInput
}>

export type DiscussionPostQuery = {
  __typename?: "Query"
  discussion?: {
    __typename?: "Discussion"
    id?: number | null
    title?: string | null
    body?: string | null
    type?: number | null
    likes?: number | null
    total_responses?: number | null
    created_time?: any | null
    is_discussion_question?: boolean | null
    allow_replies?: boolean | null
    path_item_name?: string | null
    last_activity_time?: any | null
    user_last_viewed_time?: any | null
    user_last_responded_time?: any | null
    user_has_viewed_recent?: boolean | null
    user_is_subscribed?: boolean | null
    user_liked?: boolean | null
    context_id?: number | null
    resource_id?: number | null
    cohort_id?: number | null
    step_id?: number | null
    user?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        rewardful_referral_id?: string | null
      } | null
    } | null
    cohort?: {
      __typename?: "Group"
      id: number
      name: string
      created?: any | null
      description?: string | null
      price?: number | null
      seats?: number | null
      seats_filled?: number | null
      seats_available?: number | null
      registration_ends?: any | null
      registration_starts?: any | null
      registration_started?: boolean | null
      registration_ended?: boolean | null
      access_ends?: any | null
      access_starts?: any | null
      is_master?: boolean | null
      is_source_cohort?: boolean | null
      source_cohort_id?: number | null
      parent_section_id?: number | null
      visibility?: number | null
      is_collection_linked?: boolean | null
      is_subscription_only?: boolean | null
      status?: GroupStatus | null
      analytics_disabled?: boolean | null
      created_by_id?: number | null
    } | null
    resource?: {
      __typename?: "Resource"
      id: number
      name: string
      slug: string
      created?: any | null
      resource_type?: number | null
      visibility?: number | null
      description?: string | null
      short_description?: string | null
      background_overlay?: string | null
      background_image?: string | null
      image_raw?: string | null
      has_certificate?: boolean | null
      discussion_enabled?: boolean | null
      lowest_public_offering_price?: number | null
      highest_public_offering_price?: number | null
      licensed_from_resource_id?: number | null
      licensed_from_school_id?: number | null
      created_by_id?: number | null
      image: string
      cover_image: string
    } | null
    tagLinks?: {
      __typename?: "TagLinkConnection"
      edges?: Array<{
        __typename?: "TagLinkEdge"
        node?: {
          __typename?: "TagLink"
          id?: number | null
          tag_id?: number | null
          discussion_context_id?: number | null
          tag?: {
            __typename?: "Tag"
            id?: number | null
            name?: string | null
            slug?: string | null
            description?: string | null
            color?: string | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type GroupContextFragment = {
  __typename?: "Group"
  id: number
  name: string
  created?: any | null
  description?: string | null
  price?: number | null
  seats?: number | null
  seats_filled?: number | null
  seats_available?: number | null
  registration_ends?: any | null
  registration_starts?: any | null
  registration_started?: boolean | null
  registration_ended?: boolean | null
  access_ends?: any | null
  access_starts?: any | null
  is_master?: boolean | null
  is_source_cohort?: boolean | null
  source_cohort_id?: number | null
  parent_section_id?: number | null
  visibility?: number | null
  is_collection_linked?: boolean | null
  is_subscription_only?: boolean | null
  status?: GroupStatus | null
  analytics_disabled?: boolean | null
  created_by_id?: number | null
  resource?: {
    __typename?: "Resource"
    id: number
    name: string
    slug: string
    created?: any | null
    resource_type?: number | null
    visibility?: number | null
    description?: string | null
    short_description?: string | null
    background_overlay?: string | null
    background_image?: string | null
    image_raw?: string | null
    has_certificate?: boolean | null
    discussion_enabled?: boolean | null
    lowest_public_offering_price?: number | null
    highest_public_offering_price?: number | null
    licensed_from_resource_id?: number | null
    licensed_from_school_id?: number | null
    created_by_id?: number | null
    image: string
    blurred_background?: string | null
    cover_image: string
  } | null
  user_registration?: {
    __typename?: "Registration"
    id: number
    role?: number | null
  } | null
  user_source_registration?: {
    __typename?: "Registration"
    id: number
    role?: number | null
  } | null
}

export type GroupFragment = {
  __typename?: "Group"
  id: number
  name: string
  created?: any | null
  description?: string | null
  price?: number | null
  seats?: number | null
  seats_filled?: number | null
  seats_available?: number | null
  registration_ends?: any | null
  registration_starts?: any | null
  registration_started?: boolean | null
  registration_ended?: boolean | null
  access_ends?: any | null
  access_starts?: any | null
  is_master?: boolean | null
  is_source_cohort?: boolean | null
  source_cohort_id?: number | null
  parent_section_id?: number | null
  visibility?: number | null
  is_collection_linked?: boolean | null
  is_subscription_only?: boolean | null
  status?: GroupStatus | null
  analytics_disabled?: boolean | null
  created_by_id?: number | null
}

export type GroupQueryVariables = Exact<{
  id: Scalars["Int"]["input"]
}>

export type GroupQuery = {
  __typename?: "Query"
  group?: {
    __typename?: "Group"
    id: number
    name: string
    created?: any | null
    description?: string | null
    price?: number | null
    seats?: number | null
    seats_filled?: number | null
    seats_available?: number | null
    registration_ends?: any | null
    registration_starts?: any | null
    registration_started?: boolean | null
    registration_ended?: boolean | null
    access_ends?: any | null
    access_starts?: any | null
    is_master?: boolean | null
    is_source_cohort?: boolean | null
    source_cohort_id?: number | null
    parent_section_id?: number | null
    visibility?: number | null
    is_collection_linked?: boolean | null
    is_subscription_only?: boolean | null
    status?: GroupStatus | null
    analytics_disabled?: boolean | null
    created_by_id?: number | null
    resource?: {
      __typename?: "Resource"
      id: number
      name: string
      slug: string
      created?: any | null
      resource_type?: number | null
      visibility?: number | null
      description?: string | null
      short_description?: string | null
      background_overlay?: string | null
      background_image?: string | null
      image_raw?: string | null
      has_certificate?: boolean | null
      discussion_enabled?: boolean | null
      lowest_public_offering_price?: number | null
      highest_public_offering_price?: number | null
      licensed_from_resource_id?: number | null
      licensed_from_school_id?: number | null
      created_by_id?: number | null
      image: string
      blurred_background?: string | null
      cover_image: string
    } | null
    user_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
    } | null
    user_source_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
    } | null
  } | null
}

export type UserRegistrationsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>
  cursor?: InputMaybe<Scalars["String"]["input"]>
}>

export type UserRegistrationsQuery = {
  __typename?: "Query"
  me?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    registrations?: {
      __typename?: "RegistrationConnection"
      total: number
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
      edges?: Array<{
        __typename?: "RegistrationEdge"
        node?: {
          __typename?: "Registration"
          id: number
          role?: number | null
          completion_state?: number | null
          send_due_notifications?: boolean | null
          send_discussion_notifications?: boolean | null
          last_activity_time?: any | null
          expiration_dtime?: any | null
          created?: any | null
          resource?: {
            __typename?: "Resource"
            id: number
            name: string
            slug: string
            created?: any | null
            resource_type?: number | null
            visibility?: number | null
            description?: string | null
            short_description?: string | null
            background_overlay?: string | null
            background_image?: string | null
            image_raw?: string | null
            has_certificate?: boolean | null
            discussion_enabled?: boolean | null
            lowest_public_offering_price?: number | null
            highest_public_offering_price?: number | null
            licensed_from_resource_id?: number | null
            licensed_from_school_id?: number | null
            created_by_id?: number | null
            image: string
            cover_image: string
          } | null
          group?: {
            __typename?: "Group"
            id: number
            name: string
            created?: any | null
            description?: string | null
            price?: number | null
            seats?: number | null
            seats_filled?: number | null
            seats_available?: number | null
            registration_ends?: any | null
            registration_starts?: any | null
            registration_started?: boolean | null
            registration_ended?: boolean | null
            access_ends?: any | null
            access_starts?: any | null
            is_master?: boolean | null
            is_source_cohort?: boolean | null
            source_cohort_id?: number | null
            parent_section_id?: number | null
            visibility?: number | null
            is_collection_linked?: boolean | null
            is_subscription_only?: boolean | null
            status?: GroupStatus | null
            analytics_disabled?: boolean | null
            created_by_id?: number | null
          } | null
          path?: {
            __typename?: "Path"
            id: number
            progress?: number | null
            completion_date?: any | null
          } | null
          school_sub_used?: {
            __typename?: "SchoolSubscription"
            id?: number | null
            subscription?: {
              __typename?: "Subscription"
              id?: number | null
              is_delinquent?: boolean | null
              canceled_dtime?: any | null
            } | null
          } | null
          group_sub_used?: {
            __typename?: "SchoolGroupSubscription"
            id?: number | null
          } | null
          group_sub_community_membership?: {
            __typename?: "MentorGroupMembership"
            id?: number | null
            access_revoked_time?: any | null
          } | null
          user?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              rewardful_referral_id?: string | null
            } | null
          } | null
        } | null
      } | null> | null
    } | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      rewardful_referral_id?: string | null
    } | null
  } | null
}

export type CreateResourceMutationVariables = Exact<{
  name: Scalars["String"]["input"]
  cover_image: Scalars["String"]["input"]
  resource_type?: InputMaybe<ResourceType>
}>

export type CreateResourceMutation = {
  __typename?: "Mutations"
  createResource?: {
    __typename?: "Resource"
    id: number
    name: string
    slug: string
    resource_type?: number | null
    background_overlay?: string | null
    blurred_background?: string | null
    curriculum?: { __typename?: "Group"; id: number } | null
  } | null
}

export type CreatedResourceFragment = {
  __typename?: "Resource"
  id: number
  name: string
  slug: string
  resource_type?: number | null
  background_overlay?: string | null
  blurred_background?: string | null
  curriculum?: { __typename?: "Group"; id: number } | null
}

export type RandomResourceImageQueryVariables = Exact<{
  themeType?: InputMaybe<ThemeType>
}>

export type RandomResourceImageQuery = {
  __typename?: "Query"
  resourceImage: Array<any | null>
}

export type UpdateLocalTranslationMutationVariables = Exact<{
  translation: Scalars["JSON"]["input"]
}>

export type UpdateLocalTranslationMutation = {
  __typename?: "Mutations"
  updateLocalTranslation?: any | null
}

export type TranslationQueryVariables = Exact<{
  language?: InputMaybe<Scalars["String"]["input"]>
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>
}>

export type TranslationQuery = {
  __typename?: "Query"
  translation?: {
    __typename?: "Translation"
    id?: number | null
    name?: string | null
    language?: string | null
    translation?: any | null
    master_translation?: any | null
    is_default?: boolean | null
  } | null
}

export type PathCreatorCohortFragment = {
  __typename?: "Group"
  id: number
  name: string
  registrations?: {
    __typename?: "RegistrationConnection"
    total: number
    edges?: Array<{
      __typename?: "RegistrationEdge"
      node?: {
        __typename?: "Registration"
        id: number
        user?: {
          __typename?: "User"
          id?: number | null
          full_name?: string | null
          profile?: {
            __typename?: "Profile"
            id?: number | null
            image?: string | null
          } | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type PathCreatorCreatePathFragment = {
  __typename?: "Resource"
  id: number
  name: string
  background_image?: string | null
  image: string
  cohort?: {
    __typename?: "Group"
    id: number
    name: string
    registrations?: {
      __typename?: "RegistrationConnection"
      total: number
      edges?: Array<{
        __typename?: "RegistrationEdge"
        node?: {
          __typename?: "Registration"
          id: number
          user?: {
            __typename?: "User"
            id?: number | null
            full_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              image?: string | null
            } | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
  source_cohort?: {
    __typename?: "Group"
    id: number
    registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
    } | null
  } | null
}

export type PathCreatorResourceFragment = {
  __typename?: "Resource"
  id: number
  name: string
  background_image?: string | null
  image: string
  source_cohort?: {
    __typename?: "Group"
    id: number
    registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
    } | null
  } | null
}

export type PathCreatorAssignRoleMutationVariables = Exact<{
  cohorts:
    | Array<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  user: Scalars["Int"]["input"]
  role: RegistrationRoleEnum
  sendNotification?: InputMaybe<Scalars["Boolean"]["input"]>
  message?: InputMaybe<Scalars["String"]["input"]>
}>

export type PathCreatorAssignRoleMutation = {
  __typename?: "Mutations"
  assign?: any | null
}

export type PathCreatorCohortsQueryVariables = Exact<{
  resourceId: Scalars["Int"]["input"]
  targetUserId: Scalars["Int"]["input"]
}>

export type PathCreatorCohortsQuery = {
  __typename?: "Query"
  cohorts?: {
    __typename?: "GroupConnection"
    edges?: Array<{
      __typename?: "GroupEdge"
      node?: {
        __typename?: "Group"
        id: number
        name: string
        user_registration?: {
          __typename?: "Registration"
          id: number
          role?: number | null
        } | null
        registrations?: {
          __typename?: "RegistrationConnection"
          total: number
          edges?: Array<{
            __typename?: "RegistrationEdge"
            node?: {
              __typename?: "Registration"
              id: number
              user?: {
                __typename?: "User"
                id?: number | null
                full_name?: string | null
                profile?: {
                  __typename?: "Profile"
                  id?: number | null
                  image?: string | null
                } | null
              } | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type PathCreatorCohortWithRegistrationFragment = {
  __typename?: "Group"
  id: number
  name: string
  user_registration?: {
    __typename?: "Registration"
    id: number
    role?: number | null
  } | null
  registrations?: {
    __typename?: "RegistrationConnection"
    total: number
    edges?: Array<{
      __typename?: "RegistrationEdge"
      node?: {
        __typename?: "Registration"
        id: number
        user?: {
          __typename?: "User"
          id?: number | null
          full_name?: string | null
          profile?: {
            __typename?: "Profile"
            id?: number | null
            image?: string | null
          } | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type PathCreatorCreateCohortMutationVariables = Exact<{
  resourceId: Scalars["Int"]["input"]
  name: Scalars["String"]["input"]
}>

export type PathCreatorCreateCohortMutation = {
  __typename?: "Mutations"
  createCohort?: {
    __typename?: "Group"
    id: number
    name: string
    registrations?: {
      __typename?: "RegistrationConnection"
      total: number
      edges?: Array<{
        __typename?: "RegistrationEdge"
        node?: {
          __typename?: "Registration"
          id: number
          user?: {
            __typename?: "User"
            id?: number | null
            full_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              image?: string | null
            } | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type PathCreatorCreatePathMutationVariables = Exact<{
  name: Scalars["String"]["input"]
  image: Scalars["String"]["input"]
  resourceType?: InputMaybe<ResourceType>
  cohortName?: InputMaybe<Scalars["String"]["input"]>
}>

export type PathCreatorCreatePathMutation = {
  __typename?: "Mutations"
  createResource?: {
    __typename?: "Resource"
    id: number
    name: string
    background_image?: string | null
    image: string
    cohort?: {
      __typename?: "Group"
      id: number
      name: string
      registrations?: {
        __typename?: "RegistrationConnection"
        total: number
        edges?: Array<{
          __typename?: "RegistrationEdge"
          node?: {
            __typename?: "Registration"
            id: number
            user?: {
              __typename?: "User"
              id?: number | null
              full_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                image?: string | null
              } | null
            } | null
          } | null
        } | null> | null
      } | null
    } | null
    source_cohort?: {
      __typename?: "Group"
      id: number
      registration?: {
        __typename?: "Registration"
        id: number
        role?: number | null
      } | null
    } | null
  } | null
}

export type PathCreatorRandomResourceImageQueryVariables = Exact<{
  themeType?: InputMaybe<ThemeType>
}>

export type PathCreatorRandomResourceImageQuery = {
  __typename?: "Query"
  resourceImage: Array<any | null>
}

export type PathCreatorResourcesQueryVariables = Exact<{ [key: string]: never }>

export type PathCreatorResourcesQuery = {
  __typename?: "Query"
  resources?: {
    __typename?: "ResourceConnection"
    edges?: Array<{
      __typename?: "ResourceEdge"
      node?: {
        __typename?: "Resource"
        id: number
        name: string
        background_image?: string | null
        image: string
        source_cohort?: {
          __typename?: "Group"
          id: number
          registration?: {
            __typename?: "Registration"
            id: number
            role?: number | null
          } | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type CohortSyncPathQueryVariables = Exact<{
  cohortId: Scalars["Int"]["input"]
}>

export type CohortSyncPathQuery = {
  __typename?: "Query"
  cohort?: {
    __typename?: "Group"
    id: number
    name: string
    is_master?: boolean | null
    visibility?: number | null
    user_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
    } | null
    path: { __typename?: "Path"; id: number }
    source?: {
      __typename?: "Group"
      id: number
      name: string
      is_master?: boolean | null
      user_registration?: {
        __typename?: "Registration"
        id: number
        role?: number | null
      } | null
      path: { __typename?: "Path"; id: number }
    } | null
    resource?: {
      __typename?: "Resource"
      id: number
      slug: string
      background_overlay?: string | null
      background_image?: string | null
      description?: string | null
      short_description?: string | null
      name: string
      licensing_enabled?: boolean | null
      resource_type?: number | null
      blurred_background?: string | null
      cohorts?: {
        __typename?: "GroupConnection"
        total: number
        edges?: Array<{
          __typename?: "GroupEdge"
          node?: {
            __typename?: "Group"
            id: number
            name: string
            is_master?: boolean | null
            path: { __typename?: "Path"; id: number }
          } | null
        } | null> | null
      } | null
      license_offerings?: {
        __typename?: "LicenseOfferingConnection"
        total: number
      } | null
    } | null
    registrations?: {
      __typename?: "RegistrationConnection"
      total: number
      edges?: Array<{
        __typename?: "RegistrationEdge"
        node?: {
          __typename?: "Registration"
          id: number
          role?: number | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type CohortSyncPathCohortFragment = {
  __typename?: "Group"
  id: number
  name: string
  is_master?: boolean | null
  visibility?: number | null
  user_registration?: {
    __typename?: "Registration"
    id: number
    role?: number | null
  } | null
  path: { __typename?: "Path"; id: number }
  source?: {
    __typename?: "Group"
    id: number
    name: string
    is_master?: boolean | null
    user_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
    } | null
    path: { __typename?: "Path"; id: number }
  } | null
  resource?: {
    __typename?: "Resource"
    id: number
    slug: string
    background_overlay?: string | null
    background_image?: string | null
    description?: string | null
    short_description?: string | null
    name: string
    licensing_enabled?: boolean | null
    resource_type?: number | null
    blurred_background?: string | null
    cohorts?: {
      __typename?: "GroupConnection"
      total: number
      edges?: Array<{
        __typename?: "GroupEdge"
        node?: {
          __typename?: "Group"
          id: number
          name: string
          is_master?: boolean | null
          path: { __typename?: "Path"; id: number }
        } | null
      } | null> | null
    } | null
    license_offerings?: {
      __typename?: "LicenseOfferingConnection"
      total: number
    } | null
  } | null
  registrations?: {
    __typename?: "RegistrationConnection"
    total: number
    edges?: Array<{
      __typename?: "RegistrationEdge"
      node?: {
        __typename?: "Registration"
        id: number
        role?: number | null
      } | null
    } | null> | null
  } | null
}

export type CompletePathItemMutationVariables = Exact<{
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
  skip?: InputMaybe<Scalars["Boolean"]["input"]>
  completion_data?: InputMaybe<Scalars["JSON"]["input"]>
}>

export type CompletePathItemMutation = {
  __typename?: "Mutations"
  completePathItem?: {
    __typename?: "PathItem"
    id: number
    order: number
    verb?: string | null
    name: string
    type: PathItemType
    path_id: number
    source_id: number
    parent_id?: number | null
    parent_source_id?: number | null
    description?: string | null
    curriculum_sync_opt_out_dtime?: any | null
    assignment_type?: PathItemAssignmentType | null
    content_id?: number | null
    content_type?: string | null
    item_content_type_id?: number | null
    is_required?: boolean | null
    is_previewable?: boolean | null
    due?: any | null
    passive_due?: boolean | null
    starts?: any | null
    passive_starts?: boolean | null
    has_started?: boolean | null
    points?: number | null
    item_needs_grading?: boolean | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    time_to_complete?: number | null
    lock_password?: string | null
    is_locked?: boolean | null
    lock_unlocked_time?: any | null
    allow_resets?: number | null
    show_grading_feedback?: number | null
    completion_comment_required_status?: string | null
    last_published_dtime?: any | null
    progress: number
    path?: {
      __typename?: "Path"
      id: number
      progress?: number | null
      next_step_id?: number | null
      completion_date?: any | null
      points?: {
        __typename?: "Points"
        path_id?: number | null
        total?: number | null
        earned?: number | null
        total_points_possible?: number | null
        percentage?: number | null
        has_points?: boolean | null
      } | null
    } | null
    context?: {
      __typename?: "StepContext"
      resource_id?: number | null
      cohort_id?: number | null
    } | null
    blocks?: {
      __typename?: "BlocksContent"
      content_last_modified?: any | null
      last_published?: any | null
    } | null
    permissions?: {
      __typename?: "PathItemPermissions"
      can_complete?: boolean | null
      can_discuss?: boolean | null
      can_edit?: boolean | null
      can_edit_content?: boolean | null
      can_note?: boolean | null
      can_view?: boolean | null
      can_review?: boolean | null
      can_view_content?: boolean | null
    } | null
    completion?: {
      __typename?: "Completion"
      id?: number | null
      checked_date?: any | null
      completion_attempts?: number | null
      completion_comment?: string | null
      completion_date?: any | null
      completion_due?: any | null
      last_completion_attempt_date?: any | null
      has_failed_passing_attempt?: boolean | null
      has_points?: boolean | null
      is_late?: boolean | null
      is_graded?: boolean | null
      is_skipped?: boolean | null
      is_complete?: boolean | null
      is_auto_graded?: boolean | null
      is_forced_completion?: boolean | null
      needs_grading?: boolean | null
      userpoints_earned?: number | null
      userpoints_explanation?: string | null
      userpoints_value?: number | null
      minimum_passing_score?: number | null
      grading_type?: string | null
      requires_api_completion?: boolean | null
      overridden_by_api?: boolean | null
      overridden_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      checked_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      submission?: {
        __typename?: "UserSubmission"
        id?: number | null
        text?: string | null
        file_url?: string | null
        file_name?: string | null
        file_image?: string | null
      } | null
      feedback_discussion_context?: {
        __typename?: "CompletionFeedbackDiscussionContext"
        id?: number | null
        discussion_id?: number | null
        num_responses?: number | null
      } | null
    } | null
    linked_resource?: {
      __typename?: "Resource"
      id: number
      name: string
      slug: string
      created?: any | null
      resource_type?: number | null
      visibility?: number | null
      description?: string | null
      short_description?: string | null
      background_overlay?: string | null
      background_image?: string | null
      image_raw?: string | null
      has_certificate?: boolean | null
      discussion_enabled?: boolean | null
      lowest_public_offering_price?: number | null
      highest_public_offering_price?: number | null
      licensed_from_resource_id?: number | null
      licensed_from_school_id?: number | null
      created_by_id?: number | null
      image: string
      cover_image: string
      curriculum_group?: { __typename?: "Group"; id: number } | null
    } | null
    linked_cohort?: {
      __typename?: "Group"
      id: number
      user_registration?: {
        __typename?: "Registration"
        id: number
        role?: number | null
        completion_state?: number | null
        send_due_notifications?: boolean | null
        send_discussion_notifications?: boolean | null
        last_activity_time?: any | null
        expiration_dtime?: any | null
        created?: any | null
        path?: { __typename?: "Path"; id: number } | null
        user?: {
          __typename?: "User"
          id?: number | null
          email?: string | null
          first_name?: string | null
          last_name?: string | null
          full_name?: string | null
          display_name?: string | null
          profile?: {
            __typename?: "Profile"
            id?: number | null
            bio?: string | null
            image?: string | null
            location?: string | null
            last_activity?: any | null
            rewardful_referral_id?: string | null
          } | null
        } | null
      } | null
    } | null
    linked_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
      completion_state?: number | null
      send_due_notifications?: boolean | null
      send_discussion_notifications?: boolean | null
      last_activity_time?: any | null
      expiration_dtime?: any | null
      created?: any | null
      path?: { __typename?: "Path"; id: number } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type CreatePathItemMutationVariables = Exact<{
  path_id: Scalars["Int"]["input"]
  order: Scalars["Int"]["input"]
  name: Scalars["String"]["input"]
  type: PathItemType
  assignment_type?: InputMaybe<PathItemAssignmentType>
  parent?: InputMaybe<Scalars["Int"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  content_type?: InputMaybe<Scalars["String"]["input"]>
  content_resource?: InputMaybe<Scalars["Int"]["input"]>
}>

export type CreatePathItemMutation = {
  __typename?: "Mutations"
  createPathItem?: {
    __typename?: "PathItem"
    id: number
    order: number
    verb?: string | null
    name: string
    type: PathItemType
    path_id: number
    source_id: number
    parent_id?: number | null
    parent_source_id?: number | null
    description?: string | null
    curriculum_sync_opt_out_dtime?: any | null
    assignment_type?: PathItemAssignmentType | null
    content_id?: number | null
    content_type?: string | null
    item_content_type_id?: number | null
    is_required?: boolean | null
    is_previewable?: boolean | null
    due?: any | null
    passive_due?: boolean | null
    starts?: any | null
    passive_starts?: boolean | null
    has_started?: boolean | null
    points?: number | null
    item_needs_grading?: boolean | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    time_to_complete?: number | null
    lock_password?: string | null
    is_locked?: boolean | null
    lock_unlocked_time?: any | null
    allow_resets?: number | null
    show_grading_feedback?: number | null
    completion_comment_required_status?: string | null
    last_published_dtime?: any | null
    progress: number
    items?: {
      __typename?: "PathItemConnection"
      edges?: Array<{
        __typename?: "PathItemEdge"
        node?: {
          __typename?: "PathItem"
          id: number
          order: number
          verb?: string | null
          name: string
          type: PathItemType
          path_id: number
          source_id: number
          parent_id?: number | null
          parent_source_id?: number | null
          description?: string | null
          curriculum_sync_opt_out_dtime?: any | null
          assignment_type?: PathItemAssignmentType | null
          content_id?: number | null
          content_type?: string | null
          item_content_type_id?: number | null
          is_required?: boolean | null
          is_previewable?: boolean | null
          due?: any | null
          passive_due?: boolean | null
          starts?: any | null
          passive_starts?: boolean | null
          has_started?: boolean | null
          points?: number | null
          item_needs_grading?: boolean | null
          minimum_passing_score?: number | null
          grading_type?: string | null
          time_to_complete?: number | null
          lock_password?: string | null
          is_locked?: boolean | null
          lock_unlocked_time?: any | null
          allow_resets?: number | null
          show_grading_feedback?: number | null
          completion_comment_required_status?: string | null
          last_published_dtime?: any | null
          progress: number
          context?: {
            __typename?: "StepContext"
            resource_id?: number | null
            cohort_id?: number | null
          } | null
          blocks?: {
            __typename?: "BlocksContent"
            content_last_modified?: any | null
            last_published?: any | null
          } | null
          permissions?: {
            __typename?: "PathItemPermissions"
            can_complete?: boolean | null
            can_discuss?: boolean | null
            can_edit?: boolean | null
            can_edit_content?: boolean | null
            can_note?: boolean | null
            can_view?: boolean | null
            can_review?: boolean | null
            can_view_content?: boolean | null
          } | null
          completion?: {
            __typename?: "Completion"
            id?: number | null
            checked_date?: any | null
            completion_attempts?: number | null
            completion_comment?: string | null
            completion_date?: any | null
            completion_due?: any | null
            last_completion_attempt_date?: any | null
            has_failed_passing_attempt?: boolean | null
            has_points?: boolean | null
            is_late?: boolean | null
            is_graded?: boolean | null
            is_skipped?: boolean | null
            is_complete?: boolean | null
            is_auto_graded?: boolean | null
            is_forced_completion?: boolean | null
            needs_grading?: boolean | null
            userpoints_earned?: number | null
            userpoints_explanation?: string | null
            userpoints_value?: number | null
            minimum_passing_score?: number | null
            grading_type?: string | null
            requires_api_completion?: boolean | null
            overridden_by_api?: boolean | null
            overridden_by?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
            checked_by?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
            user?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
            submission?: {
              __typename?: "UserSubmission"
              id?: number | null
              text?: string | null
              file_url?: string | null
              file_name?: string | null
              file_image?: string | null
            } | null
            feedback_discussion_context?: {
              __typename?: "CompletionFeedbackDiscussionContext"
              id?: number | null
              discussion_id?: number | null
              num_responses?: number | null
            } | null
          } | null
          linked_resource?: {
            __typename?: "Resource"
            id: number
            name: string
            slug: string
            created?: any | null
            resource_type?: number | null
            visibility?: number | null
            description?: string | null
            short_description?: string | null
            background_overlay?: string | null
            background_image?: string | null
            image_raw?: string | null
            has_certificate?: boolean | null
            discussion_enabled?: boolean | null
            lowest_public_offering_price?: number | null
            highest_public_offering_price?: number | null
            licensed_from_resource_id?: number | null
            licensed_from_school_id?: number | null
            created_by_id?: number | null
            image: string
            cover_image: string
            curriculum_group?: { __typename?: "Group"; id: number } | null
          } | null
          linked_cohort?: {
            __typename?: "Group"
            id: number
            user_registration?: {
              __typename?: "Registration"
              id: number
              role?: number | null
              completion_state?: number | null
              send_due_notifications?: boolean | null
              send_discussion_notifications?: boolean | null
              last_activity_time?: any | null
              expiration_dtime?: any | null
              created?: any | null
              path?: { __typename?: "Path"; id: number } | null
              user?: {
                __typename?: "User"
                id?: number | null
                email?: string | null
                first_name?: string | null
                last_name?: string | null
                full_name?: string | null
                display_name?: string | null
                profile?: {
                  __typename?: "Profile"
                  id?: number | null
                  bio?: string | null
                  image?: string | null
                  location?: string | null
                  last_activity?: any | null
                  rewardful_referral_id?: string | null
                } | null
              } | null
            } | null
          } | null
          linked_registration?: {
            __typename?: "Registration"
            id: number
            role?: number | null
            completion_state?: number | null
            send_due_notifications?: boolean | null
            send_discussion_notifications?: boolean | null
            last_activity_time?: any | null
            expiration_dtime?: any | null
            created?: any | null
            path?: { __typename?: "Path"; id: number } | null
            user?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
          } | null
        } | null
      } | null> | null
    } | null
    context?: {
      __typename?: "StepContext"
      resource_id?: number | null
      cohort_id?: number | null
    } | null
    blocks?: {
      __typename?: "BlocksContent"
      content_last_modified?: any | null
      last_published?: any | null
    } | null
    permissions?: {
      __typename?: "PathItemPermissions"
      can_complete?: boolean | null
      can_discuss?: boolean | null
      can_edit?: boolean | null
      can_edit_content?: boolean | null
      can_note?: boolean | null
      can_view?: boolean | null
      can_review?: boolean | null
      can_view_content?: boolean | null
    } | null
    completion?: {
      __typename?: "Completion"
      id?: number | null
      checked_date?: any | null
      completion_attempts?: number | null
      completion_comment?: string | null
      completion_date?: any | null
      completion_due?: any | null
      last_completion_attempt_date?: any | null
      has_failed_passing_attempt?: boolean | null
      has_points?: boolean | null
      is_late?: boolean | null
      is_graded?: boolean | null
      is_skipped?: boolean | null
      is_complete?: boolean | null
      is_auto_graded?: boolean | null
      is_forced_completion?: boolean | null
      needs_grading?: boolean | null
      userpoints_earned?: number | null
      userpoints_explanation?: string | null
      userpoints_value?: number | null
      minimum_passing_score?: number | null
      grading_type?: string | null
      requires_api_completion?: boolean | null
      overridden_by_api?: boolean | null
      overridden_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      checked_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      submission?: {
        __typename?: "UserSubmission"
        id?: number | null
        text?: string | null
        file_url?: string | null
        file_name?: string | null
        file_image?: string | null
      } | null
      feedback_discussion_context?: {
        __typename?: "CompletionFeedbackDiscussionContext"
        id?: number | null
        discussion_id?: number | null
        num_responses?: number | null
      } | null
    } | null
    linked_resource?: {
      __typename?: "Resource"
      id: number
      name: string
      slug: string
      created?: any | null
      resource_type?: number | null
      visibility?: number | null
      description?: string | null
      short_description?: string | null
      background_overlay?: string | null
      background_image?: string | null
      image_raw?: string | null
      has_certificate?: boolean | null
      discussion_enabled?: boolean | null
      lowest_public_offering_price?: number | null
      highest_public_offering_price?: number | null
      licensed_from_resource_id?: number | null
      licensed_from_school_id?: number | null
      created_by_id?: number | null
      image: string
      cover_image: string
      curriculum_group?: { __typename?: "Group"; id: number } | null
    } | null
    linked_cohort?: {
      __typename?: "Group"
      id: number
      user_registration?: {
        __typename?: "Registration"
        id: number
        role?: number | null
        completion_state?: number | null
        send_due_notifications?: boolean | null
        send_discussion_notifications?: boolean | null
        last_activity_time?: any | null
        expiration_dtime?: any | null
        created?: any | null
        path?: { __typename?: "Path"; id: number } | null
        user?: {
          __typename?: "User"
          id?: number | null
          email?: string | null
          first_name?: string | null
          last_name?: string | null
          full_name?: string | null
          display_name?: string | null
          profile?: {
            __typename?: "Profile"
            id?: number | null
            bio?: string | null
            image?: string | null
            location?: string | null
            last_activity?: any | null
            rewardful_referral_id?: string | null
          } | null
        } | null
      } | null
    } | null
    linked_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
      completion_state?: number | null
      send_due_notifications?: boolean | null
      send_discussion_notifications?: boolean | null
      last_activity_time?: any | null
      expiration_dtime?: any | null
      created?: any | null
      path?: { __typename?: "Path"; id: number } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type CreateRegistrationUpsertGroupMutationVariables = Exact<{
  resource_id: Scalars["Int"]["input"]
  parent_offering_id: Scalars["Int"]["input"]
  step_source_id: Scalars["Int"]["input"]
  createrRegistration?: InputMaybe<Scalars["Boolean"]["input"]>
}>

export type CreateRegistrationUpsertGroupMutation = {
  __typename?: "Mutations"
  createRegistrationUpsertGroup?: {
    __typename?: "Group"
    id: number
    name: string
    created?: any | null
    description?: string | null
    price?: number | null
    seats?: number | null
    seats_filled?: number | null
    seats_available?: number | null
    registration_ends?: any | null
    registration_starts?: any | null
    registration_started?: boolean | null
    registration_ended?: boolean | null
    access_ends?: any | null
    access_starts?: any | null
    is_master?: boolean | null
    is_source_cohort?: boolean | null
    source_cohort_id?: number | null
    parent_section_id?: number | null
    visibility?: number | null
    is_collection_linked?: boolean | null
    is_subscription_only?: boolean | null
    status?: GroupStatus | null
    analytics_disabled?: boolean | null
    created_by_id?: number | null
    user_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
      completion_state?: number | null
      send_due_notifications?: boolean | null
      send_discussion_notifications?: boolean | null
      last_activity_time?: any | null
      expiration_dtime?: any | null
      created?: any | null
      path?: { __typename?: "Path"; id: number } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type DeletePathItemMutationVariables = Exact<{
  path_id: Scalars["Int"]["input"]
  id: Scalars["Int"]["input"]
}>

export type DeletePathItemMutation = {
  __typename?: "Mutations"
  deletePathItem?: { __typename?: "PathItem"; id: number } | null
}

export type ExecuteSyncPlanMutationVariables = Exact<{
  sync_plan: PathSyncPlanInput
}>

export type ExecuteSyncPlanMutation = {
  __typename?: "Mutations"
  executePathSyncPlan?: boolean | null
}

export type PastePathItemMutationVariables = Exact<{
  pathId: Scalars["Int"]["input"]
  itemId: Scalars["Int"]["input"]
  parentId?: InputMaybe<Scalars["Int"]["input"]>
  order: Scalars["Int"]["input"]
}>

export type PastePathItemMutation = {
  __typename?: "Mutations"
  pastePathItem?: {
    __typename?: "PathItem"
    id: number
    order: number
    verb?: string | null
    name: string
    type: PathItemType
    path_id: number
    source_id: number
    parent_id?: number | null
    parent_source_id?: number | null
    description?: string | null
    curriculum_sync_opt_out_dtime?: any | null
    assignment_type?: PathItemAssignmentType | null
    content_id?: number | null
    content_type?: string | null
    item_content_type_id?: number | null
    is_required?: boolean | null
    is_previewable?: boolean | null
    due?: any | null
    passive_due?: boolean | null
    starts?: any | null
    passive_starts?: boolean | null
    has_started?: boolean | null
    points?: number | null
    item_needs_grading?: boolean | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    time_to_complete?: number | null
    lock_password?: string | null
    is_locked?: boolean | null
    lock_unlocked_time?: any | null
    allow_resets?: number | null
    show_grading_feedback?: number | null
    completion_comment_required_status?: string | null
    last_published_dtime?: any | null
    progress: number
    items?: {
      __typename?: "PathItemConnection"
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
      edges?: Array<{
        __typename?: "PathItemEdge"
        node?: {
          __typename?: "PathItem"
          id: number
          order: number
          verb?: string | null
          name: string
          type: PathItemType
          path_id: number
          source_id: number
          parent_id?: number | null
          parent_source_id?: number | null
          description?: string | null
          curriculum_sync_opt_out_dtime?: any | null
          assignment_type?: PathItemAssignmentType | null
          content_id?: number | null
          content_type?: string | null
          item_content_type_id?: number | null
          is_required?: boolean | null
          is_previewable?: boolean | null
          due?: any | null
          passive_due?: boolean | null
          starts?: any | null
          passive_starts?: boolean | null
          has_started?: boolean | null
          points?: number | null
          item_needs_grading?: boolean | null
          minimum_passing_score?: number | null
          grading_type?: string | null
          time_to_complete?: number | null
          lock_password?: string | null
          is_locked?: boolean | null
          lock_unlocked_time?: any | null
          allow_resets?: number | null
          show_grading_feedback?: number | null
          completion_comment_required_status?: string | null
          last_published_dtime?: any | null
          progress: number
          context?: {
            __typename?: "StepContext"
            resource_id?: number | null
            cohort_id?: number | null
          } | null
          blocks?: {
            __typename?: "BlocksContent"
            content_last_modified?: any | null
            last_published?: any | null
          } | null
          permissions?: {
            __typename?: "PathItemPermissions"
            can_complete?: boolean | null
            can_discuss?: boolean | null
            can_edit?: boolean | null
            can_edit_content?: boolean | null
            can_note?: boolean | null
            can_view?: boolean | null
            can_review?: boolean | null
            can_view_content?: boolean | null
          } | null
          completion?: {
            __typename?: "Completion"
            id?: number | null
            checked_date?: any | null
            completion_attempts?: number | null
            completion_comment?: string | null
            completion_date?: any | null
            completion_due?: any | null
            last_completion_attempt_date?: any | null
            has_failed_passing_attempt?: boolean | null
            has_points?: boolean | null
            is_late?: boolean | null
            is_graded?: boolean | null
            is_skipped?: boolean | null
            is_complete?: boolean | null
            is_auto_graded?: boolean | null
            is_forced_completion?: boolean | null
            needs_grading?: boolean | null
            userpoints_earned?: number | null
            userpoints_explanation?: string | null
            userpoints_value?: number | null
            minimum_passing_score?: number | null
            grading_type?: string | null
            requires_api_completion?: boolean | null
            overridden_by_api?: boolean | null
            overridden_by?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
            checked_by?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
            user?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
            submission?: {
              __typename?: "UserSubmission"
              id?: number | null
              text?: string | null
              file_url?: string | null
              file_name?: string | null
              file_image?: string | null
            } | null
            feedback_discussion_context?: {
              __typename?: "CompletionFeedbackDiscussionContext"
              id?: number | null
              discussion_id?: number | null
              num_responses?: number | null
            } | null
          } | null
          linked_resource?: {
            __typename?: "Resource"
            id: number
            name: string
            slug: string
            created?: any | null
            resource_type?: number | null
            visibility?: number | null
            description?: string | null
            short_description?: string | null
            background_overlay?: string | null
            background_image?: string | null
            image_raw?: string | null
            has_certificate?: boolean | null
            discussion_enabled?: boolean | null
            lowest_public_offering_price?: number | null
            highest_public_offering_price?: number | null
            licensed_from_resource_id?: number | null
            licensed_from_school_id?: number | null
            created_by_id?: number | null
            image: string
            cover_image: string
            curriculum_group?: { __typename?: "Group"; id: number } | null
          } | null
          linked_cohort?: {
            __typename?: "Group"
            id: number
            user_registration?: {
              __typename?: "Registration"
              id: number
              role?: number | null
              completion_state?: number | null
              send_due_notifications?: boolean | null
              send_discussion_notifications?: boolean | null
              last_activity_time?: any | null
              expiration_dtime?: any | null
              created?: any | null
              path?: { __typename?: "Path"; id: number } | null
              user?: {
                __typename?: "User"
                id?: number | null
                email?: string | null
                first_name?: string | null
                last_name?: string | null
                full_name?: string | null
                display_name?: string | null
                profile?: {
                  __typename?: "Profile"
                  id?: number | null
                  bio?: string | null
                  image?: string | null
                  location?: string | null
                  last_activity?: any | null
                  rewardful_referral_id?: string | null
                } | null
              } | null
            } | null
          } | null
          linked_registration?: {
            __typename?: "Registration"
            id: number
            role?: number | null
            completion_state?: number | null
            send_due_notifications?: boolean | null
            send_discussion_notifications?: boolean | null
            last_activity_time?: any | null
            expiration_dtime?: any | null
            created?: any | null
            path?: { __typename?: "Path"; id: number } | null
            user?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
          } | null
        } | null
      } | null> | null
    } | null
    context?: {
      __typename?: "StepContext"
      resource_id?: number | null
      cohort_id?: number | null
    } | null
    blocks?: {
      __typename?: "BlocksContent"
      content_last_modified?: any | null
      last_published?: any | null
    } | null
    permissions?: {
      __typename?: "PathItemPermissions"
      can_complete?: boolean | null
      can_discuss?: boolean | null
      can_edit?: boolean | null
      can_edit_content?: boolean | null
      can_note?: boolean | null
      can_view?: boolean | null
      can_review?: boolean | null
      can_view_content?: boolean | null
    } | null
    completion?: {
      __typename?: "Completion"
      id?: number | null
      checked_date?: any | null
      completion_attempts?: number | null
      completion_comment?: string | null
      completion_date?: any | null
      completion_due?: any | null
      last_completion_attempt_date?: any | null
      has_failed_passing_attempt?: boolean | null
      has_points?: boolean | null
      is_late?: boolean | null
      is_graded?: boolean | null
      is_skipped?: boolean | null
      is_complete?: boolean | null
      is_auto_graded?: boolean | null
      is_forced_completion?: boolean | null
      needs_grading?: boolean | null
      userpoints_earned?: number | null
      userpoints_explanation?: string | null
      userpoints_value?: number | null
      minimum_passing_score?: number | null
      grading_type?: string | null
      requires_api_completion?: boolean | null
      overridden_by_api?: boolean | null
      overridden_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      checked_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      submission?: {
        __typename?: "UserSubmission"
        id?: number | null
        text?: string | null
        file_url?: string | null
        file_name?: string | null
        file_image?: string | null
      } | null
      feedback_discussion_context?: {
        __typename?: "CompletionFeedbackDiscussionContext"
        id?: number | null
        discussion_id?: number | null
        num_responses?: number | null
      } | null
    } | null
    linked_resource?: {
      __typename?: "Resource"
      id: number
      name: string
      slug: string
      created?: any | null
      resource_type?: number | null
      visibility?: number | null
      description?: string | null
      short_description?: string | null
      background_overlay?: string | null
      background_image?: string | null
      image_raw?: string | null
      has_certificate?: boolean | null
      discussion_enabled?: boolean | null
      lowest_public_offering_price?: number | null
      highest_public_offering_price?: number | null
      licensed_from_resource_id?: number | null
      licensed_from_school_id?: number | null
      created_by_id?: number | null
      image: string
      cover_image: string
      curriculum_group?: { __typename?: "Group"; id: number } | null
    } | null
    linked_cohort?: {
      __typename?: "Group"
      id: number
      user_registration?: {
        __typename?: "Registration"
        id: number
        role?: number | null
        completion_state?: number | null
        send_due_notifications?: boolean | null
        send_discussion_notifications?: boolean | null
        last_activity_time?: any | null
        expiration_dtime?: any | null
        created?: any | null
        path?: { __typename?: "Path"; id: number } | null
        user?: {
          __typename?: "User"
          id?: number | null
          email?: string | null
          first_name?: string | null
          last_name?: string | null
          full_name?: string | null
          display_name?: string | null
          profile?: {
            __typename?: "Profile"
            id?: number | null
            bio?: string | null
            image?: string | null
            location?: string | null
            last_activity?: any | null
            rewardful_referral_id?: string | null
          } | null
        } | null
      } | null
    } | null
    linked_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
      completion_state?: number | null
      send_due_notifications?: boolean | null
      send_discussion_notifications?: boolean | null
      last_activity_time?: any | null
      expiration_dtime?: any | null
      created?: any | null
      path?: { __typename?: "Path"; id: number } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type PathFragment = {
  __typename?: "Path"
  id: number
  progress?: number | null
  next_step_id?: number | null
  completion_date?: any | null
  created_date?: any | null
  points?: {
    __typename?: "Points"
    path_id?: number | null
    total?: number | null
    earned?: number | null
    total_points_possible?: number | null
    percentage?: number | null
    has_points?: boolean | null
  } | null
  user?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      rewardful_referral_id?: string | null
    } | null
  } | null
  sync?: {
    __typename?: "PathSync"
    path_id: number
    synced: boolean
    last_synced_date?: any | null
    sync_source_path_id?: number | null
    user_id?: number | null
  } | null
  publish?: {
    __typename?: "PathPublish"
    path_id: number
    published: boolean
    last_published_date?: any | null
  } | null
}

export type PathSyncFragment = {
  __typename?: "PathSync"
  path_id: number
  synced: boolean
  last_synced_date?: any | null
  sync_source_path_id?: number | null
  user_id?: number | null
}

export type PathPublishFragment = {
  __typename?: "PathPublish"
  path_id: number
  published: boolean
  last_published_date?: any | null
}

export type PathSyncPublishFragment = {
  __typename?: "Path"
  id: number
  sync?: {
    __typename?: "PathSync"
    path_id: number
    synced: boolean
    last_synced_date?: any | null
    sync_source_path_id?: number | null
    user_id?: number | null
  } | null
  publish?: {
    __typename?: "PathPublish"
    path_id: number
    published: boolean
    last_published_date?: any | null
  } | null
}

export type PathItemFragment = {
  __typename?: "PathItem"
  id: number
  order: number
  verb?: string | null
  name: string
  type: PathItemType
  path_id: number
  source_id: number
  parent_id?: number | null
  parent_source_id?: number | null
  description?: string | null
  curriculum_sync_opt_out_dtime?: any | null
  assignment_type?: PathItemAssignmentType | null
  content_id?: number | null
  content_type?: string | null
  item_content_type_id?: number | null
  is_required?: boolean | null
  is_previewable?: boolean | null
  due?: any | null
  passive_due?: boolean | null
  starts?: any | null
  passive_starts?: boolean | null
  has_started?: boolean | null
  points?: number | null
  item_needs_grading?: boolean | null
  minimum_passing_score?: number | null
  grading_type?: string | null
  time_to_complete?: number | null
  lock_password?: string | null
  is_locked?: boolean | null
  lock_unlocked_time?: any | null
  allow_resets?: number | null
  show_grading_feedback?: number | null
  completion_comment_required_status?: string | null
  last_published_dtime?: any | null
  progress: number
  context?: {
    __typename?: "StepContext"
    resource_id?: number | null
    cohort_id?: number | null
  } | null
  blocks?: {
    __typename?: "BlocksContent"
    content_last_modified?: any | null
    last_published?: any | null
  } | null
  permissions?: {
    __typename?: "PathItemPermissions"
    can_complete?: boolean | null
    can_discuss?: boolean | null
    can_edit?: boolean | null
    can_edit_content?: boolean | null
    can_note?: boolean | null
    can_view?: boolean | null
    can_review?: boolean | null
    can_view_content?: boolean | null
  } | null
  completion?: {
    __typename?: "Completion"
    id?: number | null
    checked_date?: any | null
    completion_attempts?: number | null
    completion_comment?: string | null
    completion_date?: any | null
    completion_due?: any | null
    last_completion_attempt_date?: any | null
    has_failed_passing_attempt?: boolean | null
    has_points?: boolean | null
    is_late?: boolean | null
    is_graded?: boolean | null
    is_skipped?: boolean | null
    is_complete?: boolean | null
    is_auto_graded?: boolean | null
    is_forced_completion?: boolean | null
    needs_grading?: boolean | null
    userpoints_earned?: number | null
    userpoints_explanation?: string | null
    userpoints_value?: number | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    requires_api_completion?: boolean | null
    overridden_by_api?: boolean | null
    overridden_by?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        rewardful_referral_id?: string | null
      } | null
    } | null
    checked_by?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        rewardful_referral_id?: string | null
      } | null
    } | null
    user?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        rewardful_referral_id?: string | null
      } | null
    } | null
    submission?: {
      __typename?: "UserSubmission"
      id?: number | null
      text?: string | null
      file_url?: string | null
      file_name?: string | null
      file_image?: string | null
    } | null
    feedback_discussion_context?: {
      __typename?: "CompletionFeedbackDiscussionContext"
      id?: number | null
      discussion_id?: number | null
      num_responses?: number | null
    } | null
  } | null
  linked_resource?: {
    __typename?: "Resource"
    id: number
    name: string
    slug: string
    created?: any | null
    resource_type?: number | null
    visibility?: number | null
    description?: string | null
    short_description?: string | null
    background_overlay?: string | null
    background_image?: string | null
    image_raw?: string | null
    has_certificate?: boolean | null
    discussion_enabled?: boolean | null
    lowest_public_offering_price?: number | null
    highest_public_offering_price?: number | null
    licensed_from_resource_id?: number | null
    licensed_from_school_id?: number | null
    created_by_id?: number | null
    image: string
    cover_image: string
    curriculum_group?: { __typename?: "Group"; id: number } | null
  } | null
  linked_cohort?: {
    __typename?: "Group"
    id: number
    user_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
      completion_state?: number | null
      send_due_notifications?: boolean | null
      send_discussion_notifications?: boolean | null
      last_activity_time?: any | null
      expiration_dtime?: any | null
      created?: any | null
      path?: { __typename?: "Path"; id: number } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
    } | null
  } | null
  linked_registration?: {
    __typename?: "Registration"
    id: number
    role?: number | null
    completion_state?: number | null
    send_due_notifications?: boolean | null
    send_discussion_notifications?: boolean | null
    last_activity_time?: any | null
    expiration_dtime?: any | null
    created?: any | null
    path?: { __typename?: "Path"; id: number } | null
    user?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        rewardful_referral_id?: string | null
      } | null
    } | null
  } | null
}

export type PathItemQueryVariables = Exact<{
  id?: InputMaybe<Scalars["Int"]["input"]>
  path_id?: InputMaybe<Scalars["Int"]["input"]>
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
  source_id?: InputMaybe<Scalars["Int"]["input"]>
}>

export type PathItemQuery = {
  __typename?: "Query"
  pathItem?: {
    __typename?: "PathItem"
    id: number
    order: number
    verb?: string | null
    name: string
    type: PathItemType
    path_id: number
    source_id: number
    parent_id?: number | null
    parent_source_id?: number | null
    description?: string | null
    curriculum_sync_opt_out_dtime?: any | null
    assignment_type?: PathItemAssignmentType | null
    content_id?: number | null
    content_type?: string | null
    item_content_type_id?: number | null
    is_required?: boolean | null
    is_previewable?: boolean | null
    due?: any | null
    passive_due?: boolean | null
    starts?: any | null
    passive_starts?: boolean | null
    has_started?: boolean | null
    points?: number | null
    item_needs_grading?: boolean | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    time_to_complete?: number | null
    lock_password?: string | null
    is_locked?: boolean | null
    lock_unlocked_time?: any | null
    allow_resets?: number | null
    show_grading_feedback?: number | null
    completion_comment_required_status?: string | null
    last_published_dtime?: any | null
    progress: number
    context?: {
      __typename?: "StepContext"
      resource_id?: number | null
      cohort_id?: number | null
    } | null
    blocks?: {
      __typename?: "BlocksContent"
      content_last_modified?: any | null
      last_published?: any | null
    } | null
    permissions?: {
      __typename?: "PathItemPermissions"
      can_complete?: boolean | null
      can_discuss?: boolean | null
      can_edit?: boolean | null
      can_edit_content?: boolean | null
      can_note?: boolean | null
      can_view?: boolean | null
      can_review?: boolean | null
      can_view_content?: boolean | null
    } | null
    completion?: {
      __typename?: "Completion"
      id?: number | null
      checked_date?: any | null
      completion_attempts?: number | null
      completion_comment?: string | null
      completion_date?: any | null
      completion_due?: any | null
      last_completion_attempt_date?: any | null
      has_failed_passing_attempt?: boolean | null
      has_points?: boolean | null
      is_late?: boolean | null
      is_graded?: boolean | null
      is_skipped?: boolean | null
      is_complete?: boolean | null
      is_auto_graded?: boolean | null
      is_forced_completion?: boolean | null
      needs_grading?: boolean | null
      userpoints_earned?: number | null
      userpoints_explanation?: string | null
      userpoints_value?: number | null
      minimum_passing_score?: number | null
      grading_type?: string | null
      requires_api_completion?: boolean | null
      overridden_by_api?: boolean | null
      overridden_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      checked_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      submission?: {
        __typename?: "UserSubmission"
        id?: number | null
        text?: string | null
        file_url?: string | null
        file_name?: string | null
        file_image?: string | null
      } | null
      feedback_discussion_context?: {
        __typename?: "CompletionFeedbackDiscussionContext"
        id?: number | null
        discussion_id?: number | null
        num_responses?: number | null
      } | null
    } | null
    linked_resource?: {
      __typename?: "Resource"
      id: number
      name: string
      slug: string
      created?: any | null
      resource_type?: number | null
      visibility?: number | null
      description?: string | null
      short_description?: string | null
      background_overlay?: string | null
      background_image?: string | null
      image_raw?: string | null
      has_certificate?: boolean | null
      discussion_enabled?: boolean | null
      lowest_public_offering_price?: number | null
      highest_public_offering_price?: number | null
      licensed_from_resource_id?: number | null
      licensed_from_school_id?: number | null
      created_by_id?: number | null
      image: string
      cover_image: string
      curriculum_group?: { __typename?: "Group"; id: number } | null
    } | null
    linked_cohort?: {
      __typename?: "Group"
      id: number
      user_registration?: {
        __typename?: "Registration"
        id: number
        role?: number | null
        completion_state?: number | null
        send_due_notifications?: boolean | null
        send_discussion_notifications?: boolean | null
        last_activity_time?: any | null
        expiration_dtime?: any | null
        created?: any | null
        path?: { __typename?: "Path"; id: number } | null
        user?: {
          __typename?: "User"
          id?: number | null
          email?: string | null
          first_name?: string | null
          last_name?: string | null
          full_name?: string | null
          display_name?: string | null
          profile?: {
            __typename?: "Profile"
            id?: number | null
            bio?: string | null
            image?: string | null
            location?: string | null
            last_activity?: any | null
            rewardful_referral_id?: string | null
          } | null
        } | null
      } | null
    } | null
    linked_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
      completion_state?: number | null
      send_due_notifications?: boolean | null
      send_discussion_notifications?: boolean | null
      last_activity_time?: any | null
      expiration_dtime?: any | null
      created?: any | null
      path?: { __typename?: "Path"; id: number } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type PathItemsFragment = {
  __typename?: "Path"
  id: number
  progress?: number | null
  next_step_id?: number | null
  completion_date?: any | null
  created_date?: any | null
  items?: {
    __typename?: "PathItemConnection"
    edges?: Array<{
      __typename?: "PathItemEdge"
      node?: {
        __typename?: "PathItem"
        id: number
        order: number
        verb?: string | null
        name: string
        type: PathItemType
        path_id: number
        source_id: number
        parent_id?: number | null
        parent_source_id?: number | null
        description?: string | null
        curriculum_sync_opt_out_dtime?: any | null
        assignment_type?: PathItemAssignmentType | null
        content_id?: number | null
        content_type?: string | null
        item_content_type_id?: number | null
        is_required?: boolean | null
        is_previewable?: boolean | null
        due?: any | null
        passive_due?: boolean | null
        starts?: any | null
        passive_starts?: boolean | null
        has_started?: boolean | null
        points?: number | null
        item_needs_grading?: boolean | null
        minimum_passing_score?: number | null
        grading_type?: string | null
        time_to_complete?: number | null
        lock_password?: string | null
        is_locked?: boolean | null
        lock_unlocked_time?: any | null
        allow_resets?: number | null
        show_grading_feedback?: number | null
        completion_comment_required_status?: string | null
        last_published_dtime?: any | null
        progress: number
        context?: {
          __typename?: "StepContext"
          resource_id?: number | null
          cohort_id?: number | null
        } | null
        blocks?: {
          __typename?: "BlocksContent"
          content_last_modified?: any | null
          last_published?: any | null
        } | null
        permissions?: {
          __typename?: "PathItemPermissions"
          can_complete?: boolean | null
          can_discuss?: boolean | null
          can_edit?: boolean | null
          can_edit_content?: boolean | null
          can_note?: boolean | null
          can_view?: boolean | null
          can_review?: boolean | null
          can_view_content?: boolean | null
        } | null
        completion?: {
          __typename?: "Completion"
          id?: number | null
          checked_date?: any | null
          completion_attempts?: number | null
          completion_comment?: string | null
          completion_date?: any | null
          completion_due?: any | null
          last_completion_attempt_date?: any | null
          has_failed_passing_attempt?: boolean | null
          has_points?: boolean | null
          is_late?: boolean | null
          is_graded?: boolean | null
          is_skipped?: boolean | null
          is_complete?: boolean | null
          is_auto_graded?: boolean | null
          is_forced_completion?: boolean | null
          needs_grading?: boolean | null
          userpoints_earned?: number | null
          userpoints_explanation?: string | null
          userpoints_value?: number | null
          minimum_passing_score?: number | null
          grading_type?: string | null
          requires_api_completion?: boolean | null
          overridden_by_api?: boolean | null
          overridden_by?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              rewardful_referral_id?: string | null
            } | null
          } | null
          checked_by?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              rewardful_referral_id?: string | null
            } | null
          } | null
          user?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              rewardful_referral_id?: string | null
            } | null
          } | null
          submission?: {
            __typename?: "UserSubmission"
            id?: number | null
            text?: string | null
            file_url?: string | null
            file_name?: string | null
            file_image?: string | null
          } | null
          feedback_discussion_context?: {
            __typename?: "CompletionFeedbackDiscussionContext"
            id?: number | null
            discussion_id?: number | null
            num_responses?: number | null
          } | null
        } | null
        linked_resource?: {
          __typename?: "Resource"
          id: number
          name: string
          slug: string
          created?: any | null
          resource_type?: number | null
          visibility?: number | null
          description?: string | null
          short_description?: string | null
          background_overlay?: string | null
          background_image?: string | null
          image_raw?: string | null
          has_certificate?: boolean | null
          discussion_enabled?: boolean | null
          lowest_public_offering_price?: number | null
          highest_public_offering_price?: number | null
          licensed_from_resource_id?: number | null
          licensed_from_school_id?: number | null
          created_by_id?: number | null
          image: string
          cover_image: string
          curriculum_group?: { __typename?: "Group"; id: number } | null
        } | null
        linked_cohort?: {
          __typename?: "Group"
          id: number
          user_registration?: {
            __typename?: "Registration"
            id: number
            role?: number | null
            completion_state?: number | null
            send_due_notifications?: boolean | null
            send_discussion_notifications?: boolean | null
            last_activity_time?: any | null
            expiration_dtime?: any | null
            created?: any | null
            path?: { __typename?: "Path"; id: number } | null
            user?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
          } | null
        } | null
        linked_registration?: {
          __typename?: "Registration"
          id: number
          role?: number | null
          completion_state?: number | null
          send_due_notifications?: boolean | null
          send_discussion_notifications?: boolean | null
          last_activity_time?: any | null
          expiration_dtime?: any | null
          created?: any | null
          path?: { __typename?: "Path"; id: number } | null
          user?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              rewardful_referral_id?: string | null
            } | null
          } | null
        } | null
      } | null
    } | null> | null
  } | null
  points?: {
    __typename?: "Points"
    path_id?: number | null
    total?: number | null
    earned?: number | null
    total_points_possible?: number | null
    percentage?: number | null
    has_points?: boolean | null
  } | null
  user?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      rewardful_referral_id?: string | null
    } | null
  } | null
  sync?: {
    __typename?: "PathSync"
    path_id: number
    synced: boolean
    last_synced_date?: any | null
    sync_source_path_id?: number | null
    user_id?: number | null
  } | null
  publish?: {
    __typename?: "PathPublish"
    path_id: number
    published: boolean
    last_published_date?: any | null
  } | null
}

export type PathItemsQueryVariables = Exact<{
  pathId?: InputMaybe<Scalars["Int"]["input"]>
  cohortId?: InputMaybe<Scalars["Int"]["input"]>
  sourceId?: InputMaybe<Scalars["Int"]["input"]>
  lessonSourceId?: InputMaybe<Scalars["Int"]["input"]>
  cursor?: InputMaybe<Scalars["String"]["input"]>
}>

export type PathItemsQuery = {
  __typename?: "Query"
  pathItems?: {
    __typename?: "PathItemConnection"
    edges?: Array<{
      __typename?: "PathItemEdge"
      node?: {
        __typename?: "PathItem"
        id: number
        order: number
        verb?: string | null
        name: string
        type: PathItemType
        path_id: number
        source_id: number
        parent_id?: number | null
        parent_source_id?: number | null
        description?: string | null
        curriculum_sync_opt_out_dtime?: any | null
        assignment_type?: PathItemAssignmentType | null
        content_id?: number | null
        content_type?: string | null
        item_content_type_id?: number | null
        is_required?: boolean | null
        is_previewable?: boolean | null
        due?: any | null
        passive_due?: boolean | null
        starts?: any | null
        passive_starts?: boolean | null
        has_started?: boolean | null
        points?: number | null
        item_needs_grading?: boolean | null
        minimum_passing_score?: number | null
        grading_type?: string | null
        time_to_complete?: number | null
        lock_password?: string | null
        is_locked?: boolean | null
        lock_unlocked_time?: any | null
        allow_resets?: number | null
        show_grading_feedback?: number | null
        completion_comment_required_status?: string | null
        last_published_dtime?: any | null
        progress: number
        context?: {
          __typename?: "StepContext"
          resource_id?: number | null
          cohort_id?: number | null
        } | null
        blocks?: {
          __typename?: "BlocksContent"
          content_last_modified?: any | null
          last_published?: any | null
        } | null
        permissions?: {
          __typename?: "PathItemPermissions"
          can_complete?: boolean | null
          can_discuss?: boolean | null
          can_edit?: boolean | null
          can_edit_content?: boolean | null
          can_note?: boolean | null
          can_view?: boolean | null
          can_review?: boolean | null
          can_view_content?: boolean | null
        } | null
        completion?: {
          __typename?: "Completion"
          id?: number | null
          checked_date?: any | null
          completion_attempts?: number | null
          completion_comment?: string | null
          completion_date?: any | null
          completion_due?: any | null
          last_completion_attempt_date?: any | null
          has_failed_passing_attempt?: boolean | null
          has_points?: boolean | null
          is_late?: boolean | null
          is_graded?: boolean | null
          is_skipped?: boolean | null
          is_complete?: boolean | null
          is_auto_graded?: boolean | null
          is_forced_completion?: boolean | null
          needs_grading?: boolean | null
          userpoints_earned?: number | null
          userpoints_explanation?: string | null
          userpoints_value?: number | null
          minimum_passing_score?: number | null
          grading_type?: string | null
          requires_api_completion?: boolean | null
          overridden_by_api?: boolean | null
          overridden_by?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              rewardful_referral_id?: string | null
            } | null
          } | null
          checked_by?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              rewardful_referral_id?: string | null
            } | null
          } | null
          user?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              rewardful_referral_id?: string | null
            } | null
          } | null
          submission?: {
            __typename?: "UserSubmission"
            id?: number | null
            text?: string | null
            file_url?: string | null
            file_name?: string | null
            file_image?: string | null
          } | null
          feedback_discussion_context?: {
            __typename?: "CompletionFeedbackDiscussionContext"
            id?: number | null
            discussion_id?: number | null
            num_responses?: number | null
          } | null
        } | null
        linked_resource?: {
          __typename?: "Resource"
          id: number
          name: string
          slug: string
          created?: any | null
          resource_type?: number | null
          visibility?: number | null
          description?: string | null
          short_description?: string | null
          background_overlay?: string | null
          background_image?: string | null
          image_raw?: string | null
          has_certificate?: boolean | null
          discussion_enabled?: boolean | null
          lowest_public_offering_price?: number | null
          highest_public_offering_price?: number | null
          licensed_from_resource_id?: number | null
          licensed_from_school_id?: number | null
          created_by_id?: number | null
          image: string
          cover_image: string
          curriculum_group?: { __typename?: "Group"; id: number } | null
        } | null
        linked_cohort?: {
          __typename?: "Group"
          id: number
          user_registration?: {
            __typename?: "Registration"
            id: number
            role?: number | null
            completion_state?: number | null
            send_due_notifications?: boolean | null
            send_discussion_notifications?: boolean | null
            last_activity_time?: any | null
            expiration_dtime?: any | null
            created?: any | null
            path?: { __typename?: "Path"; id: number } | null
            user?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
          } | null
        } | null
        linked_registration?: {
          __typename?: "Registration"
          id: number
          role?: number | null
          completion_state?: number | null
          send_due_notifications?: boolean | null
          send_discussion_notifications?: boolean | null
          last_activity_time?: any | null
          expiration_dtime?: any | null
          created?: any | null
          path?: { __typename?: "Path"; id: number } | null
          user?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              rewardful_referral_id?: string | null
            } | null
          } | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type PathPublishQueryVariables = Exact<{
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
}>

export type PathPublishQuery = {
  __typename?: "Query"
  path?: {
    __typename?: "Path"
    id: number
    publish?: {
      __typename?: "PathPublish"
      path_id: number
      published: boolean
      last_published_date?: any | null
    } | null
  } | null
}

export type PathQueryVariables = Exact<{
  id?: InputMaybe<Scalars["Int"]["input"]>
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
}>

export type PathQuery = {
  __typename?: "Query"
  path?: {
    __typename?: "Path"
    id: number
    progress?: number | null
    next_step_id?: number | null
    completion_date?: any | null
    created_date?: any | null
    schedule?: {
      __typename?: "PathNodeSchedule"
      start?: {
        __typename?: "ScheduleDate"
        behavior: ScheduleDateBehavior
        date?: any | null
      } | null
      end?: {
        __typename?: "ScheduleDate"
        behavior: ScheduleDateBehavior
        date?: any | null
      } | null
    } | null
    items?: {
      __typename?: "PathItemConnection"
      edges?: Array<{
        __typename?: "PathItemEdge"
        node?: {
          __typename?: "PathItem"
          id: number
          order: number
          verb?: string | null
          name: string
          type: PathItemType
          path_id: number
          source_id: number
          parent_id?: number | null
          parent_source_id?: number | null
          description?: string | null
          curriculum_sync_opt_out_dtime?: any | null
          assignment_type?: PathItemAssignmentType | null
          content_id?: number | null
          content_type?: string | null
          item_content_type_id?: number | null
          is_required?: boolean | null
          is_previewable?: boolean | null
          due?: any | null
          passive_due?: boolean | null
          starts?: any | null
          passive_starts?: boolean | null
          has_started?: boolean | null
          points?: number | null
          item_needs_grading?: boolean | null
          minimum_passing_score?: number | null
          grading_type?: string | null
          time_to_complete?: number | null
          lock_password?: string | null
          is_locked?: boolean | null
          lock_unlocked_time?: any | null
          allow_resets?: number | null
          show_grading_feedback?: number | null
          completion_comment_required_status?: string | null
          last_published_dtime?: any | null
          progress: number
          context?: {
            __typename?: "StepContext"
            resource_id?: number | null
            cohort_id?: number | null
          } | null
          blocks?: {
            __typename?: "BlocksContent"
            content_last_modified?: any | null
            last_published?: any | null
          } | null
          permissions?: {
            __typename?: "PathItemPermissions"
            can_complete?: boolean | null
            can_discuss?: boolean | null
            can_edit?: boolean | null
            can_edit_content?: boolean | null
            can_note?: boolean | null
            can_view?: boolean | null
            can_review?: boolean | null
            can_view_content?: boolean | null
          } | null
          completion?: {
            __typename?: "Completion"
            id?: number | null
            checked_date?: any | null
            completion_attempts?: number | null
            completion_comment?: string | null
            completion_date?: any | null
            completion_due?: any | null
            last_completion_attempt_date?: any | null
            has_failed_passing_attempt?: boolean | null
            has_points?: boolean | null
            is_late?: boolean | null
            is_graded?: boolean | null
            is_skipped?: boolean | null
            is_complete?: boolean | null
            is_auto_graded?: boolean | null
            is_forced_completion?: boolean | null
            needs_grading?: boolean | null
            userpoints_earned?: number | null
            userpoints_explanation?: string | null
            userpoints_value?: number | null
            minimum_passing_score?: number | null
            grading_type?: string | null
            requires_api_completion?: boolean | null
            overridden_by_api?: boolean | null
            overridden_by?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
            checked_by?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
            user?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
            submission?: {
              __typename?: "UserSubmission"
              id?: number | null
              text?: string | null
              file_url?: string | null
              file_name?: string | null
              file_image?: string | null
            } | null
            feedback_discussion_context?: {
              __typename?: "CompletionFeedbackDiscussionContext"
              id?: number | null
              discussion_id?: number | null
              num_responses?: number | null
            } | null
          } | null
          linked_resource?: {
            __typename?: "Resource"
            id: number
            name: string
            slug: string
            created?: any | null
            resource_type?: number | null
            visibility?: number | null
            description?: string | null
            short_description?: string | null
            background_overlay?: string | null
            background_image?: string | null
            image_raw?: string | null
            has_certificate?: boolean | null
            discussion_enabled?: boolean | null
            lowest_public_offering_price?: number | null
            highest_public_offering_price?: number | null
            licensed_from_resource_id?: number | null
            licensed_from_school_id?: number | null
            created_by_id?: number | null
            image: string
            cover_image: string
            curriculum_group?: { __typename?: "Group"; id: number } | null
          } | null
          linked_cohort?: {
            __typename?: "Group"
            id: number
            user_registration?: {
              __typename?: "Registration"
              id: number
              role?: number | null
              completion_state?: number | null
              send_due_notifications?: boolean | null
              send_discussion_notifications?: boolean | null
              last_activity_time?: any | null
              expiration_dtime?: any | null
              created?: any | null
              path?: { __typename?: "Path"; id: number } | null
              user?: {
                __typename?: "User"
                id?: number | null
                email?: string | null
                first_name?: string | null
                last_name?: string | null
                full_name?: string | null
                display_name?: string | null
                profile?: {
                  __typename?: "Profile"
                  id?: number | null
                  bio?: string | null
                  image?: string | null
                  location?: string | null
                  last_activity?: any | null
                  rewardful_referral_id?: string | null
                } | null
              } | null
            } | null
          } | null
          linked_registration?: {
            __typename?: "Registration"
            id: number
            role?: number | null
            completion_state?: number | null
            send_due_notifications?: boolean | null
            send_discussion_notifications?: boolean | null
            last_activity_time?: any | null
            expiration_dtime?: any | null
            created?: any | null
            path?: { __typename?: "Path"; id: number } | null
            user?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
          } | null
        } | null
      } | null> | null
    } | null
    points?: {
      __typename?: "Points"
      path_id?: number | null
      total?: number | null
      earned?: number | null
      total_points_possible?: number | null
      percentage?: number | null
      has_points?: boolean | null
    } | null
    user?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        rewardful_referral_id?: string | null
      } | null
    } | null
    sync?: {
      __typename?: "PathSync"
      path_id: number
      synced: boolean
      last_synced_date?: any | null
      sync_source_path_id?: number | null
      user_id?: number | null
    } | null
    publish?: {
      __typename?: "PathPublish"
      path_id: number
      published: boolean
      last_published_date?: any | null
    } | null
  } | null
}

export type SyncPathMutationVariables = Exact<{
  id: Scalars["Int"]["input"]
}>

export type SyncPathMutation = {
  __typename?: "Mutations"
  syncPath?: {
    __typename?: "Path"
    id: number
    progress?: number | null
    next_step_id?: number | null
    completion_date?: any | null
    created_date?: any | null
    items?: {
      __typename?: "PathItemConnection"
      edges?: Array<{
        __typename?: "PathItemEdge"
        node?: {
          __typename?: "PathItem"
          id: number
          order: number
          verb?: string | null
          name: string
          type: PathItemType
          path_id: number
          source_id: number
          parent_id?: number | null
          parent_source_id?: number | null
          description?: string | null
          curriculum_sync_opt_out_dtime?: any | null
          assignment_type?: PathItemAssignmentType | null
          content_id?: number | null
          content_type?: string | null
          item_content_type_id?: number | null
          is_required?: boolean | null
          is_previewable?: boolean | null
          due?: any | null
          passive_due?: boolean | null
          starts?: any | null
          passive_starts?: boolean | null
          has_started?: boolean | null
          points?: number | null
          item_needs_grading?: boolean | null
          minimum_passing_score?: number | null
          grading_type?: string | null
          time_to_complete?: number | null
          lock_password?: string | null
          is_locked?: boolean | null
          lock_unlocked_time?: any | null
          allow_resets?: number | null
          show_grading_feedback?: number | null
          completion_comment_required_status?: string | null
          last_published_dtime?: any | null
          progress: number
          context?: {
            __typename?: "StepContext"
            resource_id?: number | null
            cohort_id?: number | null
          } | null
          blocks?: {
            __typename?: "BlocksContent"
            content_last_modified?: any | null
            last_published?: any | null
          } | null
          permissions?: {
            __typename?: "PathItemPermissions"
            can_complete?: boolean | null
            can_discuss?: boolean | null
            can_edit?: boolean | null
            can_edit_content?: boolean | null
            can_note?: boolean | null
            can_view?: boolean | null
            can_review?: boolean | null
            can_view_content?: boolean | null
          } | null
          completion?: {
            __typename?: "Completion"
            id?: number | null
            checked_date?: any | null
            completion_attempts?: number | null
            completion_comment?: string | null
            completion_date?: any | null
            completion_due?: any | null
            last_completion_attempt_date?: any | null
            has_failed_passing_attempt?: boolean | null
            has_points?: boolean | null
            is_late?: boolean | null
            is_graded?: boolean | null
            is_skipped?: boolean | null
            is_complete?: boolean | null
            is_auto_graded?: boolean | null
            is_forced_completion?: boolean | null
            needs_grading?: boolean | null
            userpoints_earned?: number | null
            userpoints_explanation?: string | null
            userpoints_value?: number | null
            minimum_passing_score?: number | null
            grading_type?: string | null
            requires_api_completion?: boolean | null
            overridden_by_api?: boolean | null
            overridden_by?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
            checked_by?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
            user?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
            submission?: {
              __typename?: "UserSubmission"
              id?: number | null
              text?: string | null
              file_url?: string | null
              file_name?: string | null
              file_image?: string | null
            } | null
            feedback_discussion_context?: {
              __typename?: "CompletionFeedbackDiscussionContext"
              id?: number | null
              discussion_id?: number | null
              num_responses?: number | null
            } | null
          } | null
          linked_resource?: {
            __typename?: "Resource"
            id: number
            name: string
            slug: string
            created?: any | null
            resource_type?: number | null
            visibility?: number | null
            description?: string | null
            short_description?: string | null
            background_overlay?: string | null
            background_image?: string | null
            image_raw?: string | null
            has_certificate?: boolean | null
            discussion_enabled?: boolean | null
            lowest_public_offering_price?: number | null
            highest_public_offering_price?: number | null
            licensed_from_resource_id?: number | null
            licensed_from_school_id?: number | null
            created_by_id?: number | null
            image: string
            cover_image: string
            curriculum_group?: { __typename?: "Group"; id: number } | null
          } | null
          linked_cohort?: {
            __typename?: "Group"
            id: number
            user_registration?: {
              __typename?: "Registration"
              id: number
              role?: number | null
              completion_state?: number | null
              send_due_notifications?: boolean | null
              send_discussion_notifications?: boolean | null
              last_activity_time?: any | null
              expiration_dtime?: any | null
              created?: any | null
              path?: { __typename?: "Path"; id: number } | null
              user?: {
                __typename?: "User"
                id?: number | null
                email?: string | null
                first_name?: string | null
                last_name?: string | null
                full_name?: string | null
                display_name?: string | null
                profile?: {
                  __typename?: "Profile"
                  id?: number | null
                  bio?: string | null
                  image?: string | null
                  location?: string | null
                  last_activity?: any | null
                  rewardful_referral_id?: string | null
                } | null
              } | null
            } | null
          } | null
          linked_registration?: {
            __typename?: "Registration"
            id: number
            role?: number | null
            completion_state?: number | null
            send_due_notifications?: boolean | null
            send_discussion_notifications?: boolean | null
            last_activity_time?: any | null
            expiration_dtime?: any | null
            created?: any | null
            path?: { __typename?: "Path"; id: number } | null
            user?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                rewardful_referral_id?: string | null
              } | null
            } | null
          } | null
        } | null
      } | null> | null
    } | null
    points?: {
      __typename?: "Points"
      path_id?: number | null
      total?: number | null
      earned?: number | null
      total_points_possible?: number | null
      percentage?: number | null
      has_points?: boolean | null
    } | null
    user?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        rewardful_referral_id?: string | null
      } | null
    } | null
    sync?: {
      __typename?: "PathSync"
      path_id: number
      synced: boolean
      last_synced_date?: any | null
      sync_source_path_id?: number | null
      user_id?: number | null
    } | null
    publish?: {
      __typename?: "PathPublish"
      path_id: number
      published: boolean
      last_published_date?: any | null
    } | null
  } | null
}

export type PathSyncPlanChangeFragment = {
  __typename?: "PathSyncPlanChange"
  item_source_id: number
  parent_source_id?: number | null
  item_name: string
  parent_name?: string | null
  item_type: PathItemType
  assignment_type?: PathItemAssignmentType | null
  forced?: PathSyncPlanForceType | null
  forced_reasons?: Array<PathSyncPlanForceReasonType | null> | null
  change_details?: any | null
  last_changed?: any | null
}

export type PathSyncPlanQueryVariables = Exact<{
  sync_plan: PathSyncPlanInput
}>

export type PathSyncPlanQuery = {
  __typename?: "Query"
  pathSyncPlan?: {
    __typename?: "PathSyncPlan"
    from_path_id?: number | null
    to_path_id?: number | null
    hash: string
    included_changes: Array<{
      __typename?: "PathSyncPlanChange"
      item_source_id: number
      parent_source_id?: number | null
      item_name: string
      parent_name?: string | null
      item_type: PathItemType
      assignment_type?: PathItemAssignmentType | null
      forced?: PathSyncPlanForceType | null
      forced_reasons?: Array<PathSyncPlanForceReasonType | null> | null
      change_details?: any | null
      last_changed?: any | null
    } | null>
    excluded_changes: Array<{
      __typename?: "PathSyncPlanChange"
      item_source_id: number
      parent_source_id?: number | null
      item_name: string
      parent_name?: string | null
      item_type: PathItemType
      assignment_type?: PathItemAssignmentType | null
      forced?: PathSyncPlanForceType | null
      forced_reasons?: Array<PathSyncPlanForceReasonType | null> | null
      change_details?: any | null
      last_changed?: any | null
    } | null>
  } | null
}

export type ReorderPathItemsMutationVariables = Exact<{
  path_id: Scalars["Int"]["input"]
  items: Array<InputMaybe<PathItemOrder>> | InputMaybe<PathItemOrder>
}>

export type ReorderPathItemsMutation = {
  __typename?: "Mutations"
  reorderPathItems?: boolean | null
}

export type ResetPathItemForUserMutationVariables = Exact<{
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
}>

export type ResetPathItemForUserMutation = {
  __typename?: "Mutations"
  resetPathItemForUser?: {
    __typename?: "PathItem"
    id: number
    order: number
    verb?: string | null
    name: string
    type: PathItemType
    path_id: number
    source_id: number
    parent_id?: number | null
    parent_source_id?: number | null
    description?: string | null
    curriculum_sync_opt_out_dtime?: any | null
    assignment_type?: PathItemAssignmentType | null
    content_id?: number | null
    content_type?: string | null
    item_content_type_id?: number | null
    is_required?: boolean | null
    is_previewable?: boolean | null
    due?: any | null
    passive_due?: boolean | null
    starts?: any | null
    passive_starts?: boolean | null
    has_started?: boolean | null
    points?: number | null
    item_needs_grading?: boolean | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    time_to_complete?: number | null
    lock_password?: string | null
    is_locked?: boolean | null
    lock_unlocked_time?: any | null
    allow_resets?: number | null
    show_grading_feedback?: number | null
    completion_comment_required_status?: string | null
    last_published_dtime?: any | null
    progress: number
    context?: {
      __typename?: "StepContext"
      resource_id?: number | null
      cohort_id?: number | null
    } | null
    blocks?: {
      __typename?: "BlocksContent"
      content_last_modified?: any | null
      last_published?: any | null
    } | null
    permissions?: {
      __typename?: "PathItemPermissions"
      can_complete?: boolean | null
      can_discuss?: boolean | null
      can_edit?: boolean | null
      can_edit_content?: boolean | null
      can_note?: boolean | null
      can_view?: boolean | null
      can_review?: boolean | null
      can_view_content?: boolean | null
    } | null
    completion?: {
      __typename?: "Completion"
      id?: number | null
      checked_date?: any | null
      completion_attempts?: number | null
      completion_comment?: string | null
      completion_date?: any | null
      completion_due?: any | null
      last_completion_attempt_date?: any | null
      has_failed_passing_attempt?: boolean | null
      has_points?: boolean | null
      is_late?: boolean | null
      is_graded?: boolean | null
      is_skipped?: boolean | null
      is_complete?: boolean | null
      is_auto_graded?: boolean | null
      is_forced_completion?: boolean | null
      needs_grading?: boolean | null
      userpoints_earned?: number | null
      userpoints_explanation?: string | null
      userpoints_value?: number | null
      minimum_passing_score?: number | null
      grading_type?: string | null
      requires_api_completion?: boolean | null
      overridden_by_api?: boolean | null
      overridden_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      checked_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      submission?: {
        __typename?: "UserSubmission"
        id?: number | null
        text?: string | null
        file_url?: string | null
        file_name?: string | null
        file_image?: string | null
      } | null
      feedback_discussion_context?: {
        __typename?: "CompletionFeedbackDiscussionContext"
        id?: number | null
        discussion_id?: number | null
        num_responses?: number | null
      } | null
    } | null
    linked_resource?: {
      __typename?: "Resource"
      id: number
      name: string
      slug: string
      created?: any | null
      resource_type?: number | null
      visibility?: number | null
      description?: string | null
      short_description?: string | null
      background_overlay?: string | null
      background_image?: string | null
      image_raw?: string | null
      has_certificate?: boolean | null
      discussion_enabled?: boolean | null
      lowest_public_offering_price?: number | null
      highest_public_offering_price?: number | null
      licensed_from_resource_id?: number | null
      licensed_from_school_id?: number | null
      created_by_id?: number | null
      image: string
      cover_image: string
      curriculum_group?: { __typename?: "Group"; id: number } | null
    } | null
    linked_cohort?: {
      __typename?: "Group"
      id: number
      user_registration?: {
        __typename?: "Registration"
        id: number
        role?: number | null
        completion_state?: number | null
        send_due_notifications?: boolean | null
        send_discussion_notifications?: boolean | null
        last_activity_time?: any | null
        expiration_dtime?: any | null
        created?: any | null
        path?: { __typename?: "Path"; id: number } | null
        user?: {
          __typename?: "User"
          id?: number | null
          email?: string | null
          first_name?: string | null
          last_name?: string | null
          full_name?: string | null
          display_name?: string | null
          profile?: {
            __typename?: "Profile"
            id?: number | null
            bio?: string | null
            image?: string | null
            location?: string | null
            last_activity?: any | null
            rewardful_referral_id?: string | null
          } | null
        } | null
      } | null
    } | null
    linked_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
      completion_state?: number | null
      send_due_notifications?: boolean | null
      send_discussion_notifications?: boolean | null
      last_activity_time?: any | null
      expiration_dtime?: any | null
      created?: any | null
      path?: { __typename?: "Path"; id: number } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type ResetPathItemMutationVariables = Exact<{
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
}>

export type ResetPathItemMutation = {
  __typename?: "Mutations"
  resetPathItem?: {
    __typename?: "PathItem"
    id: number
    order: number
    verb?: string | null
    name: string
    type: PathItemType
    path_id: number
    source_id: number
    parent_id?: number | null
    parent_source_id?: number | null
    description?: string | null
    curriculum_sync_opt_out_dtime?: any | null
    assignment_type?: PathItemAssignmentType | null
    content_id?: number | null
    content_type?: string | null
    item_content_type_id?: number | null
    is_required?: boolean | null
    is_previewable?: boolean | null
    due?: any | null
    passive_due?: boolean | null
    starts?: any | null
    passive_starts?: boolean | null
    has_started?: boolean | null
    points?: number | null
    item_needs_grading?: boolean | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    time_to_complete?: number | null
    lock_password?: string | null
    is_locked?: boolean | null
    lock_unlocked_time?: any | null
    allow_resets?: number | null
    show_grading_feedback?: number | null
    completion_comment_required_status?: string | null
    last_published_dtime?: any | null
    progress: number
    path?: {
      __typename?: "Path"
      id: number
      progress?: number | null
      next_step_id?: number | null
      completion_date?: any | null
      points?: {
        __typename?: "Points"
        path_id?: number | null
        total?: number | null
        earned?: number | null
        total_points_possible?: number | null
        percentage?: number | null
        has_points?: boolean | null
      } | null
    } | null
    context?: {
      __typename?: "StepContext"
      resource_id?: number | null
      cohort_id?: number | null
    } | null
    blocks?: {
      __typename?: "BlocksContent"
      content_last_modified?: any | null
      last_published?: any | null
    } | null
    permissions?: {
      __typename?: "PathItemPermissions"
      can_complete?: boolean | null
      can_discuss?: boolean | null
      can_edit?: boolean | null
      can_edit_content?: boolean | null
      can_note?: boolean | null
      can_view?: boolean | null
      can_review?: boolean | null
      can_view_content?: boolean | null
    } | null
    completion?: {
      __typename?: "Completion"
      id?: number | null
      checked_date?: any | null
      completion_attempts?: number | null
      completion_comment?: string | null
      completion_date?: any | null
      completion_due?: any | null
      last_completion_attempt_date?: any | null
      has_failed_passing_attempt?: boolean | null
      has_points?: boolean | null
      is_late?: boolean | null
      is_graded?: boolean | null
      is_skipped?: boolean | null
      is_complete?: boolean | null
      is_auto_graded?: boolean | null
      is_forced_completion?: boolean | null
      needs_grading?: boolean | null
      userpoints_earned?: number | null
      userpoints_explanation?: string | null
      userpoints_value?: number | null
      minimum_passing_score?: number | null
      grading_type?: string | null
      requires_api_completion?: boolean | null
      overridden_by_api?: boolean | null
      overridden_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      checked_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      submission?: {
        __typename?: "UserSubmission"
        id?: number | null
        text?: string | null
        file_url?: string | null
        file_name?: string | null
        file_image?: string | null
      } | null
      feedback_discussion_context?: {
        __typename?: "CompletionFeedbackDiscussionContext"
        id?: number | null
        discussion_id?: number | null
        num_responses?: number | null
      } | null
    } | null
    linked_resource?: {
      __typename?: "Resource"
      id: number
      name: string
      slug: string
      created?: any | null
      resource_type?: number | null
      visibility?: number | null
      description?: string | null
      short_description?: string | null
      background_overlay?: string | null
      background_image?: string | null
      image_raw?: string | null
      has_certificate?: boolean | null
      discussion_enabled?: boolean | null
      lowest_public_offering_price?: number | null
      highest_public_offering_price?: number | null
      licensed_from_resource_id?: number | null
      licensed_from_school_id?: number | null
      created_by_id?: number | null
      image: string
      cover_image: string
      curriculum_group?: { __typename?: "Group"; id: number } | null
    } | null
    linked_cohort?: {
      __typename?: "Group"
      id: number
      user_registration?: {
        __typename?: "Registration"
        id: number
        role?: number | null
        completion_state?: number | null
        send_due_notifications?: boolean | null
        send_discussion_notifications?: boolean | null
        last_activity_time?: any | null
        expiration_dtime?: any | null
        created?: any | null
        path?: { __typename?: "Path"; id: number } | null
        user?: {
          __typename?: "User"
          id?: number | null
          email?: string | null
          first_name?: string | null
          last_name?: string | null
          full_name?: string | null
          display_name?: string | null
          profile?: {
            __typename?: "Profile"
            id?: number | null
            bio?: string | null
            image?: string | null
            location?: string | null
            last_activity?: any | null
            rewardful_referral_id?: string | null
          } | null
        } | null
      } | null
    } | null
    linked_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
      completion_state?: number | null
      send_due_notifications?: boolean | null
      send_discussion_notifications?: boolean | null
      last_activity_time?: any | null
      expiration_dtime?: any | null
      created?: any | null
      path?: { __typename?: "Path"; id: number } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type SingleCohortPathQueryVariables = Exact<{
  cohort_id: Scalars["Int"]["input"]
}>

export type SingleCohortPathQuery = {
  __typename?: "Query"
  path?: {
    __typename?: "Path"
    id: number
    items?: {
      __typename?: "PathItemConnection"
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
      edges?: Array<{
        __typename?: "PathItemEdge"
        node?: {
          __typename?: "PathItem"
          id: number
          type: PathItemType
          blocks?: {
            __typename?: "BlocksContent"
            content_last_modified?: any | null
            last_published?: any | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type SingleCohortPathFragment = {
  __typename?: "Path"
  id: number
  items?: {
    __typename?: "PathItemConnection"
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges?: Array<{
      __typename?: "PathItemEdge"
      node?: {
        __typename?: "PathItem"
        id: number
        type: PathItemType
        blocks?: {
          __typename?: "BlocksContent"
          content_last_modified?: any | null
          last_published?: any | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type SkipPathItemForUserMutationVariables = Exact<{
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
}>

export type SkipPathItemForUserMutation = {
  __typename?: "Mutations"
  skipPathItemForUser?: {
    __typename?: "PathItem"
    id: number
    order: number
    verb?: string | null
    name: string
    type: PathItemType
    path_id: number
    source_id: number
    parent_id?: number | null
    parent_source_id?: number | null
    description?: string | null
    curriculum_sync_opt_out_dtime?: any | null
    assignment_type?: PathItemAssignmentType | null
    content_id?: number | null
    content_type?: string | null
    item_content_type_id?: number | null
    is_required?: boolean | null
    is_previewable?: boolean | null
    due?: any | null
    passive_due?: boolean | null
    starts?: any | null
    passive_starts?: boolean | null
    has_started?: boolean | null
    points?: number | null
    item_needs_grading?: boolean | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    time_to_complete?: number | null
    lock_password?: string | null
    is_locked?: boolean | null
    lock_unlocked_time?: any | null
    allow_resets?: number | null
    show_grading_feedback?: number | null
    completion_comment_required_status?: string | null
    last_published_dtime?: any | null
    progress: number
    context?: {
      __typename?: "StepContext"
      resource_id?: number | null
      cohort_id?: number | null
    } | null
    blocks?: {
      __typename?: "BlocksContent"
      content_last_modified?: any | null
      last_published?: any | null
    } | null
    permissions?: {
      __typename?: "PathItemPermissions"
      can_complete?: boolean | null
      can_discuss?: boolean | null
      can_edit?: boolean | null
      can_edit_content?: boolean | null
      can_note?: boolean | null
      can_view?: boolean | null
      can_review?: boolean | null
      can_view_content?: boolean | null
    } | null
    completion?: {
      __typename?: "Completion"
      id?: number | null
      checked_date?: any | null
      completion_attempts?: number | null
      completion_comment?: string | null
      completion_date?: any | null
      completion_due?: any | null
      last_completion_attempt_date?: any | null
      has_failed_passing_attempt?: boolean | null
      has_points?: boolean | null
      is_late?: boolean | null
      is_graded?: boolean | null
      is_skipped?: boolean | null
      is_complete?: boolean | null
      is_auto_graded?: boolean | null
      is_forced_completion?: boolean | null
      needs_grading?: boolean | null
      userpoints_earned?: number | null
      userpoints_explanation?: string | null
      userpoints_value?: number | null
      minimum_passing_score?: number | null
      grading_type?: string | null
      requires_api_completion?: boolean | null
      overridden_by_api?: boolean | null
      overridden_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      checked_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      submission?: {
        __typename?: "UserSubmission"
        id?: number | null
        text?: string | null
        file_url?: string | null
        file_name?: string | null
        file_image?: string | null
      } | null
      feedback_discussion_context?: {
        __typename?: "CompletionFeedbackDiscussionContext"
        id?: number | null
        discussion_id?: number | null
        num_responses?: number | null
      } | null
    } | null
    linked_resource?: {
      __typename?: "Resource"
      id: number
      name: string
      slug: string
      created?: any | null
      resource_type?: number | null
      visibility?: number | null
      description?: string | null
      short_description?: string | null
      background_overlay?: string | null
      background_image?: string | null
      image_raw?: string | null
      has_certificate?: boolean | null
      discussion_enabled?: boolean | null
      lowest_public_offering_price?: number | null
      highest_public_offering_price?: number | null
      licensed_from_resource_id?: number | null
      licensed_from_school_id?: number | null
      created_by_id?: number | null
      image: string
      cover_image: string
      curriculum_group?: { __typename?: "Group"; id: number } | null
    } | null
    linked_cohort?: {
      __typename?: "Group"
      id: number
      user_registration?: {
        __typename?: "Registration"
        id: number
        role?: number | null
        completion_state?: number | null
        send_due_notifications?: boolean | null
        send_discussion_notifications?: boolean | null
        last_activity_time?: any | null
        expiration_dtime?: any | null
        created?: any | null
        path?: { __typename?: "Path"; id: number } | null
        user?: {
          __typename?: "User"
          id?: number | null
          email?: string | null
          first_name?: string | null
          last_name?: string | null
          full_name?: string | null
          display_name?: string | null
          profile?: {
            __typename?: "Profile"
            id?: number | null
            bio?: string | null
            image?: string | null
            location?: string | null
            last_activity?: any | null
            rewardful_referral_id?: string | null
          } | null
        } | null
      } | null
    } | null
    linked_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
      completion_state?: number | null
      send_due_notifications?: boolean | null
      send_discussion_notifications?: boolean | null
      last_activity_time?: any | null
      expiration_dtime?: any | null
      created?: any | null
      path?: { __typename?: "Path"; id: number } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type UnlockPathItemMutationVariables = Exact<{
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
  step_lock_password: Scalars["String"]["input"]
}>

export type UnlockPathItemMutation = {
  __typename?: "Mutations"
  unlockPathItem?: {
    __typename?: "PathItem"
    id: number
    order: number
    verb?: string | null
    name: string
    type: PathItemType
    path_id: number
    source_id: number
    parent_id?: number | null
    parent_source_id?: number | null
    description?: string | null
    curriculum_sync_opt_out_dtime?: any | null
    assignment_type?: PathItemAssignmentType | null
    content_id?: number | null
    content_type?: string | null
    item_content_type_id?: number | null
    is_required?: boolean | null
    is_previewable?: boolean | null
    due?: any | null
    passive_due?: boolean | null
    starts?: any | null
    passive_starts?: boolean | null
    has_started?: boolean | null
    points?: number | null
    item_needs_grading?: boolean | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    time_to_complete?: number | null
    lock_password?: string | null
    is_locked?: boolean | null
    lock_unlocked_time?: any | null
    allow_resets?: number | null
    show_grading_feedback?: number | null
    completion_comment_required_status?: string | null
    last_published_dtime?: any | null
    progress: number
    context?: {
      __typename?: "StepContext"
      resource_id?: number | null
      cohort_id?: number | null
    } | null
    blocks?: {
      __typename?: "BlocksContent"
      content_last_modified?: any | null
      last_published?: any | null
    } | null
    permissions?: {
      __typename?: "PathItemPermissions"
      can_complete?: boolean | null
      can_discuss?: boolean | null
      can_edit?: boolean | null
      can_edit_content?: boolean | null
      can_note?: boolean | null
      can_view?: boolean | null
      can_review?: boolean | null
      can_view_content?: boolean | null
    } | null
    completion?: {
      __typename?: "Completion"
      id?: number | null
      checked_date?: any | null
      completion_attempts?: number | null
      completion_comment?: string | null
      completion_date?: any | null
      completion_due?: any | null
      last_completion_attempt_date?: any | null
      has_failed_passing_attempt?: boolean | null
      has_points?: boolean | null
      is_late?: boolean | null
      is_graded?: boolean | null
      is_skipped?: boolean | null
      is_complete?: boolean | null
      is_auto_graded?: boolean | null
      is_forced_completion?: boolean | null
      needs_grading?: boolean | null
      userpoints_earned?: number | null
      userpoints_explanation?: string | null
      userpoints_value?: number | null
      minimum_passing_score?: number | null
      grading_type?: string | null
      requires_api_completion?: boolean | null
      overridden_by_api?: boolean | null
      overridden_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      checked_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      submission?: {
        __typename?: "UserSubmission"
        id?: number | null
        text?: string | null
        file_url?: string | null
        file_name?: string | null
        file_image?: string | null
      } | null
      feedback_discussion_context?: {
        __typename?: "CompletionFeedbackDiscussionContext"
        id?: number | null
        discussion_id?: number | null
        num_responses?: number | null
      } | null
    } | null
    linked_resource?: {
      __typename?: "Resource"
      id: number
      name: string
      slug: string
      created?: any | null
      resource_type?: number | null
      visibility?: number | null
      description?: string | null
      short_description?: string | null
      background_overlay?: string | null
      background_image?: string | null
      image_raw?: string | null
      has_certificate?: boolean | null
      discussion_enabled?: boolean | null
      lowest_public_offering_price?: number | null
      highest_public_offering_price?: number | null
      licensed_from_resource_id?: number | null
      licensed_from_school_id?: number | null
      created_by_id?: number | null
      image: string
      cover_image: string
      curriculum_group?: { __typename?: "Group"; id: number } | null
    } | null
    linked_cohort?: {
      __typename?: "Group"
      id: number
      user_registration?: {
        __typename?: "Registration"
        id: number
        role?: number | null
        completion_state?: number | null
        send_due_notifications?: boolean | null
        send_discussion_notifications?: boolean | null
        last_activity_time?: any | null
        expiration_dtime?: any | null
        created?: any | null
        path?: { __typename?: "Path"; id: number } | null
        user?: {
          __typename?: "User"
          id?: number | null
          email?: string | null
          first_name?: string | null
          last_name?: string | null
          full_name?: string | null
          display_name?: string | null
          profile?: {
            __typename?: "Profile"
            id?: number | null
            bio?: string | null
            image?: string | null
            location?: string | null
            last_activity?: any | null
            rewardful_referral_id?: string | null
          } | null
        } | null
      } | null
    } | null
    linked_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
      completion_state?: number | null
      send_due_notifications?: boolean | null
      send_discussion_notifications?: boolean | null
      last_activity_time?: any | null
      expiration_dtime?: any | null
      created?: any | null
      path?: { __typename?: "Path"; id: number } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type UpdatePathItemMutationVariables = Exact<{
  path_id: Scalars["Int"]["input"]
  source_id: Scalars["Int"]["input"]
  order: Scalars["Int"]["input"]
  type: PathItemType
  name: Scalars["String"]["input"]
  description?: InputMaybe<Scalars["String"]["input"]>
  assignment_type?: InputMaybe<PathItemAssignmentType>
  is_required?: InputMaybe<Scalars["Boolean"]["input"]>
  allow_resets?: InputMaybe<Scalars["Int"]["input"]>
  show_grading_feedback?: InputMaybe<Scalars["Int"]["input"]>
  completion_comment_required_status?: InputMaybe<Scalars["String"]["input"]>
  points?: InputMaybe<Scalars["Int"]["input"]>
  minimum_passing_score?: InputMaybe<Scalars["Int"]["input"]>
  grading_type?: InputMaybe<Scalars["String"]["input"]>
  is_previewable?: InputMaybe<Scalars["Boolean"]["input"]>
  lock_password?: InputMaybe<Scalars["String"]["input"]>
  time_to_complete?: InputMaybe<Scalars["Int"]["input"]>
  requires_api_completion?: InputMaybe<Scalars["Boolean"]["input"]>
}>

export type UpdatePathItemMutation = {
  __typename?: "Mutations"
  updatePathItem?: {
    __typename?: "PathItem"
    id: number
    order: number
    verb?: string | null
    name: string
    type: PathItemType
    path_id: number
    source_id: number
    parent_id?: number | null
    parent_source_id?: number | null
    description?: string | null
    curriculum_sync_opt_out_dtime?: any | null
    assignment_type?: PathItemAssignmentType | null
    content_id?: number | null
    content_type?: string | null
    item_content_type_id?: number | null
    is_required?: boolean | null
    is_previewable?: boolean | null
    due?: any | null
    passive_due?: boolean | null
    starts?: any | null
    passive_starts?: boolean | null
    has_started?: boolean | null
    points?: number | null
    item_needs_grading?: boolean | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    time_to_complete?: number | null
    lock_password?: string | null
    is_locked?: boolean | null
    lock_unlocked_time?: any | null
    allow_resets?: number | null
    show_grading_feedback?: number | null
    completion_comment_required_status?: string | null
    last_published_dtime?: any | null
    progress: number
    context?: {
      __typename?: "StepContext"
      resource_id?: number | null
      cohort_id?: number | null
    } | null
    blocks?: {
      __typename?: "BlocksContent"
      content_last_modified?: any | null
      last_published?: any | null
    } | null
    permissions?: {
      __typename?: "PathItemPermissions"
      can_complete?: boolean | null
      can_discuss?: boolean | null
      can_edit?: boolean | null
      can_edit_content?: boolean | null
      can_note?: boolean | null
      can_view?: boolean | null
      can_review?: boolean | null
      can_view_content?: boolean | null
    } | null
    completion?: {
      __typename?: "Completion"
      id?: number | null
      checked_date?: any | null
      completion_attempts?: number | null
      completion_comment?: string | null
      completion_date?: any | null
      completion_due?: any | null
      last_completion_attempt_date?: any | null
      has_failed_passing_attempt?: boolean | null
      has_points?: boolean | null
      is_late?: boolean | null
      is_graded?: boolean | null
      is_skipped?: boolean | null
      is_complete?: boolean | null
      is_auto_graded?: boolean | null
      is_forced_completion?: boolean | null
      needs_grading?: boolean | null
      userpoints_earned?: number | null
      userpoints_explanation?: string | null
      userpoints_value?: number | null
      minimum_passing_score?: number | null
      grading_type?: string | null
      requires_api_completion?: boolean | null
      overridden_by_api?: boolean | null
      overridden_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      checked_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      submission?: {
        __typename?: "UserSubmission"
        id?: number | null
        text?: string | null
        file_url?: string | null
        file_name?: string | null
        file_image?: string | null
      } | null
      feedback_discussion_context?: {
        __typename?: "CompletionFeedbackDiscussionContext"
        id?: number | null
        discussion_id?: number | null
        num_responses?: number | null
      } | null
    } | null
    linked_resource?: {
      __typename?: "Resource"
      id: number
      name: string
      slug: string
      created?: any | null
      resource_type?: number | null
      visibility?: number | null
      description?: string | null
      short_description?: string | null
      background_overlay?: string | null
      background_image?: string | null
      image_raw?: string | null
      has_certificate?: boolean | null
      discussion_enabled?: boolean | null
      lowest_public_offering_price?: number | null
      highest_public_offering_price?: number | null
      licensed_from_resource_id?: number | null
      licensed_from_school_id?: number | null
      created_by_id?: number | null
      image: string
      cover_image: string
      curriculum_group?: { __typename?: "Group"; id: number } | null
    } | null
    linked_cohort?: {
      __typename?: "Group"
      id: number
      user_registration?: {
        __typename?: "Registration"
        id: number
        role?: number | null
        completion_state?: number | null
        send_due_notifications?: boolean | null
        send_discussion_notifications?: boolean | null
        last_activity_time?: any | null
        expiration_dtime?: any | null
        created?: any | null
        path?: { __typename?: "Path"; id: number } | null
        user?: {
          __typename?: "User"
          id?: number | null
          email?: string | null
          first_name?: string | null
          last_name?: string | null
          full_name?: string | null
          display_name?: string | null
          profile?: {
            __typename?: "Profile"
            id?: number | null
            bio?: string | null
            image?: string | null
            location?: string | null
            last_activity?: any | null
            rewardful_referral_id?: string | null
          } | null
        } | null
      } | null
    } | null
    linked_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
      completion_state?: number | null
      send_due_notifications?: boolean | null
      send_discussion_notifications?: boolean | null
      last_activity_time?: any | null
      expiration_dtime?: any | null
      created?: any | null
      path?: { __typename?: "Path"; id: number } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type ContextQueryVariables = Exact<{ [key: string]: never }>

export type ContextQuery = {
  __typename?: "Query"
  school?: {
    __typename?: "School"
    id: number
    url?: string | null
    name: string
    about?: string | null
    email?: string | null
    website?: string | null
    subdomain: string
    created?: any | null
    billing_model?: number | null
    platform_version?: number | null
    currency_code?: string | null
    ietf_language_tag?: string | null
    has_certificate?: boolean | null
    is_activated?: boolean | null
    is_curriculum_enabled?: boolean | null
    is_curriculum_subscriber?: boolean | null
    stripe_access_token?: string | null
    stripe_publishable_api_key?: string | null
    show_stripe_reconnect?: boolean | null
    payment_requires_zip_code?: boolean | null
    consent_description?: string | null
    image?: string | null
    grant_access_upon_authentication?: boolean | null
    pathwright_login_enabled?: boolean | null
    theme?: {
      __typename?: "Theme"
      id?: number | null
      name?: string | null
      theme_type?: ThemeType | null
      background_image?: string | null
      background_overlay?: string | null
      background_type?: number | null
      heading_font?: string | null
      primary_color?: string | null
      typekit_id?: string | null
      custom_css?: string | null
      processed_custom_css?: string | null
      color_palette?: {
        __typename?: "NodeThemeColorPalette"
        _50?: string | null
        _100?: string | null
        _200?: string | null
        _300?: string | null
        _400?: string | null
        _500?: string | null
        _600?: string | null
        _700?: string | null
        _800?: string | null
        _900?: string | null
      } | null
    } | null
    features: { __typename?: "SchoolFeatures"; auth__platform: boolean }
  } | null
  me?: {
    __typename?: "User"
    username?: string | null
    no_password_set?: boolean | null
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    group_role_stats?: Array<{
      __typename?: "GroupRoleStats"
      type?: string | null
      total?: number | null
      role?: number | null
    } | null> | null
    permissions?: {
      __typename?: "ContextPermissions"
      is_school_owner?: boolean | null
      can_administrate?: boolean | null
      can_edit_library?: boolean | null
      has_member_access?: boolean | null
      has_pathwright_staff_access?: boolean | null
    } | null
    membership?: {
      __typename?: "Membership"
      id: number
      created: any
      last_seen?: any | null
      last_active?: any | null
      has_access: boolean
      role: number
      consent_snapshot?: string | null
      consent_dtime?: any | null
    } | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      notification_digest_interval?: NotificationDigestInterval | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      rewardful_referral_id?: string | null
    } | null
  } | null
  translation?: {
    __typename?: "Translation"
    id?: number | null
    name?: string | null
    language?: string | null
    translation?: any | null
    master_translation?: any | null
    is_default?: boolean | null
  } | null
  platformConfig?: {
    __typename?: "PlatformConfig"
    id?: number | null
    payments_maintenance_mode?: boolean | null
  } | null
  integrations?: {
    __typename?: "Integrations"
    firebase?: {
      __typename?: "FirebaseIntegration"
      auth_token?: string | null
      url?: string | null
      client_api_key?: string | null
    } | null
    intercom?: {
      __typename?: "IntercomIntegration"
      app_id?: string | null
      pathwright_app_id?: string | null
      school_app_id?: string | null
      user_hash?: string | null
    } | null
  } | null
}

export type ContextFragment = {
  __typename?: "Query"
  school?: {
    __typename?: "School"
    id: number
    url?: string | null
    name: string
    about?: string | null
    email?: string | null
    website?: string | null
    subdomain: string
    created?: any | null
    billing_model?: number | null
    platform_version?: number | null
    currency_code?: string | null
    ietf_language_tag?: string | null
    has_certificate?: boolean | null
    is_activated?: boolean | null
    is_curriculum_enabled?: boolean | null
    is_curriculum_subscriber?: boolean | null
    stripe_access_token?: string | null
    stripe_publishable_api_key?: string | null
    show_stripe_reconnect?: boolean | null
    payment_requires_zip_code?: boolean | null
    consent_description?: string | null
    image?: string | null
    grant_access_upon_authentication?: boolean | null
    pathwright_login_enabled?: boolean | null
    theme?: {
      __typename?: "Theme"
      id?: number | null
      name?: string | null
      theme_type?: ThemeType | null
      background_image?: string | null
      background_overlay?: string | null
      background_type?: number | null
      heading_font?: string | null
      primary_color?: string | null
      typekit_id?: string | null
      custom_css?: string | null
      processed_custom_css?: string | null
      color_palette?: {
        __typename?: "NodeThemeColorPalette"
        _50?: string | null
        _100?: string | null
        _200?: string | null
        _300?: string | null
        _400?: string | null
        _500?: string | null
        _600?: string | null
        _700?: string | null
        _800?: string | null
        _900?: string | null
      } | null
    } | null
    features: { __typename?: "SchoolFeatures"; auth__platform: boolean }
  } | null
  me?: {
    __typename?: "User"
    username?: string | null
    no_password_set?: boolean | null
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    group_role_stats?: Array<{
      __typename?: "GroupRoleStats"
      type?: string | null
      total?: number | null
      role?: number | null
    } | null> | null
    permissions?: {
      __typename?: "ContextPermissions"
      is_school_owner?: boolean | null
      can_administrate?: boolean | null
      can_edit_library?: boolean | null
      has_member_access?: boolean | null
      has_pathwright_staff_access?: boolean | null
    } | null
    membership?: {
      __typename?: "Membership"
      id: number
      created: any
      last_seen?: any | null
      last_active?: any | null
      has_access: boolean
      role: number
      consent_snapshot?: string | null
      consent_dtime?: any | null
    } | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      notification_digest_interval?: NotificationDigestInterval | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      rewardful_referral_id?: string | null
    } | null
  } | null
  translation?: {
    __typename?: "Translation"
    id?: number | null
    name?: string | null
    language?: string | null
    translation?: any | null
    master_translation?: any | null
    is_default?: boolean | null
  } | null
  platformConfig?: {
    __typename?: "PlatformConfig"
    id?: number | null
    payments_maintenance_mode?: boolean | null
  } | null
  integrations?: {
    __typename?: "Integrations"
    firebase?: {
      __typename?: "FirebaseIntegration"
      auth_token?: string | null
      url?: string | null
      client_api_key?: string | null
    } | null
    intercom?: {
      __typename?: "IntercomIntegration"
      app_id?: string | null
      pathwright_app_id?: string | null
      school_app_id?: string | null
      user_hash?: string | null
    } | null
  } | null
}

export type MeFragment = {
  __typename?: "User"
  username?: string | null
  no_password_set?: boolean | null
  id?: number | null
  email?: string | null
  first_name?: string | null
  last_name?: string | null
  full_name?: string | null
  display_name?: string | null
  group_role_stats?: Array<{
    __typename?: "GroupRoleStats"
    type?: string | null
    total?: number | null
    role?: number | null
  } | null> | null
  permissions?: {
    __typename?: "ContextPermissions"
    is_school_owner?: boolean | null
    can_administrate?: boolean | null
    can_edit_library?: boolean | null
    has_member_access?: boolean | null
    has_pathwright_staff_access?: boolean | null
  } | null
  membership?: {
    __typename?: "Membership"
    id: number
    created: any
    last_seen?: any | null
    last_active?: any | null
    has_access: boolean
    role: number
    consent_snapshot?: string | null
    consent_dtime?: any | null
  } | null
  profile?: {
    __typename?: "Profile"
    id?: number | null
    notification_digest_interval?: NotificationDigestInterval | null
    bio?: string | null
    image?: string | null
    location?: string | null
    last_activity?: any | null
    rewardful_referral_id?: string | null
  } | null
}

export type UpdatePositionedObjectsMutationVariables = Exact<{
  context: PositioningContextInput
  positions:
    | Array<InputMaybe<PositionedObjectPositionInput>>
    | InputMaybe<PositionedObjectPositionInput>
}>

export type UpdatePositionedObjectsMutation = {
  __typename?: "Mutations"
  updatePositionedObjects?: any | null
}

export type __TestRegistrationPositioningQueryVariables = Exact<{
  first: Scalars["Int"]["input"]
  positioningContext?: InputMaybe<PositioningContextInput>
}>

export type __TestRegistrationPositioningQuery = {
  __typename?: "Query"
  me?: {
    __typename?: "User"
    id?: number | null
    registrations?: {
      __typename?: "RegistrationConnection"
      edges?: Array<{
        __typename?: "RegistrationEdge"
        node?: {
          __typename?: "Registration"
          id: number
          position?: string | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type __TestRegistrationFragment = {
  __typename?: "Registration"
  id: number
  position?: string | null
}

export type UpdateReferralMutationVariables = Exact<{
  referral: Scalars["String"]["input"]
}>

export type UpdateReferralMutation = {
  __typename?: "Mutations"
  updateReferral?: { __typename?: "Referral"; referral?: string | null } | null
}

export type RegistrationFragment = {
  __typename?: "Registration"
  id: number
  role?: number | null
  completion_state?: number | null
  send_due_notifications?: boolean | null
  send_discussion_notifications?: boolean | null
  last_activity_time?: any | null
  expiration_dtime?: any | null
  created?: any | null
  user?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      rewardful_referral_id?: string | null
    } | null
  } | null
}

export type RegistrationQueryVariables = Exact<{
  group_id: Scalars["Int"]["input"]
  registration_id?: InputMaybe<Scalars["Int"]["input"]>
}>

export type RegistrationQuery = {
  __typename?: "Query"
  group?: {
    __typename?: "Group"
    id: number
    name: string
    created?: any | null
    description?: string | null
    price?: number | null
    seats?: number | null
    seats_filled?: number | null
    seats_available?: number | null
    registration_ends?: any | null
    registration_starts?: any | null
    registration_started?: boolean | null
    registration_ended?: boolean | null
    access_ends?: any | null
    access_starts?: any | null
    is_master?: boolean | null
    is_source_cohort?: boolean | null
    source_cohort_id?: number | null
    parent_section_id?: number | null
    visibility?: number | null
    is_collection_linked?: boolean | null
    is_subscription_only?: boolean | null
    status?: GroupStatus | null
    analytics_disabled?: boolean | null
    created_by_id?: number | null
    permissions?: {
      __typename?: "GroupPermissions"
      can_invite_observer?: boolean | null
      can_invite_student?: boolean | null
      can_invite_moderator?: boolean | null
      can_invite_teacher?: boolean | null
      can_invite_editor?: boolean | null
    } | null
    registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
      completion_state?: number | null
      send_due_notifications?: boolean | null
      send_discussion_notifications?: boolean | null
      last_activity_time?: any | null
      expiration_dtime?: any | null
      created?: any | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type RegistrationsQueryVariables = Exact<{
  groupId: Scalars["Int"]["input"]
  roleFilter?: InputMaybe<RoleFilter>
  dateLastActive?: InputMaybe<DateFilter>
  dateJoined?: InputMaybe<DateFilter>
  orderBy?: InputMaybe<
    | Array<InputMaybe<RegistrationOrderByInput>>
    | InputMaybe<RegistrationOrderByInput>
  >
  cursor?: InputMaybe<Scalars["String"]["input"]>
}>

export type RegistrationsQuery = {
  __typename?: "Query"
  group?: {
    __typename?: "Group"
    id: number
    name: string
    created?: any | null
    description?: string | null
    price?: number | null
    seats?: number | null
    seats_filled?: number | null
    seats_available?: number | null
    registration_ends?: any | null
    registration_starts?: any | null
    registration_started?: boolean | null
    registration_ended?: boolean | null
    access_ends?: any | null
    access_starts?: any | null
    is_master?: boolean | null
    is_source_cohort?: boolean | null
    source_cohort_id?: number | null
    parent_section_id?: number | null
    visibility?: number | null
    is_collection_linked?: boolean | null
    is_subscription_only?: boolean | null
    status?: GroupStatus | null
    analytics_disabled?: boolean | null
    created_by_id?: number | null
    resource?: {
      __typename?: "Resource"
      id: number
      name: string
      slug: string
      created?: any | null
      resource_type?: number | null
      visibility?: number | null
      description?: string | null
      short_description?: string | null
      background_overlay?: string | null
      background_image?: string | null
      image_raw?: string | null
      has_certificate?: boolean | null
      discussion_enabled?: boolean | null
      lowest_public_offering_price?: number | null
      highest_public_offering_price?: number | null
      licensed_from_resource_id?: number | null
      licensed_from_school_id?: number | null
      created_by_id?: number | null
      image: string
      cover_image: string
    } | null
    registrations?: {
      __typename?: "RegistrationConnection"
      total: number
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
      edges?: Array<{
        __typename?: "RegistrationEdge"
        node?: {
          __typename?: "Registration"
          id: number
          role?: number | null
          completion_state?: number | null
          send_due_notifications?: boolean | null
          send_discussion_notifications?: boolean | null
          last_activity_time?: any | null
          expiration_dtime?: any | null
          created?: any | null
          user?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              rewardful_referral_id?: string | null
            } | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type UserProfileRegistrationsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  id?: InputMaybe<Scalars["Int"]["input"]>
  role_filter?: InputMaybe<RoleFilter>
  progress_filter?: InputMaybe<ProgressFilter>
  parent_cohort_filter?: InputMaybe<IdFilter>
  hasParentRegistration?: InputMaybe<Scalars["Boolean"]["input"]>
  orderBy?: InputMaybe<
    | Array<InputMaybe<RegistrationOrderByInput>>
    | InputMaybe<RegistrationOrderByInput>
  >
}>

export type UserProfileRegistrationsQuery = {
  __typename?: "Query"
  user?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    registrations?: {
      __typename?: "RegistrationConnection"
      total: number
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
      edges?: Array<{
        __typename?: "RegistrationEdge"
        node?: {
          __typename?: "Registration"
          id: number
          role?: number | null
          completion_state?: number | null
          send_due_notifications?: boolean | null
          send_discussion_notifications?: boolean | null
          last_activity_time?: any | null
          expiration_dtime?: any | null
          created?: any | null
          resource?: {
            __typename?: "Resource"
            id: number
            name: string
            slug: string
            created?: any | null
            resource_type?: number | null
            visibility?: number | null
            description?: string | null
            short_description?: string | null
            background_overlay?: string | null
            background_image?: string | null
            image_raw?: string | null
            has_certificate?: boolean | null
            discussion_enabled?: boolean | null
            lowest_public_offering_price?: number | null
            highest_public_offering_price?: number | null
            licensed_from_resource_id?: number | null
            licensed_from_school_id?: number | null
            created_by_id?: number | null
            image: string
            cover_image: string
          } | null
          group?: {
            __typename?: "Group"
            id: number
            name: string
            created?: any | null
            description?: string | null
            price?: number | null
            seats?: number | null
            seats_filled?: number | null
            seats_available?: number | null
            registration_ends?: any | null
            registration_starts?: any | null
            registration_started?: boolean | null
            registration_ended?: boolean | null
            access_ends?: any | null
            access_starts?: any | null
            is_master?: boolean | null
            is_source_cohort?: boolean | null
            source_cohort_id?: number | null
            parent_section_id?: number | null
            visibility?: number | null
            is_collection_linked?: boolean | null
            is_subscription_only?: boolean | null
            status?: GroupStatus | null
            analytics_disabled?: boolean | null
            created_by_id?: number | null
          } | null
          path?: {
            __typename?: "Path"
            id: number
            progress?: number | null
            completion_date?: any | null
          } | null
          user?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              rewardful_referral_id?: string | null
            } | null
          } | null
        } | null
      } | null> | null
    } | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      rewardful_referral_id?: string | null
    } | null
  } | null
}

export type ResourceFragment = {
  __typename?: "Resource"
  id: number
  name: string
  slug: string
  created?: any | null
  resource_type?: number | null
  visibility?: number | null
  description?: string | null
  short_description?: string | null
  background_overlay?: string | null
  background_image?: string | null
  image_raw?: string | null
  has_certificate?: boolean | null
  discussion_enabled?: boolean | null
  lowest_public_offering_price?: number | null
  highest_public_offering_price?: number | null
  licensed_from_resource_id?: number | null
  licensed_from_school_id?: number | null
  created_by_id?: number | null
  image: string
  cover_image: string
}

export type ResourceCopyConfigQueryVariables = Exact<{
  key: Scalars["String"]["input"]
}>

export type ResourceCopyConfigQuery = {
  __typename?: "Query"
  resourceCopyConfig?: {
    __typename?: "ResourceCopyConfig"
    id: number
    name?: string | null
    lookup_key: string
    children?: Array<{
      __typename?: "ResourceCopyConfig"
      id: number
      name?: string | null
      lookup_key: string
      children?: Array<{
        __typename?: "ResourceCopyConfig"
        id: number
        name?: string | null
        lookup_key: string
        children?: Array<{
          __typename?: "ResourceCopyConfig"
          id: number
          name?: string | null
          lookup_key: string
          resource?: {
            __typename?: "ResourceCopyConfigResource"
            id: number
            meta: {
              __typename?: "NodeMeta"
              id: string
              type: string
              key: string
              title?: string | null
              image?: { __typename?: "NodeImage"; src?: string | null } | null
            }
          } | null
        } | null> | null
        resource?: {
          __typename?: "ResourceCopyConfigResource"
          id: number
          meta: {
            __typename?: "NodeMeta"
            id: string
            type: string
            key: string
            title?: string | null
            image?: { __typename?: "NodeImage"; src?: string | null } | null
          }
        } | null
      } | null> | null
      resource?: {
        __typename?: "ResourceCopyConfigResource"
        id: number
        meta: {
          __typename?: "NodeMeta"
          id: string
          type: string
          key: string
          title?: string | null
          image?: { __typename?: "NodeImage"; src?: string | null } | null
        }
      } | null
    } | null> | null
    resource?: {
      __typename?: "ResourceCopyConfigResource"
      id: number
      meta: {
        __typename?: "NodeMeta"
        id: string
        type: string
        key: string
        title?: string | null
        image?: { __typename?: "NodeImage"; src?: string | null } | null
      }
    } | null
  } | null
}

export type ResourceCopyConfigConnectionsQueryVariables = Exact<{
  first: Scalars["Int"]["input"]
  keys:
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
}>

export type ResourceCopyConfigConnectionsQuery = {
  __typename?: "Query"
  resourceCopyConfigConnections?: {
    __typename?: "ResourceCopyConfigConnection"
    edges?: Array<{
      __typename?: "ResourceCopyConfigEdge"
      node?: {
        __typename?: "ResourceCopyConfig"
        id: number
        name?: string | null
        lookup_key: string
        children?: Array<{
          __typename?: "ResourceCopyConfig"
          id: number
          name?: string | null
          lookup_key: string
          children?: Array<{
            __typename?: "ResourceCopyConfig"
            id: number
            name?: string | null
            lookup_key: string
            children?: Array<{
              __typename?: "ResourceCopyConfig"
              id: number
              name?: string | null
              lookup_key: string
              resource?: {
                __typename?: "ResourceCopyConfigResource"
                id: number
                meta: {
                  __typename?: "NodeMeta"
                  id: string
                  type: string
                  key: string
                  title?: string | null
                  image?: {
                    __typename?: "NodeImage"
                    src?: string | null
                  } | null
                }
              } | null
            } | null> | null
            resource?: {
              __typename?: "ResourceCopyConfigResource"
              id: number
              meta: {
                __typename?: "NodeMeta"
                id: string
                type: string
                key: string
                title?: string | null
                image?: { __typename?: "NodeImage"; src?: string | null } | null
              }
            } | null
          } | null> | null
          resource?: {
            __typename?: "ResourceCopyConfigResource"
            id: number
            meta: {
              __typename?: "NodeMeta"
              id: string
              type: string
              key: string
              title?: string | null
              image?: { __typename?: "NodeImage"; src?: string | null } | null
            }
          } | null
        } | null> | null
        resource?: {
          __typename?: "ResourceCopyConfigResource"
          id: number
          meta: {
            __typename?: "NodeMeta"
            id: string
            type: string
            key: string
            title?: string | null
            image?: { __typename?: "NodeImage"; src?: string | null } | null
          }
        } | null
      } | null
    } | null> | null
  } | null
}

export type ResourceCopyConfigFragment = {
  __typename?: "ResourceCopyConfig"
  id: number
  name?: string | null
  lookup_key: string
  children?: Array<{
    __typename?: "ResourceCopyConfig"
    id: number
    name?: string | null
    lookup_key: string
    children?: Array<{
      __typename?: "ResourceCopyConfig"
      id: number
      name?: string | null
      lookup_key: string
      children?: Array<{
        __typename?: "ResourceCopyConfig"
        id: number
        name?: string | null
        lookup_key: string
        resource?: {
          __typename?: "ResourceCopyConfigResource"
          id: number
          meta: {
            __typename?: "NodeMeta"
            id: string
            type: string
            key: string
            title?: string | null
            image?: { __typename?: "NodeImage"; src?: string | null } | null
          }
        } | null
      } | null> | null
      resource?: {
        __typename?: "ResourceCopyConfigResource"
        id: number
        meta: {
          __typename?: "NodeMeta"
          id: string
          type: string
          key: string
          title?: string | null
          image?: { __typename?: "NodeImage"; src?: string | null } | null
        }
      } | null
    } | null> | null
    resource?: {
      __typename?: "ResourceCopyConfigResource"
      id: number
      meta: {
        __typename?: "NodeMeta"
        id: string
        type: string
        key: string
        title?: string | null
        image?: { __typename?: "NodeImage"; src?: string | null } | null
      }
    } | null
  } | null> | null
  resource?: {
    __typename?: "ResourceCopyConfigResource"
    id: number
    meta: {
      __typename?: "NodeMeta"
      id: string
      type: string
      key: string
      title?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
  } | null
}

export type ResourceCopyConfigPartialFragment = {
  __typename?: "ResourceCopyConfig"
  id: number
  name?: string | null
  lookup_key: string
  resource?: {
    __typename?: "ResourceCopyConfigResource"
    id: number
    meta: {
      __typename?: "NodeMeta"
      id: string
      type: string
      key: string
      title?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
  } | null
}

export type SchoolFragment = {
  __typename?: "School"
  id: number
  url?: string | null
  name: string
  about?: string | null
  email?: string | null
  website?: string | null
  subdomain: string
  created?: any | null
  billing_model?: number | null
  platform_version?: number | null
  currency_code?: string | null
  ietf_language_tag?: string | null
  has_certificate?: boolean | null
  is_activated?: boolean | null
  is_curriculum_enabled?: boolean | null
  is_curriculum_subscriber?: boolean | null
  stripe_access_token?: string | null
  stripe_publishable_api_key?: string | null
  show_stripe_reconnect?: boolean | null
  payment_requires_zip_code?: boolean | null
  consent_description?: string | null
  image?: string | null
  grant_access_upon_authentication?: boolean | null
  pathwright_login_enabled?: boolean | null
}

export type ThemeFragment = {
  __typename?: "Theme"
  id?: number | null
  name?: string | null
  theme_type?: ThemeType | null
  background_image?: string | null
  background_overlay?: string | null
  background_type?: number | null
  heading_font?: string | null
  primary_color?: string | null
  typekit_id?: string | null
  custom_css?: string | null
  processed_custom_css?: string | null
  color_palette?: {
    __typename?: "NodeThemeColorPalette"
    _50?: string | null
    _100?: string | null
    _200?: string | null
    _300?: string | null
    _400?: string | null
    _500?: string | null
    _600?: string | null
    _700?: string | null
    _800?: string | null
    _900?: string | null
  } | null
}

export type BlurredThemeBackgroundImageQueryVariables = Exact<{
  url: Scalars["String"]["input"]
  width?: InputMaybe<Scalars["Int"]["input"]>
  height?: InputMaybe<Scalars["Int"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  high?: InputMaybe<Scalars["Int"]["input"]>
  blend?: InputMaybe<Scalars["Int"]["input"]>
  balph?: InputMaybe<Scalars["Int"]["input"]>
  fm?: InputMaybe<Scalars["String"]["input"]>
  q?: InputMaybe<Scalars["Int"]["input"]>
  bm?: InputMaybe<Scalars["String"]["input"]>
}>

export type BlurredThemeBackgroundImageQuery = {
  __typename?: "Query"
  media?: { __typename?: "Media"; image?: string | null } | null
}

export type CreatedSpaceFragment = {
  __typename?: "School"
  id: number
  name: string
  sso_link?: string | null
  profile?: { __typename?: "SchoolProfile"; id?: number | null } | null
}

export type CreateSpaceMutationVariables = Exact<{
  name: Scalars["String"]["input"]
  subdomain: Scalars["String"]["input"]
  copy?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >
  utm_source?: InputMaybe<Scalars["String"]["input"]>
  utm_medium?: InputMaybe<Scalars["String"]["input"]>
  utm_campaign?: InputMaybe<Scalars["String"]["input"]>
  theme?: InputMaybe<ThemeInput>
}>

export type CreateSpaceMutation = {
  __typename?: "Mutations"
  createSchool?: {
    __typename?: "School"
    id: number
    name: string
    sso_link?: string | null
    profile?: { __typename?: "SchoolProfile"; id?: number | null } | null
  } | null
}

export type DeferOrExecuteResourceCopyConfigMutationVariables = Exact<{
  copyCode: Scalars["String"]["input"]
  targetSchoolId: Scalars["Int"]["input"]
}>

export type DeferOrExecuteResourceCopyConfigMutation = {
  __typename?: "Mutations"
  deferOrExecuteResourceCopyConfig?: {
    __typename?: "DeferOrExecuteResourceCopyConfigResult"
    defer: any
    execute: any
  } | null
}

export type SpaceBillingPlanSetQueryVariables = Exact<{ [key: string]: never }>

export type SpaceBillingPlanSetQuery = {
  __typename?: "Query"
  account?: {
    __typename?: "SchoolAcount"
    id?: number | null
    billing_plan_set?: {
      __typename?: "SchoolBillingPlanSet"
      id?: number | null
      name?: string | null
      is_default?: boolean | null
      plans?: Array<{
        __typename?: "SchoolBillingPlan"
        id?: number | null
        name?: string | null
      } | null> | null
    } | null
  } | null
}

export type SpaceBillingPlanSetPlanFragment = {
  __typename?: "SchoolBillingPlan"
  id?: number | null
  name?: string | null
}

export type SpaceSsoLinkQueryVariables = Exact<{
  spaceId: Scalars["Int"]["input"]
  pathname: Scalars["String"]["input"]
}>

export type SpaceSsoLinkQuery = {
  __typename?: "Query"
  context?: {
    __typename?: "AccountContext"
    school: {
      __typename?: "School"
      id: number
      url?: string | null
      sso_link?: string | null
    }
  } | null
}

export type SubdomainQueryVariables = Exact<{
  subdomain?: InputMaybe<Scalars["String"]["input"]>
}>

export type SubdomainQuery = {
  __typename?: "Query"
  subdomain?: {
    __typename?: "Subdomain"
    id?: number | null
    subdomain?: string | null
  } | null
}

export type UpdateSpaceProfileMutationVariables = Exact<{
  id: Scalars["Int"]["input"]
  school_type?: InputMaybe<SchoolType>
  plan_to_sell?: InputMaybe<Scalars["Boolean"]["input"]>
}>

export type UpdateSpaceProfileMutation = {
  __typename?: "Mutations"
  updateSchoolProfile?: {
    __typename?: "SchoolProfile"
    id?: number | null
    school_type?: SchoolType | null
    plan_to_sell?: boolean | null
  } | null
}

export type UserCreatedSpaceMembershipsQueryVariables = Exact<{
  [key: string]: never
}>

export type UserCreatedSpaceMembershipsQuery = {
  __typename?: "Query"
  me?: {
    __typename?: "User"
    id?: number | null
    memberships?: {
      __typename?: "MembershipConnection"
      edges?: Array<{
        __typename?: "MembershipEdge"
        node?: {
          __typename?: "Membership"
          id: number
          school: {
            __typename?: "School"
            subdomain: string
            id: number
            name: string
            sso_link?: string | null
            profile?: {
              __typename?: "SchoolProfile"
              id?: number | null
            } | null
          }
        } | null
      } | null> | null
    } | null
  } | null
}

export type UserSpaceMembershipFragment = {
  __typename?: "Membership"
  id: number
  role: number
  school: {
    __typename?: "School"
    id: number
    name: string
    image?: string | null
    sso_link?: string | null
  }
}

export type UserSpaceMembershipsQueryVariables = Exact<{ [key: string]: never }>

export type UserSpaceMembershipsQuery = {
  __typename?: "Query"
  me?: {
    __typename?: "User"
    id?: number | null
    memberships?: {
      __typename?: "MembershipConnection"
      edges?: Array<{
        __typename?: "MembershipEdge"
        node?: {
          __typename?: "Membership"
          id: number
          role: number
          school: {
            __typename?: "School"
            id: number
            name: string
            image?: string | null
            sso_link?: string | null
          }
        } | null
      } | null> | null
    } | null
  } | null
}

export type ScheduleItemMutationVariables = Exact<{
  schedule_context: ScheduleItemContext
  starts?: InputMaybe<Scalars["DateTime"]["input"]>
  due?: InputMaybe<Scalars["DateTime"]["input"]>
  passive_starts?: InputMaybe<Scalars["Boolean"]["input"]>
  passive_due?: InputMaybe<Scalars["Boolean"]["input"]>
}>

export type ScheduleItemMutation = {
  __typename?: "Mutations"
  scheduleItem?: {
    __typename?: "PathItem"
    id: number
    order: number
    verb?: string | null
    name: string
    type: PathItemType
    path_id: number
    source_id: number
    parent_id?: number | null
    parent_source_id?: number | null
    description?: string | null
    curriculum_sync_opt_out_dtime?: any | null
    assignment_type?: PathItemAssignmentType | null
    content_id?: number | null
    content_type?: string | null
    item_content_type_id?: number | null
    is_required?: boolean | null
    is_previewable?: boolean | null
    due?: any | null
    passive_due?: boolean | null
    starts?: any | null
    passive_starts?: boolean | null
    has_started?: boolean | null
    points?: number | null
    item_needs_grading?: boolean | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    time_to_complete?: number | null
    lock_password?: string | null
    is_locked?: boolean | null
    lock_unlocked_time?: any | null
    allow_resets?: number | null
    show_grading_feedback?: number | null
    completion_comment_required_status?: string | null
    last_published_dtime?: any | null
    progress: number
    context?: {
      __typename?: "StepContext"
      resource_id?: number | null
      cohort_id?: number | null
    } | null
    blocks?: {
      __typename?: "BlocksContent"
      content_last_modified?: any | null
      last_published?: any | null
    } | null
    permissions?: {
      __typename?: "PathItemPermissions"
      can_complete?: boolean | null
      can_discuss?: boolean | null
      can_edit?: boolean | null
      can_edit_content?: boolean | null
      can_note?: boolean | null
      can_view?: boolean | null
      can_review?: boolean | null
      can_view_content?: boolean | null
    } | null
    completion?: {
      __typename?: "Completion"
      id?: number | null
      checked_date?: any | null
      completion_attempts?: number | null
      completion_comment?: string | null
      completion_date?: any | null
      completion_due?: any | null
      last_completion_attempt_date?: any | null
      has_failed_passing_attempt?: boolean | null
      has_points?: boolean | null
      is_late?: boolean | null
      is_graded?: boolean | null
      is_skipped?: boolean | null
      is_complete?: boolean | null
      is_auto_graded?: boolean | null
      is_forced_completion?: boolean | null
      needs_grading?: boolean | null
      userpoints_earned?: number | null
      userpoints_explanation?: string | null
      userpoints_value?: number | null
      minimum_passing_score?: number | null
      grading_type?: string | null
      requires_api_completion?: boolean | null
      overridden_by_api?: boolean | null
      overridden_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      checked_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
      submission?: {
        __typename?: "UserSubmission"
        id?: number | null
        text?: string | null
        file_url?: string | null
        file_name?: string | null
        file_image?: string | null
      } | null
      feedback_discussion_context?: {
        __typename?: "CompletionFeedbackDiscussionContext"
        id?: number | null
        discussion_id?: number | null
        num_responses?: number | null
      } | null
    } | null
    linked_resource?: {
      __typename?: "Resource"
      id: number
      name: string
      slug: string
      created?: any | null
      resource_type?: number | null
      visibility?: number | null
      description?: string | null
      short_description?: string | null
      background_overlay?: string | null
      background_image?: string | null
      image_raw?: string | null
      has_certificate?: boolean | null
      discussion_enabled?: boolean | null
      lowest_public_offering_price?: number | null
      highest_public_offering_price?: number | null
      licensed_from_resource_id?: number | null
      licensed_from_school_id?: number | null
      created_by_id?: number | null
      image: string
      cover_image: string
      curriculum_group?: { __typename?: "Group"; id: number } | null
    } | null
    linked_cohort?: {
      __typename?: "Group"
      id: number
      user_registration?: {
        __typename?: "Registration"
        id: number
        role?: number | null
        completion_state?: number | null
        send_due_notifications?: boolean | null
        send_discussion_notifications?: boolean | null
        last_activity_time?: any | null
        expiration_dtime?: any | null
        created?: any | null
        path?: { __typename?: "Path"; id: number } | null
        user?: {
          __typename?: "User"
          id?: number | null
          email?: string | null
          first_name?: string | null
          last_name?: string | null
          full_name?: string | null
          display_name?: string | null
          profile?: {
            __typename?: "Profile"
            id?: number | null
            bio?: string | null
            image?: string | null
            location?: string | null
            last_activity?: any | null
            rewardful_referral_id?: string | null
          } | null
        } | null
      } | null
    } | null
    linked_registration?: {
      __typename?: "Registration"
      id: number
      role?: number | null
      completion_state?: number | null
      send_due_notifications?: boolean | null
      send_discussion_notifications?: boolean | null
      last_activity_time?: any | null
      expiration_dtime?: any | null
      created?: any | null
      path?: { __typename?: "Path"; id: number } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          rewardful_referral_id?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type SchoolSubscriptionFragment = {
  __typename?: "SchoolSubscription"
  id?: number | null
  user?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      rewardful_referral_id?: string | null
    } | null
  } | null
  subscription?: {
    __typename?: "Subscription"
    id?: number | null
    quantity?: number | null
    canceled_dtime?: any | null
    cancellation_reason?: number | null
    card_last_4?: string | null
    cycle_end_dtime?: any | null
    cycle_start_dtime?: any | null
    is_canceled_at_cycle_end?: boolean | null
    is_delinquent?: boolean | null
    paused_until_dtime?: any | null
    trial_type?: number | null
    trial_end_dtime?: any | null
    payment_intent_needing_action_id?: string | null
    subscription_plan?: {
      __typename?: "SubscriptionPlan"
      id?: number | null
      name?: string | null
      amount?: number | null
      interval?: number | null
      trial_period_days?: number | null
      trial_type?: number | null
      plan_type?: string | null
    } | null
    customer?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        rewardful_referral_id?: string | null
      } | null
    } | null
  } | null
  subscription_plan?: {
    __typename?: "SubscriptionPlan"
    id?: number | null
    name?: string | null
    amount?: number | null
    interval?: number | null
    trial_period_days?: number | null
    trial_type?: number | null
    plan_type?: string | null
  } | null
  school_subscription_plan?: {
    __typename?: "SchoolSubscriptionPlan"
    id?: number | null
    image?: string | null
    details_html?: string | null
    subscribe_complete_html?: string | null
    catalog_header?: string | null
    is_visible?: boolean | null
    giftable_cycles?: Array<number | null> | null
    subscription_plan?: {
      __typename?: "SubscriptionPlan"
      id?: number | null
      name?: string | null
      amount?: number | null
      interval?: number | null
      trial_period_days?: number | null
      trial_type?: number | null
      plan_type?: string | null
    } | null
  } | null
}

export type SchoolSubscriptionPlanFragment = {
  __typename?: "SchoolSubscriptionPlan"
  id?: number | null
  image?: string | null
  details_html?: string | null
  subscribe_complete_html?: string | null
  catalog_header?: string | null
  is_visible?: boolean | null
  giftable_cycles?: Array<number | null> | null
  subscription_plan?: {
    __typename?: "SubscriptionPlan"
    id?: number | null
    name?: string | null
    amount?: number | null
    interval?: number | null
    trial_period_days?: number | null
    trial_type?: number | null
    plan_type?: string | null
  } | null
}

export type SchoolSubscriptionPlansQueryVariables = Exact<{
  ids?: InputMaybe<IdFilter>
  visibility_code?: InputMaybe<Scalars["String"]["input"]>
  visibility_code_filter?: InputMaybe<StringFilter>
  ignore_visibility?: InputMaybe<Scalars["Boolean"]["input"]>
  giftable?: InputMaybe<Scalars["Boolean"]["input"]>
  interval?: InputMaybe<SubscriptionIntervalType>
  intervalFilter?: InputMaybe<SubscriptionIntervalFilter>
  positioningContext?: InputMaybe<PositioningContextInput>
  orderBy?: InputMaybe<
    | Array<InputMaybe<SchoolSubscriptionPlanOrderByInput>>
    | InputMaybe<SchoolSubscriptionPlanOrderByInput>
  >
}>

export type SchoolSubscriptionPlansQuery = {
  __typename?: "Query"
  school?: {
    __typename?: "School"
    id: number
    url?: string | null
    name: string
    about?: string | null
    email?: string | null
    website?: string | null
    subdomain: string
    created?: any | null
    billing_model?: number | null
    platform_version?: number | null
    currency_code?: string | null
    ietf_language_tag?: string | null
    has_certificate?: boolean | null
    is_activated?: boolean | null
    is_curriculum_enabled?: boolean | null
    is_curriculum_subscriber?: boolean | null
    stripe_access_token?: string | null
    stripe_publishable_api_key?: string | null
    show_stripe_reconnect?: boolean | null
    payment_requires_zip_code?: boolean | null
    consent_description?: string | null
    image?: string | null
    grant_access_upon_authentication?: boolean | null
    pathwright_login_enabled?: boolean | null
    subscription_plans?: {
      __typename?: "SchoolSubscriptionPlanConnection"
      total: number
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
      edges?: Array<{
        __typename?: "SchoolSubscriptionPlanEdge"
        node?: {
          __typename?: "SchoolSubscriptionPlan"
          position?: string | null
          id?: number | null
          image?: string | null
          details_html?: string | null
          subscribe_complete_html?: string | null
          catalog_header?: string | null
          is_visible?: boolean | null
          giftable_cycles?: Array<number | null> | null
          subscription_plan?: {
            __typename?: "SubscriptionPlan"
            id?: number | null
            name?: string | null
            amount?: number | null
            interval?: number | null
            trial_period_days?: number | null
            trial_type?: number | null
            plan_type?: string | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type PositionedSchoolSubscriptionPlanFragment = {
  __typename?: "SchoolSubscriptionPlan"
  position?: string | null
  id?: number | null
  image?: string | null
  details_html?: string | null
  subscribe_complete_html?: string | null
  catalog_header?: string | null
  is_visible?: boolean | null
  giftable_cycles?: Array<number | null> | null
  subscription_plan?: {
    __typename?: "SubscriptionPlan"
    id?: number | null
    name?: string | null
    amount?: number | null
    interval?: number | null
    trial_period_days?: number | null
    trial_type?: number | null
    plan_type?: string | null
  } | null
}

export type SubscriptionFragment = {
  __typename?: "Subscription"
  id?: number | null
  quantity?: number | null
  canceled_dtime?: any | null
  cancellation_reason?: number | null
  card_last_4?: string | null
  cycle_end_dtime?: any | null
  cycle_start_dtime?: any | null
  is_canceled_at_cycle_end?: boolean | null
  is_delinquent?: boolean | null
  paused_until_dtime?: any | null
  trial_type?: number | null
  trial_end_dtime?: any | null
  payment_intent_needing_action_id?: string | null
  subscription_plan?: {
    __typename?: "SubscriptionPlan"
    id?: number | null
    name?: string | null
    amount?: number | null
    interval?: number | null
    trial_period_days?: number | null
    trial_type?: number | null
    plan_type?: string | null
  } | null
  customer?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      rewardful_referral_id?: string | null
    } | null
  } | null
}

export type SubscriptionPlanFragment = {
  __typename?: "SubscriptionPlan"
  id?: number | null
  name?: string | null
  amount?: number | null
  interval?: number | null
  trial_period_days?: number | null
  trial_type?: number | null
  plan_type?: string | null
}

export type TagAttachmentFragment = {
  __typename?: "TagAttachment"
  id?: number | null
  position?: string | null
  resource_id?: number | null
}

export type TagFragment = {
  __typename?: "Tag"
  id?: number | null
  name?: string | null
  slug?: string | null
  description?: string | null
  color?: string | null
}

export type TagLinkFragment = {
  __typename?: "TagLink"
  id?: number | null
  tag_id?: number | null
  discussion_context_id?: number | null
}

export type TranslationFragment = {
  __typename?: "Translation"
  id?: number | null
  name?: string | null
  language?: string | null
  translation?: any | null
  master_translation?: any | null
  is_default?: boolean | null
}

export type SoftArchiveRegistrationMutationVariables = Exact<{
  id: Scalars["Int"]["input"]
  softArchivedTime?: InputMaybe<Scalars["DateTime"]["input"]>
  sendDiscussionNotifications?: InputMaybe<Scalars["Boolean"]["input"]>
  sendDueNotifications?: InputMaybe<Scalars["Boolean"]["input"]>
}>

export type SoftArchiveRegistrationMutation = {
  __typename?: "Mutations"
  updateRegistration?: {
    __typename?: "Registration"
    id: number
    role?: number | null
    completion_state?: number | null
    send_due_notifications?: boolean | null
    send_discussion_notifications?: boolean | null
    softArchivedTime?: any | null
    last_activity_time?: any | null
    expiration_dtime?: any | null
    created?: any | null
    position?: string | null
    user?: {
      __typename?: "User"
      id?: number | null
      display_name?: string | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        image?: string | null
      } | null
    } | null
    resource?: {
      __typename?: "Resource"
      id: number
      name: string
      slug: string
      created?: any | null
      resource_type?: number | null
      visibility?: number | null
      description?: string | null
      short_description?: string | null
      background_overlay?: string | null
      background_image?: string | null
      image_raw?: string | null
      has_certificate?: boolean | null
      discussion_enabled?: boolean | null
      lowest_public_offering_price?: number | null
      highest_public_offering_price?: number | null
      licensed_from_resource_id?: number | null
      licensed_from_school_id?: number | null
      created_by_id?: number | null
      cover_image: string
      source_cohort?: {
        __typename?: "Group"
        id: number
        created_by_id?: number | null
        is_master?: boolean | null
        is_source_cohort?: boolean | null
        resource?: {
          __typename?: "Resource"
          name: string
          slug: string
        } | null
      } | null
      groups?: {
        __typename?: "GroupConnection"
        total: number
        edges?: Array<{
          __typename?: "GroupEdge"
          node?: {
            __typename?: "Group"
            id: number
            is_master?: boolean | null
            is_source_cohort?: boolean | null
            name: string
          } | null
        } | null> | null
      } | null
    } | null
    group?: {
      __typename?: "Group"
      id: number
      name: string
      created?: any | null
      description?: string | null
      price?: number | null
      seats?: number | null
      seats_filled?: number | null
      seats_available?: number | null
      registration_ends?: any | null
      registration_starts?: any | null
      registration_started?: boolean | null
      registration_ended?: boolean | null
      resource_id?: number | null
      access_ends?: any | null
      access_starts?: any | null
      is_master?: boolean | null
      is_source_cohort?: boolean | null
      source_cohort_id?: number | null
      parent_section_id?: number | null
      visibility?: number | null
      is_collection_linked?: boolean | null
      is_subscription_only?: boolean | null
      status?: GroupStatus | null
      analytics_disabled?: boolean | null
      resource_license?: {
        __typename?: "ResourceLicense"
        expiration_dtime?: any | null
        id?: number | null
      } | null
    } | null
    path?: {
      __typename?: "Path"
      id: number
      progress?: number | null
      completion_date?: any | null
    } | null
  } | null
}

export type UnifiedHomeCohortMembersQueryVariables = Exact<{
  groupId: Scalars["Int"]["input"]
}>

export type UnifiedHomeCohortMembersQuery = {
  __typename?: "Query"
  group?: {
    __typename?: "Group"
    id: number
    name: string
    registrations?: {
      __typename?: "RegistrationConnection"
      total: number
      edges?: Array<{
        __typename?: "RegistrationEdge"
        node?: {
          __typename?: "Registration"
          id: number
          user?: {
            __typename?: "User"
            id?: number | null
            full_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              image?: string | null
            } | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type UnifiedHomeRegistrationsQueryVariables = Exact<{
  first: Scalars["Int"]["input"]
  positioningContext: PositioningContextInput
  orderBy:
    | Array<InputMaybe<RegistrationOrderByInput>>
    | InputMaybe<RegistrationOrderByInput>
  softArchived?: InputMaybe<DateFilter>
}>

export type UnifiedHomeRegistrationsQuery = {
  __typename?: "Query"
  me?: {
    __typename?: "User"
    id?: number | null
    registrations?: {
      __typename?: "RegistrationConnection"
      edges?: Array<{
        __typename?: "RegistrationEdge"
        node?: {
          __typename?: "Registration"
          id: number
          role?: number | null
          completion_state?: number | null
          send_due_notifications?: boolean | null
          send_discussion_notifications?: boolean | null
          softArchivedTime?: any | null
          last_activity_time?: any | null
          expiration_dtime?: any | null
          created?: any | null
          position?: string | null
          user?: {
            __typename?: "User"
            id?: number | null
            display_name?: string | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              image?: string | null
            } | null
          } | null
          resource?: {
            __typename?: "Resource"
            id: number
            name: string
            slug: string
            created?: any | null
            resource_type?: number | null
            visibility?: number | null
            description?: string | null
            short_description?: string | null
            background_overlay?: string | null
            background_image?: string | null
            image_raw?: string | null
            has_certificate?: boolean | null
            discussion_enabled?: boolean | null
            lowest_public_offering_price?: number | null
            highest_public_offering_price?: number | null
            licensed_from_resource_id?: number | null
            licensed_from_school_id?: number | null
            created_by_id?: number | null
            cover_image: string
            source_cohort?: {
              __typename?: "Group"
              id: number
              created_by_id?: number | null
              is_master?: boolean | null
              is_source_cohort?: boolean | null
              resource?: {
                __typename?: "Resource"
                name: string
                slug: string
              } | null
            } | null
            groups?: {
              __typename?: "GroupConnection"
              total: number
              edges?: Array<{
                __typename?: "GroupEdge"
                node?: {
                  __typename?: "Group"
                  id: number
                  is_master?: boolean | null
                  is_source_cohort?: boolean | null
                  name: string
                } | null
              } | null> | null
            } | null
          } | null
          group?: {
            __typename?: "Group"
            id: number
            name: string
            created?: any | null
            description?: string | null
            price?: number | null
            seats?: number | null
            seats_filled?: number | null
            seats_available?: number | null
            registration_ends?: any | null
            registration_starts?: any | null
            registration_started?: boolean | null
            registration_ended?: boolean | null
            resource_id?: number | null
            access_ends?: any | null
            access_starts?: any | null
            is_master?: boolean | null
            is_source_cohort?: boolean | null
            source_cohort_id?: number | null
            parent_section_id?: number | null
            visibility?: number | null
            is_collection_linked?: boolean | null
            is_subscription_only?: boolean | null
            status?: GroupStatus | null
            analytics_disabled?: boolean | null
            resource_license?: {
              __typename?: "ResourceLicense"
              expiration_dtime?: any | null
              id?: number | null
            } | null
          } | null
          path?: {
            __typename?: "Path"
            id: number
            progress?: number | null
            completion_date?: any | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type UnifiedHomeResourceGroupsQueryVariables = Exact<{
  resourceId?: InputMaybe<Scalars["Int"]["input"]>
}>

export type UnifiedHomeResourceGroupsQuery = {
  __typename?: "Query"
  resource?: {
    __typename?: "Resource"
    groups?: {
      __typename?: "GroupConnection"
      total: number
      edges?: Array<{
        __typename?: "GroupEdge"
        group?: {
          __typename?: "Group"
          id: number
          name: string
          created?: any | null
          description?: string | null
          price?: number | null
          seats?: number | null
          seats_filled?: number | null
          seats_available?: number | null
          registration_ends?: any | null
          registration_starts?: any | null
          registration_started?: boolean | null
          registration_ended?: boolean | null
          resource_id?: number | null
          access_ends?: any | null
          access_starts?: any | null
          is_master?: boolean | null
          is_source_cohort?: boolean | null
          source_cohort_id?: number | null
          parent_section_id?: number | null
          visibility?: number | null
          is_collection_linked?: boolean | null
          is_subscription_only?: boolean | null
          status?: GroupStatus | null
          analytics_disabled?: boolean | null
          resource_license?: {
            __typename?: "ResourceLicense"
            expiration_dtime?: any | null
            id?: number | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type GroupListItemFragment = {
  __typename?: "Group"
  id: number
  name: string
  registrations?: {
    __typename?: "RegistrationConnection"
    total: number
    edges?: Array<{
      __typename?: "RegistrationEdge"
      node?: {
        __typename?: "Registration"
        id: number
        user?: {
          __typename?: "User"
          id?: number | null
          full_name?: string | null
          profile?: {
            __typename?: "Profile"
            id?: number | null
            image?: string | null
          } | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type UnifiedHomeGroupFragment = {
  __typename?: "Group"
  id: number
  name: string
  created?: any | null
  description?: string | null
  price?: number | null
  seats?: number | null
  seats_filled?: number | null
  seats_available?: number | null
  registration_ends?: any | null
  registration_starts?: any | null
  registration_started?: boolean | null
  registration_ended?: boolean | null
  resource_id?: number | null
  access_ends?: any | null
  access_starts?: any | null
  is_master?: boolean | null
  is_source_cohort?: boolean | null
  source_cohort_id?: number | null
  parent_section_id?: number | null
  visibility?: number | null
  is_collection_linked?: boolean | null
  is_subscription_only?: boolean | null
  status?: GroupStatus | null
  analytics_disabled?: boolean | null
  resource_license?: {
    __typename?: "ResourceLicense"
    expiration_dtime?: any | null
    id?: number | null
  } | null
}

export type UnifiedHomeRegistrationFragment = {
  __typename?: "Registration"
  id: number
  role?: number | null
  completion_state?: number | null
  send_due_notifications?: boolean | null
  send_discussion_notifications?: boolean | null
  softArchivedTime?: any | null
  last_activity_time?: any | null
  expiration_dtime?: any | null
  created?: any | null
  position?: string | null
  user?: {
    __typename?: "User"
    id?: number | null
    display_name?: string | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      image?: string | null
    } | null
  } | null
  resource?: {
    __typename?: "Resource"
    id: number
    name: string
    slug: string
    created?: any | null
    resource_type?: number | null
    visibility?: number | null
    description?: string | null
    short_description?: string | null
    background_overlay?: string | null
    background_image?: string | null
    image_raw?: string | null
    has_certificate?: boolean | null
    discussion_enabled?: boolean | null
    lowest_public_offering_price?: number | null
    highest_public_offering_price?: number | null
    licensed_from_resource_id?: number | null
    licensed_from_school_id?: number | null
    created_by_id?: number | null
    cover_image: string
    source_cohort?: {
      __typename?: "Group"
      id: number
      created_by_id?: number | null
      is_master?: boolean | null
      is_source_cohort?: boolean | null
      resource?: { __typename?: "Resource"; name: string; slug: string } | null
    } | null
    groups?: {
      __typename?: "GroupConnection"
      total: number
      edges?: Array<{
        __typename?: "GroupEdge"
        node?: {
          __typename?: "Group"
          id: number
          is_master?: boolean | null
          is_source_cohort?: boolean | null
          name: string
        } | null
      } | null> | null
    } | null
  } | null
  group?: {
    __typename?: "Group"
    id: number
    name: string
    created?: any | null
    description?: string | null
    price?: number | null
    seats?: number | null
    seats_filled?: number | null
    seats_available?: number | null
    registration_ends?: any | null
    registration_starts?: any | null
    registration_started?: boolean | null
    registration_ended?: boolean | null
    resource_id?: number | null
    access_ends?: any | null
    access_starts?: any | null
    is_master?: boolean | null
    is_source_cohort?: boolean | null
    source_cohort_id?: number | null
    parent_section_id?: number | null
    visibility?: number | null
    is_collection_linked?: boolean | null
    is_subscription_only?: boolean | null
    status?: GroupStatus | null
    analytics_disabled?: boolean | null
    resource_license?: {
      __typename?: "ResourceLicense"
      expiration_dtime?: any | null
      id?: number | null
    } | null
  } | null
  path?: {
    __typename?: "Path"
    id: number
    progress?: number | null
    completion_date?: any | null
  } | null
}

export type UnifiedHomeResourceFragment = {
  __typename?: "Resource"
  id: number
  name: string
  slug: string
  created?: any | null
  resource_type?: number | null
  visibility?: number | null
  description?: string | null
  short_description?: string | null
  background_overlay?: string | null
  background_image?: string | null
  image_raw?: string | null
  has_certificate?: boolean | null
  discussion_enabled?: boolean | null
  lowest_public_offering_price?: number | null
  highest_public_offering_price?: number | null
  licensed_from_resource_id?: number | null
  licensed_from_school_id?: number | null
  created_by_id?: number | null
  cover_image: string
  source_cohort?: {
    __typename?: "Group"
    id: number
    created_by_id?: number | null
    is_master?: boolean | null
    is_source_cohort?: boolean | null
    resource?: { __typename?: "Resource"; name: string; slug: string } | null
  } | null
  groups?: {
    __typename?: "GroupConnection"
    total: number
    edges?: Array<{
      __typename?: "GroupEdge"
      node?: {
        __typename?: "Group"
        id: number
        is_master?: boolean | null
        is_source_cohort?: boolean | null
        name: string
      } | null
    } | null> | null
  } | null
}

export type UserFragment = {
  __typename?: "User"
  id?: number | null
  email?: string | null
  first_name?: string | null
  last_name?: string | null
  full_name?: string | null
  display_name?: string | null
  profile?: {
    __typename?: "Profile"
    id?: number | null
    bio?: string | null
    image?: string | null
    location?: string | null
    last_activity?: any | null
    rewardful_referral_id?: string | null
  } | null
}

export const BackgroundTaskFragmentDoc = gql`
  fragment BackgroundTask on BackgroundTask {
    id
    status
    result
  }
`
export const CohortFragmentDoc = gql`
  fragment Cohort on Group {
    id
    name
    created
    created_by_id
    status
    is_collection_linked
    is_master
    access_starts
    parent_section_id
    registration {
      id
      role
      send_due_notifications
      send_discussion_notifications
    }
    path(type: source) {
      id
    }
    access_ends
    resource {
      id
      name
      image
      slug
      background_image
      background_overlay
      resource_type
    }
    resource_license {
      id
      expiration_dtime
    }
  }
`
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    email
    first_name
    last_name
    full_name
    display_name
    profile {
      id
      bio
      image(width: 250, height: 250, crop: faces)
      location
      last_activity
      rewardful_referral_id
    }
  }
`
export const GroupFragmentDoc = gql`
  fragment Group on Group {
    id
    name
    created
    description
    price
    seats
    seats_filled
    seats_available
    registration_ends
    registration_starts
    registration_started
    registration_ended
    access_ends
    access_starts
    is_master
    is_source_cohort
    source_cohort_id
    parent_section_id
    visibility
    is_collection_linked
    is_subscription_only
    status
    analytics_disabled
    created_by_id
  }
`
export const ResourceFragmentDoc = gql`
  fragment Resource on Resource {
    id
    name
    slug
    created
    resource_type
    visibility
    description
    short_description
    background_overlay
    background_image
    image_raw
    has_certificate
    discussion_enabled
    lowest_public_offering_price
    highest_public_offering_price
    licensed_from_resource_id
    licensed_from_school_id
    created_by_id
    image(width: 500, height: 375, fit: crop)
    cover_image: image(width: 240, height: 180, fit: crop)
  }
`
export const TagLinkFragmentDoc = gql`
  fragment TagLink on TagLink {
    id
    tag_id
    discussion_context_id
  }
`
export const TagFragmentDoc = gql`
  fragment Tag on Tag {
    id
    name
    slug
    description
    color
  }
`
export const DiscussionPostFragmentDoc = gql`
  fragment DiscussionPost on Discussion {
    id
    title
    body
    type
    likes
    total_responses
    created_time
    is_discussion_question
    allow_replies
    path_item_name
    last_activity_time
    user_last_viewed_time
    user_last_responded_time
    user_has_viewed_recent
    user_is_subscribed
    user_liked
    context_id
    resource_id
    cohort_id
    step_id
    user {
      ...User
    }
    cohort {
      ...Group
    }
    resource {
      ...Resource
    }
    tagLinks(first: 50) {
      edges {
        node {
          ...TagLink
          tag {
            ...Tag
          }
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${GroupFragmentDoc}
  ${ResourceFragmentDoc}
  ${TagLinkFragmentDoc}
  ${TagFragmentDoc}
`
export const GroupContextFragmentDoc = gql`
  fragment GroupContext on Group {
    ...Group
    resource {
      ...Resource
      blurred_background: background_image(blur: 500, width: 250, height: 250)
    }
    user_registration {
      id
      role
    }
    user_source_registration {
      id
      role
    }
  }
  ${GroupFragmentDoc}
  ${ResourceFragmentDoc}
`
export const CreatedResourceFragmentDoc = gql`
  fragment CreatedResource on Resource {
    id
    name
    slug
    resource_type
    background_overlay
    blurred_background: background_image(blur: 500, width: 250, height: 250)
    curriculum: group(is_source: true) {
      id
    }
  }
`
export const PathCreatorResourceFragmentDoc = gql`
  fragment PathCreatorResource on Resource {
    id
    name
    background_image(
      width: 250
      height: 250
      blur: 500
      high: -80
      blend: 666666
      balph: 40
      fm: "jpg"
      q: 100
      bm: "screen"
    )
    image(width: 500, height: 375, fit: crop)
    source_cohort: curriculum_group {
      id
      registration {
        id
        role
      }
    }
  }
`
export const GroupListItemFragmentDoc = gql`
  fragment GroupListItem on Group {
    id
    name
    registrations(
      first: 4
      role_filter: { in: [teacher, moderator, student] }
      orderBy: { last_activity_time: ASC }
    ) {
      total
      edges {
        node {
          id
          user {
            id
            full_name
            profile {
              id
              image
            }
          }
        }
      }
    }
  }
`
export const PathCreatorCohortFragmentDoc = gql`
  fragment PathCreatorCohort on Group {
    ...GroupListItem
  }
  ${GroupListItemFragmentDoc}
`
export const PathCreatorCreatePathFragmentDoc = gql`
  fragment PathCreatorCreatePath on Resource {
    ...PathCreatorResource
    cohort: group(is_source: false) {
      ...PathCreatorCohort
    }
  }
  ${PathCreatorResourceFragmentDoc}
  ${PathCreatorCohortFragmentDoc}
`
export const PathCreatorCohortWithRegistrationFragmentDoc = gql`
  fragment PathCreatorCohortWithRegistration on Group {
    ...PathCreatorCohort
    user_registration(user_id: $targetUserId) {
      id
      role
    }
  }
  ${PathCreatorCohortFragmentDoc}
`
export const CohortSyncPathCohortFragmentDoc = gql`
  fragment CohortSyncPathCohort on Group {
    id
    name
    is_master
    user_registration {
      id
      role
    }
    visibility
    path(type: source) {
      id
    }
    source {
      id
      name
      is_master
      user_registration {
        id
        role
      }
      path(type: source) {
        id
      }
    }
    resource {
      id
      slug
      background_overlay
      background_image
      blurred_background: background_image(blur: 500, width: 400, height: 500)
      cohorts: groups(first: 20, status: in_progress) {
        total
        edges {
          node {
            id
            name
            is_master
            path(type: source) {
              id
            }
          }
        }
      }
      license_offerings(first: 1) {
        total
      }
      description
      short_description
      name
      licensing_enabled
      resource_type
    }
    registrations(first: 1, role_filter: { eq: student }) {
      edges {
        node {
          id
          role
        }
      }
      total
    }
  }
`
export const PathSyncFragmentDoc = gql`
  fragment PathSync on PathSync {
    path_id
    synced
    last_synced_date
    sync_source_path_id
    user_id
  }
`
export const PathPublishFragmentDoc = gql`
  fragment PathPublish on PathPublish {
    path_id
    published
    last_published_date
  }
`
export const PathSyncPublishFragmentDoc = gql`
  fragment PathSyncPublish on Path {
    id
    sync {
      ...PathSync
    }
    publish {
      ...PathPublish
    }
  }
  ${PathSyncFragmentDoc}
  ${PathPublishFragmentDoc}
`
export const PathFragmentDoc = gql`
  fragment Path on Path {
    id
    progress
    points {
      path_id
      total
      earned
      total_points_possible
      percentage
      has_points
    }
    next_step_id
    completion_date
    created_date
    user {
      ...User
    }
    ...PathSyncPublish
  }
  ${UserFragmentDoc}
  ${PathSyncPublishFragmentDoc}
`
export const CompletionFragmentDoc = gql`
  fragment Completion on Completion {
    id
    checked_date
    completion_attempts
    completion_comment
    completion_date
    completion_due
    last_completion_attempt_date
    has_failed_passing_attempt
    has_points
    is_late
    is_graded
    is_skipped
    is_complete
    is_auto_graded
    is_forced_completion
    needs_grading
    userpoints_earned
    userpoints_explanation
    userpoints_value
    minimum_passing_score
    grading_type
    requires_api_completion
    overridden_by_api
    overridden_by {
      ...User
    }
    checked_by {
      ...User
    }
    user {
      ...User
    }
    submission {
      id
      text
      file_url
      file_name
      file_image
    }
    feedback_discussion_context {
      id
      discussion_id
      num_responses
    }
  }
  ${UserFragmentDoc}
`
export const RegistrationFragmentDoc = gql`
  fragment Registration on Registration {
    id
    role
    completion_state
    send_due_notifications
    send_discussion_notifications
    last_activity_time
    expiration_dtime
    created
    user {
      ...User
    }
  }
  ${UserFragmentDoc}
`
export const PathItemFragmentDoc = gql`
  fragment PathItem on PathItem {
    id
    order
    verb
    name
    type
    verb
    path_id
    source_id
    parent_id
    parent_source_id
    description
    curriculum_sync_opt_out_dtime
    assignment_type
    content_id
    content_type
    item_content_type_id
    is_required
    is_previewable
    due
    passive_due
    starts
    passive_starts
    has_started
    points
    item_needs_grading
    minimum_passing_score
    grading_type
    time_to_complete
    lock_password
    is_locked
    lock_unlocked_time
    allow_resets
    show_grading_feedback
    completion_comment_required_status
    last_published_dtime
    progress
    context {
      resource_id
      cohort_id
    }
    blocks {
      content_last_modified
      last_published
    }
    permissions {
      can_complete
      can_discuss
      can_edit
      can_edit_content
      can_note
      can_view
      can_review
      can_view_content
    }
    completion {
      ...Completion
    }
    linked_resource {
      ...Resource
      curriculum_group {
        id
      }
    }
    linked_cohort {
      id
      user_registration {
        ...Registration
        path {
          id
        }
      }
    }
    linked_registration {
      ...Registration
      path {
        id
      }
    }
  }
  ${CompletionFragmentDoc}
  ${ResourceFragmentDoc}
  ${RegistrationFragmentDoc}
`
export const PathItemsFragmentDoc = gql`
  fragment PathItems on Path {
    ...Path
    items(first: 1042, flatten: true) {
      edges {
        node {
          ...PathItem
        }
      }
    }
  }
  ${PathFragmentDoc}
  ${PathItemFragmentDoc}
`
export const PathSyncPlanChangeFragmentDoc = gql`
  fragment PathSyncPlanChange on PathSyncPlanChange {
    item_source_id
    parent_source_id
    item_name
    parent_name
    item_type
    assignment_type
    forced
    forced_reasons
    change_details
    last_changed
  }
`
export const SingleCohortPathFragmentDoc = gql`
  fragment SingleCohortPath on Path {
    id
    items(flatten: true, first: 10) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          type
          blocks {
            content_last_modified
            last_published
          }
        }
      }
    }
  }
`
export const SchoolFragmentDoc = gql`
  fragment School on School {
    id
    url
    name
    about
    email
    website
    subdomain
    created
    billing_model
    platform_version
    currency_code
    ietf_language_tag
    has_certificate
    is_activated
    is_curriculum_enabled
    is_curriculum_subscriber
    stripe_access_token
    stripe_publishable_api_key
    show_stripe_reconnect
    payment_requires_zip_code
    consent_description
    image(width: 150, height: 150)
    grant_access_upon_authentication
    pathwright_login_enabled
  }
`
export const ThemeFragmentDoc = gql`
  fragment Theme on Theme {
    id
    name
    theme_type
    background_image
    background_overlay
    background_type
    heading_font
    primary_color
    typekit_id
    custom_css
    processed_custom_css
    color_palette {
      _50
      _100
      _200
      _300
      _400
      _500
      _600
      _700
      _800
      _900
    }
  }
`
export const MeFragmentDoc = gql`
  fragment Me on User {
    ...User
    username
    no_password_set
    group_role_stats {
      type
      total
      role
    }
    permissions {
      is_school_owner
      can_administrate
      can_edit_library
      has_member_access
      has_pathwright_staff_access
    }
    membership {
      id
      created
      last_seen
      last_active
      has_access
      role
      consent_snapshot
      consent_dtime
    }
    profile {
      id
      notification_digest_interval
    }
  }
  ${UserFragmentDoc}
`
export const TranslationFragmentDoc = gql`
  fragment Translation on Translation {
    id
    name
    language
    translation
    master_translation
    is_default
  }
`
export const ContextFragmentDoc = gql`
  fragment Context on Query {
    school {
      ...School
      theme {
        ...Theme
      }
      features {
        auth__platform
      }
    }
    me {
      ...Me
    }
    translation {
      ...Translation
    }
    platformConfig {
      id
      payments_maintenance_mode
    }
    integrations {
      firebase {
        auth_token
        url
        client_api_key
      }
      intercom {
        app_id
        pathwright_app_id
        school_app_id
        user_hash
      }
    }
  }
  ${SchoolFragmentDoc}
  ${ThemeFragmentDoc}
  ${MeFragmentDoc}
  ${TranslationFragmentDoc}
`
export const __TestRegistrationFragmentDoc = gql`
  fragment __TestRegistration on Registration {
    id
    position
  }
`
export const ResourceCopyConfigPartialFragmentDoc = gql`
  fragment ResourceCopyConfigPartial on ResourceCopyConfig {
    id
    name
    lookup_key
    resource {
      id
      meta {
        id
        type
        key
        title
        image(height: 150, width: 150, fit: fill) {
          src
        }
      }
    }
  }
`
export const ResourceCopyConfigFragmentDoc = gql`
  fragment ResourceCopyConfig on ResourceCopyConfig {
    ...ResourceCopyConfigPartial
    children {
      ...ResourceCopyConfigPartial
      children {
        ...ResourceCopyConfigPartial
        children {
          ...ResourceCopyConfigPartial
        }
      }
    }
  }
  ${ResourceCopyConfigPartialFragmentDoc}
`
export const CreatedSpaceFragmentDoc = gql`
  fragment CreatedSpace on School {
    id
    name
    sso_link(pathname: "/home/teach/")
    profile {
      id
    }
  }
`
export const SpaceBillingPlanSetPlanFragmentDoc = gql`
  fragment SpaceBillingPlanSetPlan on SchoolBillingPlan {
    id
    name
  }
`
export const UserSpaceMembershipFragmentDoc = gql`
  fragment UserSpaceMembership on Membership {
    id
    role
    school {
      id
      name
      image(width: 150, height: 150)
      sso_link(pathname: "/home/")
    }
  }
`
export const SubscriptionPlanFragmentDoc = gql`
  fragment SubscriptionPlan on SubscriptionPlan {
    id
    name
    amount
    interval
    trial_period_days
    trial_type
    plan_type
  }
`
export const SubscriptionFragmentDoc = gql`
  fragment Subscription on Subscription {
    id
    quantity
    canceled_dtime
    cancellation_reason
    card_last_4
    cycle_end_dtime
    cycle_start_dtime
    is_canceled_at_cycle_end
    is_delinquent
    paused_until_dtime
    trial_type
    trial_end_dtime
    payment_intent_needing_action_id
    subscription_plan {
      ...SubscriptionPlan
    }
    customer {
      ...User
    }
  }
  ${SubscriptionPlanFragmentDoc}
  ${UserFragmentDoc}
`
export const SchoolSubscriptionPlanFragmentDoc = gql`
  fragment SchoolSubscriptionPlan on SchoolSubscriptionPlan {
    id
    image
    details_html
    subscribe_complete_html
    catalog_header
    is_visible
    giftable_cycles
    subscription_plan {
      ...SubscriptionPlan
    }
  }
  ${SubscriptionPlanFragmentDoc}
`
export const SchoolSubscriptionFragmentDoc = gql`
  fragment SchoolSubscription on SchoolSubscription {
    id
    user {
      ...User
    }
    subscription {
      ...Subscription
    }
    subscription_plan {
      ...SubscriptionPlan
    }
    school_subscription_plan {
      ...SchoolSubscriptionPlan
    }
  }
  ${UserFragmentDoc}
  ${SubscriptionFragmentDoc}
  ${SubscriptionPlanFragmentDoc}
  ${SchoolSubscriptionPlanFragmentDoc}
`
export const PositionedSchoolSubscriptionPlanFragmentDoc = gql`
  fragment PositionedSchoolSubscriptionPlan on SchoolSubscriptionPlan {
    ...SchoolSubscriptionPlan
    position
  }
  ${SchoolSubscriptionPlanFragmentDoc}
`
export const TagAttachmentFragmentDoc = gql`
  fragment TagAttachment on TagAttachment {
    id
    position
    resource_id
  }
`
export const UnifiedHomeResourceFragmentDoc = gql`
  fragment UnifiedHomeResource on Resource {
    id
    name
    slug
    created
    resource_type
    visibility
    description
    short_description
    background_overlay
    background_image
    image_raw
    has_certificate
    discussion_enabled
    lowest_public_offering_price
    highest_public_offering_price
    licensed_from_resource_id
    licensed_from_school_id
    created_by_id
    cover_image: image(width: 240, height: 180, fit: crop)
    source_cohort: curriculum_group {
      id
      created_by_id
      is_master
      is_source_cohort
      resource {
        name
        slug
      }
    }
    groups(first: 50, is_master: false) {
      total
      edges {
        node {
          id
          is_master
          is_source_cohort
          name
        }
      }
    }
  }
`
export const UnifiedHomeGroupFragmentDoc = gql`
  fragment UnifiedHomeGroup on Group {
    id
    name
    created
    description
    price
    seats
    seats_filled
    seats_available
    registration_ends
    registration_starts
    registration_started
    registration_ended
    resource_id
    resource_license {
      expiration_dtime
      id
    }
    access_ends
    access_starts
    is_master
    is_source_cohort
    source_cohort_id
    parent_section_id
    visibility
    is_collection_linked
    is_subscription_only
    status
    analytics_disabled
  }
`
export const UnifiedHomeRegistrationFragmentDoc = gql`
  fragment UnifiedHomeRegistration on Registration {
    id
    role
    completion_state
    send_due_notifications
    send_discussion_notifications
    softArchivedTime
    last_activity_time
    expiration_dtime
    created
    position
    user {
      id
      display_name
      profile {
        id
        image
      }
    }
    resource {
      ...UnifiedHomeResource
    }
    group {
      ...UnifiedHomeGroup
    }
    path {
      id
      progress
      completion_date
    }
  }
  ${UnifiedHomeResourceFragmentDoc}
  ${UnifiedHomeGroupFragmentDoc}
`
export const CreateSchoolThemeDocument = gql`
  mutation CreateSchoolTheme($theme: ThemeInput!) {
    createSchoolTheme(theme: $theme) {
      ...Theme
    }
  }
  ${ThemeFragmentDoc}
`
export type CreateSchoolThemeMutationFn = Apollo.MutationFunction<
  CreateSchoolThemeMutation,
  CreateSchoolThemeMutationVariables
>

/**
 * __useCreateSchoolThemeMutation__
 *
 * To run a mutation, you first call `useCreateSchoolThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolThemeMutation, { data, loading, error }] = useCreateSchoolThemeMutation({
 *   variables: {
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useCreateSchoolThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSchoolThemeMutation,
    CreateSchoolThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateSchoolThemeMutation,
    CreateSchoolThemeMutationVariables
  >(CreateSchoolThemeDocument, options)
}
export type CreateSchoolThemeMutationHookResult = ReturnType<
  typeof useCreateSchoolThemeMutation
>
export type CreateSchoolThemeMutationResult =
  Apollo.MutationResult<CreateSchoolThemeMutation>
export type CreateSchoolThemeMutationOptions = Apollo.BaseMutationOptions<
  CreateSchoolThemeMutation,
  CreateSchoolThemeMutationVariables
>
export const UpdateSchoolThemeDocument = gql`
  mutation UpdateSchoolTheme($id: Int!, $theme: ThemeInput!) {
    updateSchoolTheme(id: $id, theme: $theme) {
      ...Theme
    }
  }
  ${ThemeFragmentDoc}
`
export type UpdateSchoolThemeMutationFn = Apollo.MutationFunction<
  UpdateSchoolThemeMutation,
  UpdateSchoolThemeMutationVariables
>

/**
 * __useUpdateSchoolThemeMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolThemeMutation, { data, loading, error }] = useUpdateSchoolThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useUpdateSchoolThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSchoolThemeMutation,
    UpdateSchoolThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateSchoolThemeMutation,
    UpdateSchoolThemeMutationVariables
  >(UpdateSchoolThemeDocument, options)
}
export type UpdateSchoolThemeMutationHookResult = ReturnType<
  typeof useUpdateSchoolThemeMutation
>
export type UpdateSchoolThemeMutationResult =
  Apollo.MutationResult<UpdateSchoolThemeMutation>
export type UpdateSchoolThemeMutationOptions = Apollo.BaseMutationOptions<
  UpdateSchoolThemeMutation,
  UpdateSchoolThemeMutationVariables
>
export const AssignResourceDocument = gql`
  query AssignResource(
    $id: Int
    $assignConfig: AssignConfig
    $search: String
    $cursor: String
  ) {
    resource(id: $id) {
      ...Resource
      cohorts: groups(
        first: 10
        assign_config: $assignConfig
        search: $search
        after: $cursor
      ) {
        total
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...Group
          }
        }
      }
    }
  }
  ${ResourceFragmentDoc}
  ${GroupFragmentDoc}
`

/**
 * __useAssignResourceQuery__
 *
 * To run a query within a React component, call `useAssignResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignResourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      assignConfig: // value for 'assignConfig'
 *      search: // value for 'search'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useAssignResourceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AssignResourceQuery,
    AssignResourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AssignResourceQuery, AssignResourceQueryVariables>(
    AssignResourceDocument,
    options
  )
}
export function useAssignResourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssignResourceQuery,
    AssignResourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AssignResourceQuery, AssignResourceQueryVariables>(
    AssignResourceDocument,
    options
  )
}
export function useAssignResourceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AssignResourceQuery,
    AssignResourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AssignResourceQuery,
    AssignResourceQueryVariables
  >(AssignResourceDocument, options)
}
export type AssignResourceQueryHookResult = ReturnType<
  typeof useAssignResourceQuery
>
export type AssignResourceLazyQueryHookResult = ReturnType<
  typeof useAssignResourceLazyQuery
>
export type AssignResourceSuspenseQueryHookResult = ReturnType<
  typeof useAssignResourceSuspenseQuery
>
export type AssignResourceQueryResult = Apollo.QueryResult<
  AssignResourceQuery,
  AssignResourceQueryVariables
>
export const BackgroundTaskDocument = gql`
  query BackgroundTask($id: String!) {
    backgroundTask(id: $id) {
      ...BackgroundTask
    }
  }
  ${BackgroundTaskFragmentDoc}
`

/**
 * __useBackgroundTaskQuery__
 *
 * To run a query within a React component, call `useBackgroundTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackgroundTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackgroundTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBackgroundTaskQuery(
  baseOptions: Apollo.QueryHookOptions<
    BackgroundTaskQuery,
    BackgroundTaskQueryVariables
  > &
    (
      | { variables: BackgroundTaskQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BackgroundTaskQuery, BackgroundTaskQueryVariables>(
    BackgroundTaskDocument,
    options
  )
}
export function useBackgroundTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BackgroundTaskQuery,
    BackgroundTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BackgroundTaskQuery, BackgroundTaskQueryVariables>(
    BackgroundTaskDocument,
    options
  )
}
export function useBackgroundTaskSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BackgroundTaskQuery,
    BackgroundTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BackgroundTaskQuery,
    BackgroundTaskQueryVariables
  >(BackgroundTaskDocument, options)
}
export type BackgroundTaskQueryHookResult = ReturnType<
  typeof useBackgroundTaskQuery
>
export type BackgroundTaskLazyQueryHookResult = ReturnType<
  typeof useBackgroundTaskLazyQuery
>
export type BackgroundTaskSuspenseQueryHookResult = ReturnType<
  typeof useBackgroundTaskSuspenseQuery
>
export type BackgroundTaskQueryResult = Apollo.QueryResult<
  BackgroundTaskQuery,
  BackgroundTaskQueryVariables
>
export const BackgroundTasksDocument = gql`
  query BackgroundTasks($ids: [String]!) {
    backgroundTasks(ids: $ids) {
      ...BackgroundTask
    }
  }
  ${BackgroundTaskFragmentDoc}
`

/**
 * __useBackgroundTasksQuery__
 *
 * To run a query within a React component, call `useBackgroundTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackgroundTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackgroundTasksQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBackgroundTasksQuery(
  baseOptions: Apollo.QueryHookOptions<
    BackgroundTasksQuery,
    BackgroundTasksQueryVariables
  > &
    (
      | { variables: BackgroundTasksQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BackgroundTasksQuery, BackgroundTasksQueryVariables>(
    BackgroundTasksDocument,
    options
  )
}
export function useBackgroundTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BackgroundTasksQuery,
    BackgroundTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BackgroundTasksQuery,
    BackgroundTasksQueryVariables
  >(BackgroundTasksDocument, options)
}
export function useBackgroundTasksSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BackgroundTasksQuery,
    BackgroundTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BackgroundTasksQuery,
    BackgroundTasksQueryVariables
  >(BackgroundTasksDocument, options)
}
export type BackgroundTasksQueryHookResult = ReturnType<
  typeof useBackgroundTasksQuery
>
export type BackgroundTasksLazyQueryHookResult = ReturnType<
  typeof useBackgroundTasksLazyQuery
>
export type BackgroundTasksSuspenseQueryHookResult = ReturnType<
  typeof useBackgroundTasksSuspenseQuery
>
export type BackgroundTasksQueryResult = Apollo.QueryResult<
  BackgroundTasksQuery,
  BackgroundTasksQueryVariables
>
export const CohortDocument = gql`
  query Cohort($id: Int!) {
    cohort: group(id: $id) {
      ...Cohort
    }
  }
  ${CohortFragmentDoc}
`

/**
 * __useCohortQuery__
 *
 * To run a query within a React component, call `useCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCohortQuery(
  baseOptions: Apollo.QueryHookOptions<CohortQuery, CohortQueryVariables> &
    ({ variables: CohortQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CohortQuery, CohortQueryVariables>(
    CohortDocument,
    options
  )
}
export function useCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CohortQuery, CohortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CohortQuery, CohortQueryVariables>(
    CohortDocument,
    options
  )
}
export function useCohortSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CohortQuery,
    CohortQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CohortQuery, CohortQueryVariables>(
    CohortDocument,
    options
  )
}
export type CohortQueryHookResult = ReturnType<typeof useCohortQuery>
export type CohortLazyQueryHookResult = ReturnType<typeof useCohortLazyQuery>
export type CohortSuspenseQueryHookResult = ReturnType<
  typeof useCohortSuspenseQuery
>
export type CohortQueryResult = Apollo.QueryResult<
  CohortQuery,
  CohortQueryVariables
>
export const CohortSwitcherCohortsDocument = gql`
  query CohortSwitcherCohorts(
    $first: Int = 25
    $cursor: String
    $resourceId: Int!
    $role: RoleFilter
    $search: String
  ) {
    cohorts: groups(
      first: $first
      after: $cursor
      resource_id: $resourceId
      role: $role
      search: $search
      include_curriculum: false
      status: in_progress
    ) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...Cohort
        }
      }
    }
  }
  ${CohortFragmentDoc}
`

/**
 * __useCohortSwitcherCohortsQuery__
 *
 * To run a query within a React component, call `useCohortSwitcherCohortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortSwitcherCohortsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortSwitcherCohortsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      resourceId: // value for 'resourceId'
 *      role: // value for 'role'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCohortSwitcherCohortsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortSwitcherCohortsQuery,
    CohortSwitcherCohortsQueryVariables
  > &
    (
      | { variables: CohortSwitcherCohortsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CohortSwitcherCohortsQuery,
    CohortSwitcherCohortsQueryVariables
  >(CohortSwitcherCohortsDocument, options)
}
export function useCohortSwitcherCohortsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortSwitcherCohortsQuery,
    CohortSwitcherCohortsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CohortSwitcherCohortsQuery,
    CohortSwitcherCohortsQueryVariables
  >(CohortSwitcherCohortsDocument, options)
}
export function useCohortSwitcherCohortsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CohortSwitcherCohortsQuery,
    CohortSwitcherCohortsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CohortSwitcherCohortsQuery,
    CohortSwitcherCohortsQueryVariables
  >(CohortSwitcherCohortsDocument, options)
}
export type CohortSwitcherCohortsQueryHookResult = ReturnType<
  typeof useCohortSwitcherCohortsQuery
>
export type CohortSwitcherCohortsLazyQueryHookResult = ReturnType<
  typeof useCohortSwitcherCohortsLazyQuery
>
export type CohortSwitcherCohortsSuspenseQueryHookResult = ReturnType<
  typeof useCohortSwitcherCohortsSuspenseQuery
>
export type CohortSwitcherCohortsQueryResult = Apollo.QueryResult<
  CohortSwitcherCohortsQuery,
  CohortSwitcherCohortsQueryVariables
>
export const CohortsDocument = gql`
  query Cohorts($cursor: String) {
    groups(
      first: 1042
      after: $cursor
      role: { in: [teacher, moderator] }
      include_curriculum: false
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...Cohort
        }
      }
    }
  }
  ${CohortFragmentDoc}
`

/**
 * __useCohortsQuery__
 *
 * To run a query within a React component, call `useCohortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useCohortsQuery(
  baseOptions?: Apollo.QueryHookOptions<CohortsQuery, CohortsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CohortsQuery, CohortsQueryVariables>(
    CohortsDocument,
    options
  )
}
export function useCohortsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CohortsQuery, CohortsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CohortsQuery, CohortsQueryVariables>(
    CohortsDocument,
    options
  )
}
export function useCohortsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CohortsQuery,
    CohortsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CohortsQuery, CohortsQueryVariables>(
    CohortsDocument,
    options
  )
}
export type CohortsQueryHookResult = ReturnType<typeof useCohortsQuery>
export type CohortsLazyQueryHookResult = ReturnType<typeof useCohortsLazyQuery>
export type CohortsSuspenseQueryHookResult = ReturnType<
  typeof useCohortsSuspenseQuery
>
export type CohortsQueryResult = Apollo.QueryResult<
  CohortsQuery,
  CohortsQueryVariables
>
export const CompletionDocument = gql`
  query Completion($id: Int) {
    pathItem(id: $id) {
      ...PathItem
      parent {
        id
        name
      }
      path {
        id
        progress
        points {
          path_id
          total
          earned
          percentage
          total_points_possible
          has_points
        }
      }
      resource {
        id
        name
        image(width: 150, height: 150)
      }
    }
  }
  ${PathItemFragmentDoc}
`

/**
 * __useCompletionQuery__
 *
 * To run a query within a React component, call `useCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompletionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompletionQuery,
    CompletionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompletionQuery, CompletionQueryVariables>(
    CompletionDocument,
    options
  )
}
export function useCompletionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompletionQuery,
    CompletionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompletionQuery, CompletionQueryVariables>(
    CompletionDocument,
    options
  )
}
export function useCompletionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CompletionQuery,
    CompletionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CompletionQuery, CompletionQueryVariables>(
    CompletionDocument,
    options
  )
}
export type CompletionQueryHookResult = ReturnType<typeof useCompletionQuery>
export type CompletionLazyQueryHookResult = ReturnType<
  typeof useCompletionLazyQuery
>
export type CompletionSuspenseQueryHookResult = ReturnType<
  typeof useCompletionSuspenseQuery
>
export type CompletionQueryResult = Apollo.QueryResult<
  CompletionQuery,
  CompletionQueryVariables
>
export const CompletionsDocument = gql`
  query Completions(
    $first: Int = 50
    $user_id: Int
    $cohort_id: Int
    $group_id: Int
    $path_id: Int
    $step_id: Int
    $lesson_source_id: Int
    $source_id: Int
    $needs_review: Boolean
    $is_reviewed: Boolean
    $is_complete: Boolean
    $order_by: [String]
    $cursor: String
  ) {
    pathItems(
      first: $first
      type: step
      after: $cursor
      user_id: $user_id
      cohort_id: $cohort_id
      group_id: $group_id
      step_id: $step_id
      path_id: $path_id
      lesson_source_id: $lesson_source_id
      source_id: $source_id
      needs_review: $needs_review
      is_reviewed: $is_reviewed
      is_complete: $is_complete
      reviewable: true
      order_by: $order_by
      registration_role_filter: { in: [student, moderator] }
    ) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...PathItem
          cohort {
            id
            name
          }
          resource {
            id
            name
            image(width: 100, height: 100, fit: crop)
            blurred_background: background_image(
              blur: 500
              width: 250
              height: 250
            )
            background_overlay
            background_image
            image_raw
          }
          parent {
            id
            source_id
            order
            name
          }
          analytics {
            need_feedback
            total_users
          }
        }
      }
    }
  }
  ${PathItemFragmentDoc}
`

/**
 * __useCompletionsQuery__
 *
 * To run a query within a React component, call `useCompletionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      user_id: // value for 'user_id'
 *      cohort_id: // value for 'cohort_id'
 *      group_id: // value for 'group_id'
 *      path_id: // value for 'path_id'
 *      step_id: // value for 'step_id'
 *      lesson_source_id: // value for 'lesson_source_id'
 *      source_id: // value for 'source_id'
 *      needs_review: // value for 'needs_review'
 *      is_reviewed: // value for 'is_reviewed'
 *      is_complete: // value for 'is_complete'
 *      order_by: // value for 'order_by'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useCompletionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompletionsQuery,
    CompletionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompletionsQuery, CompletionsQueryVariables>(
    CompletionsDocument,
    options
  )
}
export function useCompletionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompletionsQuery,
    CompletionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompletionsQuery, CompletionsQueryVariables>(
    CompletionsDocument,
    options
  )
}
export function useCompletionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CompletionsQuery,
    CompletionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CompletionsQuery, CompletionsQueryVariables>(
    CompletionsDocument,
    options
  )
}
export type CompletionsQueryHookResult = ReturnType<typeof useCompletionsQuery>
export type CompletionsLazyQueryHookResult = ReturnType<
  typeof useCompletionsLazyQuery
>
export type CompletionsSuspenseQueryHookResult = ReturnType<
  typeof useCompletionsSuspenseQuery
>
export type CompletionsQueryResult = Apollo.QueryResult<
  CompletionsQuery,
  CompletionsQueryVariables
>
export const ResetCompletionFeedbackDocument = gql`
  mutation ResetCompletionFeedback($id: Int!, $pathId: Int!) {
    resetCompletionFeedback(id: $id, path_id: $pathId) {
      ...Completion
    }
  }
  ${CompletionFragmentDoc}
`
export type ResetCompletionFeedbackMutationFn = Apollo.MutationFunction<
  ResetCompletionFeedbackMutation,
  ResetCompletionFeedbackMutationVariables
>

/**
 * __useResetCompletionFeedbackMutation__
 *
 * To run a mutation, you first call `useResetCompletionFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCompletionFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCompletionFeedbackMutation, { data, loading, error }] = useResetCompletionFeedbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *      pathId: // value for 'pathId'
 *   },
 * });
 */
export function useResetCompletionFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetCompletionFeedbackMutation,
    ResetCompletionFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetCompletionFeedbackMutation,
    ResetCompletionFeedbackMutationVariables
  >(ResetCompletionFeedbackDocument, options)
}
export type ResetCompletionFeedbackMutationHookResult = ReturnType<
  typeof useResetCompletionFeedbackMutation
>
export type ResetCompletionFeedbackMutationResult =
  Apollo.MutationResult<ResetCompletionFeedbackMutation>
export type ResetCompletionFeedbackMutationOptions = Apollo.BaseMutationOptions<
  ResetCompletionFeedbackMutation,
  ResetCompletionFeedbackMutationVariables
>
export const SaveCompletionFeedbackDocument = gql`
  mutation SaveCompletionFeedback(
    $id: Int!
    $pathId: Int!
    $userpointsEarned: Int
    $userpointsExplanation: String
    $notify: Boolean
  ) {
    saveCompletionFeedback(
      id: $id
      path_id: $pathId
      userpoints_earned: $userpointsEarned
      userpoints_explanation: $userpointsExplanation
      notify: $notify
    ) {
      ...Completion
    }
  }
  ${CompletionFragmentDoc}
`
export type SaveCompletionFeedbackMutationFn = Apollo.MutationFunction<
  SaveCompletionFeedbackMutation,
  SaveCompletionFeedbackMutationVariables
>

/**
 * __useSaveCompletionFeedbackMutation__
 *
 * To run a mutation, you first call `useSaveCompletionFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCompletionFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCompletionFeedbackMutation, { data, loading, error }] = useSaveCompletionFeedbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *      pathId: // value for 'pathId'
 *      userpointsEarned: // value for 'userpointsEarned'
 *      userpointsExplanation: // value for 'userpointsExplanation'
 *      notify: // value for 'notify'
 *   },
 * });
 */
export function useSaveCompletionFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCompletionFeedbackMutation,
    SaveCompletionFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveCompletionFeedbackMutation,
    SaveCompletionFeedbackMutationVariables
  >(SaveCompletionFeedbackDocument, options)
}
export type SaveCompletionFeedbackMutationHookResult = ReturnType<
  typeof useSaveCompletionFeedbackMutation
>
export type SaveCompletionFeedbackMutationResult =
  Apollo.MutationResult<SaveCompletionFeedbackMutation>
export type SaveCompletionFeedbackMutationOptions = Apollo.BaseMutationOptions<
  SaveCompletionFeedbackMutation,
  SaveCompletionFeedbackMutationVariables
>
export const DiscussionsDocument = gql`
  query Discussions(
    $context: DiscussionContextInput!
    $type: DiscussionTypeInput
    $is_discussion_question: Boolean
    $total_responses: IntFilter
    $tagIdFilter: IntFilter
    $order_by: [String]
    $first: Int = 15
    $cursor: String
  ) {
    discussions(
      context: $context
      type: $type
      is_discussion_question: $is_discussion_question
      total_responses: $total_responses
      tag_id_filter: $tagIdFilter
      order_by: $order_by
      first: $first
      after: $cursor
    ) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...DiscussionPost
        }
      }
    }
  }
  ${DiscussionPostFragmentDoc}
`

/**
 * __useDiscussionsQuery__
 *
 * To run a query within a React component, call `useDiscussionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscussionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscussionsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      type: // value for 'type'
 *      is_discussion_question: // value for 'is_discussion_question'
 *      total_responses: // value for 'total_responses'
 *      tagIdFilter: // value for 'tagIdFilter'
 *      order_by: // value for 'order_by'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useDiscussionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DiscussionsQuery,
    DiscussionsQueryVariables
  > &
    (
      | { variables: DiscussionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DiscussionsQuery, DiscussionsQueryVariables>(
    DiscussionsDocument,
    options
  )
}
export function useDiscussionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DiscussionsQuery,
    DiscussionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DiscussionsQuery, DiscussionsQueryVariables>(
    DiscussionsDocument,
    options
  )
}
export function useDiscussionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DiscussionsQuery,
    DiscussionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<DiscussionsQuery, DiscussionsQueryVariables>(
    DiscussionsDocument,
    options
  )
}
export type DiscussionsQueryHookResult = ReturnType<typeof useDiscussionsQuery>
export type DiscussionsLazyQueryHookResult = ReturnType<
  typeof useDiscussionsLazyQuery
>
export type DiscussionsSuspenseQueryHookResult = ReturnType<
  typeof useDiscussionsSuspenseQuery
>
export type DiscussionsQueryResult = Apollo.QueryResult<
  DiscussionsQuery,
  DiscussionsQueryVariables
>
export const DiscussionPostDocument = gql`
  query DiscussionPost($id: Int, $context: DiscussionContextInput!) {
    discussion(id: $id, context: $context) {
      ...DiscussionPost
    }
  }
  ${DiscussionPostFragmentDoc}
`

/**
 * __useDiscussionPostQuery__
 *
 * To run a query within a React component, call `useDiscussionPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscussionPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscussionPostQuery({
 *   variables: {
 *      id: // value for 'id'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useDiscussionPostQuery(
  baseOptions: Apollo.QueryHookOptions<
    DiscussionPostQuery,
    DiscussionPostQueryVariables
  > &
    (
      | { variables: DiscussionPostQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DiscussionPostQuery, DiscussionPostQueryVariables>(
    DiscussionPostDocument,
    options
  )
}
export function useDiscussionPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DiscussionPostQuery,
    DiscussionPostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DiscussionPostQuery, DiscussionPostQueryVariables>(
    DiscussionPostDocument,
    options
  )
}
export function useDiscussionPostSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DiscussionPostQuery,
    DiscussionPostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    DiscussionPostQuery,
    DiscussionPostQueryVariables
  >(DiscussionPostDocument, options)
}
export type DiscussionPostQueryHookResult = ReturnType<
  typeof useDiscussionPostQuery
>
export type DiscussionPostLazyQueryHookResult = ReturnType<
  typeof useDiscussionPostLazyQuery
>
export type DiscussionPostSuspenseQueryHookResult = ReturnType<
  typeof useDiscussionPostSuspenseQuery
>
export type DiscussionPostQueryResult = Apollo.QueryResult<
  DiscussionPostQuery,
  DiscussionPostQueryVariables
>
export const GroupDocument = gql`
  query Group($id: Int!) {
    group(id: $id) {
      ...GroupContext
    }
  }
  ${GroupContextFragmentDoc}
`

/**
 * __useGroupQuery__
 *
 * To run a query within a React component, call `useGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupQuery(
  baseOptions: Apollo.QueryHookOptions<GroupQuery, GroupQueryVariables> &
    ({ variables: GroupQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GroupQuery, GroupQueryVariables>(
    GroupDocument,
    options
  )
}
export function useGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupQuery, GroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GroupQuery, GroupQueryVariables>(
    GroupDocument,
    options
  )
}
export function useGroupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GroupQuery, GroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GroupQuery, GroupQueryVariables>(
    GroupDocument,
    options
  )
}
export type GroupQueryHookResult = ReturnType<typeof useGroupQuery>
export type GroupLazyQueryHookResult = ReturnType<typeof useGroupLazyQuery>
export type GroupSuspenseQueryHookResult = ReturnType<
  typeof useGroupSuspenseQuery
>
export type GroupQueryResult = Apollo.QueryResult<
  GroupQuery,
  GroupQueryVariables
>
export const UserRegistrationsDocument = gql`
  query UserRegistrations($first: Int = 1042, $cursor: String) {
    me {
      ...User
      registrations(
        first: $first
        after: $cursor
        role_filter: { lte: student }
      ) {
        total
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...Registration
            resource {
              ...Resource
            }
            group {
              ...Group
            }
            path {
              id
              progress
              completion_date
            }
            school_sub_used {
              id
              subscription {
                id
                is_delinquent
                canceled_dtime
              }
            }
            group_sub_used {
              id
            }
            group_sub_community_membership {
              id
              access_revoked_time
            }
          }
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${RegistrationFragmentDoc}
  ${ResourceFragmentDoc}
  ${GroupFragmentDoc}
`

/**
 * __useUserRegistrationsQuery__
 *
 * To run a query within a React component, call `useUserRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRegistrationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useUserRegistrationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserRegistrationsQuery,
    UserRegistrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserRegistrationsQuery,
    UserRegistrationsQueryVariables
  >(UserRegistrationsDocument, options)
}
export function useUserRegistrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserRegistrationsQuery,
    UserRegistrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserRegistrationsQuery,
    UserRegistrationsQueryVariables
  >(UserRegistrationsDocument, options)
}
export function useUserRegistrationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserRegistrationsQuery,
    UserRegistrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    UserRegistrationsQuery,
    UserRegistrationsQueryVariables
  >(UserRegistrationsDocument, options)
}
export type UserRegistrationsQueryHookResult = ReturnType<
  typeof useUserRegistrationsQuery
>
export type UserRegistrationsLazyQueryHookResult = ReturnType<
  typeof useUserRegistrationsLazyQuery
>
export type UserRegistrationsSuspenseQueryHookResult = ReturnType<
  typeof useUserRegistrationsSuspenseQuery
>
export type UserRegistrationsQueryResult = Apollo.QueryResult<
  UserRegistrationsQuery,
  UserRegistrationsQueryVariables
>
export const CreateResourceDocument = gql`
  mutation CreateResource(
    $name: String!
    $cover_image: String!
    $resource_type: ResourceType
  ) {
    createResource(
      name: $name
      cover_image: $cover_image
      resource_type: $resource_type
    ) {
      ...CreatedResource
    }
  }
  ${CreatedResourceFragmentDoc}
`
export type CreateResourceMutationFn = Apollo.MutationFunction<
  CreateResourceMutation,
  CreateResourceMutationVariables
>

/**
 * __useCreateResourceMutation__
 *
 * To run a mutation, you first call `useCreateResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResourceMutation, { data, loading, error }] = useCreateResourceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      cover_image: // value for 'cover_image'
 *      resource_type: // value for 'resource_type'
 *   },
 * });
 */
export function useCreateResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateResourceMutation,
    CreateResourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateResourceMutation,
    CreateResourceMutationVariables
  >(CreateResourceDocument, options)
}
export type CreateResourceMutationHookResult = ReturnType<
  typeof useCreateResourceMutation
>
export type CreateResourceMutationResult =
  Apollo.MutationResult<CreateResourceMutation>
export type CreateResourceMutationOptions = Apollo.BaseMutationOptions<
  CreateResourceMutation,
  CreateResourceMutationVariables
>
export const RandomResourceImageDocument = gql`
  query RandomResourceImage($themeType: ThemeType) {
    resourceImage(theme_type: $themeType)
  }
`

/**
 * __useRandomResourceImageQuery__
 *
 * To run a query within a React component, call `useRandomResourceImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRandomResourceImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRandomResourceImageQuery({
 *   variables: {
 *      themeType: // value for 'themeType'
 *   },
 * });
 */
export function useRandomResourceImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RandomResourceImageQuery,
    RandomResourceImageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RandomResourceImageQuery,
    RandomResourceImageQueryVariables
  >(RandomResourceImageDocument, options)
}
export function useRandomResourceImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RandomResourceImageQuery,
    RandomResourceImageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RandomResourceImageQuery,
    RandomResourceImageQueryVariables
  >(RandomResourceImageDocument, options)
}
export function useRandomResourceImageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RandomResourceImageQuery,
    RandomResourceImageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    RandomResourceImageQuery,
    RandomResourceImageQueryVariables
  >(RandomResourceImageDocument, options)
}
export type RandomResourceImageQueryHookResult = ReturnType<
  typeof useRandomResourceImageQuery
>
export type RandomResourceImageLazyQueryHookResult = ReturnType<
  typeof useRandomResourceImageLazyQuery
>
export type RandomResourceImageSuspenseQueryHookResult = ReturnType<
  typeof useRandomResourceImageSuspenseQuery
>
export type RandomResourceImageQueryResult = Apollo.QueryResult<
  RandomResourceImageQuery,
  RandomResourceImageQueryVariables
>
export const UpdateLocalTranslationDocument = gql`
  mutation UpdateLocalTranslation($translation: JSON!) {
    updateLocalTranslation(translation: $translation)
  }
`
export type UpdateLocalTranslationMutationFn = Apollo.MutationFunction<
  UpdateLocalTranslationMutation,
  UpdateLocalTranslationMutationVariables
>

/**
 * __useUpdateLocalTranslationMutation__
 *
 * To run a mutation, you first call `useUpdateLocalTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocalTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocalTranslationMutation, { data, loading, error }] = useUpdateLocalTranslationMutation({
 *   variables: {
 *      translation: // value for 'translation'
 *   },
 * });
 */
export function useUpdateLocalTranslationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLocalTranslationMutation,
    UpdateLocalTranslationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateLocalTranslationMutation,
    UpdateLocalTranslationMutationVariables
  >(UpdateLocalTranslationDocument, options)
}
export type UpdateLocalTranslationMutationHookResult = ReturnType<
  typeof useUpdateLocalTranslationMutation
>
export type UpdateLocalTranslationMutationResult =
  Apollo.MutationResult<UpdateLocalTranslationMutation>
export type UpdateLocalTranslationMutationOptions = Apollo.BaseMutationOptions<
  UpdateLocalTranslationMutation,
  UpdateLocalTranslationMutationVariables
>
export const TranslationDocument = gql`
  query Translation($language: String, $isDefault: Boolean) {
    translation(language: $language, is_default: $isDefault) {
      id
      name
      language
      translation
      master_translation
      is_default
    }
  }
`

/**
 * __useTranslationQuery__
 *
 * To run a query within a React component, call `useTranslationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslationQuery({
 *   variables: {
 *      language: // value for 'language'
 *      isDefault: // value for 'isDefault'
 *   },
 * });
 */
export function useTranslationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TranslationQuery,
    TranslationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TranslationQuery, TranslationQueryVariables>(
    TranslationDocument,
    options
  )
}
export function useTranslationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TranslationQuery,
    TranslationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TranslationQuery, TranslationQueryVariables>(
    TranslationDocument,
    options
  )
}
export function useTranslationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TranslationQuery,
    TranslationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<TranslationQuery, TranslationQueryVariables>(
    TranslationDocument,
    options
  )
}
export type TranslationQueryHookResult = ReturnType<typeof useTranslationQuery>
export type TranslationLazyQueryHookResult = ReturnType<
  typeof useTranslationLazyQuery
>
export type TranslationSuspenseQueryHookResult = ReturnType<
  typeof useTranslationSuspenseQuery
>
export type TranslationQueryResult = Apollo.QueryResult<
  TranslationQuery,
  TranslationQueryVariables
>
export const PathCreatorAssignRoleDocument = gql`
  mutation PathCreatorAssignRole(
    $cohorts: [Int]!
    $user: Int!
    $role: RegistrationRoleEnum!
    $sendNotification: Boolean = false
    $message: String
  ) {
    assign(
      offerings: $cohorts
      assign_to_user: $user
      role: $role
      send_notification: $sendNotification
      assignment_message: $message
    )
  }
`
export type PathCreatorAssignRoleMutationFn = Apollo.MutationFunction<
  PathCreatorAssignRoleMutation,
  PathCreatorAssignRoleMutationVariables
>

/**
 * __usePathCreatorAssignRoleMutation__
 *
 * To run a mutation, you first call `usePathCreatorAssignRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePathCreatorAssignRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pathCreatorAssignRoleMutation, { data, loading, error }] = usePathCreatorAssignRoleMutation({
 *   variables: {
 *      cohorts: // value for 'cohorts'
 *      user: // value for 'user'
 *      role: // value for 'role'
 *      sendNotification: // value for 'sendNotification'
 *      message: // value for 'message'
 *   },
 * });
 */
export function usePathCreatorAssignRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PathCreatorAssignRoleMutation,
    PathCreatorAssignRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PathCreatorAssignRoleMutation,
    PathCreatorAssignRoleMutationVariables
  >(PathCreatorAssignRoleDocument, options)
}
export type PathCreatorAssignRoleMutationHookResult = ReturnType<
  typeof usePathCreatorAssignRoleMutation
>
export type PathCreatorAssignRoleMutationResult =
  Apollo.MutationResult<PathCreatorAssignRoleMutation>
export type PathCreatorAssignRoleMutationOptions = Apollo.BaseMutationOptions<
  PathCreatorAssignRoleMutation,
  PathCreatorAssignRoleMutationVariables
>
export const PathCreatorCohortsDocument = gql`
  query PathCreatorCohorts($resourceId: Int!, $targetUserId: Int!) {
    cohorts: groups(first: 1042, resource_id: $resourceId) {
      edges {
        node {
          ...PathCreatorCohortWithRegistration
        }
      }
    }
  }
  ${PathCreatorCohortWithRegistrationFragmentDoc}
`

/**
 * __usePathCreatorCohortsQuery__
 *
 * To run a query within a React component, call `usePathCreatorCohortsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathCreatorCohortsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathCreatorCohortsQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *      targetUserId: // value for 'targetUserId'
 *   },
 * });
 */
export function usePathCreatorCohortsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PathCreatorCohortsQuery,
    PathCreatorCohortsQueryVariables
  > &
    (
      | { variables: PathCreatorCohortsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PathCreatorCohortsQuery,
    PathCreatorCohortsQueryVariables
  >(PathCreatorCohortsDocument, options)
}
export function usePathCreatorCohortsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PathCreatorCohortsQuery,
    PathCreatorCohortsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PathCreatorCohortsQuery,
    PathCreatorCohortsQueryVariables
  >(PathCreatorCohortsDocument, options)
}
export function usePathCreatorCohortsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PathCreatorCohortsQuery,
    PathCreatorCohortsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    PathCreatorCohortsQuery,
    PathCreatorCohortsQueryVariables
  >(PathCreatorCohortsDocument, options)
}
export type PathCreatorCohortsQueryHookResult = ReturnType<
  typeof usePathCreatorCohortsQuery
>
export type PathCreatorCohortsLazyQueryHookResult = ReturnType<
  typeof usePathCreatorCohortsLazyQuery
>
export type PathCreatorCohortsSuspenseQueryHookResult = ReturnType<
  typeof usePathCreatorCohortsSuspenseQuery
>
export type PathCreatorCohortsQueryResult = Apollo.QueryResult<
  PathCreatorCohortsQuery,
  PathCreatorCohortsQueryVariables
>
export const PathCreatorCreateCohortDocument = gql`
  mutation PathCreatorCreateCohort($resourceId: Int!, $name: String!) {
    createCohort: createGroup(
      base_resource_id: $resourceId
      visibility: 5
      name: $name
    ) {
      ...PathCreatorCohort
    }
  }
  ${PathCreatorCohortFragmentDoc}
`
export type PathCreatorCreateCohortMutationFn = Apollo.MutationFunction<
  PathCreatorCreateCohortMutation,
  PathCreatorCreateCohortMutationVariables
>

/**
 * __usePathCreatorCreateCohortMutation__
 *
 * To run a mutation, you first call `usePathCreatorCreateCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePathCreatorCreateCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pathCreatorCreateCohortMutation, { data, loading, error }] = usePathCreatorCreateCohortMutation({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function usePathCreatorCreateCohortMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PathCreatorCreateCohortMutation,
    PathCreatorCreateCohortMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PathCreatorCreateCohortMutation,
    PathCreatorCreateCohortMutationVariables
  >(PathCreatorCreateCohortDocument, options)
}
export type PathCreatorCreateCohortMutationHookResult = ReturnType<
  typeof usePathCreatorCreateCohortMutation
>
export type PathCreatorCreateCohortMutationResult =
  Apollo.MutationResult<PathCreatorCreateCohortMutation>
export type PathCreatorCreateCohortMutationOptions = Apollo.BaseMutationOptions<
  PathCreatorCreateCohortMutation,
  PathCreatorCreateCohortMutationVariables
>
export const PathCreatorCreatePathDocument = gql`
  mutation PathCreatorCreatePath(
    $name: String!
    $image: String!
    $resourceType: ResourceType
    $cohortName: String
  ) {
    createResource(
      name: $name
      cover_image: $image
      resource_type: $resourceType
      group_name: $cohortName
    ) {
      ...PathCreatorCreatePath
    }
  }
  ${PathCreatorCreatePathFragmentDoc}
`
export type PathCreatorCreatePathMutationFn = Apollo.MutationFunction<
  PathCreatorCreatePathMutation,
  PathCreatorCreatePathMutationVariables
>

/**
 * __usePathCreatorCreatePathMutation__
 *
 * To run a mutation, you first call `usePathCreatorCreatePathMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePathCreatorCreatePathMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pathCreatorCreatePathMutation, { data, loading, error }] = usePathCreatorCreatePathMutation({
 *   variables: {
 *      name: // value for 'name'
 *      image: // value for 'image'
 *      resourceType: // value for 'resourceType'
 *      cohortName: // value for 'cohortName'
 *   },
 * });
 */
export function usePathCreatorCreatePathMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PathCreatorCreatePathMutation,
    PathCreatorCreatePathMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PathCreatorCreatePathMutation,
    PathCreatorCreatePathMutationVariables
  >(PathCreatorCreatePathDocument, options)
}
export type PathCreatorCreatePathMutationHookResult = ReturnType<
  typeof usePathCreatorCreatePathMutation
>
export type PathCreatorCreatePathMutationResult =
  Apollo.MutationResult<PathCreatorCreatePathMutation>
export type PathCreatorCreatePathMutationOptions = Apollo.BaseMutationOptions<
  PathCreatorCreatePathMutation,
  PathCreatorCreatePathMutationVariables
>
export const PathCreatorRandomResourceImageDocument = gql`
  query PathCreatorRandomResourceImage($themeType: ThemeType) {
    resourceImage(
      theme_type: $themeType
      image: [
        { width: 240, height: 180, fit: crop }
        {
          width: 250
          height: 250
          blur: 500
          high: -80
          blend: 666666
          balph: 40
          fm: "jpg"
          q: 100
          bm: "screen"
        }
      ]
    )
  }
`

/**
 * __usePathCreatorRandomResourceImageQuery__
 *
 * To run a query within a React component, call `usePathCreatorRandomResourceImageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathCreatorRandomResourceImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathCreatorRandomResourceImageQuery({
 *   variables: {
 *      themeType: // value for 'themeType'
 *   },
 * });
 */
export function usePathCreatorRandomResourceImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PathCreatorRandomResourceImageQuery,
    PathCreatorRandomResourceImageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PathCreatorRandomResourceImageQuery,
    PathCreatorRandomResourceImageQueryVariables
  >(PathCreatorRandomResourceImageDocument, options)
}
export function usePathCreatorRandomResourceImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PathCreatorRandomResourceImageQuery,
    PathCreatorRandomResourceImageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PathCreatorRandomResourceImageQuery,
    PathCreatorRandomResourceImageQueryVariables
  >(PathCreatorRandomResourceImageDocument, options)
}
export function usePathCreatorRandomResourceImageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PathCreatorRandomResourceImageQuery,
    PathCreatorRandomResourceImageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    PathCreatorRandomResourceImageQuery,
    PathCreatorRandomResourceImageQueryVariables
  >(PathCreatorRandomResourceImageDocument, options)
}
export type PathCreatorRandomResourceImageQueryHookResult = ReturnType<
  typeof usePathCreatorRandomResourceImageQuery
>
export type PathCreatorRandomResourceImageLazyQueryHookResult = ReturnType<
  typeof usePathCreatorRandomResourceImageLazyQuery
>
export type PathCreatorRandomResourceImageSuspenseQueryHookResult = ReturnType<
  typeof usePathCreatorRandomResourceImageSuspenseQuery
>
export type PathCreatorRandomResourceImageQueryResult = Apollo.QueryResult<
  PathCreatorRandomResourceImageQuery,
  PathCreatorRandomResourceImageQueryVariables
>
export const PathCreatorResourcesDocument = gql`
  query PathCreatorResources {
    resources(first: 1042) {
      edges {
        node {
          ...PathCreatorResource
        }
      }
    }
  }
  ${PathCreatorResourceFragmentDoc}
`

/**
 * __usePathCreatorResourcesQuery__
 *
 * To run a query within a React component, call `usePathCreatorResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathCreatorResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathCreatorResourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePathCreatorResourcesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PathCreatorResourcesQuery,
    PathCreatorResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PathCreatorResourcesQuery,
    PathCreatorResourcesQueryVariables
  >(PathCreatorResourcesDocument, options)
}
export function usePathCreatorResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PathCreatorResourcesQuery,
    PathCreatorResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PathCreatorResourcesQuery,
    PathCreatorResourcesQueryVariables
  >(PathCreatorResourcesDocument, options)
}
export function usePathCreatorResourcesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PathCreatorResourcesQuery,
    PathCreatorResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    PathCreatorResourcesQuery,
    PathCreatorResourcesQueryVariables
  >(PathCreatorResourcesDocument, options)
}
export type PathCreatorResourcesQueryHookResult = ReturnType<
  typeof usePathCreatorResourcesQuery
>
export type PathCreatorResourcesLazyQueryHookResult = ReturnType<
  typeof usePathCreatorResourcesLazyQuery
>
export type PathCreatorResourcesSuspenseQueryHookResult = ReturnType<
  typeof usePathCreatorResourcesSuspenseQuery
>
export type PathCreatorResourcesQueryResult = Apollo.QueryResult<
  PathCreatorResourcesQuery,
  PathCreatorResourcesQueryVariables
>
export const CohortSyncPathDocument = gql`
  query CohortSyncPath($cohortId: Int!) {
    cohort: group(id: $cohortId) {
      ...CohortSyncPathCohort
    }
  }
  ${CohortSyncPathCohortFragmentDoc}
`

/**
 * __useCohortSyncPathQuery__
 *
 * To run a query within a React component, call `useCohortSyncPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortSyncPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortSyncPathQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useCohortSyncPathQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortSyncPathQuery,
    CohortSyncPathQueryVariables
  > &
    (
      | { variables: CohortSyncPathQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CohortSyncPathQuery, CohortSyncPathQueryVariables>(
    CohortSyncPathDocument,
    options
  )
}
export function useCohortSyncPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortSyncPathQuery,
    CohortSyncPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CohortSyncPathQuery, CohortSyncPathQueryVariables>(
    CohortSyncPathDocument,
    options
  )
}
export function useCohortSyncPathSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CohortSyncPathQuery,
    CohortSyncPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CohortSyncPathQuery,
    CohortSyncPathQueryVariables
  >(CohortSyncPathDocument, options)
}
export type CohortSyncPathQueryHookResult = ReturnType<
  typeof useCohortSyncPathQuery
>
export type CohortSyncPathLazyQueryHookResult = ReturnType<
  typeof useCohortSyncPathLazyQuery
>
export type CohortSyncPathSuspenseQueryHookResult = ReturnType<
  typeof useCohortSyncPathSuspenseQuery
>
export type CohortSyncPathQueryResult = Apollo.QueryResult<
  CohortSyncPathQuery,
  CohortSyncPathQueryVariables
>
export const CompletePathItemDocument = gql`
  mutation CompletePathItem(
    $id: Int!
    $path_id: Int!
    $skip: Boolean
    $completion_data: JSON
  ) {
    completePathItem(
      id: $id
      path_id: $path_id
      skip: $skip
      completion_data: $completion_data
    ) {
      ...PathItem
      path {
        id
        progress
        points {
          path_id
          total
          earned
          total_points_possible
          percentage
          has_points
        }
        next_step_id
        completion_date
      }
    }
  }
  ${PathItemFragmentDoc}
`
export type CompletePathItemMutationFn = Apollo.MutationFunction<
  CompletePathItemMutation,
  CompletePathItemMutationVariables
>

/**
 * __useCompletePathItemMutation__
 *
 * To run a mutation, you first call `useCompletePathItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePathItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePathItemMutation, { data, loading, error }] = useCompletePathItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      path_id: // value for 'path_id'
 *      skip: // value for 'skip'
 *      completion_data: // value for 'completion_data'
 *   },
 * });
 */
export function useCompletePathItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompletePathItemMutation,
    CompletePathItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompletePathItemMutation,
    CompletePathItemMutationVariables
  >(CompletePathItemDocument, options)
}
export type CompletePathItemMutationHookResult = ReturnType<
  typeof useCompletePathItemMutation
>
export type CompletePathItemMutationResult =
  Apollo.MutationResult<CompletePathItemMutation>
export type CompletePathItemMutationOptions = Apollo.BaseMutationOptions<
  CompletePathItemMutation,
  CompletePathItemMutationVariables
>
export const CreatePathItemDocument = gql`
  mutation CreatePathItem(
    $path_id: Int!
    $order: Int!
    $name: String!
    $type: PathItemType!
    $assignment_type: PathItemAssignmentType
    $parent: Int
    $description: String
    $content_type: String
    $content_resource: Int
  ) {
    createPathItem(
      path_id: $path_id
      order: $order
      name: $name
      type: $type
      assignment_type: $assignment_type
      parent: $parent
      description: $description
      content_type: $content_type
      content_resource: $content_resource
    ) {
      ...PathItem
      items(first: 50) {
        edges {
          node {
            ...PathItem
          }
        }
      }
    }
  }
  ${PathItemFragmentDoc}
`
export type CreatePathItemMutationFn = Apollo.MutationFunction<
  CreatePathItemMutation,
  CreatePathItemMutationVariables
>

/**
 * __useCreatePathItemMutation__
 *
 * To run a mutation, you first call `useCreatePathItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePathItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPathItemMutation, { data, loading, error }] = useCreatePathItemMutation({
 *   variables: {
 *      path_id: // value for 'path_id'
 *      order: // value for 'order'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      assignment_type: // value for 'assignment_type'
 *      parent: // value for 'parent'
 *      description: // value for 'description'
 *      content_type: // value for 'content_type'
 *      content_resource: // value for 'content_resource'
 *   },
 * });
 */
export function useCreatePathItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePathItemMutation,
    CreatePathItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePathItemMutation,
    CreatePathItemMutationVariables
  >(CreatePathItemDocument, options)
}
export type CreatePathItemMutationHookResult = ReturnType<
  typeof useCreatePathItemMutation
>
export type CreatePathItemMutationResult =
  Apollo.MutationResult<CreatePathItemMutation>
export type CreatePathItemMutationOptions = Apollo.BaseMutationOptions<
  CreatePathItemMutation,
  CreatePathItemMutationVariables
>
export const CreateRegistrationUpsertGroupDocument = gql`
  mutation CreateRegistrationUpsertGroup(
    $resource_id: Int!
    $parent_offering_id: Int!
    $step_source_id: Int!
    $createrRegistration: Boolean = false
  ) {
    createRegistrationUpsertGroup(
      resource_id: $resource_id
      parent_offering_id: $parent_offering_id
      step_source_id: $step_source_id
      create_registration: $createrRegistration
    ) {
      ...Group
      user_registration {
        ...Registration
        path {
          id
        }
      }
    }
  }
  ${GroupFragmentDoc}
  ${RegistrationFragmentDoc}
`
export type CreateRegistrationUpsertGroupMutationFn = Apollo.MutationFunction<
  CreateRegistrationUpsertGroupMutation,
  CreateRegistrationUpsertGroupMutationVariables
>

/**
 * __useCreateRegistrationUpsertGroupMutation__
 *
 * To run a mutation, you first call `useCreateRegistrationUpsertGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegistrationUpsertGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegistrationUpsertGroupMutation, { data, loading, error }] = useCreateRegistrationUpsertGroupMutation({
 *   variables: {
 *      resource_id: // value for 'resource_id'
 *      parent_offering_id: // value for 'parent_offering_id'
 *      step_source_id: // value for 'step_source_id'
 *      createrRegistration: // value for 'createrRegistration'
 *   },
 * });
 */
export function useCreateRegistrationUpsertGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRegistrationUpsertGroupMutation,
    CreateRegistrationUpsertGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateRegistrationUpsertGroupMutation,
    CreateRegistrationUpsertGroupMutationVariables
  >(CreateRegistrationUpsertGroupDocument, options)
}
export type CreateRegistrationUpsertGroupMutationHookResult = ReturnType<
  typeof useCreateRegistrationUpsertGroupMutation
>
export type CreateRegistrationUpsertGroupMutationResult =
  Apollo.MutationResult<CreateRegistrationUpsertGroupMutation>
export type CreateRegistrationUpsertGroupMutationOptions =
  Apollo.BaseMutationOptions<
    CreateRegistrationUpsertGroupMutation,
    CreateRegistrationUpsertGroupMutationVariables
  >
export const DeletePathItemDocument = gql`
  mutation DeletePathItem($path_id: Int!, $id: Int!) {
    deletePathItem(path_id: $path_id, id: $id) {
      id
    }
  }
`
export type DeletePathItemMutationFn = Apollo.MutationFunction<
  DeletePathItemMutation,
  DeletePathItemMutationVariables
>

/**
 * __useDeletePathItemMutation__
 *
 * To run a mutation, you first call `useDeletePathItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePathItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePathItemMutation, { data, loading, error }] = useDeletePathItemMutation({
 *   variables: {
 *      path_id: // value for 'path_id'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePathItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePathItemMutation,
    DeletePathItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeletePathItemMutation,
    DeletePathItemMutationVariables
  >(DeletePathItemDocument, options)
}
export type DeletePathItemMutationHookResult = ReturnType<
  typeof useDeletePathItemMutation
>
export type DeletePathItemMutationResult =
  Apollo.MutationResult<DeletePathItemMutation>
export type DeletePathItemMutationOptions = Apollo.BaseMutationOptions<
  DeletePathItemMutation,
  DeletePathItemMutationVariables
>
export const ExecuteSyncPlanDocument = gql`
  mutation ExecuteSyncPlan($sync_plan: PathSyncPlanInput!) {
    executePathSyncPlan(sync_plan: $sync_plan)
  }
`
export type ExecuteSyncPlanMutationFn = Apollo.MutationFunction<
  ExecuteSyncPlanMutation,
  ExecuteSyncPlanMutationVariables
>

/**
 * __useExecuteSyncPlanMutation__
 *
 * To run a mutation, you first call `useExecuteSyncPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteSyncPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeSyncPlanMutation, { data, loading, error }] = useExecuteSyncPlanMutation({
 *   variables: {
 *      sync_plan: // value for 'sync_plan'
 *   },
 * });
 */
export function useExecuteSyncPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExecuteSyncPlanMutation,
    ExecuteSyncPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ExecuteSyncPlanMutation,
    ExecuteSyncPlanMutationVariables
  >(ExecuteSyncPlanDocument, options)
}
export type ExecuteSyncPlanMutationHookResult = ReturnType<
  typeof useExecuteSyncPlanMutation
>
export type ExecuteSyncPlanMutationResult =
  Apollo.MutationResult<ExecuteSyncPlanMutation>
export type ExecuteSyncPlanMutationOptions = Apollo.BaseMutationOptions<
  ExecuteSyncPlanMutation,
  ExecuteSyncPlanMutationVariables
>
export const PastePathItemDocument = gql`
  mutation PastePathItem(
    $pathId: Int!
    $itemId: Int!
    $parentId: Int
    $order: Int!
  ) {
    pastePathItem(
      path_id: $pathId
      item_id: $itemId
      parent_id: $parentId
      order: $order
    ) {
      ...PathItem
      items(first: 50) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...PathItem
          }
        }
      }
    }
  }
  ${PathItemFragmentDoc}
`
export type PastePathItemMutationFn = Apollo.MutationFunction<
  PastePathItemMutation,
  PastePathItemMutationVariables
>

/**
 * __usePastePathItemMutation__
 *
 * To run a mutation, you first call `usePastePathItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePastePathItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pastePathItemMutation, { data, loading, error }] = usePastePathItemMutation({
 *   variables: {
 *      pathId: // value for 'pathId'
 *      itemId: // value for 'itemId'
 *      parentId: // value for 'parentId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePastePathItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PastePathItemMutation,
    PastePathItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PastePathItemMutation,
    PastePathItemMutationVariables
  >(PastePathItemDocument, options)
}
export type PastePathItemMutationHookResult = ReturnType<
  typeof usePastePathItemMutation
>
export type PastePathItemMutationResult =
  Apollo.MutationResult<PastePathItemMutation>
export type PastePathItemMutationOptions = Apollo.BaseMutationOptions<
  PastePathItemMutation,
  PastePathItemMutationVariables
>
export const PathItemDocument = gql`
  query PathItem(
    $id: Int
    $path_id: Int
    $cohort_id: Int
    $user_id: Int
    $source_id: Int
  ) {
    pathItem(
      id: $id
      path_id: $path_id
      cohort_id: $cohort_id
      user_id: $user_id
      source_id: $source_id
    ) {
      ...PathItem
    }
  }
  ${PathItemFragmentDoc}
`

/**
 * __usePathItemQuery__
 *
 * To run a query within a React component, call `usePathItemQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *      path_id: // value for 'path_id'
 *      cohort_id: // value for 'cohort_id'
 *      user_id: // value for 'user_id'
 *      source_id: // value for 'source_id'
 *   },
 * });
 */
export function usePathItemQuery(
  baseOptions?: Apollo.QueryHookOptions<PathItemQuery, PathItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PathItemQuery, PathItemQueryVariables>(
    PathItemDocument,
    options
  )
}
export function usePathItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PathItemQuery,
    PathItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PathItemQuery, PathItemQueryVariables>(
    PathItemDocument,
    options
  )
}
export function usePathItemSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PathItemQuery,
    PathItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PathItemQuery, PathItemQueryVariables>(
    PathItemDocument,
    options
  )
}
export type PathItemQueryHookResult = ReturnType<typeof usePathItemQuery>
export type PathItemLazyQueryHookResult = ReturnType<
  typeof usePathItemLazyQuery
>
export type PathItemSuspenseQueryHookResult = ReturnType<
  typeof usePathItemSuspenseQuery
>
export type PathItemQueryResult = Apollo.QueryResult<
  PathItemQuery,
  PathItemQueryVariables
>
export const PathItemsDocument = gql`
  query PathItems(
    $pathId: Int
    $cohortId: Int
    $sourceId: Int
    $lessonSourceId: Int
    $cursor: String
  ) {
    pathItems(
      path_id: $pathId
      cohort_id: $cohortId
      source_id: $sourceId
      lesson_source_id: $lessonSourceId
      first: 1042
    ) {
      edges {
        node {
          ...PathItem
        }
      }
    }
  }
  ${PathItemFragmentDoc}
`

/**
 * __usePathItemsQuery__
 *
 * To run a query within a React component, call `usePathItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathItemsQuery({
 *   variables: {
 *      pathId: // value for 'pathId'
 *      cohortId: // value for 'cohortId'
 *      sourceId: // value for 'sourceId'
 *      lessonSourceId: // value for 'lessonSourceId'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function usePathItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<PathItemsQuery, PathItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PathItemsQuery, PathItemsQueryVariables>(
    PathItemsDocument,
    options
  )
}
export function usePathItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PathItemsQuery,
    PathItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PathItemsQuery, PathItemsQueryVariables>(
    PathItemsDocument,
    options
  )
}
export function usePathItemsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PathItemsQuery,
    PathItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PathItemsQuery, PathItemsQueryVariables>(
    PathItemsDocument,
    options
  )
}
export type PathItemsQueryHookResult = ReturnType<typeof usePathItemsQuery>
export type PathItemsLazyQueryHookResult = ReturnType<
  typeof usePathItemsLazyQuery
>
export type PathItemsSuspenseQueryHookResult = ReturnType<
  typeof usePathItemsSuspenseQuery
>
export type PathItemsQueryResult = Apollo.QueryResult<
  PathItemsQuery,
  PathItemsQueryVariables
>
export const PathPublishDocument = gql`
  query PathPublish($cohort_id: Int) {
    path(cohort_id: $cohort_id) {
      id
      publish {
        path_id
        published
        last_published_date
      }
    }
  }
`

/**
 * __usePathPublishQuery__
 *
 * To run a query within a React component, call `usePathPublishQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathPublishQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathPublishQuery({
 *   variables: {
 *      cohort_id: // value for 'cohort_id'
 *   },
 * });
 */
export function usePathPublishQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PathPublishQuery,
    PathPublishQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PathPublishQuery, PathPublishQueryVariables>(
    PathPublishDocument,
    options
  )
}
export function usePathPublishLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PathPublishQuery,
    PathPublishQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PathPublishQuery, PathPublishQueryVariables>(
    PathPublishDocument,
    options
  )
}
export function usePathPublishSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PathPublishQuery,
    PathPublishQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PathPublishQuery, PathPublishQueryVariables>(
    PathPublishDocument,
    options
  )
}
export type PathPublishQueryHookResult = ReturnType<typeof usePathPublishQuery>
export type PathPublishLazyQueryHookResult = ReturnType<
  typeof usePathPublishLazyQuery
>
export type PathPublishSuspenseQueryHookResult = ReturnType<
  typeof usePathPublishSuspenseQuery
>
export type PathPublishQueryResult = Apollo.QueryResult<
  PathPublishQuery,
  PathPublishQueryVariables
>
export const PathDocument = gql`
  query Path($id: Int, $cohort_id: Int) {
    path(id: $id, cohort_id: $cohort_id) {
      ...PathItems
      schedule {
        start {
          behavior
          date
        }
        end {
          behavior
          date
        }
      }
    }
  }
  ${PathItemsFragmentDoc}
`

/**
 * __usePathQuery__
 *
 * To run a query within a React component, call `usePathQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathQuery({
 *   variables: {
 *      id: // value for 'id'
 *      cohort_id: // value for 'cohort_id'
 *   },
 * });
 */
export function usePathQuery(
  baseOptions?: Apollo.QueryHookOptions<PathQuery, PathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PathQuery, PathQueryVariables>(PathDocument, options)
}
export function usePathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PathQuery, PathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PathQuery, PathQueryVariables>(
    PathDocument,
    options
  )
}
export function usePathSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PathQuery, PathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PathQuery, PathQueryVariables>(
    PathDocument,
    options
  )
}
export type PathQueryHookResult = ReturnType<typeof usePathQuery>
export type PathLazyQueryHookResult = ReturnType<typeof usePathLazyQuery>
export type PathSuspenseQueryHookResult = ReturnType<
  typeof usePathSuspenseQuery
>
export type PathQueryResult = Apollo.QueryResult<PathQuery, PathQueryVariables>
export const SyncPathDocument = gql`
  mutation SyncPath($id: Int!) {
    syncPath(id: $id) {
      ...PathItems
    }
  }
  ${PathItemsFragmentDoc}
`
export type SyncPathMutationFn = Apollo.MutationFunction<
  SyncPathMutation,
  SyncPathMutationVariables
>

/**
 * __useSyncPathMutation__
 *
 * To run a mutation, you first call `useSyncPathMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncPathMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncPathMutation, { data, loading, error }] = useSyncPathMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSyncPathMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncPathMutation,
    SyncPathMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SyncPathMutation, SyncPathMutationVariables>(
    SyncPathDocument,
    options
  )
}
export type SyncPathMutationHookResult = ReturnType<typeof useSyncPathMutation>
export type SyncPathMutationResult = Apollo.MutationResult<SyncPathMutation>
export type SyncPathMutationOptions = Apollo.BaseMutationOptions<
  SyncPathMutation,
  SyncPathMutationVariables
>
export const PathSyncPlanDocument = gql`
  query PathSyncPlan($sync_plan: PathSyncPlanInput!) {
    pathSyncPlan(sync_plan: $sync_plan) {
      from_path_id
      to_path_id
      included_changes {
        ...PathSyncPlanChange
      }
      excluded_changes {
        ...PathSyncPlanChange
      }
      hash
    }
  }
  ${PathSyncPlanChangeFragmentDoc}
`

/**
 * __usePathSyncPlanQuery__
 *
 * To run a query within a React component, call `usePathSyncPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathSyncPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathSyncPlanQuery({
 *   variables: {
 *      sync_plan: // value for 'sync_plan'
 *   },
 * });
 */
export function usePathSyncPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    PathSyncPlanQuery,
    PathSyncPlanQueryVariables
  > &
    (
      | { variables: PathSyncPlanQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PathSyncPlanQuery, PathSyncPlanQueryVariables>(
    PathSyncPlanDocument,
    options
  )
}
export function usePathSyncPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PathSyncPlanQuery,
    PathSyncPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PathSyncPlanQuery, PathSyncPlanQueryVariables>(
    PathSyncPlanDocument,
    options
  )
}
export function usePathSyncPlanSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PathSyncPlanQuery,
    PathSyncPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PathSyncPlanQuery, PathSyncPlanQueryVariables>(
    PathSyncPlanDocument,
    options
  )
}
export type PathSyncPlanQueryHookResult = ReturnType<
  typeof usePathSyncPlanQuery
>
export type PathSyncPlanLazyQueryHookResult = ReturnType<
  typeof usePathSyncPlanLazyQuery
>
export type PathSyncPlanSuspenseQueryHookResult = ReturnType<
  typeof usePathSyncPlanSuspenseQuery
>
export type PathSyncPlanQueryResult = Apollo.QueryResult<
  PathSyncPlanQuery,
  PathSyncPlanQueryVariables
>
export const ReorderPathItemsDocument = gql`
  mutation ReorderPathItems($path_id: Int!, $items: [PathItemOrder]!) {
    reorderPathItems(path_id: $path_id, items: $items)
  }
`
export type ReorderPathItemsMutationFn = Apollo.MutationFunction<
  ReorderPathItemsMutation,
  ReorderPathItemsMutationVariables
>

/**
 * __useReorderPathItemsMutation__
 *
 * To run a mutation, you first call `useReorderPathItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderPathItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderPathItemsMutation, { data, loading, error }] = useReorderPathItemsMutation({
 *   variables: {
 *      path_id: // value for 'path_id'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useReorderPathItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderPathItemsMutation,
    ReorderPathItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ReorderPathItemsMutation,
    ReorderPathItemsMutationVariables
  >(ReorderPathItemsDocument, options)
}
export type ReorderPathItemsMutationHookResult = ReturnType<
  typeof useReorderPathItemsMutation
>
export type ReorderPathItemsMutationResult =
  Apollo.MutationResult<ReorderPathItemsMutation>
export type ReorderPathItemsMutationOptions = Apollo.BaseMutationOptions<
  ReorderPathItemsMutation,
  ReorderPathItemsMutationVariables
>
export const ResetPathItemForUserDocument = gql`
  mutation ResetPathItemForUser($id: Int!, $path_id: Int!) {
    resetPathItemForUser(id: $id, path_id: $path_id) {
      ...PathItem
    }
  }
  ${PathItemFragmentDoc}
`
export type ResetPathItemForUserMutationFn = Apollo.MutationFunction<
  ResetPathItemForUserMutation,
  ResetPathItemForUserMutationVariables
>

/**
 * __useResetPathItemForUserMutation__
 *
 * To run a mutation, you first call `useResetPathItemForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPathItemForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPathItemForUserMutation, { data, loading, error }] = useResetPathItemForUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      path_id: // value for 'path_id'
 *   },
 * });
 */
export function useResetPathItemForUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPathItemForUserMutation,
    ResetPathItemForUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetPathItemForUserMutation,
    ResetPathItemForUserMutationVariables
  >(ResetPathItemForUserDocument, options)
}
export type ResetPathItemForUserMutationHookResult = ReturnType<
  typeof useResetPathItemForUserMutation
>
export type ResetPathItemForUserMutationResult =
  Apollo.MutationResult<ResetPathItemForUserMutation>
export type ResetPathItemForUserMutationOptions = Apollo.BaseMutationOptions<
  ResetPathItemForUserMutation,
  ResetPathItemForUserMutationVariables
>
export const ResetPathItemDocument = gql`
  mutation ResetPathItem($id: Int!, $path_id: Int!) {
    resetPathItem(id: $id, path_id: $path_id) {
      ...PathItem
      path {
        id
        progress
        points {
          path_id
          total
          earned
          total_points_possible
          percentage
          has_points
        }
        next_step_id
        completion_date
      }
    }
  }
  ${PathItemFragmentDoc}
`
export type ResetPathItemMutationFn = Apollo.MutationFunction<
  ResetPathItemMutation,
  ResetPathItemMutationVariables
>

/**
 * __useResetPathItemMutation__
 *
 * To run a mutation, you first call `useResetPathItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPathItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPathItemMutation, { data, loading, error }] = useResetPathItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      path_id: // value for 'path_id'
 *   },
 * });
 */
export function useResetPathItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPathItemMutation,
    ResetPathItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetPathItemMutation,
    ResetPathItemMutationVariables
  >(ResetPathItemDocument, options)
}
export type ResetPathItemMutationHookResult = ReturnType<
  typeof useResetPathItemMutation
>
export type ResetPathItemMutationResult =
  Apollo.MutationResult<ResetPathItemMutation>
export type ResetPathItemMutationOptions = Apollo.BaseMutationOptions<
  ResetPathItemMutation,
  ResetPathItemMutationVariables
>
export const SingleCohortPathDocument = gql`
  query SingleCohortPath($cohort_id: Int!) {
    path(cohort_id: $cohort_id) {
      ...SingleCohortPath
    }
  }
  ${SingleCohortPathFragmentDoc}
`

/**
 * __useSingleCohortPathQuery__
 *
 * To run a query within a React component, call `useSingleCohortPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleCohortPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleCohortPathQuery({
 *   variables: {
 *      cohort_id: // value for 'cohort_id'
 *   },
 * });
 */
export function useSingleCohortPathQuery(
  baseOptions: Apollo.QueryHookOptions<
    SingleCohortPathQuery,
    SingleCohortPathQueryVariables
  > &
    (
      | { variables: SingleCohortPathQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SingleCohortPathQuery, SingleCohortPathQueryVariables>(
    SingleCohortPathDocument,
    options
  )
}
export function useSingleCohortPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SingleCohortPathQuery,
    SingleCohortPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SingleCohortPathQuery,
    SingleCohortPathQueryVariables
  >(SingleCohortPathDocument, options)
}
export function useSingleCohortPathSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SingleCohortPathQuery,
    SingleCohortPathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SingleCohortPathQuery,
    SingleCohortPathQueryVariables
  >(SingleCohortPathDocument, options)
}
export type SingleCohortPathQueryHookResult = ReturnType<
  typeof useSingleCohortPathQuery
>
export type SingleCohortPathLazyQueryHookResult = ReturnType<
  typeof useSingleCohortPathLazyQuery
>
export type SingleCohortPathSuspenseQueryHookResult = ReturnType<
  typeof useSingleCohortPathSuspenseQuery
>
export type SingleCohortPathQueryResult = Apollo.QueryResult<
  SingleCohortPathQuery,
  SingleCohortPathQueryVariables
>
export const SkipPathItemForUserDocument = gql`
  mutation SkipPathItemForUser($id: Int!, $path_id: Int!) {
    skipPathItemForUser(id: $id, path_id: $path_id) {
      ...PathItem
    }
  }
  ${PathItemFragmentDoc}
`
export type SkipPathItemForUserMutationFn = Apollo.MutationFunction<
  SkipPathItemForUserMutation,
  SkipPathItemForUserMutationVariables
>

/**
 * __useSkipPathItemForUserMutation__
 *
 * To run a mutation, you first call `useSkipPathItemForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipPathItemForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipPathItemForUserMutation, { data, loading, error }] = useSkipPathItemForUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      path_id: // value for 'path_id'
 *   },
 * });
 */
export function useSkipPathItemForUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SkipPathItemForUserMutation,
    SkipPathItemForUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SkipPathItemForUserMutation,
    SkipPathItemForUserMutationVariables
  >(SkipPathItemForUserDocument, options)
}
export type SkipPathItemForUserMutationHookResult = ReturnType<
  typeof useSkipPathItemForUserMutation
>
export type SkipPathItemForUserMutationResult =
  Apollo.MutationResult<SkipPathItemForUserMutation>
export type SkipPathItemForUserMutationOptions = Apollo.BaseMutationOptions<
  SkipPathItemForUserMutation,
  SkipPathItemForUserMutationVariables
>
export const UnlockPathItemDocument = gql`
  mutation UnlockPathItem(
    $id: Int!
    $path_id: Int!
    $step_lock_password: String!
  ) {
    unlockPathItem(
      id: $id
      path_id: $path_id
      step_lock_password: $step_lock_password
    ) {
      ...PathItem
    }
  }
  ${PathItemFragmentDoc}
`
export type UnlockPathItemMutationFn = Apollo.MutationFunction<
  UnlockPathItemMutation,
  UnlockPathItemMutationVariables
>

/**
 * __useUnlockPathItemMutation__
 *
 * To run a mutation, you first call `useUnlockPathItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockPathItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockPathItemMutation, { data, loading, error }] = useUnlockPathItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      path_id: // value for 'path_id'
 *      step_lock_password: // value for 'step_lock_password'
 *   },
 * });
 */
export function useUnlockPathItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlockPathItemMutation,
    UnlockPathItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnlockPathItemMutation,
    UnlockPathItemMutationVariables
  >(UnlockPathItemDocument, options)
}
export type UnlockPathItemMutationHookResult = ReturnType<
  typeof useUnlockPathItemMutation
>
export type UnlockPathItemMutationResult =
  Apollo.MutationResult<UnlockPathItemMutation>
export type UnlockPathItemMutationOptions = Apollo.BaseMutationOptions<
  UnlockPathItemMutation,
  UnlockPathItemMutationVariables
>
export const UpdatePathItemDocument = gql`
  mutation UpdatePathItem(
    $path_id: Int!
    $source_id: Int!
    $order: Int!
    $type: PathItemType!
    $name: String!
    $description: String
    $assignment_type: PathItemAssignmentType
    $is_required: Boolean
    $allow_resets: Int
    $show_grading_feedback: Int
    $completion_comment_required_status: String
    $points: Int
    $minimum_passing_score: Int
    $grading_type: String
    $is_previewable: Boolean
    $lock_password: String
    $time_to_complete: Int
    $requires_api_completion: Boolean
  ) {
    updatePathItem(
      path_id: $path_id
      source_id: $source_id
      type: $type
      name: $name
      order: $order
      description: $description
      assignment_type: $assignment_type
      is_required: $is_required
      allow_resets: $allow_resets
      show_grading_feedback: $show_grading_feedback
      completion_comment_required_status: $completion_comment_required_status
      points: $points
      minimum_passing_score: $minimum_passing_score
      grading_type: $grading_type
      is_previewable: $is_previewable
      lock_password: $lock_password
      time_to_complete: $time_to_complete
      requires_api_completion: $requires_api_completion
    ) {
      ...PathItem
    }
  }
  ${PathItemFragmentDoc}
`
export type UpdatePathItemMutationFn = Apollo.MutationFunction<
  UpdatePathItemMutation,
  UpdatePathItemMutationVariables
>

/**
 * __useUpdatePathItemMutation__
 *
 * To run a mutation, you first call `useUpdatePathItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePathItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePathItemMutation, { data, loading, error }] = useUpdatePathItemMutation({
 *   variables: {
 *      path_id: // value for 'path_id'
 *      source_id: // value for 'source_id'
 *      order: // value for 'order'
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      assignment_type: // value for 'assignment_type'
 *      is_required: // value for 'is_required'
 *      allow_resets: // value for 'allow_resets'
 *      show_grading_feedback: // value for 'show_grading_feedback'
 *      completion_comment_required_status: // value for 'completion_comment_required_status'
 *      points: // value for 'points'
 *      minimum_passing_score: // value for 'minimum_passing_score'
 *      grading_type: // value for 'grading_type'
 *      is_previewable: // value for 'is_previewable'
 *      lock_password: // value for 'lock_password'
 *      time_to_complete: // value for 'time_to_complete'
 *      requires_api_completion: // value for 'requires_api_completion'
 *   },
 * });
 */
export function useUpdatePathItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePathItemMutation,
    UpdatePathItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePathItemMutation,
    UpdatePathItemMutationVariables
  >(UpdatePathItemDocument, options)
}
export type UpdatePathItemMutationHookResult = ReturnType<
  typeof useUpdatePathItemMutation
>
export type UpdatePathItemMutationResult =
  Apollo.MutationResult<UpdatePathItemMutation>
export type UpdatePathItemMutationOptions = Apollo.BaseMutationOptions<
  UpdatePathItemMutation,
  UpdatePathItemMutationVariables
>
export const ContextDocument = gql`
  query Context {
    ...Context
  }
  ${ContextFragmentDoc}
`

/**
 * __useContextQuery__
 *
 * To run a query within a React component, call `useContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useContextQuery(
  baseOptions?: Apollo.QueryHookOptions<ContextQuery, ContextQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContextQuery, ContextQueryVariables>(
    ContextDocument,
    options
  )
}
export function useContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContextQuery, ContextQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContextQuery, ContextQueryVariables>(
    ContextDocument,
    options
  )
}
export function useContextSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContextQuery,
    ContextQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ContextQuery, ContextQueryVariables>(
    ContextDocument,
    options
  )
}
export type ContextQueryHookResult = ReturnType<typeof useContextQuery>
export type ContextLazyQueryHookResult = ReturnType<typeof useContextLazyQuery>
export type ContextSuspenseQueryHookResult = ReturnType<
  typeof useContextSuspenseQuery
>
export type ContextQueryResult = Apollo.QueryResult<
  ContextQuery,
  ContextQueryVariables
>
export const UpdatePositionedObjectsDocument = gql`
  mutation UpdatePositionedObjects(
    $context: PositioningContextInput!
    $positions: [PositionedObjectPositionInput]!
  ) {
    updatePositionedObjects(context: $context, positions: $positions)
  }
`
export type UpdatePositionedObjectsMutationFn = Apollo.MutationFunction<
  UpdatePositionedObjectsMutation,
  UpdatePositionedObjectsMutationVariables
>

/**
 * __useUpdatePositionedObjectsMutation__
 *
 * To run a mutation, you first call `useUpdatePositionedObjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePositionedObjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePositionedObjectsMutation, { data, loading, error }] = useUpdatePositionedObjectsMutation({
 *   variables: {
 *      context: // value for 'context'
 *      positions: // value for 'positions'
 *   },
 * });
 */
export function useUpdatePositionedObjectsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePositionedObjectsMutation,
    UpdatePositionedObjectsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePositionedObjectsMutation,
    UpdatePositionedObjectsMutationVariables
  >(UpdatePositionedObjectsDocument, options)
}
export type UpdatePositionedObjectsMutationHookResult = ReturnType<
  typeof useUpdatePositionedObjectsMutation
>
export type UpdatePositionedObjectsMutationResult =
  Apollo.MutationResult<UpdatePositionedObjectsMutation>
export type UpdatePositionedObjectsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePositionedObjectsMutation,
  UpdatePositionedObjectsMutationVariables
>
export const __TestRegistrationPositioningDocument = gql`
  query __TestRegistrationPositioning(
    $first: Int!
    $positioningContext: PositioningContextInput
  ) {
    me {
      id
      registrations(first: $first, positioningContext: $positioningContext) {
        edges {
          node {
            ...__TestRegistration
          }
        }
      }
    }
  }
  ${__TestRegistrationFragmentDoc}
`

/**
 * __use__TestRegistrationPositioningQuery__
 *
 * To run a query within a React component, call `use__TestRegistrationPositioningQuery` and pass it any options that fit your needs.
 * When your component renders, `use__TestRegistrationPositioningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use__TestRegistrationPositioningQuery({
 *   variables: {
 *      first: // value for 'first'
 *      positioningContext: // value for 'positioningContext'
 *   },
 * });
 */
export function use__TestRegistrationPositioningQuery(
  baseOptions: Apollo.QueryHookOptions<
    __TestRegistrationPositioningQuery,
    __TestRegistrationPositioningQueryVariables
  > &
    (
      | {
          variables: __TestRegistrationPositioningQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    __TestRegistrationPositioningQuery,
    __TestRegistrationPositioningQueryVariables
  >(__TestRegistrationPositioningDocument, options)
}
export function use__TestRegistrationPositioningLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    __TestRegistrationPositioningQuery,
    __TestRegistrationPositioningQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    __TestRegistrationPositioningQuery,
    __TestRegistrationPositioningQueryVariables
  >(__TestRegistrationPositioningDocument, options)
}
export function use__TestRegistrationPositioningSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    __TestRegistrationPositioningQuery,
    __TestRegistrationPositioningQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    __TestRegistrationPositioningQuery,
    __TestRegistrationPositioningQueryVariables
  >(__TestRegistrationPositioningDocument, options)
}
export type __TestRegistrationPositioningQueryHookResult = ReturnType<
  typeof use__TestRegistrationPositioningQuery
>
export type __TestRegistrationPositioningLazyQueryHookResult = ReturnType<
  typeof use__TestRegistrationPositioningLazyQuery
>
export type __TestRegistrationPositioningSuspenseQueryHookResult = ReturnType<
  typeof use__TestRegistrationPositioningSuspenseQuery
>
export type __TestRegistrationPositioningQueryResult = Apollo.QueryResult<
  __TestRegistrationPositioningQuery,
  __TestRegistrationPositioningQueryVariables
>
export const UpdateReferralDocument = gql`
  mutation UpdateReferral($referral: String!) {
    updateReferral(referral: $referral) {
      referral
    }
  }
`
export type UpdateReferralMutationFn = Apollo.MutationFunction<
  UpdateReferralMutation,
  UpdateReferralMutationVariables
>

/**
 * __useUpdateReferralMutation__
 *
 * To run a mutation, you first call `useUpdateReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferralMutation, { data, loading, error }] = useUpdateReferralMutation({
 *   variables: {
 *      referral: // value for 'referral'
 *   },
 * });
 */
export function useUpdateReferralMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReferralMutation,
    UpdateReferralMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateReferralMutation,
    UpdateReferralMutationVariables
  >(UpdateReferralDocument, options)
}
export type UpdateReferralMutationHookResult = ReturnType<
  typeof useUpdateReferralMutation
>
export type UpdateReferralMutationResult =
  Apollo.MutationResult<UpdateReferralMutation>
export type UpdateReferralMutationOptions = Apollo.BaseMutationOptions<
  UpdateReferralMutation,
  UpdateReferralMutationVariables
>
export const RegistrationDocument = gql`
  query Registration($group_id: Int!, $registration_id: Int) {
    group(id: $group_id) {
      ...Group
      permissions {
        can_invite_observer
        can_invite_student
        can_invite_moderator
        can_invite_teacher
        can_invite_editor
      }
      registration(id: $registration_id) {
        ...Registration
        user {
          ...User
        }
      }
    }
  }
  ${GroupFragmentDoc}
  ${RegistrationFragmentDoc}
  ${UserFragmentDoc}
`

/**
 * __useRegistrationQuery__
 *
 * To run a query within a React component, call `useRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationQuery({
 *   variables: {
 *      group_id: // value for 'group_id'
 *      registration_id: // value for 'registration_id'
 *   },
 * });
 */
export function useRegistrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegistrationQuery,
    RegistrationQueryVariables
  > &
    (
      | { variables: RegistrationQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RegistrationQuery, RegistrationQueryVariables>(
    RegistrationDocument,
    options
  )
}
export function useRegistrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegistrationQuery,
    RegistrationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RegistrationQuery, RegistrationQueryVariables>(
    RegistrationDocument,
    options
  )
}
export function useRegistrationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RegistrationQuery,
    RegistrationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<RegistrationQuery, RegistrationQueryVariables>(
    RegistrationDocument,
    options
  )
}
export type RegistrationQueryHookResult = ReturnType<
  typeof useRegistrationQuery
>
export type RegistrationLazyQueryHookResult = ReturnType<
  typeof useRegistrationLazyQuery
>
export type RegistrationSuspenseQueryHookResult = ReturnType<
  typeof useRegistrationSuspenseQuery
>
export type RegistrationQueryResult = Apollo.QueryResult<
  RegistrationQuery,
  RegistrationQueryVariables
>
export const RegistrationsDocument = gql`
  query Registrations(
    $groupId: Int!
    $roleFilter: RoleFilter
    $dateLastActive: DateFilter
    $dateJoined: DateFilter
    $orderBy: [RegistrationOrderByInput]
    $cursor: String
  ) {
    group(id: $groupId) {
      ...Group
      resource {
        ...Resource
      }
      registrations(
        first: 50
        role_filter: $roleFilter
        last_active: $dateLastActive
        created: $dateJoined
        orderBy: $orderBy
        after: $cursor
      ) {
        total
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...Registration
          }
        }
      }
    }
  }
  ${GroupFragmentDoc}
  ${ResourceFragmentDoc}
  ${RegistrationFragmentDoc}
`

/**
 * __useRegistrationsQuery__
 *
 * To run a query within a React component, call `useRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      roleFilter: // value for 'roleFilter'
 *      dateLastActive: // value for 'dateLastActive'
 *      dateJoined: // value for 'dateJoined'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useRegistrationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegistrationsQuery,
    RegistrationsQueryVariables
  > &
    (
      | { variables: RegistrationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RegistrationsQuery, RegistrationsQueryVariables>(
    RegistrationsDocument,
    options
  )
}
export function useRegistrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegistrationsQuery,
    RegistrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RegistrationsQuery, RegistrationsQueryVariables>(
    RegistrationsDocument,
    options
  )
}
export function useRegistrationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RegistrationsQuery,
    RegistrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    RegistrationsQuery,
    RegistrationsQueryVariables
  >(RegistrationsDocument, options)
}
export type RegistrationsQueryHookResult = ReturnType<
  typeof useRegistrationsQuery
>
export type RegistrationsLazyQueryHookResult = ReturnType<
  typeof useRegistrationsLazyQuery
>
export type RegistrationsSuspenseQueryHookResult = ReturnType<
  typeof useRegistrationsSuspenseQuery
>
export type RegistrationsQueryResult = Apollo.QueryResult<
  RegistrationsQuery,
  RegistrationsQueryVariables
>
export const UserProfileRegistrationsDocument = gql`
  query UserProfileRegistrations(
    $cursor: String
    $first: Int = 50
    $id: Int
    $role_filter: RoleFilter
    $progress_filter: ProgressFilter
    $parent_cohort_filter: IDFilter
    $hasParentRegistration: Boolean
    $orderBy: [RegistrationOrderByInput]
  ) {
    user(id: $id) {
      ...User
      registrations(
        first: $first
        after: $cursor
        role_filter: $role_filter
        progress_filter: $progress_filter
        parent_cohort_filter: $parent_cohort_filter
        has_parent_registration: $hasParentRegistration
        orderBy: $orderBy
      ) {
        total
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...Registration
            resource {
              ...Resource
            }
            group {
              ...Group
            }
            path {
              id
              progress
              completion_date
            }
          }
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${RegistrationFragmentDoc}
  ${ResourceFragmentDoc}
  ${GroupFragmentDoc}
`

/**
 * __useUserProfileRegistrationsQuery__
 *
 * To run a query within a React component, call `useUserProfileRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileRegistrationsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *      id: // value for 'id'
 *      role_filter: // value for 'role_filter'
 *      progress_filter: // value for 'progress_filter'
 *      parent_cohort_filter: // value for 'parent_cohort_filter'
 *      hasParentRegistration: // value for 'hasParentRegistration'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUserProfileRegistrationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserProfileRegistrationsQuery,
    UserProfileRegistrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserProfileRegistrationsQuery,
    UserProfileRegistrationsQueryVariables
  >(UserProfileRegistrationsDocument, options)
}
export function useUserProfileRegistrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserProfileRegistrationsQuery,
    UserProfileRegistrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserProfileRegistrationsQuery,
    UserProfileRegistrationsQueryVariables
  >(UserProfileRegistrationsDocument, options)
}
export function useUserProfileRegistrationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserProfileRegistrationsQuery,
    UserProfileRegistrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    UserProfileRegistrationsQuery,
    UserProfileRegistrationsQueryVariables
  >(UserProfileRegistrationsDocument, options)
}
export type UserProfileRegistrationsQueryHookResult = ReturnType<
  typeof useUserProfileRegistrationsQuery
>
export type UserProfileRegistrationsLazyQueryHookResult = ReturnType<
  typeof useUserProfileRegistrationsLazyQuery
>
export type UserProfileRegistrationsSuspenseQueryHookResult = ReturnType<
  typeof useUserProfileRegistrationsSuspenseQuery
>
export type UserProfileRegistrationsQueryResult = Apollo.QueryResult<
  UserProfileRegistrationsQuery,
  UserProfileRegistrationsQueryVariables
>
export const ResourceCopyConfigDocument = gql`
  query ResourceCopyConfig($key: String!) {
    resourceCopyConfig(key: $key) {
      ...ResourceCopyConfig
    }
  }
  ${ResourceCopyConfigFragmentDoc}
`

/**
 * __useResourceCopyConfigQuery__
 *
 * To run a query within a React component, call `useResourceCopyConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceCopyConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceCopyConfigQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useResourceCopyConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceCopyConfigQuery,
    ResourceCopyConfigQueryVariables
  > &
    (
      | { variables: ResourceCopyConfigQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ResourceCopyConfigQuery,
    ResourceCopyConfigQueryVariables
  >(ResourceCopyConfigDocument, options)
}
export function useResourceCopyConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceCopyConfigQuery,
    ResourceCopyConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ResourceCopyConfigQuery,
    ResourceCopyConfigQueryVariables
  >(ResourceCopyConfigDocument, options)
}
export function useResourceCopyConfigSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ResourceCopyConfigQuery,
    ResourceCopyConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ResourceCopyConfigQuery,
    ResourceCopyConfigQueryVariables
  >(ResourceCopyConfigDocument, options)
}
export type ResourceCopyConfigQueryHookResult = ReturnType<
  typeof useResourceCopyConfigQuery
>
export type ResourceCopyConfigLazyQueryHookResult = ReturnType<
  typeof useResourceCopyConfigLazyQuery
>
export type ResourceCopyConfigSuspenseQueryHookResult = ReturnType<
  typeof useResourceCopyConfigSuspenseQuery
>
export type ResourceCopyConfigQueryResult = Apollo.QueryResult<
  ResourceCopyConfigQuery,
  ResourceCopyConfigQueryVariables
>
export const ResourceCopyConfigConnectionsDocument = gql`
  query ResourceCopyConfigConnections($first: Int!, $keys: [String]!) {
    resourceCopyConfigConnections(first: $first, keys: $keys) {
      edges {
        node {
          ...ResourceCopyConfig
        }
      }
    }
  }
  ${ResourceCopyConfigFragmentDoc}
`

/**
 * __useResourceCopyConfigConnectionsQuery__
 *
 * To run a query within a React component, call `useResourceCopyConfigConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceCopyConfigConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceCopyConfigConnectionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      keys: // value for 'keys'
 *   },
 * });
 */
export function useResourceCopyConfigConnectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceCopyConfigConnectionsQuery,
    ResourceCopyConfigConnectionsQueryVariables
  > &
    (
      | {
          variables: ResourceCopyConfigConnectionsQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ResourceCopyConfigConnectionsQuery,
    ResourceCopyConfigConnectionsQueryVariables
  >(ResourceCopyConfigConnectionsDocument, options)
}
export function useResourceCopyConfigConnectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceCopyConfigConnectionsQuery,
    ResourceCopyConfigConnectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ResourceCopyConfigConnectionsQuery,
    ResourceCopyConfigConnectionsQueryVariables
  >(ResourceCopyConfigConnectionsDocument, options)
}
export function useResourceCopyConfigConnectionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ResourceCopyConfigConnectionsQuery,
    ResourceCopyConfigConnectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ResourceCopyConfigConnectionsQuery,
    ResourceCopyConfigConnectionsQueryVariables
  >(ResourceCopyConfigConnectionsDocument, options)
}
export type ResourceCopyConfigConnectionsQueryHookResult = ReturnType<
  typeof useResourceCopyConfigConnectionsQuery
>
export type ResourceCopyConfigConnectionsLazyQueryHookResult = ReturnType<
  typeof useResourceCopyConfigConnectionsLazyQuery
>
export type ResourceCopyConfigConnectionsSuspenseQueryHookResult = ReturnType<
  typeof useResourceCopyConfigConnectionsSuspenseQuery
>
export type ResourceCopyConfigConnectionsQueryResult = Apollo.QueryResult<
  ResourceCopyConfigConnectionsQuery,
  ResourceCopyConfigConnectionsQueryVariables
>
export const BlurredThemeBackgroundImageDocument = gql`
  query BlurredThemeBackgroundImage(
    $url: String!
    $width: Int
    $height: Int
    $blur: Int
    $high: Int
    $blend: Int
    $balph: Int
    $fm: String
    $q: Int
    $bm: String
  ) {
    media {
      image(
        url: $url
        width: $width
        height: $height
        blur: $blur
        high: $high
        blend: $blend
        balph: $balph
        fm: $fm
        q: $q
        bm: $bm
      )
    }
  }
`

/**
 * __useBlurredThemeBackgroundImageQuery__
 *
 * To run a query within a React component, call `useBlurredThemeBackgroundImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlurredThemeBackgroundImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlurredThemeBackgroundImageQuery({
 *   variables: {
 *      url: // value for 'url'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      blur: // value for 'blur'
 *      high: // value for 'high'
 *      blend: // value for 'blend'
 *      balph: // value for 'balph'
 *      fm: // value for 'fm'
 *      q: // value for 'q'
 *      bm: // value for 'bm'
 *   },
 * });
 */
export function useBlurredThemeBackgroundImageQuery(
  baseOptions: Apollo.QueryHookOptions<
    BlurredThemeBackgroundImageQuery,
    BlurredThemeBackgroundImageQueryVariables
  > &
    (
      | { variables: BlurredThemeBackgroundImageQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BlurredThemeBackgroundImageQuery,
    BlurredThemeBackgroundImageQueryVariables
  >(BlurredThemeBackgroundImageDocument, options)
}
export function useBlurredThemeBackgroundImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BlurredThemeBackgroundImageQuery,
    BlurredThemeBackgroundImageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BlurredThemeBackgroundImageQuery,
    BlurredThemeBackgroundImageQueryVariables
  >(BlurredThemeBackgroundImageDocument, options)
}
export function useBlurredThemeBackgroundImageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BlurredThemeBackgroundImageQuery,
    BlurredThemeBackgroundImageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BlurredThemeBackgroundImageQuery,
    BlurredThemeBackgroundImageQueryVariables
  >(BlurredThemeBackgroundImageDocument, options)
}
export type BlurredThemeBackgroundImageQueryHookResult = ReturnType<
  typeof useBlurredThemeBackgroundImageQuery
>
export type BlurredThemeBackgroundImageLazyQueryHookResult = ReturnType<
  typeof useBlurredThemeBackgroundImageLazyQuery
>
export type BlurredThemeBackgroundImageSuspenseQueryHookResult = ReturnType<
  typeof useBlurredThemeBackgroundImageSuspenseQuery
>
export type BlurredThemeBackgroundImageQueryResult = Apollo.QueryResult<
  BlurredThemeBackgroundImageQuery,
  BlurredThemeBackgroundImageQueryVariables
>
export const CreateSpaceDocument = gql`
  mutation CreateSpace(
    $name: String!
    $subdomain: String!
    $copy: [String]
    $utm_source: String
    $utm_medium: String
    $utm_campaign: String
    $theme: ThemeInput
  ) {
    createSchool(
      name: $name
      subdomain: $subdomain
      copy: $copy
      utm_source: $utm_source
      utm_medium: $utm_medium
      utm_campaign: $utm_campaign
      theme: $theme
    ) {
      ...CreatedSpace
    }
  }
  ${CreatedSpaceFragmentDoc}
`
export type CreateSpaceMutationFn = Apollo.MutationFunction<
  CreateSpaceMutation,
  CreateSpaceMutationVariables
>

/**
 * __useCreateSpaceMutation__
 *
 * To run a mutation, you first call `useCreateSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpaceMutation, { data, loading, error }] = useCreateSpaceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      subdomain: // value for 'subdomain'
 *      copy: // value for 'copy'
 *      utm_source: // value for 'utm_source'
 *      utm_medium: // value for 'utm_medium'
 *      utm_campaign: // value for 'utm_campaign'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useCreateSpaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSpaceMutation,
    CreateSpaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSpaceMutation, CreateSpaceMutationVariables>(
    CreateSpaceDocument,
    options
  )
}
export type CreateSpaceMutationHookResult = ReturnType<
  typeof useCreateSpaceMutation
>
export type CreateSpaceMutationResult =
  Apollo.MutationResult<CreateSpaceMutation>
export type CreateSpaceMutationOptions = Apollo.BaseMutationOptions<
  CreateSpaceMutation,
  CreateSpaceMutationVariables
>
export const DeferOrExecuteResourceCopyConfigDocument = gql`
  mutation DeferOrExecuteResourceCopyConfig(
    $copyCode: String!
    $targetSchoolId: Int!
  ) {
    deferOrExecuteResourceCopyConfig(
      copy_code: $copyCode
      target_school_id: $targetSchoolId
    ) {
      defer
      execute
    }
  }
`
export type DeferOrExecuteResourceCopyConfigMutationFn =
  Apollo.MutationFunction<
    DeferOrExecuteResourceCopyConfigMutation,
    DeferOrExecuteResourceCopyConfigMutationVariables
  >

/**
 * __useDeferOrExecuteResourceCopyConfigMutation__
 *
 * To run a mutation, you first call `useDeferOrExecuteResourceCopyConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeferOrExecuteResourceCopyConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deferOrExecuteResourceCopyConfigMutation, { data, loading, error }] = useDeferOrExecuteResourceCopyConfigMutation({
 *   variables: {
 *      copyCode: // value for 'copyCode'
 *      targetSchoolId: // value for 'targetSchoolId'
 *   },
 * });
 */
export function useDeferOrExecuteResourceCopyConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeferOrExecuteResourceCopyConfigMutation,
    DeferOrExecuteResourceCopyConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeferOrExecuteResourceCopyConfigMutation,
    DeferOrExecuteResourceCopyConfigMutationVariables
  >(DeferOrExecuteResourceCopyConfigDocument, options)
}
export type DeferOrExecuteResourceCopyConfigMutationHookResult = ReturnType<
  typeof useDeferOrExecuteResourceCopyConfigMutation
>
export type DeferOrExecuteResourceCopyConfigMutationResult =
  Apollo.MutationResult<DeferOrExecuteResourceCopyConfigMutation>
export type DeferOrExecuteResourceCopyConfigMutationOptions =
  Apollo.BaseMutationOptions<
    DeferOrExecuteResourceCopyConfigMutation,
    DeferOrExecuteResourceCopyConfigMutationVariables
  >
export const SpaceBillingPlanSetDocument = gql`
  query SpaceBillingPlanSet {
    account {
      id
      billing_plan_set {
        id
        name
        is_default
        plans {
          ...SpaceBillingPlanSetPlan
        }
      }
    }
  }
  ${SpaceBillingPlanSetPlanFragmentDoc}
`

/**
 * __useSpaceBillingPlanSetQuery__
 *
 * To run a query within a React component, call `useSpaceBillingPlanSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceBillingPlanSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceBillingPlanSetQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpaceBillingPlanSetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpaceBillingPlanSetQuery,
    SpaceBillingPlanSetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SpaceBillingPlanSetQuery,
    SpaceBillingPlanSetQueryVariables
  >(SpaceBillingPlanSetDocument, options)
}
export function useSpaceBillingPlanSetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpaceBillingPlanSetQuery,
    SpaceBillingPlanSetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SpaceBillingPlanSetQuery,
    SpaceBillingPlanSetQueryVariables
  >(SpaceBillingPlanSetDocument, options)
}
export function useSpaceBillingPlanSetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SpaceBillingPlanSetQuery,
    SpaceBillingPlanSetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SpaceBillingPlanSetQuery,
    SpaceBillingPlanSetQueryVariables
  >(SpaceBillingPlanSetDocument, options)
}
export type SpaceBillingPlanSetQueryHookResult = ReturnType<
  typeof useSpaceBillingPlanSetQuery
>
export type SpaceBillingPlanSetLazyQueryHookResult = ReturnType<
  typeof useSpaceBillingPlanSetLazyQuery
>
export type SpaceBillingPlanSetSuspenseQueryHookResult = ReturnType<
  typeof useSpaceBillingPlanSetSuspenseQuery
>
export type SpaceBillingPlanSetQueryResult = Apollo.QueryResult<
  SpaceBillingPlanSetQuery,
  SpaceBillingPlanSetQueryVariables
>
export const SpaceSsoLinkDocument = gql`
  query SpaceSSOLink($spaceId: Int!, $pathname: String!) {
    context(school_id: $spaceId) {
      school {
        id
        url(pathname: $pathname)
        sso_link(pathname: $pathname)
      }
    }
  }
`

/**
 * __useSpaceSsoLinkQuery__
 *
 * To run a query within a React component, call `useSpaceSsoLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceSsoLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceSsoLinkQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *      pathname: // value for 'pathname'
 *   },
 * });
 */
export function useSpaceSsoLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpaceSsoLinkQuery,
    SpaceSsoLinkQueryVariables
  > &
    (
      | { variables: SpaceSsoLinkQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SpaceSsoLinkQuery, SpaceSsoLinkQueryVariables>(
    SpaceSsoLinkDocument,
    options
  )
}
export function useSpaceSsoLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpaceSsoLinkQuery,
    SpaceSsoLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SpaceSsoLinkQuery, SpaceSsoLinkQueryVariables>(
    SpaceSsoLinkDocument,
    options
  )
}
export function useSpaceSsoLinkSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SpaceSsoLinkQuery,
    SpaceSsoLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SpaceSsoLinkQuery, SpaceSsoLinkQueryVariables>(
    SpaceSsoLinkDocument,
    options
  )
}
export type SpaceSsoLinkQueryHookResult = ReturnType<
  typeof useSpaceSsoLinkQuery
>
export type SpaceSsoLinkLazyQueryHookResult = ReturnType<
  typeof useSpaceSsoLinkLazyQuery
>
export type SpaceSsoLinkSuspenseQueryHookResult = ReturnType<
  typeof useSpaceSsoLinkSuspenseQuery
>
export type SpaceSsoLinkQueryResult = Apollo.QueryResult<
  SpaceSsoLinkQuery,
  SpaceSsoLinkQueryVariables
>
export const SubdomainDocument = gql`
  query Subdomain($subdomain: String) {
    subdomain(subdomain: $subdomain) {
      id
      subdomain
    }
  }
`

/**
 * __useSubdomainQuery__
 *
 * To run a query within a React component, call `useSubdomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubdomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubdomainQuery({
 *   variables: {
 *      subdomain: // value for 'subdomain'
 *   },
 * });
 */
export function useSubdomainQuery(
  baseOptions?: Apollo.QueryHookOptions<SubdomainQuery, SubdomainQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SubdomainQuery, SubdomainQueryVariables>(
    SubdomainDocument,
    options
  )
}
export function useSubdomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubdomainQuery,
    SubdomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SubdomainQuery, SubdomainQueryVariables>(
    SubdomainDocument,
    options
  )
}
export function useSubdomainSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SubdomainQuery,
    SubdomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SubdomainQuery, SubdomainQueryVariables>(
    SubdomainDocument,
    options
  )
}
export type SubdomainQueryHookResult = ReturnType<typeof useSubdomainQuery>
export type SubdomainLazyQueryHookResult = ReturnType<
  typeof useSubdomainLazyQuery
>
export type SubdomainSuspenseQueryHookResult = ReturnType<
  typeof useSubdomainSuspenseQuery
>
export type SubdomainQueryResult = Apollo.QueryResult<
  SubdomainQuery,
  SubdomainQueryVariables
>
export const UpdateSpaceProfileDocument = gql`
  mutation UpdateSpaceProfile(
    $id: Int!
    $school_type: SchoolType
    $plan_to_sell: Boolean
  ) {
    updateSchoolProfile(
      id: $id
      school_type: $school_type
      plan_to_sell: $plan_to_sell
    ) {
      id
      school_type
      plan_to_sell
    }
  }
`
export type UpdateSpaceProfileMutationFn = Apollo.MutationFunction<
  UpdateSpaceProfileMutation,
  UpdateSpaceProfileMutationVariables
>

/**
 * __useUpdateSpaceProfileMutation__
 *
 * To run a mutation, you first call `useUpdateSpaceProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpaceProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpaceProfileMutation, { data, loading, error }] = useUpdateSpaceProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      school_type: // value for 'school_type'
 *      plan_to_sell: // value for 'plan_to_sell'
 *   },
 * });
 */
export function useUpdateSpaceProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSpaceProfileMutation,
    UpdateSpaceProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateSpaceProfileMutation,
    UpdateSpaceProfileMutationVariables
  >(UpdateSpaceProfileDocument, options)
}
export type UpdateSpaceProfileMutationHookResult = ReturnType<
  typeof useUpdateSpaceProfileMutation
>
export type UpdateSpaceProfileMutationResult =
  Apollo.MutationResult<UpdateSpaceProfileMutation>
export type UpdateSpaceProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateSpaceProfileMutation,
  UpdateSpaceProfileMutationVariables
>
export const UserCreatedSpaceMembershipsDocument = gql`
  query UserCreatedSpaceMemberships {
    me {
      id
      memberships(first: 1, role_filter: { eq: administrator }, global: true) {
        edges {
          node {
            id
            school {
              subdomain
              ...CreatedSpace
            }
          }
        }
      }
    }
  }
  ${CreatedSpaceFragmentDoc}
`

/**
 * __useUserCreatedSpaceMembershipsQuery__
 *
 * To run a query within a React component, call `useUserCreatedSpaceMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCreatedSpaceMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCreatedSpaceMembershipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCreatedSpaceMembershipsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserCreatedSpaceMembershipsQuery,
    UserCreatedSpaceMembershipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserCreatedSpaceMembershipsQuery,
    UserCreatedSpaceMembershipsQueryVariables
  >(UserCreatedSpaceMembershipsDocument, options)
}
export function useUserCreatedSpaceMembershipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserCreatedSpaceMembershipsQuery,
    UserCreatedSpaceMembershipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserCreatedSpaceMembershipsQuery,
    UserCreatedSpaceMembershipsQueryVariables
  >(UserCreatedSpaceMembershipsDocument, options)
}
export function useUserCreatedSpaceMembershipsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserCreatedSpaceMembershipsQuery,
    UserCreatedSpaceMembershipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    UserCreatedSpaceMembershipsQuery,
    UserCreatedSpaceMembershipsQueryVariables
  >(UserCreatedSpaceMembershipsDocument, options)
}
export type UserCreatedSpaceMembershipsQueryHookResult = ReturnType<
  typeof useUserCreatedSpaceMembershipsQuery
>
export type UserCreatedSpaceMembershipsLazyQueryHookResult = ReturnType<
  typeof useUserCreatedSpaceMembershipsLazyQuery
>
export type UserCreatedSpaceMembershipsSuspenseQueryHookResult = ReturnType<
  typeof useUserCreatedSpaceMembershipsSuspenseQuery
>
export type UserCreatedSpaceMembershipsQueryResult = Apollo.QueryResult<
  UserCreatedSpaceMembershipsQuery,
  UserCreatedSpaceMembershipsQueryVariables
>
export const UserSpaceMembershipsDocument = gql`
  query UserSpaceMemberships {
    me {
      id
      memberships(first: 1042, global: true) {
        edges {
          node {
            ...UserSpaceMembership
          }
        }
      }
    }
  }
  ${UserSpaceMembershipFragmentDoc}
`

/**
 * __useUserSpaceMembershipsQuery__
 *
 * To run a query within a React component, call `useUserSpaceMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSpaceMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSpaceMembershipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSpaceMembershipsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserSpaceMembershipsQuery,
    UserSpaceMembershipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserSpaceMembershipsQuery,
    UserSpaceMembershipsQueryVariables
  >(UserSpaceMembershipsDocument, options)
}
export function useUserSpaceMembershipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSpaceMembershipsQuery,
    UserSpaceMembershipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserSpaceMembershipsQuery,
    UserSpaceMembershipsQueryVariables
  >(UserSpaceMembershipsDocument, options)
}
export function useUserSpaceMembershipsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserSpaceMembershipsQuery,
    UserSpaceMembershipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    UserSpaceMembershipsQuery,
    UserSpaceMembershipsQueryVariables
  >(UserSpaceMembershipsDocument, options)
}
export type UserSpaceMembershipsQueryHookResult = ReturnType<
  typeof useUserSpaceMembershipsQuery
>
export type UserSpaceMembershipsLazyQueryHookResult = ReturnType<
  typeof useUserSpaceMembershipsLazyQuery
>
export type UserSpaceMembershipsSuspenseQueryHookResult = ReturnType<
  typeof useUserSpaceMembershipsSuspenseQuery
>
export type UserSpaceMembershipsQueryResult = Apollo.QueryResult<
  UserSpaceMembershipsQuery,
  UserSpaceMembershipsQueryVariables
>
export const ScheduleItemDocument = gql`
  mutation ScheduleItem(
    $schedule_context: ScheduleItemContext!
    $starts: DateTime
    $due: DateTime
    $passive_starts: Boolean
    $passive_due: Boolean
  ) {
    scheduleItem(
      schedule_context: $schedule_context
      starts: $starts
      due: $due
      passive_starts: $passive_starts
      passive_due: $passive_due
    ) {
      ...PathItem
    }
  }
  ${PathItemFragmentDoc}
`
export type ScheduleItemMutationFn = Apollo.MutationFunction<
  ScheduleItemMutation,
  ScheduleItemMutationVariables
>

/**
 * __useScheduleItemMutation__
 *
 * To run a mutation, you first call `useScheduleItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleItemMutation, { data, loading, error }] = useScheduleItemMutation({
 *   variables: {
 *      schedule_context: // value for 'schedule_context'
 *      starts: // value for 'starts'
 *      due: // value for 'due'
 *      passive_starts: // value for 'passive_starts'
 *      passive_due: // value for 'passive_due'
 *   },
 * });
 */
export function useScheduleItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ScheduleItemMutation,
    ScheduleItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ScheduleItemMutation,
    ScheduleItemMutationVariables
  >(ScheduleItemDocument, options)
}
export type ScheduleItemMutationHookResult = ReturnType<
  typeof useScheduleItemMutation
>
export type ScheduleItemMutationResult =
  Apollo.MutationResult<ScheduleItemMutation>
export type ScheduleItemMutationOptions = Apollo.BaseMutationOptions<
  ScheduleItemMutation,
  ScheduleItemMutationVariables
>
export const SchoolSubscriptionPlansDocument = gql`
  query SchoolSubscriptionPlans(
    $ids: IDFilter
    $visibility_code: String
    $visibility_code_filter: StringFilter
    $ignore_visibility: Boolean
    $giftable: Boolean
    $interval: SubscriptionIntervalType
    $intervalFilter: SubscriptionIntervalFilter
    $positioningContext: PositioningContextInput
    $orderBy: [SchoolSubscriptionPlanOrderByInput]
  ) {
    school {
      ...School
      subscription_plans(
        first: 50
        ids: $ids
        visibility_code: $visibility_code
        visibility_code_filter: $visibility_code_filter
        ignore_visibility: $ignore_visibility
        giftable: $giftable
        interval: $interval
        interval_filter: $intervalFilter
        positioningContext: $positioningContext
        orderBy: $orderBy
      ) {
        total
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...PositionedSchoolSubscriptionPlan
          }
        }
      }
    }
  }
  ${SchoolFragmentDoc}
  ${PositionedSchoolSubscriptionPlanFragmentDoc}
`

/**
 * __useSchoolSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useSchoolSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolSubscriptionPlansQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      visibility_code: // value for 'visibility_code'
 *      visibility_code_filter: // value for 'visibility_code_filter'
 *      ignore_visibility: // value for 'ignore_visibility'
 *      giftable: // value for 'giftable'
 *      interval: // value for 'interval'
 *      intervalFilter: // value for 'intervalFilter'
 *      positioningContext: // value for 'positioningContext'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSchoolSubscriptionPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SchoolSubscriptionPlansQuery,
    SchoolSubscriptionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SchoolSubscriptionPlansQuery,
    SchoolSubscriptionPlansQueryVariables
  >(SchoolSubscriptionPlansDocument, options)
}
export function useSchoolSubscriptionPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolSubscriptionPlansQuery,
    SchoolSubscriptionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SchoolSubscriptionPlansQuery,
    SchoolSubscriptionPlansQueryVariables
  >(SchoolSubscriptionPlansDocument, options)
}
export function useSchoolSubscriptionPlansSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SchoolSubscriptionPlansQuery,
    SchoolSubscriptionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SchoolSubscriptionPlansQuery,
    SchoolSubscriptionPlansQueryVariables
  >(SchoolSubscriptionPlansDocument, options)
}
export type SchoolSubscriptionPlansQueryHookResult = ReturnType<
  typeof useSchoolSubscriptionPlansQuery
>
export type SchoolSubscriptionPlansLazyQueryHookResult = ReturnType<
  typeof useSchoolSubscriptionPlansLazyQuery
>
export type SchoolSubscriptionPlansSuspenseQueryHookResult = ReturnType<
  typeof useSchoolSubscriptionPlansSuspenseQuery
>
export type SchoolSubscriptionPlansQueryResult = Apollo.QueryResult<
  SchoolSubscriptionPlansQuery,
  SchoolSubscriptionPlansQueryVariables
>
export const SoftArchiveRegistrationDocument = gql`
  mutation SoftArchiveRegistration(
    $id: Int!
    $softArchivedTime: DateTime
    $sendDiscussionNotifications: Boolean
    $sendDueNotifications: Boolean
  ) {
    updateRegistration(
      id: $id
      softArchivedTime: $softArchivedTime
      send_discussion_notifications: $sendDiscussionNotifications
      send_due_notifications: $sendDueNotifications
    ) {
      ...UnifiedHomeRegistration
    }
  }
  ${UnifiedHomeRegistrationFragmentDoc}
`
export type SoftArchiveRegistrationMutationFn = Apollo.MutationFunction<
  SoftArchiveRegistrationMutation,
  SoftArchiveRegistrationMutationVariables
>

/**
 * __useSoftArchiveRegistrationMutation__
 *
 * To run a mutation, you first call `useSoftArchiveRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftArchiveRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softArchiveRegistrationMutation, { data, loading, error }] = useSoftArchiveRegistrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      softArchivedTime: // value for 'softArchivedTime'
 *      sendDiscussionNotifications: // value for 'sendDiscussionNotifications'
 *      sendDueNotifications: // value for 'sendDueNotifications'
 *   },
 * });
 */
export function useSoftArchiveRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SoftArchiveRegistrationMutation,
    SoftArchiveRegistrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SoftArchiveRegistrationMutation,
    SoftArchiveRegistrationMutationVariables
  >(SoftArchiveRegistrationDocument, options)
}
export type SoftArchiveRegistrationMutationHookResult = ReturnType<
  typeof useSoftArchiveRegistrationMutation
>
export type SoftArchiveRegistrationMutationResult =
  Apollo.MutationResult<SoftArchiveRegistrationMutation>
export type SoftArchiveRegistrationMutationOptions = Apollo.BaseMutationOptions<
  SoftArchiveRegistrationMutation,
  SoftArchiveRegistrationMutationVariables
>
export const UnifiedHomeCohortMembersDocument = gql`
  query UnifiedHomeCohortMembers($groupId: Int!) {
    group(id: $groupId) {
      ...GroupListItem
    }
  }
  ${GroupListItemFragmentDoc}
`

/**
 * __useUnifiedHomeCohortMembersQuery__
 *
 * To run a query within a React component, call `useUnifiedHomeCohortMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnifiedHomeCohortMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnifiedHomeCohortMembersQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useUnifiedHomeCohortMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    UnifiedHomeCohortMembersQuery,
    UnifiedHomeCohortMembersQueryVariables
  > &
    (
      | { variables: UnifiedHomeCohortMembersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UnifiedHomeCohortMembersQuery,
    UnifiedHomeCohortMembersQueryVariables
  >(UnifiedHomeCohortMembersDocument, options)
}
export function useUnifiedHomeCohortMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnifiedHomeCohortMembersQuery,
    UnifiedHomeCohortMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UnifiedHomeCohortMembersQuery,
    UnifiedHomeCohortMembersQueryVariables
  >(UnifiedHomeCohortMembersDocument, options)
}
export function useUnifiedHomeCohortMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UnifiedHomeCohortMembersQuery,
    UnifiedHomeCohortMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    UnifiedHomeCohortMembersQuery,
    UnifiedHomeCohortMembersQueryVariables
  >(UnifiedHomeCohortMembersDocument, options)
}
export type UnifiedHomeCohortMembersQueryHookResult = ReturnType<
  typeof useUnifiedHomeCohortMembersQuery
>
export type UnifiedHomeCohortMembersLazyQueryHookResult = ReturnType<
  typeof useUnifiedHomeCohortMembersLazyQuery
>
export type UnifiedHomeCohortMembersSuspenseQueryHookResult = ReturnType<
  typeof useUnifiedHomeCohortMembersSuspenseQuery
>
export type UnifiedHomeCohortMembersQueryResult = Apollo.QueryResult<
  UnifiedHomeCohortMembersQuery,
  UnifiedHomeCohortMembersQueryVariables
>
export const UnifiedHomeRegistrationsDocument = gql`
  query UnifiedHomeRegistrations(
    $first: Int!
    $positioningContext: PositioningContextInput!
    $orderBy: [RegistrationOrderByInput]!
    $softArchived: DateFilter
  ) {
    me {
      id
      registrations(
        first: $first
        positioningContext: $positioningContext
        orderBy: $orderBy
        softArchived: $softArchived
      ) {
        edges {
          node {
            ...UnifiedHomeRegistration
          }
        }
      }
    }
  }
  ${UnifiedHomeRegistrationFragmentDoc}
`

/**
 * __useUnifiedHomeRegistrationsQuery__
 *
 * To run a query within a React component, call `useUnifiedHomeRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnifiedHomeRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnifiedHomeRegistrationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      positioningContext: // value for 'positioningContext'
 *      orderBy: // value for 'orderBy'
 *      softArchived: // value for 'softArchived'
 *   },
 * });
 */
export function useUnifiedHomeRegistrationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UnifiedHomeRegistrationsQuery,
    UnifiedHomeRegistrationsQueryVariables
  > &
    (
      | { variables: UnifiedHomeRegistrationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UnifiedHomeRegistrationsQuery,
    UnifiedHomeRegistrationsQueryVariables
  >(UnifiedHomeRegistrationsDocument, options)
}
export function useUnifiedHomeRegistrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnifiedHomeRegistrationsQuery,
    UnifiedHomeRegistrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UnifiedHomeRegistrationsQuery,
    UnifiedHomeRegistrationsQueryVariables
  >(UnifiedHomeRegistrationsDocument, options)
}
export function useUnifiedHomeRegistrationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UnifiedHomeRegistrationsQuery,
    UnifiedHomeRegistrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    UnifiedHomeRegistrationsQuery,
    UnifiedHomeRegistrationsQueryVariables
  >(UnifiedHomeRegistrationsDocument, options)
}
export type UnifiedHomeRegistrationsQueryHookResult = ReturnType<
  typeof useUnifiedHomeRegistrationsQuery
>
export type UnifiedHomeRegistrationsLazyQueryHookResult = ReturnType<
  typeof useUnifiedHomeRegistrationsLazyQuery
>
export type UnifiedHomeRegistrationsSuspenseQueryHookResult = ReturnType<
  typeof useUnifiedHomeRegistrationsSuspenseQuery
>
export type UnifiedHomeRegistrationsQueryResult = Apollo.QueryResult<
  UnifiedHomeRegistrationsQuery,
  UnifiedHomeRegistrationsQueryVariables
>
export const UnifiedHomeResourceGroupsDocument = gql`
  query UnifiedHomeResourceGroups($resourceId: Int) {
    resource(id: $resourceId) {
      groups(first: 50) {
        total
        edges {
          group: node {
            ...UnifiedHomeGroup
          }
        }
      }
    }
  }
  ${UnifiedHomeGroupFragmentDoc}
`

/**
 * __useUnifiedHomeResourceGroupsQuery__
 *
 * To run a query within a React component, call `useUnifiedHomeResourceGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnifiedHomeResourceGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnifiedHomeResourceGroupsQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useUnifiedHomeResourceGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UnifiedHomeResourceGroupsQuery,
    UnifiedHomeResourceGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UnifiedHomeResourceGroupsQuery,
    UnifiedHomeResourceGroupsQueryVariables
  >(UnifiedHomeResourceGroupsDocument, options)
}
export function useUnifiedHomeResourceGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnifiedHomeResourceGroupsQuery,
    UnifiedHomeResourceGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UnifiedHomeResourceGroupsQuery,
    UnifiedHomeResourceGroupsQueryVariables
  >(UnifiedHomeResourceGroupsDocument, options)
}
export function useUnifiedHomeResourceGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UnifiedHomeResourceGroupsQuery,
    UnifiedHomeResourceGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    UnifiedHomeResourceGroupsQuery,
    UnifiedHomeResourceGroupsQueryVariables
  >(UnifiedHomeResourceGroupsDocument, options)
}
export type UnifiedHomeResourceGroupsQueryHookResult = ReturnType<
  typeof useUnifiedHomeResourceGroupsQuery
>
export type UnifiedHomeResourceGroupsLazyQueryHookResult = ReturnType<
  typeof useUnifiedHomeResourceGroupsLazyQuery
>
export type UnifiedHomeResourceGroupsSuspenseQueryHookResult = ReturnType<
  typeof useUnifiedHomeResourceGroupsSuspenseQuery
>
export type UnifiedHomeResourceGroupsQueryResult = Apollo.QueryResult<
  UnifiedHomeResourceGroupsQuery,
  UnifiedHomeResourceGroupsQueryVariables
>
