import { graphql } from "@apollo/client/react/hoc"
import get from "lodash/get"
import { CompletionDocument } from "../../api/generated"

export default graphql(CompletionDocument, {
  options: ({ stepId }) => ({
    variables: { id: stepId }
  }),
  skip: ({ step }) => !!step,
  props: ({ data }) => ({
    error: data.error,
    loading: data.loading,
    refetch: data.refetch,
    step: get(data, "pathItem"),
    completion: get(data, "pathItem.completion")
  })
})
