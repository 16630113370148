import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Heading,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  VStack,
  useDisclosure
} from "@chakra-ui/react"
import { XCircleIcon, type IconName } from "@pathwright/pathicons"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { CSSProperties, isValidElement, useEffect, useState } from "react"
import SuggestedAction from "../path/sync/SuggestedAction"
import { useTips } from "./TipsProvider"
import { Tip as TipType } from "./types"

export type TipProps = {
  tip: TipType
  layout?:
    | "accordion"
    | "dismissable"
    | "tooltip"
    | "accordion-pill"
    | "default"
  style?: CSSProperties
  icon?: IconName | null
  defaultState?: "expanded" | "collapsed"
  renderButton?: (props: { onDismiss: () => void }) => JSX.Element
  colorScheme?: "light" | "dark"
}

type TipContentProps = TipProps & {
  hideTitle?: boolean
}

const TipContent = (props: TipContentProps) => {
  const { tip, icon = "lightbulb-on", style, hideTitle } = props

  const { setTipStatus } = useTips()

  return (
    <VStack
      className="tip-content"
      align="start"
      px={"16px"}
      pb={"16px"}
      pt={hideTitle ? 0 : "16px"}
      style={{ ...style }}
      gap={"16px"}
      borderRadius={"card"}
      sx={{
        a: {
          textDecoration: "underline"
        }
      }}
    >
      <HStack gap={3} align="center">
        {icon && (
          <HStack align="center">
            <Pathicon
              icon={icon as IconName}
              color={style!.color}
              size="1.4em"
            />
          </HStack>
        )}
        {!hideTitle && (
          <Heading
            as="h4"
            color={style!.color}
            fontSize="md"
            fontWeight="bold"
            mt={0}
            mb={0}
          >
            {tip.title}
          </Heading>
        )}
      </HStack>
      <Box
        fontSize="sm"
        color={style!.color}
        my={0}
        sx={{
          "*": {
            color: "inherit"
          }
        }}
      >
        {typeof tip.content === "string" ? (
          <Box dangerouslySetInnerHTML={{ __html: tip.content as string }} />
        ) : typeof tip.content === "function" ? (
          tip.content({})
        ) : isValidElement(tip.content) ? (
          tip.content
        ) : null}
      </Box>
      {props.renderButton && (
        <HStack justify="center" w="full" pt={3} pb={0}>
          {props.renderButton({
            onDismiss: () => setTipStatus(tip.id, "dismissed")
          })}
        </HStack>
      )}
    </VStack>
  )
}

const TipAccordion = (
  props: TipProps & {
    pill?: boolean
  }
) => {
  const { tip, icon, style, defaultState, pill } = props
  const { title, id } = tip as TipType

  const { getTipStatus, setTipStatus } = useTips()

  const tipStatus = getTipStatus(id)

  const { isOpen, onClose, onOpen } = useDisclosure({
    id,
    defaultIsOpen: !!tipStatus
      ? tipStatus !== "collapsed"
      : defaultState !== "collapsed"
  })

  useEffect(() => {
    if (!tipStatus) return

    if (tipStatus === "expanded") {
      onOpen()
    } else {
      onClose()
    }
  }, [tipStatus])

  const handleToggleTip = (toggle: boolean) => {
    if (toggle) {
      setTipStatus(id, "expanded")
      onOpen()
    } else {
      setTipStatus(id, "collapsed")
      onClose()
    }
  }

  const renderButton = ({ isExpanded }: { isExpanded: boolean }) => {
    if (pill) {
      return (
        <AccordionButton
          justifyContent="center"
          cursor="pointer"
          onClick={() => handleToggleTip(!isExpanded)}
          bg={isExpanded ? style?.backgroundColor : "transparent"}
          pt={3}
          pb={3}
          borderTopRadius="card"
          sx={{
            ".chakra-button": {
              border: "none",
              color: "var(--chakra-colors-textOnLight)",
              fontWeight: "400"
            },
            svg: {
              fill: "var(--chakra-colors-textOnLight)"
            }
          }}
          _hover={{
            bg: isExpanded ? "" : "rgba(255,255,255,0.8)",
            ".chakra-button": {
              backgroundColor: isExpanded ? "transparent" : "rgba(0, 0, 0, 0.1)",
              color: "var(--chakra-colors-textOnLight)"
            }
          }}
          pos="relative"
        >
          <SuggestedAction icon={icon as IconName} label={title} />
          <Box
            position="absolute"
            right={3}
            top="50%"
            transform="translateY(-50%)"
          >
            {isExpanded ? (
              <XCircleIcon
                size="1em"
                color="var(--chakra-colors-textOnLight)"
              />
            ) : null}
          </Box>
        </AccordionButton>
      )
    }

    return (
      <AccordionButton
        justifyContent="space-between"
        cursor="pointer"
        onClick={() => handleToggleTip(!isExpanded)}
        bg={style?.backgroundColor}
        pt={3}
        pb={3}
        _hover={{
          bg: isExpanded ? "" : "rgba(255,255,255,0.8)"
        }}
      >
        <HStack gap={0} flex={1}>
          <Box mr={3}>
            {icon && (
              <Pathicon
                icon={icon as IconName}
                color={style!.color}
                size="1.4em"
                style={{
                  marginTop: "0.1em"
                }}
              />
            )}
          </Box>
          <Heading
            textAlign="left"
            as="h4"
            fontSize="md"
            fontWeight="bold"
            m={0}
          >
            {title}
          </Heading>
        </HStack>
        <Pathicon
          className="toggle-tip-icon"
          icon={isExpanded ? "minus" : ("plus" as IconName)}
          size="1.4em"
          color={style!.color}
        />
      </AccordionButton>
    )
  }

  return (
    <Accordion index={isOpen ? 0 : -1}>
      <AccordionItem bg={"transparent"} border={pill ? "none" : undefined}>
        {({ isExpanded }) => (
          <>
            {renderButton({ isExpanded })}
            <AccordionPanel
              p={0}
              borderBottomRadius="card"
              sx={{
                ".tip-content": {
                  borderTopRadius: 0
                }
              }}
            >
              <TipContent {...props} hideTitle={true} icon={null} />
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  )
}

const TipDismissable = (props: TipProps) => {
  const {
    tip: { id },
    style
  } = props
  const { getTipStatus, setTipStatus } = useTips()

  const [visible, setVisible] = useState<boolean>(
    getTipStatus(id) !== "dismissed"
  )

  const handleDismiss = () => {
    setVisible(false)
    setTipStatus(id, "dismissed")
  }

  if (!visible) {
    return null
  }

  return (
    <Box
      bg={"transparent"}
      borderRadius="10px"
      boxShadow="md"
      position="relative"
    >
      <Pathicon
        className="dismiss-tip"
        icon="x-circle-fill"
        size="1.2em"
        color={style!.color}
        position="absolute"
        top={4}
        right={4}
        cursor="pointer"
        onClick={handleDismiss}
      />
      <TipContent {...props} />
    </Box>
  )
}

const TipTooltip = (props: TipProps) => {
  const {
    tip: { id },
    style
  } = props
  const { getTipStatus, setTipStatus } = useTips()

  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen: getTipStatus(id) !== "seen"
  })

  return (
    <Popover
      trigger="hover"
      isOpen={isOpen}
      placement="left"
      onOpen={() => {
        setTipStatus(id, "seen")
        onOpen()
      }}
      onClose={onClose}
    >
      <PopoverTrigger>
        <IconButton
          p={0}
          aria-label="info"
          icon={
            <Pathicon icon="info-circle" size="1.2em" color={style!.color} />
          }
        />
      </PopoverTrigger>
      <PopoverContent
        p={0}
        // border="none"
        borderRadius={0}
        bg={"rgba(255,255,255,0.9)"}
        // bg="transparent"
      >
        <PopoverArrow bg={"rgba(255,255,255,0.9)"} />
        <PopoverBody p={0}>
          <Box>
            <TipContent {...props} />
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

const getDefaults = (props: TipProps) => {
  return {
    layout: "dismissable",
    style: {
      ...(props.colorScheme === "dark"
        ? {
            backgroundColor: "rgba(26, 32, 44, 0.33)",
            color: "rgba(255, 255, 255, 0.9)"
          }
        : {
            backgroundColor: "rgba(26, 32, 44, 0.1)",
            color: "#444"
          }),
      ...props.style
    },
    colorScheme: "light",
    ...props
  } as TipProps
}

const Tip = (props: TipProps) => {
  const passedProps = getDefaults(props)

  if (
    passedProps.layout === "accordion" ||
    passedProps.layout === "accordion-pill"
  ) {
    return (
      <TipAccordion
        {...passedProps}
        pill={props.layout === "accordion-pill"}
      ></TipAccordion>
    )
  }

  if (passedProps.layout === "dismissable") {
    return (
      <TipDismissable {...passedProps}>
        <TipContent {...passedProps} />
      </TipDismissable>
    )
  }

  if (passedProps.layout === "tooltip") {
    return <TipTooltip {...passedProps} />
  }

  if (!passedProps.layout || passedProps.layout === "default") {
    return <TipContent {...passedProps} />
  }
}

export default Tip
