import CardStack from "@pathwright/ui/src/components/card/CardStack"
import {
  AUGMENTATION_PANEL_QUERY_PARAM,
  getCardPath
} from "@pathwright/web/src/modules/utils/urls"
import ConnectStores from "lib/core/store/components/ConnectStores"
import path from "path-browserify"
import { useCallback } from "react"
import { useHistory } from "react-router-dom"

const RouteCardStack = (props) => {
  const {
    cards,
    location,
    defaultRoute,
    prevent_modal_close,
    modal_launched_from_url,
    onCloseCardStack
  } = props

  const history = useHistory()

  const handleCloseCard = useCallback(
    (card, index) => {
      // returning false prevents popping the card
      if (prevent_modal_close) return false
      // Allow popping the card, we must be popping a card that isn't
      // tied to a route.
      if (!card.path) return true

      // Get the card path from the ?AUGMENTATION_PANEL_QUERY_PARAM query param, if it exists.
      const cardPath = getCardPath(props.location)
      // Fallback to legacy routing where card path matches the pathname.
      const prevUrlPath = cardPath || location.pathname

      if (index > 0) {
        const prevURL = path.join(prevUrlPath, card.reversePath)
        // Using newer ?AUGMENTATION_PANEL_QUERY_PARAM routing where card path matches the ?AUGMENTATION_PANEL_QUERY_PARAM query param.
        if (cardPath) {
          history.push({
            pathname: props.location.pathname,
            search: prevURL
              ? `${AUGMENTATION_PANEL_QUERY_PARAM}=${decodeURIComponent(
                  prevURL
                )}`
              : ""
          })
        }
        // Prefer using the history's goBack method when there is a
        // history entry to go back to.
        // else if (historyEntries.length > 1) {
        //   history.goBack()
        // }
        // Use legacy routing where card path matches the pathname.
        else {
          history.push({ pathname: prevURL })
        }
      } else {
        // Closing card, route to prev url before card stack mounted
        history.push(modal_launched_from_url || defaultRoute())
        // Close cards stack when this is the root card.
        onCloseCardStack?.()
      }
    },
    [cards, location, prevent_modal_close, modal_launched_from_url]
  )

  return <CardStack {...props} onCloseCard={handleCloseCard} />
}

RouteCardStack.displayName = "RouteCardStack"

const connectedStores = [
  {
    store: "navigation",
    watch: ["modal_launched_from_url", "prevent_modal_close"]
  }
]

const mapStateToProps = function (state, ownProps) {
  const { modal_launched_from_url, prevent_modal_close } =
    state.navigationStore.state
  const { defaultRoute } = state.navigationStore.request
  const storeProps = {
    modal_launched_from_url,
    prevent_modal_close,
    defaultRoute
  }
  const stackProps = _.assign({}, storeProps, ownProps)
  return stackProps
}

export default ConnectStores(
  RouteCardStack,
  connectedStores,
  {},
  mapStateToProps
)
