import {
  Box,
  Button,
  HStack,
  Heading,
  Link,
  Spinner,
  Text,
  VStack
} from "@chakra-ui/react"
import CardComponent from "@pathwright/ui/src/components/card/Card"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { getGroupUrl } from "@pathwright/web/src/modules/utils/urls"
import { Query } from "../../api/generated"
import { Card } from "../sync/types"

// This component is triggered as a Card modal in 2 places if the Source Path is empty:
//   - When clicking the "Enable member-created cohorts" toggle in the manage cohorts card.
//   - When adding a new cohort (from manage card or Cohort Island), if there's exactly 1 cohort.

const SetupSourcePathCard = ({
  card,
  cohort
}: {
  card: Card
  cohort: Query["group"] & { resource: { source_cohort?: { id: number } } }
}) => {
  const { tc, Trans } = useTranslate()

  return (
    <CardComponent card={card} noaction hideClose>
      <Box maxW="400px" mx="auto" py={10}>
        {cohort ? (
          <VStack spacing={1}>
            <Pathicon
              icon="cloud-arrows-fill"
              style={{
                fontSize: "2em",
                width: "27px",
                height: "27px",
                color: "var(--color-textOnLight)"
              }}
            />
            <Heading size="md" mt={0} mb={1}>
              {tc("Setup Source Path")}
            </Heading>
            <Text textAlign="center">
              <Trans i18nKey="setup_source_path_description">
                To create another Cohort taking the{" "}
                {cohort.resource.name || cohort.name} Path, first setup the
                Source Path.
              </Trans>
            </Text>
            <HStack justify="center">
              <Link
                href={getGroupUrl(
                  cohort.resource.slug,
                  cohort.resource.source_cohort?.id!
                )}
              >
                <Button
                  onClick={() => card.onClose()}
                  colorScheme="brand"
                  cursor="pointer"
                >
                  {tc("Setup Source Path")}
                </Button>
              </Link>
            </HStack>
          </VStack>
        ) : (
          <Spinner />
        )}
      </Box>
    </CardComponent>
  )
}

export default SetupSourcePathCard
