import { useQuery } from "@apollo/client"
import {
  Box,
  Button,
  Flex,
  HStack,
  Spacer,
  Text,
  VStack
} from "@chakra-ui/react"
import Card from "@pathwright/ui/src/components/card/Card"
import Error from "@pathwright/ui/src/components/error/Error"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { Link as ReactRouterLink } from "react-router-dom"
import { FEATURE_LOCK_QUERY } from "../../lib/FeatureLock"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { canAdministrate } from "../../user/permissions"
import CertificateContext from "../context/CertificateContext"
import { CertificateCreatePrompt } from "../editor/CertificateCreator"
import CertificateFeatureLockGate from "../gating/CertificateFeatureLockGate"
import CertificateResourcesManager from "../manager/CertificateResourcesManager"

const CreateCertificateBlankSlate = () => {
  const pwContext = usePathwrightContext()

  return (
    <VStack spacing={4} px={4} pb={4} textAlign="center">
      <CertificateCreatePrompt />
      <Spacer />
      <Flex
        border="dashed 2px grey"
        background="whitesmoke"
        h="200px"
        w="300px"
        alignItems="center"
        justifyContent="center"
      >
        <Text>No Certificate Template</Text>
      </Flex>
      <Spacer />
      {canAdministrate(pwContext) ? (
        <Button
          colorScheme="brand"
          as={ReactRouterLink}
          variant="solid"
          to="/certificate/"
          size="lg"
        >
          <HStack>
            <Pathicon icon="edit" />
            <span>Design Completion Certificate</span>
          </HStack>
        </Button>
      ) : (
        <>
          <Button colorScheme="brand" variant="solid" size="lg" isDisabled>
            <HStack>
              <Pathicon icon="edit" />
              <span>Design Completion Certificate</span>
            </HStack>
          </Button>
          <Text size="xs" color="gray.500">
            Only Administrators can edit certificate templates.
          </Text>
        </>
      )}

      <Spacer />
    </VStack>
  )
}

function CertificateCard({ card, resourceId }) {
  // Hacky, but prepping the feature lock query now, so we don't run into
  // a waterfall scenarios since the certificate context must load first.
  const featureLockQuery = useQuery(FEATURE_LOCK_QUERY)

  return (
    <Card className="chakra-ui-reset-css" card={card}>
      <Box px={4}>
        <CertificateContext certificateScope={{ resourceId }}>
          {({ certificate, resource, loading, error }) =>
            loading || featureLockQuery.loading ? (
              <LoadingCircle center />
            ) : error ? (
              <Error errorMessage={error.message} />
            ) : (
              <CertificateFeatureLockGate>
                {certificate ? (
                  <CertificateResourcesManager />
                ) : (
                  <CreateCertificateBlankSlate />
                )}
              </CertificateFeatureLockGate>
            )
          }
        </CertificateContext>
      </Box>
    </Card>
  )
}

export default CertificateCard
